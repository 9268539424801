import {reactive, ref, inject, toRaw, computed} from 'vue'
import {
    caseGetNumBySource,
    caseSave,
    caseValid,
    caseCancelValid,
    caseUpdateDelay,
    caseUpdateRedoDuty,
    caseGet,
    caseGetNo,
    caseGetPage,
    caseGetHistoryList,
    caseExportExcel,
    caseOut,
    caseGetSimplePage,
    caseAudit,
    caseTemplateExcel,
    caseGetForPhoto,
    caseFileAdd,
    caseFileDelete,
    caseGetLateList,
    caseGetLateExcel,
    caseReturn,
    caseModRemark,
    caseUpdateFinanceAudit,
} from '@/api/case'
import http from '@/util/axios'
import {ElNotification, ElMessage} from "element-plus";
import {
    caseTypeEnum,
    caseStateEnum,
    orderSourceEnum,
    auditStateEnum,
    caseType,
    tryState,
    toothTypeEnum, tryStateEnum, discountTypeEnum, modelTypeEnum
} from "@/util/enum";
import {nullProductNo} from "@/util/constant";
import {
    caseMonthModCase
} from "@/api/case";

/**
 * 获取某个关联订单号关联的订单数量
 * @returns {{getNumBySource: (function(*): Promise<unknown>), getNumBySourceLoading: *}}
 */
function useGetNumBySource() {
    const getNumBySourceLoading = ref(false)
    const getNumBySource = async caseNo => {
        if (getNumBySourceLoading.value) return
        getNumBySourceLoading.value = true
        let result = await caseGetNumBySource({caseNo: caseNo})
        getNumBySourceLoading.value = false
        return result ? result : 0
    }
    return {
        getNumBySourceLoading,
        getNumBySource
    }
}

//保存工厂订单 oycm 2022/8/8
function useSaveCase() {
    const facCase = reactive({
        saveOrderSource: orderSourceEnum.工厂后台,
        id: 0,
        caseNo: '',
        caseType: 1,
        caseTypeStr: '',
        isTry: 0,
        tryState: '',
        isInvoice: 0,
        isUrgent: 0,
        isValid: 1,
        boxNo: '',
        sourceFactoryId: null,
        processFactoryId: null,
        facHospitalId: null,
        facHospitalName: '',
        district: null,
        facDoctorId: null,
        facDoctorName: '',
        facWorkerId: null,
        facWorkerName: '',
        isPrivate: 0,
        agentId: null,
        patientName: '',
        gender: '',
        patientAge: null,
        patientPhoneNo: '',
        hospitalCaseNo: '',
        wearDate: null,
        inDate: new Date(),
        makeDays: null,
        extraDays: null,
        scheduleDeliveryDate: '',
        sourceCaseNo: '',
        doctorRemark: '',
        doctorHabit: '',
        factoryRemark: '',
        stepNote: '',
        makeCost: '',
        materialCost: '',
        totalPrice: 0,
        caseState: caseStateEnum.入件,
        orderSource: orderSourceEnum.工厂后台,
        medicalNo: '',
        realHospitalName: '',
        isOralScan: 0,
        modelType: modelTypeEnum.打印模型,
        isImportant: 0,
        express: {
            sendType: '',
            receiverType: '',
            contactPerson: '',
            contactPhoneNo: '',
            province: '',
            city: '',
            area: '',
            address: '',
            isCollect: 0,
            shipPerson: '',
            shipPhoneNo: '',
            shipRemark: '',
            hospitalDeptName: '',
            packDate: '',
        },
        productList: [newCaseProduct()],
        attachList: [],
        changeMaterial: true,
        changeFile: true,
        materialList: [],
        fileList: [],
        plantList: []
    })
    const caseEmpty = Object.assign({}, facCase)

    // ----------------材料
    function newMaterial() {
        return {
            id: 0,
            materialId: null,
            materialNo: '',
            materialName: '',
            lotNo: '',
            registerNo: '',
        }
    }

    function addMaterial() {
        if (facCase.materialList.length >= 15) {
            ElMessage.error('不能超过20条数据')
            return
        }
        facCase.materialList.push(newMaterial())
    }

    function deleteMaterial(index) {
        if (facCase.materialList.length <= 1) {
            Object.assign(facCase.materialList[0], newMaterial())
            return
        }
        facCase.materialList.splice(index, 1)
    }

    const materialNum = computed(() => facCase.materialList.filter(x => !!x.materialId).length)

    // ----------------种植
    function newPlant() {
        return {
            id: 0,
            pos1: '',
            pos2: '',
            pos3: '',
            pos4: '',
            upperJaw: 0,
            lowerJaw: 0,
            num: 0,
            plantSystem: '',
            plantRetention: ''
        }
    }

    function addPlant() {
        if (facCase.plantList.length >= 15) {
            ElMessage.error('不能超过20条数据')
            return
        }
        facCase.plantList.push(newPlant())
    }

    function deletePlant(index) {
        if (facCase.plantList.length <= 1) {
            Object.assign(facCase.plantList[0], newPlant())
            return
        }
        facCase.plantList.splice(index, 1)
    }

    const plantNum = computed(() => facCase.plantList.filter(x => !!x.num).length)

    // ----------------产品
    function newCaseProduct() {
        return {
            id: 0,
            productId: null,
            productName: '',
            productNo: '',
            color: '',
            num: null,
            price: null,
            discount: 0,
            discountType: discountTypeEnum.人民币,
            sum: null,
            benefit: null,
            pos1: '',
            pos2: '',
            pos3: '',
            pos4: '',
            upperJaw: 0,
            lowerJaw: 0,
            complete: 0,
            toothType: toothTypeEnum.单冠,
            pontic: '',
            missing1: '',
            missing2: '',
            missing3: '',
            missing4: '',
            fileList: []
        }
    }

    // 附件
    const newAttach = () => {
        return {
            id: 0,
            name: '',
            num: 0,
            belong: ''
        }
    }
    const addAttach = () => {
        if (facCase.attachList.length && facCase.attachList.length >= 50) {
            ElMessage.error('不能超过50个附件，请检查')
            return
        }
        facCase.attachList.push(newAttach())
    }
    const deleteAttach = index => {
        if (facCase.attachList.length && facCase.attachList.length <= 1) {
            ElMessage.error('不能全部删除，请检查')
            return
        }
        facCase.attachList.splice(index, 1)
    }
    const attachNum = computed(() => facCase.attachList.filter(x => !!x.num && !!x.name).length)

    const setFields = inject('setFields')
    const initCase = () => {
        Object.assign(facCase, caseEmpty)
        facCase.productList = [newCaseProduct()]
        facCase.attachList = []
        facCase.materialList = []
        facCase.plantList = []
        for (let i = 0; i < 4; i++) {
            addAttach(i)
        }
        addPlant(0)
    }
    const caseFormRef = ref()
    const validateBeforeSave = () => {
        if (facCase.attachList.some(x => x.num < 0)) {
            ElMessage.error('附件数量不能为负数')
            return false
        }
        let validateProductList = facCase.productList.filter(x => !!x.productId)
        if (!validateProductList || !validateProductList.length) {
            ElMessage.error('请填写订单产品')
            return false
        }
        if (validateProductList.some(x => !x.productId || !x.num || x.price === null)) {
            ElMessage.error('每个订单产品都需要选择产品名称，并填写产品数量和单价')
            return false
        }
        if (validateProductList.some(x => x.productNo === nullProductNo)) {
            ElMessage.error('不能选择未指定产品，请于医生沟通')
            return false
        }
        if (validateProductList.some(x => x.num < 0 || x.price < 0 || x.sum < 0)) {
            ElMessage.error('产品数量、单价、小计不能小于0')
            return false
        }
        if (facCase.isTry !== 0 && !facCase.tryState) {
            ElMessage.error('请选择试戴类型')
            return false
        }
        if (facCase.isPrivate && !facCase.facDoctorId) {
            ElMessage.error('私人订单一定要选择医生')
            return false
        }
        facCase.productList = validateProductList
        return true
    }
    const caseSaveRules = reactive({
        caseType: [
            {required: true, message: '请选择订单类型', trigger: 'change'},
        ],
        facHospitalId: [
            {required: true, message: '请选择客户', trigger: 'change'},
        ],
        patientName: [
            {required: true, message: '请输入患者', trigger: 'blur'},
        ],
        inDate: [
            {required: true, message: '请输入入件日期', trigger: 'blur'},
        ],
        sourceFactoryId: [
            {required: true, message: '请选择入件工厂', trigger: 'blur'},
        ],
        totalPrice: [
            {type: 'number', required: true, min: 0, message: '订单金额不能为负数', trigger: 'blur'},
        ],
    })
    const saveCaseLoading = ref(false)
    const saveCaseResult = ref(false)
    const saveCaseInfo = reactive({
        id: 0,
        caseNo: ''
    })
    const saveCase = async () => {
        saveCaseResult.value = false
        if (saveCaseLoading.value) return
        if (!validateBeforeSave()) return
        saveCaseLoading.value = true
        await caseFormRef.value.validate(async (valid, fields) => {
            if (!valid) {
                saveCaseLoading.value = false
                return
            }
            let response = await caseSave(facCase)
            if (response.code === 0) {
                saveCaseResult.value = true
                saveCaseInfo.id = response.data.id
                saveCaseInfo.caseNo = response.data.caseNo
                ElNotification.success('操作成功')
            } else {
                saveCaseResult.value = false
                console.log('factoryPost fail', response)
                ElNotification({
                    title: '失败',
                    message: response.message,
                    type: 'error',
                })
            }
            saveCaseLoading.value = false
        })
    }
    const saveMonthCase = async () => {
        // 审核对账单时修改订单
        saveCaseResult.value = false
        if (saveCaseLoading.value) return
        if (!validateBeforeSave()) return
        saveCaseLoading.value = true
        await caseFormRef.value.validate(async (valid, fields) => {
            if (!valid) {
                saveCaseLoading.value = false
                return
            }
            saveCaseResult.value = await caseMonthModCase(facCase)
            saveCaseLoading.value = false
        })
    }
    // 订单产品是否免费
    const isCaseFree = caseInfo => {
        return caseInfo.caseType === caseTypeEnum.调整件 || caseInfo.caseType === caseTypeEnum.试做 || caseInfo.caseState === caseStateEnum.已退货
            || caseInfo.caseType === caseTypeEnum.返工 || caseInfo.caseType === caseTypeEnum.返修
            || (caseInfo.caseType === caseTypeEnum.正常 && caseInfo.isTry === 1 && caseInfo.tryState !== tryStateEnum.未试戴)
    }
    // 根据订单类型将订单金额修改为0
    const setCaseMoneyFree = caseInfo => {
        let makeCost = caseInfo.makeCost ? parseFloat(caseInfo.makeCost) : 0
        let materialCost = caseInfo.materialCost ? parseFloat(caseInfo.materialCost) : 0
        if (isCaseFree(caseInfo)) {
            if (caseInfo.productList && caseInfo.productList.length) {
                caseInfo.productList.forEach(x => {
                    x.discountType = discountTypeEnum.人民币
                    x.discount = x.price
                    x.benefit = Math.round(x.price * x.num * 100) / 100
                    x.sum = 0
                })
            }
            caseInfo.totalPrice = makeCost + materialCost
        }
    }
    //计算订单金额
    const calCaseTotalPrice = caseInfo => {
        let makeCost = caseInfo.makeCost ? parseFloat(caseInfo.makeCost) : 0
        let materialCost = caseInfo.materialCost ? parseFloat(caseInfo.materialCost) : 0
        let productPrice = 0
        if (caseInfo.productList && caseInfo.productList.length) {
            for (let i = 0; i < caseInfo.productList.length; i++) {
                let product = caseInfo.productList[i]
                let num = product.num ? parseFloat(product.num) : 0
                let price = product.price ? parseFloat(product.price) : 0
                let sum = 0
                if (product.discountType === discountTypeEnum.百分比) {
                    //百分比
                    let discount = product.discount ? parseFloat(product.discount) : 100
                    product.benefit = (num * price * (100 - discount) / 100)
                    sum = num * price * product.discount / 100
                } else {
                    // 金额
                    let discount = product.discount ? parseFloat(product.discount) : 0
                    product.benefit = discount * num
                    sum = num * (price - product.discount)
                }
                product.benefit = Math.round(product.benefit * 100) / 100
                product.sum = Math.round(sum * 100) / 100
                productPrice = (productPrice * 100 + product.sum * 100) / 100
            }
        }
        caseInfo.totalPrice = (makeCost * 100 + materialCost * 100 + productPrice * 100) / 100
    }
    //根据订单是否免费计算订单金额
    const getCaseTotalPrice = caseInfo => {
        if (isCaseFree(caseInfo)) {
            setCaseMoneyFree(caseInfo)
        } else {
            calCaseTotalPrice(caseInfo)
        }
    }
    const getTotalPrice = () => {
        getCaseTotalPrice(facCase)
    }
    const totalNum = computed(() => {
        if (facCase.productList && facCase.productList.length) {
            let tmpSum = 0
            for (let product of facCase.productList) {
                let num = product.num ? parseFloat(product.num) : 0
                tmpSum = tmpSum + num
            }
            return tmpSum
        }
        return 0
    })
    const getTotalDiscount = caseInfo => {
        let discountSum = 0
        if (caseInfo.productList && caseInfo.productList.length) {
            for (let product of caseInfo.productList) {
                let num = product.num ? parseFloat(product.num) : 0
                let price = product.price ? parseFloat(product.price) : 0
                if (product.discountType === 0) {
                    //百分比
                    let discount = product.discount ? parseFloat(product.discount) : 100
                    discountSum = discountSum + (num * price * (100 - product.discount) / 100)
                } else {
                    // 金额
                    let discount = product.discount ? parseFloat(product.discount) : 0
                    discountSum = discountSum + (discount * num)
                }
            }
        }
        return discountSum
    }
    const totalDiscount = computed(() => {
        let discountSum = 0
        if (facCase.productList && facCase.productList.length) {
            for (let product of facCase.productList) {
                let num = product.num ? parseFloat(product.num) : 0
                let price = product.price ? parseFloat(product.price) : 0
                if (product.discountType === 0) {
                    //百分比
                    let discount = product.discount ? parseFloat(product.discount) : 100
                    discountSum = discountSum + (num * price * (100 - product.discount) / 100)
                } else {
                    // 金额
                    let discount = product.discount ? parseFloat(product.discount) : 0
                    discountSum = discountSum + (discount * num)
                }
            }
        }
        return discountSum
    })
    // 根据医院、医生、是否私人单位获取订单产品的价格  并计算合计与小计
    const changeCaseProductPrice = (row, productDetails, storeHospitalDetail, storeDoctor) => {
        if (!row.productId) return;
        let sameProductNum = facCase.productList.filter(x => x.productId === row.productId)
        if (sameProductNum && sameProductNum.length >= 2) {
            row.productId = null
            ElMessage.error('产品重复，请检查')
            return;
        }
        // 获取基础价格
        let storeProductDetail = productDetails.find(x => x.id === row.productId)
        row.productName = storeProductDetail.name
        row.productNo = storeProductDetail.productNo
        let salesPrice = 0
        if (storeProductDetail && storeProductDetail.price) {
            salesPrice = parseFloat(storeProductDetail.price)
        }
        if (storeHospitalDetail.retailBan === 1) {
            // 客户禁用零售价
            salesPrice = 0
        }
        // 获取客户价格
        let hospitalPrice = null
        if (storeHospitalDetail && storeHospitalDetail.priceList && storeHospitalDetail.priceList.length) {
            let storeHospitalPrice = storeHospitalDetail.priceList.find(x => x.productId === row.productId)
            if (storeHospitalPrice) hospitalPrice = storeHospitalPrice.salesPrice
        }
        // 如果是私人单位
        if (facCase.isPrivate === 1) {
            if (!storeDoctor) {
                row.price = hospitalPrice ? hospitalPrice : salesPrice
            } else {
                if (storeDoctor.discountId === -1) {
                    //私人单位按照零售价
                    row.price = salesPrice
                    if (!row.price) ElMessage.error('产品未设置零售价/零售价为0，请检查')
                } else if (storeDoctor.discountId > 0) {
                    //私人单位按照私人价格
                    let doctorPrice = null
                    if (storeDoctor.priceList && storeDoctor.priceList.length) {
                        let storeDoctorPrice = storeDoctor.priceList.find(x => x.productId === row.productId)
                        if (storeDoctorPrice) doctorPrice = storeDoctorPrice.salesPrice
                    }
                    row.price = doctorPrice
                    if (!row.price) ElMessage.error('产品未设置私人价/私人价格0，请检查')
                } else {
                    //私人单位按照客户价格
                    row.price = hospitalPrice
                    if (!row.price) ElMessage.error('产品未设置客户价/客户价为0，请检查')
                }
            }
        } else {
            row.price = hospitalPrice !== null ? hospitalPrice : salesPrice
            if (!row.price) ElMessage.error('产品未设置客户价/客户价为0，请检查')
        }
        if (isCaseFree(facCase)) {
            row.discount = row.price
            row.discountType = discountTypeEnum.人民币
        }
        calCaseTotalPrice(facCase)
    }
    return {
        addMaterial,
        deleteMaterial,
        materialNum,
        addPlant,
        deletePlant,
        plantNum,
        newCaseProduct,
        addAttach,
        deleteAttach,
        attachNum,
        facCase,
        initCase,
        caseFormRef,
        caseSaveRules,
        saveCaseLoading,
        saveCaseResult,
        saveCaseInfo,
        saveCase,
        saveMonthCase,
        calCaseTotalPrice,
        getCaseTotalPrice,
        getTotalPrice,
        getTotalDiscount,
        changeCaseProductPrice,
        totalDiscount,
        totalNum,
    }
}

// 获取订单
function useGetCase() {
    const caseInfo = reactive({})
    const caseGetLoading = ref(false)
    const setFields = inject('setFields')
    const getCase = async id => {
        if (!id || id <= 0) return
        if (caseGetLoading.value) return
        caseGetLoading.value = true
        let res = await caseGet({id: id})
        if (!!res) {
            Object.assign(caseInfo, res)
        } else {
            Object.assign(caseInfo, {id: 0})
        }
        caseGetLoading.value = false
    }
    const getCaseByNo = async caseNo => {
        if (!caseNo) return
        if (caseGetLoading.value) return
        caseGetLoading.value = true
        let res = await caseGetNo({caseNo: caseNo})
        if (!!res) {
            Object.assign(caseInfo, res)
        } else {
            Object.assign(caseInfo, {id: 0})
        }
        caseGetLoading.value = false
    }
    return {
        caseInfo,
        caseGetLoading,
        getCase,
        getCaseByNo
    }
}

// 订单分页
function useGetPageCase() {
    const getPageCaseParam = reactive({
        exactCaseNo: '',
        caseNo: '',
        boxNo: '',
        caseType: [],
        caseTypeStr: '',
        caseState: [],
        maxCaseState: null,
        minCaseState: null,
        caseStateStr: '',
        isTry: -1,
        tryState: null,
        isPrivate: -1,
        isUrgent: -1,
        isInvoice: -1,
        sourceCaseNo: '',
        patientName: '',
        patientPhoneNo: '',
        facHospitalId: null,
        district: null,
        facDoctorId: null,
        facWorkerId: null,
        agentId: null,
        medicalNo: '',
        hospitalCaseNo: '',
        inBeginDate: '',
        inEndDate: '',
        outBeginDate: '',
        outEndDate: '',
        scheduleBeginDate: '',
        scheduleEndDate: '',
        wearBeginDate: '',
        wearEndDate: '',
        isValid: -1,
        isAudit: 1,
        isDelay: -1,
        includeProduct: false,
        salesmanArr: null,
        invoiceNo: '',
        hidePrice: null,
        pageIndex: 1,
        pageSize: 10,
    })
    const getPageCaseParamEmpty = JSON.parse(JSON.stringify(toRaw(getPageCaseParam)))
    const setFields = inject('setFields')
    const initGetPageCaseParam = () => {
        setFields(getPageCaseParam, getPageCaseParamEmpty)
    }
    const getPageCaseLoading = ref(false)
    const casePage = ref([])
    const getPageCaseResult = reactive({
        totalCount: 0,
        totalPages: 0,
    })
    const getPageCase = async () => {
        if (getPageCaseLoading.value) return
        getPageCaseLoading.value = true
        getPageCaseParam.caseStateStr = getPageCaseParam.caseState.join(',')
        getPageCaseParam.caseTypeStr = getPageCaseParam.caseType.join(',')
        let data = await caseGetPage(getPageCaseParam)
        if (data && data.list && data.list.length) {
            casePage.value = data.list
            getPageCaseResult.totalPages = Math.ceil(data.totalCount / getPageCaseParam.pageSize)
            getPageCaseResult.totalCount = data.totalCount
        } else {
            casePage.value = []
            getPageCaseResult.totalPages = 0
            getPageCaseResult.totalCount = 0
        }
        getPageCaseLoading.value = false
    }
    return {
        getPageCaseParam,
        initGetPageCaseParam,
        getPageCaseLoading,
        casePage,
        getPageCaseResult,
        getPageCase,
    }
}

// 订单分页
function useGetSimplePageCase() {
    const getSimplePageCaseParam = reactive({
        caseNo: '',
        boxNo: '',
        hospitalCaseNo: '',
        sourceCaseNo: '',
        patientName: '',
        facHospitalId: null,
        facDoctorId: null,
        inBeginDate: '',
        inEndDate: '',
        outBeginDate: '',
        outEndDate: '',
        facWorkerId: '',
        salesmanArr: null,
        auditState: null,
        isAudit: null,
        isFormAudit: null,
        isValid: null,
        pageIndex: 1,
        pageSize: 10,
    })
    const getSimplePageCaseParamEmpty = Object.assign({}, getSimplePageCaseParam)
    const setFields = inject('setFields')
    const initGetSimplePageCaseParam = () => {
        setFields(getSimplePageCaseParam, getSimplePageCaseParamEmpty)
    }
    const getSimplePageCaseLoading = ref(false)
    const casePage = ref([])
    const getSimplePageCaseResult = reactive({
        totalCount: 0,
        totalPages: 0,
    })
    const getSimplePageCase = async () => {
        if (getSimplePageCaseLoading.value) return
        getSimplePageCaseLoading.value = true
        let data = await caseGetSimplePage(getSimplePageCaseParam)
        if (data && data.list && data.list.length) {
            casePage.value = data.list
            getSimplePageCaseResult.totalPages = Math.ceil(data.totalCount / getSimplePageCaseParam.pageSize)
            getSimplePageCaseResult.totalCount = data.totalCount
        } else {
            casePage.value = []
            getSimplePageCaseResult.totalPages = 0
            getSimplePageCaseResult.totalCount = 0
        }
        getSimplePageCaseLoading.value = false
    }
    return {
        getSimplePageCaseParam,
        initGetSimplePageCaseParam,
        getSimplePageCaseLoading,
        casePage,
        getSimplePageCaseResult,
        getSimplePageCase,
    }
}

// 获取订单操作日志
function useGetHistoryListCase() {
    const caseHistoryList = ref([])
    const getCaseHistoryListLoading = ref(false)
    const getCaseHistoryList = async id => {
        if (getCaseHistoryListLoading.value) return
        getCaseHistoryListLoading.value = true
        let data = await caseGetHistoryList({id: id})
        if (data) caseHistoryList.value = data
        getCaseHistoryListLoading.value = false
    }
    return {
        caseHistoryList,
        getCaseHistoryListLoading,
        getCaseHistoryList
    }
}

// 作废订单
function useValidCase() {
    const validCaseLoading = ref(false)
    const validCaseResult = ref(false)
    const validCaseParam = reactive({
        id: 0,
        validReason: ''
    })
    const validParam = () => {
        if (!validCaseParam.id) {
            ElMessage.warning('请选择作废订单')
            return false
        }
        if (!validCaseParam.validReason) {
            ElMessage.warning('请填写作废原因')
            return false
        }
        return true
    }
    const validCase = async () => {
        if (!validParam()) return
        validCaseResult.value = false
        if (validCaseLoading.value) return
        validCaseLoading.value = true
        validCaseResult.value = await caseValid(validCaseParam)
        validCaseLoading.value = false
    }
    return {
        validCaseLoading,
        validCaseResult,
        validCaseParam,
        validCase,
    }
}

/**
 * 作废订单
 * @returns
 */
function useCancelValidCase() {
    const cancelValidCaseLoading = ref(false)
    const cancelValidCaseResult = ref(false)
    const cancelValidCaseParam = reactive({
        id: 0
    })
    const cancelValidParam = () => {
        if (!cancelValidCaseParam.id) {
            ElMessage.warning('请选择取消作废订单')
            return false
        }
        return true
    }
    const cancelValidCase = async () => {
        if (!cancelValidParam()) return
        cancelValidCaseResult.value = false
        if (cancelValidCaseLoading.value) return
        cancelValidCaseLoading.value = true
        cancelValidCaseResult.value = await caseCancelValid(cancelValidCaseParam)
        cancelValidCaseLoading.value = false
    }
    return {
        cancelValidCaseLoading,
        cancelValidCaseResult,
        cancelValidCaseParam,
        cancelValidCase,
    }
}

/**
 * 修改订单财务审核状态
 * @returns
 */
function useUpdateFinanceAuditCase() {
    const updateFinanceAuditCaseLoading = ref(false)
    const updateFinanceAuditCaseResult = ref(false)
    const updateFinanceAuditCaseParam = reactive({
        id: 0,
        financeAuditState: auditStateEnum.待审核
    })
    const validParam = () => {
        if (!updateFinanceAuditCaseParam.id) {
            ElMessage.warning('请选择订单')
            return false
        }
        return true
    }
    const updateFinanceAuditCaseCase = async () => {
        if (!validParam()) return
        updateFinanceAuditCaseResult.value = false
        if (updateFinanceAuditCaseLoading.value) return
        updateFinanceAuditCaseLoading.value = true
        updateFinanceAuditCaseResult.value = await caseUpdateFinanceAudit(updateFinanceAuditCaseParam)
        updateFinanceAuditCaseLoading.value = false
    }
    return {
        updateFinanceAuditCaseLoading,
        updateFinanceAuditCaseResult,
        updateFinanceAuditCaseParam,
        updateFinanceAuditCaseCase,
    }
}

// 修改延迟原因
function useUpdateDelayCase() {
    const updateDelayCaseLoading = ref(false)
    const updateDelayCaseResult = ref(false)
    const updateDelayCaseParam = reactive({
        id: 0,
        delayReason: ''
    })
    const updateDelayCase = async id => {
        updateDelayCaseResult.value = false
        if (updateDelayCaseLoading.value) return
        updateDelayCaseLoading.value = true
        updateDelayCaseResult.value = await caseUpdateDelay(updateDelayCaseParam)
        updateDelayCaseLoading.value = false
    }
    return {
        updateDelayCaseLoading,
        updateDelayCaseResult,
        updateDelayCaseParam,
        updateDelayCase,
    }
}

// 导出订单列表
const exportCaseExcel = req => {
    if (!req.inDate && !req.inEndDate && !req.outBeginDate && !req.outEndDate && !req.wearBeginDate && !req.wearEndDate) {
        ElMessage.error('导出Excel至少需要选择入件日期、出件日期或戴牙日期')
        return
    }
    req.caseStateStr = req.caseState.join(',')
    req.caseTypeStr = req.caseType.join(',')
    req.token = sessionStorage.getItem('token')
    let url = caseExportExcel + '?' + http.getQueryFromJson(req)
    window.open(url, '_blank')
}
// 导出自定义订单报表
const exportCaseTemplateExcel = req => {
    if (!req.inDate && !req.inEndDate && !req.outBeginDate && !req.outEndDate && !req.wearBeginDate && !req.wearEndDate) {
        ElMessage.error('导出Excel至少需要选择入件日期、出件日期或戴牙日期')
        return
    }
    req.caseStateStr = req.caseState.join(',')
    req.caseTypeStr = req.caseType.join(',')
    req.token = sessionStorage.getItem('token')
    let url = caseTemplateExcel + '?' + http.getQueryFromJson(req)
    window.open(url, '_blank')
}

// 出货
const useCaseOut = () => {
    const caseOutParam = reactive({
        id: 0,
        outDate: ''
    })
    const validParam = () => {
        if (!caseOutParam.id) {
            ElMessage.warning('请选择作废订单')
            return false
        }
        if (!caseOutParam.outDate) {
            ElMessage.warning('请填写出货日期')
            return false
        }
        return true
    }
    const outCaseLoading = ref(false)
    const outCaseResult = ref(false)
    const outCase = async () => {
        if (!validParam()) return
        if (outCaseLoading.value) return
        outCaseLoading.value = true
        outCaseResult.value = false
        outCaseResult.value = await caseOut(caseOutParam)
        outCaseLoading.value = false
    }
    return {
        caseOutParam,
        outCaseResult,
        outCaseLoading,
        outCase
    }
}

/**
 * 获取订单制作类型文本
 * @param caseInfo
 * @returns {string|*}
 */
function getCaseMakeTypeStr(caseInfo) {
    let curType = caseType.find(x => x.id === caseInfo.caseType)
    if (!curType) return ''
    if (curType.name === '正常') {
        if (!!caseInfo.isTry) {
            let curTryState = tryState.find(x => x.id === caseInfo.tryState)
            if (!curTryState) {
                return '试戴'
            } else {
                return curTryState.name
            }
        } else {
            return curType.name
        }
    } else {
        return curType.name
    }
}

function useGetCaseForPhoto() {
    const caseForPhoto = reactive({
        id: '',
        caseNo: '',
        facHospitalName: '',
        facDoctorName: '',
        facWorkerName: '',
        boxNo: ''
    })
    const emptyCaseForPhoto = {}
    Object.assign(emptyCaseForPhoto, caseForPhoto)
    const initCaseForPhoto = () => {
        Object.assign(caseForPhoto, emptyCaseForPhoto)
    }
    const getCaseForPhotoLoading = ref(false)
    const getCaseForPhoto = async caseNo => {
        if (getCaseForPhotoLoading.value) return
        getCaseForPhotoLoading.value = true
        let res = await caseGetForPhoto({caseNo: caseNo})
        if (res) {
            Object.assign(caseForPhoto, res)
        } else {
            initCaseForPhoto()
        }
        getCaseForPhotoLoading.value = false
    }
    return {
        initCaseForPhoto,
        getCaseForPhotoLoading,
        getCaseForPhoto,
        caseForPhoto,
    }
}

function useAddCaseFile() {
    const addCaseFileParam = reactive({
        facCaseId: '',
        fileId: '',
        fileUse: '',
        miniFileId: '',
        casePicType: ''
    })
    const addCaseFileLoading = ref(false)
    const addCaseFile = async () => {
        addCaseFileLoading.value = true
        await caseFileAdd(addCaseFileParam)
        addCaseFileLoading.value = false
    }
    return {
        addCaseFileLoading,
        addCaseFileParam,
        addCaseFile,
    }
}

function useDeleteCaseFile() {
    const deleteCaseFileParam = reactive({
        facCaseId: '',
        fileId: '',
    })
    const deleteCaseFileLoading = ref(false)
    const deleteCaseFile = async () => {
        deleteCaseFileLoading.value = true
        await caseFileDelete(deleteCaseFileParam)
        deleteCaseFileLoading.value = false
    }
    return {
        deleteCaseFileLoading,
        deleteCaseFileParam,
        deleteCaseFile,
    }
}

// 延迟发货订单列表
function useGetLateCaseList() {
    const lateCaseList = ref([])
    const getLateCaseListLoading = ref(false)
    const getLateCaseList = async () => {
        if (getLateCaseListLoading.value) return
        getLateCaseListLoading.value = true
        let res = await caseGetLateList()
        if (res) {
            lateCaseList.value = res
        } else {
            lateCaseList.value = []
        }
        getLateCaseListLoading.value = false
    }
    return {
        lateCaseList,
        getLateCaseListLoading,
        getLateCaseList
    }
}

//退货
function useReturnCase() {
    const returnCaseParam = reactive({
        id: null,
        returnDate: '',
        returnReason: ''
    })
    const returnCaseLoading = ref(false)
    const returnCaseResult = ref(false)
    const returnCase = async () => {
        if (returnCaseLoading.value) return
        returnCaseResult.value = false
        returnCaseLoading.value = true
        returnCaseResult.value = await caseReturn(returnCaseParam)
        returnCaseLoading.value = false
    }
    return {
        returnCaseParam,
        returnCase,
        returnCaseResult,
        returnCaseLoading
    }
}

function useModCaseRemark() {
    const modCaseRemarkParam = reactive({
        id: null,
        isOralScan: 0,
    })
    const modCaseRemarkLoading = ref(false)
    const modCaseRemarkResult = ref(false)
    const modCaseRemark = async () => {
        if (modCaseRemarkLoading.value) return
        modCaseRemarkResult.value = false
        modCaseRemarkLoading.value = true
        modCaseRemarkResult.value = await caseModRemark(modCaseRemarkParam)
        modCaseRemarkLoading.value = false
    }
    return {
        modCaseRemarkParam,
        modCaseRemark,
        modCaseRemarkResult,
        modCaseRemarkLoading
    }
}

/**
 * 导出应出货列表
 * @param req
 */
const exportLateCaseExcel = req => {
    req.token = sessionStorage.getItem('token')
    let url = caseGetLateExcel + '?' + http.getQueryFromJson(req)
    window.open(url, '_blank')
}

export {
    useSaveCase,
    useGetCase,
    useGetPageCase,
    useGetSimplePageCase,
    useValidCase,
    useCancelValidCase,
    useUpdateDelayCase,
    useGetHistoryListCase,
    exportCaseExcel,
    exportCaseTemplateExcel,
    useCaseOut,
    getCaseMakeTypeStr,
    useGetCaseForPhoto,
    useAddCaseFile,
    useDeleteCaseFile,
    useGetLateCaseList,
    useReturnCase,
    useModCaseRemark,
    useUpdateFinanceAuditCase,
    exportLateCaseExcel,
    useGetNumBySource
}