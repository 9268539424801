<template>
    <el-date-picker v-model="searchDateRange" type="daterange"
                    @change="updateOuter" @blur="updateOuter" @keydown.enter="updateOuter"
                    value-format="YYYY-MM-DD HH:mm:ss" :shortcuts="rangeShortcuts"
                    :default-time="dateRangeDefaultTime" start-placeholder="开始日期"
                    end-placeholder="结束日期"></el-date-picker>
</template>
<!--时间区间用法示例-->
<!--<date-range-picker v-model:begin-date="getRedoSumParam.beginDate"-->
<!--                   v-model:end-date="getRedoSumParam.endDate"-->
<!--                   ></date-range-picker>-->
<script setup>
import {ref, reactive, onMounted, watch} from 'vue'
import {dateRangeDefaultTime} from "@/util/formatter";
import {rangeShortcuts} from "@/util/constant";
import {debounce} from "@/util/common";
import moment from 'moment'

const props = defineProps({
    beginDate: String,//开始日期
    endDate: String,//结束日期
})
const emits = defineEmits(['update:beginDate', 'update:endDate'])

const innerBeginDate = ref('')
const innerEndDate = ref('')
watch(() => props.beginDate, (n, o) => {
    innerBeginDate.value = n
    updateFromOuter()
})
watch(() => props.endDate, (n, o) => {
    innerEndDate.value = n
    updateFromOuter()
})
const updateFromOuter = debounce(() => {
    searchDateRange.value = [innerBeginDate.value, innerEndDate.value]
}, 100)

const searchDateRange = ref([])
const updateOuter = () => {
    let newVal = searchDateRange.value
    if (newVal && newVal.length) {
        innerBeginDate.value = newVal[0]
        innerEndDate.value = newVal[1]
        if (innerEndDate.value.endsWith("00")) {
            innerEndDate.value = moment(innerEndDate.value).format('yyyy-MM-DD 23:59:59')
        }
        emits('update:beginDate', innerBeginDate.value)
        emits('update:endDate', innerEndDate.value)
    } else {
        innerBeginDate.value = ''
        innerEndDate.value = ''
        emits('update:beginDate', innerBeginDate.value)
        emits('update:endDate', innerEndDate.value)
    }
}
</script>