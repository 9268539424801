import http from "@/util/axios";
// 职位
export const jobSave = data => http.factoryPost('facJob/save', data)
export const jobDelete = data => http.factoryPost('facJob/delete', data)
export const jobGetList = data => http.factoryGet('facJob/get_list', data)
// 技术等级
export const techLevelSave = data => http.factoryPost('facTechLevel/save', data)
export const techLevelDelete = data => http.factoryPost('facTechLevel/delete', data)
export const techLevelGetList = data => http.factoryGet('facTechLevel/get_list', data)
// 部门
export const facDeptSave = data => http.factoryPost('facDept/save', data)
export const facDeptDelete = data => http.factoryPost('facDept/delete', data)
export const facDeptSort = data => http.factoryPost('facDept/sort', data)
export const facDeptGetList = data => http.factoryGet('facDept/get_list', data)
export const facDeptSaveStepList = data => http.factoryPost('facDept/save_step_list', data)
export const facDeptGetStepList = data => http.factoryGet('facDept/get_step_list', data)
// 员工
export const facWorkerSave = data => http.factoryPost('facWorker/save', data)
export const facWorkerDelete = data => http.factoryPost('facWorker/delete', data)
export const facWorkerGetSimpleList = data => http.factoryGet('facWorker/get_simple_list', data)
export const facWorkerGetPage = data => http.factoryGet('facWorker/get_page', data)
export const facWorkerUpdateValid = data => http.factoryPost('facWorker/update_valid', data)
export const facWorkerBatchValid = data => http.factoryPost('facWorker/batch_valid', data)
export const facWorkerGetUser = data => http.factoryGet('facWorker/get_user', data)
export const facWorkerGet = data => http.factoryGet('facWorker/get', data)
export const facWorkerExcelList = '/api/factory/factoryInfoExport/excel_worker_list'
// 员工合同
export const facWorkerContractSave = data => http.factoryPost('facWorkerContract/save', data)
export const facWorkerContractDelete = data => http.factoryPost('facWorkerContract/delete', data)
export const facWorkerContractUpdateValid = data => http.factoryPost('facWorkerContract/update_valid', data)
export const facWorkerContractGetList = data => http.factoryGet('facWorkerContract/get_list', data)
// 员工健康证
export const facWorkerHealthSave = data => http.factoryPost('facWorkerHealth/save', data)
export const facWorkerHealthDelete = data => http.factoryPost('facWorkerHealth/delete', data)
export const facWorkerHealthUpdateValid = data => http.factoryPost('facWorkerHealth/update_valid', data)
export const facWorkerHealthGetList = data => http.factoryGet('facWorkerHealth/get_list', data)
// 停薪留职
export const facWorkerStopSalarySave = data => http.factoryPost('facWorkerStopSalary/save', data)
export const facWorkerStopSalaryDelete = data => http.factoryPost('facWorkerStopSalary/delete', data)
export const facWorkerStopSalaryUpdateValid = data => http.factoryPost('facWorkerStopSalary/update_valid', data)
export const facWorkerStopSalaryGetList = data => http.factoryGet('facWorkerStopSalary/get_list', data)
// 员工培训
export const facTrainSave = data => http.factoryPost('facTrain/save', data)
export const facTrainDelete = data => http.factoryPost('facTrain/delete', data)
export const facTrainGetList = data => http.factoryGet('facTrain/get_list', data)
export const facTrainGetPage = data => http.factoryGet('facTrain/get_page', data)
export const facTrainExcelList = '/api/factory/factoryInfoExport/excel_train_list'