import {reactive, ref, inject, toRaw} from 'vue'
import moment from 'moment'
import {
    stockNowGetPage,
    stockNowUpdateSafe,
    stockNowUpdateHigh,
    stockNowUpdateLow,
    stockNowGetAllDetail,
    stockNowExportExcel,
    stockNowDetailExportExcel,
    inventoryExportExcel,
    stockNowDetailGetPage,
    stockMaterialGetByLotNo, stockNowUpdateStorePlace
} from "@/api/warehouse";
import {ElMessage} from "element-plus";
import {useStore} from "vuex";
import http from "@/util/axios";

// 当期库存分页
function useGetPageStockNow() {
    const getPageStockNowParam = reactive({
        materialId: null,
        warehouseId: null,
        pageIndex: 1,
        pageSize: 10,
    })
    const getPageStockNowParamEmpty = JSON.parse(JSON.stringify(toRaw(getPageStockNowParam)))
    const setFields = inject('setFields')
    const initGetPageStockNowParam = () => {
        setFields(getPageStockNowParam, getPageStockNowParamEmpty)
    }
    const getPageStockNowLoading = ref(false)
    const stockNowPage = ref([])
    const getPageStockNowResult = reactive({
        totalCount: 0,
        totalPages: 0,
    })
    const getPageStockNow = async () => {
        if (getPageStockNowLoading.value) return
        getPageStockNowLoading.value = true
        let data = await stockNowGetPage(getPageStockNowParam)
        if (data && data.list && data.list.length) {
            stockNowPage.value = data.list
            getPageStockNowResult.totalPages = Math.ceil(data.totalCount / getPageStockNowParam.pageSize)
            getPageStockNowResult.totalCount = data.totalCount
        } else {
            stockNowPage.value = []
            getPageStockNowResult.totalPages = 0
            getPageStockNowResult.totalCount = 0
        }
        getPageStockNowLoading.value = false
    }
    return {
        getPageStockNowParam,
        initGetPageStockNowParam,
        getPageStockNowLoading,
        stockNowPage,
        getPageStockNowResult,
        getPageStockNow,
    }
}

// 修改富裕库存
function useUpdateHighStockNow() {
    const updateHighStockNowLoading = ref(false)
    const updateHighStockNowResult = ref(false)
    const updateHighStockNow = async param => {
        if (updateHighStockNowLoading.value) return
        updateHighStockNowLoading.value = true
        updateHighStockNowResult.value = false
        updateHighStockNowResult.value = await stockNowUpdateHigh(param)
        updateHighStockNowLoading.value = false
    }
    return {
        updateHighStockNow,
        updateHighStockNowResult,
        updateHighStockNowLoading
    }
}

// 修改安全库存
function useUpdateSafeStockNow() {
    const updateSafeStockNowLoading = ref(false)
    const updateSafeStockNowResult = ref(false)
    const updateSafeStockNow = async param => {
        if (updateSafeStockNowLoading.value) return
        updateSafeStockNowLoading.value = true
        updateSafeStockNowResult.value = false
        updateSafeStockNowResult.value = await stockNowUpdateSafe(param)
        updateSafeStockNowLoading.value = false
    }
    return {
        updateSafeStockNow,
        updateSafeStockNowResult,
        updateSafeStockNowLoading
    }
}

// 修改储位
function useUpdateStorePlaceStockNow() {
    const updateStorePlaceStockNowLoading = ref(false)
    const updateStorePlaceStockNowResult = ref(false)
    const updateStorePlaceStockNow = async param => {
        if (updateStorePlaceStockNowLoading.value) return
        updateStorePlaceStockNowLoading.value = true
        updateStorePlaceStockNowResult.value = false
        updateStorePlaceStockNowResult.value = await stockNowUpdateStorePlace(param)
        updateStorePlaceStockNowLoading.value = false
    }
    return {
        updateStorePlaceStockNow,
        updateStorePlaceStockNowResult,
        updateStorePlaceStockNowLoading
    }
}

// 修改最低库存
function useUpdateLowStockNow() {
    const updateLowStockNowLoading = ref(false)
    const updateLowStockNowResult = ref(false)
    const updateLowStockNow = async param => {
        if (updateLowStockNowLoading.value) return
        updateLowStockNowLoading.value = true
        updateLowStockNowResult.value = false
        updateLowStockNowResult.value = await stockNowUpdateLow(param)
        updateLowStockNowLoading.value = false
    }
    return {
        updateLowStockNow,
        updateLowStockNowResult,
        updateLowStockNowLoading
    }
}

// 获取某个仓库当前所有库存明细
function useGetAllStockNowDetail() {
    const getAllStockNowDetailLoading = ref(false)
    const stockNowDetails = ref([])
    const getAllStockNowDetails = async id => {
        let data = await stockNowGetAllDetail({id: id})
        if (data) {
            stockNowDetails.value = data
        }
    }
    return {
        getAllStockNowDetailLoading,
        stockNowDetails,
        getAllStockNowDetails,
    }
}

function useGetPageStockNowDetail() {
    const getPageStockNowDetailParam = reactive({
        materialId: null,
        warehouseId: null,
        lotNo: '',
        pageIndex: 1,
        pageSize: 10,
    })
    const getPageStockNowDetailParamEmpty = JSON.parse(JSON.stringify(toRaw(getPageStockNowDetailParam)))
    const setFields = inject('setFields')
    const initGetPageStockNowDetailParam = () => {
        setFields(getPageStockNowDetailParam, getPageStockNowDetailParamEmpty)
    }
    const getPageStockNowDetailLoading = ref(false)
    const stockNowDetailPage = ref([])
    const getPageStockNowDetailResult = reactive({
        totalCount: 0,
        totalPages: 0,
    })
    const getPageStockNowDetail = async () => {
        if (getPageStockNowDetailLoading.value) return
        getPageStockNowDetailLoading.value = true
        let data = await stockNowDetailGetPage(getPageStockNowDetailParam)
        if (data && data.list && data.list.length) {
            stockNowDetailPage.value = data.list
            getPageStockNowDetailResult.totalPages = Math.ceil(data.totalCount / getPageStockNowDetailParam.pageSize)
            getPageStockNowDetailResult.totalCount = data.totalCount
        } else {
            stockNowDetailPage.value = []
            getPageStockNowDetailResult.totalPages = 0
            getPageStockNowDetailResult.totalCount = 0
        }
        getPageStockNowDetailLoading.value = false
    }
    return {
        getPageStockNowDetailParam,
        initGetPageStockNowDetailParam,
        getPageStockNowDetailLoading,
        stockNowDetailPage,
        getPageStockNowDetailResult,
        getPageStockNowDetail,
    }
}

// 根据批号获取材料简单列表
const useGetStockMaterialByLotNo = () => {
    const simpleStockMaterialList = ref([])
    const getStockMaterialByLotNoLoading = ref(false)
    const getStockMaterialByLotNo = async lotNo => {
        if (!lotNo) return
        if (getStockMaterialByLotNoLoading.value) return
        getStockMaterialByLotNoLoading.value = true
        let res = await stockMaterialGetByLotNo({lotNo: lotNo})
        if (res && res.length) {
            simpleStockMaterialList.value = res
        } else {
            simpleStockMaterialList.value = []
        }
        getStockMaterialByLotNoLoading.value = false
    }
    return {
        simpleStockMaterialList,
        getStockMaterialByLotNoLoading,
        getStockMaterialByLotNo,
    }
}

const exportStockNowExcel = req => {
    req.token = sessionStorage.getItem('token')
    let url = stockNowExportExcel + '?' + http.getQueryFromJson(req)
    window.open(url, '_blank')
}

const exportInventory = req => {
    if (!req || !req.warehouseId) {
        ElMessage.error('请选择仓库')
        return
    }
    req.token = sessionStorage.getItem('token')
    let url = inventoryExportExcel + '?' + http.getQueryFromJson(req)
    window.open(url, '_blank')
}

const exportStockNowDetailExcel = req => {
    req.token = sessionStorage.getItem('token')
    let url = stockNowDetailExportExcel + '?' + http.getQueryFromJson(req)
    window.open(url, '_blank')
}

export {
    useGetPageStockNow,
    useUpdateSafeStockNow,
    useUpdateLowStockNow,
    useUpdateHighStockNow,
    useUpdateStorePlaceStockNow,
    useGetAllStockNowDetail,
    exportStockNowExcel,
    exportInventory,
    exportStockNowDetailExcel,
    useGetPageStockNowDetail,
    useGetStockMaterialByLotNo,
}