<template>
    <el-autocomplete title="可以按照名称、拼音首字母和编号搜索" v-model.trim="inputHospitalInfo" value-key="name" clearable
                     placeholder="请选择客户" @select="chooseInputHospital" select-when-unmatched
                     highlight-first-item @blur="blurHospital"
                     :fetch-suggestions="fetchHospital">
        <template #default="{ item }">
            <div style="display: flex;justify-content:space-between;">
                <b>{{ item.name }}</b>
                <span style="float: right;color: var(--el-text-color-secondary);font-size: 13px;">{{
                        item.code
                    }}</span>
            </div>
        </template>

    </el-autocomplete>
    <!--    <el-select size="default" v-model="innerHospitalId" filterable title="可以按照名称、拼音首字母和编号搜索"-->
    <!--               :filter-method="filterHospital" @change="changeHospitalId" default-first-option-->
    <!--               @visible-change="hospitalVisibleChange" placeholder="选择医院"-->
    <!--               clearable>-->
    <!--        <el-option v-for="item in searchHospitalList" :key="item.id" :value="item.id"-->
    <!--                   :label="item.name">-->
    <!--            <span style="float: left">{{ item.name }}</span>-->
    <!--            <span-->
    <!--                style="float: right;color: var(&#45;&#45;el-text-color-secondary);font-size: 13px;">{{-->
    <!--                    item.code-->
    <!--                }}</span>-->
    <!--        </el-option>-->
    <!--    </el-select>-->
</template>
<!--示例-->
<!--
<hospital-select v-model:hospital-id="hospitalId"></hospital-select>
-->
<script setup>
import {useStore} from "vuex";
import {ref, reactive, onMounted, watch, toRef, defineProps, defineEmits, computed} from 'vue'
import {commonListDefaultFilter, commonListFilter} from "@/util/formatter";

const props = defineProps({
    hospitalId: Number,
    hospitalIds: Array,
    hospitalList: Array
})
const emits = defineEmits(['update:hospitalId', 'changeHospital'])
watch(() => props.hospitalId, (n, o) => {
    if (n) {
        innerHospitalId.value = parseInt(n)
        let hospital = store.state.hospitalList.find(x => x.id === n)
        hospital && (inputHospitalInfo.value = hospital.name)
    } else {
        innerHospitalId.value = null
        inputHospitalInfo.value = ''
        //外部主动清空数据时 进行一次排序
        sortHospital()
    }
})
const store = useStore()
const innerHospitalId = ref()
const inputHospitalInfo = ref('')
let storeHospitalList = null
const sortHospital = () => {
    storeHospitalList.sort((a, b) => {
        if (b.count === a.count) {
            return b.id - a.id
        }
        return b.count - a.count
    })
}
watch(() => props.hospitalIds, (n, o) => {
    if (n && n.length) {
        storeHospitalList = store.state.hospitalList.filter(x => n.includes(x.id))
    } else {
        storeHospitalList = store.state.hospitalList
    }
})
// 输入框
const fetchHospital = (str, cb) => {
    let res = []
    if (str) {
        res = storeHospitalList.filter(commonListFilter(str))
    } else {
        res = storeHospitalList.filter(commonListDefaultFilter)
    }
    cb(res)
}
const chooseInputHospital = item => {
    innerHospitalId.value = (item && item.id) ? item.id : null
    changeHospitalId()
}
const blurHospital = () => {
    let emitOuter = false//是否要通知外层
    if (!inputHospitalInfo.value) {
        if (innerHospitalId.value) emitOuter = true
        innerHospitalId.value = null
    } else {
        let hospital = store.state.hospitalList.find(x => x.name === inputHospitalInfo.value)
        if (hospital) {
            if (innerHospitalId.value !== hospital.id) {
                innerHospitalId.value = hospital.id
                emitOuter = true
            }
        } else {
            if (innerHospitalId.value) emitOuter = true
            innerHospitalId.value = null
        }
        //没有选择 清空输入框
        if (!innerHospitalId.value) {
            inputHospitalInfo.value = ''
        }
    }
    if (emitOuter) changeHospitalId()
}

onMounted(() => {
    if (props.hospitalId) {
        innerHospitalId.value = props.hospitalId
        let hospital = store.state.hospitalList.find(x => x.id === props.hospitalId)
        hospital && (inputHospitalInfo.value = hospital.name)
    }
    if (props.hospitalList && props.hospitalList.length) {
        storeHospitalList = props.hospitalList
    } else {
        storeHospitalList = store.state.hospitalList
    }
    sortHospital()
    if (props.hospitalIds && props.hospitalIds.length) {
        storeHospitalList = storeHospitalList.filter(x => props.hospitalIds.includes(x.id))
    }

})

const changeHospitalId = () => {
    if (innerHospitalId.value) {
        emits('update:hospitalId', innerHospitalId.value)
    } else {
        emits('update:hospitalId', null)
    }
    emits('changeHospital', innerHospitalId.value)
}

</script>

<style scoped>

</style>