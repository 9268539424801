<template>
    <div>
        <div class="view-list flex-row">
            <div class="view-left">
                <el-descriptions :column="1" border>
                    <el-descriptions-item label="扫描订单号">
                        <el-input ref="caseNoRef" v-model.trim="caseNo" maxlength="20" @click="clickCaseNo"
                                  placeholder="请扫描订单号"
                                  @keydown.enter="handleScanCaseNo"></el-input>
                    </el-descriptions-item>
                    <!--                                <el-descriptions-item label="盒号">-->
                    <!--                                    <el-input ref="boxNoRef" v-model.trim="boxNo" maxlength="20" @click="clickBoxNo"-->
                    <!--                                              placeholder="请扫描盒号" class="input-small"-->
                    <!--                                              @keydown.enter="handleScanBoxNo"></el-input>-->
                    <!--                                </el-descriptions-item>-->
                    <el-descriptions-item label="扫描工号">
                        <el-space>
                            <el-input maxlength="20" ref="workerNoRef" v-model.trim="scanWorker.workerNo"
                                      @keydown.enter="getWorkerByNo" class="w-120" placeholder="请扫描工号"
                                      @click="clickWorkerNo"></el-input>
                            <el-tag v-show="!!scanWorker.workerName ">{{ scanWorker.workerName }}</el-tag>
                            <el-tag v-show="!!scanWorker.deptName ">{{ scanWorker.deptName }}</el-tag>
                        </el-space>
                    </el-descriptions-item>
                    <!--                    <el-descriptions-item label="姓名" width="14%">-->
                    <!--                        {{ scanWorker.workerName }}-->
                    <!--                    </el-descriptions-item>-->
                    <!--                    <el-descriptions-item label="部门" width="14%">-->
                    <!--                        {{ scanWorker.deptName }}-->
                    <!--                    </el-descriptions-item>-->
                </el-descriptions>
                <el-descriptions :column="2" border>
                    <el-descriptions-item label="搜客户">
                        <hospital-select v-model:hospital-id="getPackCaseListParam.facHospitalId" class="w-df"
                                         @change-hospital="getPackCaseList"
                                         :hospital-list="storeHospitalList"></hospital-select>
                    </el-descriptions-item>
                    <el-descriptions-item label="搜销售">
                        <worker-select v-model:worker-id="getPackCaseListParam.salesmanId" class="w-df"
                                       @change-worker="getPackCaseList"
                                       :worker-list="storeWorkerList"></worker-select>
                    </el-descriptions-item>
                </el-descriptions>
                <el-tabs v-model="curTab" type="border-card" @tab-change="changeTab" class="compact-tab"
                         style="height:38px;">
                    <el-tab-pane name="all" label="全部">
                    </el-tab-pane>
                    <el-tab-pane name="hospital" label="医院">
                    </el-tab-pane>
                    <el-tab-pane name="sales" label="销售">
                    </el-tab-pane>
                    <el-tab-pane name="hospitalDept" label="科室">
                    </el-tab-pane>
                </el-tabs>
                <el-table :data="showPackList" border height="450" @row-click="chooseRow"
                          v-loading="getPackCaseListLoading" ref="packListRef"
                          highlight-current-row>
                    <el-table-column label="发货方式" prop="sendType" width="82" fixed
                                     :formatter="(r,c,v)=>enumTypeFormatter(v,sendType)"></el-table-column>
                    <el-table-column label="收货对象" fixed min-width="100">
                        <template #default="scope">
                            {{ scope.row.receiverName }}
                        </template>
                    </el-table-column>
                    <el-table-column label="收货方式" prop="receiverType" width="82" fixed
                                     :formatter="(r,c,v)=>enumTypeFormatter(v,receiverType)"></el-table-column>
                    <el-table-column label="装箱日期" prop="packDate" width="98"
                                     :formatter="shortDateFormat"></el-table-column>
                    <el-table-column label="数量" width="55" prop="num"></el-table-column>
                    <el-table-column label="快递公司" width="90" prop="expressCompany">
                    </el-table-column>
                    <el-table-column label="收货人" width="90" prop="contactPerson">
                    </el-table-column>
                </el-table>
                <div class="flex-row">
                    <img id="view1" alt="主摄像头" v-show="device.isConnect==='已连接'">
                    <el-image class="scan-view" v-if="device.isConnect!=='已连接'"/>
                    <div class="flex-grow flex-col el-border-right">
                        <el-divider>扫描订单号后</el-divider>
                        <el-space>
                            <el-checkbox border v-model="casePackOutSetting.printCaseOut" label="打印发货单"
                                         @change="changeCasePackOutSetting"
                                         :false-label="0" :true-label="1"></el-checkbox>
                            <el-checkbox border v-model="casePackOutSetting.printWarranty" label="打印质保卡"
                                         @change="changeCasePackOutSetting"
                                         :false-label="0" :true-label="1"></el-checkbox>
                        </el-space>
                        <el-checkbox-group v-model="casePackOutSetting.printLabelIds"
                                           @change="changeCasePackOutSetting">
                            <el-checkbox border v-for="item in labelTemplateList" :key="item.id" :label="item.id">
                                {{ item.name }}
                            </el-checkbox>
                        </el-checkbox-group>
                    </div>
                </div>
                <el-space :size="1">
                    <el-button title="刷新高拍仪设备和镜头状态" :icon="Refresh" @click="getDeviceInfo">刷新设备状态</el-button>
                    <el-button title="高拍仪镜头向左旋转" @click="rotate(90)">镜头左转</el-button>
                    <el-button title="高拍仪镜头向右旋转" @click="rotate(270)">镜头右转</el-button>
                    <el-checkbox border v-model="casePackOutSetting.autoScan" label="扫描后高拍仪自动拍照"
                                 @change="changeAutoScan" title="目前只支持良田高拍仪"
                                 :false-label="0" :true-label="1"></el-checkbox>
                </el-space>
                <el-descriptions border :column="2">
                    <el-descriptions-item label="设备状态">
                        {{ device.isConnect }}
                    </el-descriptions-item>
                    <el-descriptions-item label="设备型号">
                        {{ device.version }}
                    </el-descriptions-item>
                    <el-descriptions-item label="主摄状态">
                        {{ device.videoStatus }}
                    </el-descriptions-item>
                </el-descriptions>
            </div>
            <div class="view-list-right flex-grow">
                <el-space>
                    <el-button size="large" type="primary" :icon="Van" @click="handlePackOut"
                               :loading="packCaseOutLoading || getHospitalProductPriceListLoading">装箱发货
                    </el-button>
                    <span style="font-size: 15px;width: 140px;">装箱发货后自动操作:</span>
                    <el-space :size="0">
                        <el-checkbox border v-model="casePackOutSetting.packCaseOut" label="打印发货单"
                                     @change="changeCasePackOutSetting"
                                     :false-label="0" :true-label="1"></el-checkbox>
                        <el-checkbox border v-model="casePackOutSetting.packWarranty" label="打印质保卡"
                                     @change="changeCasePackOutSetting"
                                     :false-label="0" :true-label="1"></el-checkbox>
                        <el-checkbox border v-model="casePackOutSetting.createSfOrder" label="顺丰下单"
                                     @change="changeCasePackOutSetting"
                                     :false-label="0" :true-label="1"></el-checkbox>
                        <el-checkbox v-show="!!casePackOutSetting.createSfOrder" border
                                     v-model="casePackOutSetting.printSfOrder" label="顺丰单打印"
                                     @change="changeCasePackOutSetting"
                                     :false-label="0" :true-label="1"></el-checkbox>
                        <el-checkbox-group v-model="casePackOutSetting.packLabelIds" @change="changeCasePackOutSetting"
                                           class="compact-checkbox">
                            <el-checkbox border v-for="item in labelTemplateList" :key="item.id" :label="item.id">
                                {{ item.name }}
                            </el-checkbox>
                        </el-checkbox-group>
                    </el-space>
                </el-space>
                <el-table style="width: 100%" :data="curRow.list" border v-loading="getCasePackDetailListLoading">
                    <el-table-column fixed width="100" class-name="compact-cell">
                        <template #default="scope">
                            <el-button-group>
                                <el-button @click="showCaseInfo(scope.row.id)" size="small" type="success"
                                           title="查看订单详情">查看
                                </el-button>
                                <el-button @click="handleCancelCase(scope.row)" size="small" type="warning"
                                           title="将订单撤销装箱">撤销
                                </el-button>
                            </el-button-group>
                        </template>
                    </el-table-column>
                    <el-table-column type="index" width="55" label="序号" fixed></el-table-column>
                    <el-table-column label="订单号" prop="caseNo" class-name="compact-cell" width="105"
                                     fixed></el-table-column>
                    <el-table-column label="客户" prop="facHospitalName" class-name="compact-cell" width="115"
                                     fixed></el-table-column>
                    <el-table-column label="医生" prop="facDoctorName" class-name="compact-cell" width="85"
                                     fixed></el-table-column>
                    <el-table-column label="患者" prop="patientName" class-name="compact-cell" width="85"
                                     fixed></el-table-column>
                    <el-table-column label="销售员" prop="facWorkerName" class-name="compact-cell"
                                     width="85"></el-table-column>
                    <el-table-column label="产品" prop="productName" width="120"></el-table-column>
                    <el-table-column label="发货类型" prop="sendType" width="90"
                                     :formatter="(r,c,v)=>enumTypeFormatter(v,sendType)"></el-table-column>
                    <el-table-column label="收货对象" prop="receiverType" width="90"
                                     :formatter="(r,c,v)=>enumTypeFormatter(v,receiverType)"></el-table-column>
                    <el-table-column label="装箱日期" prop="packDate" width="98" :formatter="dateFormat"></el-table-column>
                    <el-table-column label="制作类型" prop="makeTypeStr" width="85"></el-table-column>
                    <el-table-column label="盒号" prop="boxNo" width="70"></el-table-column>
                    <el-table-column label="入件日期" prop="inDate" width="98" :formatter="dateFormat"></el-table-column>
                    <el-table-column label="客户单号" prop="hospitalCaseNo" width="110"></el-table-column>
                </el-table>
            </div>
        </div>

        <el-dialog v-model="caseVisible" title="订单详情" width="1200px">
            <case-view :id="caseId" @get-case-info="setCaseInfo"></case-view>
            <template #footer>
                <el-button @click="showPrintCaseOut(true,caseInfo)" type="primary"
                           plain>出货单
                </el-button>
                <el-button @click="showPrintWarranty(caseInfo)" type="success"
                           plain>
                    质保卡
                </el-button>
                <el-select v-model="labelProductId" placeholder="选择标签订单产品"
                           class="w-120">
                    <el-option v-for="product in caseInfo.productList" :key="product.productId"
                               :value="product.productId"
                               :label="product.productName"></el-option>
                </el-select>
                <el-button v-for="item in labelTemplateList" :key="item.id"
                           @click="showPrintCaseLabel(item.id)">
                    {{ item.name }}
                </el-button>
            </template>
        </el-dialog>

        <el-dialog v-model="printCaseOutVisible" title="打印出货单" width="430px">
            <el-alert type="error" v-if="!caseOutTemplateList.length" title="请到设置-订单打印格式-出货单设置页面设置确认单格式"></el-alert>
            <el-form>
                <el-form-item label="模板">
                    <el-select v-model="caseOutTemplateId">
                        <el-option v-for="item in caseOutTemplateList" :key="item.id" :value="item.id"
                                   :label="item.name"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button type="primary" :icon="Printer" @click="handlePrintCaseOut"
                           :disabled="!caseOutTemplateList.length">
                    确认打印
                </el-button>
                <el-button type="info" @click='printCaseOutVisible=false'>
                    关闭
                </el-button>
            </template>
        </el-dialog>
        <el-dialog v-model="printWarrantyVisible" title="打印质保卡" width="430px">
            <el-alert type="error" v-if="!warrantyTemplateList.length" title="请到设置-订单打印格式-质保卡设置页面设置质保卡格式"></el-alert>
            <el-form>
                <el-form-item label="产品">
                    <el-select v-model="warrantyProductId" @change="setDefaultWarranty">
                        <el-option v-for="product in caseInfo.productList" :key="product.productId"
                                   :value="product.productId"
                                   :label="product.productName"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="模板">
                    <el-select v-model="warrantyTemplateId">
                        <el-option v-for="item in warrantyTemplateList" :key="item.id" :value="item.id"
                                   :label="item.name"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="自定义打印产品名称">
                    <el-input v-model.trim="warrantProductName" maxlength="50"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button type="primary" :icon="Printer" @click="handlePrintWarranty"
                           :disabled="!warrantyTemplateList.length">
                    确认打印
                </el-button>
                <el-button type="info" @click='printWarrantyVisible=false'>
                    关闭
                </el-button>
            </template>
        </el-dialog>
        <el-dialog v-model="sfOrderVisible" :title="curRow.receiverName+'-' + curRow.packNo+'顺丰下单'" width="700">
            <pack-sf-create :id="curRow.id" @success="afterCreateSfOrder"></pack-sf-create>
        </el-dialog>
        <el-dialog v-model="printVisible" :title="curRow.receiverName+'-' +curRow.packNo+'顺丰单打印'" width="700">
            <pack-sf-print :id="curRow.id" @success="afterPrintSf"></pack-sf-print>
        </el-dialog>
    </div>
</template>

<script setup>
import {ref, reactive, onMounted, watch, inject, computed} from 'vue';
import {Plus, Search, Refresh, Edit, Delete, View, Close, Printer, Picture, Van} from '@element-plus/icons-vue';
import {useStore} from 'vuex'
import {
    enumTypeFormatter,
    dateFormat,
    shortDateFormat,
} from "@/util/formatter";
import {
    caseSettingItem,
    caseTypeEnum,
    factoryWebStoreEnum,
    fileUseEnum,
    printTemplateTypeEnum,
    sendType, sendTypeEnum,
    receiverType, receiverTypeEnum
} from "@/util/enum";
import {ElMessage, ElMessageBox} from "element-plus";
import {useScanCasePack, useGetCasePackList, useGetCasePackDetailList, useCancelCasePack} from "@/use/case/useCaseOut";
import {useGetListCaseSetting} from "@/use/case/useCaseSetting";
import {usePackCaseOut} from "@/use/case/useCaseOut";
import {useGetHospitalProductPriceList} from "@/use/customer/useHospitalPrice";


const store = useStore()
const storeWorkerList = computed(() => store.state.workerList)
const currentWorker = computed(() => store.state.currentWorker)
const storeProductList = computed(() => store.state.productList)
const productDetails = computed(() => store.state.productDetails)
const storeHospitalDetails = computed(() => store.state.hospitalDetails)
const storeCasePackOutSetting = computed(() => store.state.casePackOutSetting)
const storeHospitalList = computed(() => store.state.hospitalList)
//基础设置
const updateFactoryStoreLoading = inject('updateFactoryStoreLoading')
const updateFactoryStoreType = inject('updateFactoryStoreType')
watch(updateFactoryStoreLoading, async (n, o) => {
    if (!n) {
        if (updateFactoryStoreType.value === factoryWebStoreEnum.factorySetting) {
            initFactorySetting()
        }
    }
})
let factorySetting = {
    fullName: '',
    servicePhoneNo: '',
    fullAddress: '',
}
const initFactorySetting = () => {
    if (store.state.factorySetting) {
        let setting = JSON.parse(store.state.factorySetting)
        Object.assign(factorySetting, setting)
        factorySetting.fullAddress = setting.province + setting.city + setting.area + setting.address
    }
}
const {
    casePackDetailList,
    getCasePackDetailListParam,
    getCasePackDetailListLoading,
    getStepCaseStatDetailList
} = useGetCasePackDetailList()
//设置
const {
    getListCaseSetting,
    caseSettingList
} = useGetListCaseSetting()

// 初始化打印
import {useGetPrinterList, usePrintSingle, usePrintList} from "@/use/system/usePrint";
import {hiprint} from "vue-plugin-hiprint";

const {
    successList, failList, printResList, printList,
    printListLoading, printNoScanList
} = usePrintList()
const {printSingleLoading, printSingle} = usePrintSingle()
hiprint.init()
const {
    start,
    setTemplatePrinter,
} = useGetPrinterList()
const {
    cancelCasePackLoading,
    cancelCasePackResult,
    cancelCasePack,
} = useCancelCasePack()
const {
    getHospitalProductPriceList,
    getHospitalProductPriceListLoading,
    hospitalProductList
} = useGetHospitalProductPriceList()
onMounted(async () => {
    caseNoRef.value.focus()
    initScanWorker()
    initFactorySetting()
    getPackCaseList()
    getListCaseSetting()
    getTemplatePrinterList()
    //打印机
    start(hiprint)
    //高拍仪
    if (casePackOutSetting.autoScan) {
        initLiangtianSetting()
        open_view1('view1')
        getDeviceInfo()
    }
})
//查询已装箱
const {
    getPackCaseListParam,
    packCaseList,
    getPackCaseListLoading,
    getPackCaseList,
    addPackCase
} = useGetCasePackList()
const curRow = reactive({
    sendType: '',
    receiverType: '',
    contactPerson: '',
    contactPhoneNo: '',
    expressCompany: '',
    receiverName: '',
})
const chooseRow = async row => {
    packCaseList.value.forEach(x => x.isCheck = false)
    row.isCheck = true
    Object.assign(curRow, row)
    getCasePackDetailListParam.casePackId = row.id
    await getStepCaseStatDetailList()
    curRow.list = casePackDetailList
}
//设置模板默认打印机
const getTemplatePrinterList = async () => {
    await getListCasePrintTemplate()
    if (!casePrintTemplateList.value || !casePrintTemplateList.value.length) return
    for (let item of casePrintTemplateList.value) {
        setTemplatePrinter(item)
    }
}
const labelTemplateList = computed(() => casePrintTemplateList.value.filter(x => x.printTemplateType === printTemplateTypeEnum.订单标签 && x.showCaseOut === 1))
// 打印设置
const casePackOutSetting = reactive({
    printCaseOut: 0,
    printWarranty: 0,
    printLabelIds: [],
    autoScan: 0,
    packCaseOut: 0,
    packWarranty: 0,
    createSfOrder: 0,
    printSfOrder: 0,
    packLabelIds: []
})
if (storeCasePackOutSetting && storeCasePackOutSetting.value) {
    Object.assign(casePackOutSetting, storeCasePackOutSetting.value)
}
const changeAutoScan = () => {
    if (casePackOutSetting.autoScan) {
        initLiangtianSetting()
        open_view1('view1')
        getDeviceInfo()
    }
    changeCasePackOutSetting()
}
const changeCasePackOutSetting = () => {
    store.commit('setCasePackOutSetting', casePackOutSetting)
}
// 扫描工号获取员工信息
const workerNoRef = ref()
const scanWorker = reactive({
    facWorkerId: '',
    workerName: '',
    workerNo: '',
    deptName: ''
})
const initScanWorker = () => {
    scanWorker.facWorkerId = currentWorker.value.id
    scanWorker.workerName = currentWorker.value.name
    scanWorker.workerNo = currentWorker.value.code
    scanWorker.deptName = currentWorker.value.deptName
}
const getWorkerByNo = () => {
    if (storeWorkerList.value.length) {
        let worker = storeWorkerList.value.find(x => x.code === scanWorker.workerNo)
        if (worker) {
            scanWorker.facWorkerId = worker.id
            scanWorker.workerName = worker.name
            scanWorker.workerNo = worker.code
            scanWorker.deptName = worker.deptName
        } else {
            ElMessage.error('请输入正确的工号')
            scanWorker.facWorkerId = null
            scanWorker.workerName = ''
            scanWorker.workerNo = ''
            scanWorker.deptName = ''
        }
    } else {
        ElMessage.error('系统当前没有设置员工')
    }
}
const clickWorkerNo = () => {
    if (scanWorker.workerNo) {
        workerNoRef.value.select()
    }
}
// 扫描订单装箱获取订单信息
const {
    scanCasePackLoading,
    scanCasePackParam,
    casePackInfo,
    scanCasePack,
} = useScanCasePack()
const caseNoRef = ref()
const caseNo = ref('')
const clickCaseNo = () => {
    if (caseNo.value) {
        caseNoRef.value.select()
    }
}
let storeHospitalDetail = null
import {useGetCase} from "@/use/case/useCase";

const {
    getCaseByNo,
    caseInfo
} = useGetCase()
const handleScanCaseNo = async () => {
    storeHospitalDetail = null
    if (!scanWorker.facWorkerId) {
        ElMessage.error('请先扫描工号')
        workerNoRef.value.focus()
        return
    }
    if (!caseNo.value) {
        ElMessage.error('请扫描订单号')
        return
    }
    scanCasePackParam.caseNo = caseNo.value
    await scanCasePack()
    if (!casePackInfo.id) {
        ElMessage.error('请扫描正确的订单号')
        return
    }

    addPackCase(casePackInfo)
    chooseRow(casePackInfo)
    let row = packCaseList.value.find(x => x.id === casePackInfo.id)
    packListRef.value && packListRef.value.setCurrentRow(row)
    await handleCaseAfterPack()
    caseNo.value = ''
    caseNoRef.value.focus()
}
const packListRef = ref()
// 扫描订单装箱后打印和拍照
const handleCaseAfterPack = async () => {
    await getCaseByNo(caseNo.value)
    storeHospitalDetail = storeHospitalDetails.value.find(x => x.id === caseInfo.facHospitalId)
    if (storeHospitalDetail) {
        storeHospitalDetail.priceList = await getHospitalProductPrice(caseInfo.facHospitalId)
    }
    printResList.value = []
    if (casePackOutSetting.printCaseOut === 1) {
        showPrintCaseOut(false, caseInfo)
    }
    if (casePackOutSetting.printWarranty === 1) {
        let warrantySetting = caseSettingList.value.find(x => x.settingItem === caseSettingItem.返工返修订单不打印质保卡)
        if (warrantySetting && warrantySetting.intResult && (caseInfo.caseType === caseTypeEnum.返工 || caseInfo.caseType === caseTypeEnum.返修)) {
            //返工返修订单不打印质保卡
        } else {
            printAllWarranty(caseInfo)
        }
    }
    if (casePackOutSetting.printLabelIds.length) {
        for (let printLabelId of casePackOutSetting.printLabelIds) {
            handlePrintCaseLabel(printLabelId, false, caseInfo)
        }
    }
    if (printResList.value.length) {
        await printNow()
    }
    if (casePackOutSetting.autoScan === 1) {
        await takePhoto()
    }
}
// 所有的客户自定义产品价格和打印信息
let productPriceList = []
/**
 * 根据医院ID获取 自定义产品价格和打印信息
 * @param facHospitalId
 * @returns {Promise<*>}
 */
const getHospitalProductPrice = async facHospitalId => {
    let priceItem = productPriceList.find(x => x.facHospitalId === caseInfo.facHospitalId)
    if (priceItem) {
        return priceItem.priceList
    } else {
        await getHospitalProductPriceList(caseInfo.facHospitalId)
        priceItem = {
            facHospitalId: caseInfo.facHospitalId,
            priceList: hospitalProductList.value.slice()
        }
        productPriceList.push(priceItem)
        return priceItem.priceList
    }
}
// 扫描盒号
// const boxNoRef = ref()
// const boxNo = ref('')
// const clickBoxNo = () => {
//     if (boxNo.value) {
//         boxNoRef.value.select()
//     }
// }
// const handleScanBoxNo = async () => {
//
// }

// 打印
import {useGetListCasePrintTemplate} from "@/use/case/useCasePrintTemplate";
import {usePrintCaseOut, usePrintWarranty, usePrintCaseLabel} from "@/use/case/usePrintCase";

const {
    getListCasePrintTemplateParam,
    casePrintTemplateList,
    getListCasePrintTemplate
} = useGetListCasePrintTemplate()

// 打印出货单
const {getPrintCaseOutList, getDefaultCaseOutId} = usePrintCaseOut()
const printCaseOutVisible = ref(false)
const caseOutTemplateId = ref('')
const caseOutTemplateList = computed(() => casePrintTemplateList.value.filter(x => x.printTemplateType === printTemplateTypeEnum.出货单))
const showPrintCaseOut = (isPrintNow, caseInfo) => {
    caseOutTemplateId.value = getDefaultCaseOutId(storeHospitalDetail, caseOutTemplateList)
    //如果设置了 不显示弹窗 直接打印出货单
    let setting = caseSettingList.value.find(x => x.settingItem === caseSettingItem.打印出货单不显示模板选择弹窗)
    if (setting && setting.intResult) {
        handlePrintCaseOut(isPrintNow, caseInfo)
        return
    }
    printCaseOutVisible.value = true
}
const handlePrintCaseOut = async (isPrintNow, caseInfo) => {
    let caseOutTemplate = casePrintTemplateList.value.find(x => x.id === caseOutTemplateId.value)
    if (!caseOutTemplate) {
        ElMessage.error('请先设置出货单打印模板(基础设置-订单打印格式-出货单设置)')
        return
    }
    printCaseOutVisible.value = false
    let res = getPrintCaseOutList(caseInfo, factorySetting, productDetails, currentWorker.value.name, caseOutTemplate, hiprint)
    if (isPrintNow) {
        printResList.value = res
        await printList(caseOutTemplate, hiprint)
    } else {
        for (let item of res) {
            printResList.value.push(item)
        }
    }
}
//打印质保卡
const {printWarranty, getDefaultWarrantyId} = usePrintWarranty()
const warrantyTemplateType = printTemplateTypeEnum.质保卡
const printWarrantyVisible = ref(false)
const warrantyProductId = ref('')
const warrantyTemplateId = ref('')
const warrantyTemplateList = computed(() => casePrintTemplateList.value.filter(x => x.printTemplateType === warrantyTemplateType))
const setDefaultWarrantyProduct = () => {
    warrantyProductId.value = ''
    for (let i = 0; i < caseInfo.productList.length; i++) {
        let productDetail = productDetails.value.find(x => x.id === caseInfo.productList[i].productId)
        if (productDetail && productDetail.isWarrant === 1) {
            warrantyProductId.value = caseInfo.productList[i].productId
            break
        }
    }
    if (!warrantyProductId.value) {
        ElMessage.error('没有需要打印质保卡的产品，请检查产品设置')
        return
    }
    setDefaultWarranty()
}
const setDefaultWarranty = () => {
    let storeProductDetail = productDetails.value.find(x => x.id === warrantyProductId.value)
    if (storeProductDetail) {
        warrantyTemplateId.value = getDefaultWarrantyId(storeProductDetail, warrantyTemplateList)
    }
}
const warrantyProductIds = ref([])//一个订单可能多个需要打印质保卡的产品
const showPrintWarranty = caseInfo => {
    let stopSetting = caseSettingList.value.find(x => x.settingItem === caseSettingItem.返工返修订单不打印质保卡)
    if (stopSetting && stopSetting.intResult && (caseInfo.caseType === caseTypeEnum.返工 || caseInfo.caseType === caseTypeEnum.返修)) {
        ElMessage.error('已设置返工返修订单不打印质保卡')
        return
    }
    warrantyProductIds.value = []
    warrantyProductId.value = ''
    for (let i = 0; i < caseInfo.productList.length; i++) {
        let productPriceDetail = storeHospitalDetail.priceList.find(x => x.productId === caseInfo.productList[i].productId)
        if (productPriceDetail && productPriceDetail.isPrintWarranty === 0) {
            // 设置该客户不打印该产品
            continue
        }
        let productDetail = productDetails.value.find(x => x.id === caseInfo.productList[i].productId)
        if (productDetail && productDetail.isWarrant === 1) {
            // 默认打印第一个产品的质保卡
            if (!warrantyProductId.value) warrantyProductId.value = caseInfo.productList[i].productId
            warrantyProductIds.value.push(caseInfo.productList[i].productId)
        }
    }
    if (!warrantyProductIds.value || !warrantyProductIds.value.length) {
        ElMessage.error('没有需要打印质保卡的产品，请检查产品设置')
        return
    }
    let storeProductDetail = productDetails.value.find(x => x.id === warrantyProductId.value)
    if (storeProductDetail) {
        warrantyTemplateId.value = getDefaultWarrantyId(storeProductDetail, warrantyTemplateList)
    }
    //如果设置了 不显示弹窗并且只有一个产品 直接打印质保卡
    let setting = caseSettingList.value.find(x => x.settingItem === caseSettingItem.打印质保卡不显示模板选择弹窗)
    if (setting && setting.intResult) {
        printAllWarranty(caseInfo)
        return
    }
    warrantProductName.value = ''
    printWarrantyVisible.value = true
}
const printAllWarranty = caseInfo => {
    for (let i = 0; i < caseInfo.productList.length; i++) {
        let caseProduct = caseInfo.productList[i]
        if (caseProduct && caseProduct.isPrintWarranty === 0) {
            // 设置该客户不打印该产品
            continue
        }
        let productDetail = productDetails.value.find(x => x.id === caseProduct.productId)
        if (productDetail && productDetail.isWarrant === 1) {
            let warrantyTemplateId = getDefaultWarrantyId(productDetail, warrantyTemplateList)
            let warrantyTemplate = casePrintTemplateList.value.find(x => x.id === warrantyTemplateId)
            if (!!warrantyTemplate) {
                let res = printWarranty(caseInfo, productDetail.id, warrantyTemplate, hiprint, productDetails.value, factorySetting)
                printResList.value.push(res)
            }
        }
    }
}
const warrantProductName = ref('')//单个产品打印时  自定义产品打印名称
const handlePrintWarranty = isPrintNow => {
    if (!warrantyProductId.value) {
        ElMessage.error('没有需要打印质保卡的产品，请检查产品设置')
        return
    }
    if (!warrantyTemplateId.value) {
        ElMessage.error('没有质保卡模板，请检查订单打印设置')
        return
    }
    let warrantyTemplate = casePrintTemplateList.value.find(x => x.id === warrantyTemplateId.value)
    if (!warrantyTemplate) {
        ElMessage.error('请选择打印模板')
        return
    }
    let caseProduct = caseInfo.productList.find(x => x.productId === warrantyProductId.value)
    if (warrantProductName.value) {
        caseProduct.warrantProductName = warrantProductName.value
    }
    printWarrantyVisible.value = false
    let res = printWarranty(caseInfo, warrantyProductId.value, warrantyTemplate, hiprint, productDetails.value, factorySetting)
    if (isPrintNow) {
        printSingle(warrantyTemplate, res, hiprint)
    } else {
        printResList.value.push(res)
    }
}
// 打印自定义标签
const {
    printCaseLabel,
    printCaseProductLabel
} = usePrintCaseLabel()
const labelProductId = ref('')
const handlePrintCaseLabel = (id, isPrintNow) => {
    let labelTemplate = casePrintTemplateList.value.find(x => x.id === id)
    if (!labelTemplate) {
        ElMessage.error('打印模板不存在')
        return
    }
    if (labelTemplate.mainItem === 1) {
        let res = printCaseLabel(caseInfo, factorySetting, storeHospitalDetail, productDetails, currentWorker.value.name, labelTemplate, hiprint)
        if (isPrintNow) {
            printSingle(labelTemplate, res, hiprint)
        } else {
            printResList.value.push(res)
        }
    } else {
        if (isPrintNow) {
            // 立刻打印弹窗产品标签
            let res = printCaseProductLabel(caseInfo, warrantyProductId.value, productDetails, storeHospitalDetail, factorySetting, currentWorker.value.name, labelTemplate, hiprint)
            printSingle(labelTemplate, res, hiprint)
        } else {
            for (let caseProduct of caseInfo.productList) {
                let res = printCaseProductLabel(caseInfo, caseProduct.productId, productDetails, storeHospitalDetail, factorySetting, currentWorker.value.name, labelTemplate, hiprint)
                printResList.value.push(res)
            }
        }
    }
}
const showPrintCaseLabel = (id) => {
    let labelTemplate = casePrintTemplateList.value.find(x => x.id === id)
    if (!labelTemplate) {
        ElMessage.error('打印模板不存在')
        return
    }
    if (labelTemplate.mainItem === 1) {
        let res = printCaseLabel(caseInfo, factorySetting, storeHospitalDetail, productDetails, currentWorker.value.name, labelTemplate, hiprint)
        printSingle(labelTemplate, res, hiprint)
    } else {
        if (!labelProductId.value) {
            ElMessage.error('请选择打印产品')
            return
        }
        let res = printCaseProductLabel(caseInfo, labelProductId.value, productDetails, storeHospitalDetail, factorySetting, currentWorker.value.name, labelTemplate, hiprint)
        printSingle(labelTemplate, res, hiprint)
    }
}

const printNow = async () => {
    await printNoScanList(hiprint)
}

// 获取订单详情
const caseVisible = ref(false)
const caseId = ref()
const showCaseInfo = id => {
    caseId.value = id
    caseVisible.value = true
}
const setCaseInfo = async val => {
    if (!!val) {
        Object.assign(caseInfo, val)
        storeHospitalDetail = storeHospitalDetails.value.find(x => x.id === caseInfo.facHospitalId)
        if (storeHospitalDetail) {
            storeHospitalDetail.priceList = await getHospitalProductPrice(caseInfo.facHospitalId)
        }
        setDefaultWarrantyProduct()
        if (caseInfo.productList && caseInfo.productList.length === 1) {
            labelProductId.value = caseInfo.productList[0].productId
        }
    }
}
//缓存数据字典
import {useGetDataValueListFromStore} from "@/use/system/useData";

const {
    getDataValueListFromStore,
} = useGetDataValueListFromStore()
const district = ref([])
const setData = () => {
    district.value = getDataValueListFromStore('district')
}
setData()
//切换
const curTab = ref('all')
const showPackList = computed(() => {
    if (curTab.value === 'all') {
        return packCaseList.value
    } else if (curTab.value === 'hospital') {
        return packCaseList.value.filter(x => x.receiverType === receiverTypeEnum.医院收货)
    } else if (curTab.value === 'sales') {
        return packCaseList.value.filter(x => x.receiverType === receiverTypeEnum.销售)
    } else if (curTab.value === 'hospitalDept') {
        return packCaseList.value.filter(x => x.receiverType === receiverTypeEnum.科室收货)
    }
})
const changeTab = () => {
    // 通过computed自动搜索 不需要手动搜索
}

// 自动拍照
import {useScanDevice} from "@/use/system/useLiangtian";
import {useUploadPicCompress, picPathPrefix} from "@/use/system/useFile";
import {useAddCaseFile} from "@/use/case/useCase";

const {
    initLiangtianSetting,
    device,
    open_view1,
    getDeviceInfo,
    view1_scan,
    rotate,
} = useScanDevice()
const {
    addCaseFile,
    addCaseFileLoading,
    addCaseFileParam
} = useAddCaseFile()
const {
    uploadFileCompressList,
    uploadFileCompress,
    uploadCompressLoading,
    uploadCompressData,
    uploadCompressResult
} = useUploadPicCompress('casePic')
const takePhoto = async () => {
    if (!caseInfo.id) {
        ElMessage.error('请先扫描订单号获取订单信息')
        return
    }
    if (device.videoStatus === '未连接') {
        ElMessage.error('请先连接高拍仪并刷新设备状态')
        return
    }
    let file = await view1_scan()
    if (!file) return
    await uploadFileCompress({file: file})
    addCaseFileParam.casePicType = '订单发货照片'
    addCaseFileParam.facCaseId = caseInfo.id
    addCaseFileParam.fileUse = fileUseEnum.订单图片
    addCaseFileParam.fileId = uploadCompressData.picId
    addCaseFileParam.miniFileId = uploadCompressData.miniPicId
    await addCaseFile()
}
import PackSfCreate from "@/views/case/packSfCreate";
import PackSfPrint from "@/views/case/packSfPrint";
// 装箱发货
const {
    packCaseOut,
    packCaseOutLoading
} = usePackCaseOut()
const handlePackOut = async () => {
    if (!curRow.list || !curRow.list.length) {
        ElMessage.error('没有需要装箱发货的订单，请检查')
        return
    }
    let param = {
        casePackId: curRow.id,
        caseIds: curRow.list.map(x => x.id)
    }
    let res = await packCaseOut(param)
    if (!res || !res.length) return
    printResList.value = []
    for (let caseInfo of res) {
        storeHospitalDetail = storeHospitalDetails.value.find(x => x.id === caseInfo.facHospitalId)
        if (storeHospitalDetail) {
            storeHospitalDetail.priceList = await getHospitalProductPrice(caseInfo.facHospitalId)
        }
        if (casePackOutSetting.packCaseOut === 1) {
            showPrintCaseOut(false, caseInfo)
        }
        if (casePackOutSetting.packWarranty === 1) {
            let warrantySetting = caseSettingList.value.find(x => x.settingItem === caseSettingItem.返工返修订单不打印质保卡)
            if (warrantySetting && warrantySetting.intResult && (caseInfo.caseType === caseTypeEnum.返工 || caseInfo.caseType === caseTypeEnum.返修)) {
                //返工返修订单不打印质保卡
            } else {
                printAllWarranty(caseInfo)
            }
        }
        if (casePackOutSetting.packLabelIds.length) {
            for (let printLabelId of casePackOutSetting.packLabelIds) {
                handlePrintCaseLabel(printLabelId, false, caseInfo)
            }
        }
    }
    if (printResList.value.length) {
        await printNow()
    }
    if (casePackOutSetting.createSfOrder && !curRow.expressNo) {
        // 顺丰下单
        showCreateSfOrder()
    }
    let curPackIndex = packCaseList.value.findIndex(x => x.id === curRow.id)
    packCaseList.value.splice(curPackIndex, 1)
    curRow.list = []
}
// 顺丰下单
const sfOrderVisible = ref(false)
const showCreateSfOrder = () => {
    sfOrderVisible.value = true
}
const hideCreateSfOrder = () => {
    sfOrderVisible.value = false
}
const afterCreateSfOrder = res => {
    if (res) {
        hideCreateSfOrder()
        if (casePackOutSetting.printSfOrder) {
            showPrintSf()
        }
    }
}
// 顺丰打印
const printVisible = ref(false)
const showPrintSf = () => {
    printVisible.value = true
}
const afterPrintSf = () => {
    printVisible.value = false
}
//取消装箱
const handleCancelCase = row => {
    ElMessageBox.confirm('确定撤销订单' + row.caseNo + '的装箱吗', '重要提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
    }).then(async () => {
        await cancelCasePack(row.id)
        if (cancelCasePackResult.value) {
            let rowIndex = curRow.list.findIndex(x => x.id === row.id)
            curRow.list.splice(rowIndex, 1)
            let casePack = packCaseList.value.find(x => x.id === row.casePackId)
            if (casePack) {
                casePack.num--
                if (!casePack.num) {
                    // 装箱单没有产品了
                    let packIndex = packCaseList.value.findIndex(x => x.id === row.casePackId)
                    packCaseList.value.splice(packIndex, 1)
                }
            }
        }
    })
}
</script>

<style scoped lang="scss">
.view-left {
    padding: 5px;
    flex-shrink: 0;
    width: 510px;
}

#view1, .scan-view {
    height: 225px;
    width: 300px;
    border: 1px solid #edf1f2;
}
</style>