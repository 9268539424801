<template>
    <div>
        <el-upload accept="image/*"
                   :multiple="false"
                   :limit="maxFileNum"
                   list-type="picture-card"
                   :before-upload="handleBeforePicUpload"
                   :auto-upload="true"
                   :http-request="uploadFile"
                   v-model:file-list="fileList"
                   :on-preview="previewPic"
                   :on-remove="removePic"
                   :on-success="successPicUpload"
                   :on-exceed="fileExceed"
                   :disabled="innerDisabled"
        >
            <el-icon>
                <Plus/>
            </el-icon>
        </el-upload>
        <el-dialog v-model="curPicVisible" append-to-body>
            <el-image :src="curPicUrl">
            </el-image>
        </el-dialog>
    </div>
</template>

<script setup>
import {ref, reactive, onMounted, watch} from 'vue'
import {Plus, Search, Refresh, Edit, Delete, Check} from '@element-plus/icons-vue';
import {useGetFileUrl, useUploadFile} from "@/use/system/useUpload";
import {beforePicUpload} from "@/use/system/useFile";
import {ElMessage} from "element-plus";

const emits = defineEmits(['finishUpload'])
// files格式  [{orgFileName:'', fileId:'',fileToken:''}]
const props = defineProps({
    files: Array,
    folderPrefix: String,
    fileUse: {
        type: Number,
        default: 0
    },
    disabled: {
        type: Boolean,
        default: true
    }
})
const {
    uploadParam,
    uploadFileResult,
    uploadFileList,
    uploadFile,
} = useUploadFile()
const fileList = ref([])//页面显示的文件
const innerDisabled = ref(true)
watch(() => props.folderPrefix, (n, o) => {
    uploadParam.folderPrefix = n
})
watch(() => props.fileUse, (n, o) => {
    uploadParam.fileUse = n
})
watch(() => props.disabled, (n, o) => {
    innerDisabled.value = n
})
watch(() => props.files, (n, o) => {
    // setFileList()
})
onMounted(async () => {
    uploadParam.folderPrefix = props.folderPrefix
    uploadParam.fileUse = props.fileUse
    innerDisabled.value = props.disabled
    setFileList()
})
// 入参文件转化为页面显示文件
const setFileList = async () => {
    if (props.files && props.files.length) {
        for (let item of props.files) {
            item.url = await getFileUrl(item.fileToken)
        }
        fileList.value = props.files.map(x => {
            return {
                name: x.orgFileName,
                url: x.url
            }
        })
        uploadFileList.value = props.files.map(x => {
            return {
                result: true,
                picId: x.fileId,
                picToken: x.fileToken,
                orgFileName: x.orgFileName
            }
        })
    } else {
        fileList.value = []
        uploadFileList.value = []
    }
}
const maxFileNum = 5 //最多上传5
const fileExceed = () => {
    ElMessage.error(`最多上传${maxFileNum}个文件`)
}
const {getFileUrl} = useGetFileUrl()
const handleBeforePicUpload = file => {
    let fileIndex = uploadFileList.value.findIndex(x => x.orgFileName === file.name)
    if (fileIndex >= 0) {
        ElMessage.error('存在同名文件，请检查')
        return false
    }
    return beforePicUpload(file)
}
// 移除
const removePic = file => {
    let fileIndex = uploadFileList.value.findIndex(x => x.orgFileName === file.name)
    if (fileIndex < 0) return
    uploadFileList.value.splice(fileIndex, 1)
    finishChange()
}
const finishChange = () => {
    emits('finishUpload', uploadFileList.value.map(x => {
        return {
            fileId: x.picId,
            fileToken: x.picToken
        }
    }))
}
// 新增
const successPicUpload = () => {
    finishChange()
}
// 预览
const curPicVisible = ref(false)
const curPicUrl = ref('')
const previewPic = file => {
    let pic = fileList.value.find(x => x.name === file.name)
    if (pic) {
        curPicVisible.value = true
        curPicUrl.value = pic.url
    }
}
</script>