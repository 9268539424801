<template>
    <div class="tooth-container">
        <el-row>
            <el-col :span="24" class="text-center">
                <el-checkbox label="上颌" v-model="innerUpperJaw" :true-label="1" :false-label="0"
                             @change="changeUpperJaw"></el-checkbox>
                <el-checkbox label="下颌" v-model="innerLowerJaw" :true-label="1" :false-label="0"
                             @change="changeLowerJaw"></el-checkbox>
            </el-col>
        </el-row>
        <ul class="row-ul">
            <li class="row-ul-li border-bottom-dash border-right-dash">
                <el-checkbox-group v-model="pos1Arr" @change="changePos1" size="default">
                    <el-checkbox-button class="tooth-checkbox" v-for="item in decreaseArr" :value="item" :label="item"
                                        :key="item"></el-checkbox-button>
                </el-checkbox-group>
            </li>
            <li class="row-ul-li border-bottom-dash">
                <el-checkbox-group v-model="pos2Arr" @change="changePos2" size="default">
                    <el-checkbox-button class="tooth-checkbox" v-for="item in increaseArr" :value="item" :label="item"
                                        :key="item"></el-checkbox-button>
                </el-checkbox-group>
            </li>
        </ul>
        <ul class="row-ul">
            <li class="row-ul-li border-right-dash">
                <el-checkbox-group v-model="pos4Arr" @change="changePos4" size="default">
                    <el-checkbox-button class="tooth-checkbox" v-for="item in decreaseArr" :value="item" :label="item"
                                        :key="item"></el-checkbox-button>
                </el-checkbox-group>
            </li>
            <li class="row-ul-li">
                <el-checkbox-group v-model="pos3Arr" @change="changePos3" size="default">
                    <el-checkbox-button class="tooth-checkbox" v-for="item in increaseArr" :value="item" :label="item"
                                        :key="item"></el-checkbox-button>
                </el-checkbox-group>
            </li>
        </ul>
    </div>
</template>
<!--牙位组件-->
<!--用法示例-->
<!--<tooth-no-->
<!--    v-model:pos1="toothNoValue.pos1"-->
<!--    v-model:pos2="toothNoValue.pos2"-->
<!--    v-model:pos3="toothNoValue.pos3"-->
<!--    v-model:pos4="toothNoValue.pos4"-->
<!--    v-model:upper-jaw="toothNoValue.upperJaw"-->
<!--    v-model:lower-jaw="toothNoValue.lowerJaw"-->
<!--    @change-tooth="getToothNum"-->
<!--&gt;</tooth-no>-->
<script setup>
import {ref, reactive, inject, watch, toRef, defineProps, defineEmits, computed, onMounted} from 'vue'

const props = defineProps({
    pos1: String,//左上
    pos2: String,//右上
    pos3: String,//右下
    pos4: String,//左下
    upperJaw: Number,
    lowerJaw: Number,
})
const emits = defineEmits(['update:pos1', 'update:pos2', 'update:pos3', 'update:pos4', 'update:upperJaw', 'update:lowerJaw', 'changeTooth'])

const innerUpperJaw = ref(0)
watch(() => props.upperJaw, (newVal, oldVal) => {
    innerUpperJaw.value = newVal
})
const innerLowerJaw = ref(0)
watch(() => props.lowerJaw, (newVal, oldVal) => {
    innerLowerJaw.value = newVal
})
const pos1Arr = ref([])
watch(() => props.pos1, (newVal, oldVal) => {
    pos1Arr.value = !!newVal ? newVal.split('') : []
})
const pos2Arr = ref([])
watch(() => props.pos2, (newVal, oldVal) => {
    pos2Arr.value = !!newVal ? newVal.split('') : []
})
const pos3Arr = ref([])
watch(() => props.pos3, (newVal, oldVal) => {
    pos3Arr.value = !!newVal ? newVal.split('') : []
})
const pos4Arr = ref([])
watch(() => props.pos4, (newVal, oldVal) => {
    pos4Arr.value = !!newVal ? newVal.split('') : []
})
onMounted(() => {
    innerUpperJaw.value = props.upperJaw
    innerLowerJaw.value = props.lowerJaw
    pos1Arr.value = props.pos1 ? props.pos1.split('') : []
    pos2Arr.value = props.pos2 ? props.pos2.split('') : []
    pos3Arr.value = props.pos3 ? props.pos3.split('') : []
    pos4Arr.value = props.pos4 ? props.pos4.split('') : []
})

const increaseArr = ref(['1', '2', '3', '4', '5', '6', '7', '8'])
const decreaseArr = ref(['8', '7', '6', '5', '4', '3', '2', '1'])
const changePos1 = () => {
    emits('update:pos1', pos1Arr.value.sort().join(''))
    changeToothNo()
}
const changePos2 = () => {
    emits('update:pos2', pos2Arr.value.sort().join(''))
    changeToothNo()
}
const changePos3 = () => {
    emits('update:pos3', pos3Arr.value.sort().join(''))
    changeToothNo()
}
const changePos4 = () => {
    emits('update:pos4', pos4Arr.value.sort().join(''))
    // $emit('update:pos4',pos4Arr.sort().join(''))
    changeToothNo()
}
const changeUpperJaw = () => {
    emits('update:upperJaw', innerUpperJaw.value)
    changeToothNo()
}
const changeLowerJaw = () => {
    emits('update:lowerJaw', innerLowerJaw.value)
    changeToothNo()
}
const changeToothNo = () => {
    let toothNum = 0
    if (innerUpperJaw.value || innerLowerJaw.value) {
        toothNum = innerUpperJaw.value + innerLowerJaw.value
    } else {
        toothNum = pos1Arr.value.length + pos2Arr.value.length + pos3Arr.value.length + pos4Arr.value.length
    }
    emits('changeTooth', toothNum)
}
</script>

<style scoped>
.tooth-container {
    font-size: 12px;
    width: 700px;
    height: 140px;
}

.border-bottom-dash {
    border-bottom: 1px dashed #777;
}

.border-right-dash {
    border-right: 1px dashed #777;
}

.row-ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.row-ul-li {
    padding: 8px 6px;
}

</style>