import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// 跑马灯
import Vue3Marquee from 'vue3-marquee'
// import 'vue3-marquee/dist/style.css'
// 一次性解决时区和日期格式问题
import moment from 'moment'
/* import the fontawesome core */
import {library} from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {
    faUserCircle,
    faChartLine,
    faChartColumn,
    faChartPie,
    faList,
    faChartSimple,
    faMoneyBill,
    faMoneyBills,
    faHospitalUser,
    faHospital,
    faWrench,
    faCartPlus,
    faTruckFast,
    faShareFromSquare
} from '@fortawesome/free-solid-svg-icons'

library.add(faChartLine, faChartColumn, faChartPie, faList, faChartSimple, faMoneyBill, faHospitalUser, faWrench, faUserCircle
    , faCartPlus, faTruckFast, faMoneyBills, faShareFromSquare, faHospital)
Date.prototype.toISOString = function () {
    return moment(this).format('YYYY-MM-DD HH:mm:ss')
}
const app = createApp(App)

app.use(ElementPlus, {
    locale: zhCn,
    size: 'default'
})
app.use(store)
app.use(router)
app.use(Vue3Marquee)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

// 跳转路由
app.provide('toPage', (uri, checkResult = true) => {
    if (checkResult) {
        router.push({name: uri})
    }
})
//复制对象字段
app.provide('setFields', (targetObj, val) => {
    for (let field in targetObj) {
        if (val[field] !== undefined) targetObj[field] = val[field]
    }
})
//清空对象字段
app.provide('initFields', (targetObj) => {
    for (let field in targetObj) {
        if (field === 'id' || field.indexOf('Id') >= 0 || field === 'points' || field.indexOf('Level') >= 0 || field === 'pageIndex' || field === 'isCollect') {
            targetObj[field] = 0
        } else if (field.indexOf('List') >= 0) {
            targetObj[field] = []
        } else {
            targetObj[field] = ''
        }
    }
})

//自定义全局指令v-only-number 让输入框只能输入数字
import onlyNumber from './directive/onlyNumberDirective'

app.directive(onlyNumber.name, onlyNumber.directive)
import onlyInt from './directive/onlyIntDirective'

app.directive(onlyInt.name, onlyInt.directive)
import money from './directive/moneyDirective'

app.directive(money.name, money.directive)
import checkAuth from './directive/checkAuth'

app.directive(checkAuth.name, checkAuth.directive)
import fileSize from './directive/fileSizeDirective'

app.directive(fileSize.name, fileSize.directive)
import datetime from './directive/datetimeDirectives'

app.directive(datetime.name, datetime.directive)
import date from './directive/dateDirective'

app.directive(date.name, date.directive)
import enumText from './directive/enumText'

app.directive(enumText.name, enumText.directive)
import bankNumber from './directive/bankNumber'

app.directive(bankNumber.name, bankNumber.directive)
import focus from './directive/focus'

app.directive(focus.name, focus.directive)


import toothNo from './components/toothNo'
import tableTooth from './components/tableTooth'
import printTooth from './components/printTooth'
import dataSet from './components/dataSet'
import caseView from "./components/caseView";
import auditForm from "./components/auditForm";
import tableToothMini from "./components/tableToothMini";
import workerSelect from "./components/workerSelect";
import supplySelect from "./components/supplySelect";
import productSelect from "./components/productSelect";
import materialSelect from "./components/materialSelect";
import hospitalSelect from "./components/hospitalSelect";
import doctorSelect from "./components/doctorSelect";
import multiProductSelect from "./components/multiProductSelect";
import dateRangePicker from "./components/dateRangePicker";
import doubleFixedSpan from "./components/doubleFixedSpan";
import productStep from "./components/productStep";
import picUpload from "./components/picUpload";
import picsUpload from "./components/picsUpload";
import picPreview from "./components/picPreview";

app.component('tooth-no', toothNo)
app.component('table-tooth', tableTooth)
app.component('print-tooth', printTooth)
app.component('data-set', dataSet)
app.component('case-view', caseView)
app.component('audit-form', auditForm)
app.component('table-tooth-mini', tableToothMini)
app.component('worker-select', workerSelect)
app.component('supply-select', supplySelect)
app.component('product-select', productSelect)
app.component('material-select', materialSelect)
app.component('hospital-select', hospitalSelect)
app.component('doctor-select', doctorSelect)
app.component('multi-product-select', multiProductSelect)
app.component('date-range-picker', dateRangePicker)
app.component('doubleFixed-span', doubleFixedSpan)
app.component('product-step', productStep)
app.component('pic-upload', picUpload)
app.component('pic-preview', picPreview)
app.component('pics-upload', picsUpload)

app.component('font-awesome-icon', FontAwesomeIcon).mount('#app')

// 通用样式表
import './assets/main.scss'