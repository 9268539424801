<template>
    <div style="width: 100%">
        <el-descriptions :column="4" border v-loading="caseGetLoading">
            <el-descriptions-item width="12.5%" label="订单号">
                <b>
                    {{ caseInfo.caseNo }}
                </b>
            </el-descriptions-item>
            <el-descriptions-item label="盒号">
                <span class="text-danger">
                {{ caseInfo.boxNo }}
                </span>
            </el-descriptions-item>
            <el-descriptions-item label="客户单号">
                {{ caseInfo.hospitalCaseNo }}
            </el-descriptions-item>
            <el-descriptions-item label="病历号">
                {{ caseInfo.medicalNo }}
            </el-descriptions-item>
            <el-descriptions-item label="总金额">
                <span class="text-danger">
                {{ txtMoneyFormat(caseInfo.totalPrice) }}
                </span>
            </el-descriptions-item>
            <el-descriptions-item width="12.5%" label="关联订单号">
                {{ caseInfo.sourceCaseNo }}
            </el-descriptions-item>
            <el-descriptions-item width="12.5%" label="订单类型">
                {{ enumTypeFormatter(caseInfo.caseType, caseType) }}
            </el-descriptions-item>
            <el-descriptions-item width="12.5%" label="试戴">
                {{ enumTypeFormatter(caseInfo.tryState, tryState) }}
            </el-descriptions-item>

            <el-descriptions-item label="医院">
                <b>
                    {{ caseInfo.facHospitalName }}
                </b>
            </el-descriptions-item>
            <el-descriptions-item label="医生">
                <b>
                    {{ caseInfo.facDoctorName }}
                </b>
            </el-descriptions-item>
            <el-descriptions-item label="销售">
                {{ caseInfo.facWorkerName }}
            </el-descriptions-item>
            <el-descriptions-item label="片区">
                {{ caseInfo.district }}
            </el-descriptions-item>

            <el-descriptions-item label="订单状态" class-name="text-warning">
                {{ enumTypeFormatter(caseInfo.caseState, caseState) }}
            </el-descriptions-item>
            <el-descriptions-item label="患者姓名">
                {{ caseInfo.patientName }}
            </el-descriptions-item>
            <el-descriptions-item label="年龄性别">
                {{ caseInfo.patientAge }}
                {{ caseInfo.gender }}
            </el-descriptions-item>
            <el-descriptions-item label="患者手机">
                {{ caseInfo.patientPhoneNo }}
            </el-descriptions-item>

            <el-descriptions-item label="是否加急">
                <span class="text-danger">
                {{ caseInfo.isUrgent === 1 ? '加急' : '' }}
                </span>
            </el-descriptions-item>
            <el-descriptions-item label="代理">
                {{ caseInfo.agentName }}
            </el-descriptions-item>
            <el-descriptions-item label="戴牙日期">
                {{ txtDateFormat(caseInfo.wearDate) }}
            </el-descriptions-item>
            <el-descriptions-item label="私人单位">
                {{ caseInfo.isPrivate === 1 ? '私' : '' }}
            </el-descriptions-item>

            <el-descriptions-item label="入件日期">
                {{ txtDateFormat(caseInfo.inDate) }}
            </el-descriptions-item>
            <el-descriptions-item label="生产天数">
                {{ caseInfo.makeDays }}
            </el-descriptions-item>
            <el-descriptions-item label="额外天数">
                {{ caseInfo.extraDays }}
            </el-descriptions-item>
            <el-descriptions-item label="预计出件">
                {{ txtDateFormat(caseInfo.scheduleDeliveryDate) }}
            </el-descriptions-item>

            <el-descriptions-item label="制作费用" v-if="showPrice">
                {{ caseInfo.makeCost }}
            </el-descriptions-item>
            <el-descriptions-item label="材料费用" v-if="showPrice">
                {{ caseInfo.materialCost }}
            </el-descriptions-item>
            <el-descriptions-item label="外返原因" :span="2">
                {{ caseInfo.redoReason }}
            </el-descriptions-item>
            <el-descriptions-item label="医生习惯" :span="2">
                {{ caseInfo.doctorHabit }}
            </el-descriptions-item>
            <el-descriptions-item label="医生备注" :span="2">
                {{ caseInfo.doctorRemark }}
            </el-descriptions-item>
            <el-descriptions-item label="生产备注" :span="2">
                {{ caseInfo.stepNote }}
            </el-descriptions-item>
            <el-descriptions-item label="工厂备注" :span="2">
                {{ caseInfo.factoryRemark }}
            </el-descriptions-item>
        </el-descriptions>
        <el-table :data="caseInfo.productList" border style="width: 100%">
            <el-table-column label="产品" prop="productName"></el-table-column>
            <el-table-column label="种类">
                <template #default="scope">
                    {{
                        getProductFullType(scope.row.productId)
                    }}
                </template>
            </el-table-column>
            <el-table-column label="牙色" prop="color"></el-table-column>
            <el-table-column min-width="200" label="牙位">
                <template #default="scope">
                    <table-tooth
                        :key="scope.row.id"
                        :pos1="scope.row.pos1"
                        :pos2="scope.row.pos2"
                        :pos3="scope.row.pos3"
                        :pos4="scope.row.pos4"
                        :upper-jaw="scope.row.upperJaw"
                        :lower-jaw="scope.row.lowerJaw"
                        :complete="scope.row.complete"
                        :tooth-type="scope.row.toothType"
                        :pontic="scope.row.pontic"
                        :missing1="scope.row.missing1"
                        :missing2="scope.row.missing2"
                        :missing3="scope.row.missing3"
                        :missing4="scope.row.missing4"
                    />
                </template>
            </el-table-column>
            <el-table-column label="数量" prop="num"></el-table-column>
            <el-table-column label="小计" prop="sum" :formatter="moneyFormat" v-if="showPrice"></el-table-column>
            <el-table-column label="优惠" prop="benefit" :formatter="moneyFormat" v-if="showPrice"></el-table-column>
        </el-table>
        <el-row v-show="caseInfo.plantList && caseInfo.plantList.length">
            <el-col :span="24">
                <el-table :data="caseInfo.plantList" border style="width: 100%">
                    <el-table-column label="牙位">
                        <template #default="scope">
                            <table-tooth
                                :key="scope.$index"
                                :pos1="scope.row.pos1"
                                :pos2="scope.row.pos2"
                                :pos3="scope.row.pos3"
                                :pos4="scope.row.pos4"
                                :upper-jaw="scope.row.upperJaw"
                                :lower-jaw="scope.row.lowerJaw"
                                :tooth-type="toothTypeEnum.单冠"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column label="器械系统" prop="plantSystem"></el-table-column>
                    <el-table-column label="固位方式" prop="plantRetention"></el-table-column>
                </el-table>
            </el-col>
        </el-row>
        <el-row v-show="caseInfo.attachList && caseInfo.attachList.length">
            <el-col :span="24">
                <el-table :data="caseInfo.attachList" border style="width: 100%">
                    <el-table-column label="附件" prop="name" width="300"></el-table-column>
                    <el-table-column label="数量" prop="num"></el-table-column>
                    <el-table-column label="归属" prop="belong"
                                     :formatter="(r,c,v)=>enumTypeFormatter(v,attachBelongList)">
                    </el-table-column>
                </el-table>
            </el-col>
        </el-row>
    </div>
</template>

<script setup>
import {onMounted, defineProps, inject, watch, defineEmits} from "vue";
import {useGetCase} from "@/use/case/useCase";
import {useStore} from "vuex";
import {caseType, tryState, caseState, toothTypeEnum, attachBelongEnum, getEnumList} from "@/util/enum";
import {txtDateFormat, enumTypeFormatter, moneyFormat, txtMoneyFormat} from "@/util/formatter";


const store = useStore()
const props = defineProps({
    id: Number,
    caseNo: String,
    showPrice: {
        type: Boolean,
        require: false,
        default: true
    }
})
const emits = defineEmits(['getCaseInfo'])
const {
    caseInfo,
    getCase,
    caseGetLoading,
    getCaseByNo
} = useGetCase()
const initFields = inject('initFields')

onMounted(async () => {
    if (props.id) {
        await getCase(props.id)
        emits('getCaseInfo', caseInfo)
    } else if (props.caseNo) {
        await getCaseByNo(props.caseNo)
        emits('getCaseInfo', caseInfo)
    } else {
        initFields(caseInfo)
        emits('getCaseInfo', '')
    }
})

watch(() => props.id, async (newVal, oldVal) => {
    if (!!newVal) {
        await getCase(newVal)
        emits('getCaseInfo', caseInfo)
    }
})

watch(() => props.caseNo, async (newVal, oldVal) => {
    if (!!newVal) {
        await getCaseByNo(props.caseNo)
        emits('getCaseInfo', caseInfo)
    }
})

const getProductFullType = val => {
    let product = store.state.productDetails.find(x => x.id === val)
    if (product) {
        return product.productTypeName + ' - ' + product.productBelongName
    }
}

const attachBelongList = getEnumList(attachBelongEnum)

</script>

<style scoped>

</style>