import {reactive, ref, inject, toRaw} from 'vue'
import {
    facDoctorSave,
    facDoctorDelete,
    facDoctorGetList,
    facDoctorUpdateValid,
    facDoctorGetPage,
    facDoctorGet,
    facDoctorGetSimpleList,
    doctorHabitBatchSave,
    doctorHabitGetList
} from '@/api/customer'

import {useStore} from 'vuex'

//保存工厂医生 oycm 2022/7/26
function useSaveFacDoctor() {
    const facDoctor = reactive({
        id: 0,
        name: '',
        telephone: '',
        landline: '',
        doctorNo: '',
        weixin: '',
        qq: '',
        gender: '',
        birthday: '',
        emailAddress: '',
        hospitalDeptId: '',
        job: '',
        dept: '',
        isVip: '',
        habit: '',
        remark: '',
        isValid: 1,
        facHospitalId: null,
        discountId: 0,
        discountPercent: 100,
        payMonth: 1,
        isDelivery: 1,
        doctorUserId: '',
        allowOnlineCase: 0
    })
    const facDoctorEmpty = JSON.parse(JSON.stringify(toRaw(facDoctor)))
    const setFields = inject('setFields')
    const initFacDoctor = () => {
        setFields(facDoctor, facDoctorEmpty)
    }
    const facDoctorFormRef = ref()
    const facDoctorSaveRules = reactive({
        facHospitalId: [
            {required: true, message: '请输入选择客户', trigger: 'blur'},
        ],
        name: [
            {required: true, message: '请输入姓名', trigger: 'blur'},
        ],
        telephone: [
            {required: true, message: '请输入手机号', trigger: 'blur'},
        ],
    })
    const saveFacDoctorLoading = ref(false)
    const saveFacDoctorResult = ref(false)
    const saveFacDoctor = async () => {
        saveFacDoctorResult.value = false
        if (saveFacDoctorLoading.value) return
        saveFacDoctorLoading.value = true
        await facDoctorFormRef.value.validate(async (valid, fields) => {
            if (!valid) {
                saveFacDoctorLoading.value = false
                return
            }
            saveFacDoctorResult.value = await facDoctorSave(facDoctor)
            saveFacDoctorLoading.value = false
        })
    }
    return {
        facDoctor,
        initFacDoctor,
        facDoctorFormRef,
        facDoctorSaveRules,
        saveFacDoctorLoading,
        saveFacDoctorResult,
        saveFacDoctor,
    }
}

//删除工厂医生 oycm 2022/7/26
function useDeleteFacDoctor() {
    const deleteFacDoctorLoading = ref(false)
    const deleteFacDoctorResult = ref(false)
    const deleteFacDoctor = async id => {
        deleteFacDoctorResult.value = false
        if (deleteFacDoctorLoading.value) return
        deleteFacDoctorLoading.value = true
        deleteFacDoctorResult.value = await facDoctorDelete({id: id})
        deleteFacDoctorLoading.value = false
    }
    return {
        deleteFacDoctorLoading,
        deleteFacDoctorResult,
        deleteFacDoctor,
    }
}

//列表工厂医生 oycm 2022/7/26
function useGetListFacDoctor() {
    const getListFacDoctorLoading = ref(false)
    const facDoctorList = ref([])
    const getListFacDoctor = async facHospitalId => {
        if (getListFacDoctorLoading.value) return
        getListFacDoctorLoading.value = true
        let data = await facDoctorGetList({id: facHospitalId})
        if (data) {
            facDoctorList.value = data
        }
        getListFacDoctorLoading.value = false
    }
    return {
        getListFacDoctorLoading,
        facDoctorList,
        getListFacDoctor,
    }
}

// 工厂医生下拉列表（全部可用医生用来缓存）oycm
function useUpdateStoreDoctorList() {
    const updateStoreDoctorListLoading = ref(false)
    const store = useStore()
    const updateStoreDoctorList = async () => {
        if (updateStoreDoctorListLoading.value) return
        updateStoreDoctorListLoading.value = true
        let res = await facDoctorGetSimpleList()
        if (res && res.length) {
            for (let doctor of res) {
                let doctorCount = store.state.doctorCount
                let item = doctorCount.find(x => x.doctorId === doctor.id)
                doctor.count = item ? item.count : 0
            }
        }
        store.commit('setDoctorList', (res && res.length) ? res : [])
        updateStoreDoctorListLoading.value = false
    }
    return {
        updateStoreDoctorListLoading,
        updateStoreDoctorList,
    }
}

//停用启用医生
function useUpdateValidFacDoctor() {
    const updateValidFacDoctorLoading = ref(false)
    const updateValidFacDoctorResult = ref(false)
    const updateValidFacDoctor = async (id, isValid) => {
        updateValidFacDoctorResult.value = false
        if (updateValidFacDoctorLoading.value) return
        updateValidFacDoctorLoading.value = true
        updateValidFacDoctorResult.value = await facDoctorUpdateValid({id: id, isValid: isValid})
        updateValidFacDoctorLoading.value = false
    }
    return {
        updateValidFacDoctorLoading,
        updateValidFacDoctorResult,
        updateValidFacDoctor,
    }
}

function useGetPageFacDoctor() {
    const getPageFacDoctorParam = reactive({
        facHospitalId: null,
        salesman: null,
        searchInfo: '',
        telephone: '',
        isValid: 1,//不看作废的
        pageIndex: 1,
        pageSize: 10,
    })
    const getPageFacDoctorParamEmpty = Object.assign({}, getPageFacDoctorParam)
    const initGetPageFacDoctorParam = () => {
        Object.assign(getPageFacDoctorParam, getPageFacDoctorParamEmpty)
    }
    const getPageFacDoctorLoading = ref(false)
    const facDoctorPage = ref([])
    const getPageFacDoctorResult = reactive({
        totalCount: 0,
        totalPages: 0,
    })
    const getPageFacDoctor = async () => {
        if (getPageFacDoctorLoading.value) return
        getPageFacDoctorLoading.value = true
        let data = await facDoctorGetPage(getPageFacDoctorParam)
        if (data && data.list && data.list.length) {
            facDoctorPage.value = data.list
            getPageFacDoctorResult.totalPages = Math.ceil(data.totalCount / getPageFacDoctorParam.pageSize)
            getPageFacDoctorResult.totalCount = data.totalCount
        } else {
            facDoctorPage.value = []
            getPageFacDoctorResult.totalPages = 0
            getPageFacDoctorResult.totalCount = 0
        }
        getPageFacDoctorLoading.value = false
    }
    return {
        getPageFacDoctorParam,
        initGetPageFacDoctorParam,
        getPageFacDoctorLoading,
        facDoctorPage,
        getPageFacDoctorResult,
        getPageFacDoctor,
    }
}

function useGetFacDoctor() {
    const getFacDoctorLoading = ref(false)
    const getFacDoctor = async id => {
        if (getFacDoctorLoading.value) return
        getFacDoctorLoading.value = true
        let res = await facDoctorGet({id: id})
        getFacDoctorLoading.value = false
        return res
    }
    return {
        getFacDoctor,
        getFacDoctorLoading
    }
}

// 保存医生习惯
function useBatchSaveDoctorHabit() {
    const batchSaveDoctorHabitLoading = ref(false)
    const batchSaveDoctorHabitResult = ref(false)
    const batchSaveDoctorHabit = async param => {
        batchSaveDoctorHabitResult.value = false
        if (batchSaveDoctorHabitLoading.value) return
        batchSaveDoctorHabitLoading.value = true
        batchSaveDoctorHabitResult.value = await doctorHabitBatchSave(param)
        batchSaveDoctorHabitLoading.value = false
    }
    return {
        batchSaveDoctorHabitLoading,
        batchSaveDoctorHabitResult,
        batchSaveDoctorHabit,
    }
}

// 获取医生习惯
function useGetListDoctorHabit() {
    const getListDoctorHabitLoading = ref(false)
    const doctorHabitList = ref([])
    const getListDoctorHabit = async facDoctorId => {
        if (getListDoctorHabitLoading.value) return
        getListDoctorHabitLoading.value = true
        let data = await doctorHabitGetList({id: facDoctorId})
        if (data) {
            doctorHabitList.value = data
        }
        getListDoctorHabitLoading.value = false
    }
    return {
        getListDoctorHabitLoading,
        doctorHabitList,
        getListDoctorHabit,
    }
}

export {
    useSaveFacDoctor,
    useDeleteFacDoctor,
    useGetListFacDoctor,
    useUpdateStoreDoctorList,
    useUpdateValidFacDoctor,
    useGetPageFacDoctor,
    useGetFacDoctor,
    useBatchSaveDoctorHabit,
    useGetListDoctorHabit,
}