// 格式化银行账户号显示格式
// 示例：<td v-bank-number></td>

export default {
    name: 'bankNumber',
    directive: (el, binding) => {
        let val = el.innerHtml
        if (val && val.length>4) {
            let input = val.replace(/\s+/g, '');
            let l = input.length;
            // 空格数
            let spl = parseInt((l - 1) / 4);
            for (let i = 1; i <= spl; i++) {
                input = input.slice(0, 4 * i + (i - 1)) + ' ' + input.slice(4 * i + (i - 1));
            }
            el.innerHtml=input
        }
    },
}