<template>
    <div style="font-size: 8px;width: 100px;margin: 3px ;" class="print-tooth">
        <div style="display: flex;-webkit-flex-direction: column;flex-direction: column;">
            <div class="upper">
                <div class="left" :class="{'is-pontic':isPontic}">
                    {{ !!upperJaw ? '上颌' : '' }}
                    {{ !!complete ? '全口' : '' }}
                    <span v-for="item in pos1Arr" :key="item.posPart+''+item.pos"
                          :class="{'pontic':item.isPontic,'begin':item.isBegin,'end':item.isEnd,'missing':item.isMissing}">
                        {{ item.isSingle || item.isMissing || item.isPontic ? item.pos : '' }}
                    </span>
                </div>
                <div class="right" :class="{'is-pontic':isPontic}">
                    <span v-for="item in pos2Arr" :key="item.posPart+''+item.pos"
                          :class="{'pontic':item.isPontic,'begin':item.isBegin,'end':item.isEnd,'missing':item.isMissing}">
                        {{ item.isSingle || item.isMissing || item.isPontic ? item.pos : '' }}
                    </span>
                </div>
            </div>
            <div class="lower">
                <div class="left" :class="{'is-pontic':isPontic}">
                    {{ !!lowerJaw ? '下颌' : '' }}
                    <span v-for="item in pos4Arr" :key="item.posPart+''+item.pos"
                          :class="{'pontic':item.isPontic,'begin':item.isBegin,'end':item.isEnd,'missing':item.isMissing}">
                        {{ item.isSingle || item.isMissing || item.isPontic ? item.pos : '' }}
                    </span>
                </div>
                <div class="right" :class="{'is-pontic':isPontic}">
                    <span v-for="item in pos3Arr" :key="item.posPart+''+item.pos"
                          :class="{'pontic':item.isPontic,'begin':item.isBegin,'end':item.isEnd,'missing':item.isMissing}">
                        {{ item.isSingle || item.isMissing || item.isPontic ? item.pos : '' }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>
<!--牙位组件-->
<!--用法示例-->
<!--重要 table使用一定要加上key-->
<!--<print-tooth-->
<!--    :key="scope.$index"-->
<!--    :pos1="scope.row.pos1"-->
<!--    :pos2="scope.row.pos2"-->
<!--    :pos3="scope.row.pos3"-->
<!--    :pos4="scope.row.pos4"-->
<!--    :upper-jaw="scope.row.upperJaw"-->
<!--    :lower-jaw="scope.row.lowerJaw"-->
<!--    :tooth-type="scope.row.toothType"-->
<!--    :pontic="scope.row.pontic"-->
<!--    :missing1="scope.row.missing1"-->
<!--    :missing2="scope.row.missing2"-->
<!--    :missing3="scope.row.missing3"-->
<!--    :missing4="scope.row.missing4"-->
<!--/>-->
<script setup>
import {ref, reactive, inject, watch, toRef, defineProps, defineEmits, computed, onMounted} from 'vue'
import {getPosObj, getFdiStr} from "@/use/case/useTooth";
import {toothTypeEnum} from "@/util/enum";

const props = defineProps({
    pos1: String,//左上
    pos2: String,//右上
    pos3: String,//右下
    pos4: String,//左下
    upperJaw: Number,
    lowerJaw: Number,
    complete: Number,
    toothType: Number,//牙位类型
    pontic: String,//桥体
    missing1: String,
    missing2: String,
    missing3: String,
    missing4: String,
})
// 牙位类型
const innerToothType = ref(toothTypeEnum.单冠)
watch(() => props.toothType, (newVal, oldVal) => {
    setInnerToothType(newVal)
})
const setInnerToothType = toothType => {
    if (toothType) {
        innerToothType.value = toothType
    } else {
        innerToothType.value = toothTypeEnum.单冠
    }
}
const isPontic = computed(() => !!(innerToothType.value & toothTypeEnum.桥))
const isMissing = computed(() => !!(innerToothType.value & toothTypeEnum.缺失))
// 上下颌
const innerUpperJaw = ref(0)
watch(() => props.upperJaw, (newVal, oldVal) => {
    innerUpperJaw.value = newVal
})
const innerLowerJaw = ref(0)
watch(() => props.lowerJaw, (newVal, oldVal) => {
    innerLowerJaw.value = newVal
})
//牙位对象
const pos1Arr = ref([])
const pos2Arr = ref([])
const pos3Arr = ref([])
const pos4Arr = ref([])
const newPosItem = (pos, posPart) => {
    return {
        pos: pos,//1-8
        posPart: posPart,//1 2 3 4
        fdiStr: getFdiStr(pos, posPart),//A1
        isSingle: false,//单冠
        isMissing: false,//缺失
        isPontic: false,//桥体
        isBegin: false,//桥体开始
        isEnd: false//桥体结束
    }
}
//初始化牙位对象
const initPosArr = () => {
    for (let i = 1; i <= 8; i++) {
        pos1Arr.value.push(newPosItem(i, 1))
        pos1Arr.value.sort((a, b) => b.pos - a.pos)
        pos2Arr.value.push(newPosItem(i, 2))
        pos3Arr.value.push(newPosItem(i, 3))
        pos4Arr.value.push(newPosItem(i, 4))
        pos4Arr.value.sort((a, b) => b.pos - a.pos)
    }
}
initPosArr()
// 根据传入pos修改牙位对象
const changeSingleFromOuter = (val, posPart) => {
    if (posPart === 1) {
        pos1Arr.value.forEach(x => {
            x.isSingle = val && val.includes(x.pos + '')
        })
    } else if (posPart === 2) {
        pos2Arr.value.forEach(x => {
            x.isSingle = val && val.includes(x.pos + '')
        })
    } else if (posPart === 3) {
        pos3Arr.value.forEach(x => {
            x.isSingle = val && val.includes(x.pos + '')
        })
    } else if (posPart === 4) {
        pos4Arr.value.forEach(x => {
            x.isSingle = val && val.includes(x.pos + '')
        })
    }
}
// 根据传入missing修改牙位对象
const changeMissingFromOuter = (val, posPart) => {
    if (posPart === 1) {
        pos1Arr.value.forEach(x => {
            x.isMissing = val && val.includes(x.pos + '')
        })
    } else if (posPart === 2) {
        pos2Arr.value.forEach(x => {
            x.isMissing = val && val.includes(x.pos + '')
        })
    } else if (posPart === 3) {
        pos3Arr.value.forEach(x => {
            x.isMissing = val && val.includes(x.pos + '')
        })
    } else if (posPart === 4) {
        pos4Arr.value.forEach(x => {
            x.isMissing = val && val.includes(x.pos + '')
        })
    }
}

watch(() => props.pos1, (newVal, oldVal) => {
    changeSingleFromOuter(newVal, 1)
})
watch(() => props.missing1, (newVal, oldVal) => {
    changeMissingFromOuter(newVal, 1)
})
watch(() => props.pos2, (newVal, oldVal) => {
    changeSingleFromOuter(newVal, 2)
})
watch(() => props.missing2, (newVal, oldVal) => {
    changeMissingFromOuter(newVal, 2)
})
watch(() => props.pos3, (newVal, oldVal) => {
    changeSingleFromOuter(newVal, 3)
})
watch(() => props.missing3, (newVal, oldVal) => {
    changeMissingFromOuter(newVal, 3)
})
watch(() => props.pos4, (newVal, oldVal) => {
    changeSingleFromOuter(newVal, 4)
})
watch(() => props.missing4, (newVal, oldVal) => {
    changeMissingFromOuter(newVal, 4)
})

const innerPontic = ref('')//桥字符串 多个桥|分割
const ponticArr = ref([])//桥数组 牙位,分割
watch(() => props.pontic, (newVal, oldVal) => {
    if (innerPontic.value === newVal) return
    innerPontic.value = newVal
    setPosFromPonticStr()
})
//根据桥体字符串修改牙位对象
const setPosFromPonticStr = () => {
    //桥体字符串拆分牙位数组
    ponticArr.value = innerPontic.value ? innerPontic.value.split('|') : []
    setPosFromPontic()
}
//根据桥体数组修改牙位对象
const setPosFromPonticArr = () => {
    innerPontic.value = ponticArr.value.length ? ponticArr.value.join('|') : ''
    setPosFromPontic()
}
const setPosFromPontic = () => {
    let beginArr = []//桥开始牙位
    let endArr = []//桥结束牙位
    let allPonticArr = []//所有桥牙位
    for (let item of ponticArr.value) {
        let posArr = item.split(',')
        allPonticArr = allPonticArr.concat(posArr)
        if (posArr.length) {
            beginArr.push(posArr[0])
            endArr.push(posArr[posArr.length - 1])
        }
    }
    pos1Arr.value.forEach(x => setPosPontic(x, beginArr, endArr, allPonticArr))
    pos2Arr.value.forEach(x => setPosPontic(x, beginArr, endArr, allPonticArr))
    pos3Arr.value.forEach(x => setPosPontic(x, beginArr, endArr, allPonticArr))
    pos4Arr.value.forEach(x => setPosPontic(x, beginArr, endArr, allPonticArr))
}
//设置某个牙位桥的情况
const setPosPontic = (tooth, beginArr, endArr, allPonticArr) => {
    tooth.isBegin = beginArr && beginArr.includes(tooth.fdiStr)
    tooth.isEnd = endArr && endArr.includes(tooth.fdiStr)
    tooth.isPontic = allPonticArr && allPonticArr.includes(tooth.fdiStr)
    if (tooth.isPontic) tooth.isSingle = false
}

// 根据牙位对象获取显示状态

onMounted(() => {
    innerUpperJaw.value = props.upperJaw
    innerLowerJaw.value = props.lowerJaw

    setInnerToothType(props.toothType)
    innerPontic.value = props.pontic

    setPosFromPonticStr()

    changeSingleFromOuter(props.pos1, 1)
    changeSingleFromOuter(props.pos2, 2)
    changeSingleFromOuter(props.pos3, 3)
    changeSingleFromOuter(props.pos4, 4)

    changeMissingFromOuter(props.missing1, 1)
    changeMissingFromOuter(props.missing2, 2)
    changeMissingFromOuter(props.missing3, 3)
    changeMissingFromOuter(props.missing4, 4)
})
</script>

<style scoped lang="scss">
//打印样式要在print-lock.css里面复制过去一份！！！！
.print-tooth {
    .upper {
        display: flex;
        -webkit-flex-direction: row;
        flex-direction: row;
        border-bottom: 1px solid grey;
    }

    .lower {
        display: flex;
        -webkit-flex-direction: row;
        flex-direction: row;
    }

    .left {
        border-right: 1px solid grey;
        width: 50%;
        height: 15px;
        padding-right: 2px;
        text-align: right;
    }

    .left.is-pontic {
        padding-right: 0;
    }

    .right {
        width: 50%;
        height: 15px;
        padding-left: 2px;
        text-align: left;
    }

    .right.is-pontic {
        padding-left: 0;
    }

    .upper .pontic {
        border-top: 1px solid black;
    }

    .lower .pontic {
        border-bottom: 1px solid black;
    }

    .pontic.begin {
        margin-left: 2px;
        padding-left: 2px;
        border-left: 1px solid black;
    }

    .pontic.end {
        margin-right: 2px;
        padding-right: 2px;
        border-right: 1px solid black
    }

    .missing {
        text-decoration: line-through
    }
}
</style>