<template>
    <div>
        <el-radio-group v-model="currentStepProduct" @change="changeCaseProduct">
            <el-radio-button v-for="item in caseProductStepSimpleList" :label="item.caseProductId"
                             :key="item.caseProductId">
                {{ item.productName }}
            </el-radio-button>
        </el-radio-group>
        <!--        <el-table border stripe :data="showStepList" style="width:700px;">-->
        <!--            <el-table-column label="生产流程" align="center">-->
        <!--                <el-table-column label="制作工序" align="center" prop="stepName" width="180"-->
        <!--                                 class-name="text-danger"></el-table-column>-->
        <!--                <el-table-column label="制作人" align="center" prop="workerName" width="120"></el-table-column>-->
        <!--                <el-table-column label="开始时间" align="center" :formatter="minuteFormat" prop="operTime"-->
        <!--                                 min-width="110"></el-table-column>-->
        <!--                <el-table-column label="完成时间" align="center" :formatter="minuteFormat" prop="finishTime"-->
        <!--                                 min-width="110"></el-table-column>-->
        <!--            </el-table-column>-->
        <!--        </el-table>-->
        <el-timeline style="margin-top: 20px;">
            <el-timeline-item v-for="(item,index) in showStepList" :timestamp="item.operTime"
                              :type="index===0?'primary':''"
                              placement="top">
                <el-space :size="10">
                    <h4>{{ item.workerName }}</h4>
                    <el-tag>{{ item.stepName }}</el-tag>
                    <span v-if="!!item.finishTime">
                                完成时间：{{ txtDatetimeFormat(item.finishTime) }}
                        <!--                            {{-->
                        <!--                                    getStepOperTypeName(item.operType)-->
                        <!--                                }}-->
                            </span>
                </el-space>
            </el-timeline-item>
        </el-timeline>
    </div>
</template>

<script setup>
import {ref, computed, watch} from 'vue'
import {stepOperType, getEnumList} from "@/util/enum";
import {useGetSimpleListCaseProductStep} from "@/use/produce/useCaseProductStep";
import {txtDatetimeFormat} from "@/util/formatter";

const props = defineProps({
    id: Number,//订单ID
})
const {
    caseProductStepSimpleList,
    getSimpleListCaseProductStep
} = useGetSimpleListCaseProductStep()
watch(() => props.id, (n, o) => {
    if (!!n) {
        chooseStep()
    } else {
        currentStepProduct.value = 0
    }
})
//当前显示生产流程的产品
const currentStepProduct = ref(0)
//当前显示的生产流程
// const showStepSimpleList = computed(() => {
//     let item = caseProductStepSimpleList.value.find(x => x.caseProductId === currentStepProduct.value)
//     if (item && item.stepList && item.stepList.length) {
//         return item.stepList
//     } else {
//         return []
//     }
// })
const chooseStep = async () => {
    if (!props.id) {
        currentStepProduct.value = 0
        productStep.value = []
        return
    }
    await getSimpleListCaseProductStep(props.id)
    // caseProductStepSimpleList.value.forEach(x => {
    //     if (x.stepList && x.stepList.length) x.stepList = x.stepList.reverse()
    // })
    currentStepProduct.value = caseProductStepSimpleList.value[0].caseProductId
    changeCaseProduct()
}
const stepOperTypeList = getEnumList(stepOperType)
const getStepOperTypeName = id => {
    let item = stepOperTypeList.find(x => x.id === id)
    if (item) return item.name
}
const changeCaseProduct = () => {
    let stepInfo = caseProductStepSimpleList.value.find(x => x.caseProductId === currentStepProduct.value)
    getShowStepList(stepInfo)
}
// 显示制作工序记录
const showStepList = ref([])
// 根据后台获取的stepInfo组装制作工序记录
const getShowStepList = stepInfo => {
    showStepList.value = []
    if (stepInfo.inDisinfect) {
        showStepList.value.push({
            stepName: '入件消毒',
            workerName: stepInfo.inDisinfect.operWorkerName,
            operTime: stepInfo.inDisinfect.beginTime,
            finishTime: stepInfo.inDisinfect.endTime,
        })
    }
    if (stepInfo.stepList) {
        let stepList = stepInfo.stepList.filter(x => x.operType === stepOperType.制作接收)
        for (let item of stepList) {
            showStepList.value.push(item)
        }
    }
    if (stepInfo.outDisinfect) {
        showStepList.value.push({
            stepName: '出件消毒',
            workerName: stepInfo.outDisinfect.operWorkerName,
            operTime: stepInfo.outDisinfect.beginTime,
            finishTime: stepInfo.outDisinfect.endTime,
        })
    }
    showStepList.value.reverse()
}
</script>