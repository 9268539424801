import {reactive, ref} from 'vue'
import {
    materialTypeSave,
    materialTypeDelete,
    materialTypeGetList,
    materialTypeSort
} from '@/api/purchase'
import {useStore} from "vuex";

function useSaveMaterialType() {
    const materialType = reactive({
        id: 0,
        name: '',
        typeNo: '',
        levelId: 0,
        parentId: 0,
    })
    const initMaterialType = () => {
        materialType.id = 0
        materialType.typeNo = ''
        materialType.name = ''
        materialType.parentId = 0
        materialType.levelId = 0
    }
    const materialTypeFormRef = ref()
    const materialTypeSaveRules = reactive({
        name: [
            {required: true, message: '请输入名称', trigger: 'blur'},
        ]
    })
    const saveMaterialTypeLoading = ref(false)
    const saveMaterialTypeResult = ref(false)
    const saveMaterialType = async () => {
        saveMaterialTypeResult.value = false
        if (saveMaterialTypeLoading.value) return
        await materialTypeFormRef.value.validate(async (valid, fields) => {
            if (!valid) {
                return
            }
            saveMaterialTypeLoading.value = true
            saveMaterialTypeResult.value = await materialTypeSave(materialType)
            saveMaterialTypeLoading.value = false
        })
    }
    return {
        materialType,
        initMaterialType,
        materialTypeFormRef,
        materialTypeSaveRules,
        saveMaterialTypeResult,
        saveMaterialTypeLoading,
        saveMaterialType
    }
}

function useSortMaterialType() {
    const sortMaterialTypeLoading = ref(false)
    const sortMaterialTypeResult = ref(false)
    const sortMaterialType = async param => {
        sortMaterialTypeResult.value = false
        if (sortMaterialTypeLoading.value) return
        sortMaterialTypeLoading.value = true
        sortMaterialTypeResult.value = await materialTypeSort(param)
        sortMaterialTypeLoading.value = false
    }
    return {
        sortMaterialTypeLoading,
        sortMaterialTypeResult,
        sortMaterialType
    }
}

function useGetListMaterialType() {
    const materialTypeList = ref([])
    const getListMaterialTypeLoading = ref(false)
    const getListMaterialType = async () => {
        if (getListMaterialTypeLoading.value) return
        getListMaterialTypeLoading.value = true
        let data = await materialTypeGetList({})
        if (data) {
            materialTypeList.value = data
        }
        getListMaterialTypeLoading.value = false
    }
    return {
        materialTypeList,
        getListMaterialType,
        getListMaterialTypeLoading
    }
}

function useUpdateStoreMaterialType() {
    const updateStoreMaterialTypeLoading = ref(false)
    const store = useStore()
    const updateStoreMaterialType = async () => {
        if (updateStoreMaterialTypeLoading.value) return
        updateStoreMaterialTypeLoading.value = true
        let res = await materialTypeGetList({})
        store.commit('setMaterialType', (res && res.length) ? res : [])
        updateStoreMaterialTypeLoading.value = false
    }
    return {
        updateStoreMaterialTypeLoading,
        updateStoreMaterialType
    }
}

function useDeleteMaterialType() {
    const deleteMaterialTypeLoading = ref(false)
    const deleteMaterialTypeResult = ref(false)
    const deleteMaterialType = async id => {
        deleteMaterialTypeResult.value = false
        if (deleteMaterialTypeLoading.value) return
        deleteMaterialTypeLoading.value = true
        let param = {
            id: id
        }
        deleteMaterialTypeResult.value = await materialTypeDelete(param)
        deleteMaterialTypeLoading.value = false
    }
    return {
        deleteMaterialTypeLoading,
        deleteMaterialTypeResult,
        deleteMaterialType
    }
}

export {
    useSaveMaterialType,
    useSortMaterialType,
    useGetListMaterialType,
    useUpdateStoreMaterialType,
    useDeleteMaterialType,
}