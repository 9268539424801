import {reactive, ref, inject, toRaw} from 'vue'
import moment from 'moment'
import {
    facHospitalSave,
    facHospitalDelete,
    facHospitalGetList,
    facHospitalGetPage,
    facHospitalUpdateValid,
    facHospitalBatchValid,
    facHospitalUpdateDelivery,
    facHospitalGet,
    facHospitalListExportExcel,
    facHospitalGetNeedHideList,
    facHospitalBatchUpdateNeedHide
} from '@/api/customer'
import {useStore} from "vuex";
import {ElMessage} from "element-plus";
import {sendTypeEnum, receiverTypeEnum, customerTypeEnum, categoryEnum, getEnumList} from "@/util/enum";
import http from "@/util/axios";
import {useDownload} from "@/use/system/useFile";

//保存工厂医院 oycm 2022/7/22
function useSaveFacHospital() {
    const facHospital = reactive({
        id: 0,
        hospitalNo: '',
        hospitalType: customerTypeEnum.公立医院,
        category: categoryEnum.三类 | categoryEnum.二类,
        salesman: null,
        name: '',
        fullName: '',
        contactPerson: '',
        contactPhoneNo: '',
        province: '',
        city: '',
        area: '',
        address: '',
        district: '',
        managerName: '',
        managerPhoneNo: '',
        coopBeginDate: '',
        chairNum: 0,
        agentId: '',
        isInvoice: 1,
        invoiceType: '',
        invoiceStatus: '',
        caseOutTemplateId: '',
        payType: '',
        payCycle: 1,
        credit: 0,
        zipCode: '',
        landline: '',
        faxNo: '',
        website: '',
        emailAddress: '',
        bidName: '',
        bidBeginDate: '',
        bidEndDate: '',
        remark: '',
        isDelivery: 1,
        isVip: 0,
        isValid: 1,
        invoiceHeader: '',
        invoiceAddress: '',
        sendType: null,
        receiverType: null,
        storePlace: '',
        expressCompany: '',
        isCollect: 0,
        shipPerson: '',
        shipPhoneNo: '',
        shipRemark: '',
        licenseNo: '',
        auditState: 0,
        retailBan: 1,
    })
    const categoryIds = ref([])
    const facHospitalEmpty = JSON.parse(JSON.stringify(toRaw(facHospital)))
    const setFields = inject('setFields')
    const initFacHospital = () => {
        setFields(facHospital, facHospitalEmpty)
    }
    const facHospitalFormRef = ref()
    const facHospitalSaveRules = reactive({
        name: [
            {required: true, message: '请输入名称', trigger: 'blur'},
        ],
        fullName: [
            {required: true, message: '请输入全称', trigger: 'blur'},
        ],
        hospitalType: [
            {required: true, message: '请选择医院类型', trigger: 'change'},
        ],
    })
    const validateFacHospital = () => {
        if (facHospital.sendType === sendTypeEnum.销售 && !facHospital.salesman) {
            return '发货方式为销售时，请设置销售代表'
        }
        if (facHospital.receiverType === receiverTypeEnum.销售 && !facHospital.salesman) {
            return '收货方式为销售时，请设置销售代表'
        }
        return ''
    }
    const saveFacHospitalLoading = ref(false)
    const saveFacHospitalResult = ref(false)
    /**
     * 根据category值数组获取category
     */
    const getCategory = () => {
        if (categoryIds.value.length) {
            facHospital.category = categoryIds.value.reduce((a, b) => a | b)
        } else {
            facHospital.category = 0
        }
    }
    const categoryEnumList = getEnumList(categoryEnum)
    /**
     * 根据category获取category值数组
     */
    const setCategory = () => {
        categoryIds.value = []
        if (facHospital.category) {
            for (let item of categoryEnumList) {
                if (item.id & facHospital.category) {
                    categoryIds.value.push(item.id)
                }
            }
        }
    }
    const saveFacHospital = async () => {
        saveFacHospitalResult.value = false
        let errorMessage = validateFacHospital()
        if (!!errorMessage) {
            ElMessage.error(errorMessage)
            return
        }
        if (saveFacHospitalLoading.value) return
        getCategory()
        saveFacHospitalLoading.value = true
        await facHospitalFormRef.value.validate(async (valid, fields) => {
            if (!valid) {
                saveFacHospitalLoading.value = false
                return
            }
            saveFacHospitalResult.value = await facHospitalSave(facHospital)
            saveFacHospitalLoading.value = false
        })
    }
    return {
        facHospital,
        initFacHospital,
        facHospitalFormRef,
        facHospitalSaveRules,
        saveFacHospitalLoading,
        saveFacHospitalResult,
        saveFacHospital,
        categoryIds,
        getCategory,
        setCategory,
        categoryEnumList
    }
}

//删除工厂医院 oycm 2022/7/22
function useDeleteFacHospital() {
    const deleteFacHospitalLoading = ref(false)
    const deleteFacHospitalResult = ref(false)
    const deleteFacHospital = async id => {
        deleteFacHospitalResult.value = false
        if (deleteFacHospitalLoading.value) return
        deleteFacHospitalLoading.value = true
        deleteFacHospitalResult.value = await facHospitalDelete({id: id})
        deleteFacHospitalLoading.value = false
    }
    return {
        deleteFacHospitalLoading,
        deleteFacHospitalResult,
        deleteFacHospital,
    }
}

//列表工厂医院 oycm 2022/8/9
function useGetListFacHospital() {
    const getListFacHospitalLoading = ref(false)
    const facHospitalList = ref([])
    const getListFacHospital = async () => {
        if (getListFacHospitalLoading.value) return
        getListFacHospitalLoading.value = true
        let data = await facHospitalGetList({})
        if (data) {
            facHospitalList.value = data
        }
        getListFacHospitalLoading.value = false
    }
    const store = useStore()
    const updateStoreHospitalDetail = async () => {
        await getListFacHospital()
        store.commit('setHospitalDetails', facHospitalList.value)
        let hospitalCount = store.state.hospitalCount
        let hospitalList = facHospitalList.value.map(x => {
            let hospital = hospitalCount.find(x => x.hospitalId === x.id)
            let count = hospital ? hospital.count : 0
            return {
                id: x.id,
                name: x.name,
                fullName: x.fullName,
                pinyin: x.namePy,
                code: x.hospitalNo,
                extra1: x.salesman,
                extra2: '',
                extra3: '',
                parentId: null,
                hospitalTypeId: x.hospitalType,
                count: count
            }
        })
        store.commit('setHospitalList', hospitalList)
    }
    return {
        getListFacHospitalLoading,
        facHospitalList,
        updateStoreHospitalDetail,
        getListFacHospital,
    }
}

//分页工厂医院 oycm 2022/7/22
function useGetPageFacHospital() {
    const getPageFacHospitalParam = reactive({
        id: null,
        name: '',
        hospitalNo: '',
        hospitalType: '',
        salesman: null,
        isValid: null,
        district: '',
        province: '',
        city: '',
        area: '',
        managerName: '',
        managerPhoneNo: '',
        agentId: '',
        salesmanArr: null,
        isAudit: null,
        isDelivery: -1,
        pageIndex: 1,
        pageSize: 10,
    })
    const getPageFacHospitalParamEmpty = JSON.parse(JSON.stringify(toRaw(getPageFacHospitalParam)))
    const setFields = inject('setFields')
    const initGetPageFacHospitalParam = () => {
        setFields(getPageFacHospitalParam, getPageFacHospitalParamEmpty)
    }
    const getPageFacHospitalLoading = ref(false)
    const facHospitalPage = ref([])
    const getPageFacHospitalResult = reactive({
        totalCount: 0,
        totalPages: 0,
    })
    const getPageFacHospital = async () => {
        if (getPageFacHospitalLoading.value) return
        getPageFacHospitalLoading.value = true
        let data = await facHospitalGetPage(getPageFacHospitalParam)
        if (data && data.list && data.list.length) {
            facHospitalPage.value = data.list
            getPageFacHospitalResult.totalPages = Math.ceil(data.totalCount / getPageFacHospitalParam.pageSize)
            getPageFacHospitalResult.totalCount = data.totalCount
        } else {
            facHospitalPage.value = []
            getPageFacHospitalResult.totalPages = 0
            getPageFacHospitalResult.totalCount = 0
        }
        getPageFacHospitalLoading.value = false
    }
    return {
        getPageFacHospitalParam,
        initGetPageFacHospitalParam,
        getPageFacHospitalLoading,
        facHospitalPage,
        getPageFacHospitalResult,
        getPageFacHospital,
    }
}

//停用启用医院
function useUpdateValidFacHospital() {
    const updateValidFacHospitalLoading = ref(false)
    const updateValidFacHospitalResult = ref(false)
    const updateValidFacHospital = async (id, isValid) => {
        updateValidFacHospitalResult.value = false
        if (updateValidFacHospitalLoading.value) return
        updateValidFacHospitalLoading.value = true
        updateValidFacHospitalResult.value = await facHospitalUpdateValid({id: id, isValid: isValid})
        updateValidFacHospitalLoading.value = false
    }
    return {
        updateValidFacHospitalLoading,
        updateValidFacHospitalResult,
        updateValidFacHospital,
    }
}

// 批量停用
function useBatchValidFacHospital() {
    const batchValidFacHospitalLoading = ref(false)
    const batchValidFacHospitalResult = ref(false)
    const batchValidFacHospital = async ids => {
        batchValidFacHospitalResult.value = false
        if (batchValidFacHospitalLoading.value) return
        batchValidFacHospitalLoading.value = true
        batchValidFacHospitalResult.value = await facHospitalBatchValid({ids: ids})
        batchValidFacHospitalLoading.value = false
    }
    return {
        batchValidFacHospitalLoading,
        batchValidFacHospitalResult,
        batchValidFacHospital,
    }
}

//发货/停止发货
function useUpdateDeliveryFacHospital() {
    const updateDeliveryFacHospitalLoading = ref(false)
    const updateDeliveryFacHospitalResult = ref(false)
    const updateDeliveryFacHospital = async (id, isDelivery) => {
        updateDeliveryFacHospitalResult.value = false
        if (updateDeliveryFacHospitalLoading.value) return
        updateDeliveryFacHospitalLoading.value = true
        updateDeliveryFacHospitalResult.value = await facHospitalUpdateDelivery({id: id, isDelivery: isDelivery})
        updateDeliveryFacHospitalLoading.value = false
    }
    return {
        updateDeliveryFacHospitalLoading,
        updateDeliveryFacHospitalResult,
        updateDeliveryFacHospital,
    }
}

// 获取
function useGetFacHospital() {
    const getFacHospitalLoading = ref(false)
    const getFacHospital = async id => {
        if (getFacHospitalLoading.value) return
        getFacHospitalLoading.value = true
        let res = await facHospitalGet({id: id})
        getFacHospitalLoading.value = false
        if (res) return res
    }
    return {
        getFacHospital,
        getFacHospitalLoading
    }
}

// 导出医院列表
const useExportHospitalExcel = () => {
    const {
        downFileLoading,
        downFile
    } = useDownload()
    const exportHospitalExcel = req => {
        req.token = sessionStorage.getItem('token')
        let url = facHospitalListExportExcel + '?' + http.getQueryFromJson(req)
        downFile(url, '客户列表')
    }
    return {
        exportHospitalExcelLoading: downFileLoading,
        exportHospitalExcel
    }
}

//获取隐藏的医院列表
const useGetNeedHideListHospital = () => {
    const getNeedHideListHospitalLoading = ref(false)
    const hospitalNeedHideList = ref([])
    const getNeedHideListHospital = async () => {
        if (getNeedHideListHospitalLoading.value) return
        getNeedHideListHospitalLoading.value = true
        let data = await facHospitalGetNeedHideList({})
        if (data) {
            hospitalNeedHideList.value = data
            if (hospitalNeedHideList.value && hospitalNeedHideList.value.length) {
                hospitalNeedHideList.value.forEach(x => x.isChecked = false)
            }
        }
        getNeedHideListHospitalLoading.value = false
    }
    return {
        getNeedHideListHospitalLoading,
        hospitalNeedHideList,
        getNeedHideListHospital,
    }
}

//批量取消隐藏医院
function useBatchUpdateNeedHideHospital() {
    const batchUpdateNeedHideHospitalLoading = ref(false)
    const batchUpdateNeedHideHospitalResult = ref(false)
    const batchUpdateNeedHideHospital = async (list) => {
        batchUpdateNeedHideHospitalResult.value = false
        if (batchUpdateNeedHideHospitalLoading.value) return
        batchUpdateNeedHideHospitalLoading.value = true
        batchUpdateNeedHideHospitalResult.value = await facHospitalBatchUpdateNeedHide(list)
        batchUpdateNeedHideHospitalLoading.value = false
    }
    return {
        batchUpdateNeedHideHospitalLoading,
        batchUpdateNeedHideHospitalResult,
        batchUpdateNeedHideHospital,
    }
}

export {
    useSaveFacHospital,
    useDeleteFacHospital,
    useGetListFacHospital,
    useGetPageFacHospital,
    useUpdateValidFacHospital,
    useExportHospitalExcel,
    useBatchValidFacHospital,
    useGetFacHospital,
    useGetNeedHideListHospital,
    useBatchUpdateNeedHideHospital,
}