const cnPhoneNoReg = /^1(3|4|5|6|7|8|9)\d{9}$/;

// 手机号校验
function isPhoneNumber(phoneNum) {
    // let reg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
    /*
      * 移动号码包括的号段：134/135/136/137,138,139；
    *                     147/148(物联卡号)；
    *                     150/151/152/157/158/159；
    *                     165（虚拟运营商）；
    *                     1703/1705/1706（虚拟运营商）、178；
    *                     182/183/184/187/188
    *                     198

    * 联通号段包括：130/131
    *               145
    *               155/156
    *               166/167(虚拟运营商)
    *               1704/1707/1708/1709、171
    *               186/186
    *
    * 电信号段包括： 133
    *                153
    *                162(虚拟运营商)
    *                1700/1701/1702(虚拟运营商)
    *                180/181/189
    *                191/199
    * */
    return cnPhoneNoReg.test(phoneNum);
}

const positiveIntReg = /^[1-9]\d*$/

function isPositiveInt(number) {
    return positiveIntReg.test(number)
}

const positiveNoReg = /^(0\.0*[1-9]+[0-9]*$|[1-9]+[0-9]*\.[0-9]*[0-9]$|[1-9]+[0-9]*$)/

function isPositiveNo(number) {
    return positiveNoReg.test(number)
}

export {
    cnPhoneNoReg,
    isPhoneNumber,
    positiveIntReg,
    isPositiveInt,
    positiveNoReg,
    isPositiveNo,
}