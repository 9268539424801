import {reactive, ref, inject} from 'vue'
import {auditFormAudit, auditFormGet, auditFormApply, auditFormCancelAudit} from "@/api/system";
import {ElMessage} from "element-plus";

// 申请审核
function useApplyAuditForm() {
    const applyFormParam = reactive({
        formId: '',
        formType: '',
        formInfo: '',
    })
    const applyFormLoading = ref(false)
    const applyFormResult = ref(false)
    const applyForm = async () => {
        if (applyFormLoading.value) return
        applyFormLoading.value = true
        applyFormResult.value = false
        applyFormResult.value = await auditFormApply(applyFormParam)
        applyFormLoading.value = false
    }
    return {
        applyForm,
        applyFormResult,
        applyFormLoading,
        applyFormParam
    }
}

// 弃审
function useCancelAudit() {
    const cancelAuditParam = reactive({
        formId: '',
        formType: '',
        formInfo: '',
        auditWorkerId: '',
        nodeId: '',
        remark: '',
    })
    const cancelAuditLoading = ref(false)
    const cancelAuditResult = ref(false)
    const cancelAudit = async () => {
        if (cancelAuditLoading.value) return
        cancelAuditLoading.value = true
        cancelAuditResult.value = false
        cancelAuditResult.value = await auditFormCancelAudit(cancelAuditParam)
        cancelAuditLoading.value = false
    }
    return {
        cancelAudit,
        cancelAuditResult,
        cancelAuditLoading,
        cancelAuditParam
    }
}

// 审核
function useAuditForm() {
    const formToAudit = reactive({
        formId: '',
        formType: '',
        auditWorkerId: '',
        auditState: '',
        remark: ''
    })
    const emptyForm = Object.assign({}, formToAudit)
    const initForm = () => {
        Object.assign(formToAudit, emptyForm)
    }
    const validForm = () => {
        if (!formToAudit.formId) {
            ElMessage.error('请选择要审核的表单')
            return false
        }
        if (!formToAudit.formType) {
            ElMessage.error('请选择要审核的表单类型')
            return false
        }
        if (!formToAudit.auditWorkerId) {
            ElMessage.error('请选择审核员工')
            return false
        }
        if (!formToAudit.auditState) {
            ElMessage.error('请选择审核状态')
            return false
        }
        return true
    }
    const auditLoading = ref(false)
    const auditResult = ref(false)
    const auditForm = async () => {
        if (auditLoading.value) return
        if (!validForm()) return
        auditLoading.value = true
        auditResult.value = false
        auditResult.value = await auditFormAudit(formToAudit)
        auditLoading.value = false
    }

    return {
        formToAudit,
        initForm,
        auditLoading,
        auditResult,
        auditForm,
    }
}

// 获取审核过程
function useGetAuditContent() {
    const initFields = inject('initFields')
    const auditContent = reactive({
        id: '',
        formId: '',
        auditState: '',
        orderId: '',
        factoryId: '',
        updateTime: '',
        formType: '',
        nodeList: []
    })
    const initAuditContent = () => {
        initFields(auditContent)
    }
    const getAuditContentLoading = ref(false)
    const getAuditContent = async (formId, formType) => {
        if (getAuditContentLoading.value) return
        initFields(auditContent)
        getAuditContentLoading.value = true
        let res = await auditFormGet({
            formId: formId,
            formType: formType
        })
        if (!!res) {
            Object.assign(auditContent, res)
        }
        getAuditContentLoading.value = false
    }
    return {
        initAuditContent,
        getAuditContent,
        getAuditContentLoading,
        auditContent
    }
}

export {
    useCancelAudit,
    useApplyAuditForm,
    useAuditForm,
    useGetAuditContent
}