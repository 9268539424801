import {reactive, ref, inject, toRaw} from 'vue'
import moment from 'moment'
import {stockAlertGetPage, stockAlertGetTotalNum} from "@/api/warehouse";

// 库存提醒分页
function useGetPageStockAlert() {
    const getPageStockAlertParam = reactive({
        alertType: null,
        warehouseId: null,
        isHandle: 0,
        pageIndex: 1,
        pageSize: 10,
    })
    const getPageStockAlertParamEmpty = Object.assign({}, getPageStockAlertParam)
    const initGetPageStockAlertParam = () => {
        Object.assign(getPageStockAlertParam, getPageStockAlertParamEmpty)
    }
    const getPageStockAlertLoading = ref(false)
    const stockAlertPage = ref([])
    const getPageStockAlertResult = reactive({
        totalCount: 0,
        totalPages: 0,
    })
    const getPageStockAlert = async () => {
        if (getPageStockAlertLoading.value) return
        getPageStockAlertLoading.value = true
        let data = await stockAlertGetPage(getPageStockAlertParam)
        if (data && data.list && data.list.length) {
            stockAlertPage.value = data.list
            getPageStockAlertResult.totalPages = Math.ceil(data.totalCount / getPageStockAlertParam.pageSize)
            getPageStockAlertResult.totalCount = data.totalCount
        } else {
            stockAlertPage.value = []
            getPageStockAlertResult.totalPages = 0
            getPageStockAlertResult.totalCount = 0
        }
        getPageStockAlertLoading.value = false
    }
    return {
        getPageStockAlertParam,
        initGetPageStockAlertParam,
        getPageStockAlertLoading,
        stockAlertPage,
        getPageStockAlertResult,
        getPageStockAlert,
    }
}

function useGetStockAlertTotalNum() {
    const getStockAlertTotalNum = async () => {
        let res = await stockAlertGetTotalNum()
        return res ? res : 0
    }
    return {
        getStockAlertTotalNum
    }
}

export {
    useGetPageStockAlert,
    useGetStockAlertTotalNum
}