<template>
    <div class="flex-column tooth-container">
        <el-space>
            <el-radio-group v-model="chooseToothType" size="small" @change="changeChooseToothType">
                <el-radio-button v-for="item in toothTypeList" :key="item.id" :label="item.id"
                                 :class="{'radio-success':item.id===toothTypeEnum.桥,'radio-danger':item.id===toothTypeEnum.缺失}">
                    {{ item.name }}
                </el-radio-button>
            </el-radio-group>
            <el-checkbox label="全口" v-model="innerComplete" :true-label="1" :false-label="0"
                         @change="changeComplete"></el-checkbox>
            <el-checkbox label="上颌" v-model="innerUpperJaw" :true-label="1" :false-label="0"
                         @change="changeUpperJaw"></el-checkbox>
            <el-checkbox label="下颌" v-model="innerLowerJaw" :true-label="1" :false-label="0"
                         @change="changeLowerJaw"></el-checkbox>
        </el-space>
        <div class="flex-column">
            <div class="flex-row border-bottom-dash">
                <div class="border-right-dash tooth-part tooth-left">
                    <el-space :size="0">
                        <el-check-tag v-for="item in pos1Arr" :checked="item.isSingle||item.isMissing||item.isPontic"
                                      @change="changePos(item)" size="small" class="tooth" :class="getToothClass(item)"
                                      :key="item.posPart+''+item.pos">
                            {{ item.pos }}
                        </el-check-tag>
                    </el-space>
                </div>
                <div class="tooth-part tooth-right">
                    <el-check-tag v-for="item in pos2Arr" :checked="item.isSingle||item.isMissing||item.isPontic"
                                  @change="changePos(item)" size="small" class="tooth" :class="getToothClass(item)"
                                  :key="item.posPart+''+item.pos">
                        {{ item.pos }}
                    </el-check-tag>
                </div>
            </div>
            <div class="flex-row">
                <div class="border-right-dash tooth-part tooth-left">
                    <el-check-tag v-for="item in pos4Arr" :checked="item.isSingle||item.isMissing||item.isPontic"
                                  @change="changePos(item)" size="small" class="tooth" :class="getToothClass(item)"
                                  :key="item.posPart+''+item.pos">
                        {{ item.pos }}
                    </el-check-tag>
                </div>
                <div class="tooth-part tooth-right">
                    <el-check-tag v-for="item in pos3Arr" :checked="item.isSingle||item.isMissing||item.isPontic"
                                  @change="changePos(item)" size="small" class="tooth" :class="getToothClass(item)"
                                  :key="item.posPart+''+item.pos">
                        {{ item.pos }}
                    </el-check-tag>
                </div>
            </div>
        </div>
    </div>
</template>
<!--牙位组件-->
<!--用法示例-->
<!--重要 table使用一定要加上key-->
<!--<table-tooth-input-->
<!--    :key="scope.$index"-->
<!--    v-model:pos1="scope.row.pos1"-->
<!--    v-model:pos2="scope.row.pos2"-->
<!--    v-model:pos3="scope.row.pos3"-->
<!--    v-model:pos4="scope.row.pos4"-->
<!--    v-model:tooth-type="scope.row.toothType"-->
<!--    v-model:lower-jaw="scope.row.lowerJaw"-->
<!--    v-model:upper-jaw="scope.row.upperJaw"-->
<!--    v-model:pontic="scope.row.pontic"-->
<!--    v-model:missing1="scope.row.missing1"-->
<!--    v-model:missing2="scope.row.missing2"-->
<!--    v-model:missing3="scope.row.missing3"-->
<!--    v-model:missing4="scope.row.missing4"-->
<!--    @change-tooth="val=>setProductNum(val,scope.row)"-->
<!--/>-->
<script setup>
import {ref, reactive, inject, watch, toRef, defineProps, defineEmits, computed, onMounted} from 'vue'
import {toothTypeEnum, getEnumList, getEnumName} from "@/util/enum";
import {isAfter, getFdiStr, isUpper, isLower, getBetween} from "@/use/case/useTooth";

const props = defineProps({
    pos1: String,//左上
    pos2: String,//右上
    pos3: String,//右下
    pos4: String,//左下
    upperJaw: Number,
    lowerJaw: Number,
    complete: Number,
    toothType: Number,//牙位类型
    pontic: String,//桥体
    missing1: String,
    missing2: String,
    missing3: String,
    missing4: String,
})
const emits = defineEmits(['update:pos1', 'update:pos2', 'update:pos3', 'update:pos4', 'update:upperJaw', 'update:lowerJaw', 'update:toothType', 'changeTooth',
    'update:missing1', 'update:missing2', 'update:missing3', 'update:missing4', 'update:pontic', 'update:complete'])

const toothTypeList = getEnumList(toothTypeEnum)
const innerToothType = ref(toothTypeEnum.单冠)//牙位类型
const chooseToothType = ref(toothTypeEnum.单冠)//当前选择的牙位类型
watch(() => props.toothType, (newVal, oldVal) => {
    if (newVal) innerToothType.value = newVal ? newVal : toothTypeEnum.单冠
})
const setInnerToothType = toothType => {
    if (toothType) {
        innerToothType.value = toothType
        if (innerToothType.value & toothTypeEnum.单冠) {
            chooseToothType.value = toothTypeEnum.单冠
        } else if (innerToothType.value & toothTypeEnum.桥) {
            chooseToothType.value = toothTypeEnum.桥
        } else if (innerToothType.value & toothTypeEnum.缺失) {
            chooseToothType.value = toothTypeEnum.缺失
        }
    } else {
        innerToothType.value = toothTypeEnum.单冠
        chooseToothType.value = toothTypeEnum.单冠
    }
}
const innerUpperJaw = ref(0)
watch(() => props.upperJaw, (newVal, oldVal) => {
    innerUpperJaw.value = newVal
})
const innerLowerJaw = ref(0)
watch(() => props.lowerJaw, (newVal, oldVal) => {
    innerLowerJaw.value = newVal
})
const innerComplete = ref(0)
watch(() => props.complete, (newVal, oldVal) => {
    innerComplete.value = newVal
})
const pos1Arr = ref([])//牙位对象
const pos2Arr = ref([])
const pos3Arr = ref([])
const pos4Arr = ref([])
const newPosItem = (pos, posPart) => {
    return {
        pos: pos,//1-8
        posPart: posPart,//1 2 3 4
        fdiStr: getFdiStr(pos, posPart),//A1
        isSingle: false,//单冠
        isMissing: false,//缺失
        isPontic: false,//桥体
        isBegin: false,//桥体开始
        isEnd: false//桥体结束
    }
}
//初始化牙位对象
const initPosArr = () => {
    for (let i = 1; i <= 8; i++) {
        pos1Arr.value.push(newPosItem(i, 1))
        pos1Arr.value.sort((a, b) => b.pos - a.pos)
        pos2Arr.value.push(newPosItem(i, 2))
        pos3Arr.value.push(newPosItem(i, 3))
        pos4Arr.value.push(newPosItem(i, 4))
        pos4Arr.value.sort((a, b) => b.pos - a.pos)
    }
}
initPosArr()
/**
 * 清空牙位选择框
 */
const clearPosArr = () => {
    pos1Arr.value.forEach(clearPosPartArr)
    pos2Arr.value.forEach(clearPosPartArr)
    pos3Arr.value.forEach(clearPosPartArr)
    pos4Arr.value.forEach(clearPosPartArr)
}
const clearPosPartArr = x => {
    x.isSingle = false
    x.isMissing = false
    x.isPontic = false
    x.isBegin = false
    x.isEnd = false
}
const innerPontic = ref('')//桥字符串 多个桥|分割
const ponticArr = ref([])//桥数组 牙位,分割
watch(() => props.pontic, (newVal, oldVal) => {
    if (innerPontic.value === newVal) return
    innerPontic.value = newVal
    setPosFromPonticStr()
})
//根据桥体字符串修改牙位对象
const setPosFromPonticStr = () => {
    //桥体字符串拆分牙位数组
    ponticArr.value = innerPontic.value ? innerPontic.value.split('|') : []
    setPosFromPontic()
}
//根据桥体数组修改牙位对象
const setPosFromPonticArr = () => {
    innerPontic.value = ponticArr.value.length ? ponticArr.value.join('|') : ''
    setPosFromPontic()
}
const setPosFromPontic = () => {
    let beginArr = []//桥开始牙位
    let endArr = []//桥结束牙位
    let allPonticArr = []//所有桥牙位
    for (let item of ponticArr.value) {
        let posArr = item.split(',')
        allPonticArr = allPonticArr.concat(posArr)
        if (posArr.length) {
            beginArr.push(posArr[0])
            endArr.push(posArr[posArr.length - 1])
        }
    }
    pos1Arr.value.forEach(x => setPosPontic(x, beginArr, endArr, allPonticArr))
    pos2Arr.value.forEach(x => setPosPontic(x, beginArr, endArr, allPonticArr))
    pos3Arr.value.forEach(x => setPosPontic(x, beginArr, endArr, allPonticArr))
    pos4Arr.value.forEach(x => setPosPontic(x, beginArr, endArr, allPonticArr))
}
//设置某个牙位桥的情况
const setPosPontic = (tooth, beginArr, endArr, allPonticArr) => {
    tooth.isBegin = beginArr && beginArr.includes(tooth.fdiStr)
    tooth.isEnd = endArr && endArr.includes(tooth.fdiStr)
    tooth.isPontic = allPonticArr && allPonticArr.includes(tooth.fdiStr)
    if (tooth.isPontic) tooth.isSingle = false
}
// 根据牙位对象获取显示状态
const getToothClass = tooth => {
    if (tooth.isMissing) {
        return 'danger'
    } else if (tooth.isPontic) {
        if (tooth.isBegin) {
            return 'success-right'
        } else if (tooth.isEnd) {
            return 'success-left'
        } else if (tooth.isPontic) {
            return 'success'
        }
    } else if (tooth.fdiStr === beginPontic.value) {
        return 'success-tmp'
    } else if (tooth.isSingle) {
        return 'primary'
    } else {
        return 'default'
    }
}
// 根据传入pos修改牙位对象
const changeSingleFromOuter = (val, posPart) => {
    if (posPart === 1) {
        pos1Arr.value.forEach(x => {
            x.isSingle = val && val.includes(x.pos + '')
        })
    } else if (posPart === 2) {
        pos2Arr.value.forEach(x => {
            x.isSingle = val && val.includes(x.pos + '')
        })
    } else if (posPart === 3) {
        pos3Arr.value.forEach(x => {
            x.isSingle = val && val.includes(x.pos + '')
        })
    } else if (posPart === 4) {
        pos4Arr.value.forEach(x => {
            x.isSingle = val && val.includes(x.pos + '')
        })
    }
}
// 根据传入missing修改牙位对象
const changeMissingFromOuter = (val, posPart) => {
    if (posPart === 1) {
        pos1Arr.value.forEach(x => {
            x.isMissing = val && val.includes(x.pos + '')
        })
    } else if (posPart === 2) {
        pos2Arr.value.forEach(x => {
            x.isMissing = val && val.includes(x.pos + '')
        })
    } else if (posPart === 3) {
        pos3Arr.value.forEach(x => {
            x.isMissing = val && val.includes(x.pos + '')
        })
    } else if (posPart === 4) {
        pos4Arr.value.forEach(x => {
            x.isMissing = val && val.includes(x.pos + '')
        })
    }
}

watch(() => props.pos1, (newVal, oldVal) => {
    changeSingleFromOuter(newVal, 1)
})
watch(() => props.missing1, (newVal, oldVal) => {
    changeMissingFromOuter(newVal, 1)
})
watch(() => props.pos2, (newVal, oldVal) => {
    changeSingleFromOuter(newVal, 2)
})
watch(() => props.missing2, (newVal, oldVal) => {
    changeMissingFromOuter(newVal, 2)
})
watch(() => props.pos3, (newVal, oldVal) => {
    changeSingleFromOuter(newVal, 3)
})
watch(() => props.missing3, (newVal, oldVal) => {
    changeMissingFromOuter(newVal, 3)
})
watch(() => props.pos4, (newVal, oldVal) => {
    changeSingleFromOuter(newVal, 4)
})
watch(() => props.missing4, (newVal, oldVal) => {
    changeMissingFromOuter(newVal, 4)
})
onMounted(() => {
    innerUpperJaw.value = props.upperJaw
    innerLowerJaw.value = props.lowerJaw
    innerComplete.value = props.complete

    setInnerToothType(props.toothType)
    innerPontic.value = props.pontic

    setPosFromPonticStr()

    changeSingleFromOuter(props.pos1, 1)
    changeSingleFromOuter(props.pos2, 2)
    changeSingleFromOuter(props.pos3, 3)
    changeSingleFromOuter(props.pos4, 4)

    changeMissingFromOuter(props.missing1, 1)
    changeMissingFromOuter(props.missing2, 2)
    changeMissingFromOuter(props.missing3, 3)
    changeMissingFromOuter(props.missing4, 4)
})

const changePos = tooth => {
    if (chooseToothType.value === toothTypeEnum.单冠) {
        tooth.isSingle = !tooth.isSingle
        changeOuterPos(tooth.posPart)
        if (tooth.isSingle) {
            //如果当前是missing
            if (tooth.isMissing) {
                tooth.isMissing = false
                changeOuterMissing(tooth.posPart)
            }
            //如果当前是pontic 取消整个桥
            if (tooth.isPontic) {
                if (ponticArr.value.length) {
                    ponticArr.value = ponticArr.value.filter(x => !x.includes(tooth.fdiStr))
                    setPosFromPonticArr()
                }
            }
        }
    } else if (chooseToothType.value === toothTypeEnum.缺失) {
        tooth.isMissing = !tooth.isMissing
        changeOuterMissing(tooth.posPart)
        if (tooth.isMissing) {
            //如果当前是single
            if (tooth.isSingle) {
                tooth.isSingle = false
                changeOuterPos(tooth.posPart)
            }
        }
    } else if (chooseToothType.value === toothTypeEnum.桥) {
        changePontic(tooth)
    }
    changeToothNo()
}
// 修改当期选中牙位类型
const changeChooseToothType = () => {
    if (chooseToothType.value !== toothTypeEnum.桥) {
        // 改为非桥 清空桥体开始位
        beginPontic.value = ''
    }
}
// 通知外部修改pos
const changeOuterPos = posPart => {
    if (posPart === 1) {
        let pos1Str = pos1Arr.value.some(x => x.isSingle) ? pos1Arr.value.filter(x => x.isSingle).map(x => x.pos).join('') : ''
        emits('update:pos1', pos1Str)
    } else if (posPart === 2) {
        let pos2Str = pos2Arr.value.some(x => x.isSingle) ? pos2Arr.value.filter(x => x.isSingle).map(x => x.pos).join('') : ''
        emits('update:pos2', pos2Str)
    } else if (posPart === 3) {
        let pos3Str = pos3Arr.value.some(x => x.isSingle) ? pos3Arr.value.filter(x => x.isSingle).map(x => x.pos).join('') : ''
        emits('update:pos3', pos3Str)
    } else if (posPart === 4) {
        let pos4Str = pos4Arr.value.some(x => x.isSingle) ? pos4Arr.value.filter(x => x.isSingle).map(x => x.pos).join('') : ''
        emits('update:pos4', pos4Str)
    }
}
// 通知外部修改missing
const changeOuterMissing = posPart => {
    if (posPart === 1) {
        let pos1Str = pos1Arr.value.some(x => x.isMissing) ? pos1Arr.value.filter(x => x.isMissing).map(x => x.pos).join('') : ''
        emits('update:missing1', pos1Str)
    } else if (posPart === 2) {
        let pos2Str = pos2Arr.value.some(x => x.isMissing) ? pos2Arr.value.filter(x => x.isMissing).map(x => x.pos).join('') : ''
        emits('update:missing2', pos2Str)
    } else if (posPart === 3) {
        let pos3Str = pos3Arr.value.some(x => x.isMissing) ? pos3Arr.value.filter(x => x.isMissing).map(x => x.pos).join('') : ''
        emits('update:missing3', pos3Str)
    } else if (posPart === 4) {
        let pos4Str = pos4Arr.value.some(x => x.isMissing) ? pos4Arr.value.filter(x => x.isMissing).map(x => x.pos).join('') : ''
        emits('update:missing4', pos4Str)
    }
}
// 同时外部修改pontic
const changeOuterPontic = () => {
    emits('update:pontic', innerPontic.value)
    changeToothNo()
}
// 通知外部修改全口
const changeComplete = () => {
    emits('update:complete', innerComplete.value)
    changeToothNo()
}
// 通知外部修改上下颌
const changeUpperJaw = () => {
    emits('update:upperJaw', innerUpperJaw.value)
    changeToothNo()
}
const changeLowerJaw = () => {
    emits('update:lowerJaw', innerLowerJaw.value)
    changeToothNo()
}
/**
 * 修改产品数量
 */
const changeToothNo = () => {
    let toothNum = 0
    let toothType = 0
    if (innerComplete.value) {
        toothNum = 1
        emits('changeTooth', toothNum)
        toothType = chooseToothType.value
    } else if (innerUpperJaw.value || innerLowerJaw.value) {
        toothNum = innerUpperJaw.value + innerLowerJaw.value
        emits('changeTooth', toothNum)
        toothType = chooseToothType.value
    } else {
        let ponticNum = getPonticNum()
        let singleNum = getSingleNum()
        let missingNum = getMissingNum()
        if (singleNum > 0) toothType = toothType | toothTypeEnum.单冠
        if (missingNum > 0) toothType = toothType | toothTypeEnum.缺失
        if (ponticNum > 0) {
            toothType = toothType | toothTypeEnum.桥
            emits('changeTooth', ponticNum + singleNum)
        } else {
            emits('changeTooth', missingNum + singleNum)
        }
    }
    innerToothType.value = toothType
    emits('update:toothType', innerToothType.value)
}
const getSingleNum = () => {
    return pos1Arr.value.filter(x => x.isSingle).length + pos2Arr.value.filter(x => x.isSingle).length
        + pos4Arr.value.filter(x => x.isSingle).length + pos3Arr.value.filter(x => x.isSingle).length
}
const getMissingNum = () => {
    return pos1Arr.value.filter(x => x.isMissing).length + pos2Arr.value.filter(x => x.isMissing).length
        + pos4Arr.value.filter(x => x.isMissing).length + pos3Arr.value.filter(x => x.isMissing).length
}
const getPonticNum = () => {
    return pos1Arr.value.filter(x => x.isPontic).length + pos2Arr.value.filter(x => x.isPontic).length
        + pos4Arr.value.filter(x => x.isPontic).length + pos3Arr.value.filter(x => x.isPontic).length
}

const beginPontic = ref('')
const changePontic = tooth => {
    let fdiItem = tooth.fdiStr
    // 已有包含该桥体 则取消
    if (ponticArr.value.length && ponticArr.value.some(x => x.includes(tooth.fdiStr))) {
        ponticArr.value = ponticArr.value.filter(x => !x.includes(tooth.fdiStr))
        setPosFromPonticArr()
        changeOuterPontic()
        beginPontic.value = ''
        return
    }
    // 选择桥体结束
    if (beginPontic.value) {
        if (beginPontic.value === fdiItem) {
            beginPontic.value = ''
            return
        }
        if (isUpper(beginPontic.value) && isLower(fdiItem)) {
            return
        }
        if (isLower(beginPontic.value) && isUpper(fdiItem)) {
            return
        }
        let res = getBetween(beginPontic.value, fdiItem)
        for (let item of res) {
            //删除包含同样牙的桥体
            let index = ponticArr.value.findIndex(x => x.indexOf(item) >= 0)
            if (index >= 0) {
                ponticArr.value.splice(index, 1)
            }
        }
        ponticArr.value.push(res.join(','))
        setPosFromPonticArr()
        changeOuterPontic()
        beginPontic.value = ''
        return
    }
    // 选择桥体开始
    if (!beginPontic.value) {
        beginPontic.value = fdiItem
    }
}
</script>

<style lang="scss">
.tooth-container {
    width: 400px;
}

.border-bottom-dash {
    border-bottom: 1px dashed #777;
}

.border-right-dash {
    border-right: 1px dashed #777;
}

.tooth-part {
    padding: 3px 0;
}

</style>1