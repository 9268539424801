import {reactive, ref, inject, toRaw} from 'vue'
import {defaultPageSize} from "@/util/constant";
import {
    caseProductStepAdd,
    caseProductStepAddSingle,
    caseProductStepMod,
    caseProductStepGetPage,
    caseProductStepDelete,
    caseStepGetList,
    workerStepsGet,
    caseProductStepGetListForWorker,
    caseProductStepExportExcel,
    workerPointsExportExcel,
    caseTraceExportExcel,
    workerPointsGet,
    workerPointDetailsGet,
    caseProductStepGetList,
    caseTraceGet,
    caseProductStepCheckTime,
    stepCaseStatGet,
    stepCaseStatDetailListGet,
    caseProductGetByCaseNo,
    productMaterialLotGet,
    workerStepStat,
    useMaterialLotGet
} from "@/api/produce";
import {ElMessage} from "element-plus";
import http from "@/util/axios";

/**
 * 手动计算员工工作量
 * @returns {{statWorkerStepLoading: *, statWorkerStepParam: *, statWorkerStep: ((function(): Promise<void>)|*)}}
 */
function useStatWorkerStep() {
    const statWorkerStepParam = reactive({
        startTime: '',
        endTime: ''
    })
    const statWorkerStepLoading = ref(false)
    const statWorkerStepResult = ref(false)
    const statWorkerStep = async () => {
        if (statWorkerStepLoading.value) return
        statWorkerStepLoading.value = true
        statWorkerStepResult.value = await workerStepStat(statWorkerStepParam)
        statWorkerStepLoading.value = false
    }
    return {
        statWorkerStepLoading,
        statWorkerStepResult,
        statWorkerStepParam,
        statWorkerStep
    }
}

/**
 * 根据产品获取主材和最近批次
 * @returns {{getProductMaterialLotLoading: *, getProductMaterialLot: ((function(*): Promise<void>)|*), productMaterialLot: *}}
 */
function useGetProductMaterialLot() {
    const productMaterialLot = ref([])
    const getProductMaterialLotLoading = ref(false)
    const getProductMaterialLot = async id => {
        if (getProductMaterialLotLoading.value) return
        getProductMaterialLotLoading.value = true
        let res = await productMaterialLotGet({id: id})
        productMaterialLot.value = res ? res : []
        getProductMaterialLotLoading.value = false
    }
    return {
        productMaterialLot,
        getProductMaterialLotLoading,
        getProductMaterialLot
    }
}

/**
 * 获取材料可以用于生产的批号
 * @returns {{useMaterialLot: *, getUseMaterialLot: ((function(*): Promise<void>)|*), getUseMaterialLotLoading: *}}
 */
function useGetUseMaterialLot() {
    const useMaterialLotList = ref([])
    const getUseMaterialLotLoading = ref(false)
    const getUseMaterialLot = async ids => {
        if (getUseMaterialLotLoading.value) return
        getUseMaterialLotLoading.value = true
        let res = await useMaterialLotGet({ids: ids})
        useMaterialLotList.value = res ? res : []
        getUseMaterialLotLoading.value = false
    }
    return {
        useMaterialLotList,
        getUseMaterialLotLoading,
        getUseMaterialLot
    }
}

/**
 * 获取工厂每个工序生产中的订单和延时订单统计
 * @returns {{stepCaseStatList: *, getStepCaseStatLoading: *, getStepCaseStat: ((function(): Promise<void>)|*)}}
 */
function useGetStepCaseStat() {
    const stepCaseStatList = ref([])
    const getStepCaseStatLoading = ref(false)
    const getStepCaseStat = async () => {
        if (getStepCaseStatLoading.value) return
        getStepCaseStatLoading.value = true
        let res = await stepCaseStatGet()
        stepCaseStatList.value = res ? res : []
        getStepCaseStatLoading.value = false
    }
    return {
        stepCaseStatList,
        getStepCaseStatLoading,
        getStepCaseStat
    }
}

/**
 * 获取车间信息工序产品列表
 * @returns
 */
function useGetStepCaseStatDetailList() {
    const stepCaseStatDetailList = ref([])
    const getStepCaseStatDetailListParam = reactive({
        stepId: 0
    })
    const getStepCaseStatDetailListLoading = ref(false)
    const getStepCaseStatDetailList = async () => {
        if (getStepCaseStatDetailListLoading.value) return
        getStepCaseStatDetailListLoading.value = true
        let res = await stepCaseStatDetailListGet(getStepCaseStatDetailListParam)
        stepCaseStatDetailList.value = res ? res : []
        getStepCaseStatDetailListLoading.value = false
    }
    return {
        stepCaseStatDetailList,
        getStepCaseStatDetailListParam,
        getStepCaseStatDetailListLoading,
        getStepCaseStatDetailList
    }
}

/**
 * 根据订单号和工序id获取订单产品列表
 * @returns {{getCaseProductByCaseNoLoading: *, getCaseProductByCaseNoResult: *, getCaseProductByCaseNoParam: *, getCaseProductByCaseNo: ((function(): Promise<void>)|*)}}
 */
function useGetCaseProductByCaseNo() {
    const getCaseProductByCaseNoParam = reactive({
        caseNo: '',
        stepId: null
    })
    const getCaseProductByCaseNoLoading = ref(false)
    const getCaseProductByCaseNoResult = ref([])
    const getCaseProductByCaseNo = async () => {
        if (getCaseProductByCaseNoLoading.value) return
        getCaseProductByCaseNoLoading.value = true
        getCaseProductByCaseNoResult.value = []
        let res = await caseProductGetByCaseNo(getCaseProductByCaseNoParam)
        if (res) getCaseProductByCaseNoResult.value = res
        getCaseProductByCaseNoLoading.value = false
    }
    return {
        getCaseProductByCaseNoParam,
        getCaseProductByCaseNoResult,
        getCaseProductByCaseNoLoading,
        getCaseProductByCaseNo
    }
}

/**
 * // 制作工序录入
 * @returns {{initCaseProductStep: initCaseProductStep, addCaseProductStepResult: *, addCaseProductStepLoading: *, caseProductStep: *, addCaseProductStep: ((function(): Promise<void>)|*)}}
 */
function useAddCaseProductStep() {
    const caseProductStep = reactive({
        id: 0,
        caseProductId: '',
        facWorkerId: '',
        workerNo: '',
        workerName: '',
        deptName: '',
        materialLotNo: '',
        materialId: '',
        materialName: '',
        machineNo: '',
        propertyId: '',
        propertyName: '',
        operType: '',
        operTime: '',
        stepList: [],
        isQualified: 1,
        isBroken: 0,
        difficulty: 3
    })
    const caseProductStepEmpty = JSON.parse(JSON.stringify(toRaw(caseProductStep)))
    const initCaseProductStep = () => {
        Object.assign(caseProductStep, caseProductStepEmpty)
    }
    const valid = () => {
        if (!caseProductStep.facWorkerId) {
            ElMessage.warning('请扫描工号')
            return
        }
        if (!caseProductStep.caseProductId) {
            ElMessage.warning('请扫描订单')
            return
        }
        if (!caseProductStep.stepList || !caseProductStep.stepList.length) {
            ElMessage.warning('请选择工序')
            return
        }
        if (!caseProductStep.operType) {
            ElMessage.warning('请选择操作类型')
            return
        }
        return true
    }
    const addCaseProductStepLoading = ref(false)
    const addCaseProductStepResult = ref(false)
    const addCaseProductStep = async () => {
        if (addCaseProductStepLoading.value) return
        if (!valid()) return
        addCaseProductStepResult.value = false
        addCaseProductStepLoading.value = true
        addCaseProductStepResult.value = await caseProductStepAdd(caseProductStep)
        addCaseProductStepLoading.value = false
    }
    return {
        caseProductStep,
        initCaseProductStep,
        addCaseProductStepLoading,
        addCaseProductStepResult,
        addCaseProductStep
    }
}

/**
 * 添加单个制作扫描工序
 * @returns {{addSingleCaseProductStep: ((function(): Promise<void>)|*), addSingleCaseProductStepLoading: *, initSingleCaseProductStep: initSingleCaseProductStep, addSingleCaseProductStepResult: *, singleCaseProductStep: *}}
 */
function useAddSingleCaseProductStep() {
    const singleCaseProductStep = reactive({
        caseProductId: '',
        facWorkerId: '',
        workerNo: '',
        workerName: '',
        deptName: '',
        materialLotNo: '',
        materialId: '',
        materialName: '',
        machineNo: '',
        propertyId: '',
        propertyName: '',
        operType: '',
        operTime: '',
        stepId: '',
        keyTime: '',
        keyParam: '',
        num: 0,
        isQualified: 1,
        isBroken: 0,
        difficulty: 3
    })
    const singleCaseProductStepEmpty = JSON.parse(JSON.stringify(toRaw(singleCaseProductStep)))
    const initSingleCaseProductStep = () => {
        Object.assign(singleCaseProductStep, singleCaseProductStepEmpty)
    }
    const valid = () => {
        if (!singleCaseProductStep.facWorkerId) {
            ElMessage.warning('请扫描工号')
            return
        }
        if (!singleCaseProductStep.caseProductId) {
            ElMessage.warning('请扫描订单')
            return
        }
        if (!singleCaseProductStep.stepId) {
            ElMessage.warning('请选择工序')
            return
        }
        if (!singleCaseProductStep.operType) {
            ElMessage.warning('请选择操作类型')
            return
        }
        return true
    }
    const addSingleCaseProductStepLoading = ref(false)
    const addSingleCaseProductStepResult = ref(false)
    const addSingleCaseProductStep = async () => {
        if (addSingleCaseProductStepLoading.value) return
        if (!valid()) return
        addSingleCaseProductStepResult.value = false
        addSingleCaseProductStepLoading.value = true
        addSingleCaseProductStepResult.value = await caseProductStepAddSingle(singleCaseProductStep)
        addSingleCaseProductStepLoading.value = false
    }
    return {
        singleCaseProductStep,
        initSingleCaseProductStep,
        addSingleCaseProductStepLoading,
        addSingleCaseProductStepResult,
        addSingleCaseProductStep
    }
}


// 制作工序修改
function useModCaseProductStep() {
    const modCaseProductStepParam = reactive({
        id: null,
        operTime: null,
        keyParam: '',
        keyTime: '',
    })
    const modCaseProductStepLoading = ref(false)
    const modCaseProductStepResult = ref(false)
    const modCaseProductStep = async () => {
        modCaseProductStepResult.value = false
        if (modCaseProductStepLoading.value) return
        modCaseProductStepLoading.value = true
        modCaseProductStepResult.value = await caseProductStepMod(modCaseProductStepParam)
        modCaseProductStepLoading.value = false
    }
    return {
        modCaseProductStepParam,
        modCaseProductStepLoading,
        modCaseProductStepResult,
        modCaseProductStep,
    }
}

// 制作工序分页
function useGetPageCaseProductStep() {
    const getPageCaseProductStepParam = reactive({
        caseNo: '',
        boxNo: '',
        beginOperTime: '',
        endOperTime: '',
        facWorkerId: null,
        facHospitalId: null,
        operType: '',
        stepId: null,
        lotNo: '',
        machineNo: '',
        propertyId: null,
        pageIndex: 1,
        pageSize: defaultPageSize
    })
    const emptyParam = Object.assign({}, getPageCaseProductStepParam)
    const initGetPageCaseProductStepParam = () => {
        Object.assign(getPageCaseProductStepParam, emptyParam)
    }
    const getPageCaseProductStepLoading = ref(false)
    const caseProductStepPage = ref([])
    const getPageCaseProductStepResult = reactive({
        totalCount: 0,
        totalPages: 0,
    })
    const getPageCaseProductStep = async () => {
        if (getPageCaseProductStepLoading.value) return
        getPageCaseProductStepLoading.value = true
        let data = await caseProductStepGetPage(getPageCaseProductStepParam)
        if (data && data.list && data.list.length) {
            caseProductStepPage.value = data.list
            getPageCaseProductStepResult.totalPages = Math.ceil(data.totalCount / getPageCaseProductStepParam.pageSize)
            getPageCaseProductStepResult.totalCount = data.totalCount
        } else {
            caseProductStepPage.value = []
            getPageCaseProductStepResult.totalPages = 0
            getPageCaseProductStepResult.totalCount = 0
        }
        getPageCaseProductStepLoading.value = false
    }
    return {
        getPageCaseProductStepParam,
        initGetPageCaseProductStepParam,
        caseProductStepPage,
        getPageCaseProductStepLoading,
        getPageCaseProductStepResult,
        getPageCaseProductStep
    }
}

function useDeleteCaseProductStep() {
    const deleteCaseProductStepLoading = ref(false)
    const deleteCaseProductStepResult = ref(false)
    const deleteCaseProductStep = async id => {
        if (deleteCaseProductStepLoading.value) return
        deleteCaseProductStepResult.value = false
        deleteCaseProductStepLoading.value = true
        deleteCaseProductStepResult.value = await caseProductStepDelete({id: id})
        deleteCaseProductStepLoading.value = false
    }
    return {
        deleteCaseProductStep,
        deleteCaseProductStepLoading,
        deleteCaseProductStepResult
    }
}

/**
 * 获取订单产品已经进行过的生产流程
 * @returns {{getSimpleListCaseProductStepLoading: *, caseProductStepSimpleList: *, getSimpleListCaseProductStep: ((function(*): Promise<void>)|*)}}
 */
function useGetSimpleListCaseProductStep() {
    const caseProductStepSimpleList = ref([])
    const getSimpleListCaseProductStepLoading = ref(false)
    const getSimpleListCaseProductStep = async caseId => {
        if (getSimpleListCaseProductStepLoading.value) return
        getSimpleListCaseProductStepLoading.value = true
        let res = await caseStepGetList({id: caseId})
        if (res && res.length) {
            caseProductStepSimpleList.value = res
        } else {
            caseProductStepSimpleList.value = []
        }
        getSimpleListCaseProductStepLoading.value = false
    }
    return {
        caseProductStepSimpleList,
        getSimpleListCaseProductStepLoading,
        getSimpleListCaseProductStep
    }
}

function useGetWorkerSteps() {
    const getWorkerStepsParam = reactive({
        facWorkerId: 0,
        caseId: 0
    })
    const workerSteps = ref([])
    const getWorkerStepsLoading = ref(false)
    const getWorkerSteps = async () => {
        if (getWorkerStepsLoading.value) return
        getWorkerStepsLoading.value = true
        let res = await workerStepsGet(getWorkerStepsParam)
        if (res && res.length) {
            workerSteps.value = res
        } else {
            workerSteps.value = []
        }
        getWorkerStepsLoading.value = false
    }
    return {
        getWorkerStepsParam,
        workerSteps,
        getWorkerStepsLoading,
        getWorkerSteps,
    }
}

function useGetWorkerStepList() {
    const getWorkerStepListParam = reactive({
        facWorkerId: null,
        caseNo: '',
        beginDate: '',
        endDate: ''
    })
    const workerStepListRes = reactive({
        totalNum: 0,
        totalRedoNum: 0,
        totalPoints: 0,
        list: []
    })
    const getWorkerStepListLoading = ref(false)
    const getWorkerStepList = async () => {
        if (getWorkerStepListLoading.value) return
        getWorkerStepListLoading.value = true
        let res = await caseProductStepGetListForWorker(getWorkerStepListParam)
        if (res) {
            Object.assign(workerStepListRes, res)
        } else {
            workerStepList.value = []
        }
        getWorkerStepListLoading.value = false
    }
    return {
        getWorkerStepListParam,
        workerStepListRes,
        getWorkerStepListLoading,
        getWorkerStepList,
    }
}

const exportCaseProductStepExcel = req => {
    if (!req.beginOperTime && !req.endOperTime ) {
        ElMessage.error('需要选择好操作日期后再导出')
        return
    }
    req.token = sessionStorage.getItem('token')
    let url = caseProductStepExportExcel + '?' + http.getQueryFromJson(req)
    window.open(url, '_blank')
}
const exportWorkerPointsExcel = req => {
    req.token = sessionStorage.getItem('token')
    let url = workerPointsExportExcel + '?' + http.getQueryFromJson(req)
    window.open(url, '_blank')
}
const exportCaseTraceExcel = req => {
    req.token = sessionStorage.getItem('token')
    let url = caseTraceExportExcel + '?' + http.getQueryFromJson(req)
    window.open(url, '_blank')
}


// 获取员工工作量
function useGetWorkerPoints() {
    const getWorkerPointsParam = reactive({
        facWorkerId: null,
        deptId: null,
        beginDate: '',
        endDate: ''
    })
    const workerPoints = ref([])
    const getWorkerPointsLoading = ref(false)
    const getWorkerPoints = async () => {
        if (getWorkerPointsLoading.value) return
        getWorkerPointsLoading.value = true
        let res = await workerPointsGet(getWorkerPointsParam)
        if (res && res.length) {
            workerPoints.value = res
        } else {
            workerPoints.value = []
        }
        getWorkerPointsLoading.value = false
    }
    return {
        getWorkerPointsParam,
        workerPoints,
        getWorkerPointsLoading,
        getWorkerPoints,
    }
}

/**
 * 获取员工工作量明细
 * @returns
 */
function useGetWorkerPointDetails() {
    const getWorkerPointDetailsParam = reactive({
        facWorkerId: null,
        stepId: null,
        deptId: null,
        beginDate: '',
        endDate: ''
    })
    const emptyParam = Object.assign({}, getWorkerPointDetailsParam)
    const initGetWorkerPointDetailsParam = () => {
        Object.assign(getWorkerPointDetailsParam, emptyParam)
    }
    const workerPointDetails = ref([])
    const getWorkerPointDetailsLoading = ref(false)
    const getWorkerPointDetails = async () => {
        if (getWorkerPointDetailsLoading.value) return
        getWorkerPointDetailsLoading.value = true
        let res = await workerPointDetailsGet(getWorkerPointDetailsParam)
        if (res && res.length) {
            workerPointDetails.value = res
        } else {
            workerPointDetails.value = []
        }
        getWorkerPointDetailsLoading.value = false
    }
    return {
        getWorkerPointDetailsParam,
        initGetWorkerPointDetailsParam,
        workerPointDetails,
        getWorkerPointDetailsLoading,
        getWorkerPointDetails
    }
}

function useGetCaseProductStepList() {
    const getCaseProductStepListLoading = ref(false)
    const getCaseProductStepListParam = reactive({
        caseId: null,
        operType: null
    })
    const caseProductStepList = ref([])
    const getCaseProductStepList = async () => {
        if (getCaseProductStepListLoading.value) return
        getCaseProductStepListLoading.value = true
        let res = await caseProductStepGetList(getCaseProductStepListParam)
        caseProductStepList.value = res ? res : []
        getCaseProductStepListLoading.value = false
    }
    return {
        getCaseProductStepListParam,
        // initGetWorkerPointDetailsParam,
        getCaseProductStepListLoading,
        getCaseProductStepList,
        caseProductStepList
    }
}

function useGetCaseTrace() {
    const caseTrace = reactive({
        stepList: [],
        stepTestList: [],
        productTestList: []
    })
    const getCaseTraceLoading = ref(false)
    const getCaseTrace = async id => {
        if (getCaseTraceLoading.value) return
        getCaseTraceLoading.value = true
        let res = await caseTraceGet({id: id})
        if (res) {
            Object.assign(caseTrace, res)
        }
        getCaseTraceLoading.value = false
    }
    return {
        caseTrace,
        getCaseTraceLoading,
        getCaseTrace,
    }
}

// 检查订单工序时间是否合规（不能和其他工序时间重复）
function useCheckStepTime() {
    const checkStepTimeParam = reactive({
        caseId: '',
        stepId: '',
        operType: '',
        operTime: '',
        productId: '',
    })
    const checkStepTimeLoading = ref(false)
    const checkStepTimeResult = ref('')
    const checkStepTime = async () => {
        checkStepTimeResult.value = ''
        if (checkStepTimeLoading.value) return
        checkStepTimeLoading.value = true
        checkStepTimeResult.value = await caseProductStepCheckTime(checkStepTimeParam)
        checkStepTimeLoading.value = false
    }
    return {
        checkStepTimeParam,
        checkStepTimeLoading,
        checkStepTimeResult,
        checkStepTime
    }
}

export {
    useAddCaseProductStep,
    useAddSingleCaseProductStep,
    useModCaseProductStep,
    useGetPageCaseProductStep,
    useGetSimpleListCaseProductStep,
    useDeleteCaseProductStep,
    useGetWorkerSteps,
    useGetWorkerStepList,
    exportCaseProductStepExcel,
    useGetWorkerPoints,
    useGetWorkerPointDetails,
    exportWorkerPointsExcel,
    useGetCaseProductStepList,
    useGetCaseTrace,
    exportCaseTraceExcel,
    useCheckStepTime,
    useGetCaseProductByCaseNo,
    useGetStepCaseStat,
    useGetStepCaseStatDetailList,
    useGetProductMaterialLot,
    useStatWorkerStep,
    useGetUseMaterialLot
}