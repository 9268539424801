import {reactive, ref, inject} from 'vue'
import {
    caseFileUpload,
    casePicUpload,
    caseColorPicUpload,
    productPicUpload,
    materialPicUpload,
    hospitalPicUpload,
    supplyPicUpload,
    assetPicUpload,
    pdfPicUpload,
    workerUpload,
    productUpload,
    productTypeUpload,
    supplyUpload,
    materialTypeUpload,
    materialUpload,
    saleTargetUpload,
    materialSupplyUpload,
    hospitalUpload,
    doctorUpload,
    hospitalPriceUpload,
    privatePriceUpload,
    doctorPriceUpload,
    doctorHabitUpload,
    stockBeginUpload,
    productStepUpload,
    productMaterialUpload,
    deptStepUpload,
    caseUpload,
    abutmentPurchaseUpload,
    caseStepUpload,
    fileUpdateDownload,
    getTokenByUse, propertyUpload,
} from "@/api/system";
import {ElMessage} from "element-plus";
import Compressor from 'compressorjs';

const picPathPrefix = '/api/factory/file/get?t='
const excelTemplateList = {
    worker: '员工模板',
    product: '产品模板',
    product_type: '产品种类模板',
    material: '材料模板',
    material_type: '材料种类模板',
    hospital: '医院模板',
    doctor: '医生模板',
    property_check: '资产盘点模板',
    case: '订单模板',
    abutment_purchase: '基台采购单模板',
    supply: '供应商模板',
    material_supply: '材料采购价',
    hospital_price: '医院产品价格',
    private_price: '私人产品价格',
    doctor_price: '医生私人价格',
    doctor_habit: '医生习惯',
    stock_begin: '期初库存明细',
    product_stock_begin: '器械期初库存明细',
    product_step: '产品工序',
    product_material: '产品主材',
    dept_step: '部门工序',
    purchase_apply: '采购申请单',
    purchase: '采购单',
    stock_in: '入库单',
    stock_in_detail: '入库明细',
    stock_out: '出库单',
    product_stock_out: '器械出库单',
    case_step: '生产流程',
    property: '资产',
    sale_target: '销售目标'
}
const getExcelTemplate = async excelType => {
    for (let templateName in excelTemplateList) {
        if (excelType === templateName) {
            let url = '/api/factory/file/get_' + excelType + '_template'
            window.open(url, '_blank')
            break
        }
    }
}

function useGetTokenByUse() {
    const picTokenByFileUse = ref('')
    const getPicTokenByFileUseLoading = ref(false)
    const getPicTokenByFileUse = async fileUse => {
        if (getPicTokenByFileUseLoading.value) return
        getPicTokenByFileUseLoading.value = true
        let data = await getTokenByUse({fileUse: fileUse})
        if (!!data) picTokenByFileUse.value = data
        getPicTokenByFileUseLoading.value = false
    }
    return {
        picTokenByFileUse,
        getPicTokenByFileUseLoading,
        getPicTokenByFileUse,
    }
}

// 上传文件带压缩
function useUploadPicCompress(picType) {
    const uploadCompressResult = ref(false)
    const uploadCompressLoading = ref(false)
    const uploadCompressData = reactive({
        picId: '',
        picToken: '',
        fileName: '',
        miniPicId: '',
        miniPicToken: ''
    })
    const uploadFileCompressList = ref([])
    const uploadFileCompress = async param => {
        uploadCompressLoading.value = true
        uploadCompressResult.value = false
        uploadCompressData.picToken = ''
        const formData = new FormData()
        formData.append("file", param.file)
        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };
        try {
            let res = null
            if (picType === 'asset') {
                res = await assetPicUpload(formData, config)
            } else if (picType === 'casePic') {
                res = await casePicUpload(formData, config)
            }
            if (res.code === 0) {
                uploadCompressResult.value = true
                uploadCompressData.picToken = res.data.picToken
                uploadCompressData.picId = res.data.picId
                uploadCompressData.miniPicId = res.data.miniPicId
                uploadCompressData.miniPicToken = res.data.miniPicToken
                uploadCompressData.fileName = res.data.orgFileName
                uploadFileCompressList.value.push({
                    picToken: res.data.picToken,
                    picId: res.data.picId,
                    fileName: res.data.orgFileName,
                    miniPicId: res.data.miniPicId,
                    miniPicToken: res.data.miniPicToken,
                    id: 0,
                })
            } else {
                ElMessage.error(res.message)
            }
            uploadCompressLoading.value = false
        } catch (e) {
            uploadCompressLoading.value = false
            ElMessage.error('上传失败，请检查网络或联系客服')
        }
    }
    return {
        uploadFileCompressList,
        uploadFileCompress,
        uploadCompressData,
        uploadCompressLoading,
        uploadCompressResult
    }
}

function useUploadPic(picType) {
    const uploadResult = ref(false)
    const picToken = ref('')
    const picId = ref('')
    const fileName = ref('')
    const uploadFileList = ref([])
    const uploadPic = async param => {
        uploadResult.value = false
        picToken.value = ''
        const formData = new FormData()
        formData.append("file", param.file)
        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };
        try {
            let res = null
            if (picType === 'pdfPic') {
                res = await pdfPicUpload(formData, config)
            } else if (picType === 'product') {
                res = await productPicUpload(formData, config)
            } else if (picType === 'material') {
                res = await materialPicUpload(formData, config)
            } else if (picType === 'caseFile') {
                res = await caseFileUpload(formData, config)
            } else if (picType === 'hospital') {
                res = await hospitalPicUpload(formData, config)
            } else if (picType === 'supply') {
                res = await supplyPicUpload(formData, config)
            } else if (picType === 'caseColor') {
                res = await caseColorPicUpload(formData, config)
            }
            if (res.code === 0) {
                uploadResult.value = true
                picToken.value = res.data.picToken
                picId.value = res.data.picId
                fileName.value = res.data.orgFileName
                uploadFileList.value.push({
                    fileToken: res.data.picToken,
                    fileId: res.data.picId,
                    fileName: res.data.orgFileName,
                    id: 0,
                })
            } else {
                ElMessage.error(res.message)
            }
        } catch (e) {
            ElMessage.error('上传失败，请检查网络或联系客服')
        }
    }
    return {
        uploadResult,
        picToken,
        picId,
        fileName,
        uploadFileList,
        uploadPic
    }
}

// 图片通用压缩提示
const commonZipPic = (file) => {
    const _this = this
    return new Promise((resolve, reject) => {
        const image = new Image()
        let resultBlob = ''
        image.src = URL.createObjectURL(file)
        image.onload = () => {
            // 调用方法获取blob格式，方法写在下边
            resultBlob = compressUpload(image, file)
            const fs = new File([resultBlob], file.name, {
                type: file.type
            })
            if (fs.size / 1048576 > 0.5) {
                // this.$message.warning('压缩后图片仍大于500kb，请您手动压缩')
                // reject()
            }
            console.log('after=' + fs.size)
            resolve(fs)
        }
        image.onerror = () => {
            reject()
        }
    })
}
/* 图片压缩方法-canvas压缩 */
const compressUpload = (image, file) => {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    // const initSize = image.src.length
    const {width} = image
    const {height} = image
    canvas.width = width
    canvas.height = height
    ctx.fillRect(0, 0, canvas.width, canvas.height)
    ctx.drawImage(image, 0, 0, width, height)
    // 进行最小压缩0.1
    let imgResizeRatio = 0.2
    let compressData = canvas.toDataURL(file.type || 'image/jpeg', imgResizeRatio)
    // 压缩后调用方法进行base64转Blob，方法写在下边
    const blobImg = dataURItoBlob(compressData)
    return blobImg
}
/* base64转Blob对象 */
const dataURItoBlob = (data) => {
    let byteString
    if (data.split(',')[0].indexOf('base64') >= 0) {
        byteString = atob(data.split(',')[1])
    } else {
        byteString = unescape(data.split(',')[1])
    }
    const mimeString = data
        .split(',')[0]
        .split(':')[1]
        .split(';')[0]
    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i += 1) {
        ia[i] = byteString.charCodeAt(i)
    }
    return new Blob([ia], {type: mimeString})
}
const beforePicUpload = file => {
    console.log('before=' + file.size)
    // 文件类型限制为jpg/png/jpeg
    const fileTypeCheck = file.type.indexOf('jpg') >= 0 || file.type.indexOf('jpeg') >= 0 || file.type.indexOf('png') >= 0
    if (!fileTypeCheck) {
        ElMessage.error("上传文件只能是jpg、jpeg、png格式");
    }
    // 文件大小限制为10M
    const fileLimit = file.size / 1024 / 1024 < 10;
    if (!fileLimit) {
        ElMessage.error("上传文件大小不超过10M！");
    }
    if (!(fileLimit && fileTypeCheck)) {
        return Promise.reject()
    }
    return new Promise((resolve, reject) => {
        new Compressor(file, {
            quality: 0.6,
            success: result => {
                console.log(result.size)
                resolve(result)
            },
            error(err) {
                console.log(err.message);
                reject(false)
            },
        })
    })
    return commonZipPic(file)
}

const beforeExcelUpload = file => {
    // 文件类型限制为jpg/png/jpeg
    const fileTypeCheck = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel'
    if (!fileTypeCheck) {
        ElMessage.error("上传文件只能是xls、xlsx格式");
    }
    // 文件大小限制为10M
    const fileLimit = file.size / 1024 / 1024 < 10;
    if (!fileLimit) {
        ElMessage.error("上传文件大小不超过10M！");
    }
    return fileLimit && fileTypeCheck
}

const beforeMax20MUpload = file => {
    // 文件大小限制为10M
    const fileLimit = file.size / 1024 / 1024 < 20;
    if (!fileLimit) {
        ElMessage.error("上传文件大小不超过20M！");
    }
    return fileLimit
}

// excel 上传员工
function useUploadWorker() {
    const uploadWorkerResult = ref(false)
    const uploadWorkerLoading = ref(false)
    const uploadWorkerNum = ref(0)
    const failedWorker = ref([])
    const workerFileName = ref('')
    const uploadWorker = async param => {
        if (uploadWorkerLoading.value) return
        uploadWorkerLoading.value = true
        uploadWorkerResult.value = false
        uploadWorkerNum.value = 0
        const formData = new FormData()
        formData.append("file", param.file)
        workerFileName.value = param.file.name
        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };
        try {
            let res = await workerUpload(formData, config)
            if (res.code === 0) {
                uploadWorkerResult.value = true
                uploadWorkerNum.value = res.data.successNum
                failedWorker.value = res.data.failList
                if (!!uploadWorkerNum.value) ElMessage.success('上传成功，上传数量' + uploadWorkerNum.value)
            } else {
                ElMessage.error(res.message)
            }
        } catch (e) {
            ElMessage.error('上传失败，请检查网络或联系客服')
        }
        uploadWorkerLoading.value = false
    }
    return {
        workerFileName,
        uploadWorkerResult,
        uploadWorkerLoading,
        uploadWorkerNum,
        failedWorker,
        uploadWorker
    }
}

// 上传产品种类
function useUploadProductType() {
    const uploadProductTypeResult = ref(false)
    const uploadProductTypeLoading = ref(false)
    const uploadProductTypeNum = ref(0)
    const failedProductType = ref([])
    const uploadProductType = async param => {
        if (uploadProductTypeLoading.value) return
        uploadProductTypeLoading.value = true
        uploadProductTypeResult.value = false
        uploadProductTypeNum.value = 0
        const formData = new FormData()
        formData.append("file", param.file)
        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };
        try {
            let res = await productTypeUpload(formData, config)
            if (res.code === 0) {
                uploadProductTypeResult.value = true
                uploadProductTypeNum.value = res.data.successNum
                failedProductType.value = res.data.failList
                if (!!uploadProductTypeNum.value) ElMessage.success('上传成功，上传数量' + uploadProductTypeNum.value)
            } else {
                ElMessage.error(res.message)
            }
        } catch (e) {
            ElMessage.error('上传失败，请检查网络或联系客服')
        }
        uploadProductTypeLoading.value = false
    }
    return {
        uploadProductTypeResult,
        uploadProductTypeLoading,
        uploadProductTypeNum,
        failedProductType,
        uploadProductType
    }
}

// excel 上传产品
function useUploadProduct() {
    const uploadProductResult = ref(false)
    const uploadProductLoading = ref(false)
    const uploadProductNum = ref(0)
    const failedProduct = ref([])
    const uploadProduct = async param => {
        if (uploadProductLoading.value) return
        uploadProductLoading.value = true
        uploadProductResult.value = false
        uploadProductNum.value = 0
        const formData = new FormData()
        formData.append("file", param.file)
        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };
        try {
            let res = await productUpload(formData, config)
            if (res.code === 0) {
                uploadProductResult.value = true
                uploadProductNum.value = res.data.successNum
                failedProduct.value = res.data.failList
                if (!!uploadProductNum.value) ElMessage.success('上传成功，上传数量' + uploadProductNum.value)
            } else {
                ElMessage.error(res.message)
            }
        } catch (e) {
            ElMessage.error('上传失败，请检查网络或联系客服')
        }
        uploadProductLoading.value = false
    }
    return {
        uploadProductResult,
        uploadProductLoading,
        uploadProductNum,
        failedProduct,
        uploadProduct
    }
}

function useUploadSupply() {
    const uploadSupplyResult = ref(false)
    const uploadSupplyLoading = ref(false)
    const uploadSupplyNum = ref(0)
    const failedSupply = ref([])
    const uploadSupply = async param => {
        if (uploadSupplyLoading.value) return
        uploadSupplyLoading.value = true
        uploadSupplyResult.value = false
        uploadSupplyNum.value = 0
        const formData = new FormData()
        formData.append("file", param.file)
        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };
        try {
            let res = await supplyUpload(formData, config)
            if (res.code === 0) {
                uploadSupplyResult.value = true
                uploadSupplyNum.value = res.data.successNum
                failedSupply.value = res.data.failList
                if (!!uploadSupplyNum.value) ElMessage.success('上传成功，上传数量' + uploadSupplyNum.value)
            } else {
                ElMessage.error(res.message)
            }
        } catch (e) {
            ElMessage.error('上传失败，请检查网络或联系客服')
        }
        uploadSupplyLoading.value = false
    }
    return {
        uploadSupplyResult,
        uploadSupplyLoading,
        uploadSupplyNum,
        failedSupply,
        uploadSupply
    }
}

// excel 上传材料种类
function useUploadMaterialType() {
    const uploadMaterialTypeResult = ref(false)
    const uploadMaterialTypeLoading = ref(false)
    const uploadMaterialTypeNum = ref(0)
    const failedMaterialType = ref([])
    const uploadMaterialType = async param => {
        if (uploadMaterialTypeLoading.value) return
        uploadMaterialTypeLoading.value = true
        uploadMaterialTypeResult.value = false
        uploadMaterialTypeNum.value = 0
        const formData = new FormData()
        formData.append("file", param.file)
        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };
        try {
            let res = await materialTypeUpload(formData, config)
            if (res.code === 0) {
                uploadMaterialTypeResult.value = true
                uploadMaterialTypeNum.value = res.data.successNum
                failedMaterialType.value = res.data.failList
                if (!!uploadMaterialTypeNum.value) ElMessage.success('上传成功，上传数量' + uploadMaterialTypeNum.value)
            } else {
                ElMessage.error(res.message)
            }
        } catch (e) {
            ElMessage.error('上传失败，请检查网络或联系客服')
        }
        uploadMaterialTypeLoading.value = false
    }
    return {
        uploadMaterialTypeResult,
        uploadMaterialTypeLoading,
        uploadMaterialTypeNum,
        failedMaterialType,
        uploadMaterialType
    }
}

// excel 上传材料
function useUploadMaterial() {
    const uploadMaterialResult = ref(false)
    const uploadMaterialLoading = ref(false)
    const uploadMaterialNum = ref(0)
    const failedMaterial = ref([])
    const uploadMaterial = async param => {
        if (uploadMaterialLoading.value) return
        uploadMaterialLoading.value = true
        uploadMaterialResult.value = false
        uploadMaterialNum.value = 0
        const formData = new FormData()
        formData.append("file", param.file)
        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };
        try {
            let res = await materialUpload(formData, config)
            if (res.code === 0) {
                uploadMaterialResult.value = true
                uploadMaterialNum.value = res.data.successNum
                failedMaterial.value = res.data.failList
                if (!!uploadMaterialNum.value) ElMessage.success('上传成功，上传数量' + uploadMaterialNum.value)
            } else {
                ElMessage.error(res.message)
            }
        } catch (e) {
            ElMessage.error('上传失败，请检查网络或联系客服')
        }
        uploadMaterialLoading.value = false
    }
    return {
        uploadMaterialResult,
        uploadMaterialLoading,
        uploadMaterialNum,
        failedMaterial,
        uploadMaterial
    }
}

function useUploadMaterialSupply() {
    const uploadMaterialSupplyResult = ref(false)
    const uploadMaterialSupplyLoading = ref(false)
    const uploadMaterialSupplyNum = ref(0)
    const failedMaterialSupply = ref([])
    const uploadMaterialSupply = async param => {
        if (uploadMaterialSupplyLoading.value) return
        uploadMaterialSupplyLoading.value = true
        uploadMaterialSupplyResult.value = false
        uploadMaterialSupplyNum.value = 0
        const formData = new FormData()
        formData.append("file", param.file)
        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };
        try {
            let res = await materialSupplyUpload(formData, config)
            if (res.code === 0) {
                uploadMaterialSupplyResult.value = true
                uploadMaterialSupplyNum.value = res.data.successNum
                failedMaterialSupply.value = res.data.failList
                if (!!uploadMaterialSupplyNum.value) ElMessage.success('上传成功，上传数量' + uploadMaterialSupplyNum.value)
            } else {
                ElMessage.error(res.message)
            }
        } catch (e) {
            ElMessage.error('上传失败，请检查网络或联系客服')
        }
        uploadMaterialSupplyLoading.value = false
    }
    return {
        uploadMaterialSupplyResult,
        uploadMaterialSupplyLoading,
        uploadMaterialSupplyNum,
        failedMaterialSupply,
        uploadMaterialSupply
    }
}

// excel 上传医院
function useUploadHospital() {
    const uploadHospitalResult = ref(false)
    const uploadHospitalLoading = ref(false)
    const uploadHospitalNum = ref(0)
    const failedHospital = ref([])
    const uploadHospital = async param => {
        if (uploadHospitalLoading.value) return
        uploadHospitalLoading.value = true
        uploadHospitalResult.value = false
        uploadHospitalNum.value = 0
        const formData = new FormData()
        formData.append("file", param.file)
        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };
        try {
            let res = await hospitalUpload(formData, config)
            if (res.code === 0) {
                uploadHospitalResult.value = true
                uploadHospitalNum.value = res.data.successNum
                failedHospital.value = res.data.failList
                if (!!uploadHospitalNum.value) ElMessage.success('上传成功，上传数量' + uploadHospitalNum.value)
            } else {
                ElMessage.error(res.message)
            }
        } catch (e) {
            ElMessage.error('上传失败，请检查网络或联系客服')
        }
        uploadHospitalLoading.value = false
    }
    return {
        uploadHospitalResult,
        uploadHospitalLoading,
        uploadHospitalNum,
        failedHospital,
        uploadHospital
    }
}

// 销售目标
function useUploadSaleTarget() {
    const uploadSaleTargetResult = ref(false)
    const uploadSaleTargetLoading = ref(false)
    const uploadSaleTargetNum = ref(0)
    const failedSaleTarget = ref([])
    const uploadSaleTarget = async param => {
        if (uploadSaleTargetLoading.value) return
        uploadSaleTargetLoading.value = true
        uploadSaleTargetResult.value = false
        uploadSaleTargetNum.value = 0
        const formData = new FormData()
        formData.append("file", param.file)
        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };
        try {
            let res = await saleTargetUpload(formData, config)
            if (res.code === 0) {
                uploadSaleTargetResult.value = true
                uploadSaleTargetNum.value = res.data.successNum
                failedSaleTarget.value = res.data.failList
                if (!!uploadSaleTargetNum.value) ElMessage.success('上传成功，上传数量' + uploadSaleTargetNum.value)
            } else {
                ElMessage.error(res.message)
            }
        } catch (e) {
            ElMessage.error('上传失败，请检查网络或联系客服')
        }
        uploadSaleTargetLoading.value = false
    }
    return {
        uploadSaleTargetResult,
        uploadSaleTargetLoading,
        uploadSaleTargetNum,
        failedSaleTarget,
        uploadSaleTarget
    }
}

// excel 上传医生
function useUploadDoctor() {
    const uploadDoctorResult = ref(false)
    const uploadDoctorLoading = ref(false)
    const uploadDoctorNum = ref(0)
    const failedDoctor = ref([])
    const uploadDoctor = async param => {
        if (uploadDoctorLoading.value) return
        uploadDoctorLoading.value = true
        uploadDoctorResult.value = false
        uploadDoctorNum.value = 0
        const formData = new FormData()
        formData.append("file", param.file)
        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };
        try {
            let res = await doctorUpload(formData, config)
            if (res.code === 0) {
                uploadDoctorResult.value = true
                uploadDoctorNum.value = res.data.successNum
                failedDoctor.value = res.data.failList
                if (!!uploadDoctorNum.value) ElMessage.success('上传成功，上传数量' + uploadDoctorNum.value)
            } else {
                ElMessage.error(res.message)
            }
        } catch (e) {
            ElMessage.error('上传失败，请检查网络或联系客服')
        }
        uploadDoctorLoading.value = false
    }
    return {
        uploadDoctorResult,
        uploadDoctorLoading,
        uploadDoctorNum,
        failedDoctor,
        uploadDoctor
    }
}

// excel 上传订单
function useUploadCase() {
    const uploadCaseResult = ref(false)
    const uploadCaseLoading = ref(false)
    const uploadCaseNum = ref(0)
    const failedCase = ref([])
    const caseFileName = ref('')
    const uploadCase = async param => {
        if (uploadCaseLoading.value) return
        uploadCaseLoading.value = true
        uploadCaseResult.value = false
        uploadCaseNum.value = 0
        caseFileName.value = param.file.name
        const formData = new FormData()
        formData.append("file", param.file)
        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };
        try {
            let res = await caseUpload(formData, config)
            if (res.code === 0) {
                uploadCaseResult.value = true
                uploadCaseNum.value = res.data.successNum
                failedCase.value = res.data.failList
                if (!!uploadCaseNum.value) ElMessage.success('上传成功，上传数量' + uploadCaseNum.value)
            } else {
                ElMessage.error(res.message)
            }
        } catch (e) {
            ElMessage.error('上传失败，请检查网络或联系客服')
        }
        uploadCaseLoading.value = false
    }
    return {
        caseFileName,
        uploadCaseResult,
        uploadCaseLoading,
        uploadCaseNum,
        failedCase,
        uploadCase
    }
}

// excel 上传资产
function useUploadProperty() {
    const uploadPropertyResult = ref(false)
    const uploadPropertyLoading = ref(false)
    const uploadPropertyNum = ref(0)
    const failedProperty = ref([])
    const propertyFileName = ref('')
    const uploadProperty = async param => {
        if (uploadPropertyLoading.value) return
        uploadPropertyLoading.value = true
        uploadPropertyResult.value = false
        uploadPropertyNum.value = 0
        propertyFileName.value = param.file.name
        const formData = new FormData()
        formData.append("file", param.file)
        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };
        try {
            let res = await propertyUpload(formData, config)
            if (res.code === 0) {
                uploadPropertyResult.value = true
                uploadPropertyNum.value = res.data.successNum
                failedProperty.value = res.data.failList
                if (!!uploadPropertyNum.value) ElMessage.success('上传成功，上传数量' + uploadPropertyNum.value)
            } else {
                ElMessage.error(res.message)
            }
        } catch (e) {
            ElMessage.error('上传失败，请检查网络或联系客服')
        }
        uploadPropertyLoading.value = false
    }
    return {
        propertyFileName,
        uploadPropertyResult,
        uploadPropertyLoading,
        uploadPropertyNum,
        failedProperty,
        uploadProperty
    }
}

// 上传产和工序关联关系
function useUploadProductStep() {
    const uploadProductStepResult = ref(false)
    const uploadProductStepLoading = ref(false)
    const uploadProductStepNum = ref(0)
    const failedProductStep = ref([])
    const uploadProductStep = async param => {
        if (uploadProductStepLoading.value) return
        uploadProductStepLoading.value = true
        uploadProductStepResult.value = false
        uploadProductStepNum.value = 0
        const formData = new FormData()
        formData.append("file", param.file)
        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };
        try {
            let res = await productStepUpload(formData, config)
            if (res.code === 0) {
                uploadProductStepResult.value = true
                uploadProductStepNum.value = res.data.successNum
                failedProductStep.value = res.data.failList
                if (!!uploadProductStepNum.value) ElMessage.success('上传成功，上传数量' + uploadProductStepNum.value)
            } else {
                ElMessage.error(res.message)
            }
        } catch (e) {
            ElMessage.error('上传失败，请检查网络或联系客服')
        }
        uploadProductStepLoading.value = false
    }
    return {
        uploadProductStepResult,
        uploadProductStepLoading,
        uploadProductStepNum,
        failedProductStep,
        uploadProductStep
    }
}

/**
 * 产品主材上传
 * @returns {{uploadProductMaterialNum: *, uploadProductMaterial: ((function(*): Promise<void>)|*), uploadProductMaterialResult: *, uploadProductMaterialLoading: *, failedProductMaterial: *}}
 */
function useUploadProductMaterial() {
    const uploadProductMaterialResult = ref(false)
    const uploadProductMaterialLoading = ref(false)
    const uploadProductMaterialNum = ref(0)
    const failedProductMaterial = ref([])
    const uploadProductMaterial = async param => {
        if (uploadProductMaterialLoading.value) return
        uploadProductMaterialLoading.value = true
        uploadProductMaterialResult.value = false
        uploadProductMaterialNum.value = 0
        const formData = new FormData()
        formData.append("file", param.file)
        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };
        try {
            let res = await productMaterialUpload(formData, config)
            if (res.code === 0) {
                uploadProductMaterialResult.value = true
                uploadProductMaterialNum.value = res.data.successNum
                failedProductMaterial.value = res.data.failList
                if (!!uploadProductMaterialNum.value) ElMessage.success('上传成功，上传数量' + uploadProductMaterialNum.value)
            } else {
                ElMessage.error(res.message)
            }
        } catch (e) {
            ElMessage.error('上传失败，请检查网络或联系客服')
        }
        uploadProductMaterialLoading.value = false
    }
    return {
        uploadProductMaterialResult,
        uploadProductMaterialLoading,
        uploadProductMaterialNum,
        failedProductMaterial,
        uploadProductMaterial
    }
}

function useUploadDeptStep() {
    const uploadDeptStepResult = ref(false)
    const uploadDeptStepLoading = ref(false)
    const uploadDeptStepNum = ref(0)
    const failedDeptStep = ref([])
    const uploadDeptStep = async param => {
        if (uploadDeptStepLoading.value) return
        uploadDeptStepLoading.value = true
        uploadDeptStepResult.value = false
        uploadDeptStepNum.value = 0
        const formData = new FormData()
        formData.append("file", param.file)
        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };
        try {
            let res = await deptStepUpload(formData, config)
            if (res.code === 0) {
                uploadDeptStepResult.value = true
                uploadDeptStepNum.value = res.data.successNum
                failedDeptStep.value = res.data.failList
                if (!!uploadDeptStepNum.value) ElMessage.success('上传成功，上传数量' + uploadDeptStepNum.value)
            } else {
                ElMessage.error(res.message)
            }
        } catch (e) {
            ElMessage.error('上传失败，请检查网络或联系客服')
        }
        uploadDeptStepLoading.value = false
    }
    return {
        uploadDeptStepResult,
        uploadDeptStepLoading,
        uploadDeptStepNum,
        failedDeptStep,
        uploadDeptStep
    }
}

function useUploadCaseStep() {
    const uploadCaseStepResult = ref(false)
    const uploadCaseStepLoading = ref(false)
    const uploadCaseStepNum = ref(0)
    const failedCaseStep = ref([])
    const uploadCaseStep = async param => {
        if (uploadCaseStepLoading.value) return
        uploadCaseStepLoading.value = true
        uploadCaseStepResult.value = false
        uploadCaseStepNum.value = 0
        const formData = new FormData()
        formData.append("file", param.file)
        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };
        try {
            let res = await caseStepUpload(formData, config)
            if (res.code === 0) {
                uploadCaseStepResult.value = true
                uploadCaseStepNum.value = res.data.successNum
                failedCaseStep.value = res.data.failList
                if (!!uploadCaseStepNum.value) ElMessage.success('上传成功，上传数量' + uploadCaseStepNum.value)
            } else {
                ElMessage.error(res.message)
            }
        } catch (e) {
            ElMessage.error('上传失败，请检查网络或联系客服')
        }
        uploadCaseStepLoading.value = false
    }
    return {
        uploadCaseStepResult,
        uploadCaseStepLoading,
        uploadCaseStepNum,
        failedCaseStep,
        uploadCaseStep
    }
}

// excel 上传基台采购
function useUploadAbutmentPurchase() {
    const uploadAbutmentPurchaseResult = ref(false)
    const uploadAbutmentPurchaseLoading = ref(false)
    const uploadAbutmentPurchaseNum = ref(0)
    const failedAbutmentPurchase = ref([])
    const uploadAbutmentPurchase = async param => {
        if (uploadAbutmentPurchaseLoading.value) return
        uploadAbutmentPurchaseLoading.value = true
        uploadAbutmentPurchaseResult.value = false
        uploadAbutmentPurchaseNum.value = 0
        const formData = new FormData()
        formData.append("file", param.file)
        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };
        try {
            let res = await abutmentPurchaseUpload(formData, config)
            if (res.code === 0) {
                uploadAbutmentPurchaseResult.value = true
                uploadAbutmentPurchaseNum.value = res.data.successNum
                failedAbutmentPurchase.value = res.data.failList
                if (!!uploadAbutmentPurchaseNum.value) ElMessage.success('上传成功，上传数量' + uploadAbutmentPurchaseNum.value)
            } else {
                ElMessage.error(res.message)
            }
        } catch (e) {
            ElMessage.error('上传失败，请检查网络或联系客服')
        }
        uploadAbutmentPurchaseLoading.value = false
    }
    return {
        uploadAbutmentPurchaseResult,
        uploadAbutmentPurchaseLoading,
        uploadAbutmentPurchaseNum,
        failedAbutmentPurchase,
        uploadAbutmentPurchase
    }
}

function useUploadHospitalPrice() {
    const uploadHospitalPriceResult = ref(false)
    const uploadHospitalPriceLoading = ref(false)
    const uploadHospitalPriceNum = ref(0)
    const failedHospitalPrice = ref([])
    const uploadHospitalPrice = async param => {
        if (uploadHospitalPriceLoading.value) return
        uploadHospitalPriceLoading.value = true
        uploadHospitalPriceResult.value = false
        uploadHospitalPriceNum.value = 0
        const formData = new FormData()
        formData.append("file", param.file)
        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };
        try {
            let res = await hospitalPriceUpload(formData, config)
            if (res.code === 0) {
                uploadHospitalPriceResult.value = true
                uploadHospitalPriceNum.value = res.data.successNum
                failedHospitalPrice.value = res.data.failList
                if (!!uploadHospitalPriceNum.value) ElMessage.success('上传成功，上传数量' + uploadHospitalPriceNum.value)
            } else {
                ElMessage.error(res.message)
            }
        } catch (e) {
            ElMessage.error('上传失败，请检查网络或联系客服')
        }
        uploadHospitalPriceLoading.value = false
    }
    return {
        uploadHospitalPriceResult,
        uploadHospitalPriceLoading,
        uploadHospitalPriceNum,
        failedHospitalPrice,
        uploadHospitalPrice
    }
}

function useUploadPrivatePrice() {
    const uploadPrivatePriceResult = ref(false)
    const uploadPrivatePriceLoading = ref(false)
    const uploadPrivatePriceNum = ref(0)
    const failedPrivatePrice = ref([])
    const uploadPrivatePrice = async param => {
        if (uploadPrivatePriceLoading.value) return
        uploadPrivatePriceLoading.value = true
        uploadPrivatePriceResult.value = false
        uploadPrivatePriceNum.value = 0
        const formData = new FormData()
        formData.append("file", param.file)
        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };
        try {
            let res = await privatePriceUpload(formData, config)
            if (res.code === 0) {
                uploadPrivatePriceResult.value = true
                uploadPrivatePriceNum.value = res.data.successNum
                failedPrivatePrice.value = res.data.failList
                if (!!uploadPrivatePriceNum.value) ElMessage.success('上传成功，上传数量' + uploadPrivatePriceNum.value)
            } else {
                ElMessage.error(res.message)
            }
        } catch (e) {
            ElMessage.error('上传失败，请检查网络或联系客服')
        }
        uploadPrivatePriceLoading.value = false
    }
    return {
        uploadPrivatePriceResult,
        uploadPrivatePriceLoading,
        uploadPrivatePriceNum,
        failedPrivatePrice,
        uploadPrivatePrice
    }
}

function useUploadDoctorPrice() {
    const uploadDoctorPriceResult = ref(false)
    const uploadDoctorPriceLoading = ref(false)
    const uploadDoctorPriceNum = ref(0)
    const failedDoctorPrice = ref([])
    const uploadDoctorPrice = async param => {
        if (uploadDoctorPriceLoading.value) return
        uploadDoctorPriceLoading.value = true
        uploadDoctorPriceResult.value = false
        uploadDoctorPriceNum.value = 0
        const formData = new FormData()
        formData.append("file", param.file)
        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };
        try {
            let res = await doctorPriceUpload(formData, config)
            if (res.code === 0) {
                uploadDoctorPriceResult.value = true
                uploadDoctorPriceNum.value = res.data.successNum
                failedDoctorPrice.value = res.data.failList
                if (!!uploadDoctorPriceNum.value) ElMessage.success('上传成功，上传数量' + uploadDoctorPriceNum.value)
            } else {
                ElMessage.error(res.message)
            }
        } catch (e) {
            ElMessage.error('上传失败，请检查网络或联系客服')
        }
        uploadDoctorPriceLoading.value = false
    }
    return {
        uploadDoctorPriceResult,
        uploadDoctorPriceLoading,
        uploadDoctorPriceNum,
        failedDoctorPrice,
        uploadDoctorPrice
    }
}

function useUploadDoctorHabit() {
    const uploadDoctorHabitResult = ref(false)
    const uploadDoctorHabitLoading = ref(false)
    const uploadDoctorHabitNum = ref(0)
    const failedDoctorHabit = ref([])
    const uploadDoctorHabit = async param => {
        if (uploadDoctorHabitLoading.value) return
        uploadDoctorHabitLoading.value = true
        uploadDoctorHabitResult.value = false
        uploadDoctorHabitNum.value = 0
        const formData = new FormData()
        formData.append("file", param.file)
        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };
        try {
            let res = await doctorHabitUpload(formData, config)
            if (res.code === 0) {
                uploadDoctorHabitResult.value = true
                uploadDoctorHabitNum.value = res.data.successNum
                failedDoctorHabit.value = res.data.failList
                if (!!uploadDoctorHabitNum.value) ElMessage.success('上传成功，上传数量' + uploadDoctorHabitNum.value)
            } else {
                ElMessage.error(res.message)
            }
        } catch (e) {
            ElMessage.error('上传失败，请检查网络或联系客服')
        }
        uploadDoctorHabitLoading.value = false
    }
    return {
        uploadDoctorHabitResult,
        uploadDoctorHabitLoading,
        uploadDoctorHabitNum,
        failedDoctorHabit,
        uploadDoctorHabit
    }
}

function useUploadStockBegin() {
    const uploadStockBeginResult = ref(false)
    const uploadStockBeginLoading = ref(false)
    const uploadStockBeginNum = ref(0)
    const failedStockBegin = ref([])
    const uploadStockBegin = async param => {
        if (uploadStockBeginLoading.value) return
        uploadStockBeginLoading.value = true
        uploadStockBeginResult.value = false
        uploadStockBeginNum.value = 0
        const formData = new FormData()
        formData.append("file", param.file)
        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };
        try {
            let res = await stockBeginUpload(formData, config)
            if (res.code === 0) {
                uploadStockBeginResult.value = true
                uploadStockBeginNum.value = res.data.successNum
                failedStockBegin.value = res.data.failList
                if (!!uploadStockBeginNum.value) ElMessage.success('上传成功，上传数量' + uploadStockBeginNum.value)
            } else {
                ElMessage.error(res.message)
            }
        } catch (e) {
            ElMessage.error('上传失败，请检查网络或联系客服')
        }
        uploadStockBeginLoading.value = false
    }
    return {
        uploadStockBeginResult,
        uploadStockBeginLoading,
        uploadStockBeginNum,
        failedStockBegin,
        uploadStockBegin
    }
}

function useDownload() {
    const downFileLoading = ref(false)

    // 下载文件，自定义文件名称
    function downFile(url, fileName, token = '') {
        ElMessage.success('开始下载')
        downFileLoading.value = true
        const x = new XMLHttpRequest()
        x.open('GET', url, true)
        if (token) x.setRequestHeader("X-Auth-Token", token);
        x.responseType = 'blob'
        x.onload = function () {
            const url = window.URL.createObjectURL(x.response)
            const a = document.createElement('a')
            a.href = url
            a.download = fileName
            a.click()
        }
        x.send()
        x.onreadystatechange = function () {
            if (x.readyState === 4) {
                downFileLoading.value = false
            }
        }
    }

    return {
        downFile,
        downFileLoading
    }
}

function useUpdateFileDownload() {
    const updateFileDownloadResult = ref(false)
    const updateFileDownloadLoading = ref(false)
    let updateFileDownloadId = 0
    const updateFileDownload = async id => {
        if (updateFileDownloadLoading.value && updateFileDownloadId === id) return
        updateFileDownloadLoading.value = true
        updateFileDownloadResult.value = false
        updateFileDownloadId = id
        updateFileDownloadResult.value = await fileUpdateDownload({id: id})
        updateFileDownloadLoading.value = false
    }
    return {
        updateFileDownload
    }
}

// blob 转 base64
function blobToDataURI(blob, callback) {
    let reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = function (e) {
        callback(e.target.result);
    };
}

//将base64转换为blob
function dataURLtoBlob(dataurl) {
    let arr = dataurl.split(',')
    let mime = arr[0].match(/:(.*?);/)[1]
    // let mine = 'jpg'
    let bstr = atob(arr[1])
    let n = bstr.length
    let u8arr = new Uint8Array(n)
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }
    return new Blob([u8arr], {type: mime});
}

//将blob转换为file
function blobToFile(theBlob, fileName) {
    theBlob.lastModifiedDate = new Date()
    theBlob.name = fileName
    return theBlob
}

function base64ToFile(base64data, fileName) {
    let blob = dataURLtoBlob(base64data)
    return new File([blob], fileName, {type: blob.type})
}

export {
    excelTemplateList,
    dataURLtoBlob,
    blobToFile,
    base64ToFile,
    picPathPrefix,
    useUploadPicCompress,
    useUploadPic,
    beforePicUpload,
    beforeExcelUpload,
    beforeMax20MUpload,
    useUploadWorker,
    useUploadProduct,
    useUploadProductType,
    useUploadProductStep,
    useUploadProductMaterial,
    useUploadDeptStep,
    useUploadSupply,
    useUploadMaterialType,
    useUploadMaterial,
    useUploadMaterialSupply,
    useUploadHospital,
    useUploadDoctor,
    useUploadHospitalPrice,
    useUploadPrivatePrice,
    useUploadDoctorHabit,
    useUploadDoctorPrice,
    useUploadStockBegin,
    useUploadCase,
    useUploadCaseStep,
    useUploadAbutmentPurchase,
    getExcelTemplate,
    useGetTokenByUse,
    blobToDataURI,
    useDownload,
    useUpdateFileDownload,
    useUploadProperty,
    useUploadSaleTarget
}