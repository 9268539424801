import {reactive, ref, inject, toRaw} from 'vue'
import moment from 'moment'
import {caseExpressGet, caseExpressSave, caseGetRoutes} from "@/api/case";
import {receiverTypeEnum} from "@/util/enum";

// 保存订单快递信息
function useSaveCaseExpress() {
    const caseExpress = reactive({
        caseId: 0,
        expressNo: '',
        expressCompany: '',
        isCollect: 0,
        num: 1,
        pickUpDate: new Date(),
        sendType: '',
        receiverType: '',
        contactPerson: '',
        contactPhoneNo: '',
        province: '',
        city: '',
        area: '',
        address: '',
        shipPerson: '',
        shipPhoneNo: '',
        shipRemark: '',
        hospitalDeptName: '',
        packDate: '',
    })
    const saveCaseExpressLoading = ref(false)
    const saveCaseExpressResult = ref(false)
    const saveCaseExpress = async () => {
        if (saveCaseExpressLoading.value) return
        saveCaseExpressResult.value = false
        saveCaseExpressLoading.value = true
        saveCaseExpressResult.value = await caseExpressSave(caseExpress)
        saveCaseExpressLoading.value = false
    }
    return {
        caseExpress,
        saveCaseExpressLoading,
        saveCaseExpressResult,
        saveCaseExpress
    }
}

// 获取订单快递信息
function useGetCaseExpress() {
    const getCaseExpressLoading = ref(false)
    const getCaseExpress = async caseId => {
        if (getCaseExpressLoading.value) return
        getCaseExpressLoading.value = true
        let res = await caseExpressGet({id: caseId})
        getCaseExpressLoading.value = false
        return res
    }
    return {
        getCaseExpressLoading,
        getCaseExpress,
    }
}

function useInitSendType() {
    const newCaseExpress = () => {
        return {
            expressCompany: '',
            isCollect: 0,
            sendType: '',
            receiverType: '',
            contactPerson: '',
            contactPhoneNo: '',
            province: '',
            city: '',
            area: '',
            address: '',
            shipPerson: '',
            shipPhoneNo: '',
            shipRemark: '',
            hospitalDeptName: '',
        }
    }
    /**
     * 根据订单医院、医生和科室设置 默认修改发货和收货信息
     * @param storeHospitalDetail 医院
     * @param storeDoctor 医生
     * @param storeWorker sale
     * @returns {{area: string, address: string, province: string, city: string, receiverType: string, expressCompany: string, contactPhoneNo: string, isCollect: number, sendType: string, contactPerson: string}}
     */
    const initSendType = (storeHospitalDetail, storeDoctor, storeWorker) => {
        let caseExpress = newCaseExpress()
        if (!storeHospitalDetail) return caseExpress
        caseExpress.sendType = storeHospitalDetail.sendType
        caseExpress.receiverType = storeHospitalDetail.receiverType
        //如果科室独立收货 收货对象需要改为科室
        if (storeDoctor && storeHospitalDetail && storeHospitalDetail.deptList && storeHospitalDetail.deptList.length) {
            let hospitalDept = storeHospitalDetail.deptList.find(x => x.id === storeDoctor.hospitalDeptId)
            if (hospitalDept && hospitalDept.isOwnReceive === 1) {
                caseExpress.receiverType = receiverTypeEnum.科室收货
            }
        }
        caseExpress.expressCompany = storeHospitalDetail.expressCompany
        caseExpress.isCollect = storeHospitalDetail.isCollect
        caseExpress.shipPerson = storeHospitalDetail.shipPerson
        caseExpress.shipPhoneNo = storeHospitalDetail.shipPhoneNo
        caseExpress.shipRemark = storeHospitalDetail.shipRemark
        changeReceiverType(storeHospitalDetail, storeDoctor, storeWorker, caseExpress)
        return caseExpress
    }

    /**
     * 根据收货对象自动填充收货人和收货地址信息
     */
    const changeReceiverType = (storeHospitalDetail, storeDoctor, storeWorker, caseExpress) => {
        caseExpress.contactPerson = ''
        caseExpress.contactPhoneNo = ''
        caseExpress.province = ''
        caseExpress.city = ''
        caseExpress.area = ''
        caseExpress.address = ''
        caseExpress.hospitalDeptName = ''
        if (caseExpress.receiverType === receiverTypeEnum.医院收货) {
            if (!storeHospitalDetail) return
            caseExpress.contactPerson = storeHospitalDetail.contactPerson
            caseExpress.contactPhoneNo = storeHospitalDetail.contactPhoneNo
            caseExpress.province = storeHospitalDetail.province
            caseExpress.city = storeHospitalDetail.city
            caseExpress.area = storeHospitalDetail.area
            caseExpress.address = storeHospitalDetail.address
        } else if (caseExpress.receiverType === receiverTypeEnum.科室收货) {
            if (!storeHospitalDetail.deptList || !storeHospitalDetail.deptList.length) return
            let hospitalDept = storeHospitalDetail.deptList.find(x => x.id === storeDoctor.hospitalDeptId)
            if (!hospitalDept) return
            caseExpress.contactPerson = hospitalDept.contactPerson
            caseExpress.contactPhoneNo = hospitalDept.contactPhoneNo
            caseExpress.province = hospitalDept.province
            caseExpress.city = hospitalDept.city
            caseExpress.area = hospitalDept.area
            caseExpress.address = hospitalDept.address
            caseExpress.hospitalDeptName = hospitalDept.name
        } else if (caseExpress.receiverType === receiverTypeEnum.销售 && storeWorker) {
            caseExpress.contactPerson = storeWorker.name
            caseExpress.contactPhoneNo = storeWorker.phoneNo
            caseExpress.province = storeWorker.province
            caseExpress.city = storeWorker.city
            caseExpress.area = storeWorker.area
            caseExpress.address = storeWorker.address
        }
    }

    return {
        newCaseExpress,
        initSendType,
        changeReceiverType
    }
}

function useGetCaseRoutes() {
    const getCaseRoutesLoading = ref(false)
    const caseRoutes = reactive({
        facHospitalName: '',
        facDoctorName: '',
        facWorkerName: '',
        expressCompany: '',
        expressNo: '',
        routeList: []
    })
    const getCaseRoutes = async caseNo => {
        if (getCaseRoutesLoading.value) return
        getCaseRoutesLoading.value = true
        let res = await caseGetRoutes({caseNo: caseNo})
        if (res) {
            Object.assign(caseRoutes, res)
            if (caseRoutes.routeList.length) caseRoutes.routeList.reverse()
        }
        getCaseRoutesLoading.value = false
    }

    return {
        getCaseRoutes,
        getCaseRoutesLoading,
        caseRoutes
    }
}

export {
    useSaveCaseExpress,
    useGetCaseExpress,
    useInitSendType,
    useGetCaseRoutes
}