<template>
    <el-table border stripe :data="showStepList" style="width:1080px;">
        <el-table-column label="生产流程" align="center">
            <el-table-column label="制作工序" align="center" prop="stepName" width="100"
                             class-name="text-danger"></el-table-column>
            <el-table-column label="制作人" align="center" prop="workerName" width="100"></el-table-column>
            <el-table-column label="开始时间" align="center" :formatter="minuteFormat" prop="operTime"
                             width="140"></el-table-column>
            <el-table-column label="完成时间" align="center" :formatter="minuteFormat" prop="finishTime"
                             width="140"></el-table-column>
            <el-table-column label="设备名称" align="center" prop="propertyName" width="100"></el-table-column>
            <el-table-column label="机器编号" align="center" prop="machineNo" width="100"></el-table-column>
            <el-table-column label="材料名称" align="center" prop="materialName" width="100"></el-table-column>
            <el-table-column label="材料批号" align="center" prop="materialLotNo" width="100"></el-table-column>
            <el-table-column label="工艺参数" align="center" prop="keyParam" width="100"></el-table-column>
            <el-table-column label="工艺时间" align="center" prop="keyTime" width="100"></el-table-column>
        </el-table-column>
    </el-table>
</template>

<script setup>
import {ref, reactive, onMounted, onActivated, watch} from 'vue'
import {Plus, Search, Refresh, Edit, Delete, Check} from '@element-plus/icons-vue';
import {minuteFormat} from "@/util/formatter";
import {stepOperType} from "@/util/enum";

// 页面显示的制作扫描记录
const showStepList = ref([])
const props = defineProps({
    id: Number,//CaseProductId
    stepInfo: {
        type: Object
    }
})
watch(() => props.id, (newVal, oldVal) => {
    showStepList.value = []
    if (!newVal) {
        return
    }
    setTimeout(() => {
        getShowStepList()
    }, 100)
})
const getShowStepList = () => {
    let stepInfo = props.stepInfo
    if (stepInfo.inDisinfect) {
        showStepList.value.push({
            stepName: '入件消毒',
            workerName: stepInfo.inDisinfect.operWorkerName,
            operTime: stepInfo.inDisinfect.beginTime,
            machineNo: stepInfo.inDisinfect.machineNo,
            finishTime: stepInfo.inDisinfect.endTime,
        })
    }
    if (stepInfo.stepList) {
        let stepList = stepInfo.stepList.filter(x => x.operType === stepOperType.制作接收)
        for (let item of stepList) {
            let finishList = stepInfo.stepList.filter(x => x.stepId === item.stepId && x.id > item.id)
            if (finishList && finishList.length) {
                item.finishTime = finishList[0].operTime
                item.keyParam = finishList[0].keyParam ? finishList[0].keyParam : item.keyParam
                item.keyTime = finishList[0].keyTime ? finishList[0].keyTime : item.keyTime
            }
            showStepList.value.push(item)
        }
    }
    if (stepInfo.outDisinfect) {
        showStepList.value.push({
            stepName: '出件消毒',
            workerName: stepInfo.outDisinfect.operWorkerName,
            operTime: stepInfo.outDisinfect.beginTime,
            finishTime: stepInfo.outDisinfect.endTime,
            machineNo: stepInfo.outDisinfect.machineNo,
        })
    }
}
onMounted(() => {
    getShowStepList()
})
</script>