import {reactive, ref, inject, toRaw} from 'vue'
import moment from 'moment'
import {
    factoryAlertGetPage,
    factoryAlertGetUnreadNum,
    factoryAlertReadAll,
    factoryAlertReadOne,
} from "@/api/system";

// 我的消息分页
function useGetPageFactoryAlert() {
    const getPageFactoryAlertParam = reactive({
        beginTime: '',
        endTime: '',
        factoryAlertType: '',
        isRead: -1,
        pageIndex: 1,
        pageSize: 10,
    })
    const getPageFactoryAlertParamEmpty = Object.assign({}, getPageFactoryAlertParam)
    const getPageFactoryAlertResult = reactive({
        totalCount: 0,
        totalPages: 0,
    })
    const factoryAlertPage = ref([])
    const initGetPageFactoryAlertParam = () => {
        Object.assign(getPageFactoryAlertParam, getPageFactoryAlertParamEmpty)
    }
    const getPageFactoryAlertLoading = ref(false)
    const getPageFactoryAlert = async () => {
        if (getPageFactoryAlertLoading.value) return
        getPageFactoryAlertLoading.value = true
        let data = await factoryAlertGetPage(getPageFactoryAlertParam)
        if (data && data.list && data.list.length) {
            factoryAlertPage.value = data.list
            getPageFactoryAlertResult.totalPages = Math.ceil(data.totalCount / getPageFactoryAlertParam.pageSize)
            getPageFactoryAlertResult.totalCount = data.totalCount
        } else {
            factoryAlertPage.value = []
            getPageFactoryAlertResult.totalPages = 0
            getPageFactoryAlertResult.totalCount = 0
        }
        getPageFactoryAlertLoading.value = false
    }
    return {
        getPageFactoryAlertParam,
        getPageFactoryAlertResult,
        factoryAlertPage,
        initGetPageFactoryAlertParam,
        getPageFactoryAlertLoading,
        getPageFactoryAlert
    }
}

// 获取未读提醒数量
function useGetUnreadNumFactoryAlert() {
    const getUnreadNumFactoryAlertLoading = ref(false)
    const getUnreadNumFactoryAlert = async () => {
        if (getUnreadNumFactoryAlertLoading.value) return
        getUnreadNumFactoryAlertLoading.value = true
        let res = await factoryAlertGetUnreadNum({})
        getUnreadNumFactoryAlertLoading.value = false
        return res
    }
    return {
        getUnreadNumFactoryAlertLoading,
        getUnreadNumFactoryAlert,
    }
}

function useFactoryAlertReadAll() {
    const readAllFactoryAlertLoading = ref(false)
    const readAllFactoryAlertResult = ref(false)
    const readAllFactoryAlert = async () => {
        if (readAllFactoryAlertLoading.value) return
        readAllFactoryAlertResult.value = false
        readAllFactoryAlertLoading.value = true
        readAllFactoryAlertResult.value = await factoryAlertReadAll({})
        readAllFactoryAlertLoading.value = false
    }
    return {
        readAllFactoryAlert,
        readAllFactoryAlertLoading,
        readAllFactoryAlertResult,
    }
}

function useFactoryAlertReadOne() {
    const readOneFactoryAlertLoading = ref(false)
    const readOneFactoryAlertResult = ref(false)
    const readOneFactoryAlert = async id => {
        if (readOneFactoryAlertLoading.value) return
        readOneFactoryAlertResult.value = false
        readOneFactoryAlertLoading.value = true
        readOneFactoryAlertResult.value = await factoryAlertReadOne({id: id})
        readOneFactoryAlertLoading.value = false
    }
    return {
        readOneFactoryAlert,
        readOneFactoryAlertLoading,
        readOneFactoryAlertResult,
    }
}

export {
    useGetPageFactoryAlert,
    useGetUnreadNumFactoryAlert,
    useFactoryAlertReadAll,
    useFactoryAlertReadOne,
}