import {ref, reactive} from "vue";
import {attachBatchAdd, attachMod, attachDelete, attachGetList} from "@/api/case";
import {attachBelongEnum} from "@/util/enum";

function useBatchAddAttach() {
    const batchAddAttachParam = ref([])
    const newAttach = () => {
        return {
            name: '',
            belong: attachBelongEnum.医院
        }
    }
    const addAttach = () => {
        batchAddAttachParam.value.push(newAttach())
    }
    const removeAttach = index => {
        batchAddAttachParam.value.splice(index, 1)
    }
    const batchAddAttachLoading = ref(false)
    const batchAddAttachResult = ref(false)
    const batchAddAttach = async () => {
        batchAddAttachResult.value = false
        if (batchAddAttachLoading.value) return
        batchAddAttachLoading.value = true
        let param = {
            list: batchAddAttachParam.value.filter(x => !!x.name)
        }
        batchAddAttachResult.value = await attachBatchAdd(param)
        batchAddAttachLoading.value = false
    }
    return {
        batchAddAttachParam,
        addAttach,
        removeAttach,
        batchAddAttachLoading,
        batchAddAttachResult,
        batchAddAttach,
    }
}

function useModAttach() {
    const modAttachParam = reactive({
        id: null,
        name: '',
        belong: null
    })
    const modAttachLoading = ref(false)
    const modAttachResult = ref(false)
    const modAttach = async () => {
        modAttachResult.value = false
        if (modAttachLoading.value) return
        modAttachLoading.value = true
        modAttachResult.value = await attachMod(modAttachParam)
        modAttachLoading.value = false
    }
    return {
        modAttachParam,
        modAttachLoading,
        modAttachResult,
        modAttach,
    }
}

function useDeleteAttach() {
    const deleteAttachLoading = ref(false)
    const deleteAttachResult = ref(false)
    const deleteAttach = async id => {
        deleteAttachResult.value = false
        if (deleteAttachLoading.value) return
        deleteAttachLoading.value = true
        deleteAttachResult.value = await attachDelete({id: id})
        deleteAttachLoading.value = false
    }
    return {
        deleteAttachLoading,
        deleteAttachResult,
        deleteAttach,
    }
}

function useGetListAttach() {
    const getListAttachLoading = ref(false)
    const attachList = ref([])
    const getListAttach = async () => {
        if (getListAttachLoading.value) return
        getListAttachLoading.value = true
        let data = await attachGetList({})
        if (data) {
            attachList.value = data
        }
        getListAttachLoading.value = false
    }
    return {
        getListAttachLoading,
        attachList,
        getListAttach,
    }
}

export {
    useBatchAddAttach,
    useModAttach,
    useGetListAttach,
    useDeleteAttach
}