import {reactive, ref, inject, toRaw} from 'vue'
import {
    caseSettingSaveList,
    caseSettingGetList,
} from "@/api/case";

function useSaveListCaseSetting() {
    const saveListCaseSettingLoading = ref(false)
    const saveListCaseSettingResult = ref(false)
    const saveListCaseSetting = async list => {
        if (saveListCaseSettingLoading.value) return
        saveListCaseSettingLoading.value = true
        saveListCaseSettingResult.value = false
        saveListCaseSettingResult.value = await caseSettingSaveList(list)
        saveListCaseSettingLoading.value = false
    }
    return {
        saveListCaseSettingLoading,
        saveListCaseSettingResult,
        saveListCaseSetting,
    }
}


// 获取订单设置列表
function useGetListCaseSetting() {
    const getListCaseSettingParam = reactive({
    })
    const getListCaseSettingLoading = ref(false)
    const caseSettingList = ref([])
    const getListCaseSetting = async () => {
        if (getListCaseSettingLoading.value) return
        getListCaseSettingLoading.value = true
        let data = await caseSettingGetList(getListCaseSettingParam)
        if (data) {
            caseSettingList.value = data
        }
        getListCaseSettingLoading.value = false
    }
    return {
        getListCaseSettingParam,
        getListCaseSettingLoading,
        caseSettingList,
        getListCaseSetting,
    }
}

export {
    useSaveListCaseSetting,
    useGetListCaseSetting,
}