<template>
    <div>
        <el-space>
            <el-button :icon="Check" @click="handleSave" type="primary">保存信息
            </el-button>
            <span style="width: 80px;">
                    已选择{{ chooseLength ? chooseLength : '' }}：
                </span>
            <span class="text-danger">
                    {{ namesToChoose }}
                </span>
        </el-space>
        <div class="flex-row">
            <div style="width:190px;">
                <el-tree :data="storeProductType" @node-click="chooseProductType"
                         :props="commonTreeProps"
                         node-key="id" ref="treeRef"
                         :highlight-current="true" default-expand-all
                         :expand-on-click-node="true">
                </el-tree>
            </div>
            <div class="flex-grow">
                <el-space :size="0">
                    <el-input placeholder="搜索名称、编号、拼音首字母" v-model.trim="searchProductInfo"
                              class="input-large"
                              :suffix-icon="Search" maxlength="50"></el-input>
                    <el-button :icon="Refresh" @click="resetSearchProduct">重置搜索</el-button>
                    <span class="text-danger">单击列表产品即可选择</span>
                </el-space>
                <el-table border stripe :data="modalProductList" @row-click="chooseProduct" style="width: 100%;"
                          max-height="800px">
                    <el-table-column label="选择" width="60">
                        <template #header>
                            <el-checkbox v-model="checkAll" @change="chooseAll"></el-checkbox>
                        </template>
                        <template #default="scope">
                            <el-checkbox v-model="scope.row.isCheck" @click.stop></el-checkbox>
                        </template>
                    </el-table-column>
                    <el-table-column prop="code" label="编号" width="90"></el-table-column>
                    <el-table-column prop="name" label="名称" width="260"></el-table-column>
                    <el-table-column prop="productTypeName" label="种类" min-width="150"></el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref, watch, onMounted, computed} from 'vue'
import {Plus, Search, Refresh, Edit, Delete, Check, Hide, View} from '@element-plus/icons-vue';
import {useStore} from 'vuex'
import {productDefaultFilter} from "@/util/formatter";
import {nullProductNo} from "@/util/constant";
import {commonTreeProps} from "@/util/constant";

const props = defineProps({
    productIds: Array,
})
const emits = defineEmits(['update:productIds', 'saveProducts'])
const store = useStore()
const storeProductList = store.state.productList
if (storeProductList.length) {
    storeProductList.forEach(x => x.isCheck = false)
}
onMounted(() => {
    if (props.productIds && props.productIds.length) {
        for (let product of storeProductList) {
            product.isCheck = props.productIds.includes(product.id)
        }
    }
})
watch(() => props.productIds, (n, o) => {
    if (n && n.length) {
        for (let product of storeProductList) {
            product.isCheck = n.includes(product.id)
        }
    } else {
        storeProductList.forEach(x => x.isCheck = false)
    }
})
// 缓存产品种类
const storeProductType = computed(() => store.state.productType)
const treeRef = ref(null)// 产品树ref
const searchProductTypeId = ref(0)// 树节点选择的产品种类
const chooseProductType = val => {
    searchProductTypeId.value = val.id
    searchModalProduct()
    checkAll.value = modalProductList.value.length && modalProductList.value.every(x => x.isCheck)
}
const searchProductInfo = ref('')// 文本搜索框
let debounce = null
watch(searchProductInfo, (n, o) => {
    if (debounce !== null) {
        clearTimeout(debounce)
    }
    debounce = setTimeout(() => {
        searchModalProduct()
    }, 300)
})
const modalProductList = ref([])// 显示的产品列表
const searchModalProduct = () => {
    if (!searchProductTypeId.value && !searchProductInfo.value) {
        modalProductList.value = storeProductList.filter(productDefaultFilter)
    } else {
        modalProductList.value = storeProductList.filter(x => {
            return x.code !== nullProductNo
                && (!searchProductTypeId.value || x.typePath.indexOf(',' + searchProductTypeId.value + ',') >= 0)
                && (!searchProductInfo.value ||
                    (x.name && x.name.indexOf(searchProductInfo.value) >= 0) || (x.code && x.code.indexOf(searchProductInfo.value) >= 0)
                    || (x.pinyin && x.pinyin.indexOf(searchProductInfo.value.toUpperCase()) >= 0))
        })
    }
}
const checkAll = ref(false)// 全选
const chooseAll = () => {
    if (modalProductList.value.length) {
        modalProductList.value.forEach(x => x.isCheck = checkAll.value)
    }
}
const chooseProduct = row => {
    row.isCheck = !row.isCheck
}
const chooseLength = computed(() => {
    return storeProductList.filter(x => x.isCheck).length
})
const namesToChoose = computed(() => {
    if (storeProductList.length) {
        return storeProductList.filter(x => x.isCheck).length > 10 ? storeProductList.filter(x => x.isCheck).filter((x, index) => index < 10).map(x => x.name).join('，') + '等'
            : storeProductList.filter(x => x.isCheck).map(x => x.name).join('，')
    }
    return ''
})
const resetSearchProduct = () => {
    searchProductTypeId.value = 0
    searchProductInfo.value = ''
    checkAll.value = false
    searchModalProduct()
}

const handleSave = () => {
    let productIds = storeProductList.filter(x => x.isCheck).map(x => x.id)
    emits('saveProducts', productIds)
}
</script>