<template>
    <div>
        <div class="view-list flex-row">
            <div class="view-list-left">
                <p class="text-danger">请扫描订单号</p>
                <el-input ref="caseNoRef" v-model.trim="caseNo" maxlength="20" @click="clickCaseNo"
                          placeholder="扫描订单号自动发货"
                          @keydown.enter="handleScanCaseNo"></el-input>
                <el-descriptions :column="1" border>
                    <el-descriptions-item label="扫描工号">
                        <el-input maxlength="20" ref="workerNoRef" v-model.trim="scanWorker.workerNo"
                                  @keydown.enter="getWorkerByNo" placeholder="扫描工号获取当前操作员"
                                  @click="clickWorkerNo"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item label="姓名">
                        {{ scanWorker.workerName }}
                    </el-descriptions-item>
                    <el-descriptions-item label="部门">
                        {{ scanWorker.deptName }}
                    </el-descriptions-item>
                </el-descriptions>
                <el-divider>扫描后自动打印</el-divider>
                <el-checkbox border v-model="caseOutScanSetting.printCaseOut" label="打印发货单"
                             @change="changeCaseOutScanSetting"
                             :false-label="0" :true-label="1"></el-checkbox>
                <el-checkbox border v-model="caseOutScanSetting.printWarranty" label="打印质保卡"
                             @change="changeCaseOutScanSetting"
                             :false-label="0" :true-label="1"></el-checkbox>
                <el-checkbox-group v-model="caseOutScanSetting.printLabelIds" @change="changeCaseOutScanSetting">
                    <el-checkbox border v-for="item in labelTemplateList" :key="item.id" :label="item.id">
                        {{ item.name }}
                    </el-checkbox>
                </el-checkbox-group>
                <el-divider>扫描后自动拍照</el-divider>
                <el-space>
                    <el-checkbox border v-model="caseOutScanSetting.autoScan" label="开启高拍仪自动拍照"
                                 @change="changeCaseOutScanSetting" title="目前只支持良田高拍仪"
                                 :false-label="0" :true-label="1"></el-checkbox>
                    <el-button type="success" :icon="Picture" @click="takePhoto">拍照</el-button>
                </el-space>

                <img id="view1" alt="主摄像头" v-show="device.isConnect==='已连接'">
                <el-image class="scan-view" v-if="device.isConnect!=='已连接'"/>
                <el-space :size="0">
                    <el-button title="刷新高拍仪设备和镜头状态" :icon="Refresh" @click="getDeviceInfo">刷新设备状态</el-button>
                    <el-button title="高拍仪镜头向左旋转" @click="rotate(90)">镜头左转</el-button>
                    <el-button title="高拍仪镜头向右旋转" @click="rotate(270)">镜头右转</el-button>
                </el-space>
                <el-descriptions border :column="2">
                    <el-descriptions-item label="设备状态">
                        {{ device.isConnect }}
                    </el-descriptions-item>
                    <el-descriptions-item label="设备型号">
                        {{ device.version }}
                    </el-descriptions-item>
                    <el-descriptions-item label="主摄状态">
                        {{ device.videoStatus }}
                    </el-descriptions-item>
                </el-descriptions>
            </div>
            <div class="view-list-right flex-grow">
                <el-table :data="todayOutCaseList" border style="width: 100%" @row-click="showCaseInfo"
                          v-loading="scanCaseOutLoading || getTodayOutCaseListLoading">
                    <el-table-column type="index" width="70" fixed>
                    </el-table-column>
                    <el-table-column label="订单号" prop="caseNo" width="115" fixed></el-table-column>
                    <el-table-column label="客户" prop="facHospitalName" width="115" fixed></el-table-column>
                    <el-table-column label="医生" prop="facDoctorName" width="90" fixed></el-table-column>
                    <el-table-column label="患者姓名" prop="patientName" width="90" fixed></el-table-column>
                    <el-table-column label="销售员" prop="facWorkerName" width="90"></el-table-column>
                    <el-table-column label="片区" prop="district" width="90"></el-table-column>
                    <el-table-column label="订单类型" prop="caseType" width="60" fixed
                                     :formatter="(r,c,v)=>enumTypeFormatter(v,caseType)"></el-table-column>
                    <el-table-column label="盒号" prop="boxNo" width="70"></el-table-column>
                    <el-table-column label="入件日期" prop="inDate" width="98" :formatter="dateFormat"></el-table-column>
                    <el-table-column label="订单金额" prop="totalPrice" width="90"></el-table-column>
                    <el-table-column label="客户单号" prop="hospitalCaseNo" width="110"></el-table-column>
                    <el-table-column label="医生备注" prop="doctorRemark" width="110"></el-table-column>
                    <el-table-column label="工厂备注" prop="factoryRemark" width="110"></el-table-column>
                </el-table>
            </div>
        </div>

        <el-dialog v-model="caseVisible" title="订单详情" width="1200px">
            <el-space>
                <el-button @click="showPrintCaseOut(true)"
                           v-show="!!scanCaseInfo.id"
                           :icon="Printer"
                           type="primary" :loading="printSingleLoading"
                           plain>
                    出货单
                </el-button>
                <el-space :size="0">
                    <el-select v-model="warrantyProductId" @change="setDefaultWarranty" placeholder="选择订单产品"
                               class="w-120">
                        <el-option v-for="product in scanCaseInfo.productList" :key="product.productId"
                                   :value="product.productId"
                                   :label="product.productName"></el-option>
                    </el-select>
                    <el-select v-model="warrantyTemplateId" placeholder="选择质保卡模板" class="w-120">
                        <el-option v-for="item in warrantyTemplateList" :key="item.id" :value="item.id"
                                   :label="item.name"></el-option>
                    </el-select>
                    <el-button @click="handlePrintWarranty(true)"
                               v-show="!!scanCaseInfo.id"
                               :icon="Printer"
                               type="success" :loading="printSingleLoading"
                               plain>
                        质保卡
                    </el-button>
                </el-space>

                <el-space v-for="item in labelTemplateList" :key="item.id" :size="0">
                    <el-button :icon="Printer" @click="handlePrintCaseLabel(item.id,true)"
                               :loading="printSingleLoading">
                        {{ item.name }}
                    </el-button>
                </el-space>
            </el-space>
            <case-view :id="caseId" @get-case-info="setCaseInfo"></case-view>
        </el-dialog>

        <el-dialog v-model="printCaseOutVisible" title="打印出货单" width="430px">
            <el-alert type="error" v-if="!caseOutTemplateList.length" title="请到基础设置-订单打印格式-出货单设置页面设置确认单格式"></el-alert>
            <el-form>
                <el-form-item label="模板">
                    <el-select v-model="caseOutTemplateId">
                        <el-option v-for="item in caseOutTemplateList" :key="item.id" :value="item.id"
                                   :label="item.name"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <footer>
                <div style="width: 100%;display: flex;padding-top:10px;">
                    <div class="flex-grow"></div>
                    <el-button type="primary" :icon="Printer" @click="handlePrintCaseOut(true)"
                               :disabled="!caseOutTemplateList.length">
                        确认打印
                    </el-button>
                    <el-button type="info" @click='printCaseOutVisible=false'>
                        关闭
                    </el-button>
                </div>
            </footer>
        </el-dialog>

        <el-dialog v-model="printWarrantyVisible" title="打印质保卡" width="430px">
            <el-alert type="error" v-if="!warrantyTemplateList.length" title="请到基础设置-订单打印格式-质保卡设置页面设置质保卡格式"></el-alert>
            <el-form>
                <el-form-item label="产品">
                    <el-select v-model="warrantyProductId" @change="setDefaultWarranty">
                        <el-option v-for="product in scanCaseInfo.productList" :key="product.productId"
                                   :value="product.productId"
                                   :label="product.productName"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="模板">
                    <el-select v-model="warrantyTemplateId">
                        <el-option v-for="item in warrantyTemplateList" :key="item.id" :value="item.id"
                                   :label="item.name"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <footer>
                <div style="width: 100%;display: flex;padding-top:10px;">
                    <div class="flex-grow"></div>
                    <el-button type="primary" :icon="Printer" @click="handlePrintWarranty(true)"
                               :disabled="!warrantyTemplateList.length">
                        确认打印
                    </el-button>
                    <el-button type="info" @click='printWarrantyVisible=false'>
                        关闭
                    </el-button>
                </div>
            </footer>
        </el-dialog>
    </div>
</template>

<script setup>
import {ref, reactive, onMounted, watch, inject, computed} from 'vue';
import {Plus, Search, Refresh, Edit, Delete, Check, Close, Printer, Picture} from '@element-plus/icons-vue';
import {useStore} from 'vuex'
import {enumTypeFormatter, dateFormat} from "@/util/formatter";
import {
    caseSettingItem,
    caseType,
    caseTypeEnum,
    factoryWebStoreEnum,
    fileUseEnum,
    printTemplateTypeEnum
} from "@/util/enum";
import {ElMessage} from "element-plus";
import {useScanCaseOut, useGetTodayOutCaseList} from "@/use/case/useCaseOut";
import moment from 'moment'

const store = useStore()
const storeWorkerList = computed(() => store.state.workerList)
const currentWorker = computed(() => store.state.currentWorker)
const storeProductList = computed(() => store.state.productList)
const productDetails = computed(() => store.state.productDetails)
const storeHospitalDetails = computed(() => store.state.hospitalDetails)
const storeCaseOutScanSetting = computed(() => store.state.caseOutScanSetting)

const updateFactoryStoreLoading = inject('updateFactoryStoreLoading')
const updateFactoryStoreType = inject('updateFactoryStoreType')
watch(updateFactoryStoreLoading, async (n, o) => {
    if (!n) {
        if (updateFactoryStoreType.value === factoryWebStoreEnum.factorySetting) {
            initFactorySetting()
        }
    }
})
//基础设置
let factorySetting = {
    fullName: '',
    servicePhoneNo: '',
    fullAddress: '',
}
const initFactorySetting = () => {
    if (store.state.factorySetting) {
        let setting = JSON.parse(store.state.factorySetting)
        Object.assign(factorySetting, setting)
        factorySetting.fullAddress = setting.province + setting.city + setting.area + setting.address
    }
}
import {useGetPrinterList, usePrintList} from "@/use/system/usePrint";

const {
    start,
    setTemplatePrinter,
} = useGetPrinterList()
const {
    printListLoading,
    printList,
    printResList,
    printNoScanList
} = usePrintList()
onMounted(() => {
    caseNoRef.value.focus()
    scanWorker.facWorkerId = currentWorker.value.id
    scanWorker.workerName = currentWorker.value.name
    scanWorker.workerNo = currentWorker.value.code
    scanWorker.deptName = currentWorker.value.deptName
    initFactorySetting()
    getListCaseSetting()
    getTemplatePrinterList()
    getTodayOutCaseList()

    start(hiprint)

    initLiangtianSetting()
    open_view1('view1')
    getDeviceInfo()
})

//设置模板默认打印机
const getTemplatePrinterList = async () => {
    await getListCasePrintTemplate()
    if (!casePrintTemplateList.value || !casePrintTemplateList.value.length) return
    for (let item of casePrintTemplateList.value) {
        setTemplatePrinter(item)
    }
}
// 打印设置
const caseOutScanSetting = reactive({
    printCaseOut: 1,
    printWarranty: 0,
    printLabelIds: [],
    autoScan: 0
})
if (storeCaseOutScanSetting && storeCaseOutScanSetting.value) {
    Object.assign(caseOutScanSetting, storeCaseOutScanSetting.value)
}
const changeCaseOutScanSetting = () => {
    store.commit('setCaseOutScanSetting', caseOutScanSetting)
}

// 获取本机今日出货订单列表
const {
    getTodayOutCaseListLoading,
    getTodayOutCaseList,
    todayOutCaseList
} = useGetTodayOutCaseList()

// 扫描工号获取员工信息
const workerNoRef = ref()
const scanWorker = reactive({
    facWorkerId: '',
    workerName: '',
    workerNo: '',
    deptName: ''
})
const getWorkerByNo = () => {
    if (storeWorkerList.value.length) {
        let worker = storeWorkerList.value.find(x => x.code === scanWorker.workerNo)
        if (worker) {
            scanWorker.facWorkerId = worker.id
            scanWorker.workerName = worker.name
            scanWorker.workerNo = worker.code
            scanWorker.deptName = worker.deptName
        } else {
            ElMessage.error('请输入正确的工号')
            scanWorker.facWorkerId = null
            scanWorker.workerName = ''
            scanWorker.workerNo = ''
            scanWorker.deptName = ''
        }
    } else {
        ElMessage.error('系统当前没有设置员工')
    }
}
const clickWorkerNo = () => {
    if (scanWorker.workerNo) {
        workerNoRef.value.select()
    }
}
const {
    getHospitalProductPriceList,
    getHospitalProductPriceListLoading,
    hospitalProductList
} = useGetHospitalProductPriceList()
// 扫描订单获取订单信息
const {
    scanCaseInfo,
    scanCaseOutParam,
    scanCaseOutLoading,
    scanCaseOut
} = useScanCaseOut()
const caseNoRef = ref()
const caseNo = ref('')
const clickCaseNo = () => {
    if (caseNo.value) {
        caseNoRef.value.select()
    }
}
let storeHospitalDetail = null
const handleScanCaseNo = async () => {
    storeHospitalDetail = null
    if (!scanWorker.facWorkerId) {
        ElMessage.error('请先扫描工号')
        workerNoRef.value.focus()
        return
    }
    if (!caseNo.value) {
        ElMessage.error('请扫描订单号')
        return
    }
    scanCaseOutParam.caseNo = caseNo.value
    await scanCaseOut()
    caseNo.value = ''
    caseNoRef.value.focus()

    if (!scanCaseInfo.productList || !scanCaseInfo.productList.length) {
        ElMessage.error('请扫描正确的订单号')
        return
    }
    todayOutCaseList.value.splice(0, 0, {
        id: scanCaseInfo.id,
        caseNo: scanCaseInfo.caseNo,
        caseType: scanCaseInfo.caseType,
        caseTypeStr: scanCaseInfo.caseTypeStr,
        facHospitalNo: scanCaseInfo.facHospitalNo,
        facHospitalName: scanCaseInfo.facHospitalName,
        district: scanCaseInfo.district,
        facDoctorId: scanCaseInfo.facDoctorId,
        facDoctorName: scanCaseInfo.facDoctorName,
        facDoctorNo: scanCaseInfo.facDoctorNo,
        facWorkerId: scanCaseInfo.facWorkerId,
        facWorkerName: scanCaseInfo.facWorkerName,
        patientName: scanCaseInfo.patientName,
        boxNo: scanCaseInfo.boxNo,
        inDate: scanCaseInfo.inDate,
        totalPrice: scanCaseInfo.totalPrice,
        hospitalCaseNo: scanCaseInfo.hospitalCaseNo,
        factoryRemark: scanCaseInfo.factoryRemark,
        doctorRemark: scanCaseInfo.doctorRemark,
    })
    storeHospitalDetail = storeHospitalDetails.value.find(x => x.id === scanCaseInfo.facHospitalId)
    printResList.value = []
    if (caseOutScanSetting.printCaseOut === 1) {
        showPrintCaseOut(false)
    }
    if (caseOutScanSetting.printWarranty === 1) {
        let warrantySetting = caseSettingList.value.find(x => x.settingItem === caseSettingItem.返工返修订单不打印质保卡)
        if (warrantySetting && warrantySetting.intResult && (scanCaseInfo.caseType === caseTypeEnum.返工 || scanCaseInfo.caseType === caseTypeEnum.返修)) {
            //返工返修订单不打印质保卡
        } else {
            printAllWarranty()
        }
    }
    if (caseOutScanSetting.printLabelIds.length) {
        for (let printLabelId of caseOutScanSetting.printLabelIds) {
            handlePrintCaseLabel(printLabelId, false)
        }
    }
    if (printResList.value.length) {
        printNow()
    }
    if (caseOutScanSetting.autoScan === 1) {
        await takePhoto()
    }
}
//缓存数据字典
import {useGetDataValueListFromStore} from "@/use/system/useData";

const {
    getDataValueListFromStore,
} = useGetDataValueListFromStore()
const district = ref([])
const setData = () => {
    district.value = getDataValueListFromStore('district')
}
setData()
//设置
import {useGetListCaseSetting} from "@/use/case/useCaseSetting";

const {
    getListCaseSetting,
    caseSettingList
} = useGetListCaseSetting()
// 打印
import {useGetListCasePrintTemplate} from "@/use/case/useCasePrintTemplate";
import {usePrintCaseOut, usePrintWarranty, usePrintCaseLabel} from "@/use/case/usePrintCase";
import {hiprint} from "vue-plugin-hiprint";

hiprint.init()

const {
    getListCasePrintTemplateParam,
    casePrintTemplateList,
    getListCasePrintTemplate
} = useGetListCasePrintTemplate()
const labelTemplateList = computed(() => casePrintTemplateList.value.filter(x => x.printTemplateType === printTemplateTypeEnum.订单标签 && x.showCaseOut === 1))
import {usePrintSingle} from "@/use/system/usePrint";

const {
    printSingleLoading,
    printSingle
} = usePrintSingle()
// 打印出货单
const {getPrintCaseOutList, getDefaultCaseOutId} = usePrintCaseOut()
const caseOutTemplateType = printTemplateTypeEnum.出货单
const printCaseOutVisible = ref(false)
const caseOutTemplateId = ref('')
const caseOutTemplateList = computed(() => casePrintTemplateList.value.filter(x => x.printTemplateType === caseOutTemplateType))
const showPrintCaseOut = isPrintNow => {
    caseOutTemplateId.value = getDefaultCaseOutId(storeHospitalDetail, caseOutTemplateList)
    //如果设置了 不显示弹窗 直接打印出货单
    let setting = caseSettingList.value.find(x => x.settingItem === caseSettingItem.打印出货单不显示模板选择弹窗)
    if (setting && setting.intResult) {
        handlePrintCaseOut(isPrintNow)
        return
    }
    printCaseOutVisible.value = true
}
const handlePrintCaseOut = async isPrintNow => {
    let caseOutTemplate = casePrintTemplateList.value.find(x => x.id === caseOutTemplateId.value)
    if (!caseOutTemplate) {
        ElMessage.error('请先设置出货单打印模板(基础设置-订单打印格式-出货单设置)')
        return
    }
    printCaseOutVisible.value = false
    let res = getPrintCaseOutList(scanCaseInfo, factorySetting, storeProductList, currentWorker.value.name, caseOutTemplate, hiprint)
    if (isPrintNow) {
        printResList.value = res
        await printList(caseOutTemplate, hiprint)
    } else {
        for (let item of res) {
            printResList.value.push(item)
        }
    }
}
//打印质保卡
const {printWarranty, getDefaultWarrantyId} = usePrintWarranty()
const warrantyTemplateType = printTemplateTypeEnum.质保卡
const printWarrantyVisible = ref(false)
const warrantyProductId = ref('')
const warrantyTemplateId = ref('')
const warrantyTemplateList = computed(() => casePrintTemplateList.value.filter(x => x.printTemplateType === warrantyTemplateType))
const setDefaultWarrantyProduct = () => {
    warrantyProductId.value = ''
    for (let i = 0; i < scanCaseInfo.productList.length; i++) {
        let productDetail = productDetails.value.find(x => x.id === scanCaseInfo.productList[i].productId)
        if (productDetail && productDetail.isWarrant === 1) {
            warrantyProductId.value = scanCaseInfo.productList[i].productId
            break
        }
    }
    if (!warrantyProductId.value) {
        ElMessage.error('没有需要打印质保卡的产品，请检查产品设置')
        return
    }
    setDefaultWarranty()
}
const showPrintWarranty = isPrintNow => {
    setDefaultWarrantyProduct()
    //如果设置了 不显示弹窗并且只有一个产品 直接打印质保卡
    let setting = caseSettingList.value.find(x => x.settingItem === caseSettingItem.打印质保卡不显示模板选择弹窗)
    if (scanCaseInfo.productList.length === 1 && setting && setting.intResult) {
        handlePrintWarranty(isPrintNow)
        return
    }
    printWarrantyVisible.value = true
}
const setDefaultWarranty = () => {
    let storeProductDetail = productDetails.value.find(x => x.id === warrantyProductId.value)
    if (storeProductDetail) {
        warrantyTemplateId.value = getDefaultWarrantyId(storeProductDetail, warrantyTemplateList)
    }
}
const printAllWarranty = () => {
    for (let i = 0; i < scanCaseInfo.productList.length; i++) {
        let caseProduct = scanCaseInfo.productList[i]
        if (caseProduct && caseProduct.isPrintWarranty === 0) {
            // 设置该客户不打印该产品
            continue
        }
        let productDetail = productDetails.value.find(x => x.id === scanCaseInfo.productList[i].productId)
        if (productDetail && productDetail.isWarrant === 1) {
            let warrantyTemplateId = getDefaultWarrantyId(productDetail, warrantyTemplateList)
            let warrantyTemplate = casePrintTemplateList.value.find(x => x.id === warrantyTemplateId)
            if (!!warrantyTemplate) {
                let res = printWarranty(scanCaseInfo, productDetail.id, warrantyTemplate, hiprint, productDetails.value, factorySetting)
                printResList.value.push(res)
            }
        }
    }
}
const handlePrintWarranty = isPrintNow => {
    if (!warrantyProductId.value) {
        ElMessage.error('没有需要打印质保卡的产品，请检查产品设置')
        return
    }
    if (!warrantyTemplateId.value) {
        ElMessage.error('没有质保卡模板，请检查订单打印设置')
        return
    }
    let warrantyTemplate = casePrintTemplateList.value.find(x => x.id === warrantyTemplateId.value)
    if (!warrantyTemplate) {
        ElMessage.error('请选择打印模板')
        return
    }
    printWarrantyVisible.value = false
    let res = printWarranty(scanCaseInfo, warrantyProductId.value, warrantyTemplate, hiprint, productDetails.value, factorySetting)
    if (isPrintNow) {
        printSingle(warrantyTemplate, res, hiprint)
    } else {
        printResList.value.push(res)
    }
}
// 打印自定义标签
const {
    printCaseLabel,
    printCaseProductLabel
} = usePrintCaseLabel()
const handlePrintCaseLabel = (id, isPrintNow) => {
    let labelTemplate = casePrintTemplateList.value.find(x => x.id === id)
    if (!labelTemplate) {
        ElMessage.error('打印模板不存在')
        return
    }
    if (labelTemplate.mainItem === 1) {
        let res = printCaseLabel(scanCaseInfo, factorySetting, storeHospitalDetail, productDetails, currentWorker.value.name, labelTemplate, hiprint)
        if (isPrintNow) {
            printSingle(labelTemplate, res, hiprint)
        } else {
            printResList.value.push(res)
        }
    } else {
        if (isPrintNow) {
            // 立刻打印弹窗产品标签
            let res = printCaseProductLabel(scanCaseInfo, warrantyProductId.value, productDetails, storeHospitalDetail,
                factorySetting, currentWorker.value.name, labelTemplate, hiprint)
            printSingle(labelTemplate, res, hiprint)
        } else {
            for (let caseProduct of scanCaseInfo.productList) {
                let res = printCaseProductLabel(scanCaseInfo, caseProduct.productId, productDetails, storeHospitalDetail,
                    factorySetting, currentWorker.value.name, labelTemplate, hiprint)
                printResList.value.push(res)
            }
        }
    }
}

// 需要打印的数据
const printNow = async () => {
    if (!hiprint.hiwebSocket.opened) {
        ElMessage.error('未连接到打印服务，请检查本机打印服务是否启动并刷新页面')
        return
    }
    await printNoScanList(hiprint)
}
// 获取订单详情
const caseVisible = ref(false)
const caseId = ref()
const showCaseInfo = row => {
    caseId.value = row.id
    caseVisible.value = true
}
const setCaseInfo = async val => {
    if (!!val) {
        Object.assign(scanCaseInfo, val)
        storeHospitalDetail = storeHospitalDetails.value.find(x => x.id === scanCaseInfo.facHospitalId)
        setDefaultWarrantyProduct()
    }
}
// 自动拍照
import {useScanDevice} from "@/use/system/useLiangtian";
import {useUploadPicCompress, picPathPrefix} from "@/use/system/useFile";
import {useAddCaseFile} from "@/use/case/useCase";
import {useGetHospitalProductPriceList} from "@/use/customer/useHospitalPrice";

const {
    initLiangtianSetting,
    device,
    open_view1,
    getDeviceInfo,
    view1_scan,
    rotate,
} = useScanDevice()
const {
    addCaseFile,
    addCaseFileLoading,
    addCaseFileParam
} = useAddCaseFile()
const {
    uploadFileCompressList,
    uploadFileCompress,
    uploadCompressLoading,
    uploadCompressData,
    uploadCompressResult
} = useUploadPicCompress('casePic')
const takePhoto = async () => {
    if (!scanCaseInfo.id) {
        ElMessage.error('请先扫描订单号获取订单信息')
        return
    }
    if (device.videoStatus === '未连接') {
        ElMessage.error('请先连接高拍仪并刷新设备状态')
        return
    }
    let file = await view1_scan()
    if (!file) return
    await uploadFileCompress({file: file})
    addCaseFileParam.casePicType = '订单发货照片'
    addCaseFileParam.facCaseId = scanCaseInfo.id
    addCaseFileParam.fileUse = fileUseEnum.订单图片
    addCaseFileParam.fileId = uploadCompressData.picId
    addCaseFileParam.miniFileId = uploadCompressData.miniPicId
    await addCaseFile()
}

</script>

<style scoped>
#view1, .scan-view {
    height: 225px;
    width: 300px;
    border: 1px solid #edf1f2;
}
</style>