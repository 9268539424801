import http from "@/util/axios";
// 产品种类
export const productTypeSave = data => http.factoryPost('productType/save', data)
export const productTypeDelete = data => http.factoryPost('productType/delete', data)
export const productTypeGetList = data => http.factoryGet('productType/get_list', data)
export const productTypeSort = data => http.factoryPost('productType/sort', data)
// 出厂质检模板
export const productTestTemplateSave = data => http.factoryPost('productTestTemplate/save', data)
export const productTestTemplateDelete = data => http.factoryPost('productTestTemplate/delete', data)
export const productTestTemplateGetList = data => http.factoryGet('productTestTemplate/get_list', data)
export const productTestTemplateGetAllList = data => http.factoryGet('productTestTemplate/get_all_list', data)
export const productTestTemplateAddList = data => http.factoryPost('productTestTemplate/add_list', data)
export const productTestTemplateBatchSave = data => http.factoryPost('productTestTemplate/batch_save', data)
export const productTestTemplateCopy = data => http.factoryPost('productTestTemplate/copy', data)
export const productTestTemplateBatchCopy = data => http.factoryPost('productTestTemplate/batch_copy', data)
// 过程质检模板
export const stepTestTemplateSave = data => http.factoryPost('stepTestTemplate/save', data)
export const stepTestTemplateDelete = data => http.factoryPost('stepTestTemplate/delete', data)
export const stepTestTemplateCopy = data => http.factoryPost('stepTestTemplate/copy', data)
export const stepTestTemplateBatchCopy = data => http.factoryPost('stepTestTemplate/batch_copy', data)
export const stepTestTemplateBatchAdd = data => http.factoryPost('stepTestTemplate/batch_add', data)
export const stepTestTemplateGetList = data => http.factoryGet('stepTestTemplate/get_list', data)
export const stepTestTemplateBatchSave = data => http.factoryPost('stepTestTemplate/batch_save', data)
// 产品
export const productSave = data => http.factoryPost('product/save', data)
export const productDelete = data => http.factoryPost('product/delete', data)
export const productGetSimpleList = data => http.factoryGet('product/get_simple_list', data)
export const productGetList = data => http.factoryGet('product/get_list', data)
export const productGet = data => http.factoryGet('product/get', data)
export const productGetPage = data => http.factoryGet('product/get_page', data)
export const productUpdateValid = data => http.factoryPost('product/update_valid', data)
export const productBatchUpdateVisible = data => http.factoryPost('product/batch_update_visible', data)
export const productBatchUpdateNeedHide = data => http.factoryPost('product/batch_update_need_hide', data)
export const productGetHiddenList = data => http.factoryGet('product/get_hidden_list', data)
export const productGetNeedHideList = data => http.factoryGet('product/get_need_hide_list', data)
export const productMaterialSave = data => http.factoryPost('product/save_material', data)
export const productMaterialAdd = data => http.factoryPost('product/add_material', data)
export const productMaterialDelete = data => http.factoryPost('product/delete_material', data)
export const productMaterialGetList = data => http.factoryGet('product/get_material', data)
export const productBatchInvalid = data => http.factoryPost('product/batch_invalid', data)
export const productExportExcel = '/api/factory/productExport/excel_product'
// 产品采购价格
export const productSupplySave = data => http.factoryPost('product/save_product_supply', data)
export const productSupplyDelete = data => http.factoryPost('product/delete_product_supply', data)
export const productSupplyGetList = data => http.factoryGet('product/get_supply_list', data)
export const productSupplyGetAll = data => http.factoryGet('product/get_supply_all', data)
// 牙色设置
export const toothColorSave = data => http.factoryPost('toothColor/save', data)
export const toothColorDelete = data => http.factoryPost('toothColor/delete', data)
export const toothColorGetList = data => http.factoryGet('toothColor/get_list', data)
// 产品工序
export const productStepSaveList = data => http.factoryPost('productStep/save_list', data)
export const productStepGetList = data => http.factoryGet('productStep/get_list', data)
// 注册产品规格
export const productStandardSave = data => http.factoryPost('productStandard/save', data)
export const productStandardGetList = data => http.factoryGet('productStandard/get_list', data)
export const productStandardDelete = data => http.factoryPost('productStandard/delete', data)
//产品首营
export const productRegisterSave = data => http.factoryPost('productRegister/save', data)
export const productRegisterGetList = data => http.factoryGet('productRegister/get_page', data)
export const productRegisterRelease = data => http.factoryPost('productRegister/release', data)
export const thisProductRegisterGetList = data => http.factoryGet('productRegister/get_list', data)
// 外返原因
export const productRedoReasonSave = data => http.factoryPost('productRedoReason/save', data)
export const productRedoReasonGetList = data => http.factoryGet('productRedoReason/get_list', data)
export const productRedoReasonDelete = data => http.factoryPost('productRedoReason/delete', data)
export const productRedoReasonSort = data => http.factoryPost('productRedoReason/sort', data)