<template>
    <div style="width:600px;" v-show="!!auditContent.id">
        <el-divider style="margin-top: 20px;margin-bottom: 5px;">审核流程</el-divider>
        <el-space style="margin-left: 40px;">
            <el-tag>{{ innerFormInfo }}</el-tag>
            <h3 class="text-success"
                v-if="auditContent.id && (auditContent.auditState===auditStateEnum.通过 || auditContent.auditState===auditStateEnum.无需审核)">
                审核通过</h3>
            <h3 class="text-danger"
                v-if="auditContent.id && auditContent.auditState===auditStateEnum.不通过">审核不通过</h3>
            <h3 class="text-primary"
                v-if="auditContent.id && auditContent.auditState===auditStateEnum.待审核">待审核</h3>
            <h3 class="text-warning"
                v-if="auditContent.id && auditContent.auditState===auditStateEnum.审核中">审核中</h3>
            <el-button v-if="auditContent.id && auditContent.auditState===auditStateEnum.不通过" type="primary" plain
                       @click="handleApply" :loading="applyFormLoading">重新申请
            </el-button>
        </el-space>
        <el-timeline v-show="!!auditContent.id" v-loading="getAuditContentLoading">
            <el-timeline-item v-for="item in auditContent.nodeList" :timestamp="item.auditTime"
                              placement="top">
                <el-card style="width:400px;">
                    <template #header>
                        <span class="text-primary" v-if="item.auditState===auditStateEnum.待审核">待审核</span>
                        <span class="text-success"
                              v-if="item.auditState===auditStateEnum.通过 || item.auditState===auditStateEnum.无需审核">审核通过</span>
                        <span class="text-danger"
                              v-if="item.auditState===auditStateEnum.不通过">审核不通过</span>
                    </template>
                    <el-descriptions :column="1" border>
                        <el-descriptions-item label="审核人" v-if="!!item.auditWorkerId">
                            {{ getWorkerName(item.auditWorkerId) }}
                        </el-descriptions-item>
                        <el-descriptions-item label="备注" v-if="!!item.auditWorkerId">
                            {{ item.remark }}
                        </el-descriptions-item>
                    </el-descriptions>
                    <div v-show="allowWorkerAudit(item)" class="flex-col">
                        <el-input v-model.trim="formToAudit.remark"
                                  placeholder="请输入审核备注"
                                  maxlength="200"/>
                        <el-button-group>
                            <el-button type="primary" @click="handleAudit(auditStateEnum.通过)" plain style="width: 50%"
                                       :loading="auditLoading">审核通过
                            </el-button>
                            <el-button type="danger" @click="handleAudit(auditStateEnum.不通过)" plain style="width: 50%"
                                       :loading="auditLoading">审核不通过
                            </el-button>
                        </el-button-group>
                    </div>
                    <div v-show="allowCancelAudit(item)" class="flex-col">
                        <el-button type="warning" plain @click="handleCancelAudit(item)" :loading="cancelAuditLoading">
                            弃审
                        </el-button>
                    </div>
                </el-card>

            </el-timeline-item>
        </el-timeline>
        <el-empty description="没有审核记录" v-show="!auditContent.id" v-loading="getAuditContentLoading"/>
    </div>
</template>

<script setup>
import {ref, computed, onMounted, watch, defineProps, defineEmits} from 'vue';
import {Plus, Search, Refresh, Edit, Delete, Check, View} from '@element-plus/icons-vue';
import {auditStateEnum, factoryFormTypeEnum} from "@/util/enum";

const emits = defineEmits(['saveAudit', 'saveApply'])
import {useStore} from "vuex";

const store = useStore()
const props = defineProps({
    formId: Number,//表单ID
    formType: Number,//表单类型
    formInfo: String//日志需要保存的表单内容：一般是名称或者单据编号
})
const innerFormId = ref(props.formId)
const innerFormType = ref(props.formType)
const innerFormInfo = ref(props.formInfo)
watch(() => props.formId, (n, o) => {
    innerFormId.value = n
    if (innerFormId.value && innerFormType.value) {
        getAuditContent(innerFormId.value, innerFormType.value)
    } else {
        initAuditContent()
    }
})
watch(() => props.formType, (n, o) => {
    innerFormType.value = props.formType
    if (innerFormId.value && innerFormType.value) {
        getAuditContent(innerFormId.value, innerFormType.value)
    } else {
        initAuditContent()
    }
})
watch(() => props.formInfo, (n, o) => {
    innerFormInfo.value = props.formInfo
})

onMounted(() => {
    initAuditContent()
    if (props.formId && props.formType) {
        getAuditContent(props.formId, props.formType)
    }
})

// 审核流
const getWorkerName = id => {
    if (!id) return ''
    if (store.state.workerList && store.state.workerList.length) {
        let worker = store.state.workerList.find(x => x.id === id)
        return worker ? worker.name : ''
    } else {
        return ''
    }
}
import {useAuditForm, useGetAuditContent, useApplyAuditForm, useCancelAudit} from "@/use/system/useAuditForm";

const {
    formToAudit,
    initForm,
    auditLoading,
    auditResult,
    auditForm,
} = useAuditForm()
const {
    initAuditContent,
    getAuditContent,
    getAuditContentLoading,
    auditContent
} = useGetAuditContent()
const {
    applyForm,
    applyFormResult,
    applyFormLoading,
    applyFormParam
} = useApplyAuditForm()
const {
    cancelAudit,
    cancelAuditResult,
    cancelAuditLoading,
    cancelAuditParam
} = useCancelAudit()
const currentWorker = computed(() => store.state.currentWorker)
// 允许进行审核通过/不通过
const allowWorkerAudit = node => {
    if (auditContent.orderId !== node.orderId) return false
    if (node.auditState !== auditStateEnum.待审核) return false
    let currentWorkerId = currentWorker.value.id
    if (!currentWorkerId) return false
    return currentWorkerId === node.defaultWorkerId ||
        (node.auditWorkerIds && `,${node.auditWorkerIds},`.indexOf(`,${currentWorkerId},`) >= 0)
}
// 允许弃审
const allowCancelAudit = node => {
    let currentWorkerId = currentWorker.value.id
    // 当前节点审核通过
    // 当前节点是最近操作的节点
    // 当前节点审核人是当前登录用户
    // 入库单和出库单最终审核通过后不能弃审（已影响库存） 移库单
    //     && !(auditContent.auditState === auditStateEnum.通过 && (innerFormType.value === factoryFormTypeEnum.入库单
    //         || innerFormType.value === factoryFormTypeEnum.出库单 || innerFormType.value === factoryFormTypeEnum.移库单))
    return node.auditState === auditStateEnum.通过
        && ((auditContent.auditState === auditStateEnum.通过 && node.orderId === auditContent.orderId) || (auditContent.auditState === auditStateEnum.审核中 && node.orderId === (auditContent.orderId - 1)))
        && currentWorkerId === node.auditWorkerId
}
const handleAudit = async auditState => {
    formToAudit.formId = innerFormId.value
    formToAudit.formType = innerFormType.value
    formToAudit.auditState = auditState
    formToAudit.auditWorkerId = currentWorker.value.id
    await auditForm()
}
const handleApply = async () => {
    applyFormParam.formId = innerFormId.value
    applyFormParam.formType = innerFormType.value
    applyFormParam.formInfo = innerFormInfo.value
    await applyForm()
}
watch(auditResult, (n, o) => {
    if (!!n) {
        formToAudit.remark = ''
        getAuditContent(innerFormId.value, innerFormType.value)
        emits('saveAudit', n)
    }
})
watch(applyFormResult, (n, o) => {
    if (n !== false) {
        formToAudit.remark = ''
        auditContent.auditState = auditStateEnum.待审核
        getAuditContent(innerFormId.value, innerFormType.value)
        emits('saveApply', n)
    }
})
// 弃审
const handleCancelAudit = async item => {
    cancelAuditParam.formId = innerFormId.value
    cancelAuditParam.formType = innerFormType.value
    cancelAuditParam.formInfo = innerFormInfo.value
    cancelAuditParam.auditWorkerId = currentWorker.value.id
    cancelAuditParam.nodeId = item.id
    await cancelAudit()
    if (cancelAuditResult.value || cancelAuditResult.value === auditStateEnum.待审核) {
        auditContent.auditState = cancelAuditResult.value
        getAuditContent(innerFormId.value, innerFormType.value)
        emits('saveAudit', cancelAuditResult.value)
    }
}
</script>

<style scoped>

</style>