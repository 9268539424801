import moment from 'moment'
import {ref} from "vue";
import {nullProductNo} from "@/util/constant";
// 本文件用来存放各种格式化数据的方法

// 用来格式化el-table中的日期时间格式
function datetimeFormat(row, col, val, index) {
    if (val) {
        return moment(val).format('yyyy-MM-DD HH:mm:ss')
    }
    return val
}

function minuteFormat(row, col, val, index) {
    if (val) {
        return moment(val).format('yyyy-MM-DD HH:mm')
    }
    return val
}

function txtDatetimeFormat(val) {
    if (val) {
        return moment(val).format('yyyy-MM-DD HH:mm:ss')
    }
    return val
}

// 用来格式化el-table中的日期格式
function dateFormat(row, col, val, index) {
    if (val) {
        return moment(val).format('yyyy-MM-DD')
    }
    return val
}

/**
 * table列yyyy/MM/DD
 * @param row
 * @param col
 * @param val
 * @param index
 * @returns {*}
 */
function shortDateFormat(row, col, val, index) {
    if (val) {
        return moment(val).format('yyyy/MM/DD')
    }
    return val
}

/**
 * yyyy-MM-DD
 * @param val
 * @returns {string|*}
 */
function txtDateFormat(val) {
    if (val) {
        return moment(val).format('yyyy-MM-DD')
    }
    return ''
}

/**
 * table列两位小数
 * @param row
 * @param col
 * @param val
 * @param index
 * @returns {number|*}
 */
function moneyFormat(row, col, val, index) {
    if (val) {
        return Math.round(val * 100) / 100
    }
    return val
}

/**
 * 两位小数
 * @param val
 * @returns {number|*}
 */
function txtMoneyFormat(val) {
    if (val) {
        return Math.round(val * 100) / 100
    }
    return val
}

function fileSizeFormat(val) {
    if (val) {
        if (val > 1024 * 1024) {
            return Math.round(100 * val / 1024 / 1024) / 100 + 'M'
        } else {
            return Math.round(100 * val / 1024) / 100 + 'K'
        }
    }
    return val
}

// 用来格式化el-table中的是/否
function yesNoFormat(row, col, val, index) {
    if (val === 1) {
        return "是"
    } else if (val === 0) {
        return "否"
    }
    return ''
}

// 格式化表格的枚举值
// 使用方法示例    :formatter="(r,c,v)=>enumTypeFormatter(v,trainType)"
function enumTypeFormatter(val, enumList) {
    let result = enumList.find(x => x.id === val)
    if (result) {
        return result.name
    } else {
        return ''
    }
}

// 格式化dateRange的时间
const dateRangeDefaultTime = ref([
    new Date(2000, 1, 1, 0, 0, 0),
    new Date(2000, 2, 1, 23, 59, 59),
])

// CommonListRes的下拉列表（中文、编号、拼音首字母）筛选方法
const commonListFilter = val => {
    return x => {
        return (x.name && x.name.includes(val)) || (x.code && x.code.includes(val)) || (x.pinyin && x.pinyin.includes(val.toUpperCase()))
    }
}
const productFilter = val => {
    return x => {
        return x.code !== nullProductNo && (x.name && x.name.includes(val)) || (x.code && x.code.includes(val)) || (x.pinyin && x.pinyin.includes(val.toUpperCase()))
    }
}
const hospitalFilter = val => {
    return x => {
        return x.code !== nullProductNo && (x.name && x.name.includes(val)) || (x.code && x.code.includes(val)) || (x.pinyin && x.pinyin.includes(val.toUpperCase()))
    }
}
// CommonListRes的下拉列表（中文、编号、拼音首字母）默认显示数量
const commonListDefaultFilter = (x, index) => {
    //员工下拉列表最多显示20个
    return index < 20
}
const productDefaultFilter = (x, index) => {
    //员工下拉列表最多显示20个
    return x.code !== nullProductNo && index < 20
}
const hospitalDefaultFilter = (x, index) => {
    //客户下拉列表最多显示20个
    return index < 20
}
// 列表项中如果作废的 显示为红色背景（isValid字段判断）
const tableRowClass = ({row, index}) => {
    if (!row.isValid) {
        return 'danger-row'
    } else {
        return ''
    }
}

const sortOrderId = (a, b) => {
    return a.orderId - b.orderId
}

const isNumber = val => !isNaN(parseFloat(val)) && isFinite(val)


/**
 * 格式化金额
 * @param number
 * @param options
 */
function formatMoney(number, options = null) {
    let places = options ? options.places : undefined
    let symbol = options ? options.symbol : undefined
    let unit = options ? options.unit : undefined
    let thousand = options ? options.thousand : undefined
    let decimal = options ? options.decimal : undefined
    let result = ''
    number = number || 0;
    //places保留的小位数 否则默认保留两位
    places = !isNaN(places = Math.abs(places)) ? places : 2;
    //symbol表示前面表示的标志是￥
    symbol = symbol ? symbol : "";
    //unit 后面显示单位 元
    unit = unit ? unit : "";
    //thousand表示每几位用,隔开,是货币标识
    thousand = thousand ? thousand : ",";
    //decimal表示小数点
    decimal = decimal || ".";
    //negative表示如果钱是负数有就显示“-”如果不是负数 就不显示负号
    //i表示处理过的纯数字
    var negative = number < 0 ? "-" : ""
    var i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + ""
    var j = (j = i.length) > 3 ? j % 3 : 0
    result = symbol + negative + (j ? i.substr(0, j) + thousand : "") +
        i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (places ? decimal +
            Math.abs(number - i).toFixed(places).slice(2) : "") + unit;
    return result
}

/**
 * 获取百分比
 * @param num 分子
 * @param totalNum 分母
 * @returns {string}
 */
const txtPercent = (num, totalNum) => {
    if (!totalNum || totalNum < 0) return ''
    if (!num) return '0'
    return (num / totalNum * 100).toFixed(2)
}

export {
    datetimeFormat,
    minuteFormat,
    txtDatetimeFormat,
    shortDateFormat,
    dateFormat,
    txtDateFormat,
    moneyFormat,
    txtMoneyFormat,
    yesNoFormat,
    dateRangeDefaultTime,
    enumTypeFormatter,
    productFilter,
    hospitalFilter,
    commonListFilter,
    productDefaultFilter,
    commonListDefaultFilter,
    hospitalDefaultFilter,
    tableRowClass,
    sortOrderId,
    isNumber,
    fileSizeFormat,
    txtPercent,
    formatMoney,
}