<template>
    <div class="flex-column tooth-container">
        <div class="flex-row border-bottom-dash">
            <div class="border-right-dash tooth-part tooth-left">
                <el-checkbox-group v-model="pos1Arr" size="small">
                    <el-checkbox-button v-for="item in decreaseArr" :value="item" @change="changePos(1)" class="tooth"
                                        :label="item" :key="item"></el-checkbox-button>
                </el-checkbox-group>
            </div>
            <div class="tooth-part tooth-right">
                <el-checkbox-group v-model="pos2Arr" size="small">
                    <el-checkbox-button v-for="item in decreaseArr" :value="item" @change="changePos(2)" class="tooth"
                                        :label="item" :key="item"></el-checkbox-button>
                </el-checkbox-group>
            </div>
        </div>
        <div class="flex-row">
            <div class="border-right-dash tooth-part tooth-left">
                <el-checkbox-group v-model="pos4Arr" size="small">
                    <el-checkbox-button v-for="item in decreaseArr" :value="item" @change="changePos(4)" class="tooth"
                                        :label="item" :key="item"></el-checkbox-button>
                </el-checkbox-group>
            </div>
            <div class="tooth-part tooth-right">
                <el-checkbox-group v-model="pos3Arr" size="small">
                    <el-checkbox-button v-for="item in decreaseArr" :value="item" @change="changePos(3)" class="tooth"
                                        :label="item" :key="item"></el-checkbox-button>
                </el-checkbox-group>
            </div>
        </div>
    </div>
</template>
<!--迷你牙位输入:只包含十字牙位-->
<!--<table-tooth-mini-->
<!--    :key="scope.$index"-->
<!--    v-model:pos1="scope.row.pos1"-->
<!--    v-model:pos2="scope.row.pos2"-->
<!--    v-model:pos3="scope.row.pos3"-->
<!--    v-model:pos4="scope.row.pos4"-->
<!--    v-model:upper-jaw="scope.row.upperJaw"-->
<!--    v-model:lower-jaw="scope.row.lowerJaw"-->
<!--    v-model:complete="scope.row.complete"-->
<!--    @change-tooth="val=>setToothNum(val,scope.row)"-->
<!--/>-->
<script setup>
import {ref, reactive, inject, watch, toRef, defineProps, defineEmits, computed, onMounted} from 'vue'

const props = defineProps({
    pos1: String,//左上
    pos2: String,//右上
    pos3: String,//右下
    pos4: String,//左下
    upperJaw: Number,
    lowerJaw: Number,
    complete: Number
})
const emits = defineEmits(['update:pos1', 'update:pos2', 'update:pos3', 'update:pos4', 'update:upperJaw', 'update:lowerJaw', 'update:complete', 'changeTooth'])

const increaseArr = ref(['1', '2', '3', '4', '5', '6', '7', '8'])
const decreaseArr = ref(['8', '7', '6', '5', '4', '3', '2', '1'])

const pos1Arr = ref([])
const pos2Arr = ref([])
const pos3Arr = ref([])
const pos4Arr = ref([])
watch(() => props.pos1, (newVal, oldVal) => {
    pos1Arr.value = !!newVal ? newVal.split('') : []
})
watch(() => props.pos2, (newVal, oldVal) => {
    pos2Arr.value = !!newVal ? newVal.split('') : []
})
watch(() => props.pos3, (newVal, oldVal) => {
    pos3Arr.value = !!newVal ? newVal.split('') : []
})
watch(() => props.pos4, (newVal, oldVal) => {
    pos4Arr.value = !!newVal ? newVal.split('') : []
})
const innerUpperJaw = ref(0)
watch(() => props.upperJaw, (newVal, oldVal) => {
    innerUpperJaw.value = newVal
})
const innerLowerJaw = ref(0)
watch(() => props.lowerJaw, (newVal, oldVal) => {
    innerLowerJaw.value = newVal
})
const innerComplete = ref(0)
watch(() => props.complete, (newVal, oldVal) => {
    innerComplete.value = newVal
})

onMounted(() => {
    innerUpperJaw.value = props.upperJaw
    innerLowerJaw.value = props.lowerJaw
    innerComplete.value = props.complete
    pos1Arr.value = props.pos1 ? props.pos1.split('') : []
    pos2Arr.value = props.pos2 ? props.pos2.split('') : []
    pos3Arr.value = props.pos3 ? props.pos3.split('') : []
    pos4Arr.value = props.pos4 ? props.pos4.split('') : []
})

const changePos = (posPart) => {
    if (posPart === 1) {
        emits('update:pos1', pos1Arr.value.sort().join(''))
    } else if (posPart === 2) {
        emits('update:pos2', pos2Arr.value.sort().join(''))
    } else if (posPart === 3) {
        emits('update:pos3', pos3Arr.value.sort().join(''))
    } else if (posPart === 4) {
        emits('update:pos4', pos4Arr.value.sort().join(''))
    }
    changeToothNo()
}

const changeToothNo = () => {
    let toothNum = 0
    if (innerComplete.value) {
        toothNum = 1
    } else if (innerUpperJaw.value || innerLowerJaw.value) {
        toothNum = innerUpperJaw.value + innerLowerJaw.value
    } else {
        toothNum = pos1Arr.value.length + pos2Arr.value.length + pos3Arr.value.length + pos4Arr.value.length
    }
    emits('changeTooth', toothNum)
}


</script>

<style scoped>
.tooth-container {
    width: 420px;
}

.border-bottom-dash {
    border-bottom: 1px dashed #777;
}

.border-right-dash {
    border-right: 1px dashed #777;
}

</style>