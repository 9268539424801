// 获取当前操作系统类型
export const getSystemType = () => {
    let agent = navigator.userAgent.toLowerCase();
    if (/macintosh|mac os x/i.test(navigator.userAgent)) {
        return 'mac'
    }
    if (agent.indexOf("win32") >= 0 || agent.indexOf("wow32") >= 0) {
        return 'win32'
    } else if (agent.indexOf("win64") >= 0 || agent.indexOf("wow64") >= 0) {
        return 'win64'
    }
}

const _charStr = 'abacdefghjklmnopqrstuvwxyzABCDEFGHJKLMNOPQRSTUVWXYZ0123456789';

/**
 * 随机生成索引
 * @param min 最小值
 * @param max 最大值
 * @param i 当前获取位置
 */
function RandomIndex(min, max, i) {
    let index = Math.floor(Math.random() * (max - min + 1) + min),
        numStart = _charStr.length - 10;
    //如果字符串第一位是数字，则递归重新获取
    if (i === 0 && index >= numStart) {
        index = RandomIndex(min, max, i);
    }
    //返回最终索引值
    return index;
}

/**
 * 随机生成字符串
 * @param len 指定生成字符串长度
 */
export function getRandomString(len) {
    let min = 0, max = _charStr.length - 1, _str = '';
    //判断是否指定长度，否则默认长度为15
    len = len || 15;
    //循环生成字符串
    for (var i = 0, index; i < len; i++) {
        index = RandomIndex(min, max, i);
        _str += _charStr[index];
    }
    return _str;
}

/**
 * 判断当前是否是云环境
 */
export function checkIsCloud() {
    return window.location.host.includes('factory.cunzhi.net')
    // return true
}

/**
 * 检查功能权限
 * @param funcNameEn
 * @returns {boolean|*}
 */
export function hasAuth(funcNameEn) {
    let funcAuth = sessionStorage.getItem('funcAuth')
    if (!funcAuth) return false
    let authority = JSON.parse(funcAuth)
    if (!authority || !authority.length) {
        return false
    }
    return authority.some(x => x.nameEn === funcNameEn)
}

/**
 * 防抖（执行最后一次）
 * @param fn
 * @param delay
 * @returns {(function(): void)|*}
 */
export function debounce(fn, delay) {
    let timer = null
    return function () {
        if (timer) {
            clearTimeout(timer)
        }
        timer = setTimeout(() => {
            //模拟触发change事件
            fn.apply(this, arguments)
            // 清空计时器
            timer = null
        }, delay);
    }
}

/**
 * 节流（执行第一次）
 * @param fn
 * @param delay
 * @returns {(function(): void)|*}
 */
export function throttle(fn, delay = 100) {
    let timer = null
    return function () {
        if (timer) {
            return
        }
        timer = setTimeout(() => {
            fn.apply(this, arguments)
            timer = null
        }, delay);
    }
}

/**
 * 金额转大写
 * @param {金额} money
 * @returns
 */
export function moneyToChinese(money) {
    //汉字的数字
    var cnNums = new Array('零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖');
    //基本单位
    var cnIntRadice = new Array('', '拾', '佰', '仟');
    //对应整数部分扩展单位
    var cnIntUnits = new Array('', '万', '亿', '兆');
    //对应小数部分单位
    var cnDecUnits = new Array('角', '分', '毫', '厘');
    //整数金额时后面跟的字符
    var cnInteger = '整';
    //整型完以后的单位
    var cnIntLast = '元';
    //最大处理的数字
    var maxNum = 999999999999999.9999;
    //金额整数部分
    var integerNum;
    //金额小数部分
    var decimalNum;
    //输出的中文金额字符串
    var chineseStr = '';
    //分离金额后用的数组，预定义
    var parts;
    if (money === '') { //不能用==
        return '';
    }
    money = parseFloat(money);
    if (money >= maxNum) {
        //超出最大处理数字
        return '';
    }
    if (money == 0) {
        chineseStr = cnNums[0] + cnIntLast + cnInteger;
        return chineseStr;
    }
    //转换为字符串
    money = money.toString();
    if (money.indexOf('.') == -1) {
        integerNum = money;
        decimalNum = '';
    } else {
        parts = money.split('.');
        integerNum = parts[0];
        decimalNum = parts[1].substr(0, 4);
    }
    //获取整型部分转换
    if (parseInt(integerNum, 10) > 0) {
        var zeroCount = 0;
        var IntLen = integerNum.length;
        for (var i = 0; i < IntLen; i++) {
            var n = integerNum.substr(i, 1);
            var p = IntLen - i - 1;
            var q = p / 4;
            var m = p % 4;
            if (n == '0') {
                zeroCount++;
            } else {
                if (zeroCount > 0) {
                    chineseStr += cnNums[0];
                }
                //归零
                zeroCount = 0;
                chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
            }
            if (m == 0 && zeroCount < 4) {
                chineseStr += cnIntUnits[q];
            }
        }
        chineseStr += cnIntLast;
    }
    //小数部分
    if (decimalNum != '') {
        var decLen = decimalNum.length;
        for (var i = 0; i < decLen; i++) {
            var n = decimalNum.substr(i, 1);
            if (n != '0') {
                chineseStr += cnNums[Number(n)] + cnDecUnits[i];
            }
        }
    }
    if (chineseStr == '') {
        chineseStr += cnNums[0] + cnIntLast + cnInteger;
    } else if (decimalNum == '') {
        chineseStr += cnInteger;
    }
    return chineseStr;
}

/**
 * 去重后用逗号连接
 * @param paramArr
 * @returns {string}
 * @constructor
 */
function GetDistinctStr(paramArr) {
    return Array.from(new Set(paramArr)).join(',')
}