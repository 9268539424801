<template>
    <div>
        <el-table :data="caseHistoryList" v-loading="getCaseHistoryListLoading" border
                  style="width: 800px;">
            <el-table-column label="操作员工" prop="workerName" width="100"></el-table-column>
            <el-table-column label="操作时间" prop="operTime" width="200"
                             :formatter="datetimeFormat"></el-table-column>
            <el-table-column label="操作类型" prop="operTypeName" width="100"></el-table-column>
            <el-table-column label="操作内容" prop="operContent" width="400">
                <template #default="scope">
                    <p v-html="scope.row.operContent"></p>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script setup>
import {ref, defineProps, onMounted, watch} from 'vue'
import {datetimeFormat} from "@/util/formatter";
import {useGetHistoryListCase} from "@/use/case/useCase";

const props = defineProps({
    id: Number,//订单ID
})
// 订单操作日志
const {
    caseHistoryList, getCaseHistoryList, getCaseHistoryListLoading
} = useGetHistoryListCase()
watch(() => props.id, (n, o) => {
    if (!!n) {
        getCaseHistoryList(n)
    } else {
        caseHistoryList.value = []
    }
})
onMounted(() => {
    if (props.id) {
        getCaseHistoryList(props.id)
    }
})

</script>