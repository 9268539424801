import {reactive, ref, inject, toRaw, computed} from 'vue'
import {
    caseOutScan,
    caseOutGetTodayList,
    casePackScan,
    casePackCancel,
    casePackGetList,
    casePackOut,
    caseOutCancel,
    casePackGetPage,
    casePackDetailListGet,
    casePackCreateSf,
    casePackPrintSf,
    casePackGetSf,
    packCancelOut,
    packCancelSf,
    casePackSaveSf
} from "@/api/case";
import {receiverTypeEnum} from "@/util/enum";

// 取消发货
function useCancelCaseOut() {
    const cancelCaseOutLoading = ref(false)
    const cancelCaseOutResult = ref(false)
    const cancelCaseOut = async id => {
        cancelCaseOutResult.value = false
        if (cancelCaseOutLoading.value) return
        cancelCaseOutLoading.value = true
        cancelCaseOutResult.value = await caseOutCancel({id: id})
        cancelCaseOutLoading.value = false
    }
    return {
        cancelCaseOutLoading,
        cancelCaseOutResult,
        cancelCaseOut,
    }
}

/**
 * 装箱单取消发货
 * @returns {{cancelPackOutLoading: *, cancelPackOut: ((function(*): Promise<void>)|*), cancelPackOutResult: *}}
 */
function useCancelPackOut() {
    const cancelPackOutLoading = ref(false)
    const cancelPackOutResult = ref(false)
    const cancelPackOut = async id => {
        cancelPackOutResult.value = false
        if (cancelPackOutLoading.value) return
        cancelPackOutLoading.value = true
        cancelPackOutResult.value = await packCancelOut({id: id})
        cancelPackOutLoading.value = false
    }
    return {
        cancelPackOutLoading,
        cancelPackOutResult,
        cancelPackOut,
    }
}

/**
 * 装箱单取消顺丰
 * @returns {{cancelPackSfResult: *, cancelPackSf: ((function(*): Promise<void>)|*), cancelPackSfLoading: *}}
 */
function useCancelPackSf() {
    const cancelPackSfLoading = ref(false)
    const cancelPackSfResult = ref(false)
    const cancelPackSf = async id => {
        cancelPackSfResult.value = false
        if (cancelPackSfLoading.value) return
        cancelPackSfLoading.value = true
        cancelPackSfResult.value = await packCancelSf({id: id})
        cancelPackSfLoading.value = false
    }
    return {
        cancelPackSfLoading,
        cancelPackSfResult,
        cancelPackSf,
    }
}

/**
 * 取消装箱
 * @returns {{cancelCasePackLoading: *, cancelCasePack: ((function(*): Promise<void>)|*), cancelCasePackResult: *}}
 */
function useCancelCasePack() {
    const cancelCasePackLoading = ref(false)
    const cancelCasePackResult = ref(false)
    const cancelCasePack = async id => {
        cancelCasePackResult.value = false
        if (cancelCasePackLoading.value) return
        cancelCasePackLoading.value = true
        cancelCasePackResult.value = await casePackCancel({id: id})
        cancelCasePackLoading.value = false
    }
    return {
        cancelCasePackLoading,
        cancelCasePackResult,
        cancelCasePack,
    }
}

/**
 * 打印顺丰单（获取顺丰单PDF打印URL）
 * @returns {{printCasePackSfLoading: *, printCasePackSf: (function(): Promise<*>), printCasePackSfParam: *}}
 */
function usePrintCasePackSf() {
    const printCasePackSfParam = reactive({
        id: null,
        templateCode: '',
        isPrintLogo: '',
    })
    const printCasePackSfLoading = ref(false)
    const printCasePackSfResult = reactive({
        url: '',
        token: '',
        waybillNo: ''
    })
    const printCasePackSf = async () => {
        if (printCasePackSfLoading.value) return
        printCasePackSfLoading.value = true
        let res = await casePackPrintSf(printCasePackSfParam)
        if (res) {
            Object.assign(printCasePackSfResult, res)
        } else {
            printCasePackSfResult.url = ''
        }
        printCasePackSfLoading.value = false
    }
    return {
        printCasePackSfParam,
        printCasePackSfLoading,
        printCasePackSfResult,
        printCasePackSf
    }
}

/**
 * 装箱发顺丰单
 * @returns {{casePackCreateSfLoading: *, casePackCreateSfResult: *, casePackCreateSf: ((function(): Promise<void>)|*)}}
 */
function useCasePackCreateSf() {
    const casePackCreateSfParam = reactive({
        id: null,
        cargoName: '',
        payMethod: null,
        expressTypeId: null,
        isDocall: 1,
        sendStartTm: '',
        expressNo: '',
    })
    const casePackCreateSfLoading = ref(false)
    const casePackCreateSfResult = ref(false)
    const createPackSfOrder = async () => {
        casePackCreateSfResult.value = false
        if (casePackCreateSfLoading.value) return
        casePackCreateSfLoading.value = true
        casePackCreateSfResult.value = await casePackCreateSf(casePackCreateSfParam)
        casePackCreateSfLoading.value = false
    }
    const savePackSfOrder = async () => {
        casePackCreateSfResult.value = false
        if (casePackCreateSfLoading.value) return
        casePackCreateSfLoading.value = true
        casePackCreateSfResult.value = await casePackSaveSf(casePackCreateSfParam)
        casePackCreateSfLoading.value = false
    }
    return {
        casePackCreateSfParam,
        casePackCreateSfLoading,
        casePackCreateSfResult,
        createPackSfOrder,
        savePackSfOrder
    }
}

// 扫描发货
function useScanCaseOut() {
    const scanCaseOutParam = reactive({
        caseNo: '',
    })
    const scanCaseInfo = reactive({})
    const scanCaseOutLoading = ref(false)
    const scanCaseOut = async () => {
        if (scanCaseOutLoading.value) return
        scanCaseOutLoading.value = true
        let res = await caseOutScan(scanCaseOutParam)
        if (!!res) {
            Object.assign(scanCaseInfo, res)
        } else {
            scanCaseInfo.id = ''
            scanCaseInfo.productList = []
        }
        scanCaseOutLoading.value = false
    }
    return {
        scanCaseOutLoading,
        scanCaseOutParam,
        scanCaseInfo,
        scanCaseOut,
    }
}

// 扫描装箱
function useScanCasePack() {
    const scanCasePackParam = reactive({
        caseNo: '',
    })
    const casePackInfo = reactive({})
    const scanCasePackLoading = ref(false)
    const scanCasePack = async () => {
        if (scanCasePackLoading.value) return
        scanCasePackLoading.value = true
        let res = await casePackScan(scanCasePackParam)
        if (!!res) {
            Object.assign(casePackInfo, res)
        } else {
            casePackInfo.id = ''
        }
        scanCasePackLoading.value = false
    }
    return {
        scanCasePackLoading,
        scanCasePackParam,
        casePackInfo,
        scanCasePack,
    }
}

// 装箱订单批量发货
function usePackCaseOut() {
    const packCaseOutLoading = ref(false)
    const packCaseOut = async param => {
        if (packCaseOutLoading.value) return
        packCaseOutLoading.value = true
        let res = await casePackOut(param)
        packCaseOutLoading.value = false
        return res
    }
    return {
        packCaseOut,
        packCaseOutLoading
    }
}

// 获取今日出货订单列表
function useGetTodayOutCaseList() {
    const todayOutCaseList = ref([])
    const getTodayOutCaseListLoading = ref(false)
    const getTodayOutCaseList = async () => {
        if (getTodayOutCaseListLoading.value) return
        getTodayOutCaseListLoading.value = true
        let res = await caseOutGetTodayList()
        if (res) {
            todayOutCaseList.value = res
        } else {
            todayOutCaseList.value = []
        }
        getTodayOutCaseListLoading.value = false
    }
    return {
        todayOutCaseList,
        getTodayOutCaseListLoading,
        getTodayOutCaseList
    }
}

// 获取已装箱还未出货的装箱单
function useGetCasePackList() {
    const getPackCaseListParam = reactive({
        facHospitalId: null,
        salesmanId: null,
    })
    const packCaseList = ref([])
    const getPackCaseListLoading = ref(false)
    const getPackCaseList = async () => {
        if (getPackCaseListLoading.value) return
        getPackCaseListLoading.value = true
        let res = await casePackGetList(getPackCaseListParam)
        if (res) {
            packCaseList.value = res
        } else {
            packCaseList.value = []
        }
        getPackCaseListLoading.value = false
    }
    const addPackCase = casePack => {
        if (packCaseList.value.length) {
            let listItem = packCaseList.value.find(x => x.id === casePack.id)
            if (listItem) {
                listItem.num = casePack.num
            } else {
                packCaseList.value.push(casePack)
            }
        } else {
            packCaseList.value.push(casePack)
        }
    }
    const getReceiverName = packCaseItem => {
        if (packCaseItem.receiverType === receiverTypeEnum.医院收货) {
            return packCaseItem.facHospitalName
        } else if (packCaseItem.receiverType === receiverTypeEnum.科室收货) {
            return packCaseItem.facHospitalName + packCaseItem.hospitalDeptName
        } else if (packCaseItem.receiverType === receiverTypeEnum.销售) {
            return packCaseItem.facWorkerName
        }
    }
    return {
        getPackCaseListParam,
        addPackCase,
        packCaseList,
        getPackCaseListLoading,
        getPackCaseList
    }
}

/**
 * 装箱单分页
 * @returns {{initGetPageCasePackParam: initGetPageCasePackParam, getPageCasePackResult: *, getPageCasePack: ((function(): Promise<void>)|*), getPageCasePackParam: *, casePackPage: *, getPageCasePackLoading: *}}
 */
function useGetPageCasePack() {
    const getPageCasePackParam = reactive({
        beginDate: '',
        endDate: '',
        expressNo: '',
        receiverName: '',
        sendState: '',
        pageIndex: 1,
        pageSize: 10,
    })
    const getPageCasePackParamEmpty = Object.assign({}, getPageCasePackParam)
    const getPageCasePackResult = reactive({
        totalCount: 0,
        totalPages: 0,
    })
    const casePackPage = ref([])
    const initGetPageCasePackParam = () => {
        Object.assign(getPageCasePackParam, getPageCasePackParamEmpty)
    }
    const getPageCasePackLoading = ref(false)
    const getPageCasePack = async () => {
        if (getPageCasePackLoading.value) return
        getPageCasePackLoading.value = true
        let data = await casePackGetPage(getPageCasePackParam)
        if (data && data.list && data.list.length) {
            casePackPage.value = data.list
            getPageCasePackResult.totalPages = Math.ceil(data.totalCount / getPageCasePackParam.pageSize)
            getPageCasePackResult.totalCount = data.totalCount
        } else {
            casePackPage.value = []
            getPageCasePackResult.totalPages = 0
            getPageCasePackResult.totalCount = 0
        }
        getPageCasePackLoading.value = false
    }
    return {
        getPageCasePackParam,
        getPageCasePackResult,
        casePackPage,
        initGetPageCasePackParam,
        getPageCasePackLoading,
        getPageCasePack
    }
}

/**
 * 获取装箱单关联的订单列表
 * @returns {{getCasePackDetailListParam: UnwrapNestedRefs<{casePackId: number}>, getCasePackDetailListLoading: Ref<UnwrapRef<boolean>>, getStepCaseStatDetailList: ((function(): Promise<void>)|*), casePackDetailList: Ref<UnwrapRef<*[]>>}}
 */
function useGetCasePackDetailList() {
    const casePackDetailList = ref([])
    const getCasePackDetailListParam = reactive({
        casePackId: 0
    })
    const getCasePackDetailListLoading = ref(false)
    const getStepCaseStatDetailList = async () => {
        if (getCasePackDetailListLoading.value) return
        getCasePackDetailListLoading.value = true
        let res = await casePackDetailListGet(getCasePackDetailListParam)
        casePackDetailList.value = res ? res : []
        getCasePackDetailListLoading.value = false
    }
    return {
        casePackDetailList,
        getCasePackDetailListParam,
        getCasePackDetailListLoading,
        getStepCaseStatDetailList
    }
}

/**
 * 装箱单查询顺丰快递
 */
function useGetPackSf() {
    const getPackSfLoading = ref(false)
    const packSf = reactive({
        expressNo: '',
        routeList: []
    })
    const getPackSf = async expressNo => {
        if (getPackSfLoading.value) return
        getPackSfLoading.value = true
        let res = await casePackGetSf({expressNo: expressNo})
        packSf.routeList = (res && res.routeList) ? res.routeList : []
        packSf.routeList.reverse()
        packSf.expressNo = expressNo
        getPackSfLoading.value = false
    }
    return {
        packSf,
        getPackSfLoading,
        getPackSf
    }
}

export {
    useScanCaseOut,
    useScanCasePack,
    useGetCasePackList,
    useGetTodayOutCaseList,
    usePackCaseOut,
    useCancelCaseOut,
    useCasePackCreateSf,
    useGetPageCasePack,
    usePrintCasePackSf,
    useGetCasePackDetailList,
    useCancelCasePack,
    useGetPackSf,
    useCancelPackOut,
    useCancelPackSf
}