<template>
    <div>
        <el-form :model="printCasePackSfParam" label-width="100px" label-position="left"
                 @submit.prevent>
            <el-form-item label="打印顺丰Logo">
                <el-switch v-model="printCasePackSfParam.isPrintLogo" active-value="true"
                           inactive-value=""></el-switch>
            </el-form-item>
            <el-form-item label="顺丰打印模板" class="text-danger">
                <el-select v-model="printCasePackSfParam.templateCode" filterable>
                    <el-option v-for="(item,index) in templateCodeList" :key="index" :value="item.id"
                               :label="item.name">
                    </el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <div class="flex align-center justify-end margin-tb-sm">
            <el-button @click="handlePrintCasePack" :loading="printCasePackSfLoading" type="primary">确认打印
            </el-button>
        </div>
        <el-dialog v-model="pdfVisible" width="600" title="pdf预览和打印" append-to-body>
            <template #header>
                <el-button @click="printPdfNow" type="primary">确认打印</el-button>
            </template>
            <VuePdfEmbed ref="pdfRef" :source="pdfSource"/>
        </el-dialog>
    </div>
</template>

<script setup>
import {defineEmits, onMounted, ref, watch} from "vue";
import {usePrintCasePackSf} from "@/use/case/useCaseOut";
import {ElMessage} from "element-plus";
import {useGetPdfBase64} from "@/use/system/usePdf";
import VuePdfEmbed from 'vue-pdf-embed'

const {
    printCasePackSfParam,
    printCasePackSfResult,
    printCasePackSfLoading,
    printCasePackSf
} = usePrintCasePackSf()
const emits = defineEmits(['success'])
const props = defineProps({
    id: Number,// 装箱单ID
})
watch(() => props.id, (n, o) => {
    printCasePackSfParam.id = n
    pdfSource.value = ''
})
onMounted(() => {
    printCasePackSfParam.id = props.id
})
const handlePrintCasePack = async () => {
    if (!printCasePackSfParam.templateCode) {
        ElMessage.error('请选择打印模板')
        return
    }
    await printCasePackSf()
    if (!printCasePackSfResult.url) {
        ElMessage.error('未获取到打印地址')
        return
    } else {
        pdfVisible.value = true
        getPdfBase64(printCasePackSfResult.url, printCasePackSfResult.waybillNo, showPdf, printCasePackSfResult.token)
    }
}
const templateCodeList = ref([{
    id: 'fm_150_standard_OYCM1IUC35Z',
    name: '丰密150标准模板100*150'
}, {
    id: 'fm_76130_standard_OYCM1IUC35Z',
    name: '丰密76130标准模板76*130'
}])
// 查看和打印pdf
const pdfVisible = ref(false)
// pdf的base64
const pdfSource = ref()
// pdf的ref
const pdfRef = ref()
const {
    getPdfBase64,
    getPdfBase64Loading
} = useGetPdfBase64()
const showPdf = res => {
    pdfSource.value = res
    setTimeout(() => {
        pdfRef.value.print()
        emits('success', true)
    }, 2000)
}
const printPdfNow = () => {
    pdfRef.value.print()
}
</script>

<style scoped>

</style>