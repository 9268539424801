import http from '@/util/axios'

// 用户管理
export const userLogin = data => http.post('sysUser/login', data)
export const userLoginFactory = data => http.post('sysUser/login_factory', data)
export const userResetPassword = data => http.factoryPost('sysUser/reset_password', data)
export const userGetPage = data => http.factoryGet('sysUser/get_page', data)
export const userSave = data => http.factoryPost('sysUser/save', data)
export const userUpdateRole = data => http.factoryPost('sysUser/update_role', data)
export const userUnlock = data => http.factoryPost('sysUser/unlock', data)
export const doctorUserUnlock = data => http.factoryPost('sysUser/unlock_doctor', data)
export const doctorUserGetPage = data => http.factoryGet('sysUser/get_doctor_page', data)
export const doctorUserReset = data => http.factoryPost('sysUser/reset_doctor_password', data)
export const userResetMyPwd = data => http.factoryPost('sysUser/reset_my_password', data)
export const userRegisterCloud = data => http.factoryPost('sysUser/register_cloud_user', data)
// 模块管理
export const moduleSave = data => http.factoryPost('sysModule/save', data)
export const moduleDelete = data => http.factoryPost('sysModule/delete', data)
export const moduleGetList = data => http.factoryGet('sysModule/get_list', data)
export const moduleGetFactoryList = data => http.factoryGet('sysModule/get_factory_list', data)
export const moduleSort = data => http.factoryPost('sysModule/sort', data)
//技工模块管理
export const workerAppModuleGetList = data => http.factoryGet('workerAppModule/get_list', data)
//角色管理
export const roleSave = data => http.factoryPost('sysRole/save', data)
export const roleDelete = data => http.factoryPost('sysRole/delete', data)
export const roleGetList = data => http.factoryGet('sysRole/get_list', data)
export const roleSaveAuth = data => http.factoryPost('sysRole/save_auth', data)
export const roleGetAuth = data => http.factoryGet('sysRole/get_auth', data)
export const roleGetUserList = data => http.factoryGet('sysRole/get_user_list', data)
export const roleGetWorkerAppAuth = data => http.factoryGet('sysRole/get_woker_app_auth', data)
export const roleSaveWorkerAppAuth = data => http.factoryPost('sysRole/save_woker_app_auth', data)
// 数据字典
export const dataTypeGetList = data => http.factoryGet('sysData/get_data_type_list', data)
export const dataSave = data => http.factoryPost('sysData/save_data', data)
export const dataDelete = data => http.factoryPost('sysData/delete_data', data)
export const dataSort = data => http.factoryPost('sysData/sort_data', data)
export const dataGetList = data => http.factoryGet('sysData/get_data_list', data)
export const dataValueGetList = data => http.factoryGet('sysData/get_data_value_list', data)
export const dataValueSaveList = data => http.factoryPost('sysData/save_data_value_list', data)
export const dataValueDelete = data => http.factoryPost('sysData/delete_data_value', data)
export const dataValueSave = data => http.factoryPost('sysData/save_data_value', data)
export const dataValueGetAllList = data => http.factoryGet('sysData/get_all_data_value_list', data)
// 工序
export const stepSave = data => http.factoryPost('step/save', data)
export const stepBatchAdd = data => http.factoryPost('step/batch_add', data)
export const stepSaveAsset = data => http.factoryPost('step/save_asset', data)
export const stepDelete = data => http.factoryPost('step/delete', data)
export const stepGetList = data => http.factoryGet('step/get_list', data)
export const stepGetDeptIds = data => http.factoryGet('step/get_deptIds', data)
export const stepSaveDeptIds = data => http.factoryPost('step/save_deptIds', data)
export const stepSaveDeptSort = data => http.factoryPost('step/save_dept_sort', data)
export const redoReasonSave = data => http.factoryPost('step/save_redo_reason', data)
export const redoReasonSort = data => http.factoryPost('step/sort_redo_reason', data)
export const redoReasonDelete = data => http.factoryPost('step/delete_redo_reason', data)
export const redoReasonGetList = data => http.factoryGet('step/get_redo_reasons', data)
// 工艺路线
export const stepRouteSave = data => http.factoryPost('stepRoute/save', data)
export const stepRouteGetList = data => http.factoryGet('stepRoute/get_list', data)
export const stepRouteDelete = data => http.factoryPost('stepRoute/delete', data)
export const stepRouteSaveProducts = data => http.factoryPost('stepRoute/save_products', data)
// 工序数字化流程
export const stepDigitalSave = data => http.factoryPost('stepDigital/save', data)
export const stepDigitalGetList = data => http.factoryGet('stepDigital/get_list', data)
export const stepDigitalDelete = data => http.factoryPost('stepDigital/delete', data)
// 工厂设置
export const factorySettingSave = data => http.factoryPost('factorySetting/save', data)
export const factorySettingGetStore = data => http.factoryGet('factorySetting/get_store', data)
export const factorySettingGet = data => http.factoryGet('factorySetting/get', data)
export const factorySaveSwitch = data => http.factoryPost('factorySetting/save_switch', data)
// 工厂配置
export const factoryConfigSave = data => http.factoryPost('factoryConfig/save', data)
export const factoryConfigBatchSave = data => http.factoryPost('factoryConfig/batch_save', data)
export const factoryConfigGetList = data => http.factoryGet('factoryConfig/get_list', data)
// 文件
export const fileAdd = data => http.factoryPostBgData('file/add', data)
export const caseFileUpload = (data, header) => http.postFile('file/upload_case_file', data, header)
export const casePicUpload = (data, header) => http.postFile('file/upload_case_pic', data, header)
export const caseColorPicUpload = (data, header) => http.postFile('file/upload_case_color_pic', data, header)
export const productPicUpload = (data, header) => http.postFile('file/upload_product_pic', data, header)
export const materialPicUpload = (data, header) => http.postFile('file/upload_material_pic', data, header)
export const hospitalPicUpload = (data, header) => http.postFile('file/upload_hospital_pic', data, header)
export const supplyPicUpload = (data, header) => http.postFile('file/upload_supply_pic', data, header)
export const assetPicUpload = (data, header) => http.postFile('file/upload_asset_pic', data, header)
export const pdfPicUpload = (data, header) => http.postFile('file/upload_pdf_pic', data, header)
export const getTokenByUse = data => http.factoryGet('file/get_token_by_use', data)
export const workerUpload = (data, header) => http.postFile('file/upload_worker', data, header)
export const productUpload = (data, header) => http.postFile('file/upload_product', data, header)
export const productTypeUpload = (data, header) => http.postFile('file/upload_product_type', data, header)
export const productStepUpload = (data, header) => http.postFile('file/upload_product_step', data, header)
export const productMaterialUpload = (data, header) => http.postFile('file/upload_product_material', data, header)
export const deptStepUpload = (data, header) => http.postFile('file/upload_dept_step', data, header)
export const hospitalUpload = (data, header) => http.postFile('file/upload_hospital', data, header)
export const supplyUpload = (data, header) => http.postFile('file/upload_supply', data, header)
export const materialTypeUpload = (data, header) => http.postFile('file/upload_material_type', data, header)
export const materialUpload = (data, header) => http.postFile('file/upload_material', data, header)
export const materialSupplyUpload = (data, header) => http.postFile('file/upload_material_supply', data, header)
export const doctorUpload = (data, header) => http.postFile('file/upload_doctor', data, header)
export const hospitalPriceUpload = (data, header) => http.postFile('file/upload_hospital_price', data, header)
export const privatePriceUpload = (data, header) => http.postFile('file/upload_private_price', data, header)
export const doctorPriceUpload = (data, header) => http.postFile('file/upload_doctor_price', data, header)
export const doctorHabitUpload = (data, header) => http.postFile('file/upload_doctor_habit', data, header)
export const stockBeginUpload = (data, header) => http.postFile('file/upload_stock_begin', data, header)
export const caseUpload = (data, header) => http.postFile('file/upload_case', data, header)
export const propertyUpload = (data, header) => http.postFile('file/upload_property', data, header)
export const abutmentPurchaseUpload = (data, header) => http.postFile('file/upload_abutment_purchase', data, header)
export const purchaseApplyUpload = (data, header) => http.postFile('file/upload_purchase_apply', data, header)
export const purchaseUpload = (data, header) => http.postFile('file/upload_purchase', data, header)
export const caseStepUpload = (data, header) => http.postFile('file/upload_case_step', data, header)
export const saleTargetUpload = (data, header) => http.postFile('file/upload_sale_target', data, header)
export const fileUpdateDownload = data => http.factoryPostBg('file/update_download', data)
// oss
export const ossTokenGet = data => http.factoryGet('file/get_oss_sts', data)
// 用户操作记录
export const operLogGetPage = data => http.factoryGet('sysUserOperLog/get_page', data)
export const operLogGetOwnPage = data => http.factoryGet('sysUserOperLog/get_own_page', data)
// 用户登录历史
export const sysUserLoginRecordGetPage = data => http.factoryGet('sysUserLoginRecord/get_page', data)
export const sysUserLoginRecordAddCloud = data => http.factoryPostBg('sysUserLoginRecord/add_cloud', data)
// 提醒接收人设置
export const factoryAlertReceiverSave = data => http.factoryPost('factoryAlertReceiver/save', data)
export const factoryAlertReceiverGetList = data => http.factoryGet('factoryAlertReceiver/get_list', data)
// 我的提醒
export const factoryAlertGetPage = data => http.factoryGet('factoryAlert/get_my_page', data)
export const factoryAlertGetUnreadNum = data => http.factoryGet('factoryAlert/get_unread_num', data)
export const factoryAlertReadAll = data => http.factoryPost('factoryAlert/read_all', data)
export const factoryAlertReadOne = data => http.factoryPost('factoryAlert/read_one', data)
export const factoryAlertHasNew = data => http.factoryGet('factoryAlert/has_new', data)
// 系统公告
export const factoryNoticeGetPage = data => http.factoryGet('factoryNotice/get_page', data)
export const factoryNoticeGetPublish = data => http.factoryGet('factoryNotice/get_publish', data)
export const factoryNoticeSave = data => http.factoryPost('factoryNotice/save', data)
export const factoryNoticeDelete = data => http.factoryPost('factoryNotice/delete', data)
// 更新缓存
export const factoryStoreUpdate = data => http.post('factoryStore/update_store', data)
export const factoryHospitalStoreUpdate = data => http.factoryPostBg('factoryStore/update_hospital_store', data)
// 审核流程
export const auditProcessSave = data => http.factoryPost('auditProcess/save', data)
export const auditProcessGetList = data => http.factoryGet('auditProcess/get_list', data)
export const auditProcessBatchAuto = data => http.factoryPost('auditProcess/batch_auto_audit', data)
export const auditFormAudit = data => http.factoryPostData('auditForm/audit', data)
export const auditFormApply = data => http.factoryPostData('auditForm/apply', data)
export const auditFormGet = data => http.factoryGet('auditForm/get', data)
export const auditFormCancelAudit = data => http.factoryPostData('auditForm/cancel_audit', data)
// 工厂管理
export const factoryGetRelateList = data => http.factoryGet('factory/get_relate_list', data)
export const factoryGetSimpleList = data => http.factoryGet('factory/get_list_for_factory', data)
export const factorySave = data => http.factoryPost('factory/save_by_factory', data)
export const factoryAddRelate = data => http.factoryPost('factory/add_relate', data)
export const factoryDeleteRelate = data => http.factoryPost('factory/delete_relate', data)
// 页面功能访问记录
export const factoryPageRecordAdd = data => http.factoryPostBg('factoryPageRecord/add', data)
export const factoryPageRecordAddCloud = data => http.factoryPostBg('factoryPageRecord/add_cloud', data)
// 休息日
export const factoryRestDayBatchSave = data => http.factoryPost('factoryRestDay/batch_save', data)
export const factoryRestDayGetList = data => http.factoryGet('factoryRestDay/get_list', data)
export const factoryRestDayDelete = data => http.factoryPost('factoryRestDay/delete', data)
// 消毒方式
export const disinfectMethodBatchAdd = data => http.factoryPost('disinfectMethod/batch_add', data)
export const disinfectMethodMod = data => http.factoryPost('disinfectMethod/mod', data)
export const disinfectMethodGetList = data => http.factoryGet('disinfectMethod/get_list', data)
export const disinfectMethodDelete = data => http.factoryPost('disinfectMethod/delete', data)
// 帮助文档
export const factoryHelpGetTree = data => http.factoryGet('factoryHelp/get_tree', data)
export const factoryHelpGet = data => http.factoryGet('factoryHelp/get', data)
// 增值服务
export const vasGetList = data => http.factoryGet('factoryValue/get_valid_list', data)
// OSS剩余检测
export const factoryStorageAlert = data => http.factoryGet('factoryStorage/has_alert', data)
//获取企业数据
export const factoryEnterpriseData = data => http.factoryGet('factory/get_enterprise_data', data)
export const factoryAboutToExpire = data => http.factoryGet('factory/about_to_expire', data)
// 成品质检模板
export const finishTestSettingSaveList = data => http.factoryPost('finishTestSetting/save_list', data)
export const finishTestSettingGetList = data => http.factoryGet('finishTestSetting/get_list', data)