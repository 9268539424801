import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {ref} from 'vue'

export const downloadPDF = (page, name) => {
    html2canvas(page).then(function (canvas) {
        canvas2PDF(canvas, name);
    });
};

const canvas2PDF = (canvas, name) => {
    let contentWidth = canvas.width;
    let contentHeight = canvas.height;

    //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
    let imgWidth = 455.28;
    let imgHeight = 455.28 / contentWidth * contentHeight;

    // 第一个参数： l：横向  p：纵向
    // 第二个参数：测量单位（"pt"，"mm", "cm", "m", "in" or "px"）
    let pdf = new jsPDF("p", "pt");

    pdf.addImage(
        canvas.toDataURL("image/jpeg", 1.0),
        "JPEG",
        70,
        30,
        imgWidth,
        imgHeight
    );
    if (!name) name = '导出'
    pdf.save(name + ".pdf");
};

export function useGetPdfBase64() {
    const getPdfBase64Loading = ref(false)

    // 下载文件，自定义文件名称
    function getPdfBase64(url, fileName,callback, token = '') {
        getPdfBase64Loading.value = true
        const x = new XMLHttpRequest()
        x.open('GET', url, true)
        if (token) x.setRequestHeader("X-Auth-Token", token);
        x.responseType = 'blob'
        x.onload = function () {
            const url = window.URL.createObjectURL(x.response)
            const a = document.createElement('a')
            a.href = url
            a.download = fileName
            a.click()
        }
        x.send()
        x.onreadystatechange = function () {
            if (x.readyState === 4) {
                getPdfBase64Loading.value = false
                let reader = new FileReader();
                reader.readAsDataURL(x.response);
                reader.onload = function (e) {
                    let result=e.target.result
                    callback(result);
                };
            }
        }
    }

    return {
        getPdfBase64,
        getPdfBase64Loading
    }
}
