import {reactive, ref, inject} from 'vue'
import {
    stepSave,
    stepSaveAsset,
    stepGetList,
    stepDelete,
    stepGetDeptIds,
    stepSaveDeptIds,
    stepRouteSave,
    stepRouteDelete,
    stepRouteGetList,
    stepRouteSaveProducts,
    stepBatchAdd,
    stepSaveDeptSort,
    redoReasonSave,
    redoReasonDelete,
    redoReasonGetList, redoReasonSort,
} from '@/api/system'
import {useStore} from "vuex";

function useSaveStep() {
    const step = reactive({
        id: 0,
        name: '',
        points: 0,
        remark: '',
        timespan: null,
    })
    const initStep = () => {
        step.id = 0
        step.name = ''
        step.points = 0
        step.remark = ''
        step.timespan = null
    }
    const stepFormRef = ref()
    const stepSaveRules = reactive({
        name: [
            {required: true, message: '请输入名称', trigger: 'blur'},
        ]
    })
    const saveStepLoading = ref(false)
    const saveStepResult = ref(false)
    const saveStep = async () => {
        saveStepResult.value = false
        if (saveStepLoading.value) return
        await stepFormRef.value.validate(async (valid, fields) => {
            if (!valid) {
                return
            }
            saveStepLoading.value = true
            saveStepResult.value = await stepSave(step)
            saveStepLoading.value = false
        })
    }
    return {
        step,
        initStep,
        stepFormRef,
        stepSaveRules,
        saveStepResult,
        saveStepLoading,
        saveStep
    }
}

function useBatchAddStep() {
    const batchAddStepParam = reactive({
        list: []
    })
    const emptyStep = () => {
        return {
            name: '',
            points: 0,
            remark: '',
            timespan: null
        }
    }
    const batchAddStepLoading = ref(false)
    const batchAddStepResult = ref(false)
    const batchAddStep = async () => {
        batchAddStepResult.value = false
        if (batchAddStepLoading.value) return
        batchAddStepLoading.value = true
        batchAddStepResult.value = await stepBatchAdd(batchAddStepParam)
        batchAddStepLoading.value = false
    }
    return {
        batchAddStepParam,
        batchAddStepLoading,
        batchAddStepResult,
        batchAddStep,
        emptyStep,
    }
}

// 关联设备
function useSaveAssetStep() {
    const saveAssetStepParam = reactive({
        id: '',
        assetIds: []
    })
    const saveAssetStepLoading = ref(false)
    const saveAssetStepResult = ref(false)
    const saveAssetStep = async () => {
        if (saveAssetStepLoading.value) return
        saveAssetStepResult.value = false
        saveAssetStepLoading.value = true
        saveAssetStepResult.value = await stepSaveAsset(saveAssetStepParam)
        saveAssetStepLoading.value = false
    }
    return {
        saveAssetStepParam,
        saveAssetStepLoading,
        saveAssetStepResult,
        saveAssetStep
    }
}

function useGetListStep() {
    const stepList = ref([])
    const getListStepLoading = ref(false)
    const getListStep = async () => {
        if (getListStepLoading.value) return
        getListStepLoading.value = true
        let data = await stepGetList({})
        if (data) {
            stepList.value = data
        }
        getListStepLoading.value = false
    }
    const store = useStore()
    const updateStepListStore = async () => {
        await getListStep()
        store.commit('setStepList', stepList.value)
    }
    return {
        stepList,
        getListStep,
        getListStepLoading,
        updateStepListStore
    }
}

function useDeleteStep() {
    const deleteStepLoading = ref(false)
    const deleteStepResult = ref(false)
    const deleteStep = async id => {
        deleteStepResult.value = false
        if (deleteStepLoading.value) return
        deleteStepLoading.value = true
        let param = {
            id: id
        }
        deleteStepResult.value = await stepDelete(param)
        deleteStepLoading.value = false
    }
    return {
        deleteStepLoading,
        deleteStepResult,
        deleteStep
    }
}

// 获取工序关联部门ID
function useGetStepDeptIds() {
    const getStepDeptIdsLoading = ref(false)
    const stepDeptIds = ref([])
    const getStepDeptIds = async id => {
        stepDeptIds.value = []
        if (getStepDeptIdsLoading.value) return
        getStepDeptIdsLoading.value = true
        let data = await stepGetDeptIds({id: id})
        if (data) {
            stepDeptIds.value = data
        }
        getStepDeptIdsLoading.value = false
    }
    return {
        getStepDeptIdsLoading,
        stepDeptIds,
        getStepDeptIds,
    }
}

function useSaveStepDeptIds() {
    const saveStepDeptIdsParam = reactive({
        id: null,
        deptIds: []
    })
    const saveStepDeptIdsLoading = ref(false)
    const saveStepDeptIdsResult = ref(false)
    const saveStepDeptIds = async () => {
        if (saveStepDeptIdsLoading.value) return
        saveStepDeptIdsLoading.value = true
        saveStepDeptIdsResult.value = false
        saveStepDeptIdsResult.value = await stepSaveDeptIds(saveStepDeptIdsParam)
        saveStepDeptIdsLoading.value = false
    }
    return {
        saveStepDeptIdsParam,
        saveStepDeptIdsLoading,
        saveStepDeptIdsResult,
        saveStepDeptIds,
    }
}

// 保存工艺路线
function useSaveStepRoute() {
    const saveStepRouteParam = reactive({
        id: 0,
        name: '',
        note: '',
        list: []
    })
    const emptyItem = Object.assign({}, saveStepRouteParam)
    const initSaveStepRouteParam = () => {
        Object.assign(saveStepRouteParam, emptyItem)
        saveStepRouteParam.list = []
    }
    const newStep = () => {
        return {
            id: 0,
            routeId: 0,
            stepId: null,
            allowSkip: 0,
            orderNo: 0
        }
    }
    const addStep = index => {
        if (saveStepRouteParam.list.length > index) {
            saveStepRouteParam.list.splice(index + 1, 0, newStep())
        } else {
            saveStepRouteParam.list.push(newStep())
        }
    }
    const removeStep = index => {
        saveStepRouteParam.list.splice(index, 1)
    }
    const saveStepRouteLoading = ref(false)
    const saveStepRouteResult = ref(false)
    const saveStepRoute = async () => {
        if (saveStepRouteLoading.value) return
        saveStepRouteResult.value = false
        saveStepRouteLoading.value = true
        saveStepRouteResult.value = await stepRouteSave(saveStepRouteParam)
        saveStepRouteLoading.value = false
    }
    return {
        saveStepRouteParam,
        initSaveStepRouteParam,
        newStep,
        addStep,
        removeStep,
        saveStepRouteResult,
        saveStepRouteLoading,
        saveStepRoute
    }
}

// 获取工艺路线列表
function useGetStepRouteList() {
    const getStepRouteListLoading = ref(false)
    const stepRouteList = ref([])
    const getStepRouteList = async () => {
        stepRouteList.value = []
        if (getStepRouteListLoading.value) return
        getStepRouteListLoading.value = true
        let data = await stepRouteGetList()
        if (data) {
            stepRouteList.value = data
        }
        getStepRouteListLoading.value = false
    }
    return {
        getStepRouteListLoading,
        stepRouteList,
        getStepRouteList,
    }
}

// 批量应用工艺路线到产品
function useSaveStepRouteProducts() {
    const saveStepRouteProductsParam = reactive({
        id: 0,
        productIds: []
    })
    const saveStepRouteProductsLoading = ref(false)
    const saveStepRouteProductsResult = ref(false)
    const saveStepRouteProducts = async () => {
        if (saveStepRouteProductsLoading.value) return
        saveStepRouteProductsLoading.value = true
        saveStepRouteProductsResult.value = false
        saveStepRouteProductsResult.value = await stepRouteSaveProducts(saveStepRouteProductsParam)
        saveStepRouteProductsLoading.value = false
    }
    return {
        saveStepRouteProductsParam,
        saveStepRouteProductsLoading,
        saveStepRouteProductsResult,
        saveStepRouteProducts
    }
}

function useDeleteStepRoute() {
    const deleteStepRouteLoading = ref(false)
    const deleteStepRouteResult = ref(false)
    const deleteStepRoute = async id => {
        deleteStepRouteResult.value = false
        if (deleteStepRouteLoading.value) return
        deleteStepRouteLoading.value = true
        deleteStepRouteResult.value = await stepRouteDelete({id: id})
        deleteStepRouteLoading.value = false
    }
    return {
        deleteStepRouteLoading,
        deleteStepRouteResult,
        deleteStepRoute
    }
}

/**
 * 保存工序排序
 * @returns
 */
function useSaveStepSort() {
    const saveStepSortParam = reactive({
        list: []
    })
    const saveStepSortLoading = ref(false)
    const saveStepSortResult = ref(false)
    const saveStepSort = async () => {
        saveStepSortResult.value = false
        if (saveStepSortLoading.value) return
        saveStepSortLoading.value = true
        saveStepSortResult.value = await stepSaveDeptSort(saveStepSortParam)
        saveStepSortLoading.value = false
    }
    return {
        saveStepSortParam,
        saveStepSortLoading,
        saveStepSortResult,
        saveStepSort,
    }
}

/**
 * 内返原因排序
 * @returns {{saveRedoReasonSortLoading: *, saveRedoReasonSortResult: *, saveRedoReasonSort: ((function(): Promise<void>)|*), saveRedoReasonSortParam: *}}
 */
function useSaveRedoReasonSort() {
    const saveRedoReasonSortParam = reactive({
        list: []
    })
    const saveRedoReasonSortLoading = ref(false)
    const saveRedoReasonSortResult = ref(false)
    const saveRedoReasonSort = async () => {
        saveRedoReasonSortResult.value = false
        if (saveRedoReasonSortLoading.value) return
        saveRedoReasonSortLoading.value = true
        saveRedoReasonSortResult.value = await redoReasonSort(saveRedoReasonSortParam)
        saveRedoReasonSortLoading.value = false
    }
    return {
        saveRedoReasonSortParam,
        saveRedoReasonSortLoading,
        saveRedoReasonSortResult,
        saveRedoReasonSort,
    }
}

/**
 * 保存工序内返原因
 * @returns {{saveRedoReason: ((function(): Promise<void>)|*), saveRedoReasonParam: *, saveRedoReasonResult: *, saveRedoReasonLoading: *}}
 */
function useSaveRedoReason() {
    const saveRedoReasonParam = reactive({
        id: 0,
        stepId: null,
        redoReason: ''
    })
    const saveRedoReasonLoading = ref(false)
    const saveRedoReasonResult = ref(false)
    const saveRedoReason = async () => {
        saveRedoReasonResult.value = false
        if (saveRedoReasonLoading.value) return
        saveRedoReasonLoading.value = true
        saveRedoReasonResult.value = await redoReasonSave(saveRedoReasonParam)
        saveRedoReasonLoading.value = false
    }
    return {
        saveRedoReasonParam,
        saveRedoReasonLoading,
        saveRedoReasonResult,
        saveRedoReason,
    }
}

/**
 * 删除内返原因
 * @returns {{deleteRedoReasonLoading: *, deleteRedoReasonResult: *, deleteRedoReason: ((function(*): Promise<void>)|*)}}
 */
function useDeleteRedoReason() {
    const deleteRedoReasonLoading = ref(false)
    const deleteRedoReasonResult = ref(false)
    const deleteRedoReason = async id => {
        deleteRedoReasonResult.value = false
        if (deleteRedoReasonLoading.value) return
        deleteRedoReasonLoading.value = true
        deleteRedoReasonResult.value = await redoReasonDelete({id: id})
        deleteRedoReasonLoading.value = false
    }
    return {
        deleteRedoReasonLoading,
        deleteRedoReasonResult,
        deleteRedoReason
    }
}

/**
 * 获取所有内返原因
 * @returns {{getRedoReasonListLoading: *, redoReasonList: *, getRedoReasonList: ((function(): Promise<void>)|*)}}
 */
function useGetRedoReasonList() {
    const getRedoReasonListLoading = ref(false)
    const redoReasonList = ref([])
    const getRedoReasonList = async stepId => {
        redoReasonList.value = []
        if (getRedoReasonListLoading.value) return
        getRedoReasonListLoading.value = true
        let data = await redoReasonGetList({id: stepId})
        if (data) {
            redoReasonList.value = data
        }
        getRedoReasonListLoading.value = false
    }
    return {
        getRedoReasonListLoading,
        redoReasonList,
        getRedoReasonList,
    }
}

export {
    useSaveStep,
    useSaveAssetStep,
    useGetListStep,
    useDeleteStep,
    useGetStepDeptIds,
    useSaveStepDeptIds,
    useSaveStepRoute,
    useGetStepRouteList,
    useDeleteStepRoute,
    useSaveStepRouteProducts,
    useBatchAddStep,
    useSaveStepSort,
    useGetRedoReasonList,
    useSaveRedoReason,
    useSaveRedoReasonSort,
    useDeleteRedoReason
}