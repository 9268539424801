<template>
    <div>
        <el-upload action="#"
                   class="avatar-uploader" accept="image/*"
                   :before-upload="beforePicUpload"
                   :auto-upload="true"
                   :on-success="successPicUpload"
                   :http-request="uploadFile"
                   :show-file-list="false">
            <img v-if="!!picUrl"
                 alt="图片"
                 :src="picUrl"
                 class="avatar"/>
            <el-icon v-else class="avatar-uploader-icon">
                <Plus/>
            </el-icon>
        </el-upload>
        <div>
            <el-button v-if="!!innerFileId" type="danger" link @click="removePic">
                删除
            </el-button>
            <el-button v-if="!!innerFileId" type="success" link
                       @click="previewPic">预览
            </el-button>
        </div>
        <el-dialog v-model="picVisible">
            <el-image :src="picUrl">
            </el-image>
        </el-dialog>
    </div>
</template>
<!--使用示例-->
<!--<pic-upload v-if="picVisible" :file-use="fileUseEnum.牙色" :folder-prefix="factoryFolderPrefix.caseColor"-->
<!--            :file-id="productFileId" :file-token="productFileToken"-->
<!--            @finish-upload="finishUploadPic"></pic-upload>-->
<script setup>
import {ref, reactive, onMounted, onActivated, watch} from 'vue'
import {Plus, Search, Refresh, Edit, Delete, Check} from '@element-plus/icons-vue';
import {useUploadFile, useGetFileUrl} from "@/use/system/useUpload";
import {beforePicUpload} from '@/use/system/useFile'

const props = defineProps({
    fileId: Number,
    fileToken: String,
    folderPrefix: String,
    fileUse: Number,
})
const emits = defineEmits(['finishUpload'])
const innerFileId = ref()
const innerFileToken = ref()
const picUrl = ref()//图片url
watch(() => props.fileId, async (n, o) => {
    innerFileId.value = n
})
watch(() => props.fileToken, async (n, o) => {
    innerFileId.value = n
    if (n) {
        picUrl.value = await getFileUrl(n)
    }
})
const {getFileUrl} = useGetFileUrl()
const {
    uploadParam,
    uploadFileResult,
    uploadFileList,
    uploadFile,
} = useUploadFile()
watch(() => props.folderPrefix, (n, o) => {
    uploadParam.folderPrefix = n
})
watch(() => props.fileUse, (n, o) => {
    uploadParam.fileUse = n
})
onMounted(async () => {
    uploadParam.folderPrefix = props.folderPrefix
    uploadParam.fileUse = props.fileUse
    innerFileId.value = props.fileId
    innerFileToken.value = props.fileToken
    if (props.fileToken) {
        picUrl.value = await getFileUrl(props.fileToken)
    }
})

const successPicUpload = param => {
    innerFileId.value = uploadFileResult.picId
    innerFileToken.value = uploadFileResult.picToken
    setTimeout(async () => {
        picUrl.value = await getFileUrl(uploadFileResult.picToken)
    }, 500)
    emits('finishUpload', {
        fileId: innerFileId.value,
        fileToken: innerFileToken.value
    })
}
const removePic = param => {
    uploadFileResult.picId = ''
    uploadFileResult.picToken = ''
    innerFileId.value = ''
    innerFileToken.value = ''
    picUrl.value = ''
    emits('finishUpload', null)
}
const picVisible = ref(false)
const previewPic = param => {
    picVisible.value = true
}
</script>