import {reactive, ref, inject, toRaw} from 'vue'
import moment from 'moment'
import {
    materialSave,
    materialDelete,
    materialGetList,
    materialGet,
    materialGetPage,
    materialUpdateValid,
    materialBatchUpdateVisible,
    materialBatchUpdateNeedHide,
    materialSupplySave,
    materialSupplyDelete,
    materialSupplyGetList,
    materialGetHiddenList,
    materialGetNeedHideList,
    materialExportExcel, materialBatchInvalid,
    materialTraceGetPage,
    materialTraceStepExportExcel,
} from '@/api/purchase'
import {ElMessage} from "element-plus";
import {useStore} from "vuex";
import http from "@/util/axios";
import {useDownload} from "@/use/system/useFile";

//保存工厂材料 oycm 2022/7/23
function useSaveMaterial() {
    const material = reactive({
        id: 0,
        materialNo: '',
        name: '',
        materialType: '',
        standard: '',
        version: '',
        registerNo: '',
        storeUnit: '',
        costUnit: '',
        costUnitRate: 1,
        isMetal: '',
        financeNo: '',
        deptId: '',
        remark: '',
        picId: '',
        picToken: '',
        isValid: 1,
        isVisible: 1,
        registerNoYear: '',
        registerEndDate: '',
    })
    const materialEmpty = Object.assign({}, material)
    const initMaterial = () => {
        for (let field in material) {
            if (field !== 'materialType') {
                material[field] = materialEmpty[field]
            }
        }
    }
    const materialFormRef = ref()
    const materialSaveRules = reactive({
        name: [
            {required: true, message: '请输入名称', trigger: 'blur'},
        ],
        materialType: [
            {required: true, message: '请选择种类', trigger: 'change'},
        ],
        storeUnit: [
            {required: true, message: '请选择库存单位', trigger: 'change'},
        ],
        costUnit: [
            {required: true, message: '请选择成本单位', trigger: 'change'},
        ],
    })
    const saveMaterialLoading = ref(false)
    const saveMaterialResult = ref(false)
    const saveMaterial = async () => {
        saveMaterialResult.value = false
        if (saveMaterialLoading.value) return
        saveMaterialLoading.value = true
        await materialFormRef.value.validate(async (valid, fields) => {
            if (!valid) {
                saveMaterialLoading.value = false
                return
            }
            saveMaterialResult.value = await materialSave(material)
            saveMaterialLoading.value = false
        })
    }
    return {
        material,
        initMaterial,
        materialFormRef,
        materialSaveRules,
        saveMaterialLoading,
        saveMaterialResult,
        saveMaterial,
    }
}

//删除工厂材料 oycm 2022/7/23
function useDeleteMaterial() {
    const deleteMaterialLoading = ref(false)
    const deleteMaterialResult = ref(false)
    const deleteMaterial = async id => {
        deleteMaterialResult.value = false
        if (deleteMaterialLoading.value) return
        deleteMaterialLoading.value = true
        deleteMaterialResult.value = await materialDelete({id: id})
        deleteMaterialLoading.value = false
    }
    return {
        deleteMaterialLoading,
        deleteMaterialResult,
        deleteMaterial,
    }
}

//列表工厂材料 oycm 2022/7/23
function useGetListMaterial() {
    const getListMaterialLoading = ref(false)
    const materialList = ref([])
    const getListMaterial = async () => {
        if (getListMaterialLoading.value) return
        getListMaterialLoading.value = true
        let data = await materialGetList({})
        if (data) {
            materialList.value = data
        }
        getListMaterialLoading.value = false
    }
    const store = useStore()
    const updateStoreMaterialLoading = ref(false)
    const updateStoreMaterial = async () => {
        if (updateStoreMaterialLoading.value) return
        updateStoreMaterialLoading.value = true
        let storeMaterialList = await materialGetList({})
        if (storeMaterialList && storeMaterialList.length) {
            for (let material of storeMaterialList) {
                let materialCount = store.state.materialCount
                let item = materialCount.find(x => x.materialId === material.id)
                material.count = item ? item.count : 0
            }
        } else {
            storeMaterialList = []
        }
        store.commit('setMaterialList', storeMaterialList)
        updateStoreMaterialLoading.value = false
    }
    return {
        getListMaterialLoading,
        materialList,
        updateStoreMaterial,
        updateStoreMaterialLoading,
        getListMaterial,
    }
}

//分页工厂材料 oycm 2022/7/23
function useGetPageMaterial() {
    const getPageMaterialParam = reactive({
        pageIndex: 1,
        pageSize: 10,
        id: null,
        name: '',
        materialNo: '',
        materialType: null,
        standard: '',
        version: '',
        registerNo: '',
        isValid: null,
    })
    const getPageMaterialParamEmpty = Object.assign({}, getPageMaterialParam)
    const initGetPageMaterialParam = () => {
        Object.assign(getPageMaterialParam, getPageMaterialParamEmpty)
    }

    const getPageMaterialLoading = ref(false)
    const materialPage = ref([])
    const getPageMaterialResult = reactive({
        totalCount: 0,
        totalPages: 0,
    })
    const getPageMaterial = async () => {
        if (getPageMaterialLoading.value) return
        getPageMaterialLoading.value = true
        let data = await materialGetPage(getPageMaterialParam)
        if (data && data.list && data.list.length) {
            materialPage.value = data.list
            getPageMaterialResult.totalPages = Math.ceil(data.totalCount / getPageMaterialParam.pageSize)
            getPageMaterialResult.totalCount = data.totalCount
        } else {
            materialPage.value = []
            getPageMaterialResult.totalPages = 0
            getPageMaterialResult.totalCount = 0
        }
        getPageMaterialLoading.value = false
    }
    return {
        getPageMaterialParam,
        initGetPageMaterialParam,
        getPageMaterialLoading,
        materialPage,
        getPageMaterialResult,
        getPageMaterial,
    }
}

//停用启用
function useUpdateValidMaterial() {
    const updateValidMaterialLoading = ref(false)
    const updateValidMaterialResult = ref(false)
    const updateValidMaterial = async (id, isValid) => {
        updateValidMaterialResult.value = false
        if (updateValidMaterialLoading.value) return
        updateValidMaterialLoading.value = true
        updateValidMaterialResult.value = await materialUpdateValid({id: id, isValid: isValid})
        updateValidMaterialLoading.value = false
    }
    return {
        updateValidMaterialLoading,
        updateValidMaterialResult,
        updateValidMaterial,
    }
}

function useBatchInvalidMaterial() {
    const batchInvalidMaterialLoading = ref(false)
    const batchInvalidMaterialResult = ref(false)
    const batchInvalidMaterial = async ids => {
        batchInvalidMaterialResult.value = false
        if (batchInvalidMaterialLoading.value) return
        batchInvalidMaterialLoading.value = true
        batchInvalidMaterialResult.value = await materialBatchInvalid({ids: ids})
        batchInvalidMaterialLoading.value = false
    }
    return {
        batchInvalidMaterialLoading,
        batchInvalidMaterialResult,
        batchInvalidMaterial,
    }
}

//显示隐藏
function useBatchUpdateVisibleMaterial() {
    const batchUpdateVisibleMaterialLoading = ref(false)
    const batchUpdateVisibleMaterialResult = ref(false)
    const batchUpdateVisibleMaterial = async (list) => {
        batchUpdateVisibleMaterialResult.value = false
        if (batchUpdateVisibleMaterialLoading.value) return
        batchUpdateVisibleMaterialLoading.value = true
        batchUpdateVisibleMaterialResult.value = await materialBatchUpdateVisible(list)
        batchUpdateVisibleMaterialLoading.value = false
    }
    return {
        batchUpdateVisibleMaterialLoading,
        batchUpdateVisibleMaterialResult,
        batchUpdateVisibleMaterial,
    }
}

/**
 * 设置需要隐藏的材料
 * @returns
 */
function useBatchUpdateNeedHideMaterial() {
    const batchUpdateNeedHideMaterialLoading = ref(false)
    const batchUpdateNeedHideMaterialResult = ref(false)
    const batchUpdateNeedHideMaterial = async (list) => {
        batchUpdateNeedHideMaterialResult.value = false
        if (batchUpdateNeedHideMaterialLoading.value) return
        batchUpdateNeedHideMaterialLoading.value = true
        batchUpdateNeedHideMaterialResult.value = await materialBatchUpdateNeedHide(list)
        batchUpdateNeedHideMaterialLoading.value = false
    }
    return {
        batchUpdateNeedHideMaterialLoading,
        batchUpdateNeedHideMaterialResult,
        batchUpdateNeedHideMaterial,
    }
}

///保存材料供应商 oycm 2022/7/23
function useSaveMaterialSupply() {
    const validateMaterialSupply = param => {
        if (!param.materialId) {
            ElMessage.error('请选择材料')
            return false
        } else if (!param.supplyId) {
            ElMessage.error('请选择供应商')
            return false
        } else if (!param.buyUnit) {
            ElMessage.error('请选择采购单位')
            return false
        }
        return true
    }
    const saveMaterialSupplyLoading = ref(false)
    const saveMaterialSupplyResult = ref(false)
    const saveMaterialSupply = async param => {
        saveMaterialSupplyResult.value = false
        if (!validateMaterialSupply(param)) return
        if (saveMaterialSupplyLoading.value) return
        saveMaterialSupplyLoading.value = true
        saveMaterialSupplyResult.value = await materialSupplySave(param)
        saveMaterialSupplyLoading.value = false
    }
    return {
        saveMaterialSupplyLoading,
        saveMaterialSupplyResult,
        saveMaterialSupply,
    }
}

//删除材料供应商 oycm 2022/7/23
function useDeleteMaterialSupply() {
    const deleteMaterialSupplyLoading = ref(false)
    const deleteMaterialSupplyResult = ref(false)
    const deleteMaterialSupply = async id => {
        deleteMaterialSupplyResult.value = false
        if (deleteMaterialSupplyLoading.value) return
        deleteMaterialSupplyLoading.value = true
        deleteMaterialSupplyResult.value = await materialSupplyDelete({id: id})
        deleteMaterialSupplyLoading.value = false
    }
    return {
        deleteMaterialSupplyLoading,
        deleteMaterialSupplyResult,
        deleteMaterialSupply,
    }
}

//列表材料供应商 oycm 2022/7/23
function useGetListMaterialSupply() {
    const getListMaterialSupplyLoading = ref(false)
    const materialSupplyList = ref([])
    const getListMaterialSupply = async materialId => {
        if (getListMaterialSupplyLoading.value) return
        getListMaterialSupplyLoading.value = true
        let data = await materialSupplyGetList({id: materialId})
        if (data) {
            materialSupplyList.value = data
        }
        getListMaterialSupplyLoading.value = false
    }
    return {
        getListMaterialSupplyLoading,
        materialSupplyList,
        getListMaterialSupply,
    }
}

//隐藏材料列表 oycm 2022/8/5
function useGetHiddenListMaterial() {
    const getHiddenListMaterialLoading = ref(false)
    const materialHiddenList = ref([])
    const getHiddenListMaterial = async () => {
        if (getHiddenListMaterialLoading.value) return
        getHiddenListMaterialLoading.value = true
        let data = await materialGetHiddenList({})
        if (data) {
            materialHiddenList.value = data
            if (materialHiddenList.value && materialHiddenList.value.length) {
                materialHiddenList.value.forEach(x => x.isChecked = false)
            }
        }
        getHiddenListMaterialLoading.value = false
    }
    return {
        getHiddenListMaterialLoading,
        materialHiddenList,
        getHiddenListMaterial,
    }
}

/**
 * 需要隐藏的材料列表
 * @returns
 */
function useGetNeedHideListMaterial() {
    const getNeedHideListMaterialLoading = ref(false)
    const materialNeedHideList = ref([])
    const getNeedHideListMaterial = async () => {
        if (getNeedHideListMaterialLoading.value) return
        getNeedHideListMaterialLoading.value = true
        let data = await materialGetNeedHideList({})
        if (data) {
            materialNeedHideList.value = data
            if (materialNeedHideList.value && materialNeedHideList.value.length) {
                materialNeedHideList.value.forEach(x => x.isChecked = false)
            }
        }
        getNeedHideListMaterialLoading.value = false
    }
    return {
        getNeedHideListMaterialLoading,
        materialNeedHideList,
        getNeedHideListMaterial,
    }
}

const useExportMaterialExcel = () => {
    const {
        downFile,
        downFileLoading
    } = useDownload()
    const exportMaterialExcel = req => {
        req.token = sessionStorage.getItem('token')
        let url = materialExportExcel + '?' + http.getQueryFromJson(req)
        downFile(url, '材料列表')
    }
    return {
        exportMaterialExcelLoading: downFileLoading,
        exportMaterialExcel
    }
}

function useGetMaterial() {
    const getMaterialLoading = ref(false)
    const getMaterial = async id => {
        if (getMaterialLoading.value) return
        getMaterialLoading.value = true
        let res = await materialGet({id: id})
        getMaterialLoading.value = false
        if (res) return res
    }
    return {
        getMaterial,
        getMaterialLoading
    }
}

//  获取设备追溯分页
function useGetPageMaterialTrace() {
    const getPageMaterialTraceParam = reactive({
        materialName: '',
        materialType: '',
        lotNo: '',
        caseNo: '',
        beginOperTime: '',
        endOperTime: '',
        pageIndex: 1,
        pageSize: 10,
    })
    const getPageMaterialTraceParamEmpty = Object.assign({}, getPageMaterialTraceParam)
    const initGetPageMaterialTraceParam = () => {
        Object.assign(getPageMaterialTraceParam, getPageMaterialTraceParamEmpty)
    }
    const getPageMaterialTraceLoading = ref(false)
    const MaterialTracePage = ref([])
    const getPageMaterialTraceResult = reactive({
        totalCount: 0,
        totalPages: 0,
    })
    const getPageMaterialTrace = async () => {
        if (getPageMaterialTraceLoading.value) return
        getPageMaterialTraceLoading.value = true
        let data = await materialTraceGetPage(getPageMaterialTraceParam)
        if (data && data.list && data.list.length) {
            MaterialTracePage.value = data.list
            getPageMaterialTraceResult.totalPages = Math.ceil(data.totalCount / getPageMaterialTraceParam.pageSize)
            getPageMaterialTraceResult.totalCount = data.totalCount
        } else {
            MaterialTracePage.value = []
            getPageMaterialTraceResult.totalPages = 0
            getPageMaterialTraceResult.totalCount = 0
        }
        getPageMaterialTraceLoading.value = false
    }
    return {
        getPageMaterialTraceParam,
        getPageMaterialTraceResult,
        MaterialTracePage,
        initGetPageMaterialTraceParam,
        getPageMaterialTraceLoading,
        getPageMaterialTrace
    }
}

const exportMaterialTraceStepExcel = req => {
    if (!req.beginOperTime && !req.endOperTime) {
        ElMessage.error('需要选择好操作日期后再导出')
        return
    }
    req.token = sessionStorage.getItem('token')
    let url = materialTraceStepExportExcel + '?' + http.getQueryFromJson(req)
    window.open(url, '_blank')
}

export {
    useSaveMaterial,
    useDeleteMaterial,
    useGetListMaterial,
    useGetPageMaterial,
    useUpdateValidMaterial,
    useBatchUpdateVisibleMaterial,
    useBatchUpdateNeedHideMaterial,
    useSaveMaterialSupply,
    useDeleteMaterialSupply,
    useGetListMaterialSupply,
    useGetHiddenListMaterial,
    useGetNeedHideListMaterial,
    useExportMaterialExcel,
    useBatchInvalidMaterial,
    useGetMaterial,
    useGetPageMaterialTrace,
    exportMaterialTraceStepExcel,
}