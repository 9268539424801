import http from "@/util/axios";
// 仓库
export const facWarehouseSave = data => http.factoryPost('facWarehouse/save', data)
export const facWarehouseDelete = data => http.factoryPost('facWarehouse/delete', data)
export const facWarehouseGetList = data => http.factoryGet('facWarehouse/get_list', data)
// 储位
export const storageLocationSave = data => http.factoryPost('storageLocation/save', data)
export const storageLocationDelete = data => http.factoryPost('storageLocation/delete', data)
export const storageLocationGetList = data => http.factoryGet('storageLocation/get_list', data)
// 采购入库检验模板
export const stockInCheckTemplateSave = data => http.factoryPost('stockInCheckTemplate/save', data)
export const stockInCheckTemplateDelete = data => http.factoryPost('stockInCheckTemplate/delete', data)
export const stockInCheckTemplateGetList = data => http.factoryGet('stockInCheckTemplate/get_list', data)
export const stockInCheckContentSave = data => http.factoryPost('stockInCheckTemplate/save_content', data)
export const stockInCheckContentDelete = data => http.factoryPost('stockInCheckTemplate/delete_content', data)
// 期初库存
export const stockBeginGetPage = data => http.factoryGet('stockBegin/get_page', data)
export const stockBeginDetailBatchSave = data => http.factoryPost('stockBegin/batch_save_detail', data)
// 当前库存
export const stockNowGetPage = data => http.factoryGet('stockNow/get_page', data)
export const stockNowUpdateHigh = data => http.factoryPost('stockNow/update_high', data)
export const stockNowUpdateSafe = data => http.factoryPost('stockNow/update_safe', data)
export const stockNowUpdateLow = data => http.factoryPost('stockNow/update_low', data)
export const stockNowGetAllDetail = data => http.factoryGet('stockNow/get_all_detail', data)
export const stockNowDetailGetPage = data => http.factoryGet('stockNow/get_detail_page', data)
export const stockMaterialGetByLotNo = data => http.factoryGet('stockNow/get_material_by_lot_no', data)
export const stockNowUpdateStorePlace = data => http.factoryPost('stockNow/update_store_place', data)

export const stockNowExportExcel = '/api/factory/stockExport/excel_stock_now'
export const inventoryExportExcel = '/api/factory/stockExport/excel_inventory'
export const stockNowDetailExportExcel = '/api/factory/stockExport/excel_stock_now_detail'
// 入库
export const stockInSave = data => http.factoryPost('stockIn/save', data)
export const stockInSaveCheckContent = data => http.factoryPost('stockIn/save_check_content', data)
export const stockInGetPage = data => http.factoryGet('stockIn/get_page', data)
export const stockInGet = data => http.factoryGet('stockIn/get', data)
export const stockInGetStat = data => http.factoryGet('stockIn/get_stat', data)
export const stockInValid = data => http.factoryPost('stockIn/invalid', data)
export const stockInGetLogList = data => http.factoryGet('stockIn/get_log_list', data)
export const stockInDetailGetPage = data => http.factoryGet('stockIn/get_detail_page', data)
export const stockInDetailExportExcel = '/api/factory/stockExport/excel_stock_in_detail'
export const singleStockInExportExcel = '/api/factory/stockExport/excel_stock_in_single'
// 出库
export const stockOutSave = data => http.factoryPost('stockOut/save', data)
export const stockOutGetPage = data => http.factoryGet('stockOut/get_page', data)
export const stockOutGet = data => http.factoryGet('stockOut/get', data)
export const stockOutGetStat = data => http.factoryGet('stockOut/get_stat', data)
export const stockOutValid = data => http.factoryPost('stockOut/invalid', data)
export const stockOutGetLogList = data => http.factoryGet('stockOut/get_log_list', data)
export const stockOutDetailGetPage = data => http.factoryGet('stockOut/get_detail_page', data)
export const stockOutDetailExportExcel = '/api/factory/stockExport/excel_stock_out_detail'
export const singleStockOutExportExcel = '/api/factory/stockExport/excel_stock_out_single'
// 材料已用完
export const materialUseUp = data => http.factoryPost('stockOut/use_up', data)
export const materialCancelUseUp = data => http.factoryPost('stockOut/cancel_use_up', data)
export const materialUseUpGetPage = data => http.factoryGet('stockOut/get_use_up_page', data)
// 库存提醒
export const stockAlertGetPage = data => http.factoryGet('stockAlert/get_page', data)
export const stockAlertGetTotalNum = data => http.factoryGet('stockAlert/get_total_num', data)
// 仓库综合
export const stockFlowGetList = data => http.factoryGet('stock/get_flow_list', data)
export const stockInUpload = (data, header) => http.postFile('stock/upload_stock_in', data, header)
export const stockOutUpload = (data, header) => http.postFile('stock/upload_stock_out', data, header)
export const stockFlowExportExcel = '/api/factory/stockExport/excel_stock_flow'
// 领料申请
export const stockApplySave = data => http.factoryPost('stockApply/save', data)
export const stockApplyGetPage = data => http.factoryGet('stockApply/get_page', data)
export const stockApplyGet = data => http.factoryGet('stockApply/get', data)
export const stockApplyInvalid = data => http.factoryPost('stockApply/invalid', data)
export const stockApplyGetLogList = data => http.factoryGet('stockApply/get_log_list', data)
export const singleStockApplyExportExcel = '/api/factory/stockExport/excel_stock_apply_single'
// 移库单
export const stockMoveDetailExportExcel = '/api/factory/stockExport/excel_stock_move_detail'
export const stockMoveAdd = data => http.factoryPost('stockMove/add', data)
export const stockMoveGetPage = data => http.factoryGet('stockMove/get_page', data)
export const stockMoveGet = data => http.factoryGet('stockMove/get', data)
export const stockMoveDetailGetPage = data => http.factoryGet('stockMove/get_detail_page', data)
// 盘点单
export const stockCheckSave = data => http.factoryPostData('stockCheck/save', data)
export const stockCheckGetPage = data => http.factoryGet('stockCheck/get_page', data)
export const stockCheckDelete = data => http.factoryPost('stockCheck/delete', data)
export const stockCheckDetailSave = data => http.factoryPost('stockCheck/save_detail', data)
export const stockCheckDetailGetPage = data => http.factoryGet('stockCheck/get_detail_page', data)
export const stockCheckDetailDelete = data => http.factoryPost('stockCheck/delete_detail', data)
export const stockCheckCount = data => http.factoryPost('stockCheck/count', data)
export const stockCheckStock = data => http.factoryPost('stockCheck/stock', data)
export const stockCheckDiffGetPage = data => http.factoryGet('stockCheck/get_diff_page', data)
export const stockCheckDetailExportExcel = '/api/factory/stockExport/excel_stock_check'