<template>
    <div>
        <div class="view-list flex-row">
            <div class="view-list-left" v-show="!queryId">
                <el-form label-width="90px" :model="getSimplePageCaseParam">
                    <el-form-item label-width="10px">
                        <el-row :gutter="5">
                            <el-col :span="12">
                                <el-input placeholder="订单号" v-model.trim="getSimplePageCaseParam.caseNo"
                                          @keydown.enter="searchCase" ref="searchCaseNoRef"
                                          maxlength="30"></el-input>
                            </el-col>
                            <el-col :span="12">
                                <el-input placeholder="搜索患者" v-model.trim="getSimplePageCaseParam.patientName"
                                          @keydown.enter="searchCase"
                                          maxlength="30"></el-input>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item label-width="10px">
                        <el-row :gutter="5">
                            <el-col :span="12">
                                <el-input placeholder="搜索盒号" v-model.trim="getSimplePageCaseParam.boxNo"
                                          @keydown.enter="searchCase"
                                          maxlength="30"></el-input>
                            </el-col>
                            <el-col :span="12">
                                <el-input placeholder="搜索关联订单号" v-model.trim="getSimplePageCaseParam.sourceCaseNo"
                                          @keydown.enter="searchCase"
                                          maxlength="30"></el-input>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item label-width="10px">
                        <el-row :gutter="5">
                            <el-col :span="12">
                                <hospital-select
                                    v-model:hospital-id="getSimplePageCaseParam.facHospitalId"></hospital-select>
                            </el-col>
                            <el-col :span="12">
                                <doctor-select v-model:doctor-id="getSimplePageCaseParam.facDoctorId"
                                               :hospital-id="getSimplePageCaseParam.facHospitalId"></doctor-select>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item label="入件日期">
                        <date-range-picker v-model:begin-date="getSimplePageCaseParam.inBeginDate"
                                           v-model:end-date="getSimplePageCaseParam.inEndDate"></date-range-picker>
                    </el-form-item>
                    <el-form-item label="出件日期">
                        <date-range-picker v-model:begin-date="getSimplePageCaseParam.outBeginDate"
                                           v-model:end-date="getSimplePageCaseParam.outEndDate"></date-range-picker>
                    </el-form-item>
                    <el-space :size="10">
                        <el-button type="primary" @click="searchCase"
                                   :loading="getSimplePageCaseLoading">搜索
                        </el-button>
                        <el-button @click="initGetSimplePageCase">清空</el-button>
                        <el-button type="warning" plain @click="dataSetVisible=true">设置</el-button>
                        <el-button type="success" @click="newCase">新增</el-button>
                    </el-space>
                </el-form>
                <el-table :data="casePage" :show-header="false" v-loading="getSimplePageCaseLoading || caseGetLoading"
                          :highlight-current-row="true" @row-click="chooseRow" ref="casePageRef"
                          :row-class-name="tableRowClass">
                    <el-table-column>
                        <template #default="scope">
                            <div class="flex justify-between align-center">
                                <span style="flex-basis: 105px;">{{ scope.row.caseNo }}</span>
                                <div class="text-right flex-grow long-text-hide h-24"
                                     :title="scope.row.facHospitalName">
                                    {{ scope.row.facHospitalName }}
                                </div>
                            </div>
                            <div class="flex justify-between align-center">
                                <el-space :size="2">
                                    <div class="long-text-hide text-bold"
                                         :title="scope.row.patientName">
                                        {{ scope.row.patientName }}
                                    </div>
                                    <el-tag size="small" type="danger"
                                            v-if="!!scope.row.facDoctorName">
                                        {{ scope.row.facDoctorName }}
                                    </el-tag>
                                </el-space>
                                <div>
                                    <el-tag size="small" v-if="scope.row.isPrivate===1" type="danger">戴</el-tag>
                                    <el-tag size="small" v-if="scope.row.isUrgent===1" type="warning">急</el-tag>
                                    <el-tag size="small" v-if="scope.row.isTry===1" type="success">戴</el-tag>
                                    <el-tag size="small" type="danger" effect="plain"
                                            v-if="scope.row.caseType!==caseTypeEnum.正常">
                                        {{ getCaseTypeName(scope.row.caseType) }}
                                    </el-tag>
                                    <el-tag size="small" v-if="scope.row.caseState>=caseStateEnum.已退货" type="danger"
                                            effect="plain">
                                        {{ getCaseStateName(scope.row.caseState) }}
                                    </el-tag>
                                    <el-tag size="small" v-else-if="scope.row.caseState>=caseStateEnum.已发货"
                                            type="success" effect="plain">
                                        {{ getCaseStateName(scope.row.caseState) }}
                                    </el-tag>
                                    <el-tag size="small" v-else-if="scope.row.caseState>=caseStateEnum.加工中"
                                            type="warning" effect="plain">
                                        {{ getCaseStateName(scope.row.caseState) }}
                                    </el-tag>
                                    <el-tag size="small" v-else effect="plain">
                                        {{ getCaseStateName(scope.row.caseState) }}
                                    </el-tag>
                                    <span title="入件日期">{{ moment(scope.row.inDate).format('yyyy/MM/DD') }}</span>
                                </div>
                            </div>

                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    v-show="!!casePage.length"
                    v-model:currentPage="getSimplePageCaseParam.pageIndex"
                    v-model:page-size="getSimplePageCaseParam.pageSize"
                    :disabled="!getSimplePageCaseResult.totalCount"
                    layout="total, prev, pager, next"
                    :pager-count="5"
                    :total="getSimplePageCaseResult.totalCount"
                    @size-change="getSimplePageCase"
                    @current-change="getSimplePageCase"
                />
            </div>
            <div class="view-list-right flex-grow" style="min-width: 1100px;">
                <el-tabs type="border-card" style="min-height: 100%;" v-model="curTab" @tab-change="changeTab">
                    <el-tab-pane :label="facCase.id?'修改订单':'新增订单'" name="add">
                        <el-button v-if="!!queryId" :icon="Back" @click="handleBack" type="primary">返回</el-button>
                        <el-form :model="facCase" label-width="90px" ref='caseFormRef' :rules='caseSaveRules'
                                 style="min-width:1000px;max-width:1150px;" @submit.prevent class="compact-form"
                                 scroll-to-error
                                 v-loading='saveCaseLoading || caseGetLoading'>
                            <el-row :gutter="5">
                                <el-col :span="6">
                                    <el-form-item label="订单号" prop="caseNo" title="订单号不填写将按照订单设置规则自动生成">
                                        <el-input v-model.trim="facCase.caseNo" maxlength="50"
                                                  :readonly="!!facCase.id" placeholder="订单号不能修改"
                                                  ref="caseNoRef"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="内转盒号" prop="boxNo">
                                        <el-input v-model.trim="facCase.boxNo" maxlength="20"
                                                  :readonly=" !allowChange && !allowMod"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="客户单号" prop="hospitalCaseNo">
                                        <el-input v-model.trim="facCase.hospitalCaseNo" maxlength="20"
                                                  :readonly=" !allowChange && !allowMod"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="模型类型" prop="modelType">
                                        <el-select v-model="facCase.modelType" :disabled=" !allowChange && !allowMod">
                                            <el-option v-for="item in modelTypeList" :key="item.id" :value="item.id"
                                                       :label="item.name">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="5">
                                <el-col :span="6">
                                    <el-form-item label="关联订单号" prop="sourceCaseNo">
                                        <el-input v-model.trim="facCase.sourceCaseNo" maxlength="20"
                                                  @keydown.enter="getCaseInfoBySourceCaseNo"
                                                  :readonly=" !allowChange && !allowMod"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="18">
                                    <el-space>
                                        <el-form-item label="订单类型" prop="caseType">
                                            <el-radio-group v-model="facCase.caseType" class="radio-plain"
                                                            @change="changeCaseType"
                                                            :disabled=" !allowChange && !allowMod">
                                                <el-radio-button v-for="item in caseType" :key="item.id"
                                                                 :label="item.id">
                                                    {{ item.name }}
                                                </el-radio-button>
                                            </el-radio-group>
                                        </el-form-item>
                                        <el-form-item label="是否试戴" prop="isTry">
                                            <el-switch v-model="facCase.isTry" :active-value="1"
                                                       :disabled=" !allowChange && !allowMod"
                                                       :inactive-value="0"></el-switch>

                                            <el-select size="default" v-model="facCase.tryState" filterable
                                                       v-show="!!facCase.isTry" :disabled=" !allowChange && !allowMod"
                                                       @change="getTotalPrice"
                                                       class="input-small">
                                                <el-option v-for="item in tryState" :key="item.id" :value="item.id"
                                                           :label="item.name">
                                                </el-option>
                                            </el-select>
                                            <el-tag type="warning" v-if="facCase.orderSource===orderSourceEnum.业务员APP">
                                                员工app下单
                                            </el-tag>
                                            <el-tag type="danger" v-if="facCase.orderSource===orderSourceEnum.医生APP">
                                                医生app下单
                                            </el-tag>
                                        </el-form-item>
                                    </el-space>
                                </el-col>
                            </el-row>
                            <el-row :gutter="5">
                                <el-col :span="6">
                                    <el-tooltip content="请先选择客户" :visible="customerTipVisible">
                                        <el-form-item label="客户" prop="facHospitalId">
                                            <hospital-select v-model:hospital-id="facCase.facHospitalId"
                                                             :readonly=" !allowChange && !allowMod"
                                                             @change-hospital="changeCaseFacHospital"></hospital-select>
                                        </el-form-item>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="6" v-if="isCustomerFactory">
                                    <el-form-item label="医院" prop="realHospitalName">
                                        <el-input v-model.trim="facCase.realHospitalName" maxlength="50"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="医生" prop="facDoctorName" v-show="isCustomerFactory">
                                        <el-input v-model.trim="facCase.facDoctorName" maxlength="50"></el-input>
                                    </el-form-item>
                                    <el-form-item label="医生" prop="facDoctorId" v-show="!isCustomerFactory">
                                        <doctor-select v-model:doctor-id="facCase.facDoctorId"
                                                       :disabled=" !allowChange && !allowMod"
                                                       :hospital-id="facCase.facHospitalId"
                                                       @change-doctor="changeCaseFacDoctor(false)"></doctor-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="销售员" prop="facWorkerId">
                                        <worker-select v-model:worker-id="facCase.facWorkerId"
                                                       :readonly=" !allowChange && !allowMod"></worker-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="5">
                                <el-col :span="6">
                                    <el-form-item label="患者姓名" prop="patientName">
                                        <el-input v-model.trim="facCase.patientName" maxlength="50"
                                                  :readonly=" !allowChange && !allowMod">
                                            <template #append>
                                                <el-popover placement="right" :width="300" trigger="click">
                                                    <template #reference>
                                                        <el-button>>></el-button>
                                                    </template>
                                                    <el-form-item label="患者性别">
                                                        <el-radio-group v-model="facCase.gender">
                                                            <el-radio label="男"></el-radio>
                                                            <el-radio label="女"></el-radio>
                                                        </el-radio-group>
                                                    </el-form-item>
                                                    <el-form-item label="患者年龄">
                                                        <el-input v-only-int v-model.trim="facCase.patientAge"
                                                                  class="w-sm"
                                                                  :maxlength="3"></el-input>
                                                    </el-form-item>
                                                </el-popover>
                                            </template>
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label-width="10px">
                                        <el-space :size="0">
                                            <el-checkbox-button v-model="facCase.isUrgent" label="加急" :true-label="1"
                                                                :false-label="0"
                                                                :disabled=" !allowChange && !allowMod"/>
                                            <el-checkbox-button v-model="facCase.isPrivate" label="私人" :true-label="1"
                                                                :false-label="0" @change="changeCaseFacDoctor(true)"
                                                                :disabled=" !allowChange && !allowMod"/>
                                            <el-checkbox-button v-model="facCase.isOralScan" label="口扫" :true-label="1"
                                                                :false-label="0"
                                                                :disabled=" !allowChange && !allowMod"/>
                                            <el-checkbox-button v-model="facCase.isImportant" label="重要" :true-label="1"
                                                                :false-label="0"
                                                                :disabled=" !allowChange && !allowMod"/>
                                        </el-space>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="代理人" prop="agentId">
                                        <el-select size="default" v-model="facCase.agentId" filterable
                                                   :disabled=" !allowChange && !allowMod">
                                            <el-option v-for="item in facAgentList" :key="item.id" :value="item.id"
                                                       :label="item.name">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="片区" prop="district">
                                        <el-select v-model="facCase.district" size="default"
                                                   :disabled=" !allowChange && !allowMod"
                                                   filterable>
                                            <el-option v-for="item in district" :key="item.id" :value="item.name"
                                                       :label="item.name"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="5">
                                <el-col :span="6">
                                    <el-form-item label="入件日期" prop="inDate">
                                        <el-date-picker type="date" v-model="facCase.inDate"
                                                        :readonly=" !allowChange && !allowMod"
                                                        @change="calScheduleDeliveryDate"
                                                        size="default"></el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="生产天数" prop="makeDays">
                                        <el-input-number :min="0" :max="999" v-model="facCase.makeDays"
                                                         @change="calScheduleDeliveryDate"></el-input-number>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="预计出件" prop="scheduleDeliveryDate">
                                        <el-date-picker type="date" v-model="facCase.scheduleDeliveryDate"
                                                        size="default"></el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="戴牙日期" prop="wearDate">
                                        <el-date-picker type="date" v-model="facCase.wearDate"
                                                        :readonly=" !allowChange && !allowMod"
                                                        :shortcuts="dateShortcuts"></el-date-picker>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="5">
                                <el-col :span="12">
                                    <el-form-item label="医生习惯" prop="doctorHabit">
                                        <el-input v-model.trim="facCase.doctorHabit" maxlength="200"
                                                  :readonly=" !allowChange && !allowMod"
                                                  placeholder="根据医生设置自动填充"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="医生备注" prop="doctorRemark">
                                        <el-input v-model.trim="facCase.doctorRemark" maxlength="200"
                                                  :readonly=" !allowChange && !allowMod"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="5">
                                <el-col :span="12">
                                    <el-form-item label="工厂备注" prop="factoryRemark">
                                        <el-input v-model.trim="facCase.factoryRemark" maxlength="200"
                                                  :readonly=" !allowChange && !allowMod"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="生产备注" prop="stepNote">
                                        <el-input v-model.trim="facCase.stepNote" maxlength="200"
                                                  :readonly=" !allowChange && !allowMod"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="5">
                                <el-col :span="24">
                                    <el-table style="min-width: 1050px;" :data="facCase.productList" :border="true"
                                              show-summary :summary-method="productSummaryMethod">
                                        <el-table-column label="操作" width="75" class-name="compact-cell">
                                            <template #default="scope">
                                                <el-button-group v-show="allowChange || allowMod">
                                                    <el-button plain :icon="Plus" type="primary" size="small"
                                                               title="添加产品"
                                                               @click="newProduct"></el-button>
                                                    <el-popconfirm confirm-button-text="确认" cancel-button-text="取消"
                                                                   title="确认删除吗"
                                                                   @confirm="deleteProduct(scope.row,scope.$index)">
                                                        <template #reference>
                                                            <el-button plain :icon="Delete" type="danger"
                                                                       size="small"></el-button>
                                                        </template>
                                                    </el-popconfirm>
                                                </el-button-group>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="产品" width="200" label-class-name="text-danger"
                                                         class-name="compact-cell">
                                            <template #default="scope">
                                                <el-tag v-if="!facCase.facHospitalId">请选择客户</el-tag>
                                                <product-select v-model:product-id="scope.row.productId"
                                                                v-else-if="allowChange || allowMod"
                                                                is-textarea
                                                                :key="scope.$index" :product-list="storeProductList"
                                                                @change-product="changeCaseProduct(scope.row)"></product-select>
                                                <span v-else>{{ scope.row.productName }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="牙色" min-width="80" class-name="compact-cell">
                                            <template #default="scope">
                                                <el-autocomplete :readonly=" !allowChange && !allowMod"
                                                                 v-model.trim="scope.row.color"
                                                                 :fetch-suggestions="searchColor"
                                                                 value-key="color"
                                                                 clearable
                                                />

                                                <el-button type="primary" size="small" plain
                                                           @click="showProductPic(scope.row)">
                                                    {{ scope.row.fileId ? '查看图片' : '选择图片' }}
                                                </el-button>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="牙位" width="385" class-name="compact-cell"
                                                         align="center">
                                            <template #default="scope">
                                                <table-tooth-input
                                                    :key="scope.$index"
                                                    v-if="allowChange || allowMod"
                                                    v-model:pos1="scope.row.pos1"
                                                    v-model:pos2="scope.row.pos2"
                                                    v-model:pos3="scope.row.pos3"
                                                    v-model:pos4="scope.row.pos4"
                                                    v-model:tooth-type="scope.row.toothType"
                                                    v-model:lower-jaw="scope.row.lowerJaw"
                                                    v-model:upper-jaw="scope.row.upperJaw"
                                                    v-model:complete="scope.row.complete"
                                                    v-model:pontic="scope.row.pontic"
                                                    v-model:missing1="scope.row.missing1"
                                                    v-model:missing2="scope.row.missing2"
                                                    v-model:missing3="scope.row.missing3"
                                                    v-model:missing4="scope.row.missing4"
                                                    @change-tooth="val=>setProductNum(val,scope.row)"
                                                />
                                                <table-tooth
                                                    v-else
                                                    :key="scope.$index"
                                                    :pos1="scope.row.pos1"
                                                    :pos2="scope.row.pos2"
                                                    :pos3="scope.row.pos3"
                                                    :pos4="scope.row.pos4"
                                                    :upper-jaw="scope.row.upperJaw"
                                                    :lower-jaw="scope.row.lowerJaw"
                                                    :complete="scope.row.complete"
                                                    :tooth-type="scope.row.toothType"
                                                    :pontic="scope.row.pontic"
                                                    :missing1="scope.row.missing1"
                                                    :missing2="scope.row.missing2"
                                                    :missing3="scope.row.missing3"
                                                    :missing4="scope.row.missing4"
                                                />
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="数量" min-width="58" label-class-name="text-danger"
                                                         class-name="compact-cell">
                                            <template #default="scope">
                                                <el-input v-only-int v-model.number="scope.row.num"
                                                          @blur="calCaseTotalPrice(facCase)"
                                                          :readonly=" !allowChange && !allowMod"
                                                          maxlength="8"></el-input>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="单价" width="65" label-class-name="text-danger"
                                                         class-name="compact-cell">
                                            <template #default="scope">
                                                <el-tooltip content="按回车键可以自动改为最新的价格" v-if="priceAuth">
                                                    <el-input v-only-number v-model="scope.row.price"
                                                              @blur="calCaseTotalPrice(facCase)"
                                                              @keydown.enter="handleChangeCaseProductPrice(scope.row)"
                                                              :readonly=" !allowChange && !allowMod"
                                                              maxlength="8"></el-input>
                                                </el-tooltip>
                                                <span v-if="viewPriceAuth && !priceAuth">{{ scope.row.price }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="折扣" width="90" class-name="compact-cell">
                                            <template #default="scope">
                                                <el-space :size="0" v-if="priceAuth">
                                                    <el-input v-only-number v-model="scope.row.discount"
                                                              maxlength="8" :readonly=" !allowChange && !allowMod"
                                                              @keydown.enter="calCaseTotalPrice(facCase)"
                                                              @blur="calCaseTotalPrice(facCase)">
                                                    </el-input>
                                                    <el-tag
                                                        @click="changeDiscountType(scope.row,scope.row.discountType===0?1:0)">
                                                        {{ scope.row.discountType === 0 ? '%' : '¥' }}
                                                    </el-tag>
                                                </el-space>
                                                <span v-if="viewPriceAuth && !priceAuth">{{
                                                        scope.row.discount + (scope.row.discountType === 0 ?
                                                            '%' : '')
                                                    }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="小计" min-width="70" prop="sum"
                                                         v-if="viewPriceAuth || priceAuth"
                                                         label-class-name="text-danger">
                                        </el-table-column>
                                        <el-table-column label="优惠" min-width="70" prop="benefit"
                                                         v-if="viewPriceAuth || priceAuth">
                                        </el-table-column>
                                    </el-table>
                                </el-col>
                            </el-row>
                            <el-row :gutter="5">
                                <el-col :span="24">
                                    <el-tabs type="border-card" v-model="caseTab" style="min-height: 300px;">
                                        <el-tab-pane name="pic">
                                            <template #label>
                                                <span>图片</span>
                                                <span class="text-danger"
                                                      v-if="!!picList.length">[{{ picList.length }}]</span>
                                            </template>
                                            <el-space alignment="flex-start">
                                                <el-upload
                                                    multiple
                                                    action="#"
                                                    v-model:file-list="picList"
                                                    :limit="maxPicNum"
                                                    :auto-upload="true"
                                                    :before-upload="beforePicUpload"
                                                    :http-request="uploadFile"
                                                    :on-preview="previewPic"
                                                    :on-remove="removePic"
                                                    :on-exceed="casePicExceed">
                                                    <el-button type="primary" plain v-show="allowChange"
                                                               :icon="Picture">选择图片
                                                    </el-button>
                                                </el-upload>

                                                <div class="flex-col">
                                                    <el-checkbox border v-model="autoTakePic"
                                                                 @change="changeAutoTakePic"
                                                                 label="新增订单后自动拍照"></el-checkbox>
                                                    <el-input v-model.trim="liangtianSetting.baseUrl" maxlength="50"
                                                              placeholder="请输入设备web服务地址并回车" title="请输入设备web服务地址并回车"
                                                              @keydown.enter="changeLiangtianSetting">
                                                        <template #append>
                                                            <el-button :icon="Refresh" title="复原设备地址"
                                                                       @click="resetBaseUrl"
                                                                       size="small"></el-button>
                                                        </template>
                                                    </el-input>
                                                    <el-space :size="1">
                                                        <el-button title="刷新高拍仪设备和镜头状态" :icon="Refresh"
                                                                   @click="getDeviceInfo">刷新设备状态
                                                        </el-button>
                                                        <el-button title="高拍仪镜头向左旋转" @click="rotate(90)">镜头左转
                                                        </el-button>
                                                        <el-button title="高拍仪镜头向右旋转" @click="rotate(270)">镜头右转
                                                        </el-button>
                                                    </el-space>
                                                    <el-descriptions border :column="2">
                                                        <el-descriptions-item label="设备状态">
                                                            {{ device.isConnect }}
                                                        </el-descriptions-item>
                                                        <el-descriptions-item label="设备型号">
                                                            {{ device.version }}
                                                        </el-descriptions-item>
                                                        <el-descriptions-item label="主摄状态">
                                                            {{ device.videoStatus }}
                                                        </el-descriptions-item>
                                                    </el-descriptions>
                                                </div>
                                                <img id="view1" alt="主摄像头" v-show="device.isConnect==='已连接'">
                                                <el-image class="scan-view" v-if="device.isConnect!=='已连接'"/>
                                            </el-space>

                                        </el-tab-pane>
                                        <el-tab-pane name="attach">
                                            <template #label>
                                                <span>附件</span>
                                                <span class="text-danger"
                                                      v-show="!!attachNum">[{{ attachNum }}]</span>
                                            </template>
                                            <el-table :data="facCase.attachList" max-height="400px">
                                                <el-table-column label="操作" width="110">
                                                    <template #default="scope">
                                                        <el-button-group v-show="allowChange">
                                                            <el-button plain :icon="Plus" type="primary"
                                                                       size="small" title="添加附件"
                                                                       @click="addAttach"></el-button>
                                                            <el-popconfirm confirm-button-text="确认"
                                                                           cancel-button-text="取消"
                                                                           title="确认删除吗"
                                                                           @confirm="deleteAttach(scope.$index)">
                                                                <template #reference>
                                                                    <el-button plain :icon="Delete" type="danger"
                                                                               size="small"></el-button>
                                                                </template>
                                                            </el-popconfirm>
                                                        </el-button-group>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column label="附件" width="200">
                                                    <template #default="scope">
                                                        <el-select v-model="scope.row.name" filterable
                                                                   @change="changeAttach(scope.row)"
                                                                   :disabled=" !allowChange && !allowMod">
                                                            <el-option v-for="item in attachList" :key="item.id"
                                                                       :value="item.name"></el-option>
                                                        </el-select>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column label="数量" width="110">
                                                    <template #default="scope">
                                                        <el-input v-only-int v-model.number="scope.row.num"
                                                                  :disabled="!scope.row.name ||  !allowChange && !allowMod"></el-input>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column label="归属" width="200" prop="belong"
                                                                 :formatter="(r,c,v)=>enumTypeFormatter(v,attachBelongList)">
                                                </el-table-column>
                                            </el-table>
                                        </el-tab-pane>
                                        <el-tab-pane name="sendType" label="发货信息">
                                            <el-form-item label="发货方式">
                                                <el-radio-group v-model="caseExpress.sendType">
                                                    <el-radio v-for="item in sendType" :key="item.id"
                                                              :label="item.id">
                                                        {{ item.name }}
                                                    </el-radio>
                                                </el-radio-group>
                                            </el-form-item>
                                            <el-form-item label-width="30px"
                                                          v-if="caseExpress.sendType===sendTypeEnum.物流">
                                                <el-space>
                                                    <span>联系人</span>
                                                    <el-input class="w-120" v-model.trim="caseExpress.shipPerson"
                                                              maxlength="50"></el-input>
                                                    <span>联系电话</span>
                                                    <el-input class="w-120" v-model.trim="caseExpress.shipPhoneNo"
                                                              maxlength="50"></el-input>
                                                    <span>物流方式</span>
                                                    <el-input class="input-medium"
                                                              v-model.trim="caseExpress.shipRemark"
                                                              maxlength="50"></el-input>
                                                </el-space>
                                            </el-form-item>
                                            <el-form-item label="收货方式">
                                                <el-radio-group v-model="caseExpress.receiverType"
                                                                @change="changeCaseReceiverType">
                                                    <el-radio v-for="item in receiverType" :key="item.id"
                                                              :label="item.id">{{ item.name }}
                                                    </el-radio>
                                                </el-radio-group>
                                            </el-form-item>
                                            <el-form-item label="收货人">
                                                <el-space>
                                                    <el-input class="input-small"
                                                              v-model.trim="caseExpress.contactPerson"
                                                              maxlength="50"></el-input>
                                                    <span>收货电话</span>
                                                    <el-input class="w-150"
                                                              v-model.trim="caseExpress.contactPhoneNo"
                                                              maxlength="50"></el-input>
                                                </el-space>
                                            </el-form-item>
                                            <el-form-item label="收货地址">
                                                <el-space :size="0">
                                                    <el-select v-model="caseExpress.province" filterable
                                                               placeholder="省"
                                                               @change="changeProvince" class="input-small">
                                                        <el-option v-for="item in provinceList"
                                                                   :key="item.code"
                                                                   :value="item.name">
                                                        </el-option>
                                                    </el-select>
                                                    <el-select v-model="caseExpress.city" filterable
                                                               placeholder="市"
                                                               @change="changeCity" class="input-small">
                                                        <el-option v-for="item in cityList" :key="item.code"
                                                                   :value="item.name">
                                                        </el-option>
                                                    </el-select>
                                                    <el-select v-model="caseExpress.area" filterable
                                                               placeholder="区"
                                                               class="input-small">
                                                        <el-option v-for="item in areaList" :key="item.code"
                                                                   :value="item.name">
                                                        </el-option>
                                                    </el-select>
                                                    <el-input v-model.trim="caseExpress.address"
                                                              maxlength="100"
                                                              class="input-large"
                                                              placeholder="请输入详细地址"></el-input>
                                                </el-space>
                                            </el-form-item>
                                            <el-form-item label="快递公司">
                                                <el-space>
                                                    <el-select size="default"
                                                               v-model="caseExpress.expressCompany"
                                                               filterable
                                                               class="input-medium">
                                                        <el-option v-for="item in expressCompany" :key="item.id"
                                                                   :value="item.name" :label="item.name">
                                                        </el-option>
                                                    </el-select>
                                                    <el-checkbox :true-label="1" :false-label="0"
                                                                 v-model="caseExpress.isCollect"
                                                                 v-if="caseExpress.sendType===sendTypeEnum.快递公司">
                                                        到付
                                                    </el-checkbox>
                                                </el-space>
                                            </el-form-item>
                                        </el-tab-pane>
                                    </el-tabs>
                                </el-col>
                            </el-row>
                        </el-form>
                        <el-row :gutter="5">
                            <el-col :span="24" style="padding-top:10px;">
                                <el-tag v-if="facCase.isValid===0" effect="dark" type="danger" size="large">已作废</el-tag>
                                <el-tag v-if="caseInfo && caseInfo.extension && caseInfo.extension.outDate"
                                        size="large" type="danger">
                                    出件日期:{{ moment(caseInfo.extension.outDate).format('yyyy-MM-DD') }}
                                </el-tag>
                                <el-button type="primary" :icon="Edit" @click="handleSaveCase"
                                           v-show="allowChange && hasAuth(funcAuth.保存订单)"
                                           :loading="saveCaseLoading">保存信息
                                </el-button>
                                <el-button type="primary" :icon="Edit" @click="handleSaveCase"
                                           v-show="allowMod && hasAuth(funcAuth.更正订单) && !isFinanceAudit"
                                           :loading="saveCaseLoading">更正
                                </el-button>
                                <el-button type="primary" :icon="Edit" @click="handleSaveFinanceAudit"
                                           v-if="!(facCase.extension && facCase.extension.monthId)"
                                           v-show="hasAuth(funcAuth.财务弃审) && isFinanceAudit"
                                           :loading="updateFinanceAuditCaseLoading">财务弃审
                                </el-button>
                                <el-button type="success" @click="showOutCase"
                                           v-show="!!facCase.id && !!facCase.isValid && !isCaseOut && hasAuth(funcAuth.出货)"
                                           :icon="Van">
                                    出货
                                </el-button>
                                <!--                                <el-popconfirm confirm-button-text="确认" cancel-button-text="取消"-->
                                <!--                                               title="确认取消吗"-->
                                <!--                                               @confirm="handleCancelCasePack">-->
                                <!--                                    <template #reference>-->
                                <!--                                        <el-button type="warning" plain :loading="cancelCaseOutLoading"-->
                                <!--                                                   v-show="facCase.id && facCase.isValid && facCase.caseState===caseStateEnum.已装箱-->
                                <!--                                                    && hasAuth(funcAuth.取消出货)"-->
                                <!--                                                   :icon="Delete">-->
                                <!--                                            取消装箱-->
                                <!--                                        </el-button>-->
                                <!--                                    </template>-->
                                <!--                                </el-popconfirm>-->

                                <el-popconfirm confirm-button-text="确认" cancel-button-text="取消"
                                               title="确认取消吗"
                                               @confirm="handleCancelCaseOut">
                                    <template #reference>
                                        <el-button type="success" plain :loading="cancelCaseOutLoading"
                                                   v-show="!!facCase.id && !!facCase.isValid && isCaseOut && hasAuth(funcAuth.取消出货) && !isFinanceAudit"
                                                   :icon="Back">
                                            取消出货
                                        </el-button>
                                    </template>
                                </el-popconfirm>

                                <el-button type="danger" @click="showReturnCase" plain
                                           v-show="!!facCase.id && !!facCase.isValid && isCaseOut && hasAuth(funcAuth.退货) && !isFinanceAudit"
                                           :icon="Van">
                                    退货
                                </el-button>
                                <el-button type="danger" @click="showValidCase" plain
                                           v-show="!!facCase.id && allowChange && facCase.caseState<=caseStateEnum.加工完成 && hasAuth(funcAuth.作废订单)"
                                           :icon="Delete">
                                    作废
                                </el-button>
                                <el-popconfirm confirm-button-text="确认" cancel-button-text="取消" title="确认取消作废吗"
                                               @confirm="handleCancelValidCase"
                                               :disabled="cancelValidCaseLoading"
                                >
                                    <template #reference>
                                        <el-button type="danger" :loading="cancelValidCaseLoading" plain
                                                   v-show="allowCancelValid&&hasAuth(funcAuth.作废订单)">
                                            取消作废
                                        </el-button>
                                    </template>
                                </el-popconfirm>
                                <el-button @click="newCase" :icon="Plus" type="success" v-if="!!queryId">
                                    新增
                                </el-button>
                                <span v-if="facCase.extension && facCase.extension.monthId" class="text-danger">
                                        订单已对账，不能再修改
                                </span>
                            </el-col>
                        </el-row>
                        <el-row :gutter="5">
                            <el-col :span="24" style="padding-top:10px;">
                                <el-button @click="printBarcode" :icon="Printer" type="info" plain
                                           :loading="printSingleLoading || printListLoading"
                                           v-show="!!facCase.id && !!facCase.isValid && hasAuth(funcAuth.打印订单)">
                                    条码
                                </el-button>
                                <el-button @click="showPrintCaseOut"
                                           v-show="!!facCase.id && !!facCase.isValid && isCaseOut && hasAuth(funcAuth.打印订单)"
                                           :icon="Printer" :loading="printSingleLoading || printListLoading"
                                           type="primary"
                                           plain>
                                    出货单
                                </el-button>
                                <el-button @click="showPrintWarranty"
                                           v-show="!!facCase.id && !!facCase.isValid && isCaseOut && hasAuth(funcAuth.打印订单)"
                                           :icon="Printer"
                                           type="success" :loading="printListLoading || printListLoading"
                                           plain>
                                    质保卡
                                </el-button>
                                <el-button @click="showPrintConfirm"
                                           v-show="!!facCase.id && !!facCase.isValid && hasAuth(funcAuth.打印订单)"
                                           :icon="Printer" :loading="printSingleLoading || printListLoading"
                                           type="warning"
                                           plain>
                                    确认单
                                </el-button>
                                <el-space :size="0">
                                    <el-select v-model="labelProductId" placeholder="选择标签订单产品"
                                               v-if="!!facCase.id && !!facCase.isValid && isCaseOut && labelTemplateList.length && hasAuth(funcAuth.打印订单)"
                                               class="w-120">
                                        <el-option v-for="product in facCase.productList" :key="product.productId"
                                                   :value="product.productId"
                                                   :label="product.productName"></el-option>
                                    </el-select>
                                    <el-button v-for="item in labelTemplateList" :key="item.id"
                                               @click="handlePrintCaseLabel(item.id)"
                                               v-show="!!facCase.id && !!facCase.isValid && isCaseOut && hasAuth(funcAuth.打印订单)">
                                        {{ item.name }}
                                    </el-button>
                                </el-space>
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                    <el-tab-pane label="操作历史" name="history" :disabled="!facCase.id" v-if="priceAuth">
                        <template #label>
                            <el-tooltip effect='light' placement='top' content="请先在左侧列表选择订单">
                                操作历史
                            </el-tooltip>
                        </template>
                        <case-history v-if="curTab==='history'" :id="facCase.id"></case-history>
                    </el-tab-pane>
                    <el-tab-pane label="生产流程" name="step" :disabled="!facCase.id">
                        <template #label>
                            <el-tooltip effect='light' placement='top' content="请先在左侧列表选择订单">
                                生产流程
                            </el-tooltip>
                        </template>
                        <el-descriptions :column="4" border>
                            <el-descriptions-item width="12.5%" label="订单号">
                                {{ facCase.caseNo }}
                            </el-descriptions-item>
                            <el-descriptions-item width="12.5%" label="客户">
                                {{ facCase.facHospitalName }}
                            </el-descriptions-item>
                            <el-descriptions-item width="12.5%" label="医生">
                                {{ facCase.facDoctorName }}
                            </el-descriptions-item>
                            <el-descriptions-item label="患者">
                                {{ facCase.patientName }}
                            </el-descriptions-item>
                        </el-descriptions>
                        <case-step :id="facCase.id"></case-step>
                    </el-tab-pane>
                    <el-tab-pane label="快递信息" name="express" :disabled="!facCase.id">
                        <template #label>
                            <el-tooltip effect='light' placement='top' content="请先在左侧列表选择订单">
                                快递信息
                            </el-tooltip>
                        </template>
                        <el-space style="margin-bottom:10px;padding-left:10px; ">
                            <el-tag size="large">{{ facCase.caseNo }}</el-tag>
                            <el-tag size="large">{{ facCase.facHospitalName }}</el-tag>
                            <el-tag size="large">{{ facCase.facDoctorName }}</el-tag>
                            <el-button type="success" title="自动获取客户和科室的收货数据并填充" @click="initCaseSendType">自动获取发货与收货方式
                            </el-button>
                        </el-space>
                        <el-form :model="caseExpress" label-width="100px">
                            <el-form-item label="发货方式">
                                <el-radio-group v-model="caseExpress.sendType">
                                    <el-radio v-for="item in sendType" :key="item.id"
                                              :label="item.id">
                                        {{ item.name }}
                                    </el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label-width="30px" v-if="caseExpress.sendType===sendTypeEnum.物流">
                                <el-space>
                                    <span>联系人</span>
                                    <el-input class="w-120" v-model.trim="caseExpress.shipPerson"
                                              maxlength="50"></el-input>
                                    <span>联系电话</span>
                                    <el-input class="w-120" v-model.trim="caseExpress.shipPhoneNo"
                                              maxlength="50"></el-input>
                                    <span>物流方式</span>
                                    <el-input class="input-medium" v-model.trim="caseExpress.shipRemark"
                                              maxlength="50"></el-input>
                                </el-space>
                            </el-form-item>
                            <el-form-item label="收货方式">
                                <el-space>
                                    <el-radio-group v-model="caseExpress.receiverType" @change="changeCaseReceiverType">
                                        <el-radio v-for="item in receiverType" :key="item.id"
                                                  :label="item.id">{{ item.name }}
                                        </el-radio>
                                    </el-radio-group>
                                    <span>收货人</span>
                                    <el-input class="input-small" v-model.trim="caseExpress.contactPerson"
                                              maxlength="50"></el-input>
                                    <span>收货电话</span>
                                    <el-input class="w-150" v-model.trim="caseExpress.contactPhoneNo"
                                              maxlength="50"></el-input>
                                </el-space>
                            </el-form-item>
                            <el-form-item label="收货地址">
                                <el-space>
                                    <el-space :size="0">
                                        <el-select v-model="caseExpress.province" filterable placeholder="省"
                                                   @change="changeProvince" class="input-small">
                                            <el-option v-for="item in provinceList" :key="item.code" :value="item.name">
                                            </el-option>
                                        </el-select>
                                        <el-select v-model="caseExpress.city" filterable placeholder="市"
                                                   @change="changeCity" class="input-small">
                                            <el-option v-for="item in cityList" :key="item.code" :value="item.name">
                                            </el-option>
                                        </el-select>
                                        <el-select v-model="caseExpress.area" filterable placeholder="区"
                                                   class="input-small">
                                            <el-option v-for="item in areaList" :key="item.code" :value="item.name">
                                            </el-option>
                                        </el-select>
                                        <el-input v-model.trim="caseExpress.address" maxlength="100" class="input-large"
                                                  placeholder="请输入详细地址"></el-input>
                                    </el-space>
                                </el-space>
                            </el-form-item>
                            <el-form-item label="快递公司">
                                <el-space>
                                    <el-select size="default" v-model="caseExpress.expressCompany" filterable
                                               class="input-medium">
                                        <el-option v-for="item in expressCompany" :key="item.id"
                                                   :value="item.name" :label="item.name">
                                        </el-option>
                                    </el-select>
                                    <el-checkbox :true-label="1" :false-label="0"
                                                 v-model="caseExpress.isCollect"
                                                 v-if="caseExpress.sendType===sendTypeEnum.快递公司">到付
                                    </el-checkbox>
                                </el-space>
                            </el-form-item>
                            <el-form-item label="快递单号">
                                <el-input maxlength="100" v-model.trim="caseExpress.expressNo"
                                          class="input-large"></el-input>
                            </el-form-item>
                            <el-form-item label="产品数量">
                                <el-input maxlength="3" v-model.trim="caseExpress.num" v-only-int
                                          class="input-medium"></el-input>
                            </el-form-item>
                            <el-form-item label="快递发件日期">
                                <el-date-picker type="date" v-model="caseExpress.pickUpDate" class="input-medium"
                                                size="default"></el-date-picker>
                            </el-form-item>
                            <el-form-item>
                                <el-button :icon="Edit" @click="saveCaseExpress" :loading="saveCaseExpressLoading"
                                           v-show="hasAuth(funcAuth.保存快递信息)"
                                           type="primary">保存信息
                                </el-button>
                                <el-button :icon="Search" @click="handleSearchExpress" :loading="getCaseRoutesLoading"
                                           v-show="facCase.id && facCase.express.expressNo && facCase.express.expressCompany && facCase.express.expressCompany.includes('顺丰')"
                                           type="danger">查快递
                                </el-button>
                            </el-form-item>
                        </el-form>
                        <el-timeline>
                            <el-timeline-item
                                v-for="(item, index) in caseRoutes.routeList"
                                :key="index"
                                :timestamp="item.acceptTime"
                            >
                                <el-tag>{{ item.acceptAddress }}</el-tag>
                                {{ item.remark }}
                            </el-timeline-item>
                        </el-timeline>
                    </el-tab-pane>
                    <el-tab-pane label="问单" name="question" :disabled="!facCase.id">
                        <template #label>
                            <el-tooltip effect='light' placement='top' content="请先在左侧列表选择订单">
                                问单
                            </el-tooltip>
                        </template>
                        <el-descriptions :column="4" border>
                            <el-descriptions-item width="12.5%" label="订单号">
                                {{ facCase.caseNo }}
                            </el-descriptions-item>
                            <el-descriptions-item width="12.5%" label="客户">
                                {{ facCase.facHospitalName }}
                            </el-descriptions-item>
                            <el-descriptions-item width="12.5%" label="医生">
                                {{ facCase.facDoctorName }}
                            </el-descriptions-item>
                            <el-descriptions-item label="患者">
                                {{ facCase.patientName }}
                            </el-descriptions-item>
                        </el-descriptions>
                        <case-question :id="facCase.id"></case-question>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
        <div v-show="false">
            <!--            打印确认单产品 -->
            <table style="width:100%;" class="hiprint-table" ref="printConfirmDetailTableRef">
                <tr>
                    <td v-for="col in confirmPrintColumns" :style="{width:col.widthPer}">
                        {{ col.colTitle }}
                    </td>
                </tr>
                <tr v-for="product in caseInfo.productList">
                    <td v-for="col in confirmPrintColumns">
                        <span v-if="col.colField==='color'">{{ product.color }}</span>
                        <span v-if="col.colField==='productName'">{{ product.productName }}</span>
                        <span v-if="col.colField==='num'">{{ product.num }}</span>
                        <print-tooth v-if="col.colField==='toothNo'"
                                     :key="product.id"
                                     :pos1="product.pos1"
                                     :pos2="product.pos2"
                                     :pos3="product.pos3"
                                     :pos4="product.pos4"
                                     :upper-jaw="product.upperJaw"
                                     :lower-jaw="product.lowerJaw"
                                     :tooth-type="product.toothType"
                                     :pontic="product.pontic"
                                     :missing1="product.missing1"
                                     :missing2="product.missing2"
                                     :missing3="product.missing3"
                                     :missing4="product.missing4"
                        />
                    </td>
                </tr>
            </table>
            <!--            打印确器械参数 -->
            <table style="width:100%;" class="hiprint-table" ref="plantPrintTableRef">
                <tr>
                    <td v-for="col in plantPrintColumns" :style="{width:col.widthPer}">
                        {{ col.colTitle }}
                    </td>
                </tr>
                <tr v-for="product in caseInfo.plantList">
                    <td v-for="col in plantPrintColumns">
                        <span v-if="col.colField==='plantSystem'">{{ product.plantSystem }}</span>
                        <span v-if="col.colField==='plantRetention'">{{ product.plantRetention }}</span>
                        <print-tooth v-if="col.colField==='plantToothNo'"
                                     :key="product.id"
                                     :pos1="product.pos1"
                                     :pos2="product.pos2"
                                     :pos3="product.pos3"
                                     :pos4="product.pos4"
                                     :upper-jaw="0"
                                     :lower-jaw="0"
                                     :tooth-type="1"
                        />
                    </td>
                </tr>
            </table>
        </div>

        <el-dialog v-model="validCaseVisible" title="作废订单" width="500px">
            <el-alert type="error" :closable="false">订单一单作废，无法恢复，请慎重操作</el-alert>
            <el-form style="margin-top:10px;">
                <el-form-item label="作废原因" required>
                    <el-input v-model.trim="validCaseParam.validReason" maxlength="200" clearable></el-input>
                </el-form-item>
            </el-form>
            <footer>
                <div style="width: 100%;display: flex;padding-top:10px;">
                    <div class="flex-grow"></div>
                    <el-button type="primary" :icon="Delete" @click="handleValidCase" :loading="validCaseLoading">
                        确认作废
                    </el-button>
                </div>
            </footer>
        </el-dialog>
        <el-dialog v-model="outCaseVisible" title="订单发货" width="900px">
            <el-form style="margin-top:10px;">
                <el-form-item label="发货日期">
                    <el-date-picker type="date" v-model="caseOutParam.outDate" :clearable="false"
                                    class='input-medium'></el-date-picker>
                </el-form-item>
                <el-form-item label="发货方式">
                    <el-space direction="vertical" alignment="start">
                        <el-radio-group v-model="caseExpress.sendType">
                            <el-radio v-for="item in sendType" :key="item.id"
                                      :label="item.id">
                                {{ item.name }}
                            </el-radio>
                        </el-radio-group>

                        <el-space v-if="caseExpress.sendType===sendTypeEnum.物流">
                            <span>联系人</span>
                            <el-input class="w-120" v-model.trim="caseExpress.shipPerson"
                                      maxlength="50"></el-input>
                            <span>联系电话</span>
                            <el-input class="w-120" v-model.trim="caseExpress.shipPhoneNo"
                                      maxlength="50"></el-input>
                            <span>物流方式</span>
                            <el-input class="input-medium" v-model.trim="caseExpress.shipRemark"
                                      maxlength="50"></el-input>
                        </el-space>
                    </el-space>
                </el-form-item>
                <el-form-item label="收货方式">
                    <el-space>
                        <el-radio-group v-model="caseExpress.receiverType" @change="changeCaseReceiverType">
                            <el-radio v-for="item in receiverType" :key="item.id"
                                      :label="item.id">{{ item.name }}
                            </el-radio>
                        </el-radio-group>
                        <span>收货人</span>
                        <el-input class="input-small" v-model.trim="caseExpress.contactPerson"
                                  maxlength="50"></el-input>
                        <span>收货电话</span>
                        <el-input class="w-150" v-model.trim="caseExpress.contactPhoneNo"
                                  maxlength="50"></el-input>
                    </el-space>
                </el-form-item>
                <el-form-item label="收货地址">
                    <el-space>
                        <el-space :size="0">
                            <el-select v-model="caseExpress.province" filterable placeholder="省"
                                       @change="changeProvince" class="input-small">
                                <el-option v-for="item in provinceList" :key="item.code" :value="item.name">
                                </el-option>
                            </el-select>
                            <el-select v-model="caseExpress.city" filterable placeholder="市"
                                       @change="changeCity" class="input-small">
                                <el-option v-for="item in cityList" :key="item.code" :value="item.name">
                                </el-option>
                            </el-select>
                            <el-select v-model="caseExpress.area" filterable placeholder="区"
                                       class="input-small">
                                <el-option v-for="item in areaList" :key="item.code" :value="item.name">
                                </el-option>
                            </el-select>
                            <el-input v-model.trim="caseExpress.address" maxlength="100" class="input-large"
                                      placeholder="请输入详细地址"></el-input>
                        </el-space>
                    </el-space>
                </el-form-item>
                <el-form-item label="快递公司">
                    <el-space>
                        <el-select size="default" v-model="caseExpress.expressCompany" filterable
                                   class="input-medium">
                            <el-option v-for="item in expressCompany" :key="item.id"
                                       :value="item.name" :label="item.name">
                            </el-option>
                        </el-select>
                        <el-checkbox :true-label="1" :false-label="0"
                                     v-model="caseExpress.isCollect"
                                     v-if="caseExpress.sendType===sendTypeEnum.快递公司">到付
                        </el-checkbox>
                    </el-space>
                </el-form-item>
                <el-form-item label="快递单号">
                    <el-input maxlength="100" v-model.trim="caseExpress.expressNo"
                              class="input-large"></el-input>
                </el-form-item>
                <el-form-item label="产品数量">
                    <el-input maxlength="3" v-model.trim="caseExpress.num" v-only-int
                              class="input-medium"></el-input>
                </el-form-item>
                <el-form-item label="快递发件日期">
                    <el-date-picker type="date" v-model="caseExpress.pickUpDate" class="input-medium"
                                    size="default"></el-date-picker>
                </el-form-item>
            </el-form>
            <footer>
                <div style="width: 100%;display: flex;padding-top:10px;">
                    <div class="flex-grow"></div>
                    <el-button type="primary" :icon="Van" @click="handleOutCase" :loading="outCaseLoading">
                        确认发货
                    </el-button>
                </div>
            </footer>
        </el-dialog>
        <el-dialog v-model="returnVisible" title="退货" width="430px">
            <el-form>
                <el-form-item label="退货日期">
                    <el-date-picker type="date" v-model="returnCaseParam.returnDate" :clearable="false"
                                    class='input-medium'></el-date-picker>
                </el-form-item>
                <el-form-item label="退货原因">
                    <el-input maxlength="200" v-model="returnCaseParam.returnReason" type="textarea"></el-input>
                </el-form-item>
            </el-form>
            <footer>
                <div style="width: 100%;display: flex;padding-top:10px;">
                    <div class="flex-grow"></div>
                    <el-button type="primary" :icon="Edit" @click="handleReturnCase" :loading="returnCaseLoading">
                        确认退货
                    </el-button>
                    <el-button type="info" @click='returnVisible=false'>
                        关闭
                    </el-button>
                </div>
            </footer>
        </el-dialog>
        <el-dialog v-model="printCaseOutVisible" title="打印出货单" width="430px">
            <el-alert type="error" v-if="!caseOutTemplateList.length" title="请到设置-订单打印格式-出货单设置页面设置确认单格式"></el-alert>
            <el-form>
                <el-form-item label="模板">
                    <el-select v-model="caseOutTemplateId">
                        <el-option v-for="item in caseOutTemplateList" :key="item.id" :value="item.id"
                                   :label="item.name"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button type="primary" :icon="Printer" @click="handlePrintCaseOut"
                           :disabled="!caseOutTemplateList.length">
                    确认打印
                </el-button>
                <el-button type="info" @click='printCaseOutVisible=false'>
                    关闭
                </el-button>
            </template>
        </el-dialog>
        <el-dialog v-model="printConfirmVisible" title="打印确认单" width="430px">
            <el-alert type="error" v-if="!confirmTemplateList.length" title="请到设置-订单打印格式-确认单设置页面设置确认单格式"></el-alert>
            <el-form>
                <el-form-item label="模板">
                    <el-select v-model="confirmTemplateId">
                        <el-option v-for="item in confirmTemplateList" :key="item.id" :value="item.id"
                                   :label="item.name"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button type="primary" :icon="Printer" @click="handlePrintConfirm"
                           :disabled="!confirmTemplateList.length">
                    确认打印
                </el-button>
                <el-button type="info" @click='printConfirmVisible=false'>
                    关闭
                </el-button>
            </template>
        </el-dialog>
        <el-dialog v-model="printWarrantyVisible" title="打印质保卡" width="430px">
            <el-alert type="error" v-if="!warrantyTemplateList.length" title="请到设置-订单打印格式-质保卡设置页面设置质保卡格式"></el-alert>
            <el-form>
                <el-form-item label="产品">
                    <el-select v-model="warrantyProductId" @change="setDefaultWarrant">
                        <el-option v-for="product in caseInfo.productList" :key="product.productId"
                                   :value="product.productId"
                                   :label="product.productName"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="模板">
                    <el-select v-model="warrantyTemplateId">
                        <el-option v-for="item in warrantyTemplateList" :key="item.id" :value="item.id"
                                   :label="item.name"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="自定义打印产品名称">
                    <el-input v-model.trim="warrantProductName" maxlength="50"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button type="primary" :icon="Printer" @click="handlePrintWarranty"
                           :disabled="!warrantyTemplateList.length">
                    确认打印
                </el-button>
                <el-button type="info" @click='printWarrantyVisible=false'>
                    关闭
                </el-button>
            </template>
        </el-dialog>

        <el-dialog v-model="dataSetVisible" :close-on-click-modal="false" title="设置" width="900px" :show-close="false">
            <el-tabs v-model="dataSetTab">
                <el-tab-pane label="快递公司" name="expressCompany">
                    <data-set :data-id="expressCompanyId"></data-set>
                </el-tab-pane>
            </el-tabs>
            <template #footer>
                <el-button type="primary" :icon="Close" @click='refreshDataSet'>
                    关闭
                </el-button>
            </template>
        </el-dialog>
        <el-dialog v-model="casePicVisible">
            <el-image :src="casePicUrl">
            </el-image>
        </el-dialog>
        <el-dialog v-model="picVisible">
            <pics-upload v-if="picVisible" :file-use="fileUseEnum.牙色" :folder-prefix="factoryFolderPrefix.caseColor"
                         :files="productFiles" :disabled="!allowChange && !allowMod"
                         @finish-upload="finishUploadPic"></pics-upload>
        </el-dialog>
    </div>
</template>
<script setup>
import {ref, reactive, onMounted, watch, inject, computed, toRaw, onActivated, onDeactivated, onUpdated} from 'vue';
import {
    getEnumList, attachBelongEnum,
    caseStateEnum, caseState,
    caseTypeEnum, caseType,
    tryState,
    caseSettingItem,
    orderSourceEnum,
    factoryWebStoreEnum, modelTypeEnum,
    customerTypeEnum, printTemplateTypeEnum,
    sendType, sendTypeEnum, discountTypeEnum,
    receiverType, fileUseEnum, factoryFolderPrefix, auditStateEnum
} from "@/util/enum";
import {Plus, Search, Edit, Delete, Picture, Printer, Van, Close, Refresh, Back} from '@element-plus/icons-vue';
import {useStore} from 'vuex'
import {
    enumTypeFormatter,
    tableRowClass,
    txtMoneyFormat
} from "@/util/formatter";
import {useGetHospitalProductPriceList} from "@/use/customer/useHospitalPrice";
import {
    useValidCase,
    useCancelValidCase,
    useCaseOut,
    useReturnCase,
    useGetSimplePageCase,
    useGetCase,
    useSaveCase,
    useGetNumBySource,
    useAddCaseFile,
    useUpdateFinanceAuditCase,
} from "@/use/case/useCase";
import {ElMessage, ElMessageBox} from "element-plus";
import {useRoute, useRouter} from "vue-router";
import {useGetListCaseSetting} from "@/use/case/useCaseSetting";
import moment from "moment";
import {dateShortcuts, funcAuth} from "@/util/constant";
import {useGetListAttach} from "@/use/case/useAttach";
import {useGetListFactoryRestDay} from "@/use/system/useFactoryRestDay";
import {useCancelCaseOut, useCancelCasePack} from "@/use/case/useCaseOut";
import {hasAuth, throttle} from "@/util/common";
import {useGetPrinterList, usePrintSingle, usePrintList} from "@/use/system/usePrint";
import {useGetListDoctorHabit} from "@/use/customer/useDoctor";
import {useUploadFile, useGetFileUrl} from "@/use/system/useUpload";
import {useGetAllPrivatePriceList} from "@/use/customer/usePrivatePrice";

const store = useStore()
const route = useRoute()
const router = useRouter()
const casePageRef = ref(null)
const modelTypeList = getEnumList(modelTypeEnum)
const updateFactoryStoreLoading = inject('updateFactoryStoreLoading')
const updateFactoryStoreType = inject('updateFactoryStoreType')
watch(updateFactoryStoreLoading, async (n, o) => {
    if (!n) {
        if (updateFactoryStoreType.value === factoryWebStoreEnum.factorySetting) {
            initFactorySetting()
        }
    }
})
const {
    allPrivatePriceList,
    getAllPrivatePriceList
} = useGetAllPrivatePriceList()
// 附件
const attachBelongList = getEnumList(attachBelongEnum)
const {getListAttach, attachList} = useGetListAttach()
const changeAttach = row => {
    if (facCase.attachList.filter(x => x.name === row.name).length > 1) {
        ElMessage.error('存在相同附件')
        row.name = ''
        return
    }
    if (!row.num) row.num = 1
    let item = attachList.value.find(x => x.name === row.name)
    item && (row.belong = item.belong)
}
//基础设置
const factoryId = computed(() => store.state.factoryId)
let factorySetting = {
    fullName: '',
    servicePhoneNo: '',
    fullAddress: '',
}
const facAgentList = computed(() => {
    return store.state.agentList
})
const initFactorySetting = () => {
    if (store.state.factorySetting) {
        let setting = JSON.parse(store.state.factorySetting)
        setFields(factorySetting, setting)
        factorySetting.fullAddress = setting.province + setting.city + setting.area + setting.address
    }
}
const {
    getHospitalProductPriceList,
    getHospitalProductPriceListLoading,
    hospitalProductList
} = useGetHospitalProductPriceList()
const {doctorHabitList, getListDoctorHabit} = useGetListDoctorHabit()
const {
    printSingle,
    printSingleLoading
} = usePrintSingle()
const {
    printList,
    printListLoading,
    printScanList,
    printResList,
    printNoScanList
} = usePrintList()
const {
    start,
    setTemplatePrinter,
} = useGetPrinterList()
const {
    getListFactoryRestDay,
    calEndDate
} = useGetListFactoryRestDay()
const searchCaseNoRef = ref()
const autoTakePic = ref(false)//录单后自动高拍仪拍1张照片
const getAutoTakePic = () => {
    let takePicAfterAddCase = localStorage.getItem("takePicAfterAddCase")
    autoTakePic.value = takePicAfterAddCase && takePicAfterAddCase === 'true'
}
const changeAutoTakePic = () => {
    localStorage.setItem('takePicAfterAddCase', autoTakePic.value)
    if (autoTakePic.value && device.isConnect === '未连接') {
        getDeviceInfo()
        open_view1('view1')
    }
}
const viewPriceAuth = ref(false)//查看价格权限
onMounted(async () => {
    viewPriceAuth.value = hasAuth(funcAuth.查看订单价格)
    initGetSimplePageCase()
    initPage(true)
    searchCaseNoRef.value && searchCaseNoRef.value.focus()
    getTemplatePrinterList()
    start(hiprint)
    //初始化高拍仪
    getAutoTakePic()
    initLiangtianSetting()
    if (autoTakePic.value) {
        open_view1('view1')
        getDeviceInfo()
    }
})
const refreshDbData = async () => {
    getAllPrivatePriceList()
    getListFactoryRestDay()
    await getListAttach()
    initFactorySetting()
    getListCaseSetting()
    getListDoctorHabit(0)
}
const initPage = throttle(async isFirst => {
    await refreshDbData()
    if (route.query && route.query.id) {
        queryId.value = route.query.id
        queryUrl.value = route.query.url
        await getFacCase(route.query.id)
        if (route.query.type === 'comment') {
            curTab.value = 'comment'
            changeTab()
        }
    } else if (route.query && route.query.orderId) {
        // 网络订单生成订单
        queryUrl.value = route.query.url
    } else {
        if (isFirst) {
            queryId.value = 0
            queryUrl.value = ''
            newCase()
        }
        if (facCase.facHospitalId) {
            await setCaseStoreHospitalAndDoctor()
        }
    }
    await getSimplePageCase()
})
const queryId = ref(0)//是否是查询页面跳转过来修改订单
const queryUrl = ref('')//跳转页面地址
const removePageTab = inject('removePageTab')
const setMenu = inject('setMenu')
// 返回列表页
const handleBack = () => {
    setMenu(queryUrl.value)
    router.push({
        name: queryUrl.value,
    })
    removePageTab('caseAdd')
}
const priceAuth = ref(false)//是否有修改价格的权限
onActivated(async () => {
    priceAuth.value = hasAuth(funcAuth.修改订单价格) || hasAuth(funcAuth.更正订单)
    initPage(false)
})
onDeactivated(() => {
    customerTipVisible.value = false
})
//设置模板默认打印机
const getTemplatePrinterList = async () => {
    await getListCasePrintTemplate()
    if (!casePrintTemplateList.value || !casePrintTemplateList.value.length) return
    for (let item of casePrintTemplateList.value) {
        setTemplatePrinter(item)
    }
}
const labelTemplateList = computed(() => casePrintTemplateList.value.filter(x => x.printTemplateType === printTemplateTypeEnum.订单标签 && x.showCaseOut === 1))
// 图片
const picList = ref([])
const {getFileUrl} = useGetFileUrl()
const {
    uploadParam,
    uploadFileResult,
    uploadFileList,
    uploadFile,
} = useUploadFile()
uploadParam.folderPrefix = factoryFolderPrefix.casePic
uploadParam.fileUse = fileUseEnum.订单图片
const maxPicNum = 10//最多文件上传数量
const casePicExceed = () => {
    ElMessage.error(`最多上传${maxPicNum}个文件`)
}
const removePic = file => {
    let fileIndex = uploadFileList.value.findIndex(x => x.orgFileName === file.name)
    try {
        uploadFileList.value.splice(fileIndex, 1)
    } catch (e) {
        ElMessage.warning('没找到要删除的文件')
    }
}
const casePicVisible = ref(false)
const casePicUrl = ref('')
const previewPic = async file => {
    let caseFile = uploadFileList.value.find(x => x.orgFileName === file.name)
    if (caseFile) {
        casePicUrl.value = await getFileUrl(caseFile.picToken)
        casePicVisible.value = true
    } else {
        casePicUrl.value = ''
    }
}
// 文件
import {
    beforePicUpload,
} from "@/use/system/useFile";

//设置
const {
    getListCaseSetting,
    caseSettingList
} = useGetListCaseSetting()
// 产品
const storeProductList = computed(() => store.state.productList)
const setProductNum = (val, row) => {
    row.num = val
    calCaseTotalPrice(facCase)
}
const newProduct = () => {
    if (facCase.productList.length && facCase.productList.length >= 10) {
        ElMessage.error('不能超过10个产品，请检查')
        return
    }
    let product = newCaseProduct()
    if (storeDoctor && facCase.isPrivate === 1) {
        //如果是私人单位 需要获取医生私人折扣打折
        if (storeDoctor.discountPercent && storeDoctor.discountPercent !== 100) {
            product.discount = parseInt(storeDoctor.discountPercent)
            product.discountType = discountTypeEnum.百分比
        }
    }
    facCase.productList.push(product)
}
const deleteProduct = (row, index) => {
    if (facCase.productList.length && facCase.productList.length <= 1) {
        ElMessage.error('至少要有1个产品，请检查')
        return
    }
    facCase.productList.splice(index, 1)
    getTotalPrice()
}
const changeDiscountType = (row, discountType) => {
    if (allowChange.value || allowMod.value) {
        if (row.discountType === discountTypeEnum.百分比 && discountType === discountTypeEnum.人民币) {
            row.discount = Math.round((100 - row.discount) * row.price) / 100
        } else if (row.discountType === discountTypeEnum.人民币 && discountType === discountTypeEnum.百分比) {
            if (!row.price) {
                row.discount = 100
            } else {
                row.discount = Math.round((row.price - row.discount) / row.price * 10000) / 100
            }
        }
        row.discountType = discountType
        getTotalPrice()
    }
}
//产品数量和小计
const productSummaryMethod = param => {
    const {columns, data} = param
    const sums = []
    let totalMoney = data.map(x => x.sum).reduce((prev, cur) => prev + cur)
    let totalNum = data.map(x => x.num).reduce((prev, cur) => prev + cur)
    let totalBenefit = data.map(x => x.benefit).reduce((prev, cur) => prev + cur)
    totalMoney = txtMoneyFormat(totalMoney)
    totalBenefit = txtMoneyFormat(totalBenefit)
    columns.forEach((column, index) => {
        if (priceAuth.value && index === columns.length - 3) {
            sums[index] = '合计'
        } else if (priceAuth.value && index === columns.length - 2) {
            sums[index] = totalMoney
        } else if (priceAuth.value && index === columns.length - 1) {
            sums[index] = totalBenefit
        } else if (index === 4) {
            sums[index] = totalNum
        } else if (index === 3 && !priceAuth.value) {
            sums[index] = '数量合计'
        } else {
            sums[index] = ''
        }
    })
    return sums
}
// 牙色
const toothColorList = computed(() => {
    return store.state.toothColorList
})
const searchColor = (val, cb) => {
    let res = []
    if (!val) {
        cb(toothColorList.value)
        return
    }
    if (toothColorList.value && toothColorList.value.length) {
        res = toothColorList.value.filter(x => x.color.indexOf(val) >= 0)
    }
    cb(res)
}
//缓存数据字典
import {useGetDataValueListFromStore} from "@/use/system/useData";

const {
    getDataValueListFromStore,
    getDataIdFromStore
} = useGetDataValueListFromStore()
const district = ref([])
const expressCompany = ref([])
const expressCompanyId = getDataIdFromStore('expressCompany')
const setData = () => {
    district.value = getDataValueListFromStore('district')
    expressCompany.value = getDataValueListFromStore('expressCompany')
}
setData()
// 设置
import {useGetAllDataValueList} from "@/use/system/useData";

const {
    getAllDataValueList
} = useGetAllDataValueList()
const dataSetVisible = ref(false)
const dataSetTab = ref('expressCompany')
const refreshDataSet = async () => {
    dataSetVisible.value = false
    await getAllDataValueList()
    setData()
}
const handleSaveCase = async () => {
    facCase.saveOrderSource = orderSourceEnum.工厂后台
    // 合并图片和大文件列表
    let picListToSave = []
    if (uploadFileList.value && uploadFileList.value.length) {
        picListToSave = uploadFileList.value.map(x => {
            return {
                id: x.id,
                fileId: x.picId,
                miniFileId: x.miniPicId,
                fileUse: fileUseEnum.订单图片,
                casePicType: '订单图片'
            }
        })
    }
    if (!picListToSave.length) {
        facCase.fileList = []
    } else {
        facCase.fileList = picListToSave
    }
    facCase.plantList = facCase.plantList.filter(x => !!x.num)
    Object.assign(facCase.express, caseExpress)
    calCaseTotalPrice(facCase)
    await saveCase()
}
/**
 * 财务弃审
 */
const handleSaveFinanceAudit = async () => {
    updateFinanceAuditCaseParam.id = caseInfo.id
    updateFinanceAuditCaseParam.financeAuditState = auditStateEnum.待审核
    await updateFinanceAuditCaseCase()
    if (updateFinanceAuditCaseResult.value) {
        await getFacCase(facCase.id)
        let pageItem = casePage.value.find(x => x.id === facCase.id)
        pageItem && (pageItem.caseState = facCase.caseState)
    }
}
// 选择一行
const setFields = inject('setFields')
const chooseRow = async row => {
    await getFacCase(row.id)
    caseNoRef.value && caseNoRef.value.select()
}
// 获取订单信息 获取缓存的订单客户和医生信息
const getFacCase = async id => {
    await getCase(id)
    customerTipVisible.value = false
    Object.assign(facCase, caseInfo)
    await setCaseStoreHospitalAndDoctor()
    if (facCase.isValid === 0) {
        curTab.value = 'add'
    }
    if (!facCase.attachList || !facCase.attachList.length) {
        for (let i = 0; i < 4; i++) {
            addAttach()
        }
    }
    if (!facCase.plantList || !facCase.plantList.length) addPlant()
    // 图片与大文件分开处理
    if (facCase.fileList && facCase.fileList.length) {
        uploadFileList.value = facCase.fileList.filter(x => x.fileUse === fileUseEnum.订单图片)
    } else {
        uploadFileList.value = []
    }
    picList.value = []
    if (!!uploadFileList.value.length) {
        for (let file of uploadFileList.value) {
            file.picId = file.fileId
            file.picToken = file.fileToken
            file.miniPicId = file.miniFileId
            file.miniPicToken = file.miniFileToken
            file.orgFileName = file.fileName
            picList.value.push({
                name: file.fileName
            })
        }
    }
    // 订单快递信息单独对象管理
    Object.assign(caseExpress, caseInfo.express)
    caseExpress.caseId = caseInfo.id
    initSelect(caseExpress.province, caseExpress.city)
    // 清楚表单验证
    caseFormRef.value && caseFormRef.value.clearValidate()
    // 自定义标签产品默认选第一个
    labelProductId.value = caseInfo.productList.length ? caseInfo.productList[0].productId : ''
    changeTab()
    caseRoutes.routeList = []//清空快递路由查询
}
const {
    getNumBySource,
    getNumBySourceLoading,
} = useGetNumBySource()
// 根据关联订单号填充订单信息
const getCaseInfoBySourceCaseNo = async () => {
    if (!facCase.sourceCaseNo) return
    if (!!facCase.id) return
    await getCaseByNo(facCase.sourceCaseNo)
    // 获取关联订单数量
    let sourceNum = await getNumBySource(facCase.sourceCaseNo)
    facCase.caseNo = facCase.sourceCaseNo + "" + (parseInt(sourceNum) + 1)
    if (!caseInfo.id) return
    facCase.isTry = caseInfo.isTry
    facCase.tryState = caseInfo.tryState
    facCase.facDoctorId = caseInfo.facDoctorId
    facCase.facDoctorName = caseInfo.facDoctorName
    facCase.facHospitalId = caseInfo.facHospitalId
    facCase.facHospitalName = caseInfo.facHospitalName
    facCase.district = caseInfo.district
    facCase.agentId = caseInfo.agentId
    facCase.patientName = caseInfo.patientName
    facCase.patientAge = caseInfo.patientAge
    facCase.patientPhoneNo = caseInfo.patientPhoneNo
    facCase.gender = caseInfo.gender
    facCase.isPrivate = caseInfo.isPrivate
    facCase.isInvoice = caseInfo.isInvoice
    facCase.productList = caseInfo.productList
    if (facCase.productList && facCase.productList.length) facCase.productList.forEach(x => x.id = 0)
    getTotalPrice()
    setCaseStoreHospitalAndDoctor()
    caseFormRef.value && caseFormRef.value.clearValidate()
}
// 切换tab
const curTab = ref('add')
const changeTab = async () => {
    if (curTab.value === 'add') {

    } else if (curTab.value === 'history') {
    } else if (curTab.value === 'step') {
    } else if (curTab.value === 'question') {
    } else if (curTab.value === 'comment') {
        // commentRef.value && commentRef.value.focus()
        // await getListCaseComment(facCase.id)
    }
}
//工序
const initGetSimplePageCase = () => {
    initGetSimplePageCaseParam()
    getSimplePageCaseParam.inEndDate = moment().add(1, 'days').format('yyyy-MM-DD 23:59:59')
    getSimplePageCaseParam.inBeginDate = moment().subtract(30, 'days').format('yyyy-MM-DD 00:00:00')
    getSimplePageCaseParam.isAudit = 1
}

// 订单
const {
    addPlant,
    deletePlant,
    plantNum,
    addAttach,
    deleteAttach,
    attachNum,
    facCase,
    newCaseProduct,
    initCase,
    caseFormRef,
    caseSaveRules,
    saveCaseLoading,
    saveCaseResult,
    saveCaseInfo,
    saveCase,
    getTotalPrice,
    calCaseTotalPrice,
    totalDiscount,
    totalNum,
    changeCaseProductPrice,
} = useSaveCase()
const {
    caseGetLoading,
    caseInfo,
    getCase,
    getCaseByNo
} = useGetCase()
const {
    getSimplePageCaseParam,
    initGetSimplePageCaseParam,
    getSimplePageCaseLoading,
    casePage,
    getSimplePageCaseResult,
    getSimplePageCase,
} = useGetSimplePageCase()
const {
    validCase,
    validCaseLoading,
    validCaseParam,
    validCaseResult
} = useValidCase()
const {
    cancelValidCaseLoading,
    cancelValidCaseResult,
    cancelValidCaseParam,
    cancelValidCase
} = useCancelValidCase()
const {
    updateFinanceAuditCaseLoading,
    updateFinanceAuditCaseResult,
    updateFinanceAuditCaseParam,
    updateFinanceAuditCaseCase,
} = useUpdateFinanceAuditCase()
const {
    caseOutParam,
    outCase,
    outCaseLoading,
    outCaseResult
} = useCaseOut()
// 作废订单
const validCaseVisible = ref(false)
const showValidCase = () => {
    validCaseVisible.value = true
    validCaseParam.id = facCase.id
    validCaseParam.validReason = '录入信息错误'
}
const handleValidCase = async () => {
    await validCase()
}
const handleCancelValidCase = async () => {
    cancelValidCaseParam.id = facCase.id
    await cancelValidCase()
}
watch(validCaseResult, (newVal, oldVal) => {
    if (!!newVal) {
        validCaseVisible.value = false
        facCase.isValid = 0
        let pageItem = casePage.value.find(x => x.id === facCase.id)
        pageItem.isValid = 0
    }
})
watch(cancelValidCaseResult, (newVal, oldVal) => {
    if (!!newVal) {
        facCase.isValid = 1
        let pageItem = casePage.value.find(x => x.id === facCase.id)
        pageItem.isValid = 1
    }
})
// 修改订单类型
const changeCaseType = () => {
    if (facCase.caseType === caseTypeEnum.正常) {
        // 其他改正常 免费改收费
        facCase.productList.forEach(x => {
            if (!x.sum) {
                x.discountType = discountTypeEnum.人民币
                x.discount = 0
                x.benefit = 0
            }
        })
    }
    getTotalPrice()
}
// 取消出件
const {
    cancelCaseOutLoading,
    cancelCaseOutResult,
    cancelCaseOut,
} = useCancelCaseOut()
const handleCancelCaseOut = async () => {
    await cancelCaseOut(facCase.id)
    if (cancelCaseOutResult.value) {
        await getFacCase(facCase.id)
        let pageItem = casePage.value.find(x => x.id === facCase.id)
        pageItem && (pageItem.caseState = facCase.caseState)
    }
}
// 取消装箱
const {
    cancelCasePackLoading,
    cancelCasePackResult,
    cancelCasePack,
} = useCancelCasePack()
const handleCancelCasePack = async () => {
    await cancelCasePack(facCase.id)
    if (cancelCasePackResult.value) {
        await getFacCase(facCase.id)
        let pageItem = casePage.value.find(x => x.id === facCase.id)
        pageItem && (pageItem.caseState = facCase.caseState)
    }
}
// 订单出件
const isCaseOut = computed(() => {
    //订单是否已经出件
    return facCase.caseState >= caseStateEnum.已发货 && facCase.caseState < caseStateEnum.已退货
})
const outCaseVisible = ref(false)
const showOutCase = () => {
    if (facCase.caseType === caseTypeEnum.正常 && facCase.productList.some(x => !x.price)) {
        // 正常单 有产品没有价格  提示
        ElMessageBox.confirm('有产品单价为0，确认出货吗？', '重要提示', {
            confirmButtonText: '确认出货',
            cancelButtonText: '取消',
            type: 'warning',
        }).then(() => {
            initOutCase()
        })
    } else {
        initOutCase()
    }
}
const initOutCase = () => {
    outCaseVisible.value = true
    caseOutParam.id = facCase.id
    caseOutParam.outDate = new Date()
    initCaseSendType()
}
const handleOutCase = async () => {
    caseExpress.caseId = facCase.id
    Object.assign(caseOutParam, caseExpress)
    await outCase()
}
watch(outCaseResult, (newVal, oldVal) => {
    if (!!newVal) {
        // 发货成功后修改订单信息出货日期
        getCase(facCase.id)
        caseInfo.extension.outDate = caseOutParam.outDate

        outCaseVisible.value = false
        facCase.caseState = caseStateEnum.已发货
        let pageItem = casePage.value.find(x => x.id === facCase.id)
        pageItem.caseState = caseStateEnum.已发货
        // 根据设置自动打印出货单
        let autoCaseOutPrint = caseSettingList.value.find(x => x.settingItem === caseSettingItem.订单出货后自动打印出货单)
        if (autoCaseOutPrint && autoCaseOutPrint.intResult) {
            showPrintCaseOut()
        }
    }
})

// 新建订单
watch(saveCaseResult, async (newVal, oldVal) => {
    if (!!newVal) {
        if (!facCase.id) {
            //更新缓存使用频率
            if (!!facCase.facHospitalId) store.commit('addHospitalCount', facCase.facHospitalId)
            if (!!facCase.facDoctorId) store.commit('addDoctorCount', facCase.facDoctorId)
            if (facCase.productList && facCase.productList.length) {
                //更新缓存：上次产品
                let caseProductIds = facCase.productList.map(x => x.productId)
                store.commit('setLastProductIds', caseProductIds)
            }
            Object.assign(caseInfo, facCase)
            caseInfo.id = saveCaseInfo.id
            caseInfo.caseNo = saveCaseInfo.caseNo
            // 高拍仪自动拍照
            if (autoTakePic.value) {
                takePhoto(saveCaseInfo.id)
            }
            // 根据设置自动打印条形码
            let barcodeSetting = caseSettingList.value.find(x => x.settingItem === caseSettingItem.录单后自动打印条形码)
            if (barcodeSetting && barcodeSetting.intResult) {
                printBarcode()
            }
            // 根据设置自动打印确认单
            let confirmSetting = caseSettingList.value.find(x => x.settingItem === caseSettingItem.录单后自动打印确认单)
            if (confirmSetting && confirmSetting.intResult) {
                showPrintConfirm()
            }
            //清空
            newCase()
            getSimplePageCaseParam.pageIndex = 1
            await getSimplePageCase()
            // 根据设置不清空客户和医生信息
            let clearSetting = caseSettingList.value.find(x => x.settingItem === caseSettingItem.录单后不清空医院医生)
            if (clearSetting && clearSetting.intResult) {
                getHospitalDoctorFromCaseInfo()
            }
        } else {
            //修改
            await getSimplePageCase()
            if (!facCase.plantList || !facCase.plantList.length) addPlant()
            Object.assign(caseInfo, facCase)
            casePageRef.value.setCurrentRow(casePage.value.find(x => x.id === facCase.id))
        }
    }
})
//从caseInfo中复制医院和医生信息
const getHospitalDoctorFromCaseInfo = () => {
    facCase.facHospitalId = caseInfo.facHospitalId
    facCase.facDoctorId = caseInfo.facDoctorId
    facCase.district = caseInfo.district
    facCase.facWorkerId = caseInfo.facWorkerId
    facCase.agentId = caseInfo.agentId
    facCase.isInvoice = caseInfo.isInvoice
    setCaseStoreHospitalAndDoctor()
    let sendSetting = caseSettingList.value.find(x => x.settingItem === caseSettingItem.自动获取发货与收货方式)
    if (sendSetting && sendSetting.intResult) {
        initCaseSendType()
    }
    initSelect(caseExpress.province, caseExpress.city)
}
const customerTipVisible = ref(false)//客户输入框提示
const caseNoRef = ref(null)//订单号
const newCase = () => {
    Object.assign(caseInfo, {id: 0, extension: null})
    queryId.value = 0
    initCase()
    initCaseExpress()
    facCase.sourceFactoryId = parseInt(factoryId.value)
    facCase.processFactoryId = parseInt(factoryId.value)
    // 根据设置不清空医院和医生信息
    let clearSetting = caseSettingList.value.find(x => x.settingItem === caseSettingItem.录单后不清空医院医生)
    if (clearSetting && clearSetting.intResult) {
        getHospitalDoctorFromCaseInfo()
    }
    curTab.value = 'add'
    setTimeout(_ => {
        casePageRef.value && casePageRef.value.setCurrentRow()
        //默认先输入订单号 caseNoRef.value && caseNoRef.value.focus()
        caseFormRef.value && caseFormRef.value.clearValidate()
    }, 0)
    // 清空文件
    uploadFileList.value = []
    picList.value = []
    // 清空快递查询
    caseRoutes.routeList = []
    if (!facCase.facHospitalId) customerTipVisible.value = true
}
const calScheduleDeliveryDate = () => {
    if (facCase.inDate) {
        let makeDays = facCase.makeDays ? parseInt(facCase.makeDays) : 0
        let extraDays = facCase.extraDays ? parseInt(facCase.extraDays) : 0
        let endDate = calEndDate(facCase.inDate, makeDays + extraDays)
        facCase.scheduleDeliveryDate = endDate.format('yyyy-MM-DD 23:59:59')
    }
}
import TableToothInput from "@/components/tableToothInput";
// 搜索
const searchCase = async () => {
    if (!getSimplePageCaseParam.caseNo && !getSimplePageCaseParam.patientName && !getSimplePageCaseParam.boxNo
        && !getSimplePageCaseParam.sourceCaseNo && !getSimplePageCaseParam.facHospitalId && !getSimplePageCaseParam.facDoctorId
        && !getSimplePageCaseParam.inBeginDate && !getSimplePageCaseParam.inEndDate && !getSimplePageCaseParam.outBeginDate && !getSimplePageCaseParam.outEndDate) {
        ElMessage.error('请至少输入一个搜索条件')
        return
    }
    if (!!getSimplePageCaseParam.caseNo || !!getSimplePageCaseParam.patientName) {
        getSimplePageCaseParam.inBeginDate = ''
        getSimplePageCaseParam.inEndDate = ''
    }
    await getSimplePageCase()
    if (casePage.value.length === 1) {
        casePageRef.value.setCurrentRow(casePage.value[0])
        await chooseRow(casePage.value[0])
    }
}

// 医生列表
const storeDoctorList = computed(() => store.state.doctorList)
// 员工列表
const storeWorkerList = computed(() => store.state.workerList)
// 获取产品价格
const hospitalDetails = computed(() => store.state.hospitalDetails)
const productDetails = computed(() => store.state.productDetails)
let storeHospitalDetail = null
const setCaseStoreHospitalAndDoctor = async () => {
    // 获取缓存中的医院和医生
    storeHospitalDetail = hospitalDetails.value.find(x => x.id === facCase.facHospitalId)
    checkIsCustomerFactory()
    if (!!storeHospitalDetail) {
        await getHospitalProductPriceList(facCase.facHospitalId)
        storeHospitalDetail.priceList = hospitalProductList.value
    }
    if (facCase.facDoctorId) {
        storeDoctor = storeDoctorList.value.find(x => x.id === facCase.facDoctorId)
        storeDoctor && (storeDoctor.priceList = allPrivatePriceList.value.filter(x => x.priceId === storeDoctor.discountId))
    }
}
const changeCaseFacHospital = async () => {
    if (!facCase.facHospitalId) {
        customerTipVisible.value = true
    } else {
        customerTipVisible.value = false
    }
    initCaseExpress()
    // 获取医院的销售员、片区、代理人、是否开票
    if (!facCase.facHospitalId) {
        storeHospitalDetail = null
        return
    }
    await setCaseStoreHospitalAndDoctor()
    if (storeHospitalDetail) {
        facCase.district = storeHospitalDetail.district
        facCase.facWorkerId = storeHospitalDetail.salesman
        facCase.agentId = storeHospitalDetail.agentId
        facCase.isInvoice = storeHospitalDetail.isInvoice
    }
    // 修改客户后自动获取客户价格
    if (facCase.productList && facCase.productList.length) {
        facCase.productList.forEach(x => {
            handleChangeCaseProductPrice(x)
        })
    }
}
// 判断客户是不是工厂（工厂客户需要手动输入医院名称和医生姓名）
const isCustomerFactory = ref(false)
const checkIsCustomerFactory = () => {
    isCustomerFactory.value = !!storeHospitalDetail && !!storeHospitalDetail.hospitalType && (storeHospitalDetail.hospitalType === customerTypeEnum.义齿工厂)
    if (isCustomerFactory.value) {
        facCase.facDoctorId = null
    } else {
        facCase.realHospitalName = ''
    }
}

let storeDoctor = null//缓存医生信息
/**
 * 修改医生或私人单位后  自动修改价格和收货方式等信息
 * @param changePrivate 是否修改私人单位
 */
const changeCaseFacDoctor = (changePrivate = false) => {
    if (!facCase.facDoctorId) {
        storeDoctor = null
        return
    }
    storeDoctor = storeDoctorList.value.find(x => x.id === facCase.facDoctorId)
    storeDoctor && (storeDoctor.priceList = allPrivatePriceList.value.filter(x => x.priceId === storeDoctor.discountId))
    if (!storeDoctor) return
    if (!changePrivate) {
        // 修改医生后修改医生习惯
        changeDoctorHabit()
    }
    if (facCase.isPrivate === 1) facCase.isInvoice = 0//私人单不开票
    if (storeDoctor && facCase.isPrivate === 1) {
        //如果订单是私人单位 需要修改所有产品的折扣默认为医生设置的私人折扣
        if (facCase.productList && facCase.productList.length) {
            facCase.productList.forEach(x => {
                if (storeDoctor.discountPercent && storeDoctor.discountPercent !== 100) {
                    x.discount = parseInt(storeDoctor.discountPercent)
                    x.discountType = discountTypeEnum.百分比
                }
                handleChangeCaseProductPrice(x)
            })
        }
    } else if (storeDoctor && facCase.isPrivate === 0 && changePrivate) {
        //从私人改为非私人单位 重置折扣、单价、小计、总计等金额数据
        if (facCase.productList && facCase.productList.length) {
            facCase.productList.forEach(x => {
                x.discount = 0
                x.discountType = discountTypeEnum.人民币
                handleChangeCaseProductPrice(x)
            })
        }
    }
    // 发货收货方式
    let sendSetting = caseSettingList.value.find(x => x.settingItem === caseSettingItem.自动获取发货与收货方式)
    if (sendSetting && sendSetting.intResult) initCaseSendType()
}
//根据医生和产品填充医生习惯
const changeDoctorHabit = () => {
    if (!facCase.facDoctorId || !facCase.productList || !facCase.productList.length || !facCase.productList.some(x => !!x.productId)) return
    let productIds = facCase.productList.map(x => x.productId)
    let typePaths = productDetails.value.filter(x => productIds.includes(x.id)).map(x => x.typePath)
    let habitList = doctorHabitList.value.filter(x => x.facDoctorId === facCase.facDoctorId && x.habit && typePaths.some(a => a.includes(',' + x.productTypeId + ',')))
    if (!habitList || !habitList.length) return
    facCase.doctorHabit = Array.from(new Set(habitList.map(x => x.habit))).join(',')
}
const changeCaseProduct = row => {
    changeMakeDays()
    changeDoctorHabit()
    handleChangeCaseProductPrice(row)
}
const handleChangeCaseProductPrice = row => {
    changeCaseProductPrice(row, productDetails.value, storeHospitalDetail, storeDoctor)
}
// 根据产品生产天数 修改订单生产天数
const changeMakeDays = () => {
    if (!facCase.productList.length) return
    let maxMakeDays = 0
    for (let product of facCase.productList) {
        let productDetail = productDetails.value.find(x => x.id === product.productId)
        if (!productDetail) continue
        if (maxMakeDays < productDetail.makeDays) {
            maxMakeDays = productDetail.makeDays
        }
    }
    facCase.makeDays = maxMakeDays
    calScheduleDeliveryDate()
}
// 获取枚举名称
const getCaseTypeName = val => {
    let type = caseType.find(x => x.id === val)
    return type ? type.name : ''
}
const getCaseStateName = val => {
    let state = caseState.find(x => x.id === val)
    return state ? state.name : ''
}
const getProductFullType = val => {
    let product = productDetails.value.find(x => x.id === val)
    if (product) {
        return product.productTypeName + ' - ' + product.productBelongName
    }
}
// 订单补充信息
const caseTab = ref('attach')

const currentWorker = computed(() => store.state.currentWorker)
// 打印相关
import {autoConnect, disAutoConnect, hiprint, defaultElementTypeProvider} from 'vue-plugin-hiprint'
import {useGetListCasePrintTemplate} from "@/use/case/useCasePrintTemplate";
import {
    usePrintWarranty,
    usePrintConfirm,
    usePrintCaseOut,
    usePrintCaseBarcode,
    usePrintCaseLabel
} from "@/use/case/usePrintCase";

hiprint.init()
// 打印条码
const {
    casePrintTemplateList,
    getListCasePrintTemplate
} = useGetListCasePrintTemplate()
const barcodeType = printTemplateTypeEnum.订单条形码
const {
    printCaseBarcode
} = usePrintCaseBarcode()
const printBarcode = async () => {
    let barcodeTemplate = null//条形码打印模板
    if (casePrintTemplateList.value && casePrintTemplateList.value.length) {
        barcodeTemplate = casePrintTemplateList.value.find(x => x.printTemplateType === barcodeType)
    }
    if (!barcodeTemplate) {
        ElMessage.error('请先设置订单条形码打印模板(设置-订单打印格式-订单条形码)')
        return
    }
    printResList.value = []
    let res = printCaseBarcode(barcodeTemplate, caseInfo, hospitalDetails, hiprint)
    printResList.value.push(res)
    await printList(barcodeTemplate, hiprint)
    printResList.value = []
}
// 打印出货单
const {getDefaultCaseOutId, getPrintCaseOutList} = usePrintCaseOut()
const caseOutTemplateType = printTemplateTypeEnum.出货单
const printCaseOutVisible = ref(false)
const caseOutTemplateId = ref('')
const caseOutTemplateList = computed(() => casePrintTemplateList.value.filter(x => x.printTemplateType === caseOutTemplateType))
const showPrintCaseOut = async () => {
    caseOutTemplateId.value = getDefaultCaseOutId(storeHospitalDetail, caseOutTemplateList)
    //如果设置了 不显示弹窗 直接打印出货单
    let setting = caseSettingList.value.find(x => x.settingItem === caseSettingItem.打印出货单不显示模板选择弹窗)
    if (setting && setting.intResult) {
        handlePrintCaseOut()
        return
    }
    printCaseOutVisible.value = true
}
const handlePrintCaseOut = async () => {
    printResList.value = []
    let caseOutTemplate = casePrintTemplateList.value.find(x => x.id === caseOutTemplateId.value)
    if (!caseOutTemplate) {
        ElMessage.error('请先设置出货单打印模板(设置-订单打印格式-出货单设置)')
        return
    }
    let res = getPrintCaseOutList(caseInfo, factorySetting, productDetails, currentWorker.value.name, caseOutTemplate, hiprint)
    if (res && res.length) printResList.value = res
    await printList(caseOutTemplate, hiprint)
    printCaseOutVisible.value = false
}
//打印确认单
const {printConfirm, getDefaultConfirmId} = usePrintConfirm()
const confirmTemplateType = printTemplateTypeEnum.确认单
const printConfirmVisible = ref(false)
const confirmTemplateId = ref('')
const confirmTemplateList = computed(() => casePrintTemplateList.value.filter(x => x.printTemplateType === confirmTemplateType))
const showPrintConfirm = async () => {
    confirmTemplateId.value = getDefaultConfirmId(confirmTemplateList)
    //如果设置了 不显示弹窗 直接打印确认单
    let setting = caseSettingList.value.find(x => x.settingItem === caseSettingItem.打印确认单不显示模板选择弹窗)
    if (setting && setting.intResult) {
        handlePrintConfirm()
        return
    }
    printConfirmVisible.value = true
}
const printConfirmDetailTableRef = ref(null)
const confirmPrintColumns = ref([])
const plantPrintTableRef = ref()
const plantPrintColumns = ref([])
const handlePrintConfirm = () => {
    let confirmTemplate = casePrintTemplateList.value.find(x => x.id === confirmTemplateId.value)
    if (!confirmTemplate) {
        ElMessage.error('请选择打印模板')
        return
    }
    let res = printConfirm(caseInfo, hiprint, confirmTemplate, confirmPrintColumns, printConfirmDetailTableRef, plantPrintColumns, plantPrintTableRef)
    printSingle(confirmTemplate, res, hiprint)
    printConfirmVisible.value = false
}
//打印质保卡
const {printWarranty, getDefaultWarrantyId} = usePrintWarranty()
const warrantyTemplateType = printTemplateTypeEnum.质保卡
const printWarrantyVisible = ref(false)
const warrantyProductId = ref('')
const warrantyProductIds = ref([])
const warrantyTemplateId = ref('')
const warrantyTemplateList = computed(() => casePrintTemplateList.value.filter(x => x.printTemplateType === warrantyTemplateType))
const showPrintWarranty = () => {
    let stopSetting = caseSettingList.value.find(x => x.settingItem === caseSettingItem.返工返修订单不打印质保卡)
    if (stopSetting && stopSetting.intResult && (caseInfo.caseType === caseTypeEnum.返工 || caseInfo.caseType === caseTypeEnum.返修)) {
        ElMessage.error('已设置返工返修订单不打印质保卡')
        return
    }
    warrantyProductIds.value = []
    warrantyProductId.value = ''
    for (let i = 0; i < caseInfo.productList.length; i++) {
        let productPriceDetail = storeHospitalDetail.priceList.find(x => x.productId === caseInfo.productList[i].productId)
        if (productPriceDetail && productPriceDetail.isPrintWarranty === 0) {
            // 设置该客户不打印该产品
            continue
        }
        let productDetail = productDetails.value.find(x => x.id === caseInfo.productList[i].productId)
        if (productDetail && productDetail.isWarrant === 1) {
            if (!warrantyProductId.value) warrantyProductId.value = caseInfo.productList[i].productId
            warrantyProductIds.value.push(caseInfo.productList[i].productId)
        }
    }
    if (!warrantyProductIds.value || !warrantyProductIds.value.length) {
        ElMessage.error('没有需要打印质保卡的产品，请检查产品设置')
        return
    }
    let storeProductDetail = productDetails.value.find(x => x.id === warrantyProductId.value)
    if (storeProductDetail) {
        warrantyTemplateId.value = getDefaultWarrantyId(storeProductDetail, warrantyTemplateList)
    }
    //如果设置了 不显示弹窗并且只有一个产品 直接打印质保卡
    let setting = caseSettingList.value.find(x => x.settingItem === caseSettingItem.打印质保卡不显示模板选择弹窗)
    if (setting && setting.intResult) {
        printAllWarranty()
        return
    }
    warrantProductName.value = ''
    printWarrantyVisible.value = true
}
const setDefaultWarrant = () => {
    let storeProductDetail = productDetails.value.find(x => x.id === warrantyProductId.value)
    if (storeProductDetail) {
        warrantyTemplateId.value = getDefaultWarrantyId(storeProductDetail, warrantyTemplateList)
    }
}
const printAllWarranty = async () => {
    printResList.value = []
    let isSkip = 1
    for (let productId of warrantyProductIds.value) {
        let storeProductDetail = productDetails.value.find(x => x.id === productId)
        if (storeProductDetail) {
            let warrantyTemplateId = getDefaultWarrantyId(storeProductDetail, warrantyTemplateList)
            let warrantyTemplate = casePrintTemplateList.value.find(x => x.id === warrantyTemplateId)
            if (!warrantyTemplate) {
                ElMessage.error('打印模板不存在')
                return
            }
            isSkip = warrantyTemplate.isSkip
            let res = printWarranty(caseInfo, productId, warrantyTemplate, hiprint, productDetails.value, factorySetting)
            printResList.value.push(res)
        }
    }
    if (isSkip === 1 || printResList.value.length > 1) {
        await printNoScanList(hiprint)
    } else {
        printScanList()
    }
}
const warrantProductName = ref('')//单个产品打印时  自定义产品打印名称
const handlePrintWarranty = () => {
    let warrantyTemplate = casePrintTemplateList.value.find(x => x.id === warrantyTemplateId.value)
    if (!warrantyTemplate) {
        ElMessage.error('请选择打印模板')
        return
    }
    let caseProduct = caseInfo.productList.find(x => x.productId === warrantyProductId.value)
    if (!caseProduct) {
        ElMessage.error('请选择需要打印的产品')
        return
    } else if (warrantProductName.value) {
        caseProduct.warrantProductName = warrantProductName.value
    }
    let res = printWarranty(caseInfo, warrantyProductId.value, warrantyTemplate, hiprint, productDetails.value, factorySetting)
    printSingle(warrantyTemplate, res, hiprint)
    printWarrantyVisible.value = false
}
//打印自定义标签
const {
    printCaseLabel,
    printCaseProductLabel
} = usePrintCaseLabel()
const labelProductId = ref('')
const handlePrintCaseLabel = (id) => {
    let labelTemplate = casePrintTemplateList.value.find(x => x.id === id)
    if (!labelTemplate) {
        ElMessage.error('打印模板不存在')
        return
    }
    if (labelTemplate.mainItem === 1) {
        let res = printCaseLabel(caseInfo, factorySetting, storeHospitalDetail, productDetails, currentWorker.value.name, labelTemplate, hiprint)
        printSingle(labelTemplate, res, hiprint)
    } else {
        if (!labelProductId.value) {
            ElMessage.error('请选择打印产品')
            return
        }
        let res = printCaseProductLabel(caseInfo, labelProductId.value, productDetails, storeHospitalDetail, factorySetting, currentWorker.value.name, labelTemplate, hiprint)
        printSingle(labelTemplate, res, hiprint)
    }
}

// 订单允许修改
const allowChange = computed(() => {
    return !!facCase.isValid && (!facCase.extension || !facCase.extension.monthId) && facCase.caseState < caseStateEnum.已发货
})
//订单允许取消作废
const allowCancelValid = computed(() => {
    let within24 = false
    if (!!facCase.validTime) {
        var targetDate = new Date(facCase.validTime);
        var currentDate = new Date();
        if (currentDate - targetDate < 60 * 60 * 24 * 1000) {
            within24 = true
        }
    } else {
        within24 = true
    }
    return !!facCase.id && !facCase.isValid && (!facCase.extension || !facCase.extension.monthId) && facCase.caseState < caseStateEnum.已发货 && within24
})
// 订单允许更正
const allowMod = computed(() => {
    return !!facCase.id && !!facCase.isValid && (!facCase.extension || !facCase.extension.monthId) && facCase.caseState >= caseStateEnum.已发货
})
//订单财务已审核
const isFinanceAudit = computed(() => {
    return !!facCase.id && !!facCase.isValid && !!facCase.extension && facCase.extension.financeAuditState === auditStateEnum.通过
})
// 已经生产
const isProduce = computed(() => facCase.caseState >= caseStateEnum.加工中)
// 已经发货
const isOut = computed(() => facCase.caseState >= caseStateEnum.已发货)
// 快递信息
import {useSaveCaseExpress, useInitSendType, useGetCaseRoutes} from "@/use/case/useCaseExpress";

const {
    caseExpress,
    saveCaseExpressLoading,
    saveCaseExpressResult,
    saveCaseExpress
} = useSaveCaseExpress()
const {
    newCaseExpress,
    initSendType,
    changeReceiverType
} = useInitSendType()
const initCaseExpress = () => {
    let res = newCaseExpress()
    Object.assign(caseExpress, res)
}
watch(saveCaseExpressResult, (n, o) => {
    if (!!n) Object.assign(facCase.express, caseExpress)
})
/**
 * 根据订单医院、医生和科室设置 默认修改发货和收货信息
 */
const initCaseSendType = () => {
    let salesman = storeWorkerList.value.find(x => x.id === facCase.facWorkerId)
    // if (!salesman) return
    let res = initSendType(storeHospitalDetail, storeDoctor, salesman)
    Object.assign(caseExpress, res)
}
/**
 * 根据收货对象自动填充收货人和收货地址信息
 */
const changeCaseReceiverType = () => {
    let salesman = storeWorkerList.value.find(x => x.id === facCase.facWorkerId)
    // if (!salesman) return
    changeReceiverType(storeHospitalDetail, storeDoctor, salesman, caseExpress)
}
//省市区
import {useArea} from "@/util/area";
import TableToothMini from "@/components/tableToothMini";
import CaseHistory from "@/views/case/caseHistory";
import CaseStep from "@/views/case/caseStep";
import CaseQuestion from "@/views/case/caseQuestion";

const {
    provinceList,
    cityList,
    areaList,
    changeProvince,
    changeCity,
    initSelect
} = useArea(caseExpress)
//种植
const plantSystemList = computed(() => store.state.plantSystemList)
const plantRetentionList = computed(() => store.state.plantRetentionList)
//查快递
const {getCaseRoutes, getCaseRoutesLoading, caseRoutes} = useGetCaseRoutes()
const handleSearchExpress = async () => {
    if (!facCase.caseNo || !caseExpress.expressNo) return
    await getCaseRoutes(facCase.caseNo)
}
//退货
const {
    returnCaseParam,
    returnCase,
    returnCaseResult,
    returnCaseLoading
} = useReturnCase()
const returnVisible = ref(false)
const showReturnCase = () => {
    returnCaseParam.id = facCase.id
    returnCaseParam.returnDate = new Date()
    returnCaseParam.returnReason = ''
    returnVisible.value = true
}
const handleReturnCase = async () => {
    await returnCase()
    if (returnCaseResult.value) {
        facCase.caseState = caseStateEnum.已退货
        let item = casePage.value.find(x => x.id === facCase.id)
        if (item) item.caseState = caseStateEnum.已退货
        returnVisible.value = false
    }
}

// 自动拍照
import {useScanDevice} from "@/use/system/useLiangtian";
import {getPosObj} from "@/use/case/useTooth";

const {
    liangtianSetting,
    changeLiangtianSetting,
    resetBaseUrl,
    initLiangtianSetting,
    device,
    open_view1,
    getDeviceInfo,
    view1_scan,
    rotate,
} = useScanDevice()
const {
    addCaseFile,
    addCaseFileLoading,
    addCaseFileParam
} = useAddCaseFile()
const uploadLiangtian = useUploadFile()
uploadLiangtian.uploadParam.folderPrefix = factoryFolderPrefix.casePic
uploadLiangtian.uploadParam.fileUse = fileUseEnum.订单图片
const liangtianUpload = uploadLiangtian.uploadFile
const liangtianData = uploadLiangtian.uploadFileResult
const takePhoto = async id => {
    if (device.videoStatus === '未连接') {
        ElMessage.error('请先连接高拍仪并刷新设备状态')
        return
    }
    let file = await view1_scan()
    if (!file) return
    await liangtianUpload({file: file})
    addCaseFileParam.casePicType = '订单图片'
    addCaseFileParam.facCaseId = id
    addCaseFileParam.fileUse = fileUseEnum.订单图片
    addCaseFileParam.fileId = liangtianData.picId
    addCaseFileParam.miniFileId = liangtianData.miniPicId
    await addCaseFile()
}
// 牙色图片
const picVisible = ref(false)
const productFiles = ref([])
let curProduct = null
const showProductPic = row => {
    if (!facCase.facHospitalId || !row.productId) {
        ElMessage.error('请先选择客户和产品')
        return
    }
    picVisible.value = true
    productFiles.value = row.fileList
    curProduct = row
}
const finishUploadPic = param => {
    curProduct.fileList = param
    productFiles.value = param
}
</script>

<style scoped>
#view1, .scan-view {
    height: 225px;
    width: 300px;
    border: 1px solid #edf1f2;
}
</style>