<template>
    <span>{{ DoubleFixed(num, fixed) }}</span>&nbsp;
</template>
<!--示例-->
<!--
<doubleFix-span v-model:num="num" fixed=3></doubleFix-span>
-->
<!-- fixed值不填，默认保留2位小数 -->
<script setup>
const props = defineProps({
    num: Number,
    fixed: Number,
})

const DoubleFixed = function (num, fixed) {
    let tail = 2;
    if (fixed > 0) {
        tail = fixed;
    }
    return retain(num, tail);
}

const retain = function (value, n) {
    if (n === 'null' || n === 'undefined' || n === 0) return parseInt(value);
    let tran = Math.round(value * Math.pow(10, n)) / Math.pow(10, n);
    let tranV = tran.toString();
    let newVal = tranV.indexOf('.');
    if (newVal < 0) {
        tranV += '.'
    }
    ;
    for (let i = tranV.length - tranV.indexOf('.'); i <= n; i++) {
        tranV += '0';
    }
    ;
    return tranV
}


</script>