import {reactive, ref, inject} from 'vue'
import {
    facWorkerGetSimpleList,
    facWorkerDelete,
    facWorkerGetPage,
    facWorkerSave,
    facWorkerUpdateValid,
    facWorkerBatchValid,
    facWorkerGet,
    facWorkerGetUser, facWorkerExcelList,
} from "@/api/workerMgt";
import moment from 'moment'
import {cnPhoneNoReg} from "@/util/validator";
import {useStore} from 'vuex'
import http from "@/util/axios";

//保存工厂员工 oycm 2022/7/19
function useSaveFacWorker() {
    const facWorker = reactive({
        id: 0,
        workerName: '',
        workerNo: '',
        phoneNo: '',
        deptIds: [],
        jobId: null,
        leaderId: null,
        gender: '',
        race: '',
        birthday: '',
        politicalState: null,
        bloodType: '',
        marriageState: '',
        educationLevel: null,
        idnumber: '',
        techLevelId: null,
        workerState: null,
        province: '',
        city: '',
        area: '',
        address: '',
        houseType: '',
        houseAddress: '',
        emergencyPerson: '',
        emergencyPhoneNo: '',
        introducer: '',
        introducerRelation: '',
        selfEvaluation: '',
        financeNo: '',
        salaryRequire: '',
        accommodation: null,
        socialState: null,
        socialNo: '',
        socialBeginDate: '',
        inCompanyDate: '',
        inWorkshopDate: '',
        regularDate: '',
        officePhoneNo: '',
        emailAddress: '',
        annualVacationDate: '',
        familyMember: '',
        educationHistory: '',
        jobHistory: '',
        district: '',
        isValid: 1,
    })
    const facWorkerEmpty = Object.assign({}, facWorker)
    const initFacWorker = () => {
        Object.assign(facWorker, facWorkerEmpty)
    }
    const facWorkerFormRef = ref()
    const facWorkerSaveRules = reactive({
        workerName: [
            {required: true, message: '请输入姓名', trigger: 'blur'},
        ],
        phoneNo: [
            {required: true, pattern: cnPhoneNoReg, message: '请输入正确的手机号', trigger: 'blur'},
        ],
        deptIds: [
            {required: true, message: '请选择部门', trigger: 'blur'},
        ],
    })
    const saveFacWorkerLoading = ref(false)
    const saveFacWorkerResult = ref(false)
    const saveFacWorker = async () => {
        saveFacWorkerResult.value = false
        if (saveFacWorkerLoading.value) return
        saveFacWorkerLoading.value = true
        await facWorkerFormRef.value.validate(async (valid, fields) => {
            if (!valid) {
                saveFacWorkerLoading.value = false
                return
            }
            saveFacWorkerResult.value = await facWorkerSave(facWorker)
            saveFacWorkerLoading.value = false
        })
    }
    return {
        facWorker,
        initFacWorker,
        facWorkerFormRef,
        facWorkerSaveRules,
        saveFacWorkerLoading,
        saveFacWorkerResult,
        saveFacWorker,
    }
}

//删除工厂员工 oycm 2022/7/19
function useDeleteFacWorker() {
    const deleteFacWorkerLoading = ref(false)
    const deleteFacWorkerResult = ref(false)
    const deleteFacWorker = async id => {
        deleteFacWorkerResult.value = false
        if (deleteFacWorkerLoading.value) return
        deleteFacWorkerLoading.value = true
        deleteFacWorkerResult.value = await facWorkerDelete({id: id})
        deleteFacWorkerLoading.value = false
    }
    return {
        deleteFacWorkerLoading,
        deleteFacWorkerResult,
        deleteFacWorker,
    }
}

//停用启用员工
function useUpdateValidFacWorker() {
    const updateValidFacWorkerLoading = ref(false)
    const updateValidFacWorkerResult = ref(false)
    const updateValidFacWorker = async (id, isValid) => {
        updateValidFacWorkerResult.value = false
        if (updateValidFacWorkerLoading.value) return
        updateValidFacWorkerLoading.value = true
        updateValidFacWorkerResult.value = await facWorkerUpdateValid({id: id, isValid: isValid})
        updateValidFacWorkerLoading.value = false
    }
    return {
        updateValidFacWorkerLoading,
        updateValidFacWorkerResult,
        updateValidFacWorker,
    }
}

//缓存列表工厂员工和当前员工 oycm 2022/7/19
function useGetListFacWorker() {
    const getListFacWorkerLoading = ref(false)
    const facWorkerList = ref([])
    const getListFacWorker = async () => {
        if (getListFacWorkerLoading.value) return
        getListFacWorkerLoading.value = true
        let data = await facWorkerGetSimpleList({})
        if (data) {
            facWorkerList.value = data
        }
        getListFacWorkerLoading.value = false
    }
    const store = useStore()
    const updateStoreWorker = async () => {
        if (getListFacWorkerLoading.value) return
        getListFacWorkerLoading.value = true
        let data = await facWorkerGetSimpleList({})
        if (!data) return
        store.commit('setWorkerList', data)
        let userName = store.state.userName
        if (!!userName && data.length) {
            let worker = data.find(x => x.name === userName)
            store.commit('setCurrentWorker', worker)
        } else {
            store.commit('setCurrentWorker', '')
        }
        getListFacWorkerLoading.value = false
    }
    return {
        getListFacWorkerLoading,
        facWorkerList,
        updateStoreWorker,
        getListFacWorker,
    }
}

//获取
function useGetFacWorker() {
    const getFacWorkerLoading = ref(false)
    const getFacWorker = async id => {
        if (getFacWorkerLoading.value) return
        getFacWorkerLoading.value = true
        let res = await facWorkerGet({id: id})
        getFacWorkerLoading.value = false
        if (res) return res
    }
    return {
        getFacWorker,
        getFacWorkerLoading
    }
}

//分页工厂员工 oycm 2022/7/19
function useGetPageFacWorker() {
    const getPageFacWorkerParam = reactive({
        id: null,
        basicInfo: '',
        deptId: '',
        inCompanyBeginDate: '',
        inCompanyEndDate: '',
        pageIndex: 1,
        pageSize: 10,
    })
    const initGetPageFacWorkerParam = () => {
        getPageFacWorkerParam.pageIndex = 1
        getPageFacWorkerParam.basicInfo = ''
        getPageFacWorkerParam.deptId = ''
    }
    const getPageFacWorkerLoading = ref(false)
    const facWorkerPage = ref([])
    const getPageFacWorkerResult = reactive({
        totalCount: 0,
        totalPages: 0,
    })
    const getPageFacWorker = async () => {
        if (getPageFacWorkerLoading.value) return
        getPageFacWorkerLoading.value = true
        let data = await facWorkerGetPage(getPageFacWorkerParam)
        if (data && data.list && data.list.length) {
            facWorkerPage.value = data.list
            getPageFacWorkerResult.totalPages = Math.ceil(data.totalCount / getPageFacWorkerParam.pageSize)
            getPageFacWorkerResult.totalCount = data.totalCount
        } else {
            facWorkerPage.value = []
            getPageFacWorkerResult.totalPages = 0
            getPageFacWorkerResult.totalCount = 0
        }
        getPageFacWorkerLoading.value = false
    }
    return {
        getPageFacWorkerParam,
        initGetPageFacWorkerParam,
        getPageFacWorkerLoading,
        facWorkerPage,
        getPageFacWorkerResult,
        getPageFacWorker,
    }
}

function useGetUserFacWorker() {
    const facWorkerUser = reactive({
        id: 0,
        userName: '',
        roleId: null
    })
    const getUserFacWorkerLoading = ref(false)
    const setFields = inject('setFields')
    const getUserFacWorker = async workerId => {
        if (getUserFacWorkerLoading.value) return
        getUserFacWorkerLoading.value = true
        let data = await facWorkerGetUser({id: workerId})
        if (data) {
            setFields(facWorkerUser, data)
        }
        getUserFacWorkerLoading.value = false
    }
    return {
        facWorkerUser,
        getUserFacWorkerLoading,
        getUserFacWorker,
    }
}

/**
 * 递归获取员工所有下级
 * @param workerId 当前员工ID
 * @param storeWorkerListValue 缓存员工列表
 * @param subWorkerIdArr 所有下级ID数组
 */
function getSubWorkerIds(workerId, storeWorkerListValue, subWorkerIdArr) {
    if (!workerId) return
    if (!storeWorkerListValue || !storeWorkerListValue.length) return
    if (!subWorkerIdArr) subWorkerIdArr = []
    if (storeWorkerListValue.some(x => x.leaderId === workerId)) {
        let subArr = storeWorkerListValue.filter(x => x.leaderId === workerId).map(x => x.id)
        for (let subId of subArr) {
            //防止上级设置错误 导致无限递归
            if (subWorkerIdArr.indexOf(subId) >= 0) return;
            subWorkerIdArr.push(subId)
        }
        for (let subId of subArr) {
            getSubWorkerIds(subId, storeWorkerListValue, subWorkerIdArr)
        }
    }
}

// 批量停用
function useBatchValidFacWorker() {
    const batchValidFacWorkerLoading = ref(false)
    const batchValidFacWorkerResult = ref(false)
    const batchValidFacWorker = async ids => {
        batchValidFacWorkerResult.value = false
        if (batchValidFacWorkerLoading.value) return
        batchValidFacWorkerLoading.value = true
        batchValidFacWorkerResult.value = await facWorkerBatchValid({ids: ids})
        batchValidFacWorkerLoading.value = false
    }
    return {
        batchValidFacWorkerLoading,
        batchValidFacWorkerResult,
        batchValidFacWorker,
    }
}

const exportWorkerList = req => {
    req.token = sessionStorage.getItem('token')
    let url = facWorkerExcelList + '?' + http.getQueryFromJson(req)
    window.open(url, '_blank')
}

export {
    useSaveFacWorker,
    useDeleteFacWorker,
    useGetListFacWorker,
    useGetPageFacWorker,
    useUpdateValidFacWorker,
    useGetUserFacWorker,
    getSubWorkerIds,
    useBatchValidFacWorker,
    exportWorkerList,
    useGetFacWorker,
}