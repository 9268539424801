<template>
    <div>
        <el-button v-show="!!innerFileToken" type="primary" size="small" plain @click="showPic">查看图片</el-button>
        <el-dialog v-model="picVisible" append-to-body>
            <el-image :src="picUrl">
            </el-image>
        </el-dialog>
    </div>
</template>

<script setup>
import {ref, reactive, onMounted, watch} from 'vue'
import {useGetFileUrl} from "@/use/system/useUpload";

const props = defineProps({
    fileToken: String,
})
const {getFileUrl} = useGetFileUrl()
const innerFileToken = ref()
const picUrl = ref()//图片url
watch(() => props.fileToken, async (n, o) => {
    innerFileId.value = n
    if (n) {
        picUrl.value = await getFileUrl(n)
    }
})
onMounted(async () => {
    innerFileToken.value = props.fileToken
    if (props.fileToken) {
        picUrl.value = await getFileUrl(props.fileToken)
    }
})
const picVisible = ref(false)
const showPic = () => {
    picVisible.value = true
}
</script>