/**
 * 通过枚举名称获取ID
 * @param name
 * @param enumList
 * @returns {null|*}
 */
export const getEnumId = (name, enumList) => {
    if (!enumList || !enumList.length) return null
    if (!name) return null
    return enumList.find(x => x.name === name).id
}
/**
 * 通过枚举ID获取名称
 * @param id
 * @param enumList
 * @returns {string|*}
 */
export const getEnumName = (id, enumList) => {
    if (!enumList || !enumList.length) return ''
    if (id === null || id === undefined) return ''
    return enumList.find(x => x.id === id).name
}
//通用获取枚举列表函数
export const getEnumList = enums => {
    let enumList = []
    for (let item in enums) {
        enumList.push({
            id: enums[item],
            name: item
        })
    }
    return enumList
}
//性别
export const genderList = ['男', '女']
//婚姻状况
export const marriageStateList = [
    {id: 1, name: '已婚'},
    {id: 2, name: '未婚'},
]
//户籍性质
export const houseTypeList = [
    {id: 1, name: '城镇'},
    {id: 2, name: '农村'},
]
//客户抱怨责任归属
export const resBelongList = [
    {id: 1, name: '公司原因'},
    {id: 2, name: '非公司原因'},
]
//内返类型
export const redoTypeEnum = {
    返修: 1,
    重做: 2
}
// 审核状态
export const auditStateEnum = {
    审核中: -2,
    不通过: -1,
    待审核: 0,
    通过: 1,
    无需审核: 2,
}
export const auditState = getEnumList(auditStateEnum)
// 审核中和审核通过的数据不允许修改
export const auditAllowSave = auditState => {
    return auditState !== auditStateEnum.通过 && auditState !== auditStateEnum.审核中
}
// 审核通过或无需审核的单据才可以进行出入库操作
export const allowStock = auditState => {
    return auditState === auditStateEnum.通过 || auditState === auditStateEnum.无需审核
}
// 通过或无需审核的数据是完成审核的状态
export const auditFinish = auditState => {
    return auditState === auditStateEnum.通过 || auditState === auditStateEnum.无需审核
}
// 订单状态
export const caseStateEnum = {
    已创建: 0,
    已发送: 3,
    入件: 7,
    加工中: 10,
    加工完成: 20,
    已装箱: 30,
    已发货: 40,
    快递下单: 41,
    快递单打印: 42,
    已收货: 50,
    已退货: 51
}
export const caseState = getEnumList(caseStateEnum)
// 订单类型
export const caseTypeEnum = {
    正常: 1,
    返工: 2,
    返修: 3,
    调整件: 4,
    试做: 5,
}
export const caseType = getEnumList(caseTypeEnum)
// 试戴状态
export const tryStateEnum = {
    未试戴: 1,
    试戴中: 2,
    试戴调整: 3,
    试戴完成: 4,
}
export const tryState = getEnumList(tryStateEnum)
// 入库类型
export const stockInTypeEnum = {
    采购入库: 1,
    报溢单: 2,
    其他入库: 3,
    领料退库: 4,
}
export const stockInType = getEnumList(stockInTypeEnum)
// 出库类型
export const stockOutTypeEnum = {
    领料出库: 1,
    采购退货: 2,
    报损单: 3,
    其他出库: 4,
}
export const stockOutType = getEnumList(stockOutTypeEnum)

export const productStockOutTypeEnum = {
    领料出库: 1,
    采购退货: 2,
    报损单: 3,
    其他出库: 4,
}
export const productStockOutType = getEnumList(productStockOutTypeEnum)
//文件用途
export const fileUseEnum = {
    质检印章: 1,
    订单图片: 2,
    订单文件: 3,
    订单文件切片: 4,
    订单口扫文件: 5,
    固定资产: 6,
    产品图片: 7,
    材料图片: 8,
    设置: 9,
    医院: 10,
    供应商: 11,
    订单牙色: 12,
    工厂营业执照附件: 13,
    工厂许可证附件: 14,
    工厂备案附件: 15
}
export const fileUse = getEnumList(fileUseEnum)
// 资产使用状态
export const propertyUseStateEnum = {
    未使用: 1,
    使用中: 2,
    维修中: 3,
    已损坏: 4,
}
export const propertyUseState = getEnumList(propertyUseStateEnum)
// 资产种类
export const propertyBelongList = [
    {id: 0, name: '固定资产'},
    {id: 1, name: '低值易耗品'},
]
export const propertyBelongEnum = {
    固定资产: 0,
    低值易耗品: 1,
}
// 资产维修类型
export const propertyFixType = [
    {id: 1, name: '厂内维修'},
    {id: 2, name: '送外维修'},
]
// 消毒类型
export const disinfectTypeEnum = {
    入件消毒: 1,
    出件消毒: 2,
}
export const disinfectType = getEnumList(disinfectTypeEnum)
export const applyPurchaseStateEnum = {
    未采购: 1,
    部分采购: 2,
    采购完成: 3
}
// 采购单验收状态
export const purchaseStockInStateEnum = {
    未验收: 0,
    全部验收: 1,
    部分验收: 2,
}
export const purchaseStockInState = getEnumList(purchaseStockInStateEnum)
// 订单打印模板类型
export const printTemplateTypeEnum = {
    出货单: 1,
    确认单: 2,
    质保卡: 3,
    订单条形码: 4,
    订单标签: 5,
    材料条形码: 6
}
export const printTemplateType = getEnumList(printTemplateTypeEnum)
// 可拖动的元素类型
export const dragItemType = {
    TEXT: 'text',
    TABLE: 'table'
}
// 订单设置项目
export const caseSettingItem = {
    录单后自动打印条形码: 1,
    打印出货单不显示模板选择弹窗: 2,
    录单后不清空医院医生: 3,
    打印确认单不显示模板选择弹窗: 4,
    打印质保卡不显示模板选择弹窗: 5,
    订单出货后自动打印出货单: 6,
    录单后自动打印确认单: 7,
    订单编号前缀: 8,
    订单流水号长度: 9,
    返工返修订单不打印质保卡: 10,
    自动获取发货与收货方式: 11,
    生成商品编码: 101,
    追加生产日期: 102,
    追加失效日期: 103,
    追加生产批号: 104,
    追加序列号: 105,
    启用产品设置页面UDI: 106,
    厂商识别码: 107
}
// 订单编号前缀
export const caseNoDatePrefixEnum = {
    年月YYMM: 1,
    年月YYYYMM: 2,
    年月日YYMMDD: 3,
    年月日YYYYMMDD: 4,
}
export const caseNoDatePrefix = getEnumList(caseNoDatePrefixEnum)
// 库存提醒
export const stockAlertType = [
    {id: 1, name: '高于富裕库存'},
    {id: 2, name: '低于安全库存'},
    {id: 3, name: '低于最低库存'},
]
// 提醒类型
export const factoryAlertTypeEnum = {
    系统公告: 0,
    库存提醒: 1,
    员工生日提醒: 2,
    员工合同到期提醒: 3,
    员工健康证到期提醒: 4,
    员工转正提醒: 5,
    医生生日提醒: 6,
    供应商凭证到期提醒: 7,
    医院凭证到期提醒: 8,
    医院中标结束日期提醒: 9,
    订单在线沟通提醒: 10,
    内返提醒: 11,
    网络订单审核: 12
}
export const factoryAlertType = getEnumList(factoryAlertTypeEnum)
// 提醒方法（服务器英文名）
export const factoryAlertMethod = {
    库存提醒: 'stockAlert',
    消息全部已读: 'readAll',
    强制下线: 'logOut',
    今日最新订单: 'recentCaseList',
    更新缓存: 'updateStore',
    订单沟通: 'caseComment',
    文件上传成功: 'uploadSuccess',
    新网络订单: 'newNetOrder',
    通知提醒: 'factoryAlert',
    OSS提醒: 'ossAlert'
}
// 扫描操作类型
export const stepOperType = {
    制作接收: 1,
    制作完成: 2,
    // 组长接收: 3,
    // 组内质检: 4
}
// 缓存类型store
export const factoryWebStoreEnum = {
    dataValueList: 1,
    workerList: 2,
    hospitalList: 3,
    materialList: 4,
    productList: 5,
    doctorList: 6,
    materialSupplyList: 7,
    productTestTemplate: 8,
    factorySetting: 9,
    toothColorList: 10,
    agentList: 11,
    stepList: 12,
    plantRetention: 13,
    plantSystem: 14,
    productType: 17,
    materialType: 18
}
// 订单来源
export const orderSourceEnum = {
    工厂后台: 1,
    业务员APP: 2,
    医生APP: 3,
    医生第三方: 4,
    工厂后台销售: 5,
}
// 用户类型
export const userTypeEnum = {
    Factory: 1,
    Hospital: 3,
}
// 报表类型
export const factoryExportTemplateTypeEnum = {
    订单报表: 1,
    订单产品报表: 2,
}
// 发货类型
export const sendTypeEnum = {
    销售: 1,
    快递公司: 2,
    物流: 3
}
export const sendType = getEnumList(sendTypeEnum)
// 收货人
export const receiverTypeEnum = {
    医院收货: 1,
    科室收货: 2,
    销售: 3
}
export const receiverType = getEnumList(receiverTypeEnum)
// 牙位类型
export const toothTypeEnum = {
    单冠: 1,
    桥: 2,
    缺失: 4
}
export const toothType = getEnumList(toothTypeEnum)
// 审核表单类型
export const factoryFormTypeEnum = {
    供应商首营: 1,
    客户首营: 2,
    产品首营: 3,
    // 企业信息修改: 4,
    采购申请: 10,
    采购单: 11,
    入库单: 12,
    领料申请: 20,
    出库单: 21,
    对账单: 22,
    移库单: 30
}
export const factoryFormType = getEnumList(factoryFormTypeEnum)
// 采购状态
export const purchaseStateEnum = {
    待收货: 0,
    已退货: 1,
    已收货: 2,
    部分收货: 3,
    已发货: 4
}
export const purchaseStateList = getEnumList(purchaseStateEnum)
// 工厂关联类型
export const factoryRelateTypeEnum = {
    加工工厂: 1,
    入件工厂: 2,
    加工与入件工厂: 3,
}
export const factoryRelateType = getEnumList(factoryRelateTypeEnum)
// 工厂配置
export const factoryConfigTypeEnum = {
    工序设置: 0,
    消毒设置: 1,
    材料设置: 2,
    Excel别名设置: 3
}
export const factoryConfigItemEnum = {
    //工序设置
    制作完成前必须扫描制作接收: 1,
    //消毒设置
    消毒需要审核人: 101,
    入件消毒结束前禁止制作扫描: 102,
    //材料设置
    材料编号根据材料种类编号生成: 201,
    //Excel别名设置
    出货常用报表对账单Excel别名: 301
}
// 附件归属
export const attachBelongEnum = {
    医院: 1,
    工厂: 2
}
// 客户类型
export const customerTypeEnum = {
    公立医院: 1,
    民营医院: 2,
    门诊: 3,
    员工: 4,
    义齿工厂: 5
}
// 质检类型
export const stepCheckTypeEnum = {
    入厂质检: 1,
    出厂质检: 3
}
// 产品数量计算方式
export const productUnitTypeEnum = {
    牙位: 1,
    全口: 2,
    上下颌: 3
}
// 订单操作类型
export const caseOperTypeEnum = {
    新增: 1,
    修改: 2,
    作废: 3,
    装箱: 4,
    发货: 5,
    关联发票: 6,
    取消关联发票: 7,
    审核: 8,
    修改金额: 9,
    收货: 10,
    退货: 11,
    取消发货: 12,
    财务弃审: 13,
    财务审核: 14,
    取消作废: 15,
    口扫文件上传: 16,
    取消装箱: 17,
    顺丰下单: 18,
    取消顺丰单: 19
}
// 文件上传目录
export const factoryFolderPrefix = {
    asset: 'asset',
    product: 'product',
    material: 'material',
    caseFile: 'caseFile',
    setting: 'setting',
    hospital: 'hospital',
    supply: 'supply',
    casePic: 'casePic',
    caseColor: 'caseColor',
}
// 模块类型
export const moduleTypeEnum = {
    模块: 0,
    页面: 1,
    功能: 2,
}
// 领料申请状态
export const stockApplyStateEnum = {
    已创建: 1,
    已出库: 4,
}
// 增值服务
export const vasEnum = {
    UDI: 1,
    顺丰快递: 2,
    车间看板: 3,
    销售看板: 4,
    防伪二维码: 5
}
// 分类目录
export const categoryEnum = {
    一类: 1,
    二类: 2,
    三类: 4
}
// 折扣类型
export const discountTypeEnum = {
    百分比: 0,
    人民币: 1
}
//首营产品归属
export const ProductRegisterCategoryEnum = {
    一类: 0,
    二类: 1,
    三类: 2
}
// 仓库类型
export const warehouseTypeEnum = {
    材料仓库: 1,
    成品仓库: 2,
    综合仓库: 4,
}
//公司经营模式
export const factorySettingBusinessPattern = {
    批发兼零售: 1,
    零售: 2,
    批发: 3,
}
//数字化页面
export const digitalPageEnum = {
    数字模型处理: 1,
    数字模型设计: 5,
    数字模型排版: 8,
}
// 顺丰快递类型
export const sfTypeEnum = {
    顺丰特快: 1,
    顺丰标快: 2,
    顺丰即日: 6,
}
// 顺丰付款方式
export const sfPayMethodEnum = {
    寄方付: 1,
    收方付: 2,
    第三方付: 3,
}
// 拜访类型
export const visitTypeEnum = {
    上门拜访: 1,
    电话回访: 2,
    外出邀约: 3,
    参观来访: 4,
}
// 应用类型
export const appTypeEnum = {
    工厂管理平台: 1,
    工厂医生下单平台: 2,
    医生APP: 3,
    技工APP: 4,
}
// 模型类型
export const modelTypeEnum = {
    打印模型: 1,
    无模型: 2,
    仅设计: 3
}
// 盘点类型
export const stockCheckTypeEnum = {
    重点盘点: 1,
    全面盘点: 2
}