<template>
    <div>
        <el-button :icon="Plus" type="primary" @click="showSaveCaseQuestion(null)" plain>
            新增
        </el-button>
        <el-table :data="caseQuestionList" style="width:100%;" :border="true"
                  v-loading="getListCaseQuestionLoading">
            <el-table-column label="操作" width="120">
                <template #default="scope">
                    <el-button type="primary" @click="showSaveCaseQuestion(scope.row)" :icon="Edit"
                               plain
                               size="small"
                               title="编辑">
                    </el-button>
                    <el-popconfirm confirm-button-text="确认" cancel-button-text="取消" title="确认删除吗"
                                   @confirm="deleteCaseQuestion(scope.row.id)"
                                   :disabled="deleteCaseQuestionLoading">
                        <template #reference>
                            <el-button type="danger" :loading="deleteCaseQuestionLoading" :icon="Delete"
                                       size="small"
                                       plain
                                       title="删除">
                            </el-button>
                        </template>
                    </el-popconfirm>
                </template>
            </el-table-column>
            <el-table-column prop="operWorkerId" label="提问员工" width="100"
                             :formatter="(r,c,v)=>enumTypeFormatter(v,storeWorkerList)"/>
            <el-table-column prop="contactWorkerId" label="沟通员工" width="100"
                             :formatter="(r,c,v)=>enumTypeFormatter(v,storeWorkerList)"/>
            <el-table-column prop="askDate" :formatter="dateFormat" label="提问日期" width="120"/>
            <el-table-column prop="description" label="问题描述" min-width="150"/>
            <el-table-column prop="replyDate" :formatter="dateFormat" label="回复日期" width="120"/>
            <el-table-column prop="doctorReply" label="医生回复" min-width="150"/>
        </el-table>
        <el-dialog v-model="caseQuestionVisible" title="问单" width="500px">
            <el-form :model="caseQuestion" ref="caseQuestionFormRef" :rules="caseQuestionSaveRules" label-width="100px"
                     v-loading="saveCaseQuestionLoading">
                <el-form-item label="提问员工" prop="operWorkerId">
                    <worker-select v-model:worker-id="caseQuestion.operWorkerId" class='input-medium'></worker-select>
                </el-form-item>
                <el-form-item label="沟通员工" prop="contactWorkerId">
                    <worker-select v-model:worker-id="caseQuestion.contactWorkerId"
                                   class='input-medium'></worker-select>
                </el-form-item>
                <el-form-item label="提问日期" prop="askDate">
                    <el-date-picker type="date" v-model="caseQuestion.askDate"
                                    :shortcuts="dateShortcuts"></el-date-picker>
                </el-form-item>
                <el-form-item label="问题描述" prop="description">
                    <el-input type="textarea" maxlength="200" v-model.trim="caseQuestion.description"
                              :rows="2"></el-input>
                </el-form-item>
                <el-form-item label="回复日期" prop="replyDate">
                    <el-date-picker type="date" v-model="caseQuestion.replyDate"
                                    :shortcuts="dateShortcuts"></el-date-picker>
                </el-form-item>
                <el-form-item label="医生回复" prop="doctorReplay">
                    <el-input type="textarea" maxlength="200" v-model.trim="caseQuestion.doctorReply"
                              :rows="2"></el-input>
                </el-form-item>
            </el-form>
            <footer>
                <div style="width: 100%;display: flex;padding-top:10px;">
                    <div class="flex-grow"></div>
                    <el-button type="primary" :icon="Edit" @click="saveCaseQuestion" :loading="saveCaseQuestionLoading">
                        保存
                    </el-button>
                </div>
            </footer>
        </el-dialog>
    </div>
</template>

<script setup>
import {ref, reactive, onMounted, computed, defineProps, watch} from 'vue'
import {useStore} from 'vuex'
import {Plus, Search, Refresh, Edit, Delete, Check} from '@element-plus/icons-vue';
import {enumTypeFormatter, dateFormat} from "@/util/formatter";
import {dateShortcuts} from "@/util/constant";

const props = defineProps({
    id: Number,//订单ID
})

const store = useStore()
const storeWorkerList = computed(() => store.state.workerList)
const currentWorker = computed(() => store.state.currentWorker)
// 问单
import {useSaveCaseQuestion, useGetListCaseQuestion, useDeleteCaseQuestion} from "@/use/case/useCaseQuestion";

const {
    caseQuestion,
    initCaseQuestion,
    caseQuestionFormRef,
    caseQuestionSaveRules,
    saveCaseQuestionLoading,
    saveCaseQuestionResult,
    saveCaseQuestion,
} = useSaveCaseQuestion()
const {
    getListCaseQuestionLoading,
    caseQuestionList,
    getListCaseQuestion,
} = useGetListCaseQuestion()
const {
    deleteCaseQuestionLoading,
    deleteCaseQuestionResult,
    deleteCaseQuestion,
} = useDeleteCaseQuestion()
watch(() => props.id, (n, o) => {
    if (!!n) {
        getListCaseQuestion(n)
    } else {
        caseQuestionList.value = []
    }
})
watch(saveCaseQuestionResult, async (newVal, oldVal) => {
    if (!!newVal) {
        await getListCaseQuestion(props.id)
        caseQuestionVisible.value = false
    }
})
watch(deleteCaseQuestionResult, async (newVal, oldVal) => {
    if (!!newVal) {
        await getListCaseQuestion(props.id)
    }
})
const caseQuestionVisible = ref(false)

const showSaveCaseQuestion = row => {
    if (!!row) {
        Object.assign(caseQuestion, row)
    } else {
        initCaseQuestion()
        caseQuestion.contactWorkerId = currentWorker.value.id
        caseQuestion.askDate = new Date()
        caseQuestion.caseId = props.id
    }
    caseQuestionVisible.value = true
}

</script>