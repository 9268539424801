import http from "@/util/axios";
// 订单
export const caseGetNumBySource = data => http.factoryGet('case/get_num_by_source', data)
export const caseSave = data => http.post('case/save', data)
export const caseValid = data => http.factoryPost('case/valid', data)
export const caseCancelValid = data => http.factoryPost('case/cancel_valid', data)
export const caseGet = data => http.factoryGet('case/get', data)
export const caseGetNo = data => http.factoryGet('case/get_no', data)
export const caseGetPage = data => http.factoryGet('case/get_page', data)
export const caseGetSimplePage = data => http.factoryGet('case/get_simple_page', data)
export const caseUpdateDelay = data => http.factoryPost('case/update_delay', data)
export const caseBatchInvoice = data => http.factoryPost('case/batch_invoice', data)
export const caseCancelInvoice = data => http.factoryPost('case/cancel_invoice', data)
export const caseBatchCancelInvoice = data => http.factoryPost('case/batch_cancel_invoice', data)
export const caseGetInvoicePage = data => http.factoryGet('case/get_invoice_page', data)
export const caseGetListForInvoice = data => http.factoryGet('case/get_list_for_invoice', data)
export const caseGetLateList = data => http.factoryGet('case/get_late_list', data)
export const caseGetLateExcel = '/api/factory/caseExport/excel_late_case'
export const caseReturn = data => http.factoryPost('case/return', data)
export const caseModRemark = data => http.factoryPost('case/mod_remark', data)
export const caseInvoiceExcel = '/api/factory/caseExport/excel_invoice'
//修改订单财务审核状态
export const caseUpdateFinanceAudit = data => http.factoryPost('case/update_finance_audit', data)
// 外返
export const caseUpdateRedoDuty = data => http.factoryPost('caseProductRedo/update_redo_duty', data)
export const caseProductRedoGetPage = data => http.factoryGet('caseProductRedo/get_page', data)
// 操作记录
export const caseGetHistoryList = data => http.factoryGet('case/get_history_List', data)
export const caseHistoryGetPage = data => http.factoryGet('caseHistory/get_page', data)
// 出货
export const caseOut = data => http.factoryPost('caseOut/case_out', data)
export const caseOutScan = data => http.factoryPostData('caseOut/scan_case_out', data)
export const casePackScan = data => http.factoryPostData('caseOut/scan_case_pack', data)
export const casePackCancel = data => http.factoryPost('caseOut/cancel_case_pack', data)
export const caseOutGetTodayList = data => http.factoryGet('caseOut/today_case_out_list', data)
export const casePackGetSf = data => http.factoryGet('caseOut/get_pack_sf_route', data)
export const casePackGetList = data => http.factoryGet('caseOut/get_pack_list', data)
export const casePackOut = data => http.factoryPostData('caseOut/pack_case_out', data)
export const packCancelOut = data => http.factoryPost('caseOut/cancel_pack_out', data)
export const packCancelSf = data => http.factoryPost('caseOut/cancel_pack_sf', data)
export const caseGetRoutes = data => http.factoryGet('caseOut/get_route_list', data)
export const caseOutCancel = data => http.factoryPost('caseOut/cancel_case_out', data)
export const casePackGetPage = data => http.factoryGet('caseOut/get_pack_page', data)
export const casePackDetailListGet = data => http.factoryGet('caseOut/get_case_pack_detail_list', data)
export const casePackCreateSf = data => http.factoryPost('caseOut/pack_sf_create', data)
export const casePackSaveSf = data => http.factoryPost('caseOut/pack_sf_save', data)
export const casePackPrintSf = data => http.factoryGet('caseOut/print_sf_order', data)

export const caseExportExcel = '/api/factory/caseExport/excel'
export const caseProductExportExcel = '/api/factory/caseExport/excel_product'

export const caseProductGetPage = data => http.factoryGet('caseProduct/get_page', data)
export const caseProductGetList = data => http.factoryGet('caseProduct/get_list', data)
export const caseProductBatchAuditPrice = data => http.factoryPost('caseProduct/batch_audit_price', data)
// 打印模板设置
export const casePrintTemplateSave = data => http.factoryPostData('casePrintTemplate/save', data)
export const casePrintTemplateDelete = data => http.factoryPost('casePrintTemplate/delete', data)
export const casePrintTemplateGetList = data => http.factoryGet('casePrintTemplate/get_list', data)

// 成品放行单设置
export const caseReleaseSettingSaveList = data => http.factoryPost('caseReleaseSetting/save_list', data)
export const caseReleaseSettingDelete = data => http.factoryPost('caseReleaseSetting/delete', data)
export const caseReleaseSettingGetList = data => http.factoryGet('caseReleaseSetting/get_list', data)

// 订单设置
export const caseSettingSaveList = data => http.factoryPost('caseSetting/save_list', data)
export const caseSettingGetList = data => http.factoryGet('caseSetting/get_list', data)

// 订单快递信息
export const caseExpressSave = data => http.factoryPost('case/save_case_express', data)
export const caseExpressGet = data => http.factoryGet('case/get_case_express', data)

// 订单统计
export const caseStatByTime = data => http.factoryPost('caseStatistic/in_case_stat_by_time', data)
export const caseStatisticGetTodayList = data => http.factoryGet('caseStatistic/get_today_list', data)
export const caseStatisticGetDailyList = data => http.factoryGet('caseStatistic/get_daily_list', data)
export const caseStatisticGetTodayIn = data => http.factoryGet('caseStatistic/get_today_in', data)
export const caseStatisticGetDailySale = data => http.factoryGet('caseStatistic/get_daily_sale_stat', data)
export const caseStatisticGetMoneySum = data => http.factoryGet('caseStatistic/get_case_sum_money', data)
export const caseSaleMoneySumGet = data => http.factoryGet('caseStatistic/get_in_case_sum_money', data)
export const saleHospitalSumGet = data => http.factoryGet('caseStatistic/get_sale_hospital_sum', data)
export const saleHospitalDetailGet = data => http.factoryGet('caseStatistic/get_sale_hospital_detail', data)

export const caseStatisticGetFinance = data => http.factoryGet('caseStatistic/get_finance_case_num', data)
export const caseStatisticGetStepStat = data => http.factoryGet('caseStatistic/get_step_stat', data)
export const caseStatisticGetStepCaseList = data => http.factoryGet('caseStatistic/get_step_case_list', data)
export const caseMoneySumExcel = '/api/factory/caseExport/excel_case_money_sum'
export const caseStatisticDailyExcel = '/api/factory/caseExport/excel_case_daily_stat'
export const dailySaleStatExcel = '/api/factory/caseExport/excel_daily_sale_stat'
export const redoSumGet = data => http.factoryGet('caseStatistic/get_redo_sum', data)
export const redoSumExcel = '/api/factory/caseExport/excel_case_redo_list'

// 订单回款
export const caseNeedPayGetPage = data => http.factoryGet('casePay/get_page_case_need_pay', data)
export const caseNeedPayExcel = '/api/factory/casePay/excel_case_need_pay'
// 订单产品统计
export const dailyCaseProductStatGetPage = data => http.factoryGet('dailyCaseProductStat/get_page', data)
export const dailyCaseProductStatGetSumList = data => http.factoryGet('dailyCaseProductStat/get_sum_list', data)
export const dailyCaseProductStatGetTypeSumList = data => http.factoryGet('dailyCaseProductStat/get_type_sum_list', data)
export const dailyCaseProductStatGetOutList = data => http.factoryGet('dailyCaseProductStat/get_out_list', data)
export const inCaseProductGetStat = data => http.factoryGet('dailyCaseProductStat/get_in_product', data)
export const outCaseProductGetStat = data => http.factoryGet('dailyCaseProductStat/get_out_product', data)

export const inCaseProductStatExcel = '/api/factory/caseExport/excel_in_case_product_stat'
export const outCaseProductStatExcel = '/api/factory/caseExport/excel_out_case_product_stat'
export const dailyCaseProductStatExcel = '/api/factory/caseExport/excel_product_stat'
export const dailyCaseProductStatSumExcel = '/api/factory/caseExport/excel_product_stat_sum'
export const dailyCaseProductStatTypeSumExcel = '/api/factory/caseExport/excel_product_stat_type_sum'
export const redoInputExcel = '/api/factory/caseExport/excel_redo_input'

// 对账单
export const caseStatementSearch = data => http.factoryGet('caseStatement/search', data)
export const caseStatementSearchSum = data => http.factoryGet('caseStatement/search_sum', data)
export const caseStatementExcel = '/api/factory/caseExport/excel_case_statement'
export const caseMonthHospitalExcel = '/api/factory/caseExport/excel_case_month_hospital'
export const caseStatementListExcel = '/api/factory/caseExport/excel_case_statement_list'
export const caseMoneyFinishListExcel = '/api/factory/caseExport/excel_case_finish_list'
export const caseBatchCancelFinanceAudit = data => http.factoryPost('caseMonth/batch_cancel_finance_audit', data)//批量弃审
// 交接单
export const caseReceiptSearch = data => http.factoryGet('caseReceipt/search', data)
export const caseReceiptExcel = '/api/factory/caseExport/excel_case_receipt'
// 问单
export const caseQuestionGetList = data => http.factoryGet('caseQuestion/get_list', data)
export const caseQuestionSave = data => http.factoryPost('caseQuestion/save', data)
export const caseQuestionDelete = data => http.factoryPost('caseQuestion/delete', data)
// 订单沟通
export const caseCommentSend = data => http.post('caseComment/send', data)
export const caseCommentReadAll = data => http.factoryPost('caseComment/factory_read_all', data)
export const caseCommentGetList = data => http.factoryGet('caseComment/get_list', data)
export const caseCommentGetUnreadList = data => http.factoryGet('caseComment/get_factory_unread_list', data)
// 订单月结
export const caseSumForMonth = data => http.factoryGet('caseMonth/get_month_case_sum', data)
export const caseDetailForMonth = data => http.factoryGet('caseMonth/get_month_case_detail', data)
export const caseMonthBatchAdd = data => http.factoryPost('caseMonth/batch_add', data)
export const caseMonthAdd = data => http.factoryPostData('caseMonth/add', data)
export const caseMonthDeleteCase = data => http.factoryPost('caseMonth/delete_case', data)
export const caseMonthModCase = data => http.factoryPost('caseMonth/mod_case', data)
export const caseMonthDeleteHospital = data => http.factoryPost('caseMonth/delete_hospital', data)
export const caseMonthGetPage = data => http.factoryGet('caseMonth/get_page', data)
export const caseMonthGetList = data => http.factoryGet('caseMonth/get_list', data)
export const caseMonthUpdateInvoice = data => http.factoryPost('caseMonth/update_invoice', data)
export const caseMonthUpdateMoney = data => http.factoryPost('caseMonth/update_money', data)
export const caseMonthDeleteMoney = data => http.factoryPost('caseMonth/delete_money', data)
export const caseMonthMoneyGetList = data => http.factoryGet('caseMonth/get_money_list', data)
export const caseMoneyFinishGetList = data => http.factoryGet('caseMonth/get_money_list_all', data)
// 导出模板
export const factoryExportTemplateSave = data => http.factoryPost('factoryExportTemplate/save', data)
export const factoryExportTemplateDelete = data => http.factoryPost('factoryExportTemplate/delete', data)
export const factoryExportTemplateSort = data => http.factoryPost('factoryExportTemplate/sort', data)
export const factoryExportTemplateGetList = data => http.factoryGet('factoryExportTemplate/get_list', data)
export const caseTemplateExcel = '/api/factory/caseExport/excel_case_template'
export const caseDetailTemplateExcel = '/api/factory/caseExport/excel_case_detail_template'
// 订单拍照
export const caseGetForPhoto = data => http.factoryGet('case/get_case_for_photo', data)
export const caseFileAdd = data => http.factoryPost('case/add_case_file', data)
export const caseFileDelete = data => http.factoryPost('case/delete_case_file', data)
// 订单金额更改
export const casePriceUpdateAdd = data => http.factoryPost('casePriceUpdate/add', data)
export const casePriceUpdateAudit = data => http.factoryPost('casePriceUpdate/audit', data)
export const casePriceUpdateGetPage = data => http.factoryGet('casePriceUpdate/get_page', data)
// 附件设置
export const attachBatchAdd = data => http.factoryPost('attach/batch_add', data)
export const attachMod = data => http.factoryPost('attach/mod', data)
export const attachDelete = data => http.factoryPost('attach/delete', data)
export const attachGetList = data => http.factoryGet('attach/get_list', data)
// 网络订单
export const netOrderAuditFail = data => http.factoryPost('netOrder/audit_fail', data)
export const netOrderAuditPass = data => http.factoryPostBg('netOrder/audit_pass', data)
export const netOrderInvalid = data => http.factoryPost('netOrder/invalid', data)
export const netOrderSave = data => http.factoryPost('netOrder/save', data)
export const netOrderGet = data => http.factoryGet('netOrder/get', data)
export const netOrderGetPage = data => http.factoryGet('netOrder/get_page', data)
export const netOrderGetStat = data => http.factoryGet('netOrder/get_stat', data)
export const netOrderGetAuditStat = data => http.factoryGet('netOrder/get_audit_stat', data)
export const netOrderHasNew = data => http.factoryGet('netOrder/has_new', data)