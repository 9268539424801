<template>
    <el-alert type="warning">输入数据回车后会自动保存</el-alert>
    <el-table class="w-full" height="35">
        <el-table-column label="操作" width="220"/>
        <el-table-column label="数据值" min-width="200"/>
        <el-table-column label="序号" width="70"/>
        <el-table-column label="添加时间" width="200"/>
    </el-table>
    <el-form :model="dataValue" style="margin-top: 10px;" class="w-full" ref="dataValueFormRef" inline @submit.prevent
             :rules="dataValueAddRules">
        <el-row>
            <el-col :span="6" style="padding-left: 10px;">
                <el-form-item>
                    <el-button type="success" @click="saveDataValue" plain
                               :loading="saveDataValueLoading">
                        <plus v-if="!saveDataValueLoading" class="btn-icon"></plus>
                        新增
                    </el-button>
                </el-form-item>
            </el-col>
            <el-col :span="10" style="padding-left: 18px;">
                <el-form-item prop="dataValue">
                    <el-input v-model.trim="dataValue.dataValue" maxlength="50" ref="dataValueRef"
                              @keydown.enter="saveDataValue"
                              style="width:345px!important;"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
    <el-table :data="dataValueList" class="w-full" v-loading="getListDataValueLoading"
              :show-header="false">
        <el-table-column label="操作" width="220">
            <template #default="scope">
                <el-button-group>
                    <el-button type="primary" @click="saveModDataValue(scope.row)" plain title="保存"
                               :loading="modDataValueLoading" :icon="Edit">
                    </el-button>
                    <el-popconfirm
                        confirm-button-text="确认"
                        cancel-button-text="取消"
                        title="确认删除吗"
                        @confirm="deleteDataValue(scope.row.id)"
                        :disabled="deleteDataValueLoading"
                    >
                        <template #reference>
                            <el-button type="danger" :loading="deleteDataValueLoading" :icon="Delete" plain title="删除">
                            </el-button>
                        </template>
                    </el-popconfirm>
                    <el-button :icon="ArrowUp" type="success" plain title="上移" @click="moveUp(scope.row)"></el-button>
                    <el-button :icon="ArrowDown" type="warning" plain title="下移"
                               @click="moveDown(scope.row)"></el-button>
                </el-button-group>
            </template>
        </el-table-column>
        <el-table-column prop="dataValue" label="数据值" min-width="300">
            <template #default="scope">
                <el-input v-model.trim="scope.row.dataValue" maxlength="50"
                          @keydown.enter="saveModDataValue(scope.row)"></el-input>
            </template>
        </el-table-column>
        <el-table-column label="序号" width="70" prop="orderId"></el-table-column>
        <el-table-column prop="addTime" label="添加时间" width="200" :formatter="datetimeFormat"/>
    </el-table>
</template>

<script setup>
import {
    Plus,
    Search,
    Refresh,
    Edit,
    Delete,
    Check,
    Hide,
    View,
    Setting,
    ArrowUp,
    ArrowDown
} from '@element-plus/icons-vue';
import {datetimeFormat} from "@/util/formatter";
import {ref, reactive, inject, watch, toRef, defineProps, defineEmits, computed, onMounted} from 'vue'
import {
    useDeleteDataValue,
    useGetDataValueList,
    useModDataValue,
    useSaveDataValue,
    userSaveListDataValue
} from "@/use/system/useData";

const props = defineProps({
    dataId: Number
})
const emits = defineEmits(['changeData'])
const {
    dataValue,
    dataValueFormRef,
    initDataValue,
    dataValueAddRules,
    saveDataValueLoading,
    saveDataValueResult,
    saveDataValue
} = useSaveDataValue()

const {
    dataValueList,
    getListDataValue,
    getListDataValueLoading
} = useGetDataValueList()

const {
    deleteDataValueLoading,
    deleteDataValueResult,
    deleteDataValue
} = useDeleteDataValue()

onMounted(async () => {
    dataValue.dataId = props.dataId
    await getListDataValue(props.dataId)
})
const dataValueRef = ref()
watch(saveDataValueResult, async (newVal, oldVal) => {
    if (!!newVal) {
        await getListDataValue(props.dataId)
        initDataValue()
        dataValue.dataId = props.dataId
        dataValueRef.value.focus()
        emits('changeData', true)
    }
})
const {
    modDataValueLoading, modDataValueResult, modDataValue
} = useModDataValue()
const saveModDataValue = async row => {
    let param = {
        id: row.id,
        dataValue: row.dataValue,
        dataId: row.dataId,
        orderId: row.orderId,
    }
    await modDataValue(param)
}
watch(modDataValueResult, async (newVal, oldVal) => {
    if (!!newVal) {
        await getListDataValue(props.dataId)
        emits('changeData', true)
    }
})
watch(deleteDataValueResult, async (newVal, oldVal) => {
    if (!!newVal) {
        await getListDataValue(props.dataId)
        emits('changeData', true)
    }
})
// 上下移动
const {
    saveListDataValueResult,
    saveListDataValue,
    saveListDataValueLoading
} = userSaveListDataValue()

const moveUp = async row => {
    if (row.orderId <= 1) {
        return
    }
    let param = {
        dataId: row.dataId,
        valueList: []
    }
    let upperItem = dataValueList.value.find(x => x.orderId === row.orderId - 1)
    if (upperItem) {
        upperItem.orderId++
        param.valueList.push({
            id: upperItem.id,
            dataValue: upperItem.dataValue,
            orderId: upperItem.orderId
        })
    }
    row.orderId--
    param.valueList.push({
        id: row.id,
        dataValue: row.dataValue,
        orderId: row.orderId
    })
    dataValueList.value.sort((a, b) => {
        return a.orderId - b.orderId
    })
    await saveListDataValue(param)
    emits('changeData', true)
}

const moveDown = async row => {
    if (row.orderId >= dataValueList.value.length) {
        return
    }
    let param = {
        dataId: row.dataId,
        valueList: []
    }
    let lowerItem = dataValueList.value.find(x => x.orderId === row.orderId + 1)
    if (lowerItem) {
        lowerItem.orderId--
        param.valueList.push({
            id: lowerItem.id,
            dataValue: lowerItem.dataValue,
            orderId: lowerItem.orderId
        })
    }
    row.orderId++
    param.valueList.push({
        id: row.id,
        dataValue: row.dataValue,
        orderId: row.orderId
    })
    dataValueList.value.sort((a, b) => {
        return a.orderId - b.orderId
    })
    await saveListDataValue(param)
    emits('changeData', true)
}
</script>

<style scoped>

</style>