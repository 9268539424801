import {reactive, ref, inject, toRaw} from 'vue'
import moment from 'moment'
import {caseQuestionGetList, caseQuestionSave, caseQuestionDelete} from "@/api/case";

function useSaveCaseQuestion() {
    const caseQuestion = reactive({
        id: 0,
        caseId: '',
        operWorkerId: null,
        contactWorkerId: null,
        askDate: '',
        replyDate: '',
        description: '',
        doctorReply: ''
    })
    const caseQuestionEmpty = JSON.parse(JSON.stringify(toRaw(caseQuestion)))
    const setFields = inject('setFields')
    const initCaseQuestion = () => {
        setFields(caseQuestion, caseQuestionEmpty)
    }
    const caseQuestionFormRef = ref()
    const caseQuestionSaveRules = reactive({
        askDate: [
            {type: 'date', required: true, message: '请输入问单日期', trigger: 'blur'},
        ],
        caseId: [
            {required: true, message: '请输入订单号', trigger: 'blur'},
        ],
        contactWorkerId: [
            {required: true, message: '请输入联系员工', trigger: 'blur'},
        ],
    })
    const saveCaseQuestionLoading = ref(false)
    const saveCaseQuestionResult = ref(false)
    const saveCaseQuestion = async () => {
        saveCaseQuestionResult.value = false
        if (saveCaseQuestionLoading.value) return
        saveCaseQuestionLoading.value = true
        await caseQuestionFormRef.value.validate(async (valid, fields) => {
            if (!valid) {
                saveCaseQuestionLoading.value = false
                return
            }
            saveCaseQuestionResult.value = await caseQuestionSave(caseQuestion)
            saveCaseQuestionLoading.value = false
        })
    }
    return {
        caseQuestion,
        initCaseQuestion,
        caseQuestionFormRef,
        caseQuestionSaveRules,
        saveCaseQuestionLoading,
        saveCaseQuestionResult,
        saveCaseQuestion,
    }
}

function useDeleteCaseQuestion() {
    const deleteCaseQuestionLoading = ref(false)
    const deleteCaseQuestionResult = ref(false)
    const deleteCaseQuestion = async id => {
        deleteCaseQuestionResult.value = false
        if (deleteCaseQuestionLoading.value) return
        deleteCaseQuestionLoading.value = true
        deleteCaseQuestionResult.value = await caseQuestionDelete({id: id})
        deleteCaseQuestionLoading.value = false
    }
    return {
        deleteCaseQuestionLoading,
        deleteCaseQuestionResult,
        deleteCaseQuestion,
    }
}

function useGetListCaseQuestion() {
    const getListCaseQuestionLoading = ref(false)
    const caseQuestionList = ref([])
    const getListCaseQuestion = async caseId => {
        if (getListCaseQuestionLoading.value) return
        getListCaseQuestionLoading.value = true
        let data = await caseQuestionGetList({id: caseId})
        if (data) {
            caseQuestionList.value = data
        }
        getListCaseQuestionLoading.value = false
    }
    return {
        getListCaseQuestionLoading,
        caseQuestionList,
        getListCaseQuestion,
    }
}

export {
    useSaveCaseQuestion,
    useGetListCaseQuestion,
    useDeleteCaseQuestion
}