import {createRouter, createWebHistory} from 'vue-router'
import homeView from '../views/HomeView.vue'
import warrantyApp from '../views/warranty/warrantyApp'
import error from '../views/404.vue'
import loginView from '../views/LoginView'
import welcome from '../views/welcome'
import caseAdd from '../views/case/caseAdd'
import caseOutScan from '../views/case/caseOutScan'
import casePackOut from '../views/case/casePackOut'
import produceScan from '../views/produce/produceScan'
import purchaseApply from '../views/purchase/purchaseApply'
import {ElMessage} from "element-plus";

const routes = [
    {
        path: '/',
        name: 'login',
        component: loginView,
    },
    {
        path: '/factoryHelp',
        name: 'factoryHelp',
        component: () => import('../views/system/factoryHelp'),
    },
    {
        path: '/workshopBoard',
        name: 'workshopBoard',
        component: () => import('../views/board/workshopBoard'),
    },
    {
        path: '/stepBoard',
        name: 'stepBoard',
        component: () => import('../views/board/stepBoard'),
    },
    {
        path: '/bigSaleBoard',
        name: 'bigSaleBoard',
        component: () => import('../views/board/bigSaleBoard'),
    },
    {
        path: '/warranty',
        name: 'warranty',
        component: warrantyApp,
        children: [
            {
                path: 'warrantyHome',
                name: 'warrantyHome',
                component: () => import('../views/warranty/warrantyHome'),
            },
            {
                path: 'warrantyCase',
                name: 'warrantyCase',
                component: () => import('../views/warranty/warrantyCase'),
            },
            {
                path: 'warrantyStepList',
                name: 'warrantyStepList',
                component: () => import('../views/warranty/warrantyStepList'),
            },
            {
                path: 'warrantyOutCheck',
                name: 'warrantyOutCheck',
                component: () => import('../views/warranty/warrantyOutCheck'),
            },
        ]
    },
    {
        path: '/admin',
        name: 'home',
        component: homeView,
        children: [
            {
                path: 'welcome',
                name: 'welcome',
                component: welcome,
            },
            {
                path: 'homeInfo',
                name: 'homeInfo',
                component: () => import('../views/system/homeInfo'),
            },
            {
                path: 'help',
                name: 'help',
                component: () => import('../views/system/help'),
            },
            {
                path: 'resetPassword',
                name: 'resetPassword',
                component: () => import('../views/resetPassword'),
            },
            {
                path: 'moduleList',
                name: 'moduleList',
                component: () => import('../views/system/moduleList'),
            },
            {
                path: 'doctorUserList',
                name: 'doctorUserList',
                component: () => import('../views/system/doctorUserList'),
            },
            {
                path: 'factoryList',
                name: 'factoryList',
                component: () => import('../views/system/factoryList'),
            },
            {
                path: 'jobList',
                name: 'jobList',
                component: () => import('../views/workerMgt/jobList'),
            },
            {
                path: 'techLevelList',
                name: 'techLevelList',
                component: () => import('../views/workerMgt/techLevelList'),
            },
            {
                path: 'deptList',
                name: 'deptList',
                component: () => import('../views/workerMgt/deptList'),
            },
            {
                path: 'initData',
                name: 'initData',
                component: () => import('../views/system/initData'),
            },
            {
                path: 'stockFlow',
                name: 'stockFlow',
                component: () => import('../views/warehouse/stockFlow'),
            },
            {
                path: 'stockCheck',
                name: 'stockCheck',
                component: () => import('../views/warehouse/stockCheck'),
            },
            {
                path: 'disinfect',
                name: 'disinfect',
                component: () => import('../views/produce/disinfect'),
            },
            {
                path: 'materialUseUp',
                name: 'materialUseUp',
                component: () => import('../views/produce/materialUseUp'),
            },
            {
                path: 'produceHome',
                name: 'produceHome',
                component: () => import('../views/produce/produceHome'),
            },
            {
                path: 'workerStepList',
                name: 'workerStepList',
                component: () => import('../views/produce/workerStepList'),
            },
            {
                path: 'leaderReceive',
                name: 'leaderReceive',
                component: () => import('../views/produce/leaderReceive'),
            },
            {
                path: 'produceScan',
                name: 'produceScan',
                component: produceScan,
            },
            {
                path: 'batchProduceScan',
                name: 'batchProduceScan',
                component: () => import('../views/produce/batchProduceScan'),
            },
            {
                path: 'leaderCheck',
                name: 'leaderCheck',
                component: () => import('../views/produce/leaderCheck'),
            },
            {
                path: 'stepRedo',
                name: 'stepRedo',
                component: () => import('../views/produce/stepRedo'),
            },
            {
                path: 'stepRedoList',
                name: 'stepRedoList',
                component: () => import('../views/produce/stepRedoList'),
            },
            {
                path: 'productStepCheck',
                name: 'productStepCheck',
                component: () => import('../views/produce/productStepCheck'),
            },
            {
                path: 'stepTestAdd',
                name: 'stepTestAdd',
                component: () => import('../views/produce/stepTestAdd'),
            },
            {
                path: 'stepTestList',
                name: 'stepTestList',
                component: () => import('../views/produce/stepTestList'),
            },
            {
                path: 'caseTrace',
                name: 'caseTrace',
                component: () => import('../views/produce/caseTrace'),
            },
            {
                path: 'stepMachineList',
                name: 'stepMachineList',
                component: () => import('../views/produce/stepMachineList'),
            },
            {
                path: 'stepMaterialList',
                name: 'stepMaterialList',
                component: () => import('../views/produce/stepMaterialList'),
            },
            {
                path: 'disinfectList',
                name: 'disinfectList',
                component: () => import('../views/produce/disinfectList'),
            },
            {
                path: 'productStepList',
                name: 'productStepList',
                component: () => import('../views/produce/productStepList'),
            },
            {
                path: 'productStepCheckList',
                name: 'productStepCheckList',
                component: () => import('../views/produce/productStepCheckList'),
            },
            {
                path: 'redoStat',
                name: 'redoStat',
                component: () => import('../views/produce/redoStat'),
            },
            {
                path: 'workerPointStat',
                name: 'workerPointStat',
                component: () => import('../views/produce/workerPointStat'),
            },
            {
                path: 'propertyCheck',
                name: 'propertyCheck',
                component: () => import('../views/property/propertyCheck'),
            },
            {
                path: 'customerComplain',
                name: 'customerComplain',
                component: () => import('../views/customer/customerComplain'),
            },
            {
                path: 'facHospitalFinalCase',
                name: 'facHospitalFinalCase',
                component: () => import('../views/customer/facHospitalFinalCase'),
            },
            {
                path: 'toothColor',
                name: 'toothColor',
                component: () => import('../views/product/toothColor'),
            },
            {
                path: 'productRegister',
                name: 'productRegister',
                component: () => import('../views/product/productRegister'),
            },
            {
                path: 'saleTarget',
                name: 'saleTarget',
                component: () => import('../views/sale/saleTarget'),
            },
            {
                path: 'saleBoard',
                name: 'saleBoard',
                component: () => import('../views/sale/saleBoard'),
            },
            {
                path: 'saleMoneySum',
                name: 'saleMoneySum',
                component: () => import('../views/sale/saleMoneySum'),
            },
            {
                path: 'saleHospitalSum',
                name: 'saleHospitalSum',
                component: () => import('../views/sale/saleHospitalSum'),
            },
            {
                path: 'saleProductStat',
                name: 'saleProductStat',
                component: () => import('../views/sale/saleProductStat'),
            },
            {
                path: 'saleDetailList',
                name: 'saleDetailList',
                component: () => import('../views/sale/saleDetailList'),
            },
            {
                path: 'caseAdd',
                name: 'caseAdd',
                component: () => import('../views/case/caseAdd'),
                // component: caseAdd,
            },
            {
                path: 'caseList',
                name: 'caseList',
                component: () => import('../views/case/caseList'),
            },
            {
                path: 'netOrderAudit',
                name: 'netOrderAudit',
                component: () => import('../views/case/netOrderAudit'),
            },
            {
                path: 'caseHistoryList',
                name: 'caseHistoryList',
                component: () => import('../views/case/caseHistoryList'),
            },
            {
                path: 'caseDetailList',
                name: 'caseDetailList',
                component: () => import('../views/case/caseDetailList'),
            },
            {
                path: 'dailyOutCase',
                name: 'dailyOutCase',
                component: () => import('../views/case/dailyOutCase'),
            },
            {
                path: 'caseRedoList',
                name: 'caseRedoList',
                component: () => import('../views/case/caseRedoList'),
            },
            {
                path: 'caseOutDelayList',
                name: 'caseOutDelayList',
                component: () => import('../views/case/caseOutDelayList'),
            },
            {
                path: 'caseSetting',
                name: 'caseSetting',
                component: () => import('../views/case/caseSetting'),
            },
            {
                path: 'caseInvoice',
                name: 'caseInvoice',
                component: () => import('../views/case/caseInvoice'),
            },
            {
                path: 'caseRequirePay',
                name: 'caseRequirePay',
                component: () => import('../views/case/caseRequirePay'),
            },
            {
                path: 'caseRelease',
                name: 'caseRelease',
                component: () => import('../views/produce/caseRelease'),
                children: [
                    {
                        path: 'caseReleaseAdd',
                        name: 'caseReleaseAdd',
                        component: () => import('../views/produce/caseReleaseAdd'),
                    },
                    {
                        path: 'caseReleaseList',
                        name: 'caseReleaseList',
                        component: () => import('../views/produce/caseReleaseList'),
                    },
                ]
            },
            {
                path: 'finishTest',
                name: 'finishTest',
                component: () => import('../views/produce/finishTest'),
                children: [
                    {
                        path: 'finishTestAdd',
                        name: 'finishTestAdd',
                        component: () => import('../views/produce/finishTestAdd'),
                    },
                    {
                        path: 'finishTestList',
                        name: 'finishTestList',
                        component: () => import('../views/produce/finishTestList'),
                    },
                ]
            },
            {
                path: 'outCaseForm',
                name: 'outCaseForm',
                component: () => import('../views/stat/outCaseForm'),
                children: [
                    {
                        path: 'dailySaleStat',
                        name: 'dailySaleStat',
                        components: {
                            outCaseForm: () => import('../views/stat/dailySaleStat'),
                        },
                    },
                    {
                        path: 'caseReceipt',
                        name: 'caseReceipt',
                        components: {
                            outCaseForm: () => import('../views/case/caseReceipt'),
                        },
                    },
                    {
                        path: 'caseStatement',
                        name: 'caseStatement',
                        components: {
                            outCaseForm: () => import('../views/case/caseStatement'),
                        },
                    },
                    {
                        path: 'outMoneySum',
                        name: 'outMoneySum',
                        components: {
                            outCaseForm: () => import('../views/stat/outMoneySum'),
                        },
                    },
                ]
            },
            {
                path: 'caseAnalysis',
                name: 'caseAnalysis',
                component: () => import('../views/stat/caseAnalysis'),
                children: [
                    {
                        path: 'inCaseProductStat',
                        name: 'inCaseProductStat',
                        components: {
                            caseAnalysis: () => import('../views/case/inCaseProductStat'),
                        },
                    },
                    {
                        path: 'outCaseProductStat',
                        name: 'outCaseProductStat',
                        components: {
                            caseAnalysis: () => import('../views/stat/outCaseProductStat'),
                        },
                    },
                    {
                        path: 'dailyCaseStat',
                        name: 'dailyCaseStat',
                        components: {
                            caseAnalysis: () => import('../views/case/dailyCaseStat'),
                        },
                    },
                    {
                        path: 'dailyCaseProduct',
                        name: 'dailyCaseProduct',
                        components: {
                            caseAnalysis: () => import('../views/case/dailyCaseProduct'),
                        },
                    },
                    {
                        path: 'caseRedoSum',
                        name: 'caseRedoSum',
                        components: {
                            caseAnalysis: () => import('../views/stat/caseRedoSum'),
                        },
                    },
                    {
                        path: 'complainStat',
                        name: 'complainStat',
                        components: {
                            caseAnalysis: () => import('../views/customer/complainStat'),
                        },
                    },
                ]
            },
            {
                path: 'dataHide',
                name: 'dataHide',
                component: () => import('../views/system/dataHide'),
                children: [
                    {
                        path: 'materialHide',
                        name: 'materialHide',
                        components: {
                            dataHide: () => import('../views/purchase/materialHide'),
                        },
                    },
                    {
                        path: 'productHide',
                        name: 'productHide',
                        components: {
                            dataHide: () => import('../views/product/productHide'),
                        },
                    },
                    {
                        path: 'customerHide',
                        name: 'customerHide',
                        components: {
                            dataHide: () => import('../views/customer/customerHide'),
                        },
                    },
                ]
            },
            {
                path: 'todayInStat',
                name: 'todayInStat',
                component: () => import('../views/salesMgt/todayInStat'),
            },
            {
                path: 'productStockNow',
                name: 'productStockNow',
                component: () => import('../views/plant/productStockNow'),
            },
            {
                path: 'plantStockFlow',
                name: 'plantStockFlow',
                component: () => import('../views/plant/plantStockFlow'),
            },
            {
                path: 'productStandard',
                name: 'productStandard',
                component: () => import('../views/product/productStandard'),
            },
            {
                path: 'produceSetting',
                name: 'produceSetting',
                component: () => import('../views/system/produceSetting'),
                children: [
                    {
                        path: 'releasePermit',
                        name: 'releasePermit',
                        components: {
                            produceSetting: () => import('../views/system/releasePermit'),
                        },
                    },
                    {
                        path: 'finishTestSetting',
                        name: 'finishTestSetting',
                        components: {
                            produceSetting: () => import('../views/system/finishTestSetting'),
                        },
                    },
                    {
                        path: 'stepList',
                        name: 'stepList',
                        components: {
                            produceSetting: () => import('../views/system/stepList'),
                        },
                    },
                    {
                        path: 'disinfectMethodList',
                        name: 'disinfectMethodList',
                        components: {
                            produceSetting: () => import('../views/system/disinfectMethodList'),
                        },
                    },
                    {
                        path: 'productTestTemplate',
                        name: 'productTestTemplate',
                        components: {
                            produceSetting: () => import('../views/product/productTestTemplate'),
                        },
                    },
                ]
            },
            {
                path: 'casePrintSet',
                name: 'casePrintSet',
                component: () => import('../views/system/casePrintSet'),
                children: [
                    {
                        path: 'caseOutSet',
                        name: 'caseOutSet',
                        components: {
                            casePrintSet: () => import('../views/system/caseOutSet'),
                        },
                    },
                    {
                        path: 'caseBarcode',
                        name: 'caseBarcode',
                        components: {
                            casePrintSet: () => import('../views/system/caseBarcode'),
                        },
                    },
                    {
                        path: 'materialBarcode',
                        name: 'materialBarcode',
                        components: {
                            casePrintSet: () => import('../views/system/materialBarcode'),
                        },
                    },
                    {
                        path: 'confirmSet',
                        name: 'confirmSet',
                        components: {
                            casePrintSet: () => import('../views/system/confirmSet'),
                        },
                    },
                    {
                        path: 'warrantySet',
                        name: 'warrantySet',
                        components: {
                            casePrintSet: () => import('../views/system/warrantySet'),
                        },
                    },
                    {
                        path: 'caseLabelSet',
                        name: 'caseLabelSet',
                        components: {
                            casePrintSet: () => import('../views/system/caseLabelSet'),
                        },
                    },
                ]
            },
            {
                path: 'supply',
                name: 'supply',
                component: () => import('../views/purchase/supply'),
                children: [
                    {
                        path: 'supplyList',
                        name: 'supplyList',
                        components: {
                            supply: () => import('../views/purchase/supplyList'),
                        },
                    },
                    {
                        path: 'supplyLicense',
                        name: 'supplyLicense',
                        components: {
                            supply: () => import('../views/purchase/supplyLicense'),
                        },
                    },
                    {
                        path: 'supplySearch',
                        name: 'supplySearch',
                        components: {
                            supply: () => import('../views/purchase/supplySearch'),
                        },
                    },
                    {
                        path: 'manufacturerList',
                        name: 'manufacturerList',
                        components: {
                            supply: () => import('../views/purchase/manufacturerList'),
                        },
                    },
                ]
            },
            {
                name: 'material',
                path: 'material',
                component: () => import('../views/purchase/material'),
                children: [
                    {
                        path: 'materialTypeList',
                        name: 'materialTypeList',
                        components: {
                            material: () => import('../views/purchase/materialTypeList'),
                        },
                    },
                    {
                        path: 'materialList',
                        name: 'materialList',
                        components: {
                            material: () => import('../views/purchase/materialList'),
                        },
                    },
                    {
                        path: 'materialSearch',
                        name: 'materialSearch',
                        components: {
                            material: () => import('../views/purchase/materialSearch'),
                        },
                    },
                    {
                        path: 'materialSupplySearch',
                        name: 'materialSupplySearch',
                        components: {
                            material: () => import('../views/purchase/materialSupplySearch'),
                        },
                    },
                ]
            },
            {
                name: 'purchaseMgt',
                path: 'purchaseMgt',
                component: () => import('../views/purchase/purchaseMgt'),
                children: [
                    {
                        path: 'purchaseApply',
                        name: 'purchaseApply',
                        components: {
                            purchaseMgt: () => import('../views/purchase/purchaseApply'),
                        },
                    },
                    {
                        path: 'purchaseAdd',
                        name: 'purchaseAdd',
                        components: {
                            purchaseMgt: () => import('../views/purchase/purchaseAdd'),
                        },
                    },
                    {
                        path: 'purchaseDetailList',
                        name: 'purchaseDetailList',
                        components: {
                            purchaseMgt: () => import('../views/purchase/purchaseDetailList'),
                        },
                    },
                    {
                        path: 'purchaseList',
                        name: 'purchaseList',
                        components: {
                            purchaseMgt: () => import('../views/purchase/purchaseList'),
                        },
                    },
                    {
                        path: 'purchasePay',
                        name: 'purchasePay',
                        components: {
                            purchaseMgt: () => import('../views/purchase/purchasePay'),
                        },
                    },
                ]
            },
            {
                name: 'plantPurchase',
                path: 'plantPurchase',
                component: () => import('../views/plant/plantPurchase'),
                children: [
                    {
                        path: 'productPurchaseApply',
                        name: 'productPurchaseApply',
                        components: {
                            plantPurchase: () => import('../views/plant/productPurchaseApply'),
                        },
                    },
                    {
                        path: 'productPurchaseAdd',
                        name: 'productPurchaseAdd',
                        components: {
                            plantPurchase: () => import('../views/plant/productPurchaseAdd'),
                        },
                    },
                    {
                        path: 'productPurchaseList',
                        name: 'productPurchaseList',
                        components: {
                            plantPurchase: () => import('../views/plant/productPurchaseList'),
                        },
                    },
                ]
            },
            {
                name: 'productSetting',
                path: 'productSetting',
                component: () => import('../views/product/productSetting'),
                children: [
                    {
                        path: 'productList',
                        name: 'productList',
                        components: {
                            productSetting: () => import('../views/product/productList'),
                        },
                    },
                    {
                        path: 'productTypeList',
                        name: 'productTypeList',
                        components: {
                            productSetting: () => import('../views/product/productTypeList'),
                        },
                    },
                    {
                        path: 'productSearch',
                        name: 'productSearch',
                        components: {
                            productSetting: () => import('../views/product/productSearch'),
                        },
                    },
                ]
            },
            {
                name: 'workerBasicInfo',
                path: 'workerBasicInfo',
                component: () => import('../views/workerMgt/workerBasicInfo'),
                children: [
                    {
                        path: 'workerList',
                        name: 'workerList',
                        components: {
                            workerBasicInfo: () => import('../views/workerMgt/workerList'),
                        },
                    },
                    {
                        path: 'workerSearch',
                        name: 'workerSearch',
                        components: {
                            workerBasicInfo: () => import('../views/workerMgt/workerSearch'),
                        },
                    },
                    {
                        path: 'facTrainList',
                        name: 'facTrainList',
                        components: {
                            workerBasicInfo: () => import('../views/workerMgt/facTrainList'),
                        },
                    },
                ]
            },
            {
                name: 'customerManage',
                path: 'customerManage',
                component: () => import('../views/customer/customerManage'),
                children: [
                    {
                        path: 'customerComplain',
                        name: 'customerComplain',
                        components: {
                            customerManage: () => import('../views/customer/customerComplain'),
                        },
                    },
                    {
                        path: 'facHospitalFinalCase',
                        name: 'facHospitalFinalCase',
                        components: {
                            customerManage: () => import('../views/customer/facHospitalFinalCase'),
                        },
                    },
                ]
            },
            {
                name: 'customerSetting',
                path: 'customerSetting',
                component: () => import('../views/customer/customerSetting'),
                children: [
                    {
                        path: 'hospitalList',
                        name: 'hospitalList',
                        components: {
                            customerSetting: () => import('../views/customer/hospitalList'),
                        },
                    },
                    {
                        path: 'hospitalSearch',
                        name: 'hospitalSearch',
                        components: {
                            customerSetting: () => import('../views/customer/hospitalSearch'),
                        },
                    },
                    {
                        path: 'doctorList',
                        name: 'doctorList',
                        components: {
                            customerSetting: () => import('../views/customer/doctorList'),
                        },
                    },
                    {
                        path: 'hospitalPriceSearch',
                        name: 'hospitalPriceSearch',
                        components: {
                            customerSetting: () => import('../views/customer/hospitalPriceSearch'),
                        },
                    },
                    {
                        path: 'privatePrice',
                        name: 'privatePrice',
                        components: {
                            customerSetting: () => import('../views/customer/privatePrice'),
                        },
                    },
                    {
                        path: 'agentList',
                        name: 'agentList',
                        components: {
                            customerSetting: () => import('../views/customer/agentList'),
                        },
                    },
                ]
            },
            {
                name: 'stepStatistics',
                path: 'stepStatistics',
                component: () => import('../views/stat/stepStatistics'),
                children: [
                    {
                        path: 'redoStat',
                        name: 'redoStat',
                        components: {
                            stepStatistics: () => import('../views/produce/redoStat'),
                        },
                    },
                    {
                        path: 'workerPointStat',
                        name: 'workerPointStat',
                        components: {
                            stepStatistics: () => import('../views/produce/workerPointStat'),
                        },
                    },
                ]
            },
            {
                name: 'propertySetting',
                path: 'propertySetting',
                component: () => import('../views/property/propertySetting'),
                children: [
                    {
                        path: 'assetList',
                        name: 'assetList',
                        components: {
                            propertySetting: () => import('../views/property/assetList'),
                        },
                    },
                    {
                        path: 'lowValueList',
                        name: 'lowValueList',
                        components: {
                            propertySetting: () => import('../views/property/lowValueList'),
                        },
                    },
                    {
                        path: 'propertyType',
                        name: 'propertyType',
                        components: {
                            propertySetting: () => import('../views/property/propertyType'),
                        },
                    },
                    {
                        path: 'propertyList',
                        name: 'propertyList',
                        components: {
                            propertySetting: () => import('../views/property/propertyList'),
                        },
                    },
                ]
            },
            {
                path: 'caseFinance',
                name: 'caseFinance',
                component: () => import('../views/case/caseFinance'),
                children: [
                    {
                        path: 'batchModCaseMoney',
                        name: 'batchModCaseMoney',
                        components: {
                            caseFinance: () => import('../views/case/batchModCaseMoney'),
                        },
                    },
                    {
                        path: 'caseMonthAdd',
                        name: 'caseMonthAdd',
                        components: {
                            caseFinance: () => import('../views/case/caseMonthAdd'),
                        },
                    },
                    {
                        path: 'caseMonthList',
                        name: 'caseMonthList',
                        components: {
                            caseFinance: () => import('../views/case/caseMonthList'),
                        },
                    },
                    {
                        path: 'caseMoney',
                        name: 'caseMoney',
                        components: {
                            caseFinance: () => import('../views/case/caseMoney'),
                        },
                    },
                    {
                        path: 'caseMonthFinish',
                        name: 'caseMonthFinish',
                        components: {
                            caseFinance: () => import('../views/case/caseMonthFinish'),
                        },
                    },
                    {
                        path: 'caseMoneyFinishList',
                        name: 'caseMoneyFinishList',
                        components: {
                            caseFinance: () => import('../views/case/caseMoneyFinishList'),
                        },
                    },
                ]
            },
            {
                name: 'warehouseSetting',
                path: 'warehouseSetting',
                component: () => import('../views/warehouse/warehouseSetting'),
                children: [
                    {
                        path: 'warehouseList',
                        name: 'warehouseList',
                        components: {
                            warehouseSetting: () => import('../views/warehouse/warehouseList'),
                        },
                    },
                    {
                        path: 'stockInCheckTemplate',
                        name: 'stockInCheckTemplate',
                        components: {
                            warehouseSetting: () => import('../views/warehouse/stockInCheckTemplate'),
                        },
                    },
                    {
                        path: 'stockBegin',
                        name: 'stockBegin',
                        components: {
                            warehouseSetting: () => import('../views/warehouse/stockBegin'),
                        },
                    },
                ]
            },
            {
                name: 'materialStock',
                path: 'materialStock',
                component: () => import('../views/warehouse/materialStock'),
                children: [
                    {
                        path: 'stockNow',
                        name: 'stockNow',
                        components: {
                            materialStock: () => import('../views/warehouse/stockNow'),
                        },
                    },
                    {
                        path: 'stockNowDetail',
                        name: 'stockNowDetail',
                        components: {
                            materialStock: () => import('../views/warehouse/stockNowDetail'),
                        },
                    },
                    {
                        path: 'stockAlert',
                        name: 'stockAlert',
                        components: {
                            materialStock: () => import('../views/warehouse/stockAlert'),
                        },
                    },
                ]
            },
            {
                name: 'stockIn',
                path: 'stockIn',
                component: () => import('../views/warehouse/stockIn'),
                children: [
                    {
                        path: 'stockInAdd',
                        name: 'stockInAdd',
                        components: {
                            stockIn: () => import('../views/warehouse/stockInAdd'),
                        },
                    },
                    {
                        path: 'stockInDetailList',
                        name: 'stockInDetailList',
                        components: {
                            stockIn: () => import('../views/warehouse/stockInDetailList'),
                        },
                    },
                    {
                        path: 'purchaseStockIn',
                        name: 'purchaseStockIn',
                        components: {
                            stockIn: () => import('../views/warehouse/purchaseStockIn'),
                        },
                    },
                ]
            },
            {
                name: 'stockOut',
                path: 'stockOut',
                component: () => import('../views/warehouse/stockOut'),
                children: [
                    {
                        path: 'stockOutAdd',
                        name: 'stockOutAdd',
                        components: {
                            stockOut: () => import('../views/warehouse/stockOutAdd'),
                        },
                    },
                    {
                        path: 'stockOutDetailList',
                        name: 'stockOutDetailList',
                        components: {
                            stockOut: () => import('../views/warehouse/stockOutDetailList'),
                        },
                    },
                    {
                        path: 'stockApplyAdd',
                        name: 'stockApplyAdd',
                        components: {
                            stockOut: () => import('../views/warehouse/stockApplyAdd'),
                        },
                    },
                ]
            },
            {
                name: 'stockMove',
                path: 'stockMove',
                component: () => import('../views/warehouse/stockMove'),
                children: [
                    {
                        path: 'stockMoveAdd',
                        name: 'stockMoveAdd',
                        components: {
                            stockMove: () => import('../views/warehouse/stockMoveAdd'),
                        },
                    },
                    {
                        path: 'stockMoveList',
                        name: 'stockMoveList',
                        components: {
                            stockMove: () => import('../views/warehouse/stockMoveList'),
                        },
                    },
                ]
            },
            {
                name: 'productStockIn',
                path: 'productStockIn',
                component: () => import('../views/plant/productStockIn'),
                children: [
                    {
                        path: 'plantPurchaseReceive',
                        name: 'plantPurchaseReceive',
                        components: {
                            productStockIn: () => import('../views/plant/plantPurchaseReceive'),
                        },
                    },
                    {
                        path: 'productStockInAdd',
                        name: 'productStockInAdd',
                        components: {
                            productStockIn: () => import('../views/plant/productStockInAdd'),
                        },
                    },
                    {
                        path: 'productStockInList',
                        name: 'productStockInList',
                        components: {
                            productStockIn: () => import('../views/plant/productStockInList'),
                        },
                    },
                ]
            },
            {
                name: 'productStockOut',
                path: 'productStockOut',
                component: () => import('../views/plant/productStockOut'),
                children: [
                    {
                        path: 'productStockApply',
                        name: 'productStockApply',
                        components: {
                            productStockOut: () => import('../views/plant/productStockApply'),
                        },
                    },
                    {
                        path: 'productStockOutAdd',
                        name: 'productStockOutAdd',
                        components: {
                            productStockOut: () => import('../views/plant/productStockOutAdd'),
                        },
                    },
                    {
                        path: 'productStockOutList',
                        name: 'productStockOutList',
                        components: {
                            productStockOut: () => import('../views/plant/productStockOutList'),
                        },
                    },
                ]
            },
            {
                name: 'caseManage',
                path: 'caseManage',
                component: () => import('../views/case/caseManage'),
                children: [
                    {
                        path: 'caseAdd',
                        name: 'caseAdd',
                        components: {
                            caseManage: () => import('../views/case/caseAdd'),
                        },
                    },
                    {
                        path: 'casePhoto',
                        name: 'casePhoto',
                        components: {
                            caseManage: () => import('../views/case/casePhoto'),
                        },
                    },
                    {
                        path: 'caseOutScan',
                        name: 'caseOutScan',
                        components: {
                            caseManage: () => import('../views/case/caseOutScan'),
                        },
                    },
                    {
                        path: 'caseList',
                        name: 'caseList',
                        components: {
                            caseManage: () => import('../views/case/caseList'),
                        },
                    },
                    {
                        path: 'caseDetailList',
                        name: 'caseDetailList',
                        components: {
                            caseManage: () => import('../views/case/caseDetailList'),
                        },
                    },
                    {
                        path: 'caseHistoryList',
                        name: 'caseHistoryList',
                        components: {
                            caseManage: () => import('../views/case/caseHistoryList'),
                        },
                    },
                    {
                        path: 'caseExportTemplate',
                        name: 'caseExportTemplate',
                        components: {
                            caseManage: () => import('../views/case/caseExportTemplate'),
                        },
                    },
                    {
                        path: 'caseProductTemplate',
                        name: 'caseProductTemplate',
                        components: {
                            caseManage: () => import('../views/case/caseProductTemplate'),
                        },
                    }
                ]
            },
            {
                name: 'casePack',
                path: 'casePack',
                component: () => import('../views/case/casePack'),
                children: [
                    {
                        path: 'casePackOut',
                        name: 'casePackOut',
                        components: {
                            casePack: () => import('../views/case/casePackOut'),
                        },
                    },
                    {
                        path: 'casePackPage',
                        name: 'casePackPage',
                        components: {
                            casePack: () => import('../views/case/casePackPage'),
                        },
                    },
                ]
            },
            {
                name: 'outCaseManage',
                path: 'outCaseManage',
                component: () => import('../views/case/outCaseManage'),
                children: [
                    {
                        path: 'dailyOutCase',
                        name: 'dailyOutCase',
                        components: {
                            outCaseManage: () => import('../views/case/dailyOutCase'),
                        },
                    },
                    {
                        path: 'caseOutDelayList',
                        name: 'caseOutDelayList',
                        components: {
                            outCaseManage: () => import('../views/case/caseOutDelayList'),
                        },
                    },
                ]
            },
            {
                name: 'systemSetting',
                path: 'systemSetting',
                component: () => import('../views/system/systemSetting'),
                children: [
                    {
                        path: 'dataList',
                        name: 'dataList',
                        components: {
                            systemSetting: () => import('../views/system/dataList'),
                        },
                    },
                    {
                        path: 'basicInfo',
                        name: 'basicInfo',
                        components: {
                            systemSetting: () => import('../views/system/basicInfo'),
                        },
                    },
                    {
                        path: 'auditSet',
                        name: 'auditSet',
                        components: {
                            systemSetting: () => import('../views/system/auditSet'),
                        },
                    },
                    {
                        path: 'attachList',
                        name: 'attachList',
                        components: {
                            systemSetting: () => import('../views/case/attachList'),
                        },
                    },
                    {
                        path: 'plantSystem',
                        name: 'plantSystem',
                        components: {
                            systemSetting: () => import('../views/plant/plantSystem'),
                        },
                    },
                    {
                        path: 'plantRetention',
                        name: 'plantRetention',
                        components: {
                            systemSetting: () => import('../views/plant/plantRetention'),
                        },
                    },
                    {
                        path: 'restDay',
                        name: 'restDay',
                        components: {
                            systemSetting: () => import('../views/system/restDay'),
                        },
                    },
                ]
            },
            {
                name: 'userMgt',
                path: 'userMgt',
                component: () => import('../views/system/userMgt'),
                children: [
                    {
                        path: 'userList',
                        name: 'userList',
                        components: {
                            userMgt: () => import('../views/system/userList'),
                        },
                    },
                    {
                        path: 'roleList',
                        name: 'roleList',
                        components: {
                            userMgt: () => import('../views/system/roleList'),
                        },
                    },
                    {
                        path: 'operLogList',
                        name: 'operLogList',
                        components: {
                            userMgt: () => import('../views/system/operLogList'),
                        },
                    },
                    {
                        path: 'userLoginRecord',
                        name: 'userLoginRecord',
                        components: {
                            userMgt: () => import('../views/system/userLoginRecord'),
                        },
                    },
                ]
            },
            {
                name: 'alertSetting',
                path: 'alertSetting',
                component: () => import('../views/system/alertSetting'),
                children: [
                    {
                        path: 'factoryAlertReceiver',
                        name: 'factoryAlertReceiver',
                        components: {
                            alertSetting: () => import('../views/system/factoryAlertReceiver'),
                        },
                    },
                    {
                        path: 'factoryNotice',
                        name: 'factoryNotice',
                        components: {
                            alertSetting: () => import('../views/system/factoryNotice'),
                        },
                    },
                    {
                        path: 'myMessage',
                        name: 'myMessage',
                        components: {
                            alertSetting: () => import('../views/system/myMessage'),
                        },
                    },
                ]
            },
            {
                name: 'productStockMove',
                path: 'productStockMove',
                component: () => import('../views/plant/productStockMove'),
                children: [
                    {
                        path: 'productStockMoveAdd',
                        name: 'productStockMoveAdd',
                        components: {
                            productStockMove: () => import('../views/plant/productStockMoveAdd'),
                        },
                    },
                    {
                        path: 'productStockMoveList',
                        name: 'productStockMoveList',
                        components: {
                            productStockMove: () => import('../views/plant/productStockMoveList'),
                        },
                    },
                ]
            },
        ]
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'error',
        component: error
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})
// 路由跳转前的拦截器
router.beforeEach((to, from) => {
    // console.log(to)
    // console.log(from)
    // 一般用来判断 没有token的时候 访问任何页面都会跳转回登录页面
    // 1，判断当前跳转页面（to.name）是否是登录页面，如果不是判断有无token
    // 2，判断sessionStorage或者localStorage里面是不是有token
    // 没有token跳转到login
    // 有token直接调用next方法
    // 3，判断是否有页面访问的权限（首页都可以访问)
    if (to.name !== 'login' && to.path.indexOf('\/warranty\/') < 0 && to.path.indexOf('\/help') < 0 && to.path.indexOf('\/assets') < 0
        && to.path.indexOf('\/welcome') < 0 && to.name !== 'myMessage' && to.name !== 'resetPassword'
        && to.path.indexOf('\/factoryHelp') < 0 && to.name !== 'workshopBoard' && to.name !== 'bigSaleBoard' && to.name !== 'stepBoard' && to.name !== 'moduleList') {
        let token = sessionStorage.getItem('token')
        if (!token || token === 'undefined') {
            sessionStorage.clear()
            return {name: 'login'}
        } else {
            let auth = sessionStorage.getItem('auth')
            if (!auth.includes('\"' + to.name + '\"')) {
                ElMessage.error('没有页面访问权限，请检查')
                return {name: from.name}
            } else {
                // 可以正常访问页面
            }
        }
    }
})

export default router