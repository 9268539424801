import {reactive, ref, inject, toRaw, computed} from 'vue'
import {
    purchaseApplyGetPage,
    purchaseApplySave,
    purchaseApplyValid,
    purchaseApplyGet,
    purchaseApplyGetLogList,
    purchaseGetStat
} from "@/api/purchase";
import {ElMessage} from "element-plus";
import {
    purchaseApplyUpload,
} from "@/api/system";
import {applyPurchaseStateEnum} from "@/util/enum";
import {useStore} from 'vuex'

// 获取采购申请
function useGetPurchaseApply(purchaseApply) {
    const purchaseApplyGetLoading = ref(false)
    const getPurchaseApply = async id => {
        if (!id || id <= 0) return
        if (purchaseApplyGetLoading.value) return
        purchaseApplyGetLoading.value = true
        let res = await purchaseApplyGet({id: id})
        if (res) Object.assign(purchaseApply, res)
        purchaseApplyGetLoading.value = false
    }
    return {
        purchaseApplyGetLoading,
        getPurchaseApply,
    }
}

//保存采购申请单 oycm 2022/11/25
function useSavePurchaseApply() {
    const purchaseApply = reactive({
        id: 0,
        applyNo: '',
        applyDate: new Date(),
        applyWorkerId: null,
        applyDeptId: '',
        remark: '',
        auditState: 0,
        auditWorkerId: null,
        isValid: 1,
        applyPurchaseState: applyPurchaseStateEnum.未采购,
        details: []
    })
    const newMaterial = () => {
        return {
            id: 0,
            purchaseApplyId: purchaseApply.id,
            materialId: null,
            num: null,
            storeUnit: '',
            remark: '',
        }
    }
    const addMaterial = () => {
        if (purchaseApply.details.length && purchaseApply.details.length >= 50) {
            ElMessage.error('不能超过50个材料，请检查')
            return
        }
        purchaseApply.details.push(newMaterial())
    }
    const purchaseApplyEmpty = Object.assign({}, purchaseApply)
    const setFields = inject('setFields')
    const initPurchaseApply = () => {
        setFields(purchaseApply, purchaseApplyEmpty)
        purchaseApply.details = []
    }
    const purchaseApplyFormRef = ref()
    const purchaseApplySaveRules = reactive({
        applyDate: [
            {required: true, message: '请输入申请日期', trigger: 'blur'},
        ],
        applyWorkerId: [
            {required: true, message: '请选择申请员工', trigger: 'change'},
        ],
    })
    const savePurchaseApplyLoading = ref(false)
    const savePurchaseApplyResult = ref(false)
    const savePurchaseApply = async () => {
        savePurchaseApplyResult.value = false
        if (savePurchaseApplyLoading.value) return
        savePurchaseApplyLoading.value = true
        await purchaseApplyFormRef.value.validate(async (valid, fields) => {
            if (!valid) {
                savePurchaseApplyLoading.value = false
                return
            }
            savePurchaseApplyResult.value = await purchaseApplySave(purchaseApply)
            savePurchaseApplyLoading.value = false
        })
    }
    const totalNum = computed(() => {
        if (purchaseApply.details && purchaseApply.details.length) {
            let arr = purchaseApply.details.filter(x => x.materialId && x.num).map(x => x.num)
            return arr.length ? arr.reduce((prev, cur) => prev + cur) : ''
        } else {
            return ''
        }
    })
    return {
        addMaterial,
        purchaseApply,
        initPurchaseApply,
        purchaseApplyFormRef,
        purchaseApplySaveRules,
        savePurchaseApplyLoading,
        savePurchaseApplyResult,
        savePurchaseApply,
        totalNum
    }
}

//分页采购申请单 oycm 2022/11/25
function useGetPagePurchaseApply() {
    const getPagePurchaseApplyParam = reactive({
        applyNo: '',
        applyWorkerId: null,
        applyBeginDate: '',
        applyEndDate: '',
        isAudit: null,
        onlyUndone: 0,
        pageIndex: 1,
        pageSize: 10,
    })
    const getPagePurchaseApplyParamEmpty = Object.assign({}, getPagePurchaseApplyParam)
    const initGetPagePurchaseApplyParam = () => {
        Object.assign(getPagePurchaseApplyParam, getPagePurchaseApplyParamEmpty)
    }
    const getPagePurchaseApplyLoading = ref(false)
    const purchaseApplyPage = ref([])
    const getPagePurchaseApplyResult = reactive({
        totalCount: 0,
        totalPages: 0,
    })
    const getPagePurchaseApply = async () => {
        if (getPagePurchaseApplyLoading.value) return
        getPagePurchaseApplyLoading.value = true
        let data = await purchaseApplyGetPage(getPagePurchaseApplyParam)
        if (data && data.list && data.list.length) {
            purchaseApplyPage.value = data.list
            getPagePurchaseApplyResult.totalPages = Math.ceil(data.totalCount / getPagePurchaseApplyParam.pageSize)
            getPagePurchaseApplyResult.totalCount = data.totalCount
        } else {
            purchaseApplyPage.value = []
            getPagePurchaseApplyResult.totalPages = 0
            getPagePurchaseApplyResult.totalCount = 0
        }
        getPagePurchaseApplyLoading.value = false
    }
    return {
        getPagePurchaseApplyParam,
        initGetPagePurchaseApplyParam,
        getPagePurchaseApplyLoading,
        purchaseApplyPage,
        getPagePurchaseApplyResult,
        getPagePurchaseApply,
    }
}

//作废采购申请单 oycm 2022/11/25
function useValidPurchaseApply() {
    const validPurchaseApplyLoading = ref(false)
    const validPurchaseApplyResult = ref(false)
    const validPurchaseApply = async param => {
        validPurchaseApplyResult.value = false
        if (validPurchaseApplyLoading.value) return
        validPurchaseApplyLoading.value = true
        validPurchaseApplyResult.value = await purchaseApplyValid(param)
        validPurchaseApplyLoading.value = false
    }
    return {
        validPurchaseApplyLoading,
        validPurchaseApplyResult,
        validPurchaseApply,
    }
}

// 获取申请单操作日志
function useGetLogListPurchaseApply() {
    const purchaseApplyLogList = ref([])
    const getPurchaseApplyLogListLoading = ref(false)
    const getPurchaseApplyLogList = async id => {
        if (getPurchaseApplyLogListLoading.value) return
        getPurchaseApplyLogListLoading.value = true
        let data = await purchaseApplyGetLogList({id: id})
        if (data) purchaseApplyLogList.value = data
        getPurchaseApplyLogListLoading.value = false
    }
    return {
        purchaseApplyLogList,
        getPurchaseApplyLogListLoading,
        getPurchaseApplyLogList
    }
}

function useUploadPurchaseApply() {
    const uploadPurchaseApplyResult = ref(false)
    const uploadPurchaseApplyLoading = ref(false)
    const uploadPurchaseApplyNum = ref(0)
    const failedPurchaseApply = ref([])
    const uploadPurchaseApply = async param => {
        if (uploadPurchaseApplyLoading.value) return
        uploadPurchaseApplyLoading.value = true
        uploadPurchaseApplyResult.value = false
        uploadPurchaseApplyNum.value = 0
        const formData = new FormData()
        formData.append("file", param.file)
        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };
        try {
            let res = await purchaseApplyUpload(formData, config)
            if (res.code === 0) {
                uploadPurchaseApplyResult.value = true
                uploadPurchaseApplyNum.value = res.data.successNum
                failedPurchaseApply.value = res.data.failList
                if (!!uploadPurchaseApplyNum.value) ElMessage.success('上传成功，上传数量' + uploadPurchaseApplyNum.value)
            } else {
                ElMessage.error(res.message)
            }
        } catch (e) {
            ElMessage.error('上传失败，请检查网络或联系客服')
        }
        uploadPurchaseApplyLoading.value = false
    }
    return {
        uploadPurchaseApplyResult,
        uploadPurchaseApplyLoading,
        uploadPurchaseApplyNum,
        failedPurchaseApply,
        uploadPurchaseApply
    }
}

/**
 * 采购模块统计数据
 * @returns {{getPurchaseStatLoading: *, getPurchaseStatResult: *, getPurchaseStat: ((function(): Promise<void>)|*)}}
 */
function useGetPurchaseStat() {
    const store = useStore()
    const getPurchaseStatLoading = ref(false)
    const getPurchaseStatResult = reactive({
        applyNotPurchaseNum: 0,
        purchaseNotReceiveNum: 0
    })
    const getPurchaseStat = async () => {
        if (getPurchaseStatLoading.value) return
        getPurchaseStatLoading.value = true
        let res = await purchaseGetStat()
        if (res) {
            Object.assign(getPurchaseStatResult, res)
            store.commit('setPurchaseStat', res)
        }
        getPurchaseStatLoading.value = false
    }
    return {
        getPurchaseStat,
        getPurchaseStatResult,
        getPurchaseStatLoading
    }
}

export {
    useSavePurchaseApply,
    useGetPagePurchaseApply,
    useGetPurchaseApply,
    useValidPurchaseApply,
    useGetLogListPurchaseApply,
    useUploadPurchaseApply,
    useGetPurchaseStat
}