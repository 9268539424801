<template class="content" >
<div style="height:70vh;overflow: auto;" >
   <span class="contenTtitle"> 最近更新日期:</span> 2024年8月8日  <span class="contenTtitle">生效日期:</span> 2024年8月8日  <span class="contenTtitle">版本号：</span>UA20240808
   <el-divider></el-divider>
   <span class="title">
        欢迎您（以下或称“用户”）使用存知科技工厂管理平台或其关联的产品或服务！
        <br><br>您与存知科技共同签署《存知科技工厂管理用户使用协议》（以下简称“本协议”），并使用您已有的存知科技或其关联公司账号或您即将注被分配的存知科技账号登录存知科技平台后，您即可按照本协议约定使用存知科技或其关联的产品或服务。
        <br><br>【审慎阅读】您在签署本协议之前，请务必审慎阅读、充分理解本协议各条款内容，特别是以粗体或粗体下划线标识的条款。
        <br><br>【签署】当您在使用您已有的存知科技账户登录存知科技管理平台时，即表示您已审慎阅读、充分理解并完全接受本协议的全部内容，并同意与存知科技签署本协议。在阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，您应立即停止登录并及时联系杭州存知科技有限公司，或者立即停止账号使用或激活程序。
        <br><br>
        目录<br><br>
        一、释义<br><br>
        二、账号授权和账号注册或激活、使用<br><br>
        三、服务费用<br><br>
        四、服务内容及规范
        <br><br>
        五、用户信息的保护及授权
        <br><br>
        六、责任限制
        <br><br>
        七、用户的违约及处理
        <br><br>
        八、所有权及知识产权
        <br><br>
        九、协议范围与关联协议
        <br><br>
        十、协议的变更及终止
        <br><br>
        十一、通知
        <br><br>
        十二、法律适用、管辖与其他
    </span>
    <br><br><br>
    <span class="contenTtitle">一、释义</span>
    <br><br>
    用户：指成功注册或激活存知科技管理平台账号的自然人、法人或其他组织，和签署了本协议并使用存知科技管理平台的自然人、法人或其他组织。
    <br><br>
    存知科技：指杭州存知网络科技（中国）有限公司。
    <br><br>
    存知科技管理平台：指存知工厂管理平台，作为主要的义齿加工厂产品。
    <br><br>
    存知平台: 由存知提供的所有非定制化的相关服务平台
    <br><br>
    产品或服务：指存知科技通过存志科技管理平台或者存知方协商签署的为您提供的各项产品或服务。
    <br><br>
    平台规则：指在存知平台上已经发布的及未来根据需要不定期更新的或新发布的所有服务规则、公告及各项产品或服务的流程说明、使用方法规则介绍等。
    <br><br>
    <span class="contenTtitle"> 二、账号授权和账号注册或激活、使用</span>
    <br><br>
    2.1【账号分配】
    <br><br>
    存知科技管理平台以及相关的其他产品的账号均由存知方分配，客户会得到一个超管的账号，对于工厂内部账号可以由用户自行分配。
    2.2【账号数量】
    <br><br>
    存知加工厂端账号数量不作任何限制，但是根据用户购买的存知功能版本，会有不同的端口数量，即同时登陆系统的账号数量会有限制。
    <br><br>
    2.3【账号管理】
    <br><br>
    您有权使用您的存知科技管理平台登录存知平台，并可用管理员账号在用户列表来管理和维护相关的账号。
    <br><br>
    2.3.1 您由超管分配的加工厂账号密码由您按照平台规则自由设置并由您自行保管，存知网络科技有限公司任何时候均不会主动要求您提供您的账号密码。为了保障您的账号安全，建议您务必保管好您的账号名称、账号密码，并确保您在每次使用产品或服务结束时退出登录并以正确步骤离开存知科技管理平台以及相关产品。客户可以通过电子邮件修改账号的密码，您的账号因您主动泄露或因您遭受他人攻击、诈骗等行为导致的损失及后果，存知网络科技有限公司并不承担责任，您应通过您所在国家或地区法律规定的救济途径向侵权行为人追偿。
    <br><br>
    2.3.2 除存知网络科技有限公司存在过错外，您应对使用您的，存知相关产品账号进行的所有行为（包括但不限于在线签署各类合同或协议等法律文件、购买或使用各项产品或服务及披露信息等）结果负责。
    <br><br>
    2.3.3 如您发现任何未经授权使用您的存知账号登录存知科技管理平台或其他可能导致您的账号遭窃、遗失的情况，建议您立即通知存知网络科技有限公司。您理解，存知网络科技有限公司对您的任何请求采取行动均需要合理时间，且存知网络科技有限公司应您请求而采取的行动可能无法避免或阻止侵害后果的形成或扩大，除存知网络科技有限公司存在过错外，存知网络科技有限公司不承担责任。
    <br><br>
    <span class="contenTtitle"> 三、服务费用</span>
    <br><br>
    3.1 存知网络科技产品分为不同的功能版本，并收取不一样的年费，具体收取方案以及费用包含的内容参考《存知义齿管理平台产品报价》，除了存知提供的固定产品，客户也可以定制自己想要的产品，此定制服务费用需要与存知根据工期，内容，复杂程度等协商而定
    <br><br>
    <span class="contenTtitle">四、服务内容及规范</span>
    <br><br>
    4.1 存知网络科技有限公司提供的产品或服务种类众多，存知网络科技有限公司将根据您选择的产品或服务种类，为您提供相应的产品或服务内容。具体产品或服务内容及规范详见该产品或服务的用户服务协议（以产品或服务的具体协议名称为准）。
    <br><br>
    4.2 您同意授权存知网络科技有限公司收集您的账号登录行为数据及相关产品或服务模块的使用行为数据等（以下简称“用户行为数据”），存知网络科技有限公司有权根据用户行为数据识别风险用户，以便及时改进、优化各项产品或服务。
    <br><br>
    4.3 存知网络科技有限公司有权根据需要对存知各个相关产品（这里不包含定制化产品）或服务形态进行升级或其他调整，并将及时告知您。
    <br><br>
    4.4 使用规范
    <br><br>
    4.4.1 关于产品或服务的获取与更新：
    <br><br>
    (1)存知工厂管理平台为在线云平台产品，可以做到实时更新服务，其他相关产品可以通过发送覆盖包，在线更新等方式进行更新，如果您从未经存知网络科技有限公司
    授权的第三方处获取产品或服务，存知网络科技有限公司无法保证该产品或服务能够正常使用，并对因此给您造成的损失不予负责；
    <br><br>
    (2)为了改善用户体验、完善产品或服务内容，存知网络科技有限公司将不断努力开发新的产品或服务，并为您不时提供产品或服务更新。
    <br><br>
    4.4.2除非法律允许或存知网络科技有限公司书面许可，您使用存知网络科技有限公司的产品或服务过程中不得从事下列行为：
    <br><br>
    (1)删除存知网络科技有限公司产品或服务上以及其副本上所载关于著作权的信息；
    <br><br>
    (2)对存知网络科技有限公司的产品或服务进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现源代码；
    <br><br>
    (3)对存知网络科技有限公司拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；
    <br><br>
    (4)对存知平台运行过程中释放到任何终端内存中的数据、存知平台运行过程中客户端与服务器端的交互数据，以及存知平台运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经存知网络科技有限公司授权的第三方工具或服务接入存知平台和相关系统；
    <br><br>
    (5)通过修改或伪造存知平台运行中的指令、数据，增加、删减、变动存知平台的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；
    <br><br>
    (6)通过非存知网络科技有限公司开发、授权的第三方软件、插件、外挂、系统，登录存知平台或使用存知平台上的产品或服务，或制作、发布、传播上述工具；
    <br><br>
    (7)自行或者授权他人、第三方软件对存知平台及其组件、模块、数据进行干扰。
    <br><br>
    <span class="contenTtitle">五、用户信息的保护及授权</span>
    <br><br>
    5.1 【用户信息的保护】
    <br><br>
    存知网络科技有限公司非常重视用户信息的保护，在您登录存知平台、使用存知网络科技有限公司的产品或服务时，您同意存知网络科技有限公司按照在存知平台上公布的隐私政策收集、存储、使用、披露和保护您的信息。如用户使用的产品或服务未设置独立的隐私政策，但用户使用了存知网络科技账号登陆存知平台的，为了保护您的隐私权，我们将适用《存知科技隐私政策》的要求对您的信息进行收集、存储、使用、披露和保护。存知网络科技有限公司希望通过隐私政策向您清楚地介绍存知网络科技有限公司对您信息的处理方式，因此存知网络科技有限公司建议您完整地阅读隐私政策，以帮助您更好地保护您的隐私权。
    <br><br>
    5.2【非用户信息的保证与授权】
    <br><br>
    您声明并保证，您对您所上传、发布的信息拥有相应、合法的权利。否则，存知网络科技有限公司可对您发布的信息依法或依本协议进行删除或屏蔽。
    <br><br>
    您应当确保您所上传、发布的信息不包含以下内容：
    <br><br>
    违反国家法律法规禁止性规定；
    <br><br>
    政治宣传、封建迷信、淫秽、色情、赌博、暴力、恐怖或者教唆犯罪；
    <br><br>
    欺诈、虚假、不准确或存在误导性；
    <br><br>
    侵犯他人知识产权或涉及第三方商业秘密及其他专有权利；
    <br><br>
    侮辱、诽谤、恐吓、涉及他人隐私等侵害他人合法权益；
    <br><br>
    存在可能破坏、篡改、删除、影响存知平台正常运行或未经授权秘密获取存知平台及其他用户的数据、个人资料的病毒、木马、爬虫等恶意软件、程序代码；
    <br><br>
    其他违背社会公共利益或公共道德或依据相关平台规则的规定不适合在存知平台上发布的。
    <br><br>
    【授权使用】对于您提供、发布及在使用存知网络科技有限公司的产品或服务中形成的除用户信息外的文字、图片、视频、音频等非用户信息，均不会因上传、发布等行为发生知识产权、肖像权等权利的转移。除非存知网络科技有限公司另行说明，在法律允许的范围内，您免费授予存知平台及其关联公司、非排他的、无地域限制的许可使用（包括存储、使用、复制、修订、编辑、发布、展示、翻译、分发上述信息或制作派生作品，以已知或日后开发的形式、媒体或技术将上述信息纳入其它作品内等）及可再许可第三方使用的权利，以及可以自身名义对第三方侵权行为取证及提起诉讼的权利。
    <br><br>
    为方便您使用存知网络科技有限公司的各项产品或服务，您授权存知网络科技有限公司将您在注册或激活存知平台账号、登录存知平台、使用产品或服务过程中提供、形成的信息共享给相应的产品或服务提供者。
    <br><br>
    5.3【用户的安全责任】
    <br><br>
    【网络和连接安全】除双方另有书面约定外，您应（a）确保您的网络和系统符合我们随时提供的相关规范（如有）；（b）全权负责采购和维护从您的系统到我们或任何第三方数据中心的网络连接和电信连接；并且（c）自行承担所有因您的网络连接、电信连接或因互联网引起的或与之相关的所有问题、条件、延迟、交付失败、安全风险和所有其他损失、损坏、责任、费用或任何性质或种类的费用。
    <br><br>
    【二次开发内容安全】除双方另有书面约定外，您应对任何安全漏洞以及由您二次开发或您聘请的第三方团队二次开发的内容引起或与之相关的此类漏洞的后果负责，包括但不限于您的内容中包含的任何病毒、特洛伊木马、蠕虫或其他有害的编程程序。
    <br><br>
    【安全策略配置】存知产品支持配置各种安全策略以防范信息安全风险，如复杂密码、权限配置以及其他重要参数配置等，我们建议您按照的安全策略功能配置软件产品，如您拒绝配置安全策略，则因此导致的安全风险和后果应当由您自行承担。
    <br><br>
    【产品更新维护】鉴于安全防护技术的持续升级，我们会不时更新维护存知产品并发布更新的安全版本。除双方另有书面约定外，如您在您的服务器独立部署存知产品，您应当及时关注官网的更新公告并及时将产品版本更新至最新的版本，否则由此导致的安全漏洞及因此引发的后果，应当由您自行承担。
    <br><br>
    <span class="contenTtitle">六、责任限制</span>
    <br><br>
    6.1您理解并同意，您在使用存知网络科技有限公司的产品或服务过程中存在的风险将完全由您本人承担，存知网络科技有限公司在本协议约定的责任范围以外不承担任何责任。
    <br><br>
    6.2在法律允许的情况下，存知网络科技有限公司对于与本协议有关的任何间接的、惩罚性的、特殊的、派生的损失（包括但不限于业务损失、收益损失、利润损失、使用数据、商誉或其他经济利益的损失），不承担任何责任，即使事先已被告知此等损失的可能性。
    <br><br>
    6.3您理解并确认，对于存知网络科技有限公司向您提供的下列产品或服务本身及其引发的任何损失，存知网络科技有限公司无需承担任何责任：
    <br><br>
    存知网络科技有限公司向您免费提供的各项产品或服务；
    <br><br>
    存知网络科技有限公司向您赠送或附赠的任何产品或服务。
    <br><br>
    您理解并确认，存知网络科技有限公司可能因下列情况无法正常为您提供产品或服务，存知网络科技有限公司就此不承担损害赔偿责任：
    <br><br>
    因自然灾害、罢工、暴乱、战争、政府行为、司法行政命令等不可抗力因素；
    <br><br>
    因电力供应故障、通讯网络故障等公共服务因素或第三人因素；
    <br><br>
    在存知网络科技有限公司已尽善意管理的情况下，因常规或紧急的设备与系统维护、设备与系统故障、网络信息与数据安全等因素。
    <br><br>
    您理解并同意，因您使用存知网络科技有限公司的产品或服务、违反本协议或使用您的存知平台账号采取的任何行动，而导致的任何第三方索赔，应且仅应由您本人承担。如果由此引起存知网络科技有限公司、员工、客户、供应商和合作伙伴被第三方索赔的，您应承担全部责任。
    <br><br>
    您通过存知平台访问和使用的存知网络科技有限公司的合作伙伴或入驻存知平台的第三方服务商的服务质量仅为该第三方的责任，对于该第三方的产品或服务，存知网络科技有限公司在任何情况下都不会承担责任。
    <br><br>
    <span class="contenTtitle"> 七、用户的违约及处理</span>
    <br><br>
    7.1违约认定
    <br><br>
    发生如下情形之一的，视为您违约：
    <br><br>
    7.1.1使用存知网络科技有限公司的产品或服务时违反有关法律法规规定的；
    <br><br>
    7.1.2违反本协议、关联协议或者与存知网络科技有限公司签署的其他协议约定的。
    <br><br>
    7.2违约处理措施
    <br><br>
    <span class="contenTtitle">7.2.1存知平台的使用权应当属于一个客户公司，如果将此平台与其他公司共用，则视为客户违约</span>
    <br><br>
    7.2.2您在存知平台上实施的行为，或虽未在存知平台上实施但对存知网络科技有限公司及其用户产生影响的行为构成违约的，存知网络科技有限公司可依据相应平台规则对您执行中止向您提供部分或全部产品或服务等处理措施。如您的行为构成根本违约的，存知网络科技有限公司可关闭您的账号，终止向您提供产品或服务。
    <br><br>
    7.2.3当您违约的同时存在欺诈、售假、盗用他人账号等特定情形或您存在危及他人账号安全风险时，存知网络科技有限公司会依照您行为的风险程度对您的账号采取关闭、注销等强制措施。
    <br><br>
    7.2.4存知网络科技有限公司可将对您上述违约行为处理措施信息以及其他经国家行政或司法机关生效法律文书确认的违法信息在存知平台上予以公示。
    <br><br>
    7.3赔偿责任
    <br><br>
    7.3.1如您的行为使存知网络科技有限公司遭受损失，您应根据本协议履行赔偿责任，您需赔偿存知网络科技有限公司的因此遭受的损失（如有）包括:
    <br><br>
    支付的合理律师费、诉讼费、消除影响所支出的必要费用；
    <br><br>
    因行政处罚、司法裁判、法定标准范围内的调解等对外支出的罚款、违约金或赔偿金；
    <br><br>
    商誉损失、合理预期利益损失（如经营收入减少等），具体金额可以第三方独立合理分析、存知网络科技有限公司根据大数据合理分析及其他计算收益、损失的合理方法所得出的相关估算标准作为计算依据；
    <br><br>
    因此遭受的其他损失。
    <br><br>
    7.3.2如您的行为使存知网络科技有限公司遭受第三人主张权利，存知网络科技有限公司可在对第三人承担金钱给付等义务后就全部损失向您追偿。
    <br><br>
    7.3.5 存知网络科技有限公司因故意或重大过失违约导致您利益受损的，存知网络科技有限公司将以您的直接实际损失为计算依据向您履行赔偿责任。
    <br><br>
    7.4特别约定
    <br><br>
    7.4.1如您向存知网络科技有限公司的员工等提供实物、现金、现金等价物、劳务、旅游等价值明显超出正常商务洽谈范畴的利益，则可视为您存在商业贿赂行为。发生上述情形的，存知网络科技有限公司可按照相应平台规则进行处理，并经提前通知后终止与您的所有合作并向您收取违约金或赔偿金，该等金额以存知网络科技有限公司因您的贿赂行为而遭受的经济损失和商誉损失作为计算依据。
    <br><br>
    7.4.2如您因严重违约导致存知网络科技有限公司终止本协议时，出于维护存知平台秩序及保护其他用户合法权益的目的，存知网络科技有限公司可对与您在其他协议项下的合作采取中止甚或终止协议的措施，并以本协议约定的有效方式通知您。
    <br><br>
    7.4.3如存知网络科技有限公司与您签署的其他协议及存知网络科技有限公司或其关联公司与您签署的协议中明确约定了对您在本协议项下合作进行关联处理的情形，则存知网络科技有限公司出于维护存知平台秩序及保护其他用户合法权益的目的，可在收到指令时中止甚至终止协议，并以本协议约定的有效方式通知您。
    <br><br>
    <span class="contenTtitle">八、所有权及知识产权</span>
    <br><br>
    8.1除法律另有强制性规定外，未经存知平台或关联公司明确的特别书面许可,任何单位或个人不得以任何方式非法地全部或部分复制、转载、引用、链接、抓取或以其他方式使用存知平台的信息内容，包括但不限于著作、图片、档案、资讯、资料、架构、页面设计等，否则，存知网络科技有限公司有权追究其法律责任。
    <br><br>
    8.2存知平台所刊登的资料信息（诸如文字、图表、标识、按钮图标、图像、声音文件片段、数字下载、数据编辑和软件），均是存知平台或关联公司以及其内容提供者的财产，受中国和国际版权法的保护。存知平台上所有内容的汇编是存知平台或关联公司的排他财产，受中国和国际版权法的保护。存知平台上所有产品或服务都是存知网络科技有限公司或其供应商的财产，受中国和国际版权法的保护。
    <br><br>
    <span class="contenTtitle">九、协议范围与关联协议</span> 
    <br><br>
    9.1缔约主体
    <br><br>
    本协议由您与存知网络科技有限公司共同签署，本协议对您与存知网络科技有限公司具有同等合同效力。
    <br><br>
    本协议项下，存知网络科技有限公司可能根据存知平台的业务调整而发生变更，变更后的存知平台经营者与您共同履行本协议并向您提供服务，存知平台经营者的变更不会影响您本协议项下的权益。存知平台经营者还有可能因为提供新的产品或服务而新增，如您使用新增的产品或服务的，视为您同意新增的存知平台经营者与您共同履行本协议。发生争议时，您可根据您具体使用的产品或服务及对您权益产生影响的具体行为对象确定与您履约的主体及争议相对方。
    <br><br>
    9.2关联协议
    <br><br>
    9.2.1同时伴随互联网高速发展，您与存知网络科技有限公司签署的本协议列明的条款并不能完整罗列并覆盖您与存知网络科技有限公司所有权利与义务，现有的约定也不能保证完全符合未来发展的需求。因此，存知网络科技有限公司法律声明、隐私政策、在存知平台以公开方式发布的各项平台规则、存知网络科技有限公司具体产品或服务的用户服务协议以及隐私政策均为本协议的关联协议，与本协议具有同等法律效力。如您使用存知网络科技有限公司的产品或服务，视为您同意上述关联协议。
    <br><br>
    9.2.2您知晓并同意，由于存知网络科技有限公司提供的产品或服务类别众多且领域跨度较大，本协议作为总框架协议，难以穷尽。若与存知网络科技有限公司的具体产品或服务的用户服务协议存在不一致之处，以具体产品或服务的用户服务协议约定为准。
    <br><br>
    <span class="contenTtitle">十、协议的变更及终止</span>
    <br><br>
    10.1协议变更
    <br><br>
    10.1存知网络科技有限公司可根据国家法律法规变化及维护市场秩序、保护用户合法权益需要，不时修改本协议、关联协议，变更后的协议、关联协议（下称“变更事项”）将通过法定程序并以本协议约定的有效通知方式通知您。我们还会将本用户协议的旧版本存档，供您查阅。 点击此处查阅历史版本用户协议。
    <br><br>
    10.2如您不同意变更事项，您有权于变更事项确定的生效日前联系存知网络科技有限公司反馈意见。如反馈意见得以采纳，存知网络科技有限公司将酌情调整变更事项。如您对已生效的变更事项仍不同意的，您应当于变更事项确定的生效之日起停止使用及其关联公司服务，变更事项对您不产生效力；如您在变更事项生效后仍继续使用存知网络科技有限公司服务，则视为您同意已生效的变更事项。
    <br><br>
    10.2协议终止
    <br><br>
    10.2协议终止的情形
    <br><br>
    10.2.1您有权通过以下任一方式终止本协议：
    <br><br>
    在满足存知网络科技有限公司要求的账号注销条件时您通过存知平台自助服务注销您的账号的；
    <br><br>
    变更事项生效前您停止使用存知网络科技有限公司的产品或服务并明示不愿接受变更事项的；
    <br><br>
    您明示不愿继续使用存知网络科技有限公司的产品或服务，且符合存知网络科技有限公司要求的终止条件的。
    <br><br>
    10.2.2出现以下情况时，存知网络科技有限公司可按照本协议约定的有效方式通知您终止本协议：
    <br><br>
    您的实名认证未通过、不再合法存续或无法有效核实；
    <br><br>
    您违反本协议约定，存知网络科技有限公司依据违约条款终止本协议的；
    <br><br>
    您盗用他人账号、欺诈、售假、扰乱市场秩序、采取不正当手段牟利等行为，存知网络科技有限公司依据相应平台规则对您的账号予以关闭、注销的；
    <br><br>
    除上述情形外，因您多次违反平台规则相关规定且情节严重，存知网络科技有限公司依据相应平台规则对您的账户予以关闭、注销的；
    <br><br>
    存知网络科技有限公司基于合理理由相信您的行为可能会使您、存知网络科技有限公司及其他用户等相关方发生严重损害或产生法律责任；
    <br><br>
    其他应当终止提供产品或服务的情况。
    <br><br>
    10.3协议终止后的处理
    <br><br>
    10.3.1本协议终止后，除法律有明确规定外，存知网络科技有限公司无义务向您或您指定的第三方披露您的账号及其他任何信息。
    <br><br>
    10.3.2本协议终止后，存知网络科技有限公司仍享有下列权利：
    <br><br>
    依据《中华人民共和国网络安全法》以及其他相关法律的要求，在一定期限内继续保存您留存于存知平台的各类信息；
    <br><br>
    对于您过往的违约行为，存知网络科技有限公司仍可依据本协议向您追究违约责任。
    <br><br>
    <span class="contenTtitle">十一、通知</span>
    <br><br>
    11.1有效联系方式
    <br><br>
    您在注册或激活存知平台账号、登录存知平台，并接受存知服务时，您应该向存知网络科技有限公司提供真实有效的联系方式（包括您的电子邮件地址、联系电话、联系地址等），对于联系方式发生变更的，您有义务及时更新有关信息，并保持可被有效联系的状态。
    <br><br>
    您用于登陆存知平台、接收站内信、系统消息等即时信息的账号，也作为您的有效联系方式。
    <br><br>
    存知网络科技有限公司将向您的上述联系方式的其中之一或其中若干向您送达各类通知，而此类通知的内容可能对您的权利义务产生重大的有利或不利影响，请您务必及时关注。
    <br><br>
    11.2通知的送达
    <br><br>
    11.2.1存知网络科技有限公司通过上述联系方式向您发出通知，其中以电子的方式发出的书面通知，包括但不限于在存知平台公告，向您提供的联系电话发送手机短信，向您提供的电子邮件地址发送电子邮件，向您的账号发送系统消息以及站内信信息，在发送成功后即视为送达；以纸质载体发出的书面通知，按照提供联系地址交邮后的第五个自然日即视为送达。
    <br><br>
    11.2.2对于在存知网络科技有限公司为您提供产品或服务过程中引起的任何纠纷，您同意司法机关（包括但不限于人民法院）可以通过手机短信、电子邮件等现代通讯方式或邮寄方式向您送达法律文书（包括但不限于诉讼文书）。您指定接收法律文书的手机号码、电子邮箱等联系方式为您在注册或激活存知平台账号时提供的、更新的手机号码、电子邮箱联系方式，司法机关向上述联系方式发出法律文书即视为送达。您指定的邮寄地址为您的法定联系地址或您提供的有效联系地址。
    <br><br>
    11.2.3您同意司法机关可采取以上一种或多种送达方式向您达法律文书，司法机关采取多种方式向您送达法律文书，送达时间以上述送达方式中最先送达的为准。您同意上述送达方式适用于各个司法程序阶段。如进入诉讼程序的，包括但不限于一审、二审、再审、执行以及督促程序等。
    <br><br>
    11.2.4您应当保证所提供的联系方式是准确、有效的，并进行实时更新。如果因提供的联系方式不确切，或不及时告知变更后的联系方式，使法律文书无法送达或未及时送达，由您自行承担由此可能产生的法律后果。
    <br><br>
    <span class="contenTtitle">十二、法律适用、管辖与其他</span>
    <br><br>
    12.1本协议之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国大陆地区法律；如法律无相关规定的，参照商业惯例或行业惯例。
    <br><br>
    12.2您因使用存知网络科技有限公司的产品或服务所产生及与存知网络科技有限公司有关的争议，由存知网络科技有限公司与您协商解决。协商不成时，任何一方均可向被告所在地有管辖权的人民法院提起诉讼。
    <br><br>
    12.3本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本协议其余条款的有效性及可执行性。
    <br><br>
    12.4本协议各条款前所列索引关键词仅为帮助您理解该条款表达的主旨之用，不影响或限制本协议条款的含义或解释。为维护您自身权益，建议您仔细阅读各条款具体表述。
    <br><br>
    <span class="right"> 如您对本协议有任何疑问,可向客服咨询,客服联系电话:15958152341,1872838781</span>
    <br><br>
    <span class="right">存知网络科技有限公司</span>

   </div>
</template>
<style scoped lang="scss">
   .title{
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: bold;
   }
   .contenTtitle{
        margin-bottom: 10px;
        font-size: 15px;
        font-weight: bold;
   }
   .content{
        margin-bottom: 5px;
        font-size: 15px;
   }
   .right{
        float: right;
   }
</style>