<template>
    <div>
        <el-autocomplete title="可以搜索名称、拼首和编号；双击可以弹出搜索弹窗" v-model.trim="inputProductInfo" value-key="name"
                         clearable :readonly="readonly" :disabled="disabled" class="product-input" style="width:100%;"
                         placeholder="请选择产品" @select="chooseInputProduct" select-when-unmatched
                         highlight-first-item @blur="blurProduct" @dblclick="showProduct"
                         :type="isTextarea?'textarea':''"
                         :fetch-suggestions="fetchProduct">
            <template #default="{ item }">
                <div style="display: flex;justify-content:space-between;">
                    <b>{{ item.name }}</b>
                    <span style="float: right;color: var(--el-text-color-secondary);font-size: 13px;">{{
                            item.code
                        }}</span>
                </div>
            </template>
        </el-autocomplete>
        <el-dialog v-model="productVisible" title="产品选择框" width="800px" append-to-body>
            <div class="flex-row">
                <div style="width:190px;">
                    <el-tree :data="storeProductType" @node-click="chooseProductType"
                             :props="commonTreeProps"
                             node-key="id" ref="treeRef"
                             :highlight-current="true" default-expand-all
                             :expand-on-click-node="true">
                    </el-tree>
                </div>
                <div class="flex-grow">
                    <el-space :size="0">
                        <el-input placeholder="搜索名称、编号、拼音首字母" v-model.trim="searchProductInfo"
                                  class="input-large"
                                  :suffix-icon="Search" maxlength="50"></el-input>
                        <el-button :icon="Refresh" @click="resetSearchProduct">重置搜索</el-button>
                        <span class="text-danger">单击列表产品即可选择</span>
                    </el-space>
                    <el-table border stripe :data="modalProductList" @row-click="chooseProduct" style="width: 100%;"
                              max-height="800px">
                        <el-table-column type="index" width="60"></el-table-column>
                        <el-table-column prop="code" label="编号" width="90"></el-table-column>
                        <el-table-column prop="name" label="名称" width="260"></el-table-column>
                        <el-table-column prop="productTypeName" label="种类" min-width="150"></el-table-column>
                    </el-table>
                </div>
            </div>
            <footer>
                <div style="width: 100%;display: flex;">
                    <div class="flex-grow"></div>
                    <el-button @click="hideProduct">
                        关闭
                    </el-button>
                </div>
            </footer>
        </el-dialog>
    </div>
</template>
<!--示例-->
<!--
<product-select v-model:product-id="productId" :product-list='storeProductList'></product-select>
-->
<script setup>
import {useStore} from "vuex";
import {ref, reactive, onMounted, watch, toRef, defineProps, defineEmits, computed} from 'vue'
import {Search, Refresh} from "@element-plus/icons-vue";
import {commonTreeProps, nullProductNo} from "@/util/constant";
import {productFilter, productDefaultFilter} from "@/util/formatter";

const props = defineProps({
    productId: Number,
    readonly: Boolean || null,
    disabled: Boolean || null,
    productList: Array,
    isTextarea: Boolean
})
const emits = defineEmits(['update:productId', 'changeProduct'])
watch(() => props.readonly, (n, o) => {
    innerReadonly.value = n
})
watch(() => props.disabled, (n, o) => {
    innerDisabled.value = n
})
const innerReadonly = ref(false)
const innerDisabled = ref(false)
watch(() => props.productId, (n, o) => {
    if (n) {
        innerProductId.value = parseInt(n)
        let product = store.state.productList.find(x => x.id === n)
        product && (inputProductInfo.value = product.name)
    } else {
        innerProductId.value = null
        inputProductInfo.value = ''
        //外部清空产品时 进行产品排序（比如订单页面点新增）
        sortProductList()
    }
})
const store = useStore()
const innerProductId = ref()
const inputProductInfo = ref('')
const storeProductType = store.state.productType
let storeProductList = null
watch(() => store.state.lastProductIds, (n, o) => {
    sortProductList()
})
const sortProductList = () => {
    storeProductList.sort((a, b) => {
            if (!store.state.lastProductIds.includes(b.id) && !store.state.lastProductIds.includes(a.id)) {
                if (a.count === b.count) {
                    return b.id - a.id
                }
                return b.count - a.count
            }
            return store.state.lastProductIds.includes(b.id) ? 1 : -1
        }
    )
}
// 输入框
const fetchProduct = (str, cb) => {
    let res = []
    if (str) {
        if (storeProductList.some(x => x.id === innerProductId.value && x.name === str)) {
            res = []
        } else {
            res = storeProductList.filter(productFilter(str))
        }
    } else {
        res = storeProductList.filter(productDefaultFilter)
    }
    cb(res)
}
const chooseInputProduct = item => {
    innerProductId.value = (item && item.id) ? item.id : null
    changeProductId()
}
const blurProduct = () => {
    let emitOuter = false//是否要通知外层
    if (!inputProductInfo.value) {
        if (innerProductId.value) emitOuter = true
        innerProductId.value = null
    } else {
        let product = store.state.productList.find(x => x.name === inputProductInfo.value)
        if (product) {
            if (innerProductId.value !== product.id) {
                innerProductId.value = product.id
                emitOuter = true
            }
        } else {
            if (innerProductId.value) emitOuter = true
            innerProductId.value = null
        }
        //没有选择 清空输入框
        if (!innerProductId.value) {
            inputProductInfo.value = ''
        }
    }
    if (emitOuter) changeProductId()
}
onMounted(() => {
    if (props.productId) {
        innerProductId.value = props.productId
        let product = store.state.productList.find(x => x.id === props.productId)
        product && (inputProductInfo.value = product.name)
    }
    if (props.productList && props.productList.length) {
        storeProductList = props.productList
    } else {
        storeProductList = store.state.productList
    }
    sortProductList()
    innerReadonly.value = props.readonly
    innerDisabled.value = props.disabled
})

const changeProductId = () => {
    if (innerProductId.value) {
        emits('update:productId', innerProductId.value)
    } else {
        emits('update:productId', null)
    }
    emits('changeProduct', innerProductId.value)
}
// 产品弹窗
const productVisible = ref(false)
const showProduct = () => {
    if (innerReadonly.value || innerDisabled.value) {
        return
    }
    productVisible.value = true
    searchProductTypeId.value = 0
    searchProductInfo.value = ''
    searchModalProduct()
}
const hideProduct = () => {
    productVisible.value = false
}
const treeRef = ref(null)
const searchProductTypeId = ref(0)
const chooseProductType = val => {
    searchProductTypeId.value = val.id
    searchModalProduct()
}
const searchProductInfo = ref('')
let debounce = null
watch(searchProductInfo, (n, o) => {
    if (debounce !== null) {
        clearTimeout(debounce)
    }
    debounce = setTimeout(() => {
        searchModalProduct()
    }, 300)
})
const modalProductList = ref([])
const searchModalProduct = () => {
    if (!searchProductTypeId.value && !searchProductInfo.value) {
        modalProductList.value = storeProductList.filter(productDefaultFilter)
    } else {
        modalProductList.value = storeProductList.filter(x => {
            return x.code !== nullProductNo
                && (!searchProductTypeId.value || x.typePath.indexOf(',' + searchProductTypeId.value + ',') >= 0)
                && (!searchProductInfo.value ||
                    (x.name && x.name.indexOf(searchProductInfo.value) >= 0) || (x.code && x.code.indexOf(searchProductInfo.value) >= 0)
                    || (x.pinyin && x.pinyin.indexOf(searchProductInfo.value.toUpperCase()) >= 0))
        })
    }
}
import {throttle} from "@/util/common";

const chooseProduct = throttle(row => {
    innerProductId.value = row.id
    inputProductInfo.value = row.name
    productVisible.value = false
    changeProductId()
}, 300)
const resetSearchProduct = () => {
    searchProductTypeId.value = 0
    searchProductInfo.value = ''
    searchModalProduct()
}

</script>

<style scoped lang="scss">

</style>