<template>
    <el-autocomplete title="可以按照名称、拼音首字母和编号搜索" v-model.trim="inputSupplyInfo" value-key="name" clearable
                     placeholder="请选择供应商" @select="chooseInputSupply"
                     highlight-first-item @blur="blurSupply"
                     :fetch-suggestions="fetchSupply">
        <template #default="{ item }">
            <div style="display: flex;justify-content:space-between;">
                <b>{{ item.name }}</b>
                <span style="float: right;color: var(--el-text-color-secondary);font-size: 13px;">{{
                        item.code
                    }}</span>
            </div>
        </template>

    </el-autocomplete>
</template>
<!--示例-->
<!--
<supply-select v-model:supply-id="supplyId" :supply-list="facSupplyList"></supply-select>
-->
<script setup>
import {useStore} from "vuex";
import {ref, reactive, onMounted, watch, toRef, defineProps, defineEmits, computed} from 'vue'
import {commonListDefaultFilter, commonListFilter} from "@/util/formatter";

const props = defineProps({
    supplyId: Number,
    supplyList: Array
})
const emits = defineEmits(['update:supplyId', 'changeSupply'])
watch(() => props.supplyId, (n, o) => {
    if (n) {
        innerSupplyId.value = parseInt(n)
        debounceGetSupply()
    } else {
        innerSupplyId.value = null
        inputSupplyInfo.value = ''
    }
})
watch(() => props.supplyList, (n, o) => {
    if (n) {
        innerSupplyList = n
        debounceGetSupply()
    } else {
        innerSupplyList = []
    }
})
const getInnerSupply = () => {
    if (innerSupplyList && innerSupplyList.length) {
        let supply = innerSupplyList.find(x => x.id === innerSupplyId.value)
        supply && (inputSupplyInfo.value = supply.name)
    }
}
// 用debounce防止supplyId或者supplyList还未传值进来
let debounce = null
const debounceGetSupply = () => {
    if (debounce !== null) {
        clearTimeout(debounce)
    }
    debounce = setTimeout(() => {
        getInnerSupply()
    }, 100)
}

const innerSupplyId = ref()
const inputSupplyInfo = ref('')
let innerSupplyList = []
// 输入框
const fetchSupply = (str, cb) => {
    let res = []
    if (str) {
        res = innerSupplyList.filter(commonListFilter(str))
    } else {
        res = innerSupplyList.filter(commonListDefaultFilter)
    }
    cb(res)
}
const chooseInputSupply = item => {
    innerSupplyId.value = item.id
    changeSupplyId()
}

const blurSupply = () => {
    let emitOuter = false//是否要通知外层
    if (!inputSupplyInfo.value) {
        if (innerSupplyId.value) emitOuter = true
        innerSupplyId.value = null
    } else {
        let supply = null
        if (innerSupplyList && innerSupplyList.length) {
            supply = innerSupplyList.find(x => x.name === inputSupplyInfo.value)
        }
        if (supply) {
            if (innerSupplyId.value !== supply.id) {
                innerSupplyId.value = supply.id
                emitOuter = true
            }
        } else {
            if (innerSupplyId.value) emitOuter = true
            innerSupplyId.value = null
        }
        //没有选择 清空输入框
        if (!innerSupplyId.value) {
            inputSupplyInfo.value = ''
        }
    }
    if (emitOuter) changeSupplyId()
}

onMounted(() => {
    if (props.supplyId) {
        innerSupplyId.value = props.supplyId
        debounceGetSupply()
    }
    if (props.supplyList && props.supplyList.length) {
        innerSupplyList = props.supplyList
        debounceGetSupply()
    }
})

const changeSupplyId = () => {
    if (innerSupplyId.value) {
        emits('update:supplyId', innerSupplyId.value)
    } else {
        emits('update:supplyId', null)
    }
    emits('changeSupply', innerSupplyId.value)
}

</script>

<style scoped>

</style>