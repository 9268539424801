<template>
    <div>
        <el-form :model="casePackCreateSfParam" label-width="70px" label-position="left"
                 @submit.prevent>
            <el-form-item label="货物名称" class="text-danger">
                <el-input v-model.trim="casePackCreateSfParam.cargoName" maxlength="20"></el-input>
            </el-form-item>
            <el-form-item label="付款方式" class="text-danger">
                <el-select v-model="casePackCreateSfParam.payMethod" filterable>
                    <el-option v-for="item in sfPayMethodList" :key="item.id" :value="item.id" :label="item.name">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="快递类型" class="text-danger">
                <el-select v-model="casePackCreateSfParam.expressTypeId" filterable>
                    <el-option v-for="item in sfTypeList" :key="item.id" :value="item.id" :label="item.name">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="上门收件">
                <el-switch v-model="casePackCreateSfParam.isDocall" :active-value="1"
                           :inactive-value="0"></el-switch>
            </el-form-item>
            <el-form-item label="上门时间" v-show="!!casePackCreateSfParam.isDocall">
                <el-checkbox v-model="sendNow" label="立即上门取件" :true-label="1"
                             :false-label="0" border></el-checkbox>
                <el-radio-group v-model="sendDay" v-show="!sendNow">
                    <el-radio-button v-for="item in sendDayList" :key="item.id"
                                     :label="item.id">
                        {{ item.name }}
                    </el-radio-button>
                </el-radio-group>
                <el-radio-group v-model="sendTime" v-show="!sendNow">
                    <el-radio-button v-for="item in sendTimeList" :key="item.id"
                                     :label="item.id">
                        {{ item.name }}
                    </el-radio-button>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="快递单号">
                <el-input v-model.trim="casePackCreateSfParam.expressNo" maxlength="50" placeholder="非必填"></el-input>
            </el-form-item>
        </el-form>
        <div class="flex align-center justify-end margin-tb-sm">
            <el-button @click="handleSaveSfOrder" :loading="casePackCreateSfLoading" title="不调用顺丰下单接口，仅保存顺丰单号">仅保存订单号
            </el-button>
            <el-button @click="handleCreateSfOrder" :loading="casePackCreateSfLoading" type="primary">确认下单
            </el-button>
        </div>
    </div>
</template>

<script setup>
import {defineEmits, onMounted, ref, watch, computed} from "vue";
import {useCasePackCreateSf} from "@/use/case/useCaseOut";
import {getEnumList, sfPayMethodEnum, sfTypeEnum} from "@/util/enum";
import {ElMessage} from "element-plus";
import moment from 'moment'
import {useStore} from 'vuex'

const sfTypeList = ref(getEnumList(sfTypeEnum))
const sfPayMethodList = ref(getEnumList(sfPayMethodEnum))
const store = useStore()
const sfCache = computed(() => store.state.sfCache)
const emits = defineEmits(['success'])
const props = defineProps({
    id: Number,// 装箱单ID
})
watch(() => props.id, (n, o) => {
    casePackCreateSfParam.id = n
})
const {
    casePackCreateSfParam,
    casePackCreateSfLoading,
    casePackCreateSfResult,
    createPackSfOrder,
    savePackSfOrder
} = useCasePackCreateSf()

onMounted(() => {
    casePackCreateSfParam.id = props.id
    if (!casePackCreateSfParam.cargoName) {
        if (sfCache.value && sfCache.value.cargoName) {
            casePackCreateSfParam.cargoName = sfCache.value.cargoName
        } else {
            casePackCreateSfParam.cargoName = '牙模'
        }
    }
    if (sfCache.value) {
        if (sfCache.value.isDocall) {
            casePackCreateSfParam.isDocall = sfCache.value.isDocall
        }
        if (sfCache.value.sendNow) {
            sendNow.value = sfCache.value.sendNow
        }
        if (sfCache.value.sendDay) {
            sendDay.value = sfCache.value.sendDay
        }
        if (sfCache.value.sendTime) {
            sendTime.value = sfCache.value.sendTime
        }
    }
    if (!casePackCreateSfParam.payMethod) {
        casePackCreateSfParam.payMethod = sfPayMethodEnum.寄方付
    }
})
const sendNow = ref(1)
const sendDay = ref(1)
const sendDayList = ref([{
    id: 1,
    name: '今日取件'
}, {
    id: 2,
    name: '明日取件'
}])
const sendTime = ref()
const sendTimeList = ref([{
    id: 1,
    name: '08:00'
}, {
    id: 2,
    name: '10:00'
}, {
    id: 3,
    name: '12:00'
}, {
    id: 4,
    name: '14:00'
}, {
    id: 5,
    name: '16:00'
}, {
    id: 6,
    name: '18:00'
}])
// 根据选择日期和时间获取顺丰上门时间
const setTimeStr = () => {
    if (!casePackCreateSfParam.isDocall) {
        // 非上门取件 没有上门时间
        casePackCreateSfParam.sendStartTm = ''
        return
    }
    if (sendNow.value) {
        // 立即上门取件 没有预订上门时间
        casePackCreateSfParam.sendStartTm = ''
        return
    }
    if (!sendDay.value || !sendTime.value) {
        ElMessage.error('请选择取件时间')
        return
    }
    let timeStr = ''
    let timeItem = sendTimeList.value.find(x => x.id === sendTime.value)
    if (sendDay.value === 1) {
        timeStr = moment().format('yyyy-MM-DD ' + timeItem.name + ':00')
    } else if (sendDay.value === 2) {
        timeStr = moment().add(1, 'days').format('yyyy-MM-DD ' + timeItem.name + ':00')
    }
    casePackCreateSfParam.sendStartTm = timeStr
}
const validSfOrder = () => {
    if (!casePackCreateSfParam.cargoName) {
        ElMessage.error('请填写货物名称')
        return false
    }
    if (!casePackCreateSfParam.payMethod) {
        ElMessage.error('请选择付款方式')
        return false
    }
    if (!casePackCreateSfParam.expressTypeId) {
        ElMessage.error('请选择快递产品')
        return false
    }
    setTimeStr()
    store.commit('setSfCache', {
        cargoName: casePackCreateSfParam.cargoName,
        isDocall: casePackCreateSfParam.isDocall,
        sendNow: sendNow.value,
        sendDay: sendDay.value,
        sendTime: sendTime.value
    })
    return true
}
const handleCreateSfOrder = async () => {
    if (!validSfOrder()) return
    await createPackSfOrder()
    if (casePackCreateSfResult.value) {
        emits('success', true)
    }
}
// 仅保存顺丰单号
const handleSaveSfOrder = async () => {
    if (!validSfOrder()) return
    await savePackSfOrder()
    if (casePackCreateSfResult.value) {
        emits('success', true)
    }
}
</script>

<style scoped>

</style>