//用于指令，权限判断,没权限隐藏当前元素
//例：v-check-auth="'查看子公司订单数据|管理员工'" 通过'|'区分多个权限
export default {
    name: 'check-auth',
    directive: (el, binding) => {
        let accountAuth = sessionStorage.getItem('funcList')
        console.log('mounted')
        // const authority = JSON.parse(accountAuth)
        // if (!authority) {
        //     el.style.display = 'none'
        //     return
        // }
        // let checkAuth = binding.value
        // if (!checkAuth) {
        //     return
        // }
        // let checkAuths = checkAuth.split('|')
        // let result = authority.some(item => {
        //     if (checkAuths.indexOf(item.nameEn) > -1) {
        //         return true
        //     }
        // })
        // console.log('mounted', result)
        // if (!result) {
        //     el.style.display = 'none'
        // }
    }
    // directive: {
    //     mounted(el, binding) {
    //         let accountAuth = sessionStorage.getItem('funcList')
    //         console.log('mounted')
    //         const authority = JSON.parse(accountAuth)
    //         if (!authority) {
    //             el.style.display = 'none'
    //             return
    //         }
    //         let checkAuth = binding.value
    //         if (!checkAuth) {
    //             return
    //         }
    //         let checkAuths = checkAuth.split('|')
    //         let result = authority.some(item => {
    //             if (checkAuths.indexOf(item.nameEn) > -1) {
    //                 return true
    //             }
    //         })
    //         console.log('mounted', result)
    //         if (!result) {
    //             el.style.display = 'none'
    //         }
    //     },
    //     updated(el, binding) {
    //         let accountAuth = sessionStorage.getItem('funcList')
    //         const authority = JSON.parse(accountAuth)
    //         if (!authority) {
    //             el.style.display = 'none'
    //             return
    //         }
    //         let checkAuth = binding.value
    //         if (!checkAuth) {
    //             return
    //         }
    //         let checkAuths = checkAuth.split('|')
    //         let result = authority.some(item => {
    //             if (checkAuths.indexOf(item.nameEn) > -1) {
    //                 return true
    //             }
    //         })
    //         console.log('updated', result)
    //         if (!result) {
    //             el.style.display = 'none'
    //         }
    //     }
    // }
}