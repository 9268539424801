// 工厂公告
import {reactive, ref} from 'vue'
import {
    factoryNoticeSave,
    factoryNoticeGetPublish,
    factoryNoticeDelete,
    factoryNoticeGetPage
} from "@/api/system";
import {useStore} from 'vuex'

/**
 * 删除工厂公告
 * @returns {{deleteFactoryNoticeLoading: *, deleteFactoryNoticeResult: *, deleteFactoryNotice: ((function(*): Promise<void>)|*)}}
 */
function useDeleteFactoryNotice() {
    const deleteFactoryNoticeLoading = ref(false)
    const deleteFactoryNoticeResult = ref(false)
    const deleteFactoryNotice = async id => {
        deleteFactoryNoticeResult.value = false
        if (deleteFactoryNoticeLoading.value) return
        deleteFactoryNoticeLoading.value = true
        deleteFactoryNoticeResult.value = await factoryNoticeDelete({id: id})
        deleteFactoryNoticeLoading.value = false
    }
    return {
        deleteFactoryNoticeLoading,
        deleteFactoryNoticeResult,
        deleteFactoryNotice,
    }
}

/**
 * 保存工厂公告
 * @returns {{initFactoryNotice: initFactoryNotice, saveFactoryNotice: ((function(): Promise<void>)|*), saveFactoryNoticeLoading: *, saveFactoryNoticeResult: *, factoryNotice: *}}
 */
function useSaveFactoryNotice() {
    const factoryNotice = reactive({
        id: 0,
        content: '',
        isPublish: 0,
    })
    const emptyItem = Object.assign({}, factoryNotice)
    const initFactoryNotice = () => {
        Object.assign(factoryNotice, emptyItem)
    }
    const saveFactoryNoticeLoading = ref(false)
    const saveFactoryNoticeResult = ref(false)
    const validateFactoryNotice = () => {
        if (!factoryNotice.content) {
            ElMessage.error('请输入公告内容')
            return false
        }
        return true
    }
    const saveFactoryNotice = async () => {
        saveFactoryNoticeResult.value = false
        if (saveFactoryNoticeLoading.value) return
        let valid = validateFactoryNotice()
        if (!valid) {
            return
        }
        saveFactoryNoticeLoading.value = true
        saveFactoryNoticeResult.value = await factoryNoticeSave(factoryNotice)
        saveFactoryNoticeLoading.value = false
    }
    return {
        factoryNotice,
        initFactoryNotice,
        saveFactoryNoticeResult,
        saveFactoryNoticeLoading,
        saveFactoryNotice
    }
}

/**
 * 工厂公告分页
 * @returns {{getPageFactoryNotice: ((function(): Promise<void>)|*), getPageFactoryNoticeResult: *, getPageFactoryNoticeParam: *, factoryNoticePage: *, initGetPageFactoryNoticeParam: initGetPageFactoryNoticeParam, getPageFactoryNoticeLoading: *}}
 */
function useGetPageFactoryNotice() {
    const getPageFactoryNoticeParam = reactive({
        content: '',
        isPublish: null,
        pageIndex: 1,
        pageSize: 10,
    })
    const getPageFactoryNoticeParamEmpty = Object.assign({}, getPageFactoryNoticeParam)
    const getPageFactoryNoticeResult = reactive({
        totalCount: 0,
        totalPages: 0,
    })
    const factoryNoticePage = ref([])
    const initGetPageFactoryNoticeParam = () => {
        Object.assign(getPageFactoryNoticeParam, getPageFactoryNoticeParamEmpty)
    }
    const getPageFactoryNoticeLoading = ref(false)
    const getPageFactoryNotice = async () => {
        if (getPageFactoryNoticeLoading.value) return
        getPageFactoryNoticeLoading.value = true
        let data = await factoryNoticeGetPage(getPageFactoryNoticeParam)
        if (data && data.list && data.list.length) {
            factoryNoticePage.value = data.list
            getPageFactoryNoticeResult.totalPages = Math.ceil(data.totalCount / getPageFactoryNoticeParam.pageSize)
            getPageFactoryNoticeResult.totalCount = data.totalCount
        } else {
            factoryNoticePage.value = []
            getPageFactoryNoticeResult.totalPages = 0
            getPageFactoryNoticeResult.totalCount = 0
        }
        getPageFactoryNoticeLoading.value = false
    }
    return {
        getPageFactoryNoticeParam,
        getPageFactoryNoticeResult,
        factoryNoticePage,
        initGetPageFactoryNoticeParam,
        getPageFactoryNoticeLoading,
        getPageFactoryNotice
    }
}

/**
 * 获取最近发布的工厂公告
 * @returns {{getPublishFactoryNoticeLoading: *, publishFactoryNotice: *, getPublishFactoryNotice: ((function(): Promise<void>)|*)}}
 */
function useGetPublishFactoryNotice() {
    const publishFactoryNotice = reactive({
        id: 0,
        content: '',
        isPublish: 0
    })
    const store = useStore()
    const getPublishFactoryNoticeLoading = ref(false)
    const getPublishFactoryNotice = async () => {
        if (getPublishFactoryNoticeLoading.value) return
        getPublishFactoryNoticeLoading.value = true
        let res = await factoryNoticeGetPublish()
        if (res) {
            Object.assign(publishFactoryNotice, res)
        } else {
            publishFactoryNotice.id = 0
            publishFactoryNotice.content = ''
        }
        store.commit('setFactoryNotice', publishFactoryNotice.content)
        getPublishFactoryNoticeLoading.value = false
    }
    return {
        publishFactoryNotice,
        getPublishFactoryNoticeLoading,
        getPublishFactoryNotice
    }
}

export {
    useGetPublishFactoryNotice,
    useSaveFactoryNotice,
    useGetPageFactoryNotice,
    useDeleteFactoryNotice
}