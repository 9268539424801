<template class="content" >
    <div style="height:70vh;overflow: auto;" >
       <span class="contenTtitle"> 最近更新日期:</span> 2024年8月8日  <span class="contenTtitle">生效日期:</span> 2024年8月8日  <span class="contenTtitle">版本号：</span>YS20240808
       <el-divider></el-divider>
       <span class="title"> 引言</span>
       <br><br>
        您的信任对存知网络科技有限公司（以下简称“存知科技”或“我们”）非常重要，我们深知个人信息安全的重要性，我们将按法律法规要求，采取相应安全保护措施，尽力保护您的个人信息安全可控。鉴此，我们制定《存知科技隐私政策》（下称“本隐私政策”）并提醒您：
        <br><br>
        本隐私政策适用于我们的产品或服务。如存知科技关联公司（范围详见定义部分）的产品或服务中使用了存知科技提供的产品或服务（仅限于使用存知科技账号登录的用户）但未设置独立的隐私政策的，则本隐私政策同样适用于该部分产品或服务。
        <br><br>
        需要特别说明的是，本隐私政策不适用于其他第三方向您提供的产品或服务，第三方向您提供的产品或服务适用其向您说明的隐私政策。
        <br><br>
        在使用我们的各项产品或服务前，请您务必仔细阅读并透彻理解本隐私政策，特别是以粗体\粗体下划线标识的条款，您应重点阅读，请在确认充分理解并同意后使用我们的产品或服务。如对本隐私政策内容有任何疑问、意见或建议，您可通过本隐私政策“九、如何联系我们”中披露的联系方式与我们联系。如您不同意本隐私政策，请您不要点击或勾选同意本隐私政策以及使用我们需要收集您个人信息方可使用的功能，但您仍可以进行浏览、搜索我们网站和产品页面展示的信息。
        <br><br>
        <span class="title">第一部分 定义</span>
        <br><br>
        <span class="title">企业用户：</span>指注册、登录、使用存知产品或服务并获得管理权限的个人或组织，包括但不限于法人以及政府机构、合伙企业、个体工商户等非法人组织（下称“企业用户”）；企业用户可以通过存知产品或服务平台（下称“存知平台”）创建其工作团队，邀请并授权个人用户加入其工作团队成为其最终用户。
        <br><br>
        <span class="title">企业管理员：</span>指经企业用户指定，由存知公司分配的，拥有企业用户管理后台系统操作权限的账号。
        <br><br>
        <span class="title">企业员工：</span>由企业管理员分配账号的企业名下的员工用户。
        <br><br>
        <span class="title">存知科技：</span>指存知网络科技有限公司。
        <br><br>
        <span class="title">存知平台：</span>指存知网络科技有限公司运营的，为您提供各项产品或服务的网站、客户端、移动应用程序、微信公众号、微信小程序等渠道（包括未来技术发展出现的新的形态），包括存知工厂管理平台、存知技工助手、存知数字化助手以及其他存知提供的加工厂管理软件平台。
        <br><br>
        <span class="title">产品或服务：</span> 指我们通过存知平台为您提供的各项产品功能或服务。
        <br><br>
        <span class="title"></span> 平台规则：指在存知平台上已经发布的及未来根据需要不定期更新的或新发布的所有服务规则、公告及各项产品或服务的流程说明、使用方法\规则介绍等。
        <br><br>
        <span class="title"></span> 个人信息：指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。
        <br><br>
        <span class="title">企业信息：</span>指企业从事生产经营活动过程中形成的信息，以及我们在为企业提供产品或服务过程中产生的能够反映企业状况的信息。
        <br><br>
        除另有约定外，本隐私政策所用定义与《存知科技用户使用协议》中的定义具有相同的涵义。
        <br><br>
        <span class="title">第二部分 隐私政策</span>
        <br><br>
        <span class="title">本隐私政策部分将帮助您了解以下内容：</span>  
        <br><br>
        一、我们如何收集和使用个人信息
        <br><br>
        二、我们如何使用Cookie和同类技术
        <br><br>
        三、我们如何委托处理、共享、转让、公开披露个人信息
        <br><br>
        四、我们如何保护个人信息
        <br><br>
        五、您如何管理个人信息
        <br><br>
        六、个人信息如何在全球范围转移
        <br><br>
        七、本隐私政策如何更新
        <br><br>
        八、如何联系我们
        <br><br>
        <span class="contenTtitle">一、我们如何收集和使用个人信息</span>
        <br><br>
        我们会出于本隐私政策所述的以下目的，收集和使用您的个人信息：
        <br><br>
        <span class="contenTtitle">（一）为您提供用户服务</span>
        <br><br>
        1、照片信息:存知平台为了方便为用户提供服务，允许让用户拍照上传相关的订单实物信息用于企业内部的流转，此行为需要用户提供自身的照片信息
        <br><br>
        2、加工文件信息：存知平台能够流转数字化文件，这些数字化文件应当是客户的商业内容，如果需要存知来做管理和记录，此行为需要用户或者用户的客户上传相关的数字化文件信息
        <br><br>
        <span class="contenTtitle">（二）为您提供企业经营软件服务</span>
        <br><br>
        1、运维服务：我们会基于系统运维及评估产品运行异常情况和平台性能的目的，收集并使用您的产品异常报错记录、数据迁移操作记录（包括订单ID，材料仓库信息，账户ID、单据ID和名称、迁移及执行时间、连接ID、组织ID、单据类型、执行状态及结果、迁移工具类型）、产品系统操作日志、网络日志。
        <br><br>
        2、对接快递服务：当您使用我们集成的第三方快递服务时，您需要授权我们将您的快递流转信息、物流信息同步给第三方快递公司。
        <br><br>
        <span class="contenTtitle">（三）附加服务——为您提供售后服务</span>
        <br><br>
        1、当客户在使用存知管理平台遇到问题时，可以寻求存知方的帮助，届时需要您提供必要的个人信息（您的手机号及验证码、用户ID、所属企业名称或服务码）以校验您的用户身份。
        <br><br>
        2、为便于与您联系、尽快帮助您解决问题或记录相关问题的处理方案及结果，我们可能会保存您与我们的通信或通话记录及相关内容（包括账号信息、订单号、下单日期、支付时间、开票信息，以及您为了证明相关事实提供的其他信息，并留下您的联系电话信息）。
        <br><br>
        3、为了提供服务及改进服务质量的合理需要，我们还可能在售后服务的过程中使用您的其他信息，包括您与客服联系时您主动提供的相关信息。
        <br><br>
        <span class="contenTtitle"> （四）为您提供安全保障</span>
        <br><br>
        为提高您使用我们及我们合作伙伴产品或服务的安全性，保护您或其他用户或公众的人身、财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或本隐私政策、平台规则的情况，我们可能使用或整合您的上述个人信息、订购信息、登录\使用信息、设备信息（包括操作系统或数据库版本、CPU型号及频率、MAC地址、设备名称及型号、服务器名称、IP地址信息）、有关网络日志以及我们合作伙伴取得您授权或依据法律共享的信息，来综合判断您的账号安全及经营风险、进行实名认证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。
        <br><br>
        <span class="contenTtitle"> （五）存知科技作为个人信息受托处理者时提供的用户服务</span>
        <br><br>
        1、企业用户控制的数据
        <br><br>
        当您作为企业最终用户，使用存知分配的账号并加入企业/组织，具备企业用户身份的该企业/组织使用我们的产品或服务实现财务管理、企业日常经营管理功能时，根据企业用户管理员的配置、操作和指令，企业最终用户使用我们产品或服务过程中可能会提交或产生的信息和数据（下称“企业用户控制数据”），企业用户控制数据基于企业最终用户使用的服务不同，可能包括：
        <br><br>
        1) 您作为企业用户管理员/创始人使用我们的产品或服务、选购开通第三方产品或服务、销售产品与服务时的订单交易信息，以便向您展示并便于您对订单进行管理。
        <br><br>
        2) 企业用户基于客户关系管理（CRM）、经营管理目的，由企业管理员通过使用我们产品功能与服务，为个人用户自主配置专属账号，以便企业最终用户登录和使用我们产品具体功能与服务，这些专属账号由企业用户所有，您使用专属账号上传、录入、发布、传输、存储及其他使用行为产生的数据资料，也属于企业用户控制数据。
        <br><br>
        3) 其他由个人用户明示授权同意企业组织控制的数据。
        <br><br>
        请您注意，企业用户控制数据由企业用户管理与控制，企业用户在提供这些涉及个人的信息时，应当确保已经事先获得个人信息主体的明确同意，且仅限于企业运营与管理目的收集和处理，并应充分向个人信息主体告知处理其个人信息的目的、方式及使用方式等。
        <br><br>
        2、企业用户控制的数据权归属
        <br><br>
        您同意并理解，企业用户为上述企业用户控制数据的控制者并决定处理目的与方式。我们仅根据企业用户管理员的指令，开通、管理和使用上述产品或服务处理企业用户控制数据或您的个人信息。您作为企业最终用户，理解并同意企业用户有权为了履行其与您签署的合同所必须，或者通过依法制定的劳动规章制度和依法签订的集体合同实施人力资源管理所必须，有权作为个人信息处理者，包括委托我们在开通、管理和使用我们产品或服务的目的和范围内，基于合法合理必要性，处理您作为企业最终用户的个人信息。
        <br><br>
        <span class="contenTtitle"> （六）其他用途</span>
        <br><br>
        1、若你提供的信息中含有其他用户的个人信息，在向我们提供这些个人信息之前，您需确保您已经取得合法的授权。
        <br><br>
        2、若我们将信息用于本隐私政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的，或者我们主动从第三方处获取您的个人信息，均会事先获得您的同意。
        <br><br>
        若我们从第三方处间接获取您的信息的，我们会在收集前明确以书面形式要求该第三方在已依法取得您同意后收集您的信息，并向您告知共享的信息内容，且涉及个人敏感信息、企业重要信息的在提供给我们使用前需经过您的明确确认，要求第三方对个人信息来源的合法性和合规性作出承诺，如第三方有违反行为的，我们会明确要求该第三方承担相应法律责任；同时，我们的专业安全团队对个人信息会进行安全加固（包括个人敏感信息、企业重要信息报备、加密存储、访问权限控制等）。我们会使用不低于我们对自身个人信息同等的保护手段与措施对我们间接获取的个人信息进行保护。
        <br><br>
        请知悉，您同意我们根据适用的法律，若我们对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定用户且不能复原，或我们可能会对收集的个人信息进行匿名化地研究、统计分析和预测，用于改善存知平台的内容和布局，为商业决策提供产品及或服务支撑，以及改进我们的产品及或服务（包括使用匿名数据进行机器学习或模型算法训练、模拟重现产品问题并处理），则此类处理后数据的使用无需另行向您通知并征得您的同意。
        <br><br>
        如我们停止存知平台的运营、产品或服务的提供，我们将及时停止继续收集、处理个人信息的活动，将停止运营、提供的通知以逐一送达或公告的形式通知您，对所持有的个人信息进行删除或匿名化处理。
        <br><br>
        <span class="contenTtitle"> 二、我们如何使用Cookie和同类技术</span>
        <br><br>
        <span class="contenTtitle">  （一）Cookie</span>
        <br><br>
        为确保存知平台相关的网站正常高效运转、为您获得更轻松的访问体验、向您推荐您可能感兴趣的内容，我们会在您的计算机或移动设备上存储相关信息：这些信息可能是Cookie、Flash Cookie，或您的浏览器或关联应用程序提供的其他本地存储（统称“Cookie”）。
        <br><br>
        请您理解，我们的某些产品及或服务只能通过使用Cookie才可得到实现。如果您的浏览器或浏览器附加服务允许，您可以修改对Cookie的接受程度或者拒绝我们的Cookie，但拒绝我们的Cookie在某些情况下可能会影响您安全访问网站和使用我们提供的产品及或服务。
        <br><br>
        如果您的浏览器或浏览器附加服务允许，您可修改对Cookie的接受程度或拒绝我们的Cookie。但如果您这么做，在某些情况下可能会影响您安全访问存知平台相关的网站，且可能需要在每一次访问存知平台相关的网站时更改用户设置。
        <br><br>
        <span class="contenTtitle">  （二）网站信标和像素标签</span>
        <br><br>
        除Cookie外，我们还会在存知平台相关的网站上使用网站信标和像素标签等其他同类技术。例如，我们向您发送的电子邮件可能含有链接至存知平台相关的网站内容的地址链接，如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品及或服务偏好以便于我们主动改善用户体验。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。
        <br><br>
        <span class="contenTtitle">   （三）Do Not Track（请勿追踪）</span>
        <br><br>
        很多网络浏览器均设有Do Not Track功能，该功能可向网站发布Do Not Track请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了Do Not Track，那么存知平台相关的所有网站都会尊重您的选择。您还可以使用您的浏览器设置来删除或阻止您计算机上的Cookie。
        <br><br>
        <span class="contenTtitle">   （四）本地文件系统</span>
        <br><br>
        部分存知产品需要访问客户的本地文件系统/服务器文件系统，以提供其他特殊的相关服务，我们只是访问这些文件，但是不会自发的篡改，删除，以及复制拷贝这些文件。
        <br><br>
        <span class="contenTtitle">   三、我们如何委托处理、共享、转让、公开披露个人信息</span>
        <br><br>
        <span class="contenTtitle">   （一）委托处理</span>
        <br><br>
        存知平台、我们产品及或服务中某些具体的模块或功能由外部供应商提供。例如我们会聘请服务提供商来协助我们提供技术支持。
        <br><br>
        对我们委托处理个人信息的公司、组织和个人，我们会与其签署严格的保密协议，要求他们按照我们的要求、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。
        <br><br>
        <span class="contenTtitle">    （二）共享</span>
        <br><br>
        我们不会与我们服务提供商以外的公司、组织和个人共享个人信息，但以下情况除外：
        <br><br>
        1、在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的信息。
        <br><br>
        2、在法定情形下的共享：我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的信息。
        <br><br>
        3、在您主动选择情况下共享：您通过存知平台订购我们的产品及或服务，我们会根据您的选择，将您的订单信息中与交易有关的必要信息共享给相关产品及或服务的提供者，以实现您的交易及售后服务需求。了解此情形中目前涉及的第三方，请访问第三方共享信息清单
        <br><br>
        4、关联公司之间共享：因您可以使用存知科技账号登录存知平台、使用我们的产品及或服务，为便于我们基于统一的账号共同向您提供产品及或服务，推荐您可能感兴趣的信息或保护我们或其他用户、公众的人身财产安全免遭侵害，您的信息可能会在我们之前共享。我们只会基于“一、我们如何收集和使用个人信息”中所述目的共享必要的个人信息（如为便于您使用存知科技账号使用存知科技关联公司的产品或服务，存知科技会向关联公司共享您必要的账号信息，或存知科技关联公司将您的信息向存知科技共享），如果我们共享您的个人敏感信息、企业重要信息或关联公司改变个人信息的使用及处理目的，将再次征求您的授权同意。
        <br><br>
        了解此情形中目前涉及的公司、组织和个人，请点击此处
        <br><br>
        5、与授权合作伙伴共享：仅为实现本隐私政策中声明的目的，我们的某些产品及或服务将由我们和授权合作伙伴共同提供。我们可能会与合作伙伴共享您的某些信息，如为了提供更有效率的消息推送服务，我们集成了个推SDK及华为、小米等手机厂商的消息推送服务，以便为您提供更好的客户服务和用户体验。我们仅会出于合法、正当、必要、特定、明确的目的共享您的信息，并且只会基于“一、我们如何收集和使用个人信息”中所述目的，共享提供产品或服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于与产品及或服务无关的其他用途。
        <br><br>
        了解此情形中目前我们的授权合作伙伴包括的类型，请点击此处
        <br><br>
        对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的数据保护协议，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。
        <br><br>
        我们会对授权合作伙伴获取有关信息的应用程序接口（API）、软件工具开发包（SDK）进行严格的安全检测，并与授权合作伙伴约定严格的数据保护措施，令其按照我们的委托目的、服务说明、本隐私权政策以及其他任何相关的保密和安全措施来处理个人信息。了解此情形中目前我们使用的第三方SDK收集信息的情况，请点击此处
        <br><br>
        <span class="contenTtitle">    （三）转让</span>
        <br><br>
        我们不会将您的信息转让给任何公司、组织和个人，但以下情况除外：
        <br><br>
        1、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的信息。
        <br><br>
        2、在我们产品及或服务的提供者发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，我们会要求新的持有您信息的公司、组织和个人继续受本隐私政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。
        <br><br>
        <span class="contenTtitle">   （四）公开披露</span>
        <br><br>
        我们仅会在以下情况下，公开披露个人信息：
        <br><br>
        1、获得您明确同意或基于您的主动选择，我们可能会公开披露您的信息；
        <br><br>
        2、如果我们确定您出现违反法律法规或严重违反本隐私政策、平台规则的情况，或为保护我们及其他用户、公众的人身财产安全免遭侵害，我们在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，可能会公开披露您的信息。
        <br><br>
        根据法律规定，共享、转让经匿名化处理的个人信息，且确保数据接收方无法复原并重新识别该用户的，不属于个人信息的对外委托处理、共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。
        <br><br>
        <span class="contenTtitle">   四、我们如何保护个人信息</span>
        <br><br>
        <span class="contenTtitle">     （一）我们已采取符合业界标准、合理可行的安全防护措施保护个人信息安全，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如，在您的浏览器与服务器之间交换数据时受SSL（Secure Socket Layer）协议加密保护；我们同时对我们提供HTTPS（Hyper Text Transfer Protocol over Secure Socket Layer）协议安全浏览方式；我们会使用加密技术提高个人信息的安全性；我们会使用受信赖的保护机制防止个人信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。</span>
        <br><br>
        <span class="contenTtitle">  （二）我们有行业先进的以数据为核心，围绕数据生命周期进行的数据安全管理体系，从组织建设、制度设计、人员管理、产品技术等方面多维度提升存知平台及产品及或服务的安全性，点击详见安全认证情况</span>
        <br><br>
        <span class="contenTtitle">   （三）我们会采取合理可行的措施，尽力避免收集无关的个人信息。我们只会在达成本隐私政策所述目的所需的期限内保留个人信息。我们只会在达成本隐私政策所述目的所需的期限内保留个人信息，除非法律有强制的存留要求，例如《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年。而我们判断前述期限的标准包括：
        </span>
            <br><br>
        1、完成与您相关的产品及服务使用目的、维护相应业务记录、应对您可能的查询或投诉；
        <br><br>
        2、保证我们为您提供产品及或服务的安全和质量；
        <br><br>
        3、您是否同意更长的留存期间；
        <br><br>
        4、是否存在保留期限的其他特别约定。
        <br><br>
        在个人信息超出保留期间后，我们会根据适用法律的要求删除个人信息，或使其匿名化处理。
        <br><br>
        <span class="contenTtitle">   （四）互联网并非绝对安全的环境，我们强烈建议您不要使用非我们推荐的通信方式发送个人信息。您可以通过存知平台建立联系和相互分享。当您通过存知平台创建交流、交易或分享时，您可以自主选择沟通、交易或分享的对象，作为能够看到您使用的产品及或服务内容、联络方式、交流信息或分享内容等相关信息的第三方。
        </span>
            <br><br>
        如果在存知平台上某软件涉及到资产交易[例如下单等]，您不可避免地要向交易对方或潜在的交易对方披露自己的信息，如联络方式或联系地址。请您妥善保护自己的信息，仅在必要的情形下向第三方提供。如您发现自己的信息尤其是您的账号或密码发生泄露，请您立即联系我们，以便我们根据您的申请采取相应措施。
        <br><br>
        请注意，您在存知平台上自愿共享甚至公开分享的信息，可能会涉及您或第三方的信息甚至个人敏感信息、企业重要信息。请您更加谨慎地考虑，是否在存知平台上共享甚至公开分享相关信息。
        <br><br>
        请使用复杂密码，协助我们保证您的账号安全。我们将尽力保障您发送给我们的任何信息的安全性。如果我们的物理、技术或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。
        <br><br>
        <span class="contenTtitle">   （五）我们将不定期更新并公开安全风险、个人信息安全影响评估报告等有关内容，您可通过我们公告方式获得。
        </span>
            <br><br>
        <span class="contenTtitle">  （六）在不幸发生个人信息安全事件后，我们将按照法律法规的要求向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况我们将以邮件、信函、电话、推送通知等方式告知您，难以逐一告知用户时，我们会采取合理、有效的方式发布公告。
        </span>
            <br><br>
        同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。
        <br><br>
        <span class="contenTtitle">   五、您如何管理个人信息
        </span>
            <br><br>
        您可以通过以下方式访问及管理您的信息：
        <br><br>
        <span class="contenTtitle">   （一）访问您的信息
        </span>
            <br><br>
        您有权访问您的信息，法律法规规定的例外情况除外。您可以通过以下方式自行访问您的信息：
        <br><br>
        账号信息——您可以在存知工厂管理平台的设置-用户管理处管理个人信息。具体访问方式如下：
        <br><br>
        登录网站，打开设置，在子栏目选择用户管理，按需搜索或者在列表中寻找用户名
        <br><br>
        <span class="contenTtitle">   （二）更正或补充您的信息
        </span>
            <br><br>
        用户信息只包含了自身的账号密码，以及超管对内部用户的管理方面，而其他用户信息可以在员工信息中填写，同时存知依然会记录这些信息但是不会去使用与修改
        <br><br>
        <span class="contenTtitle">   （三）用户注销账号
        </span>
            <br><br>
        关于账号注销，目前存知没有提供接口，对于超管可以直接删除账户，否则可以联系我们的客服热线电话：18072838781或通过第九条列明的其他方式与我们取得联系。
        <br><br>
        <span class="contenTtitle">  （四）获取个人信息副本及转移您的个人信息
        </span>
            <br><br>
        您有权获取您的信息副本，您可以通过第九条列明的联系方式联系我们处理。我们在技术可行的前提下，我们会提供关于您个人信息的标准格式的可读文本。
        <br><br>
        <span class="contenTtitle">  （五）约束信息系统自动决策
        </span>
            <br><br>
        在我们产品或服务的某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将在不侵害我们商业秘密或其他用户权益、社会公共利益的前提下提供申诉方法。
        <br><br>
        <span class="contenTtitle">   （六）响应您的上述请求
        </span>
            <br><br>
        为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。
        <br><br>
        我们将在15天内做出答复。如您不满意，还可以通过联系我们发起投诉。
        <br><br>
        对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。但按照有关法律法规规定，我们将无法响应您的请求除外。
        <br><br>
        <span class="contenTtitle"> 六、个人信息如何在全球范围转移        </span>
        <br><br>
        原则上，我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。
        <br><br>
        由于我们通过遍布全球的资源和服务器提供产品或服务，这意味着，在获得您的授权同意后，您的信息可能会被转移到您使用产品及或服务所在国家地区的境外管辖区，或者受到来自这些管辖区的访问。
        <br><br>
        此类管辖区可能有不同的数据保护法，甚至未制定相关法律。在此类情况下，我们会确保您的信息得到在中华人民共和国境内足够同等的保护。例如，我们会请求您对跨境转移个人信息的同意，或者在跨境数据转移之前实施数据去标识化等安全举措。
        <br><br>
        <span class="contenTtitle">    七、本隐私政策如何更新
        </span>
            <br><br>
        我们的隐私政策可能变更。
        <br><br>
        未经您明确同意，我们不会限制您按照本隐私政策所应享有的权利。我们会在存知平台上发布对隐私政策所做的任何变更。
        <br><br>
        对于重大变更，我们还会提供更为显著的通知（包括我们会通过我们公示的方式进行意见征集、公告通知甚至向您提供弹窗提示）。
        <br><br>
        本隐私政策所指的重大变更包括但不限于：
        <br><br>
        1、我们的产品及或服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
        <br><br>
        2、我们在控制权等方面发生重大变化。如并购重组等引起的信息控制者变更等；
        <br><br>
        3、个人信息共享、转让或公开披露的主要对象发生变化；
        <br><br>
        4、您参与个人信息处理方面的权利及其行使方式发生重大变化；
        <br><br>
        5、我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化；
        <br><br>
        6、个人信息安全影响评估报告表明存在高风险。
        <br><br>
        我们还会将本隐私权政策的旧版本存档，供您查阅。点击此处查阅历史版本隐私政策。
        <br><br>
        <span class="contenTtitle">     八、如何联系我们
        </span>
            <br><br>
        我们设有专门法务合规部门监督个人信息保护事宜，有关本隐私政策或我们的隐私措施相关问题可通过如下方式联系我们，我们将在15天内回复您的请求：
        <br><br>
        客服联系电话：18072838781,15958152341
        <br><br>
        联系地址：杭州市拱墅区候圣街99号顺丰创新中心4号楼3层301
        <br><br>
        如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，您还可以通过向被告住所地有管辖权的法院提起诉讼来寻求解决方案。
            
       </div>
    </template>
<style scoped lang="scss">
   .title{
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: bold;
   }
   .contenTtitle{
        margin-bottom: 10px;
        font-size: 15px;
        font-weight: bold;
   }
   .content{
        margin-bottom: 5px;
        font-size: 15px;
   }
   .right{
        float: right;
   }
</style>