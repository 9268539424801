import {reactive, ref, inject, toRaw} from 'vue'
import {casePrintTemplateSave, casePrintTemplateDelete, casePrintTemplateGetList} from "@/api/case";

function useSaveCasePrintTemplate() {
    const casePrintTemplate = reactive({
        id: 0,
        name: '',
        remark: '',
        width: 595,
        height: 842,
        printWidth: 210,
        printHeight: 297,
        printTemplateType: 0,
        isDefault: 0,
        showCaseOut: 0,
        mainItem: 1,
        printerName: '',
        isSkip: 0,
        orient: 1,
        details: [],
        columns: []
    })
    const casePrintTemplateEmpty = JSON.parse(JSON.stringify(toRaw(casePrintTemplate)))
    const setFields = inject('setFields')
    const initCasePrintTemplate = () => {
        setFields(casePrintTemplate, casePrintTemplateEmpty)
        casePrintTemplate.details = []
        casePrintTemplate.columns = []
    }
    const casePrintTemplateFormRef = ref(null)
    const casePrintTemplateSaveRules = reactive({
        name: [
            {required: true, message: '请输入模板名称', trigger: 'change'},
        ],
        width: [
            {required: true, message: '请输入纸张宽度', trigger: 'change'},
        ],
        height: [
            {required: true, message: '请输入纸张高度', trigger: 'change'},
        ],
    })
    const saveCasePrintTemplateLoading = ref(false)
    const saveCasePrintTemplateResult = ref(false)
    const saveCasePrintTemplate = async () => {
        if (saveCasePrintTemplateLoading.value) return
        saveCasePrintTemplateLoading.value = true
        saveCasePrintTemplateResult.value = false
        await casePrintTemplateFormRef.value.validate(async (valid, fields) => {
            if (!valid) {
                saveCasePrintTemplateLoading.value = false
                return
            }
            saveCasePrintTemplateResult.value = await casePrintTemplateSave(casePrintTemplate)
            saveCasePrintTemplateLoading.value = false
        })
    }
    return {
        casePrintTemplate,
        initCasePrintTemplate,
        casePrintTemplateFormRef,
        casePrintTemplateSaveRules,
        saveCasePrintTemplateLoading,
        saveCasePrintTemplateResult,
        saveCasePrintTemplate,
    }
}

//删除打印模板
function useDeleteCasePrintTemplate() {
    const deleteCasePrintTemplateLoading = ref(false)
    const deleteCasePrintTemplateResult = ref(false)
    const deleteCasePrintTemplate = async id => {
        deleteCasePrintTemplateResult.value = false
        if (deleteCasePrintTemplateLoading.value) return
        deleteCasePrintTemplateLoading.value = true
        deleteCasePrintTemplateResult.value = await casePrintTemplateDelete({id: id})
        deleteCasePrintTemplateLoading.value = false
    }
    return {
        deleteCasePrintTemplateLoading,
        deleteCasePrintTemplateResult,
        deleteCasePrintTemplate,
    }
}

// 获取打印模板列表
function useGetListCasePrintTemplate() {
    const getListCasePrintTemplateParam = reactive({
        printTemplateType: 0
    })
    const getListCasePrintTemplateLoading = ref(false)
    const casePrintTemplateList = ref([])
    const getListCasePrintTemplate = async () => {
        if (getListCasePrintTemplateLoading.value) return
        getListCasePrintTemplateLoading.value = true
        let data = await casePrintTemplateGetList(getListCasePrintTemplateParam)
        if (data) {
            casePrintTemplateList.value = data
        }
        getListCasePrintTemplateLoading.value = false
    }
    return {
        getListCasePrintTemplateParam,
        getListCasePrintTemplateLoading,
        casePrintTemplateList,
        getListCasePrintTemplate,
    }
}

//根据模板构造打印格式
import {newPrintColumn, newPrintColumnExtra} from "@/util/printUtil";
import {printTemplateType, getEnumId} from "@/util/enum";
import {hiprint} from "vue-plugin-hiprint";
import {ElMessage} from "element-plus";

function usePrintTemplate() {
    const getPrintPanel = casePrintTemplate => {
        return {
            width: casePrintTemplate.printWidth,
            height: casePrintTemplate.printHeight,
            rotate: true
        }
    }
    const setPrintText = (panel, casePrintTemplate, tbHeight) => {
        for (let detail of casePrintTemplate.details.filter(x => !!x.isPrint)) {
            if (detail.detailType === 'toothNoTable') {
                // 牙位表格特殊文本打印
                setPrintToothForText(panel, detail)
                continue
            }
            if (detail.detailField === 'productRegNo') {
                let regNoNum = 3//最多3个产品注册证号
                for (let i = 0; i < regNoNum; i++) {
                    setPrintProductRegNo(detail, i, panel)
                }
                continue
            }
            let textItem = {
                options: {
                    width: detail.posWidth,
                    height: detail.posHeight,
                    top: detail.posTop,
                    left: detail.posLeft,
                    fontSize: detail.fontSize,
                    textType: detail.detailType,
                    fixed: true,
                }
            }
            if (detail.detailType === 'barcode') {
                textItem.options.barcodeMode = 'CODE128'
            }
            let table = casePrintTemplate.details.find(x => x.detailType === 'table' && x.detailTitle === "产品明细" && !!x.isPrint)
            if (!!table && detail.posTop > table.posTop) {
                textItem.options.top += tbHeight;
            }
            if (detail.showTitle === 1) {
                textItem.options.title = detail.detailTitle
            } else {
                textItem.options.hideTitle = true
            }
            if (detail.showField === 1) {
                textItem.options.field = detail.detailField
            }
            if (!!detail.transform) {
                textItem.options.transform = detail.transform
            }

            if (detail.align === '左') {
                textItem.options.textAlign = 'left'
            } else if (detail.align === '中') {
                textItem.options.textAlign = 'center'
            } else if (detail.align === '右') {
                textItem.options.textAlign = 'right'
            }
            panel.addPrintText(textItem)
        }
    }
    const setPrintProductRegNo = (detail, index, panel) => {
        let textItem = {
            options: {
                width: detail.posWidth,
                height: 15,
                top: detail.posTop + 15 * index,
                left: detail.posLeft,
                fontSize: detail.fontSize,
                textType: detail.detailType,
                field: 'productRegNo' + (index + 1),
                fixed: true,
            }
        }
        if (detail.align === '左') {
            textItem.options.textAlign = 'left'
        } else if (detail.align === '中') {
            textItem.options.textAlign = 'center'
        } else if (detail.align === '右') {
            textItem.options.textAlign = 'right'
        }
        panel.addPrintText(textItem)
    }
    const setPrintTable = (panel, casePrintTemplate) => {
        let table = casePrintTemplate.details.find(x => x.detailType === 'table' && !!x.isPrint)
        if (!table) return
        let columnAlign = 'right'
        if (table.align === '左') {
            columnAlign = 'left'
        } else if (table.align === '中') {
            columnAlign = 'center'
        }
        let tmpColumns = []
        for (let column of casePrintTemplate.columns.filter(x => !!x.isPrint)) {
            tmpColumns.push(newPrintColumn(column.colTitle, column.colField, column.colWidth, column.colOrder, columnAlign))
        }
        panel.addPrintTable({
            options: {
                width: table.posWidth,
                top: table.posTop,
                left: table.posLeft,
                field: 'details',
                columns: [tmpColumns]
            }
        })
    }
    // 牙位表格特殊文本打印
    const setPrintToothForText = (panel, toothTable) => {
        if (!toothTable) return
        let spaceWidth = 3//牙号和中间竖线的距离
        panel.addPrintText({
            options: {
                width: toothTable.posWidth / 2 - spaceWidth,
                height: toothTable.posHeight / 2,
                top: toothTable.posTop,
                left: toothTable.posLeft,
                fontSize: toothTable.fontSize,
                fixed: true,
                textAlign: 'right',
                field: 'pos1'
            }
        })
        panel.addPrintText({
            options: {
                width: toothTable.posWidth / 2,
                height: toothTable.posHeight / 2,
                top: toothTable.posTop,
                left: toothTable.posLeft + toothTable.posWidth / 2 + spaceWidth,
                fontSize: toothTable.fontSize,
                fixed: true,
                textAlign: 'left',
                field: 'pos2'
            }
        })
        panel.addPrintText({
            options: {
                width: toothTable.posWidth / 2 - spaceWidth,
                height: toothTable.posHeight / 2,
                top: toothTable.posTop + toothTable.posHeight / 2,
                left: toothTable.posLeft,
                fontSize: toothTable.fontSize,
                fixed: true,
                textAlign: 'right',
                field: 'pos4'
            }
        })
        panel.addPrintText({
            options: {
                width: toothTable.posWidth / 2,
                height: toothTable.posHeight / 2,
                top: toothTable.posTop + toothTable.posHeight / 2,
                left: toothTable.posLeft + toothTable.posWidth / 2 + spaceWidth,
                fontSize: toothTable.fontSize,
                fixed: true,
                textAlign: 'left',
                field: 'pos3'
            }
        })
        panel.addPrintHline({
            options: {
                width: toothTable.posWidth,
                top: toothTable.posTop + toothTable.posHeight / 2,
                left: toothTable.posLeft,
                borderColor: '',
                borderWidth: ''
            }
        })
        panel.addPrintVline({
            options: {
                height: toothTable.posHeight,
                top: toothTable.posTop,
                left: toothTable.posLeft + toothTable.posWidth / 2,
                borderColor: '',
                borderWidth: ''
            }
        })
    }
    return {
        getPrintPanel,
        setPrintText,
        setPrintTable,
    }
}


export {
    useSaveCasePrintTemplate,
    useDeleteCasePrintTemplate,
    useGetListCasePrintTemplate,
    usePrintTemplate,
}