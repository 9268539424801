import {reactive, ref} from 'vue'
import {factoryRestDayBatchSave, factoryRestDayGetList, factoryRestDayDelete} from "@/api/system";
import moment from 'moment'

function useGetListFactoryRestDay() {
    const getListFactoryRestDayParam = reactive({
        year: null
    })
    const getListFactoryRestDayLoading = ref(false)
    const factoryRestDayList = ref([])
    const getListFactoryRestDay = async () => {
        if (getListFactoryRestDayLoading.value) return
        getListFactoryRestDayLoading.value = true
        let data = await factoryRestDayGetList(getListFactoryRestDayParam)
        if (data) {
            factoryRestDayList.value = data
        }
        getListFactoryRestDayLoading.value = false
    }
    // 根据开始日期 需要天数 休息日 计算结束日期
    const calEndDate = (beginDate, days) => {
        beginDate = moment(beginDate)
        let extraDays = 0
        for (let i = 0; i < days; i++) {
            if (factoryRestDayList.value.some(x => x.restDate === beginDate.format('yyyy-MM-DD'))) {
                // 属于休息日
                extraDays++
            }
            beginDate.add(1, 'day')
        }
        if (extraDays) beginDate.add(extraDays, 'day')
        return beginDate
    }
    return {
        getListFactoryRestDayParam,
        getListFactoryRestDayLoading,
        factoryRestDayList,
        getListFactoryRestDay,
        calEndDate,
    }
}

function useBatchSaveFactoryRestDay() {
    const batchSaveFactoryRestDayParam = reactive({
        list: []
    })
    const batchSaveFactoryRestDayLoading = ref(false)
    const batchSaveFactoryRestDayResult = ref(false)
    const batchSaveFactoryRestDay = async () => {
        if (batchSaveFactoryRestDayLoading.value) return
        batchSaveFactoryRestDayLoading.value = true
        batchSaveFactoryRestDayResult.value = false
        batchSaveFactoryRestDayResult.value = await factoryRestDayBatchSave(batchSaveFactoryRestDayParam)
        batchSaveFactoryRestDayLoading.value = false
    }
    return {
        batchSaveFactoryRestDayParam,
        batchSaveFactoryRestDayLoading,
        batchSaveFactoryRestDayResult,
        batchSaveFactoryRestDay,
    }
}

function useDeleteFactoryRestDay() {
    const deleteFactoryRestDayLoading = ref(false)
    const deleteFactoryRestDayResult = ref(false)
    const deleteFactoryRestDay = async restDate => {
        deleteFactoryRestDayResult.value = false
        if (deleteFactoryRestDayLoading.value) return
        deleteFactoryRestDayLoading.value = true
        deleteFactoryRestDayResult.value = await factoryRestDayDelete({restDate: restDate})
        deleteFactoryRestDayLoading.value = false
    }
    return {
        deleteFactoryRestDayLoading,
        deleteFactoryRestDayResult,
        deleteFactoryRestDay,
    }
}


export {
    useGetListFactoryRestDay,
    useBatchSaveFactoryRestDay,
    useDeleteFactoryRestDay,
}