import {reactive, ref, inject} from 'vue'
import {useStore} from 'vuex'
import {sha256_digest} from "@/util/sha"
import {cnPhoneNoReg} from '@/util/validator'
import {ElMessage, ElNotification} from "element-plus";
import {
    userLogin,
    userLoginFactory,
    userGetPage,
    userSave,
    userResetPassword,
    userUpdateRole,
    userUnlock,
    doctorUserUnlock,
    doctorUserGetPage,
    doctorUserReset,
    userResetMyPwd,
    userRegisterCloud,
    factoryAboutToExpire
} from '@/api/system'
import {defaultPageSize} from "@/util/constant";
import {useAddCloudLoginRecord} from "@/use/system/useLoginRecord";
import moment from "moment";

function useSaveUser() {
    const user = reactive({
        id: 0,
        phoneNo: '',
        password: '',
        roleId: null,
        workerId: null
    })
    const initUser = () => {
        user.id = 0
        user.phoneNo = ''
        user.password = ''
        user.roleId = null
        user.workerId = null
    }
    const userFormRef = ref()
    const userAddRules = reactive({
        phoneNo: [
            {required: true, pattern: cnPhoneNoReg, message: '请输入正确的手机号码', trigger: 'blur'},
        ],
        password: [
            {required: true, message: '请输入初始密码', trigger: 'blur'},
        ],
        roleId: [
            {required: true, message: '请选择角色', trigger: 'change'},
        ],
        workerId: [
            {required: true, message: '请选择员工', trigger: 'blur'},
        ],
    })
    const saveUserLoading = ref(false)
    const saveUserResult = ref(false)
    const saveUser = async () => {
        saveUserResult.value = false
        if (saveUserLoading.value) return
        await userFormRef.value.validate(async (valid, fields) => {
            if (!valid) {
                return
            }
            saveUserLoading.value = true
            let param = {
                id: user.id,
                userName: user.phoneNo,
                password: sha256_digest(user.password),
                roleId: user.roleId,
                workerId: user.workerId
            }
            saveUserResult.value = await userSave(param)
            saveUserLoading.value = false
        })
    }
    const addUser = async param => {
        let request = {
            id: param.id,
            userName: param.phoneNo,
            password: sha256_digest(param.password),
            roleId: param.roleId,
            workerId: param.workerId
        }
        return await userSave(request)
    }

    return {
        user,
        initUser,
        userFormRef,
        userAddRules,
        saveUserLoading,
        saveUserResult,
        saveUser,
        addUser,
    }
}

function useResetPassword() {
    const resetPasswordParam = reactive({
        id: 0,
        password: '',
    })
    const resetPasswordRef = ref()
    const resetPasswordRules = reactive({
        password: [
            {required: true, message: '请输入密码', trigger: 'blur'},
        ]
    })
    const resetPasswordLoading = ref(false)
    const resetPasswordResult = ref(false)
    const resetPassword = async () => {
        resetPasswordResult.value = false
        if (resetPasswordLoading.value) return
        await resetPasswordRef.value.validate(async (valid, fields) => {
            if (!valid) {
                return
            }
            resetPasswordLoading.value = true
            let param = {
                id: resetPasswordParam.id,
                password: sha256_digest(resetPasswordParam.password)
            }
            resetPasswordResult.value = await userResetPassword(param)
            resetPasswordLoading.value = false
        })
    }
    return {
        resetPasswordParam,
        resetPasswordRef,
        resetPasswordRules,
        resetPasswordLoading,
        resetPasswordResult,
        resetPassword
    }
}

function useUpdateRole() {
    const updateRoleParam = reactive({
        id: '',
        workerId: '',
        roleId: ''
    })
    const updateRoleLoading = ref(false)
    const updateRoleResult = ref(false)
    const updateRole = async () => {
        if (updateRoleLoading.value) return
        updateRoleLoading.value = true
        updateRoleResult.value = false
        updateRoleResult.value = await userUpdateRole(updateRoleParam)
        updateRoleLoading.value = false
    }
    return {
        updateRoleParam,
        updateRoleLoading,
        updateRoleResult,
        updateRole,
    }
}

function useSimpleResetPassword() {
    const resetPasswordParam = reactive({
        id: 0,
        password: ''
    })
    const resetPasswordLoading = ref(false)
    const resetPasswordResult = ref(false)
    const resetPassword = async () => {
        if (!resetPasswordParam.password || resetPasswordParam.password.length < 6) {
            ElMessage.error('请输入长度超过6位的密码')
            return
        }
        if (resetPasswordLoading.value) return
        resetPasswordLoading.value = true
        let param = {
            id: resetPasswordParam.id,
            password: sha256_digest(resetPasswordParam.password)
        }
        resetPasswordResult.value = await userResetPassword(param)
        resetPasswordLoading.value = false
    }
    return {
        resetPasswordParam,
        resetPasswordLoading,
        resetPasswordResult,
        resetPassword
    }
}

// 登录
function useLogin() {
    const {addCloudLoginRecord} = useAddCloudLoginRecord()
    const store = useStore()
    const toPage = inject('toPage')
    const user = reactive({
        userName: '',
        password: '',
        verifyCode: '',
    })
    const needVerify = ref(false)
    const loginLoading = ref(false)
    const factoryId = ref(0)
    const factoryList = ref([])
    const factoryListVisible = ref(false)
    const login = async () => {
        if (loginLoading.value) return
        if (!user.userName) {
            ElMessage.error('请输入手机号')
            return
        }
        if (!user.password) {
            ElMessage.error('请输入密码')
            return
        }
        if (needVerify.value && !user.verifyCode) {
            ElMessage.error('请输入验证码')
            return
        }
        loginLoading.value = true
        let param = {
            userName: user.userName,
            password: sha256_digest(user.password),
            verifyCode: user.verifyCode
        }
        try {
            localStorage.setItem('factoryUserName', user.userName)
            let res = await userLogin(param)
            if (res.code === 0) {
                if (res.data.needVerify === 1) {
                    needVerify.value = true
                    ElMessage.error(res.data.message)
                    return
                }
                needVerify.value = false
                factoryList.value = res.data.factoryList
                if (factoryList.value.length === 1) {
                    factoryId.value = factoryList.value[0].id
                    await loginFactory()
                } else {
                    factoryList.value.push({
                        id: 0,
                        name: '请选择工厂'
                    })
                    factoryListVisible.value = true
                }
            } else {
                ElMessage.error(res.message)
            }
        } catch (e) {
            ElMessage.error('请求错误，请检查网络或者联系客服')
        } finally {
            loginLoading.value = false
        }
    }
    const loginFactoryLoading = ref(false)
    const loginFactory = async () => {
        if (factoryId.value === 0) {
            ElMessage.error('请选择工厂')
            return
        }
        if (loginFactoryLoading.value) return
        loginFactoryLoading.value = true
        let factory = factoryList.value.find(x => x.id === factoryId.value);
        let param = {
            factoryId: factoryId.value,
            userName: user.userName,
            userAppType: 1,
            password: sha256_digest(user.password)
        }
        try {
            let res = await userLoginFactory(param)
            if (res.code === 0) {
                ElMessage.success('登录成功')
                store.commit('setUserName', res.data.userName)
                store.commit('setPhoneNo', res.data.phoneNo)
                store.commit('setToken', res.data.token)
                store.commit('setAuth', res.data.modules)
                store.commit('setFuncAuth', res.data.funcList)
                store.commit('setFactoryName', factory.name)
                if (store.state.factoryId != factoryId.value) {
                    //更换了工厂 需要刷新缓存
                    store.commit('setProductType', [])
                    store.commit('setProductList', [])
                    store.commit('setProductDetails', [])
                    store.commit('setMaterialType', [])
                    store.commit('setMaterialList', [])
                    store.commit('setHospitalList', [])
                    store.commit('setDoctorList', [])
                    store.commit('setHospitalDetails', [])
                    store.commit('setWorkerList', [])
                    store.commit('setMaterialSupplyList', [])
                }
                store.commit('setUpdateStoreTime', '')
                setTimeout(async () => {
                    //先判断是否更改了工厂ID（导致刷新缓存） 再修改缓存中的工厂ID
                    store.commit('setFactoryId', factoryId.value)
                    addCloudLoginRecord()
                    var expire = await factoryAboutToExpire()
                    if (expire && expire.expiring) {
                        ElNotification({
                            title: '产品即将过期',
                            message: `您的产品将于${moment(expire.endDate).format('yyyy-MM-DD')}过期`,
                            type: 'warning',
                            position: 'bottom-right',
                            duration: 0
                        })
                    }
                    store.commit('setCurPageName', 'welcome')
                    toPage('welcome')
                }, 100)
            } else {
                ElMessage.error(res.message)
            }
        } catch (e) {
            ElMessage.error('请求错误，请检查网络或者联系客服')
        }
        loginFactoryLoading.value = false
    }
    return {
        user,
        needVerify,
        loginLoading,
        login,
        factoryId,
        factoryList,
        factoryListVisible,
        loginFactory,
        loginFactoryLoading
    }
}

// 登出
function useLogout() {
    const store = useStore()
    const toPage = inject('toPage')
    const logOut = () => {
        store.commit('setToken', '')
        sessionStorage.clear()
        toPage('login')
    }
    return {
        logOut
    }
}

// 管理员用户分页
function useGetPageUser() {
    const getPageUserParam = reactive({
        pageIndex: 1,
        pageSize: defaultPageSize,
        userName: '',
    })
    const getPageUserResult = reactive({
        totalCount: 0,
        totalPages: 0
    })
    const initGetPageUserParam = () => {
        getPageUserParam.pageIndex = 1;
        getPageUserParam.userName = '';
    }
    const userPage = ref([])
    const getPageUserLoading = ref(false)
    const getPageUser = async () => {
        if (getPageUserLoading.value) return
        getPageUserLoading.value = true
        let data = await userGetPage(getPageUserParam)
        if (data) {
            if (data.list && data.list.length) {
                userPage.value = data.list
            } else {
                userPage.value = []
            }
            getPageUserResult.totalPages = Math.ceil(data.totalCount / getPageUserParam.pageIndex)
            getPageUserResult.totalCount = data.totalCount
        }
        getPageUserLoading.value = false
    }
    return {
        getPageUserParam,
        getPageUserResult,
        initGetPageUserParam,
        userPage,
        getPageUserLoading,
        getPageUser
    }
}

function useUnlockUser() {
    const unlockUserLoading = ref(false)
    const unlockUserResult = ref(false)
    const unlockUser = async id => {
        if (unlockUserLoading.value) return
        unlockUserLoading.value = true
        unlockUserResult.value = false
        unlockUserResult.value = await userUnlock({id: id})
        unlockUserLoading.value = false
    }
    return {
        unlockUserResult,
        unlockUserLoading,
        unlockUser
    }
}

function useUnlockDoctorUser() {
    const unlockDoctorUserLoading = ref(false)
    const unlockDoctorUserResult = ref(false)
    const unlockDoctorUser = async id => {
        if (unlockDoctorUserLoading.value) return
        unlockDoctorUserLoading.value = true
        unlockDoctorUserResult.value = false
        unlockDoctorUserResult.value = await doctorUserUnlock({id: id})
        unlockDoctorUserLoading.value = false
    }
    return {
        unlockDoctorUserResult,
        unlockDoctorUserLoading,
        unlockDoctorUser
    }
}

function useResetDoctorUser() {
    const resetPasswordParam = reactive({
        id: 0,
        password: ''
    })
    const resetDoctorUserLoading = ref(false)
    const resetDoctorUserResult = ref(false)
    const resetDoctorUser = async id => {
        if (!resetPasswordParam.password || resetPasswordParam.password.length < 6) {
            ElMessage.error('请输入长度超过6位的密码')
            return
        }
        if (resetDoctorUserLoading.value) return
        resetDoctorUserLoading.value = true
        resetDoctorUserResult.value = false
        let param = {
            id: resetPasswordParam.id,
            password: sha256_digest(resetPasswordParam.password)
        }
        resetDoctorUserResult.value = await doctorUserReset(param)
        resetDoctorUserLoading.value = false
    }
    return {
        resetPasswordParam,
        resetDoctorUserResult,
        resetDoctorUserLoading,
        resetDoctorUser
    }
}

function useResetMyPwd() {
    const resetMyPwdParam = reactive({
        password: '',
        passwordAgain: ''
    })
    const resetMyPwdLoading = ref(false)
    const resetMyPwdResult = ref(false)
    const resetMyPwd = async () => {
        if (!resetMyPwdParam.password || resetMyPwdParam.password.length < 6) {
            ElMessage.error('请输入长度超过6位的密码')
            return
        }
        if (resetMyPwdParam.password !== resetMyPwdParam.passwordAgain) {
            ElMessage.error('两次密码不一致，请检查')
            return
        }
        if (resetMyPwdLoading.value) return
        resetMyPwdResult.value = false
        resetMyPwdLoading.value = true
        let param = {
            password: sha256_digest(resetMyPwdParam.password)
        }
        resetMyPwdResult.value = await userResetMyPwd(param)
        resetMyPwdLoading.value = false
    }
    return {
        resetMyPwdParam,
        resetMyPwdResult,
        resetMyPwdLoading,
        resetMyPwd
    }
}

function useGetPageDoctorUser() {
    const getPageDoctorUserParam = reactive({
        pageIndex: 1,
        pageSize: defaultPageSize,
        userName: '',
        facDoctorName: '',
        facHospitalId: null
    })
    const getPageDoctorUserResult = reactive({
        totalCount: 0,
        totalPages: 0
    })
    const initGetPageDoctorUserParam = () => {
        getPageDoctorUserParam.pageIndex = 1;
        getPageDoctorUserParam.doctorUserName = '';
    }
    const doctorUserPage = ref([])
    const getPageDoctorUserLoading = ref(false)
    const getPageDoctorUser = async () => {
        if (getPageDoctorUserLoading.value) return
        getPageDoctorUserLoading.value = true
        let data = await doctorUserGetPage(getPageDoctorUserParam)
        if (data) {
            if (data.list && data.list.length) {
                doctorUserPage.value = data.list
            } else {
                doctorUserPage.value = []
            }
            getPageDoctorUserResult.totalPages = Math.ceil(data.totalCount / getPageDoctorUserParam.pageIndex)
            getPageDoctorUserResult.totalCount = data.totalCount
        }
        getPageDoctorUserLoading.value = false
    }
    return {
        getPageDoctorUserParam,
        getPageDoctorUserResult,
        initGetPageDoctorUserParam,
        doctorUserPage,
        getPageDoctorUserLoading,
        getPageDoctorUser
    }
}

function useRegisterCloudUser() {
    const registerCloudUserLoading = ref(false)
    const registerCloudUserResult = ref(false)
    const registerCloudUser = async id => {
        if (registerCloudUserLoading.value) return
        registerCloudUserLoading.value = true
        registerCloudUserResult.value = false
        registerCloudUserResult.value = await userRegisterCloud({id: id})
        registerCloudUserLoading.value = false
    }
    return {
        registerCloudUserResult,
        registerCloudUserLoading,
        registerCloudUser
    }
}

//t代表用户名
const verifyPicPath = '/api/factory/sysUser/get_verify_pic?t='

export {
    useSaveUser,
    useResetPassword,
    useLogin,
    useGetPageUser,
    useLogout,
    useSimpleResetPassword,
    useUpdateRole,
    useUnlockUser,
    useResetDoctorUser,
    useUnlockDoctorUser,
    useGetPageDoctorUser,
    verifyPicPath,
    useResetMyPwd,
    useRegisterCloudUser,
}