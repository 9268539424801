import http from "@/util/axios";
// 代理
export const facAgentSave = data => http.factoryPost('facAgent/save', data)
export const facAgentDelete = data => http.factoryPost('facAgent/delete', data)
export const facAgentGetList = data => http.factoryGet('facAgent/get_simple_list', data)
export const facAgentGetPage = data => http.factoryGet('facAgent/get_page', data)
export const facAgentUpdateValid = data => http.factoryPost('facAgent/update_valid', data)
// 医院
export const facHospitalSave = data => http.factoryPost('facHospital/save', data)
export const facHospitalDelete = data => http.factoryPost('facHospital/delete', data)
export const facHospitalGetList = data => http.factoryGet('facHospital/get_list', data)
export const facHospitalGet = data => http.factoryGet('facHospital/get', data)
export const facHospitalGetPage = data => http.factoryGet('facHospital/get_page', data)
export const facHospitalGetNeedHideList = data => http.factoryGet('facHospital/get_need_hide_list', data)
export const facHospitalUpdateValid = data => http.factoryPost('facHospital/update_valid', data)
export const facHospitalBatchValid = data => http.factoryPost('facHospital/batch_valid', data)
export const facHospitalUpdateDelivery = data => http.factoryPost('facHospital/update_delivery', data)
export const facHospitalBatchUpdateNeedHide = data => http.factoryPost('facHospital/batch_update_need_hide', data)
export const facHospitalListExportExcel = '/api/factory/facHospitalExport/excel_list'
// 科室
export const facHospitalDeptSave = data => http.factoryPost('facHospitalDept/save', data)
export const facHospitalDeptDelete = data => http.factoryPost('facHospitalDept/delete', data)
export const facHospitalDeptGetList = data => http.factoryGet('facHospitalDept/get_list', data)
// 医生
export const facDoctorSave = data => http.factoryPost('facDoctor/save', data)
export const facDoctorDelete = data => http.factoryPost('facDoctor/delete', data)
export const facDoctorGetList = data => http.factoryGet('facDoctor/get_list', data)
export const facDoctorGetPage = data => http.factoryGet('facDoctor/get_page', data)
export const facDoctorGet = data => http.factoryGet('facDoctor/get', data)
export const facDoctorGetSimpleList = data => http.factoryGet('facDoctor/get_simple_list', data)
export const facDoctorUpdateValid = data => http.factoryPost('facDoctor/update_valid', data)
// 医生习惯
export const doctorHabitBatchSave = data => http.factoryPost('doctorHabit/batch_save', data)
export const doctorHabitGetList = data => http.factoryGet('doctorHabit/get_list', data)
// 客户凭证
export const facHospitalLicenseSave = data => http.factoryPost('facHospitalLicense/save', data)
export const facHospitalLicenseDelete = data => http.factoryPost('facHospitalLicense/delete', data)
export const facHospitalLicenseGetList = data => http.factoryGet('facHospitalLicense/get_list', data)
// 客户抱怨
export const complainSave = data => http.factoryPost('complain/save', data)
export const complainDelete = data => http.factoryPost('complain/delete', data)
export const complainGetPage = data => http.factoryGet('complain/get_page', data)
export const complainGetStat = data => http.factoryGet('complain/get_stat', data)
// 客户价格
export const facHospitalPriceSave = data => http.post('facHospitalPrice/save', data)
export const facHospitalPriceSavePrice = data => http.post('facHospitalPrice/save_price', data)
export const facHospitalPriceGetList = data => http.factoryGet('facHospitalPrice/get_list', data)
export const facHospitalPriceGetPage = data => http.factoryGet('facHospitalPrice/get_page', data)
export const facHospitalPriceCopy = data => http.post('facHospitalPrice/copy', data)
export const facHospitalPriceGetStoreList = data => http.factoryGet('facHospitalPrice/get_store_list', data)
export const hospitalProductPriceGetList = data => http.factoryGet('facHospitalPrice/get_hospital_list', data)
export const facHospitalPriceIncrease = data => http.factoryPost("facHospitalPrice/batch_increase", data)
export const facHospitalPriceExcel = '/api/factory/facHospitalExport/excel_hospital_price'
// 末次送件
export const facHospitalFinalCaseGetPage = data => http.factoryGet('facHospitalFinalCase/get_page', data)
export const facHospitalFinalCaseExcel = '/api/factory/facHospitalExport/excel_final_case'
// 医院每日入件合计
export const facHospitalDailyCaseGetPage = data => http.factoryGet('facHospitalDailyCase/get_page', data)
export const facHospitalDailyCaseExcel = '/api/factory/facHospitalExport/excel_daily_case'
// 私人价格
export const privatePriceAdd = data => http.factoryPost('privatePrice/add', data)
export const privatePriceMod = data => http.factoryPost('privatePrice/mod', data)
export const privatePriceGetList = data => http.factoryGet('privatePrice/get_list', data)
export const privatePriceGetStoreList = data => http.factoryGet('privatePrice/get_store_list', data)
export const privatePriceSaveProduct = data => http.factoryPost('privatePrice/save_product', data)
export const privatePriceDeleteProduct = data => http.factoryPost('privatePrice/delete_product', data)
export const privatePriceGetProductList = data => http.factoryGet('privatePrice/get_product_list', data)
export const privatePriceCopyPrice = data => http.factoryPostData('privatePrice/copy_price', data)
export const privatePriceGetDoctorList = data => http.factoryGet('privatePrice/get_doctor_list', data)
export const privatePriceDeleteDoctor = data => http.factoryPost('privatePrice/delete_doctor', data)
export const privatePriceDelete = data => http.factoryPost('privatePrice/delete', data)
export const privatePriceUpdateStore = data => http.factoryPostBg('privatePrice/update_hospital_store', data)
// 回访
export const visitSave = data => http.factoryPost('visit/save', data)
export const visitGetList = data => http.factoryGet('visit/get_list', data)
export const visitDelete = data => http.factoryPost('visit/delete', data)