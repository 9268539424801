<template>
    <DndProvider :backend="HTML5Backend">
        <router-view v-if="routeAlive"/>
    </DndProvider>

</template>

<script setup>
import {provide, ref, nextTick, onMounted} from 'vue'

onMounted(() => {
    document.getElementById('app').style.display = 'block'
    document.getElementById('appLoading').style.display = 'none'
})
const routeAlive = ref(true)
provide('reload', () => {
    routeAlive.value = false
    nextTick(() => {
        routeAlive.value = true
    })
})

import * as echarts from 'echarts'

provide('echarts', echarts)

import {DndProvider} from 'vue3-dnd'
import {HTML5Backend} from 'react-dnd-html5-backend'
</script>

<style lang="scss">
html, body {
    height: 100%;
}

body {
    display: flex;
    margin: 0;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*text-align: center;*/
    color: #2c3e50;
    flex-grow: 1;
}

nav {
    padding: 30px;
}

nav a {
    font-weight: bold;
    color: #2c3e50;
}

nav a.router-link-exact-active {
    color: #42b983;
}

.text-primary {
    color: var(--el-color-primary) !important;
}

.text-primary-light {
    color: var(--el-color-primary-light-5) !important;
}

.text-success {
    color: var(--el-color-success) !important;
}

.text-success-light {
    color: var(--el-color-success-light-5) !important;
}

.text-warning {
    color: var(--el-color-warning) !important;
}

.text-warning-light {
    color: var(--el-color-warning-light-5) !important;
}

.text-danger {
    color: var(--el-color-danger) !important;
}

.text-danger-light {
    color: var(--el-color-danger-light-5) !important;
}

.text-danger .el-form-item__label {
    color: var(--el-color-danger) !important;
}

.text-info {
    color: var(--el-color-info) !important;
}

.text-white {
    color: var(--el-color-white) !important;
}

.btn-icon {
    width: 1em;
    height: 1em;
    margin-right: 3px
}

.flex-row {
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
}

.flex-item-cc {
    //主轴
    justify-content: center;
    //交叉轴
    align-items: center;
}

.flex-item-sc {
    justify-content: space-between;
    align-items: center;
}

.flex-item-st {
    justify-content: flex-start;
    align-items: stretch;
}

.flex-item-ce {
    justify-content: center;
    align-items: flex-end;
}

.flex-b {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex-col {
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
}

.flex-grow {
    -webkit-flex-grow: 1;
    flex-grow: 1;
}

.w-50 {
    width: 50px !important;

    .el-input {
        width: 50px !important;
    }
}

.w-70 {
    width: 70px !important;

    .el-input {
        width: 70px !important;
    }
}

.input-small {
    width: 100px !important;

    .el-input {
        width: 100px !important;
    }
}

.w-90 {
    width: 90px !important;

    .el-input {
        width: 90px !important;
    }
}

.w-120 {
    width: 120px !important;

    .el-input {
        width: 120px !important;
    }
}

.w-135 {
    width: 135px !important;

    .el-input {
        width: 135px !important;
    }
}

.w-150 {
    width: 150px !important;

    .el-input {
        width: 150px !important;
    }
}

.w-250 {
    width: 250px !important;

    .el-input {
        width: 250px !important;
    }
}

.w-216 {
    width: 216px !important;

    .el-input {
        width: 216px !important;
    }
}

.input-medium {
    width: 200px !important;

    .el-input {
        width: 200px !important;
    }
}

.input-large {
    width: 300px !important;

    .el-input {
        width: 300px !important;
    }
}

.input-extra {
    width: 500px !important;

    .el-input {
        width: 500px !important;
    }
}

// 普通线图和柱状图的图标高度
.chart-normal {
    height: 450px;
}

.chart-small {
    height: 300px;
}

.ml-20 {
    margin-left: 20px;
}

.mt-20 {
    margin-top: 20px;
}

//页面容器
.view-container {
    //margin-top: 10px;
    border: 1px solid #edf1f2;
    border-radius: 2px 2px 0 0;
}

//页面只有form
.view-form {
    display: flex;
    padding: 30px;
    background-color: white;
}

.form-3 {
    width: 930px;
}

.form-4 {
    width: 1050px;
}

//页面搜索容器
.view-search {
    display: flex;
    padding-top: 20px;
    padding-left: 10px;
    background-color: var(--el-fill-color-light);
}

.view-search .el-form-item {
    margin-bottom: 13px !important;
}

//页面表格容器
.view-table {
    align-items: stretch;
}

//页面表格分页容器
.view-pagination {
    padding: 7px;
    background-color: white;
    display: flex;
}

// 树结构页面样式
.view-tree {
    min-height: 700px;
    align-items: stretch;
    -webkit-align-items: stretch;
    background-color: white;
}

.view-tree-left {
    padding: 10px;
    width: 250px;
    //border-right: 2px solid #edf1f2;
}

.view-tree-right {
    //padding: 10px;
    background-color: white;
}

// 左右分页页面样式
.view-list {
    min-height: 700px;
    align-items: stretch;
    -webkit-align-items: stretch;
    background-color: #f6f8f8;
}

.view-list-left {
    padding: 5px;
    flex-shrink: 0;
    width: 300px;
}

.view-list-left-sm {
    padding: 5px;
    flex-shrink: 0;
    width: 200px;
}

.view-list-left .el-form-item {
    margin-bottom: 10px !important;
}

.view-list-left-sm .el-form-item {
    margin-bottom: 10px !important;
}

.view-list-right {
    background-color: white;
    flex: 1;
    width: 0;
    min-width: 1000px;
}

.view-list-right.col-3 {
    min-width: 960px;
}

.view-list-right.col-4 {
    min-width: 1100px;
}

// 图片上传
.avatar-uploader .avatar {
    width: 150px;
    height: 150px;
    display: block;
}

.avatar-uploader .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.el-border-right {
    border-right: 1px solid var(--el-border-color);
}

.el-border-left {
    border-left: 1px solid var(--el-border-color);
}

.el-border-top {
    border-top: 1px solid var(--el-border-color);
}

.el-border-bottom {
    border-bottom: 1px solid var(--el-border-color);
}

.el-border {
    border: 1px solid var(--el-border-color);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    text-align: center;
}

.el-icon.open-select {
    &:hover {
        color: var(--el-color-primary);
        cursor: pointer;
    }
}

.el-check-tag.tooth {
    padding: 5px 8px;
    background-color: var(--el-color-white);

    &.default {
        box-shadow: 1px 1px 1px 1px var(--el-color-info-light-9) inset;
    }

    &.primary {
        color: var(--el-color-white);
        background-color: var(--el-color-primary);
    }

    &.danger {
        color: var(--el-color-white);
        background-color: var(--el-color-danger);
    }

    &.success {
        color: var(--el-color-white);
        background-color: var(--el-color-success);
    }

    &.success-tmp {
        color: var(--el-color-success);
        background-color: var(--el-color-white);
    }

    &.success-left {
        color: var(--el-color-danger);
        background-image: linear-gradient(to right, var(--el-color-success) 50%, white 50%);
    }

    &.success-right {
        color: var(--el-color-danger);
        background-image: linear-gradient(to left, var(--el-color-success) 50%, white 50%);
    }
}


.tooth.el-checkbox-button--small .el-checkbox-button__inner {
    padding: 5px 8px !important;
}

.pt-10 {
    padding-top: 10px;
}

.bg-grey-light {
    background-color: #f6f8f8 !important;
}

.bg-light {
    background-color: var(--el-fill-color-light) !important;
}

.product-input .el-input-group__append, .el-input-group__prepend {
    padding: 0 15px !important;
}

.h-24 {
    height: 24px;
}

.second-menu {
    .el-menu-item {
        line-height: 40px;
        font-size: 13px;
    }
}
</style>