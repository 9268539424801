<template>
    <div class="padding-sm">
        <el-card>
            <div class="text-lg margin-bottom-xs">企业数据</div>
            <div class="w-full flex">
                <div class="flex-sub bg-r-blue top-stat flex justify-start align-center margin-right-xs"
                     @click="setPage('hospitalList')">
                    <div class="text-primary bg-white icon-div flex align-center justify-center">
                        <font-awesome-icon icon="fa-solid fa-hospital-user"/>
                    </div>
                    <div class="margin-left-sm">
                        <div class="text-df">医院数量</div>
                        <div class="text-xl margin-top-xxs">{{ factoryEnterpriseDataInfo.numberOfHospitals }}</div>
                    </div>
                </div>
                <div class="flex-sub bg-r-red top-stat flex justify-start align-center margin-right-xs"
                     @click="setPage('productList')">
                    <div class="text-danger bg-white icon-div flex align-center justify-center">
                        <font-awesome-icon icon="fa-solid fa-hospital-user"/>
                    </div>
                    <div class="margin-left-sm">
                        <div class="text-df">产品数量</div>
                        <div class="text-xl margin-top-xxs">{{ factoryEnterpriseDataInfo.numberOfProducts }}</div>
                    </div>
                </div>
                <div class="flex-sub bg-r-yellow top-stat flex justify-start align-center margin-right-xs"
                     @click="setPage('materialList')">
                    <div class="text-warning bg-white icon-div flex align-center justify-center">
                        <font-awesome-icon icon="fa-solid fa-hospital-user"/>
                    </div>
                    <div class="margin-left-sm">
                        <div class="text-df">材料数量</div>
                        <div class="text-xl margin-top-xxs">{{ factoryEnterpriseDataInfo.quantityOfMaterial }}</div>
                    </div>
                </div>
                <div class="flex-sub bg-r-green top-stat flex justify-start align-center margin-right-xs"
                     @click="setPage('supplyList')">
                    <div class="text-success bg-white icon-div flex align-center justify-center">
                        <font-awesome-icon icon="fa-solid fa-hospital-user"/>
                    </div>
                    <div class="margin-left-sm">
                        <div class="text-df">供应商数量</div>
                        <div class="text-xl margin-top-xxs">{{ factoryEnterpriseDataInfo.numberOfDealers }}</div>
                    </div>
                </div>
                <div class="flex-sub bg-r-cyan top-stat flex justify-start align-center margin-right-xs"
                     @click="setPage('workerList')">
                    <div class="text-cyan bg-white icon-div flex align-center justify-center">
                        <font-awesome-icon icon="fa-solid fa-hospital-user"/>
                    </div>
                    <div class="margin-left-sm">
                        <div class="text-df">员工数量</div>
                        <div class="text-xl margin-top-xxs">{{ factoryEnterpriseDataInfo.numberOfEmployees }}</div>
                    </div>
                </div>
            </div>
        </el-card>
        <div class="w-full flex margin-top-sm">
            <el-card class="flex-sub margin-right-sm">
                <div class="text-lg margin-bottom-xs">订单与生产</div>
                <div class="flex bg-df padding-tb">
                    <div class="flex-sub flex flex-direction align-center justify-center" @click="setPage('caseAdd')">
                        <el-image class="func-icon" :src="caseAddIcon"></el-image>
                        <div class="text-df text-info margin-top-xs">新增订单</div>
                    </div>
                    <div class="flex-sub flex flex-direction align-center justify-center" @click="setPage('caseList')">
                        <el-image class="func-icon" :src="caseListIcon"></el-image>
                        <div class="text-df text-info margin-top-xs">订单查询</div>
                    </div>
                    <div class="flex-sub flex flex-direction align-center justify-center"
                         @click="setPage('netOrderAudit')">
                        <el-image class="func-icon" :src="financeAuditIcon"></el-image>
                        <div class="text-df text-info margin-top-xs">网络订单审核</div>
                    </div>
                    <div class="flex-sub flex flex-direction align-center justify-center"
                         @click="setPage('caseFinance')">
                        <el-image class="func-icon" :src="financeAuditIcon"></el-image>
                        <div class="text-df text-info margin-top-xs">财务审核</div>
                    </div>
                </div>
                <div class="flex bg-df padding-tb">
                    <div class="flex-sub flex flex-direction align-center justify-center"
                         @click="setPage('produceScan')">
                        <el-image class="func-icon" :src="stepAddIcon"></el-image>
                        <div class="text-df text-info margin-top-xs">扫描工序</div>
                    </div>
                    <div class="flex-sub flex flex-direction align-center justify-center" @click="setPage('stepList')">
                        <el-image class="func-icon" :src="stepSetIcon"></el-image>
                        <div class="text-df text-info margin-top-xs">工序设置</div>
                    </div>
                    <div class="flex-sub flex flex-direction align-center justify-center"
                         @click="setPage('stepTestAdd')">
                        <el-image class="func-icon" :src="stepTestIcon"></el-image>
                        <div class="text-df text-info margin-top-xs">过程质检</div>
                    </div>
                    <div class="flex-sub flex flex-direction align-center justify-center"
                         @click="setPage('productStepCheck')">
                        <el-image class="func-icon" :src="testAddIcon"></el-image>
                        <div class="text-df text-info margin-top-xs">出厂质检</div>
                    </div>
                </div>
            </el-card>
            <div class="flex-sub">
                <el-card>
                    <div class="text-lg margin-bottom-xs">采购与仓库</div>
                    <div class="flex bg-df padding-tb">
                        <div class="flex-sub flex flex-direction align-center justify-center"
                             @click="setPage('purchaseApply')">
                            <el-image class="func-icon" :src="purchaseStockIcon"></el-image>
                            <div class="text-df text-info margin-top-xs">采购申请</div>
                        </div>
                        <div class="flex-sub flex flex-direction align-center justify-center"
                             @click="setPage('purchaseAdd')">
                            <el-image class="func-icon" :src="purchaseAddIcon"></el-image>
                            <div class="text-df text-info margin-top-xs">采购单录入</div>
                        </div>
                        <div class="flex-sub flex flex-direction align-center justify-center"
                             @click="setPage('purchaseStockIn')">
                            <el-image class="func-icon" :src="purchaseStockIcon"></el-image>
                            <div class="text-df text-info margin-top-xs">采购验收</div>
                        </div>
                        <div class="flex-sub flex flex-direction align-center justify-center"
                             @click="setPage('stockInAdd')">
                            <el-image class="func-icon" :src="stockInIcon"></el-image>
                            <div class="text-df text-info margin-top-xs">入库单录入</div>
                        </div>
                    </div>
                    <div class="flex bg-df padding-tb">
                        <div class="flex-sub flex flex-direction align-center justify-center"
                             @click="setPage('stockApplyAdd')">
                            <el-image class="func-icon" :src="stockApplyIcon"></el-image>
                            <div class="text-df text-info margin-top-xs">领料申请</div>
                        </div>
                        <div class="flex-sub flex flex-direction align-center justify-center"
                             @click="setPage('stockOutAdd')">
                            <el-image class="func-icon" :src="stockOutIcon"></el-image>
                            <div class="text-df text-info margin-top-xs">出库单录入</div>
                        </div>
                        <div class="flex-sub flex flex-direction align-center justify-center"
                             @click="setPage('stockNow')">
                            <el-image class="func-icon" :src="stockNowIcon"></el-image>
                            <div class="text-df text-info margin-top-xs">当前库存</div>
                        </div>
                        <div class="flex-sub flex flex-direction align-center justify-center"
                             @click="setPage('stockFlow')">
                            <el-image class="func-icon" :src="stockFlowIcon"></el-image>
                            <div class="text-df text-info margin-top-xs">出入库流水</div>
                        </div>
                    </div>
                </el-card>
            </div>
        </div>
        <div class="w-full flex margin-top-sm" style="height: 600px">
            <el-card class="flex-sub margin-right-sm">
                <div class="text-lg margin-bottom-xs">公告列表</div>
                <el-table height="450px" :data="factoryNoticePage" :show-header="false">
                    <el-table-column label="公告内容" min-width="200" prop="content">
                    </el-table-column>
                </el-table>
                <div class="flex margin-top">
                    <div class="flex-grow"></div>
                    <el-pagination
                        v-model:currentPage="getPageFactoryNoticeParam.pageIndex"
                        v-model:page-size="getPageFactoryNoticeParam.pageSize"
                        :page-sizes="pageSizeList"
                        :disabled="!getPageFactoryNoticeResult.totalCount"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="getPageFactoryNoticeResult.totalCount"
                        @size-change="getPageFactoryNotice"
                        @current-change="getPageFactoryNotice"/>
                </div>
            </el-card>
            <el-card class="flex-sub">
                <div class="margin-bottom-xs flex align-center justify-between">
                    <span class="text-lg">仓库提醒</span>
                    <el-link type="info" @click="setPage('stockAlert')">查看>></el-link>
                </div>
                <el-table :data="stockAlertPage" v-loading="getPageStockAlertLoading" height="450px">
                    <el-table-column label="材料名称" min-width="150" prop="materialName"></el-table-column>
                    <el-table-column label="仓库" min-width="100" prop="warehouseName"></el-table-column>
                    <el-table-column label="提醒类型" width="180" prop="alertTypeName"></el-table-column>
                    <el-table-column label="提醒日期" min-width="100" prop="alertTime"
                                     :formatter="dateFormat"></el-table-column>
                </el-table>
                <div class="flex margin-top">
                    <div class="flex-grow"></div>
                    <el-pagination
                        v-model:currentPage="getPageStockAlertParam.pageIndex"
                        v-model:page-size="getPageStockAlertParam.pageSize"
                        :page-sizes="pageSizeList"
                        :disabled="!getPageStockAlertResult.totalCount"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="getPageStockAlertResult.totalCount"
                        @size-change="getPageStockAlert"
                        @current-change="getPageStockAlert"/>
                </div>
            </el-card>
        </div>
        <el-dialog v-model="stepCaseDialogVisible" :title="stepCaseDialogTitle">
            <el-checkbox border v-model="onlyLate" :true-label="1" :false-label="0" @change="changeLate">只显示延迟
            </el-checkbox>
            <el-table height="500px" :data="showCaseStepList" v-loading="getStepCaseStatDetailListLoading">
                <el-table-column label="订单号" min-width="160" prop="caseNo">
                    <template #default="scope">
                        <el-link type="primary" :underline="true" @click="editCase(scope.row.id)">{{
                                scope.row.caseNo
                            }}
                        </el-link>
                        <el-tag v-if="scope.row.whetherToPostpone" type="danger">延迟</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="盒号" min-width="90" prop="boxNo"></el-table-column>
                <el-table-column label="医院" min-width="130" prop="facHospitalName"></el-table-column>
                <el-table-column label="医生" min-width="90" prop="facDoctorName"></el-table-column>
                <el-table-column label="产品" min-width="140" prop="productName"></el-table-column>
                <el-table-column label="数量" min-width="60" prop="num"></el-table-column>
            </el-table>
            <footer>
                <div style="width: 100%;display: flex;">
                    <div class="flex-grow"></div>
                    <el-button @click="stepCaseDialogVisible=false">
                        关闭
                    </el-button>
                </div>
            </footer>
        </el-dialog>
    </div>
</template>

<script setup>
import {ref, reactive, onMounted, watch, inject, computed, onDeactivated, onActivated} from 'vue';
import {dateFormat} from "@/util/formatter";
import caseAddIcon from '@/assets/icon/caseAdd.png'
import caseListIcon from '@/assets/icon/caseList.png'
import financeAuditIcon from '@/assets/icon/financeAudit.png'
import purchaseStockIcon from '@/assets/icon/purchaseStock.png'
import stockInIcon from '@/assets/icon/stockIn.png'
import stockOutIcon from '@/assets/icon/stockOut.png'
import stockNowIcon from '@/assets/icon/stockNow.png'
import purchaseAddIcon from '@/assets/icon/purchaseAdd.png'
import stepSetIcon from '@/assets/icon/stepSet.png'
import stepAddIcon from '@/assets/icon/stepAdd.png'
import stepTestIcon from '@/assets/icon/stepTest.png'
import testAddIcon from '@/assets/icon/testAdd.png'
import stockApplyIcon from '@/assets/icon/stockApply.png'
import stockFlowIcon from '@/assets/icon/stockFlow.png'
import {useGetStepCaseStat, useGetStepCaseStatDetailList} from "@/use/produce/useCaseProductStep";
import {userGetFactoryEnterpriseData} from "@/use/system/useFactory";
import {pageSizeList} from "@/util/constant";

import {useGetPageStockAlert} from "@/use/warehouse/useStockAlert";
import {useRouter} from "vue-router";
import {debounce} from "@/util/common";
import {useGetPageFactoryNotice} from "@/use/system/useFactoryNotice";

const {
    getStepCaseStat,
    stepCaseStatList
} = useGetStepCaseStat()

const {
    getPageStockAlertParam,
    initGetPageStockAlertParam,
    getPageStockAlertLoading,
    stockAlertPage,
    getPageStockAlertResult,
    getPageStockAlert,
} = useGetPageStockAlert()
const {
    stepCaseStatDetailList,
    getStepCaseStatDetailListParam,
    getStepCaseStatDetailListLoading,
    getStepCaseStatDetailList
} = useGetStepCaseStatDetailList()
const {
    getPageFactoryNoticeParam,
    getPageFactoryNoticeResult,
    factoryNoticePage,
    initGetPageFactoryNoticeParam,
    getPageFactoryNoticeLoading,
    getPageFactoryNotice
} = useGetPageFactoryNotice()
onMounted(async () => {
    // getStepCaseStat()
    getFactoryEnterpriseData()
    getPageStockAlert()
    getPageFactoryNoticeParam.isPublish = 1
    await getPageFactoryNotice()
})
const setPage = inject('setPage')
const {
    factoryEnterpriseDataInfo,
    getFactoryEnterpriseDataLoading,
    getFactoryEnterpriseData
} = userGetFactoryEnterpriseData()
const stepCaseDialogVisible = ref(false)
const stepCaseDialogTitle = ref('')
const showStepCaseDialog = async val => {
    onlyLate.value = 0
    getStepCaseStatDetailListParam.stepId = val.stepId
    stepCaseDialogTitle.value = val.stepName
    await getStepCaseStatDetailList()
    showCaseStepList.value = stepCaseStatDetailList.value
    stepCaseDialogVisible.value = true
}
// 只显示已延迟
const onlyLate = ref(0)
const showCaseStepList = ref([])
const changeLate = debounce(async () => {
    if (onlyLate.value) {
        // 只显示延迟
        showCaseStepList.value = stepCaseStatDetailList.value.filter(x => !!x.whetherToPostpone)
    } else {
        // 显示全部
        showCaseStepList.value = stepCaseStatDetailList.value
    }
}, 300)

const setMenu = inject('setMenu')
const router = useRouter()
const editCase = id => {
    setMenu('caseAdd')
    router.push({
        name: 'caseAdd',
        query: {
            id: id,
            url: 'welcome'
        }
    })
}

</script>

<style scoped lang="scss">
.top-stat {
    height: 70px;
    padding-left: 20px;
    border-radius: 10px;
    color: white;

    .icon-div {
        height: 40px;
        width: 40px;
        border-radius: 20px;
    }
}

.func-icon {
    height: 50px;
    width: 50px;
}
</style>