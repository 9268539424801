// 良田高拍仪
import {reactive, ref} from "vue";
import {useStore} from 'vuex'
import moment from "moment";
import {base64ToFile} from "@/use/system/useFile";


let baseUrl = 'http://127.0.0.1:38088/'

const apiUrl = {
    videoFormat: 'device=getresolution',//获取视频格式、改变视频格式
    resolution: 'device=getresolution',//获取分辨率、改变分辨率
    open: 'video=stream&camidx=0?1',//打开视频流
    close: 'video=close',//关闭视频流
    rotate: 'video=rotate',//旋转
    scan: 'video=grabimage',//拍照
    isConnect: 'device=isconnect',//是否连接
    getType: 'device=getequipmenttype',//设备类型
    getStatus: 'video=status'//设备状态
}

const apiParam = {
    mjpgVideo: {"camidx": '0', "mode": '1'},
    yuy2Video: {"camidx": '0', "mode": '0'},
    close: {"camidx": "0"},
    scan: {
        "filepath": "base64",
        "rotate": "0",
        "cutpage": "0",
        "camidx": "0",
        "ColorMode": "0",
        "quality": "3"
    }
}

const initData = data => {
    let req = {
        method: 'POST',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        mode: 'cors'
    }
    if (data) {
        req.body = JSON.stringify(data)
    }
    return req
}

//---------------------------------------------设备-----------------------------------------
function useScanDevice() {
    const store = useStore()
    //高拍仪配置页面绑定
    const liangtianSetting = reactive({
        baseUrl: baseUrl,
        resolution: ''
    })
    // 初始化设置（获取缓存数据） 必须调用！
    const initLiangtianSetting = () => {
        const storeLiangtianSetting = store.state.liangtianSetting//缓存高拍仪配置
        if (!!storeLiangtianSetting) Object.assign(liangtianSetting, storeLiangtianSetting)
    }
// 修改高拍仪配置后修改缓存
    const changeLiangtianSetting = () => {
        store.commit('setLiangtianSetting', liangtianSetting)
    }
// 恢复默认BaseUrl
    const resetBaseUrl = () => {
        liangtianSetting.baseUrl = baseUrl
        changeLiangtianSetting()
    }
    const device = reactive({
        version: '',//设备编号
        isConnect: '',//已连接 未连接
        videoStatus: ''//已连接 已连接且运行 未连接
    })

    const view1_mode_list = ref([])              // 主摄像头视频模式
    const view1_mode_selected = ref('1')         // 主摄像头视频模式当前选项
    const view1_resolution_list = ref([])        // 主摄像头分辨率
    // 加载主摄像头视频模式
    const getModeList = () => {
        fetch(liangtianSetting.baseUrl + apiUrl.videoFormat, initData(apiParam.mjpgVideo)).then(res => res.json()).then(res => {
            if (res.data && res.data.split("|").length > 1) {
                view1_mode_list.value.push({
                    key: '1',
                    value: 'MJPG'
                })
            }
        }).catch(err => {
            err && console.log(err)
        })

        fetch(liangtianSetting.baseUrl + apiUrl.videoFormat, initData(apiParam.yuy2Video)).then(res => res.json()).then(res => {
            if (res.data && res.data.split("|").length > 1) {
                view1_mode_list.value.push({
                    key: '0',
                    value: 'YUY2'
                })
            }
        }).catch(err => {
            err && console.log(err)
        })
    }
    // 加载主摄像头分辨率
    let view1_resolution_power_data = {"camidx": "0", "mode": view1_mode_selected.value}
    const getResolutionList = () => {
        fetch(liangtianSetting.baseUrl + apiUrl.resolution, initData(view1_resolution_power_data)).then(res => res.json()).then(res => {
            if (!res.data) return
            if (res.code === '1') return
            let resolution_list = res.data.split("|");
            if (liangtianSetting.resolution && resolution_list.some(x => x === liangtianSetting.resolution)) {
                //如果包含缓存的分辨率 直接默认缓存分辨率
            } else if (resolution_list.length >= 3) {
                liangtianSetting.resolution = resolution_list[2]
                changeLiangtianSetting()
            } else if (resolution_list.length >= 2) {
                liangtianSetting.resolution = resolution_list[1]
                changeLiangtianSetting()
            } else if (!!resolution_list.length) {
                liangtianSetting.resolution = resolution_list[0]
                changeLiangtianSetting()
            }
            for (let i = 0; i < resolution_list.length; i++) {
                view1_resolution_list.value.push(resolution_list[i])
            }
        }).catch(err => {
            err && console.log(err)
        })
    }


// 切换主摄像头视频模式，重新加载主摄像头分辨率
    let view1_mode_change = (val) => {
        let data = {"camidx": '0', "mode": val}
        fetch(liangtianSetting.baseUrl + apiUrl.videoFormat, initData(data)).then(res => res.json()).then(res => {
            if (!res.data) return
            if (res.code === '1') return
            let resolution_list = res.data.split("|");
            liangtianSetting.resolution = resolution_list[0]
            changeLiangtianSetting()
            view1_resolution_list.value = []
            for (let i = 0; i < resolution_list.length; i++) {
                view1_resolution_list.value.push(resolution_list[i])
            }
        }).catch(err => {
            err && console.log(err)
        })
    }
    // 打开主摄像头
    let open_view1 = view1Id => {
        document.getElementById(view1Id).src = liangtianSetting.baseUrl + apiUrl.open
    }
// 关闭主摄像头
    let close_view1 = view1Id => {
        let data = {"camidx": "0"}
        fetch(liangtianSetting.baseUrl + apiUrl.close, initData(apiParam.close)).then(res => res.json()).then(res => {
            document.getElementById(view1Id).src = ''
        }).catch(err => {
            err && console.log(err)
        })
    }

// 旋转
    let rotate = (angle) => {
        let data = {"camidx": '0', "rotate": String(angle)}
        fetch(liangtianSetting.baseUrl + apiUrl.rotate, initData(data)).then(res => res.json()).then(res => {
            console.log("旋转" + String(angle) + "度成功")
        }).catch(err => {
            err && console.log(err)
        })
    }
    //---------------------------------------------设备-----------------------------------------
    /**
     * 获取设备的类型和状态
     */
    const getDeviceInfo = () => {
        getModeList()
        getResolutionList()
        getequipmenttype()
        get_state()
        is_con()
    }
    // 获取设备型号
    const getequipmenttype = () => {
        fetch(liangtianSetting.baseUrl + apiUrl.getType, initData(null)).then(res => res.json()).then(res => {
            if (res.data && res.data.indexOf('Failed') < 0) {
                device.version = res.data
            }
        }).catch(err => {
            err && console.log(err)
        })
    }
    // 获取设备状态
    const get_state = () => {
        fetch(liangtianSetting.baseUrl + apiUrl.getStatus, initData(null)).then(res => res.json()).then(res => {
            // console.log("提示：no:未连接；ok:已连接；run:已连接且运行")
            device.videoStatus = '未连接'
            if (!res) return
            if (res.video0 === 'run') {
                device.videoStatus = '已连接且运行'
            } else if (res.video0 === 'ok') {
                device.videoStatus = '已连接'
            }
        }).catch(err => {
            err && console.log(err)
        })
    }

    // 判断设备是否连接
    const is_con = () => {
        fetch(liangtianSetting.baseUrl + apiUrl.isConnect, initData(null)).then(res => res.json()).then(res => {
            if (res && res.data >= 1) {
                device.isConnect = '已连接'
            } else {
                device.isConnect = '未连接'
            }
        }).catch(err => {
            err && console.log(err)
        })
    }
    //---------------------------------------------拍照-----------------------------------------
    // 拍照
    const view1_scan = async () => {
        let response = await fetch(liangtianSetting.baseUrl + apiUrl.scan, initData(apiParam.scan))
        let res = await response.json()
        if (res.code === '0') {
            console.log("主头拍照成功")
            let fileName = Math.ceil(Math.random() * 1000) + moment().format('yyyyMMDDHHmmss') + '.jpg'
            let file = base64ToFile("data:image/jpg;base64," + res.photoBase64, fileName)
            return file
        }
        return null
    }
    return {
        changeLiangtianSetting,
        liangtianSetting,
        initLiangtianSetting,
        resetBaseUrl,
        device,
        view1_mode_list,
        view1_mode_selected,
        view1_resolution_list,
        view1_mode_change,
        open_view1,
        close_view1,
        rotate,
        getDeviceInfo,
        view1_scan,
    }
}


export {
    useScanDevice,
}