<template>
    <div class="view-container padding-sm">
        <el-row>
            <el-button type="primary" size="large" @click="handleSave(stepOperType.制作接收)"
                       v-loading="addCaseProductStepLoading">制作接收
            </el-button>
            <el-button type="success" size="large" @click="handleSave(stepOperType.制作完成)"
                       v-loading="addCaseProductStepLoading">制作完成
            </el-button>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-descriptions border :column="4">
                    <el-descriptions-item min-width="100px" label="扫描工号">
                        <el-input v-model.trim="caseProductStep.workerNo" ref="workerNoRef" maxlength="20"
                                  @click="clickWorkerNo"
                                  @keydown.enter="getWorkerByNo"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item min-width="100px" label="姓名">
                        {{ caseProductStep.workerName }}
                    </el-descriptions-item>
                    <el-descriptions-item min-width="100px" label="部门">{{
                            caseProductStep.deptName
                        }}
                    </el-descriptions-item>
                    <el-descriptions-item min-width="100px" label="盒号">{{ caseInfo.boxNo }}</el-descriptions-item>
                    <el-descriptions-item min-width="100px" label="扫描订单号">
                        <el-input v-model.trim="caseNo" ref="caseNoRef" maxlength="20" @click="clickCaseNo"
                                  @keydown.enter="getCaseProductsByNo"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item min-width="100px" label="客户">{{
                            caseInfo.facHospitalName
                        }}
                    </el-descriptions-item>
                    <el-descriptions-item min-width="100px" label="医生">{{
                            caseInfo.facDoctorName
                        }}
                    </el-descriptions-item>
                    <el-descriptions-item min-width="100px" label="医生备注">{{
                            caseInfo.doctorRemark
                        }}
                    </el-descriptions-item>
                    <el-descriptions-item min-width="100px" label="机器编号">
                        <el-input v-model.trim="caseProductStep.machineNo" ref="machineNoRef" maxlength="20"
                                  @click="clickMachineNo"
                                  @keydown.enter="getPropertyByMachineNo"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item min-width="100px" label="设备名称">
                        <el-select v-model="caseProductStep.propertyId">
                            <el-option v-for="item in simpleAssetList" :key="item.id" :value="item.id"
                                       :label="item.name"></el-option>
                        </el-select>
                    </el-descriptions-item>
                    <el-descriptions-item min-width="100px" label="材料名称">
                        <el-select v-model="caseProductStep.materialId" @change="changeMaterial">
                            <el-option v-for="item in simpleStockMaterialList" :key="item.id" :value="item.id"
                                       :label="item.name"></el-option>
                        </el-select>
                    </el-descriptions-item>
                    <el-descriptions-item min-width="100px" label="材料批号">
                        <el-input v-model.trim="caseProductStep.materialLotNo" ref="materialLotNoRef" maxlength="20"
                                  @click="clickMaterialLotNo"
                                  @keydown.enter="getMaterialByLotNo">
                            <template #suffix>
                                <el-button text :icon="Search" @click="showLot"></el-button>
                            </template>
                        </el-input>
                    </el-descriptions-item>
                </el-descriptions>
            </el-col>
        </el-row>
        <el-tag v-show="!!caseInfo.doctorHabit" size="large" effect="dark" type="danger">{{
                '医生习惯： ' + caseInfo.doctorHabit
            }}
        </el-tag>
        <el-tag v-show="!!caseInfo.stepNote" size="large" effect="dark" type="warning">{{
                '生产备注： ' + caseInfo.stepNote
            }}
        </el-tag>
        <div class="view-table">
            <el-table :data="caseInfo.productList" ref="caseProductPageRef" border style="min-width:1100px;"
                      v-loading="getSimpleListCaseProductStepLoading"
                      :highlight-current-row="true" @row-click="chooseRow">
                <el-table-column label="选择工序" width="450" label-class-name="text-danger">
                    <template #default="scope">
                        <el-space direction="vertical" :size="0">
                            <el-space :size="0" v-for="step in scope.row.stepList" :key="step.stepId">
                                <el-checkbox border style="width: 150px;"
                                             @change="changeSteps(scope.row)" v-model="step.isCheck"
                                             :label="step.stepId">
                                    {{ step.stepName }}
                                </el-checkbox>
                                <el-input class="w-120" v-model="step.keyParam" placeholder="关键参数"
                                          maxlength="50"></el-input>
                                <el-input class="w-120" v-model="step.keyTime" placeholder="时间"
                                          maxlength="50"></el-input>
                            </el-space>
                        </el-space>
                    </template>
                </el-table-column>
                <el-table-column label="产品名称" prop="productName" width="150"></el-table-column>
                <el-table-column label="产品编号" prop="productNo" width="100"></el-table-column>
                <el-table-column label="大类" prop="productBelongName" width="70"></el-table-column>
                <el-table-column label="种类" prop="productTypeName" width="100"></el-table-column>
                <el-table-column label="牙色" prop="color" width="90">
                    <template #default="scope">
                        <div>{{ scope.row.color }}</div>
                        <el-button type="primary" size="small" plain @click="showProductPic(scope.row)">查看图片</el-button>
                    </template>
                </el-table-column>
                <el-table-column width="200" label="牙位">
                    <template #default="scope">
                        <table-tooth
                            :key="scope.row.id"
                            :pos1="scope.row.pos1"
                            :pos2="scope.row.pos2"
                            :pos3="scope.row.pos3"
                            :pos4="scope.row.pos4"
                            :upper-jaw="scope.row.upperJaw"
                            :lower-jaw="scope.row.lowerJaw"
                            :complete="scope.row.complete"
                            :tooth-type="scope.row.toothType"
                            :pontic="scope.row.pontic"
                            :missing1="scope.row.missing1"
                            :missing2="scope.row.missing2"
                            :missing3="scope.row.missing3"
                            :missing4="scope.row.missing4"
                        />
                    </template>
                </el-table-column>
                <el-table-column label="数量" prop="num" min-width="70"></el-table-column>
            </el-table>
        </div>
        <product-step v-show="!!caseProductId" :id="caseProductId" :step-info="stepInfo"></product-step>
        <el-dialog v-model="picVisible">
            <pics-upload v-if="picVisible" disabled :files="productFiles"></pics-upload>
        </el-dialog>
        <el-dialog v-model="lotVisible" title="请选择批号" width="400">
            <el-table :data="lotList" :show-header="false" stripe border @row-click="chooseLot">
                <el-table-column label="请选择批号">
                    <template #default="scope">
                        {{ scope.row }}
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>

<script setup>
import {ref, onMounted, watch, computed, inject, onActivated, reactive} from 'vue';
import {Plus, Search, Refresh, Edit, Delete, Check, Hide, View, Setting} from '@element-plus/icons-vue';
import {useStore} from 'vuex'
import {ElMessage} from "element-plus";
import {useGetCase} from "@/use/case/useCase";
import {
    useAddCaseProductStep,
    useGetSimpleListCaseProductStep,
    useGetProductMaterialLot
} from "@/use/produce/useCaseProductStep";
import {useGetListStep} from "@/use/system/useStep";
import {throttle} from "@/util/common";
import {stepOperType} from "@/util/enum";

const {
    updateStepListStore
} = useGetListStep()
const store = useStore()
const workerNoRef = ref(null)
const caseNoRef = ref(null)
onMounted(() => {
    workerNoRef.value.focus()
    caseInfo.productList = []
    initPage()
})
const initPage = throttle(() => {
    if (!stepList.value || !stepList.value.length) {
        updateStepListStore()
    }
})
onActivated(() => {
    initPage()
})
//员工
const storeWorkerList = computed(() => store.state.workerList)
const getWorkerByNo = async () => {
    if (storeWorkerList.value.length) {
        let worker = storeWorkerList.value.find(x => x.code === caseProductStep.workerNo)
        if (worker) {
            caseProductStep.facWorkerId = worker.id
            caseProductStep.workerName = worker.name
            caseProductStep.workerNo = worker.code
            caseProductStep.deptName = worker.deptName
            caseNoRef.value.focus()
            await getWorkerCaseSteps()
        } else {
            ElMessage.error('请输入正确的工号')
            caseProductStep.facWorkerId = null
            caseProductStep.workerName = ''
            caseProductStep.workerNo = ''
            caseProductStep.deptName = ''
        }
    } else {
        ElMessage.error('系统当前没有设置员工')
    }
}
const clickWorkerNo = () => {
    if (caseProductStep.workerNo) {
        workerNoRef.value.select()
    }
}

//订单
const storeProductDetails = computed(() => store.state.productDetails)
const caseNo = ref('')
const {
    caseInfo,
    getCaseByNo,
    getCase
} = useGetCase()
const caseProductPageRef = ref(null)
const getCaseProductsByNo = async () => {
    if (!caseProductStep.facWorkerId) {
        ElMessage.error('请先扫描工号')
        workerNoRef.value.focus()
        return
    }
    if (!caseNo.value) {
        ElMessage.error('请扫描订单号')
        caseNoRef.value.focus()
        return
    }
    caseInfo.productList = []
    await getCaseByNo(caseNo.value)
    if (!caseInfo.productList || !caseInfo.productList.length) {
        ElMessage.error('请扫描正确的订单号')
    } else {
        caseInfo.productList.forEach(x => {
            // x.stepSimpleList = []
            let product = storeProductDetails.value.find(y => y.id === x.productId)
            if (product) {
                x.productBelongName = product.productBelongName
                x.productTypeName = product.productTypeName
                x.productTypeId = product.productTypeId
            }
        })
        machineNoRef.value.focus()
        //获取产品已做的流程
        await getSimpleListCaseProductStep(caseInfo.id)
        for (let item of caseProductStepSimpleList.value) {
            let caseProduct = caseInfo.productList.find(x => x.id === item.caseProductId)
            if (caseProduct) caseProduct.stepInfo = item
        }

        await getWorkerCaseSteps()
        await setDefaultProductAndStep()
    }
}
//当前选中的订单产品
const caseProductId = ref(null)
//已完成工序
const stepInfo = reactive({})
// 默认选中产品
const setDefaultProductAndStep = async () => {
    for (let product of caseInfo.productList) {
        let stepSimpleList = product.stepInfo.stepList
        //默认选中当前员工之前制作的产品
        let curWorkerSteps = stepSimpleList.filter(x => x.operType === stepOperType.制作接收 && x.workerId === caseProductStep.facWorkerId)
        if (curWorkerSteps && curWorkerSteps.length) {
            // 当前员工未完成的工序
            let unfinishSteps = curWorkerSteps.filter(x => x.isFinish === 0)
            if (unfinishSteps && unfinishSteps.length) {
                // 有未完成工序，默认当前是用来操作接收完成
                product.stepList.forEach(x => {
                    x.isCheck = unfinishSteps.some(a => a.stepId === x.stepId)
                })
            } else {
                // 没有未完成工序，默认选中所有未操作过的工序
                product.stepList.forEach(x => {
                    x.isCheck = !curWorkerSteps.some(a => a.stepId === x.stepId)
                })
            }
            await chooseRow(product)
            caseProductPageRef.value.setCurrentRow(product)
            return
        }
    }
    for (let product of caseInfo.productList) {
        //默认选中员工有可以操作工序的产品
        if (product.stepList && product.stepList.length) {
            //默认选中所有可操作工序
            product.stepList.forEach(x => x.isCheck = true)
            await chooseRow(product)
            caseProductPageRef.value.setCurrentRow(product)
            return
        }
    }
    //默认选中第一个产品
    await chooseRow(caseInfo.productList[0])
    caseProductPageRef.value.setCurrentRow(caseInfo.productList[0])
}
// 获取员工可以做的工序
const getWorkerCaseSteps = async () => {
    if (caseProductStep.facWorkerId && caseInfo.id && caseInfo.productList && caseInfo.productList.length) {
        getWorkerStepsParam.facWorkerId = caseProductStep.facWorkerId
        getWorkerStepsParam.caseId = caseInfo.id
        await getWorkerSteps()
        workerSteps.value.forEach(x => {
            x.isCheck = false
            x.keyParam = ''
            x.keyTime = ''
        })
        //产品关联员工可以操作的工序
        caseInfo.productList.forEach(x => {
            x.stepList = workerSteps.value.filter(a => a.productId === x.productId)
        })
    }
}
const clickCaseNo = () => {
    if (caseNo.value) {
        caseNoRef.value.select()
    }
}
import {useGetWorkerSteps} from "@/use/produce/useCaseProductStep";

const {
    getWorkerStepsParam,
    workerSteps,
    getWorkerStepsLoading,
    getWorkerSteps,
} = useGetWorkerSteps()
// 保存
const {
    caseProductStep,
    initCaseProductStep,
    addCaseProductStepLoading,
    addCaseProductStepResult,
    addCaseProductStep
} = useAddCaseProductStep()
const caseProductStepFormRef = ref()
// 不清空员工信息
let tmpWorker = {
    facWorkerId: '',
    workerNo: '',
    workerName: '',
    deptName: '',
}
const setFields = inject('setFields')
const handleSave = async operType => {
    caseProductStep.operType = operType
    caseProductStep.operTime = new Date()
    if (!!caseProductStep.materialId) {
        let material = simpleStockMaterialList.value.find(x => x.id === caseProductStep.materialId)
        if (!!material) caseProductStep.materialName = material.name
    }
    if (!!caseProductStep.propertyId) {
        let property = simpleAssetList.value.find(x => x.id === caseProductStep.propertyId)
        if (!!property) caseProductStep.propertyName = property.name
    }
    let caseProduct = caseInfo.productList.find(x => x.stepList.some(a => !!a.isCheck))
    if (!caseProduct) {
        ElMessage.warning('请选择产品工序')
        return
    }
    caseProductStep.caseProductId = caseProduct.id
    caseProductStep.stepList = []
    if (caseProduct.stepList.some(x => x.isCheck)) {
        caseProductStep.stepList = caseProduct.stepList.filter(x => x.isCheck).map(x => {
            return {
                stepId: x.stepId,
                keyParam: x.keyParam,
                keyTime: x.keyTime
            }
        })
    }
    setFields(tmpWorker, caseProductStep)
    await addCaseProductStep()
}
watch(addCaseProductStepResult, (newVal, oldVal) => {
    if (!!newVal) {
        caseNo.value = ''
        caseNoRef.value.focus()
        initCaseProductStep()
        setFields(caseProductStep, tmpWorker)
        // showStepSimpleList.value = []
        caseProductId.value = null
        caseInfo.productList = []
        caseInfo.facHospitalName = ''
        caseInfo.facDoctorName = ''
        caseInfo.boxNo = ''
        caseInfo.id = 0
        caseInfo.factoryRemark = ''
        caseInfo.doctorRemark = ''
    }
})
// 机器编号
import {useGetByMachineNoAsset} from "@/use/property/useAsset";

const {
    simpleAssetList,
    getAssetByMachineNo
} = useGetByMachineNoAsset()
const machineNoRef = ref()
const clickMachineNo = () => {
    if (!!caseProductStep.machineNo) {
        machineNoRef.value.select()
    }
}
const getPropertyByMachineNo = async () => {
    await getAssetByMachineNo(caseProductStep.machineNo)
    // 默认选中第一个设备
    if (simpleAssetList.value && simpleAssetList.value.length) {
        caseProductStep.propertyId = simpleAssetList.value[0].id
        caseProductStep.propertyName = simpleAssetList.value[0].name
        materialLotNoRef.value.focus()
    }
}
// 材料批号
import {useGetStockMaterialByLotNo} from "@/use/warehouse/useStockNow";
import PicPreview from "@/components/picPreview";

const {
    simpleStockMaterialList,
    getStockMaterialByLotNoLoading,
    getStockMaterialByLotNo,
} = useGetStockMaterialByLotNo()
const materialLotNoRef = ref()
const clickMaterialLotNo = () => {
    if (!!caseProductStep.materialLotNo) {
        materialLotNoRef.value.select()
    }
}
// 修改材料
const changeMaterial = () => {
    if (caseProductStep.materialId) {
        let firstItem = productMaterialLot.value.find(x => x.materialId === caseProductStep.materialId)
        if (firstItem) {
            caseProductStep.materialLotNo = firstItem.lotNo
            caseProductStep.materialName = firstItem.materialName
        }
    } else {
        caseProductStep.materialName = ''
        caseProductStep.materialLotNo = ''
    }
}
/**
 * 根据批号获取材料列表
 * @returns {Promise<void>}
 */
const getMaterialByLotNo = async () => {
    if (!caseProductStep.materialLotNo) return
    await getStockMaterialByLotNo(caseProductStep.materialLotNo)
    if (simpleStockMaterialList.value && simpleStockMaterialList.value.length) {
        caseProductStep.materialId = simpleStockMaterialList.value[0].id
        caseProductStep.materialName = simpleStockMaterialList.value[0].name
    }
}

// 选择产品
const changeSteps = async row => {
    await chooseRow(row)
    caseProductPageRef.value.setCurrentRow(row)
    //同时只能接收一个产品 所以选择工序后会清空其他产品工序
    if (row.stepList.some(x => x.isCheck)) {
        caseInfo.productList.forEach(x => {
            if (x.id !== row.id) {
                x.stepList.forEach(x => x.isCheck = false)
            }
        })
    }
}
const {
    productMaterialLot,
    getProductMaterialLotLoading,
    getProductMaterialLot
} = useGetProductMaterialLot()
const {
    caseProductStepSimpleList,
    getSimpleListCaseProductStepLoading,
    getSimpleListCaseProductStep
} = useGetSimpleListCaseProductStep()
//当前选择材料的批号列表
const materialLotList = computed(() => productMaterialLot.value.filter(x => x.materialId === caseProductStep.materialId))
const chooseRow = async row => {
    updateMachine(row)
    caseProductId.value = row.id
    Object.assign(stepInfo, row.stepInfo)
    //选择产品后获取材料批号信息
    await getProductMaterialLot(row.productId)
    if (productMaterialLot.value.length) {
        if (simpleStockMaterialList.value.length) {
            //如果已有材料和批号
            if (productMaterialLot.value.some(x => x.materialId === caseProductStep.materialId)) {
                //已有主材正确 不修改
                return
            }
        }
        // 没有材料和批号  或已选材料不是主材
        for (let item of productMaterialLot.value) {
            if (simpleStockMaterialList.value.some(x => x.id === item.materialId)) {
                // 已有 去重
            } else {
                simpleStockMaterialList.value.push({
                    id: item.materialId,
                    name: item.materialName
                })
            }
        }
        // 默认第一个材料 默认材料第一个批号
        caseProductStep.materialId = simpleStockMaterialList.value[0].id
        caseProductStep.materialName = simpleStockMaterialList.value[0].name
        let firstItem = productMaterialLot.value.find(x => x.materialId === caseProductStep.materialId)
        caseProductStep.materialLotNo = firstItem.lotNo
    } else {
        // 没有设置主材
        simpleStockMaterialList.value = []
        caseProductStep.materialId = null
        caseProductStep.materialName = ''
        caseProductStep.materialLotNo = ''
    }
}

// 缓存工序
const stepList = computed(() => store.state.stepList)
const updateMachine = row => {
    //可选设备下拉列表
    simpleAssetList.value = []
    //当前产品已选中工序id
    let checkedStepIds = []
    //根据之前同样工序的设备更改
    if (row.stepList.some(x => !!x.isCheck)) {
        //选中工序id
        checkedStepIds = row.stepList.filter(x => !!x.isCheck).map(x => x.stepId)
        //操作过的工序
        let doneStepList = row.stepInfo.stepList ? row.stepInfo.stepList : []
        //操作过的工序且选中 且有机器号
        let historySteps = doneStepList.filter(x => checkedStepIds.indexOf(x.stepId) >= 0 && !!x.machineNo)
        if (historySteps && historySteps.length) {
            for (let historyStep of historySteps) {
                simpleAssetList.value.push({
                    id: historyStep.propertyId,
                    name: historyStep.propertyName,
                    extra1: historyStep.machineNo
                })
            }
            caseProductStep.machineNo = historySteps[0].machineNo
            caseProductStep.propertyId = historySteps[0].propertyId
            caseProductStep.propertyName = historySteps[0].propertyName
            if (!!historySteps[0].materialId) {
                caseProductStep.materialId = historySteps[0].materialId
                caseProductStep.materialLotNo = historySteps[0].materialLotNo
                caseProductStep.materialName = historySteps[0].materialName
            }
        }
    }

    //根据工序设置页面绑定的设备更改
    if (!!caseProductStep.machineNo || !!caseProductStep.propertyId) return
    if (!row.stepList.some(x => !!x.isCheck)) return
    let curStepList = stepList.value.filter(x => checkedStepIds.indexOf(x.id) >= 0 && x.assetList && x.assetList.length)
    if (!curStepList || !curStepList.length) return
    for (let step of curStepList) {
        for (let asset of step.assetList) {
            if (!simpleAssetList.value.some(x => x.id === asset.id)) {
                simpleAssetList.value.push({
                    id: asset.id,
                    name: asset.name,
                    code: asset.propertyNo,
                    extra1: asset.machineNo
                })
            }
        }
    }
    caseProductStep.machineNo = simpleAssetList.value[0].extra1
    caseProductStep.propertyId = simpleAssetList.value[0].id
    caseProductStep.propertyName = simpleAssetList.value[0].name
}
// 牙色图片
const picVisible = ref(false)
const productFiles = ref([])
const showProductPic = row => {
    picVisible.value = true
    productFiles.value = row.fileList
}
// 选择批号
const lotVisible = ref(false)
const lotList = ref([])
const showLot = () => {
    if (!caseProductStep.materialId) {
        ElMessage.error('请选择主材')
        return
    }
    if (!materialLotList.value.length) {
        ElMessage.error('当前主材没有可选批号')
        return
    }
    lotList.value = []
    for (let item of materialLotList.value) {
        if (lotList.value.some(x => x === item.lotNo)) {
            // 去重
        } else {
            lotList.value.push(item.lotNo)
        }
    }
    lotVisible.value = true
}
const chooseLot = param => {
    caseProductStep.materialLotNo = param
    lotVisible.value = false
}
</script>

<style scoped>
.el-descriptions__label {
    width: 100px;
}
</style>