import {computed, ref} from "vue";
import {useStore} from "vuex";
import {ElMessage} from "element-plus";

function usePrintList() {
    const printListLoading = ref(false)
    const failList = ref([])
    const successList = ref([])
    const printResList = ref([])
    const printList = async (casePrintTemplate, hiprint) => {
        if (casePrintTemplate.isSkip && casePrintTemplate.printerName) {
            await printNoScanList(hiprint)
        } else {
            printScanList()
        }
    }
    const printScanList = () => {
        //预览打印1个数据
        if (!printResList.value.length) {
            ElMessage.error('没有需要打印的数据')
            return
        }
        // if (printResList.value.length > 1) {
        //     ElMessage.error('预览打印只能同时操作1条数据')
        //     return
        // }
        let printRes = printResList.value[0]
        let printDataList = printResList.value.map(x => x.data)
        printRes.template.print(printDataList, {
            leftOffset: 0,
            topOffset: 0
        })
    }
    const printNoScanList = async hiprint => {
        //跳过预览打印多个数据
        //官方建议队列打印
        if (!printResList.value.length) {
            ElMessage.error('没有需要打印的数据')
            return
        }
        if (!hiprint.hiwebSocket.opened) {
            ElMessage.error('未连接到打印服务，请检查本机打印服务是否启动并刷新页面')
            return
        }
        printListLoading.value = true
        successList.value = []
        failList.value = []
        for (let printRes of printResList.value) {
            await printOne(printRes, hiprint)
        }
        printListLoading.value = false
        printResList.value = []
        if (successList.value.length) {
            ElMessage.success('成功调用打印机次数：' + successList.value.length)
        }
        if (failList.value.length) {
            ElMessage.error('失败调用打印机次数：' + failList.value.length)
        }
    }
    const printOne = (printRes) => {
        return new Promise(resolve => {
            let option = {
                leftOffset: 0,
                topOffset: 0
            }
            if (printRes.options && printRes.options.printer) option.printer = printRes.options.printer
            if (printRes.options && printRes.options.landscape) option.landscape = printRes.options.landscape
            printRes.template.print2(printRes.data, option)
            printRes.template.on('printSuccess', function (data) {
                successList.value.push(printRes)
                resolve(true)
            })
            printRes.template.on('printError', function (err) {
                failList.value.push(printRes)
                err && console.log(err)
                resolve(true)
            })
        })
    }

    return {
        printResList,
        printList,
        printNoScanList,
        failList,
        successList,
        printListLoading,
        printScanList
    }
}

function usePrintSingle() {
    const printSingleLoading = ref(false)

    /**
     * 打印单个单据
     * @param casePrintTemplate
     * @param printRes
     * @param hiprint
     */
    function printSingle(casePrintTemplate, printRes, hiprint) {
        printSingleLoading.value = true
        if (casePrintTemplate.isSkip && casePrintTemplate.printerName) {
            if (!hiprint.hiwebSocket.opened) {
                printSingleLoading.value = false
                ElMessage.error('未连接到打印服务，请检查本机打印服务是否启动并刷新页面')
                return
            }
            // printRes.template.rotatePaper()
            // return;
            let option = {
                printer: casePrintTemplate.printerName,
                leftOffset: 0,
                topOffset: 0
            }
            if (casePrintTemplate.orient === 2) {
                option.landscape = true
            }
            printRes.template.print2(printRes.data, option)
            printRes.template.on('printSuccess', function (data) {
                let message = casePrintTemplate.name + '成功调用打印机'
                console.log(message)
                ElMessage.success(message)
                printSingleLoading.value = false
            })
            printRes.template.on('printError', function (err) {
                ElMessage.error(casePrintTemplate.name + '打印失败')
                err && console.log(err)
                printSingleLoading.value = false
            })

        } else {
            printRes.template.print(printRes.data, {
                printer: casePrintTemplate.printerName,
                leftOffset: 0,
                topOffset: 0
            })

            printSingleLoading.value = false
        }
    }

    return {
        printSingle,
        printSingleLoading
    }
}

function useGetPrinterList() {
    const store = useStore()
    const storeCasePrinterList = computed(() => store.state.casePrinterList)
    const printerList = ref([])
    let connectTimer = null
    /**
     * 连接并获取打印机列表
     * @param hiprint
     */
    const start = hiprint => {
        // 判断是否已成功连接
        if (hiprint.hiwebSocket.opened) {
            // 注意：连接是异步的
            // 已连接
            setPrinterList()
        } else {
            startConnect()
        }
    }
    const startConnect = () => {
        connectTimer = setTimeout(() => {
            if (!hiprint.hiwebSocket.opened) {
                hiprint.hiwebSocket.reconnect()
                startConnect()
            } else {
                setPrinterList()
                if (connectTimer) {
                    clearTimeout(connectTimer)
                    connectTimer = null
                }
            }
        }, 1000 * 5)
    }

    const setPrinterList = () => {
        let res = hiprint.hiwebSocket.getPrinterList();
        printerList.value = []
        if (res && res.length) {
            printerList.value = res.filter(x => x.name.indexOf('OneNote ') < 0 && x.name !== 'Fax' && x.name !== 'Microsoft XPS Document Writer')
        }
    }
    /**
     * 根据缓存设置模板默认打印机
     * @param template
     */
    const setTemplatePrinter = template => {
        if (storeCasePrinterList.value && storeCasePrinterList.value.length) {
            let casePrinter = storeCasePrinterList.value.find(x => x.id === template.id)
            template.printerName = !!casePrinter ? casePrinter.printerName : ''
            template.isSkip = casePrinter && casePrinter.isSkip ? casePrinter.isSkip : 0
            template.orient = casePrinter && casePrinter.orient ? casePrinter.orient : 1
        } else {
            template.printerName = ''
            template.isSkip = 0
            template.orient = 1
        }
    }
    /**
     * 保存模板默认打印机到缓存
     * @param template
     */
    const saveTemplatePrinter = template => {
        if (storeCasePrinterList.value && storeCasePrinterList.value.length) {
            let casePrinter = storeCasePrinterList.value.find(x => x.id === template.id)
            if (casePrinter) {
                casePrinter.printerName = template.printerName
                casePrinter.isSkip = template.isSkip ? 1 : 0
                casePrinter.orient = template.orient ? template.orient : 1
                store.commit('setCasePrinterList', storeCasePrinterList.value)
            } else {
                let tmp = [{
                    id: template.id,
                    isSkip: template.isSkip ? 1 : 0,
                    orient: template.orient ? template.orient : 1,
                    printerName: template.printerName
                }].concat(storeCasePrinterList.value)
                store.commit('setCasePrinterList', tmp)
            }
        } else {
            store.commit('setCasePrinterList', [{
                id: template.id,
                isSkip: template.isSkip ? 1 : 0,
                orient: template.orient ? template.orient : 1,
                printerName: template.printerName
            }])
        }
    }
    return {
        printerList,
        start,
        setTemplatePrinter,
        saveTemplatePrinter,
    }
}

export {
    useGetPrinterList,
    usePrintSingle,
    usePrintList,
}