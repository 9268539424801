import {reactive, ref} from 'vue'
import {
    dataSave,
    dataSort,
    dataTypeGetList,
    dataValueDelete,
    dataValueGetList,
    dataValueSave,
    dataValueSaveList,
    dataGetList,
    dataDelete, dataValueGetAllList,
} from "@/api/system";
import {ElMessage} from "element-plus";
import {useStore} from 'vuex'

function useGetListData() {
    const dataList = ref([])
    const getDataListLoading = ref(false)
    const getDataList = async () => {
        if (getDataListLoading.value) return
        getDataListLoading.value = true
        dataList.value = await dataGetList()
        getDataListLoading.value = false
    }
    return {
        dataList,
        getDataListLoading,
        getDataList
    }
}

function useSaveData() {
    const data = reactive({
        id: 0,
        nameCn: '',
        nameEn: '',
        dataTypeId: 0,
        orderId: 0
    })
    const dataFormRef = ref()
    const dataSaveRules = reactive({
        nameCn: [
            {required: true, message: '请输入中文名称', trigger: 'blur'},
        ],
        nameEn: [
            {required: true, message: '请输入英文名称', trigger: 'blur'},
        ],
    })
    const initData = () => {
        data.id = 0
        data.nameEn = ''
        data.nameCn = ''
        data.dataTypeId = 0
        data.orderId = 0
    }
    const saveDataLoading = ref(false)
    const saveDataResult = ref(false)
    const saveData = async () => {
        saveDataResult.value = false
        if (saveDataLoading.value) return
        await dataFormRef.value.validate(async (valid, fields) => {
            if (!valid) {
                return
            }
            saveDataLoading.value = true
            saveDataResult.value = await dataSave(data)
            saveDataLoading.value = false
        })
    }
    return {
        data,
        initData,
        dataFormRef,
        dataSaveRules,
        saveDataLoading,
        saveDataResult,
        saveData
    }
}

function useSortData() {
    const sortDataLoading = ref(false)
    const sortDataResult = ref(false)
    const sortData = async param => {
        sortDataResult.value = false
        if (sortDataLoading.value) return
        sortDataLoading.value = true
        sortDataResult.value = await dataSort(param)
        sortDataLoading.value = false
    }
    return {
        sortDataLoading,
        sortDataResult,
        sortData
    }
}

function useDeleteData() {
    const deleteDataLoading = ref(false)
    const deleteDataResult = ref(false)
    const deleteData = async id => {
        deleteDataResult.value = false
        if (deleteDataLoading.value) return
        deleteDataLoading.value = true
        let param = {
            id: id
        }
        deleteDataResult.value = await dataDelete(param)
        deleteDataLoading.value = false
    }
    return {
        deleteDataLoading,
        deleteDataResult,
        deleteData
    }
}

function useSaveDataValue() {
    const dataValue = reactive({
        id: 0,
        dataValue: '',
        dataId: 0,
        orderId: 0,
    })
    const initDataValue = () => {
        dataValue.id = 0
        dataValue.dataValue = ''
        dataValue.dataId = 0
        dataValue.orderId = 0
    }
    const dataValueFormRef = ref()
    const dataValueAddRules = reactive({
        dataValue: [
            {required: true, message: '请输入值', trigger: 'blur'},
        ]
    })
    const saveDataValueLoading = ref(false)
    const saveDataValueResult = ref(false)
    const saveDataValue = async () => {
        saveDataValueResult.value = false
        if (saveDataValueLoading.value) return
        await dataValueFormRef.value.validate(async (valid, fields) => {
            if (!valid) {
                return
            }
            saveDataValueLoading.value = true
            saveDataValueResult.value = await dataValueSave(dataValue)
            saveDataValueLoading.value = false
        })
    }
    return {
        dataValue,
        dataValueFormRef,
        initDataValue,
        dataValueAddRules,
        saveDataValueLoading,
        saveDataValueResult,
        saveDataValue
    }
}

function userSaveListDataValue() {
    const saveListDataValueLoading = ref(false)
    const saveListDataValueResult = ref(false)
    const saveListDataValue = async param => {
        saveListDataValueResult.value = false
        if (saveListDataValueLoading.value) return
        saveListDataValueLoading.value = true
        saveListDataValueResult.value = await dataValueSaveList(param)
        saveListDataValueLoading.value = false
    }
    return {
        saveListDataValueLoading,
        saveListDataValueResult,
        saveListDataValue
    }
}

function useModDataValue() {
    const modDataValueLoading = ref(false)
    const modDataValueResult = ref(false)
    const modDataValue = async dataValue => {
        if (!dataValue) {
            ElMessage.error("请输入数据值")
            return
        }
        if (!dataValue.dataValue) {
            ElMessage.error("请输入数据值")
            return
        }
        modDataValueResult.value = false
        if (modDataValueLoading.value) return
        modDataValueLoading.value = true
        modDataValueResult.value = await dataValueSave(dataValue)
        modDataValueLoading.value = false
    }
    return {
        modDataValueLoading, modDataValueResult, modDataValue
    }
}

function useGetDataValueList() {
    const dataValueList = ref([])
    const getListDataValueLoading = ref(false)
    const getListDataValue = async dataId => {
        if (getListDataValueLoading.value) return
        getListDataValueLoading.value = true
        let data = await dataValueGetList({id: dataId})
        if (data) {
            dataValueList.value = data
        }
        getListDataValueLoading.value = false
    }
    return {
        dataValueList,
        getListDataValue,
        getListDataValueLoading
    }
}

function useDeleteDataValue() {
    const deleteDataValueLoading = ref(false)
    const deleteDataValueResult = ref(false)
    const deleteDataValue = async id => {
        deleteDataValueResult.value = false
        if (deleteDataValueLoading.value) return
        deleteDataValueLoading.value = true
        let param = {
            id: id
        }
        deleteDataValueResult.value = await dataValueDelete(param)
        deleteDataValueLoading.value = false
    }
    return {
        deleteDataValueLoading,
        deleteDataValueResult,
        deleteDataValue
    }
}

//缓存数据字典
function useGetAllDataValueList() {
    const store = useStore()
    const allDataValueList = ref([])
    const getAllDataValueLoading = ref(false)
    const getAllDataValueList = async () => {
        if (getAllDataValueLoading.value) return
        getAllDataValueLoading.value = true
        let data = await dataValueGetAllList({})
        if (data) {
            allDataValueList.value = data
            store.commit('setDataValueList', data)
        }
        getAllDataValueLoading.value = false
    }
    return {
        allDataValueList,
        getAllDataValueLoading,
        getAllDataValueList,
    }
}

//获取缓存的数据字典
function useGetDataValueListFromStore() {
    const store = useStore()
    const getDataValueListFromStore = nameEn => {
        let dataValueList = store.state.dataValueList
        let data = dataValueList.find(x => x.nameEn === nameEn)
        if (data) {
            return data.dataValueList
        } else {
            return []
        }
    }
    const getDataIdFromStore = nameEn => {
        let dataValueList = store.state.dataValueList
        let data = dataValueList.find(x => x.nameEn === nameEn)
        if (data) {
            return data.id
        } else {
            return 0
        }
    }
    return {
        getDataValueListFromStore,
        getDataIdFromStore
    }
}

export {
    useGetListData,
    useSaveData,
    useSortData,
    useDeleteData,
    useSaveDataValue,
    useGetDataValueList,
    useDeleteDataValue,
    useModDataValue,
    useGetAllDataValueList,
    useGetDataValueListFromStore,
    userSaveListDataValue,
}