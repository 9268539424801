import {reactive, ref} from 'vue'
import {
    factorySave,
    factoryAddRelate,
    factoryDeleteRelate,
    factoryGetRelateList,
    factoryGetSimpleList,
    factoryEnterpriseData
} from "@/api/system";
import { auditAllowSave } from '@/util/enum';

function useSaveFactory() {
    const factory = reactive({
        id: 0,
        factoryName: '',
        factoryShort: '',
        factoryNo: '',
        contactPerson: '',
        contactPhoneNo: '',
        province: '',
        city: '',
        area: '',
        address: '',
        website: '',
        factoryGroupId: null
    })
    const emptyFactory = Object.assign({}, factory)
    const initFactory = () => {
        Object.assign(factory, emptyFactory)
    }
    const factoryFormRef = ref()
    const factorySaveRules = reactive({
        factoryName: [
            {required: true, message: '请输入工厂名称', trigger: 'blur'},
        ],
        factoryShort: [
            {required: true, message: '请输入工厂简称', trigger: 'blur'},
        ],
        contactPerson: [
            {required: true, message: '请输入联系人', trigger: 'blur'},
        ],
        contactPhoneNo: [
            {required: true, message: '请输入联系电话', trigger: 'blur'},
        ],
    })
    const saveFactoryLoading = ref(false)
    const saveFactoryResult = ref(false)
    const saveFactory = async () => {
        saveFactoryResult.value = false
        if (saveFactoryLoading.value) return
        await factoryFormRef.value.validate(async (valid, fields) => {
            if (!valid) return
            saveFactoryLoading.value = true
            saveFactoryResult.value = await factorySave(factory)
            saveFactoryLoading.value = false
        })
    }
    return {
        factory,
        initFactory,
        factoryFormRef,
        factorySaveRules,
        saveFactoryLoading,
        saveFactoryResult,
        saveFactory
    }
}

function useAddRelateFactory() {
    const addRelateFactoryParam = reactive({
        relateFactoryId: null,
        relateType: null
    })
    const addRelateFactoryLoading = ref(false)
    const addRelateFactoryResult = ref(false)
    const addRelateFactory = async () => {
        if (addRelateFactoryLoading.value) return
        addRelateFactoryResult.value = false
        addRelateFactoryLoading.value = true
        addRelateFactoryResult.value = await factoryAddRelate(addRelateFactoryParam)
        addRelateFactoryLoading.value = false
    }
    return {
        addRelateFactoryParam,
        addRelateFactoryLoading,
        addRelateFactoryResult,
        addRelateFactory
    }
}

function useDeleteRelateFactory() {
    const deleteRelateFactoryLoading = ref(false)
    const deleteRelateFactoryResult = ref(false)
    const deleteRelateFactory = async id => {
        if (deleteRelateFactoryLoading.value) return
        deleteRelateFactoryResult.value = false
        deleteRelateFactoryLoading.value = true
        deleteRelateFactoryResult.value = await factoryDeleteRelate({id: id})
        deleteRelateFactoryLoading.value = false
    }
    return {
        deleteRelateFactoryLoading,
        deleteRelateFactoryResult,
        deleteRelateFactory
    }
}

function useGetRelateFactoryList() {
    const relateFactoryList = ref([])
    const getRelateFactoryListLoading = ref(false)
    const getRelateFactoryList = async () => {
        if (getRelateFactoryListLoading.value) return
        getRelateFactoryListLoading.value = true
        let res = await factoryGetRelateList()
        if (res) relateFactoryList.value = res
        getRelateFactoryListLoading.value = false
    }
    return {
        relateFactoryList,
        getRelateFactoryListLoading,
        getRelateFactoryList
    }
}

function useGetFactoryList() {
    const factoryList = ref([])
    const getFactoryListLoading = ref(false)
    const getFactoryList = async () => {
        if (getFactoryListLoading.value) return
        getFactoryListLoading.value = true
        let res = await factoryGetSimpleList()
        if (res) factoryList.value = res
        getFactoryListLoading.value = false
    }
    return {
        factoryList,
        getFactoryListLoading,
        getFactoryList
    }
}

/**
 * 获取企业信息
 * @returns 
 */
function userGetFactoryEnterpriseData() {
    const factoryEnterpriseDataInfo = reactive({
        numberOfHospitals: 0,
        numberOfProducts: 0,
        quantityOfMaterial: 0,
        numberOfDealers: 0,
        numberOfEmployees: 0
    })
    const getFactoryEnterpriseDataLoading = ref(false)
    const getFactoryEnterpriseData = async () => {
        if (getFactoryEnterpriseDataLoading.value) return
        getFactoryEnterpriseDataLoading.value = true
        let res = await factoryEnterpriseData()
        if (res) {
            factoryEnterpriseDataInfo.numberOfHospitals = res.numberOfHospitals
            factoryEnterpriseDataInfo.numberOfProducts = res.numberOfProducts
            factoryEnterpriseDataInfo.quantityOfMaterial = res.quantityOfMaterial
            factoryEnterpriseDataInfo.numberOfDealers = res.numberOfDealers
            factoryEnterpriseDataInfo.numberOfEmployees = res.numberOfEmployees
        }
        getFactoryEnterpriseDataLoading.value = false
    }
    return {
        factoryEnterpriseDataInfo,
        getFactoryEnterpriseDataLoading,
        getFactoryEnterpriseData
    }
}

export {
    useSaveFactory,
    useGetFactoryList,
    useGetRelateFactoryList,
    useAddRelateFactory,
    useDeleteRelateFactory,
    userGetFactoryEnterpriseData
}