import {reactive, ref, inject} from 'vue'
import {
    facDeptDelete,
    facDeptGetList,
    facDeptSave,
    facDeptSort,
    facDeptGetStepList,
    facDeptSaveStepList
} from "@/api/workerMgt";

//保存工厂部门 oycm 2022/7/18
function useSaveFacDept() {
    const facDept = reactive({
        id: 0,
        deptName: '',
        deptNo: '',
        parentId: 0,
        deptLevel: 0,
        orderId: 0,
    })
    const initFields = inject('initFields')
    const initFacDept = () => {
        initFields(facDept)
    }
    const facDeptFormRef = ref()
    const facDeptSaveRules = reactive({
        deptName: [
            {required: true, message: '请输入名称', trigger: 'blur'},
        ],
    })
    const saveFacDeptLoading = ref(false)
    const saveFacDeptResult = ref(false)
    const saveFacDept = async () => {
        saveFacDeptResult.value = false
        if (saveFacDeptLoading.value) return
        saveFacDeptLoading.value = true
        await facDeptFormRef.value.validate(async (valid, fields) => {
            if (!valid) {
                saveFacDeptLoading.value = false
                return
            }
            saveFacDeptResult.value = await facDeptSave(facDept)
            saveFacDeptLoading.value = false
        })
    }
    return {
        facDept,
        initFacDept,
        facDeptFormRef,
        facDeptSaveRules,
        saveFacDeptLoading,
        saveFacDeptResult,
        saveFacDept,
    }
}

//删除工厂部门 oycm 2022/7/18
function useDeleteFacDept() {
    const deleteFacDeptLoading = ref(false)
    const deleteFacDeptResult = ref(false)
    const deleteFacDept = async id => {
        deleteFacDeptResult.value = false
        if (deleteFacDeptLoading.value) return
        deleteFacDeptLoading.value = true
        deleteFacDeptResult.value = await facDeptDelete({id: id})
        deleteFacDeptLoading.value = false
    }
    return {
        deleteFacDeptLoading,
        deleteFacDeptResult,
        deleteFacDept,
    }
}

//列表工厂部门 oycm 2022/7/18
function useGetListFacDept() {
    const getListFacDeptLoading = ref(false)
    const facDeptList = ref([])
    const getListFacDept = async () => {
        if (getListFacDeptLoading.value) return
        getListFacDeptLoading.value = true
        let data = await facDeptGetList({})
        if (data) {
            facDeptList.value = data
        }
        getListFacDeptLoading.value = false
    }
    return {
        getListFacDeptLoading,
        facDeptList,
        getListFacDept,
    }
}

//获取工厂部门工序 oycm 2022/8/1
function useGetStepListFacDept() {
    const getStepListFacDeptLoading = ref(false)
    const facDeptStepList = ref([])
    const getStepListFacDept = async facDeptId => {
        if (getStepListFacDeptLoading.value) return
        getStepListFacDeptLoading.value = true
        let data = await facDeptGetStepList({id:facDeptId})
        if (data) {
            facDeptStepList.value = data
        }
        getStepListFacDeptLoading.value = false
    }
    return {
        getStepListFacDeptLoading,
        facDeptStepList,
        getStepListFacDept,
    }
}

// 保存部门关联工序 oycm 2022/8/1
function useSaveStepListFacDept() {
    const saveStepListFacDeptLoading = ref(false)
    const saveStepListFacDeptResult = ref(false)
    const saveStepListFacDept = async param => {
        if(saveStepListFacDeptLoading.value) return
        saveStepListFacDeptLoading.value=true
        saveStepListFacDeptResult.value=false
        saveStepListFacDeptResult.value=await  facDeptSaveStepList(param)
        saveStepListFacDeptLoading.value=false
    }
    return {
        saveStepListFacDeptLoading,
        saveStepListFacDeptResult,
        saveStepListFacDept,
    }
}

//部门排序
function useSortFacDept() {
    const sortFacDeptLoading = ref(false)
    const sortFacDeptResult = ref(false)
    const sortFacDept = async param => {
        sortFacDeptResult.value = false
        if (sortFacDeptLoading.value) return
        sortFacDeptLoading.value = true
        sortFacDeptResult.value = await facDeptSort(param)
        sortFacDeptLoading.value = false
    }
    return {
        sortFacDeptLoading,
        sortFacDeptResult,
        sortFacDept
    }
}

export {
    useSaveFacDept,
    useDeleteFacDept,
    useGetListFacDept,
    useSortFacDept,
    useGetStepListFacDept,
    useSaveStepListFacDept,
}