import http from "@/util/axios";
// 产品种类
export const materialTypeSave = data => http.factoryPost('materialType/save', data)
export const materialTypeDelete = data => http.factoryPost('materialType/delete', data)
export const materialTypeGetList = data => http.factoryGet('materialType/get_list', data)
export const materialTypeSort = data => http.factoryPost('materialType/sort', data)

// 厂家
export const manufacturerSave = data => http.factoryPost('manufacturer/save', data)
export const manufacturerDelete = data => http.factoryPost('manufacturer/delete', data)
export const manufacturerGetList = data => http.factoryGet('manufacturer/get_list', data)
// 供应商
export const facSupplySave = data => http.factoryPost('facSupply/save', data)
export const facSupplyDelete = data => http.factoryPost('facSupply/delete', data)
export const facSupplyGetList = data => http.factoryGet('facSupply/get_simple_list', data)
export const facSupplyGet = data => http.factoryGet('facSupply/get', data)
export const facSupplyGetPage = data => http.factoryGet('facSupply/get_page', data)
export const facSupplyUpdateValid = data => http.factoryPost('facSupply/update_valid', data)
export const facSupplyBatchInvalid = data => http.factoryPost('facSupply/batch_invalid', data)
export const supplyExportExcel = '/api/factory/purchaseExport/excel_supply'
// 材料
export const materialSave = data => http.factoryPost('material/save', data)
export const materialDelete = data => http.factoryPost('material/delete', data)
export const materialGetList = data => http.factoryGet('material/get_simple_list', data)
export const materialGet = data => http.factoryGet('material/get', data)
export const materialGetPage = data => http.factoryGet('material/get_page', data)
export const materialUpdateValid = data => http.factoryPost('material/update_valid', data)
export const materialBatchInvalid = data => http.factoryPost('material/batch_invalid', data)
export const materialBatchUpdateVisible = data => http.factoryPost('material/batch_update_visible', data)
export const materialBatchUpdateNeedHide = data => http.factoryPost('material/batch_update_need_hide', data)
export const materialSupplySave = data => http.factoryPost('material/save_supply', data)
export const materialSupplyDelete = data => http.factoryPost('material/delete_supply', data)
export const materialSupplyGetList = data => http.factoryGet('material/get_supply_list', data)
export const materialSupplyGetBySupply = data => http.factoryGet('material/get_by_supply', data)
export const materialGetHiddenList = data => http.factoryGet('material/get_hidden_list', data)
export const materialGetNeedHideList = data => http.factoryGet('material/get_need_hide_list', data)
export const materialTraceGetPage = data => http.factoryGet('material/get_material_trace_list', data)
export const materialTraceStepExportExcel = '/api/factory/purchaseExport/excel_material_trace_list'
export const materialExportExcel = '/api/factory/purchaseExport/excel_material_list'
// 供应商凭证
export const facSupplyLicenseSave = data => http.factoryPost('facSupplyLicense/save', data)
export const facSupplyLicenseDelete = data => http.factoryPost('facSupplyLicense/delete', data)
export const facSupplyLicenseGetList = data => http.factoryGet('facSupplyLicense/get_list', data)
export const facSupplyLicenseGetPage = data => http.factoryGet('facSupplyLicense/get_page', data)
export const facSupplyLicenseExportExcel = '/api/factory/purchaseExport/excel_supply_license'
// 采购单
export const purchaseSave = data => http.factoryPost('purchase/save', data)
export const purchaseValid = data => http.factoryPost('purchase/valid', data)
export const purchaseGetPage = data => http.factoryGet('purchase/get_page', data)
export const purchaseGet = data => http.factoryGet('purchase/get', data)
export const purchaseGetLogList = data => http.factoryGet('purchase/get_log_list', data)
export const purchaseMaterialGetPage = data => http.factoryGet('purchase/get_material_page', data)

export const purchaseSingleExportExcel = '/api/factory/purchaseExport/excel_purchase_single'
export const purchaseExportExcel = '/api/factory/purchaseExport/excel'
export const purchaseMaterialExportExcel = '/api/factory/purchaseExport/excel_material'
// 采购申请
export const purchaseApplySave = data => http.factoryPost('purchaseApply/save', data)
export const purchaseApplyValid = data => http.factoryPost('purchaseApply/valid', data)
export const purchaseApplyGetPage = data => http.factoryGet('purchaseApply/get_page', data)
export const purchaseApplyGet = data => http.factoryGet('purchaseApply/get', data)
export const purchaseApplyGetLogList = data => http.factoryGet('purchaseApply/get_log_list', data)
export const purchaseGetStat = data => http.factoryGet('purchaseApply/get_stat', data)
// 采购付款信息
export const purchasePayGetPage = data => http.factoryGet('purchase/get_pay_page', data)
export const purchasePayExcel = '/api/factory/purchaseExport/excel_pay'
export const purchasePaySave = data => http.factoryPost('purchase/save_pay', data)
//材料供应商
export const materialSupplyGetPage = data => http.factoryGet('material/get_page_supply', data)
export const materialSupplyExportExcel = '/api/factory/purchaseExport/excel_materialSupply_list'
export const materialSupplyBatchInvalid = data => http.factoryPost('materialSupply/batch_invalid_supply', data)