<template>
    <el-container style="padding: 0;margin: 0;height: 100%;font-size: 14px;width: 100%;">
        <el-main class="app-main">
            <router-view/>
        </el-main>
        <el-footer class="menu-footer">
            此软件由存知网络科技有限公司提供
        </el-footer>
    </el-container>
</template>

<script>
</script>
<style>
.menu-header {
    height: auto !important;
    padding: 10px 6px!important;
    text-align: center;
    color: white;
    -webkit-background-image: linear-gradient(to left,#c7d2e4,#6b93c6);
    -o-background-image: linear-gradient(to left,#c7d2e4,#6b93c6);
    -moz-background-image: linear-gradient(to left,#c7d2e4,#6b93c6);
    background-image: linear-gradient(to left,#c7d2e4,#6b93c6); /* 标准的语法（必须放在最后） */
}

.app-main {
    padding: 0!important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
.page-main {
    padding: 0 20px!important;
    display: flex!important;;
    flex-direction: column;
    justify-content: center;
}
.menu-footer {
    height: auto !important;
    width: 100%;
    padding: 10px 20px 10px!important;
    text-align: center;
    color: white;
    background: url("../../assets/app/mobileBg.jpg") center no-repeat;
}
</style>
