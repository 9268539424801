import {reactive, ref, inject, toRaw} from 'vue'
import moment from 'moment'
import {
    factorySettingSave, factorySettingGet, factorySettingGetStore,
    factorySaveSwitch
} from '@/api/system'
import {useStore} from 'vuex'

//保存工厂设置 oycm 2022/7/22
function useSaveFactorySetting() {
    const factorySetting = reactive({
        id: 0,
        factoryName: '',
        factoryId: 0,
        fullName: '',
        servicePhoneNo: '',
        disinfectMinute: 0,
        produceNo: '',
        province: '',
        city: '',
        area: '',
        address: '',
        licenseNo: '',
        licenseEndDate: '',
        licensePermanent: '',
        licenseFileId: 0,
        licenseFile: null,
        docDate: '',
        docNo: '',
        docFileId: 0,
        docFile: null,
        docExpiryDate: 0,
        recordNo: '',
        recordDate: '',
        recordFileId: 0,
        recordFile: null,
        contactPerson: '',
        contactMobile: '',
        contactLandline: '',
        contactEmail: '',
        legalPerson: '',
        qualityPerson: '',
        owner: '',
        businessScope: '',
        businessPattern: '',
        isReagent: 0,
        isRegulated: 0,
        registerAddress: '',
        businessScopeDetail: '',
        hideData: 0,
    })
    const factorySettingFormRef = ref()
    const factorySettingSaveRules = reactive({
        fullName: [
            {required: true, message: '请输入工厂打印名称', trigger: 'blur'},
        ],
        servicePhoneNo: [
            {required: true, message: '请输入客服电话', trigger: 'blur'},
        ],
        licenseNo: [
            {required: true, message: '请输入统一社会信用代码', trigger: 'blur'},
        ],
        address: [
            {required: true, message: '请输入详细地址', trigger: 'blur'},
        ],
        disinfectMinute: [
            {required: true, message: '请输入消毒时长', trigger: 'blur'},
        ],
    })
    const saveFactorySettingLoading = ref(false)
    const saveFactorySettingResult = ref(false)
    const saveFactorySetting = async () => {
        saveFactorySettingResult.value = false
        if (saveFactorySettingLoading.value) return
        saveFactorySettingLoading.value = true
        await factorySettingFormRef.value.validate(async (valid, fields) => {
            if (!valid) {
                saveFactorySettingLoading.value = false
                return
            }
            saveFactorySettingResult.value = await factorySettingSave(factorySetting)
            saveFactorySettingLoading.value = false
        })
    }
    return {
        factorySetting,
        factorySettingFormRef,
        factorySettingSaveRules,
        saveFactorySettingLoading,
        saveFactorySettingResult,
        saveFactorySetting,
    }
}

//列表工厂设置 oycm 2022/7/22
function useGetFactorySetting() {
    const getFactorySettingLoading = ref(false)
    const getListFactorySetting = async () => {
        if (getFactorySettingLoading.value) return
        getFactorySettingLoading.value = true
        let data = await factorySettingGet({})
        getFactorySettingLoading.value = false
        if (data) {
            return data
        } else {
            return null
        }
    }
    const store = useStore()
    const updateFactorySettingStore = async () => {
        let data = await factorySettingGet({})
        if (data) store.commit('setFactorySetting', JSON.stringify(data))
    }
    return {
        getFactorySettingLoading,
        getListFactorySetting,
        updateFactorySettingStore,
    }
}

//获取工厂需要的大量缓存
function useGetStoreFactorySetting() {
    const getStoreFactorySettingLoading = ref(false)
    const store = useStore()
    const updateFactoryStore = async () => {
        if (getStoreFactorySettingLoading.value) return
        getStoreFactorySettingLoading.value = true
        let res = await factorySettingGetStore()
        if (!!res) {
            store.commit('setFactorySetting', res.factorySetting ? JSON.stringify(res.factorySetting) : '')
            store.commit('setToothColorList', res.toothColorList ? res.toothColorList : [])
            store.commit('setAgentList', res.agentList ? res.agentList : [])
            store.commit('setDataValueList', res.dataValueList ? res.dataValueList : [])
            store.commit('setProductTestTemplate', res.productTestTemplateList ? res.productTestTemplateList : [])
            store.commit('setPlantSystemList', res.plantSystemList ? res.plantSystemList : [])
            store.commit('setPlantRetentionList', res.plantRetentionList ? res.plantRetentionList : [])
        }
        getStoreFactorySettingLoading.value = false
    }
    return {
        updateFactoryStore,
        getStoreFactorySettingLoading
    }
}

// 数据隐藏
function useFactoryDataHide() {
    const hideDataParam = reactive({
        hideData: 0
    })
    const hideDataLoading = ref(false)
    const hideDataResult = ref(false)
    const factoryDataHide = async (hideDataValue) => {
        hideDataResult.value = false
        if (hideDataLoading.value) return
        hideDataLoading.value = true
        hideDataParam.hideData = hideDataValue
        await factorySaveSwitch(hideDataParam)
        hideDataLoading.value = false
        return hideDataValue;
    }
    return {
        hideDataLoading,
        hideDataResult,
        factoryDataHide,
    }
}

export {
    useSaveFactorySetting,
    useGetFactorySetting,
    useGetStoreFactorySetting,
    useFactoryDataHide
}