//格式化日期
// 示例：<td v-datetime></td>
import moment from 'moment'

export default {
    name: 'datetime',
    directive: (el, binding) => {
        let val = el.innerHTML
        if (val) {
            el.innerHTML=moment(val).format('yyyy-MM-DD')
        }
    },
}