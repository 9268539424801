import {reactive, ref, inject, toRaw} from 'vue'
import moment from 'moment'
import {
    productSave,
    productDelete,
    productGetSimpleList,
    productGet,
    productGetList,
    productGetPage,
    productUpdateValid,
    productBatchUpdateVisible,
    productBatchUpdateNeedHide,
    productGetHiddenList,
    productGetNeedHideList,
    productMaterialGetList,
    productMaterialSave,
    productMaterialDelete,
    productMaterialAdd,
    productBatchInvalid,
    productExportExcel,
    productSupplySave,
    productSupplyDelete,
    productSupplyGetList,
    productSupplyGetAll,
} from '@/api/product'
import {useStore} from 'vuex'
import http from "@/util/axios";
import {useDownload} from "@/use/system/useFile";
import {productUnitTypeEnum} from "@/util/enum";

//保存工厂产品 oycm 2022/7/24
function useSaveProduct() {
    const product = reactive({
        id: 0,
        productNo: '',
        name: '',
        productTypeId: '',
        productUnit: '',
        price: 0,
        serialNo: '',
        points: 0,
        warrantyTemplateId: '',
        warrantYear: 1,
        warrantType: '年',
        remark: '',
        picId: '',
        picToken: '',
        isValid: 1,
        isVisible: 1,
        // testHeader: '',
        // testRegNo: '',
        finishedGoods: 0,
        testTechReq: '',
        dicode: '',
        productStandardId: '',
        productRegisterId: '',
        makeDays: 3,
        isWarrant: 1,
        medicalNo: '',
        separateCaseOut: 0,
        category: '',
        auditState: null,
        version: '',
        brand: '',
        productUnitType: productUnitTypeEnum.牙位,
    })
    const productEmpty = Object.assign({}, product)
    const initProduct = () => {
        for (let field in product) {
            if (field !== 'productTypeId' && field !== 'warrantyTemplateId' && field !== 'testHeader' && field !== 'testRegNo' && field !== 'testTechReq') {
                product[field] = productEmpty[field]
            }
        }
    }
    const productFormRef = ref()
    const productSaveRules = reactive({
        name: [
            {required: true, message: '请输入名称', trigger: 'blur'},
        ],
        productTypeId: [
            {required: true, message: '请选择种类', trigger: 'change'},
        ],
        productUnit: [
            {required: true, message: '请选择库存单位', trigger: 'change'},
        ],
    })
    const saveProductLoading = ref(false)
    const saveProductResult = ref(false)
    const saveProduct = async () => {
        saveProductResult.value = false
        if (saveProductLoading.value) return
        saveProductLoading.value = true
        await productFormRef.value.validate(async (valid, fields) => {
            if (!valid) {
                saveProductLoading.value = false
                return
            }
            saveProductResult.value = await productSave(product)
            saveProductLoading.value = false
        })
    }
    return {
        product,
        initProduct,
        productFormRef,
        productSaveRules,
        saveProductLoading,
        saveProductResult,
        saveProduct,
    }
}

//删除工厂产品 oycm 2022/7/24
function useDeleteProduct() {
    const deleteProductLoading = ref(false)
    const deleteProductResult = ref(false)
    const deleteProduct = async id => {
        deleteProductResult.value = false
        if (deleteProductLoading.value) return
        deleteProductLoading.value = true
        deleteProductResult.value = await productDelete({id: id})
        deleteProductLoading.value = false
    }
    return {
        deleteProductLoading,
        deleteProductResult,
        deleteProduct,
    }
}

//详细列表工厂产品 oycm 2022/8/9 productGetList
function useGetListProduct() {
    const getListProductLoading = ref(false)
    const productList = ref([])
    const getListProduct = async () => {
        if (getListProductLoading.value) return
        getListProductLoading.value = true
        let data = await productGetList({})
        if (data) {
            productList.value = data
        }
        getListProductLoading.value = false
    }
    const store = useStore()
    const updateStoreProductDetailLoading = ref(false)
    const updateStoreProductDetail = async () => {
        if (updateStoreProductDetailLoading.value) return
        updateStoreProductDetailLoading.value = true
        await getListProduct()
        store.commit('setProductDetails', productList.value)
        let productCount = store.state.productCount
        let productSimpleList = productList.value.map(x => {
            let product = productCount.find(x => x.productId === x.id)
            let count = product ? product.count : 0
            return {
                id: x.id,
                name: x.name,
                pinyin: x.pinyin,
                code: x.productNo,
                extra1: x.productUnit,
                extra2: x.testRegNo,
                extra3: '',
                count: count,
                parentId: null,
                typePath: x.typePath,
                productTypeName: x.productTypeName,
                separateCaseOut: x.separateCaseOut,
                category: x.category,
                finishedGoods: x.finishedGoods
            }
        })
        store.commit('setProductList', productSimpleList)
        updateStoreProductDetailLoading.value = false
    }
    return {
        getListProductLoading,
        productList,
        updateStoreProductDetail,
        updateStoreProductDetailLoading,
        getListProduct
    }
}

function useGetProduct() {
    const getProductLoading = ref(false)
    const getProduct = async id => {
        if (getProductLoading.value) return
        getProductLoading.value = true
        let res = await productGet({id: id})
        getProductLoading.value = false
        if (res) return res
    }
    return {
        getProduct,
        getProductLoading
    }
}

//分页工厂产品 oycm 2022/7/24
function useGetPageProduct() {
    const getPageProductParam = reactive({
        pageIndex: 1,
        pageSize: 10,
        id: null,
        name: '',
        productNo: '',
        productTypeId: null,
        dicode: '',
        remark: '',
        minPrice: '',
        maxPrice: '',
        finishedGoods:'',
        isValid: null,
        isAudit: null,
        isVisible: null,
    })


    const getPageProductParamEmpty = Object.assign({}, getPageProductParam)
    const initGetPageProductParam = () => {
        Object.assign(getPageProductParam, getPageProductParamEmpty)
    }
    const getPageProductLoading = ref(false)
    const productPage = ref([])
    const getPageProductResult = reactive({
        totalCount: 0,
        totalPages: 0,
    })
    const getPageProduct = async () => {
        if (getPageProductLoading.value) return
        getPageProductLoading.value = true
        let data = await productGetPage(getPageProductParam)
        if (data && data.list && data.list.length) {
            productPage.value = data.list
            getPageProductResult.totalPages = Math.ceil(data.totalCount / getPageProductParam.pageSize)
            getPageProductResult.totalCount = data.totalCount
        } else {
            productPage.value = []
            getPageProductResult.totalPages = 0
            getPageProductResult.totalCount = 0
        }
        getPageProductLoading.value = false
    }
    return {
        getPageProductParam,
        initGetPageProductParam,
        getPageProductLoading,
        productPage,
        getPageProductResult,
        getPageProduct,
    }
}

//停用启用
function useUpdateValidProduct() {
    const updateValidProductLoading = ref(false)
    const updateValidProductResult = ref(false)
    const updateValidProduct = async (id, isValid) => {
        updateValidProductResult.value = false
        if (updateValidProductLoading.value) return
        updateValidProductLoading.value = true
        updateValidProductResult.value = await productUpdateValid({id: id, isValid: isValid})
        updateValidProductLoading.value = false
    }
    return {
        updateValidProductLoading,
        updateValidProductResult,
        updateValidProduct,
    }
}

//显示隐藏
function useBatchUpdateVisibleProduct() {
    const batchUpdateVisibleProductLoading = ref(false)
    const batchUpdateVisibleProductResult = ref(false)
    const batchUpdateVisibleProduct = async (list) => {
        batchUpdateVisibleProductResult.value = false
        if (batchUpdateVisibleProductLoading.value) return
        batchUpdateVisibleProductLoading.value = true
        batchUpdateVisibleProductResult.value = await productBatchUpdateVisible(list)
        batchUpdateVisibleProductLoading.value = false
    }
    return {
        batchUpdateVisibleProductLoading,
        batchUpdateVisibleProductResult,
        batchUpdateVisibleProduct,
    }
}

/**
 * 批量设置产品是否需要隐藏
 * @returns
 */
function useBatchUpdateNeedHideProduct() {
    const batchUpdateNeedHideProductLoading = ref(false)
    const batchUpdateNeedHideProductResult = ref(false)
    const batchUpdateNeedHideProduct = async (list) => {
        batchUpdateNeedHideProductResult.value = false
        if (batchUpdateNeedHideProductLoading.value) return
        batchUpdateNeedHideProductLoading.value = true
        batchUpdateNeedHideProductResult.value = await productBatchUpdateNeedHide(list)
        batchUpdateNeedHideProductLoading.value = false
    }
    return {
        batchUpdateNeedHideProductLoading,
        batchUpdateNeedHideProductResult,
        batchUpdateNeedHideProduct,
    }
}

/**
 * 需要隐藏产品
 * @returns
 */
function useGetNeedHideListProduct() {
    const getNeedHideListProductLoading = ref(false)
    const productNeedHideList = ref([])
    const getNeedHideListProduct = async () => {
        if (getNeedHideListProductLoading.value) return
        getNeedHideListProductLoading.value = true
        let data = await productGetNeedHideList({})
        if (data) {
            productNeedHideList.value = data
            if (productNeedHideList.value && productNeedHideList.value.length) {
                productNeedHideList.value.forEach(x => x.isChecked = false)
            }
        }
        getNeedHideListProductLoading.value = false
    }
    return {
        getNeedHideListProductLoading,
        productNeedHideList,
        getNeedHideListProduct,
    }
}

//列表工厂产品 oycm 2022/7/24
function useGetHiddenListProduct() {
    const getHiddenListProductLoading = ref(false)
    const productHiddenList = ref([])
    const getHiddenListProduct = async () => {
        if (getHiddenListProductLoading.value) return
        getHiddenListProductLoading.value = true
        let data = await productGetHiddenList({})
        if (data) {
            productHiddenList.value = data
            if (productHiddenList.value && productHiddenList.value.length) {
                productHiddenList.value.forEach(x => x.isChecked = false)
            }
        }
        getHiddenListProductLoading.value = false
    }
    return {
        getHiddenListProductLoading,
        productHiddenList,
        getHiddenListProduct,
    }
}

function useSaveProductMaterial() {
    const saveProductMaterialParam = reactive({
        id: '',
        materialIds: []
    })
    const saveProductMaterialLoading = ref(false)
    const saveProductMaterialResult = ref(false)
    const saveProductMaterial = async () => {
        if (saveProductMaterialLoading.value) return
        saveProductMaterialLoading.value = true
        saveProductMaterialResult.value = false
        saveProductMaterialResult.value = await productMaterialSave(saveProductMaterialParam)
        saveProductMaterialLoading.value = false
    }
    return {
        saveProductMaterialParam,
        saveProductMaterialResult,
        saveProductMaterial,
        saveProductMaterialLoading
    }
}

function useAddProductMaterial() {
    const addProductMaterialParam = reactive({
        productId: '',
        materialId: '',
    })
    const addProductMaterialLoading = ref(false)
    const addProductMaterialResult = ref(false)
    const addProductMaterial = async () => {
        if (addProductMaterialLoading.value) return
        addProductMaterialLoading.value = true
        addProductMaterialResult.value = false
        addProductMaterialResult.value = await productMaterialAdd(addProductMaterialParam)
        addProductMaterialLoading.value = false
    }
    return {
        addProductMaterialParam,
        addProductMaterialLoading,
        addProductMaterialResult,
        addProductMaterial
    }
}

function useDeleteProductMaterial() {
    const deleteProductMaterialParam = reactive({
        productId: '',
        materialId: '',
    })
    const deleteProductMaterialLoading = ref(false)
    const deleteProductMaterialResult = ref(false)
    const deleteProductMaterial = async () => {
        if (deleteProductMaterialLoading.value) return
        deleteProductMaterialLoading.value = true
        deleteProductMaterialResult.value = false
        deleteProductMaterialResult.value = await productMaterialDelete(deleteProductMaterialParam)
        deleteProductMaterialLoading.value = false
    }
    return {
        deleteProductMaterialParam,
        deleteProductMaterialLoading,
        deleteProductMaterialResult,
        deleteProductMaterial
    }
}

function useGetProductMaterialList() {
    const productMaterialList = ref([])
    const getProductMaterialListLoading = ref(false)
    const getProductMaterialList = async id => {
        if (getProductMaterialListLoading.value) return
        getProductMaterialListLoading.value = true
        let res = await productMaterialGetList({id: id})
        if (res) {
            productMaterialList.value = res
        } else {
            productMaterialList.value = []
        }
        getProductMaterialListLoading.value = false
    }
    return {
        productMaterialList,
        getProductMaterialListLoading,
        getProductMaterialList
    }
}

const useExportProductExcel = () => {
    const {
        downFile,
        downFileLoading
    } = useDownload()
    const exportProductExcel = req => {
        req.token = sessionStorage.getItem('token')
        let url = productExportExcel + '?' + http.getQueryFromJson(req)
        downFile(url, '产品列表')
    }
    return {
        exportProductExcelLoading: downFileLoading,
        exportProductExcel,
    }
}

// 批量作废产品
function useBatchInvalidProduct() {
    const batchInvalidProductLoading = ref(false)
    const batchInvalidProductResult = ref(false)
    const batchInvalidProduct = async ids => {
        batchInvalidProductResult.value = false
        if (batchInvalidProductLoading.value) return
        batchInvalidProductLoading.value = true
        batchInvalidProductResult.value = await productBatchInvalid({ids: ids})
        batchInvalidProductLoading.value = false
    }
    return {
        batchInvalidProductLoading,
        batchInvalidProductResult,
        batchInvalidProduct,
    }
}

// 获取产品采购价列表
function useGetProductSupplyList() {
    const productSupplyList = ref([])
    const getProductSupplyListLoading = ref(false)
    const getProductSupplyList = async id => {
        if (getProductSupplyListLoading.value) return
        getProductSupplyListLoading.value = true
        let res = await productSupplyGetList({id: id})
        if (res) {
            productSupplyList.value = res
        } else {
            productSupplyList.value = []
        }
        getProductSupplyListLoading.value = false
    }
    return {
        productSupplyList,
        getProductSupplyListLoading,
        getProductSupplyList
    }
}

// 根据供应商id获取关联产品采购数据
function useGetProductSupplyAll() {
    const supplyProductList = ref([])
    const getProductSupplyAllLoading = ref(false)
    const getProductSupplyAll = async id => {
        if (getProductSupplyAllLoading.value) return
        getProductSupplyAllLoading.value = true
        let res = await productSupplyGetAll({id: id})
        if (res) {
            supplyProductList.value = res
        } else {
            supplyProductList.value = []
        }
        getProductSupplyAllLoading.value = false
    }
    return {
        supplyProductList,
        getProductSupplyAllLoading,
        getProductSupplyAll
    }
}

// 删除产品采购价格
function useDeleteProductSupply() {
    const deleteProductSupplyLoading = ref(false)
    const deleteProductSupplyResult = ref(false)
    const deleteProductSupply = async id => {
        if (deleteProductSupplyLoading.value) return
        deleteProductSupplyLoading.value = true
        deleteProductSupplyResult.value = false
        deleteProductSupplyResult.value = await productSupplyDelete({id: id})
        deleteProductSupplyLoading.value = false
    }
    return {
        deleteProductSupplyLoading,
        deleteProductSupplyResult,
        deleteProductSupply
    }
}

function useSaveProductSupply() {
    const saveProductSupplyParam = reactive({
        id: 0,
        productId: null,
        supplyId: null,
        price: null,
        isDefault: 0
    })
    const saveProductSupplyParamEmpty = Object.assign({}, saveProductSupplyParam)
    const initSaveProductSupplyParam = () => {
        Object.assign(saveProductSupplyParam, saveProductSupplyParamEmpty)
    }
    const saveProductSupplyLoading = ref(false)
    const saveProductSupplyResult = ref(false)
    const saveProductSupply = async () => {
        if (saveProductSupplyLoading.value) return
        saveProductSupplyLoading.value = true
        saveProductSupplyResult.value = false
        saveProductSupplyResult.value = await productSupplySave(saveProductSupplyParam)
        saveProductSupplyLoading.value = false
    }
    return {
        initSaveProductSupplyParam,
        saveProductSupplyParam,
        saveProductSupplyResult,
        saveProductSupply,
        saveProductSupplyLoading
    }
}

export {
    useSaveProduct,
    useDeleteProduct,
    useGetListProduct,
    useGetPageProduct,
    useUpdateValidProduct,
    useBatchUpdateVisibleProduct,
    useBatchUpdateNeedHideProduct,
    useGetHiddenListProduct,
    useGetNeedHideListProduct,
    useSaveProductMaterial,
    useGetProductMaterialList,
    useAddProductMaterial,
    useDeleteProductMaterial,
    useExportProductExcel,
    useBatchInvalidProduct,
    useGetProduct,
    useGetProductSupplyList,
    useDeleteProductSupply,
    useSaveProductSupply,
    useGetProductSupplyAll,
}