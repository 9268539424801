<template>
    <el-container style="height:100%;" v-loading="refreshStoreLoading">
        <el-aside width="110px" style="overflow-x: hidden;">
            <el-menu
                ref="menuRef"
                background-color="#012972"
                class="home-menu"
                :router="false"
                :unique-opened="true"
                text-color="#ffffff"
                :default-active="defaultMenu">
                <el-menu-item index="homeInfo" class="logo-menu-item" @click="setPage('welcome')">
                </el-menu-item>
                <el-popover v-for="menuOne in modules" :index="menuOne.nameEn" placement="right-start"
                            :visible="menuOne.popperVisible"
                            popper-class="popper-menu">
                    <template #reference>
                        <el-menu-item :title="menuOne.nameCn" @click="chooseMenuOne(menuOne)"
                                      :class="curParentName===menuOne.nameCn?'active-menu-text':'default-menu-text'">
                            <template #title>
                                <el-icon style="font-size:14px;">
                                    <UserFilled v-if="menuOne.nameEn==='customer'"/>
                                    <User v-if="menuOne.nameEn==='workerMgt'"/>
                                    <setting v-if="menuOne.nameEn==='system'"/>
                                    <Coin v-if="menuOne.nameEn==='warehouse'"/>
                                    <Goods v-if="menuOne.nameEn==='purchase'"/>
                                    <OfficeBuilding v-if="menuOne.nameEn==='property'"/>
                                    <List v-if="menuOne.nameEn==='case'"/>
                                    <Management v-if="menuOne.nameEn==='product'"/>
                                    <Suitcase v-if="menuOne.nameEn==='salesMgt'"/>
                                    <Tools v-if="menuOne.nameEn==='produce'"/>
                                    <DataAnalysis v-if="menuOne.nameEn==='analysis'"/>
                                    <Document v-if="menuOne.nameEn==='plant'"/>
                                    <Sell v-if="menuOne.nameEn==='sale'"/>
                                </el-icon>
                                <span>{{
                                        menuOne.nameCn
                                    }}</span>
                            </template>
                        </el-menu-item>
                    </template>
                    <el-menu
                        text-color="#38476C"
                        active-text-color="#38476C"
                        background-color="white"
                        router unique-opened
                        :default-openeds="menuOne.children.length?[menuOne.children[0].nameEn]:[]"
                    >
                        <template v-for="(menuTwo,index) in menuOne.children">
                            <el-menu-item v-if="menuTwo.moduleType===moduleTypeEnum.页面"
                                          :index="setPath(menuTwo.nameEn)"
                                          :class="curPageName===menuTwo.nameCn?'active-page-text':'default-page-text'"
                                          @click="addPageTab(menuTwo.nameCn,menuTwo.parentNameCn,menuTwo.nameEn)">
                                {{ menuTwo.nameCn }}
                            </el-menu-item>
                            <el-sub-menu v-else :title="menuTwo.nameCn" :index="menuTwo.nameEn">
                                <template #title>
                                    <b>{{ menuTwo.nameCn }}</b>
                                </template>
                                <el-menu-item v-for="menuThree in menuTwo.children" :index="setPath(menuThree.nameEn)"
                                              :class="curPageName===menuThree.nameCn?'active-page-text':'default-page-text'"
                                              @click="addPageTab(menuThree.nameCn,menuTwo.parentNameCn,menuThree.nameEn)">
                                    {{ menuThree.nameCn }}
                                </el-menu-item>
                            </el-sub-menu>
                        </template>
                        <!--                        <el-sub-menu v-for="(menuTwo,index) in menuOne.children" :title="menuTwo.nameCn"-->
                        <!--                                     :index="menuTwo.nameEn">-->
                        <!--                            <template #title>-->
                        <!--                                <b>{{ menuTwo.nameCn }}</b>-->
                        <!--                            </template>-->
                        <!--                            <el-menu-item v-for="menuThree in menuTwo.children" :index="setPath(menuThree.nameEn)"-->
                        <!--                                          :class="curPageName===menuThree.nameCn?'active-page-text':'default-page-text'"-->
                        <!--                                          @click="addPageTab(menuThree.nameCn,menuTwo.parentNameCn,menuThree.nameEn)">-->
                        <!--                                {{ menuThree.nameCn }}-->
                        <!--                            </el-menu-item>-->
                        <!--                        </el-sub-menu>-->
                    </el-menu>
                </el-popover>
            </el-menu>
        </el-aside>
        <el-container>
            <el-header class='flex align-center justify-between'
                       style='background-color: #E9F1FF !important;height:52px;'>
                <el-space>
                    <el-select v-model="searchPageNameEn" @change="changeSearchPage"
                               filterable clearable class="w-150 page-select"
                               placeholder="搜索页面">
                        <el-option v-for="item in allPages" :key="item.id" :value="item.nameEn"
                                   :label="item.nameCn"></el-option>
                    </el-select>
                </el-space>
                <marquee-text style="max-width: 700px;color:#666;" class="text-df" :duration="30"
                              v-show="!!factoryNotice">
                    {{ factoryNotice }}
                </marquee-text>
                <el-space>
                    <el-image style="width:26px;height:26px;" :src="userAvatar"></el-image>
                    <el-link type='primary' title='重置密码' style="min-width: 90px;"
                             @click='handleToResetPwd'>
                        你好 {{ userName }}
                    </el-link>
                    <el-button :icon='RefreshRight' type='success' text title='点击可以刷新当前页面'
                               @click='refreshPage'></el-button>
                    <el-button type='warning' text title='我的通知' @click='readMessage'>
                        <el-badge :value="unreadNum" :hidden="!unreadNum" is-dot class="message-badge">
                            <el-icon>
                                <Message/>
                            </el-icon>&nbsp;
                        </el-badge>
                    </el-button>
                    <el-button :icon='InfoFilled' type='success' text title='点击可以刷新缓存数据，例如设置、产品、客户等数据，如果数据较多可能需要花费几分钟'
                               @click='showRefresh'></el-button>
                    <el-button :icon='QuestionFilled' type='primary' text title='帮助'
                               @click='handleHelp'></el-button>
                    <el-button :icon='SwitchButton' type='danger' text title='退出'
                               @click='handleLogOut'></el-button>
                </el-space>
            </el-header>
            <!--                    <el-menu-item index="caseComment" class="text-warning-light" @click="showUnreadCaseComment"-->
            <!--                                  title="订单沟通消息（未读）">-->
            <!--                        <el-badge :value="caseCommentUnreadList.length" :hidden="!caseCommentUnreadList.length"-->
            <!--                                  class="message-badge">-->
            <!--                            <el-icon>-->
            <!--                                <ChatDotRound/>-->
            <!--                            </el-icon>&nbsp;-->
            <!--                        </el-badge>-->
            <!--                    </el-menu-item>-->
            <el-tabs
                v-show="pageTabs.length"
                v-model="curPageTab"
                type="card"
                class="page-tabs"
                :closable="curPageTab!=='welcome'"
                @tab-click="changePageTab"
                @tab-remove="removePageTab"
            >
                <el-tab-pane
                    v-for="item in pageTabs"
                    :key="item.routeName"
                    :label="item.parentPageName+(item.parentPageName?' - ':'')+item.pageName"
                    :name="item.routeName"
                >
                </el-tab-pane>
            </el-tabs>
            <el-main class="home-main" @click="hideAllMenus">
                <router-view v-slot="{ Component }" v-if="isRouterAlive">
                    <keep-alive>
                        <component :is="Component"/>
                    </keep-alive>
                </router-view>
            </el-main>
            <el-footer style="height:25px;padding:3px;background-color: #f5f7fa;">
                <el-row>
                    <el-col :span="24" class="text-center text-info" style="font-size: 10px;">
                        此软件由存知网络科技有限公司提供 v1.0.0
                    </el-col>
                </el-row>
            </el-footer>
        </el-container>
        <el-drawer v-model="caseCommentVisible" title="订单沟通消息（未处理）">
            <el-table :data="caseCommentUnreadList" :show-header="false" v-loading="getUnreadListCaseCommentLoading"
                      style="width: 100%;">
                <el-table-column min-width="400">
                    <template #default="scope">
                        <div style="display: flex;justify-content:space-between;">
                            <b>{{ scope.row.caseNo }}</b>
                            <span>{{ moment(scope.row.addTime).format('yyyy-MM-DD HH:mm') }}</span>
                        </div>
                        <div style="display: flex;justify-content:space-between;align-items: flex-end;">
                            <div class="flex-col">
                                <span class="text-primary">{{ scope.row.facDoctorName }}</span>
                                <span>{{ scope.row.facHospitalName }}</span>
                            </div>
                            <div>
                                <span class="text-danger">{{ scope.row.unreadNum }}</span>
                                <span style="margin-right: 20px;">条未读</span>
                                <el-button plain type="primary" @click="handleCaseComment(scope.row.caseId)"
                                           :loading="readAllCaseCommentLoading">前往>>
                                </el-button>
                                <el-button plain type="warning" @click="readAllCaseComment(scope.row.caseId)"
                                           :loading="readAllCaseCommentLoading">已处理
                                </el-button>
                            </div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </el-drawer>
        <el-drawer v-model="refreshVisible" title="刷新缓存">
            <el-button-group>
                <el-button type="primary" :icon="Refresh" @click="refreshStore" :loading="refreshStoreLoading">刷新全部缓存
                </el-button>
            </el-button-group>
            <el-table :data="refreshList" :show-header="false">
                <el-table-column width="200">
                    <template #default="scope">
                        <el-button :loading="scope.row.loading || refreshStoreLoading" @click="refreshItem(scope.row)"
                                   type="primary" plain
                                   :disabled="!!scope.row.result">
                            {{ scope.row.nameCn }}
                        </el-button>
                    </template>
                </el-table-column>
                <el-table-column min-width="150">
                    <template #default="scope">
                        <span class="text-success">{{ scope.row.result }}</span>
                    </template>
                </el-table-column>
            </el-table>
        </el-drawer>
    </el-container>
</template>

<script setup>
import {useStore} from 'vuex'
import MarqueeText from 'vue-marquee-text-component'
import {useLogout} from '@/use/system/useUser'
import {computed, nextTick, onMounted, inject, provide, ref, reactive, onBeforeUnmount, watch} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import {ElMessage} from "element-plus";
import {
    Refresh,
    QuestionFilled,
    User,
    RefreshRight,
    SwitchButton,
    InfoFilled,
    DArrowLeft,
    DArrowRight
} from "@element-plus/icons-vue";
import {useGetListFacHospital} from "@/use/customer/useHospital";
import {useUpdateStoreDoctorList} from "@/use/customer/useDoctor";
import {useGetListProduct} from "@/use/product/useProduct";
import {useGetStoreFactorySetting} from "@/use/system/useFactorySetting";
import {useGetListStep} from "@/use/system/useStep";
import {useGetListMaterial} from "@/use/purchase/useMaterial";
import {useUpdateStoreMaterialType} from "@/use/purchase/useMaterialType";
import moment from "moment";
import {netOrderHasNew} from "@/api/case";
import {factoryAlertHasNew, factoryStorageAlert} from "@/api/system";
import {useGetAllDataValueList} from '@/use/system/useData'

const {
    getPublishFactoryNotice
} = useGetPublishFactoryNotice()
import userAvatar from '@/assets/icon/user.png'

// ResizeObserver loop limit exceeded报错解决方案
const debounce = (fn, delay) => {
    let timer = null;
    return function () {
        let context = this;
        let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            fn.apply(context, args);
        }, delay);
    }
}
const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
    constructor(callback) {
        callback = debounce(callback, 16);
        super(callback);
    }
}
const isRouterAlive = ref(true)
const toPage = inject('toPage')
const {updateStoreDoctorList, updateStoreDoctorListLoading} = useUpdateStoreDoctorList()
const {updateFactoryStore, getStoreFactorySettingLoading} = useGetStoreFactorySetting()
const {updateStoreHospitalDetail, getListFacHospitalLoading} = useGetListFacHospital()
const {updateStoreMaterial, updateStoreMaterialLoading} = useGetListMaterial()
const {updateStoreProductDetail, updateStoreProductDetailLoading} = useGetListProduct()
const {updateStoreProductType, updateStoreProductTypeLoading} = useUpdateStoreProductType()
const {updateStoreMaterialType, updateStoreMaterialTypeLoading} = useUpdateStoreMaterialType()
const {getAllDataValueLoading, getAllDataValueList} = useGetAllDataValueList()
const {updateStepListStore, getListStepLoading} = useGetListStep()
//缓存数据字典、员工、医院、医生、产品、材料
onMounted(async () => {
    initPageTab()
    getAllPages()
    updateNotice()
    await start()
    //数据较多 无法缓存到storage 所以如果F5刷新 需要重新获取
    // getOssToken()
    refreshStoreLoading.value = true
    await initData()
    refreshStoreLoading.value = false
    await getUnreadListCaseComment()
    let unreadNumRes = await getUnreadNumFactoryAlert()
    store.commit('setUnreadNum', unreadNumRes)
    await updateStore()
    await netOrderHasNew()
    await factoryAlertHasNew()
    await factoryStorageAlert()
})
const factoryNotice = computed(() => store.state.factoryNotice)
//每分钟获取最新的公告
let noticeTimer = null
const updateNotice = () => {
    getPublishFactoryNotice()
    noticeTimer = setTimeout(() => {
        updateNotice()
    }, 2 * 60 * 1000)
}
const initData = async () => {
    let factorySetting = sessionStorage.getItem('factorySetting')
    if (!factorySetting || factorySetting === 'null' || factorySetting === 'undefined') {
        //已经缓存在storage里面的数据 F5导致的刷新不重新获取
        await updateFactoryStore()
    }
}
const updateStore = async () => {
    let updateStoreTime = store.state.updateStoreTime
    if (updateStoreTime) {
        //1天前的数据  更新缓存
        if (moment(updateStoreTime).isBefore(moment().subtract(1, 'days'))) {
            await refreshAll()
        }
    } else {
        await refreshAll()
    }
}
// 跳转到帮助页面
const handleHelp = () => {
    window.open('https://factory.cunzhi.net/factoryHelp', '_blank')
}
//用来刷新的时候 保持菜单选中状态
const route = useRoute()
const defaultMenu = ref(route.path)
const menuRef = ref()
const store = useStore()
const userName = store.state.userName
const factoryName = store.state.factoryName
const {
    logOut
} = useLogout()
const modules = ref(JSON.parse(store.state.auth))
//隐藏所有二级菜单
const hideAllMenus = (nameEn = null) => {
    if (modules.value && modules.value.length) {
        for (let menuOne of modules.value.filter(x => x.nameEn !== nameEn)) {
            menuOne.popperVisible = false
        }
    }
}
// 获取所有页面列表
const allPages = ref([])
const getAllPages = () => {
    if (!modules.value || !modules.value.length) {
        allPages.value = []
    } else {
        for (let item of modules.value) {
            getChildPages(item)
        }
    }
}
const getChildPages = item => {
    if (item.moduleType === moduleTypeEnum.页面 && !item.children.length) {
        allPages.value.push(item)
    } else if (item.children.length) {
        for (let child of item.children) {
            getChildPages(child)
        }
    }
}
const searchPageNameEn = ref('')
const changeSearchPage = () => {
    if (searchPageNameEn.value) {
        setPage(searchPageNameEn.value)
    }
}

hideAllMenus()
const chooseMenuOne = menuOne => {
    hideAllMenus(menuOne.nameEn)
    menuOne.popperVisible = !menuOne.popperVisible
}

const setPath = nameEn => {
    return '/admin/' + nameEn
}
const handleLogOut = async () => {
    await connection.off(factoryAlertMethod.强制下线)
    await disconnect()
    if (noticeTimer) {
        clearTimeout(noticeTimer)
    }
    clearedStorePageList()
    if (netOrderNotification) netOrderNotification.close()
    if (ossAlert) ossAlert.close()
    if (alertList.length) {
        for (let alert of alertList) {
            if (alert.alertNotification) alert.alertNotification.close()
        }
    }
    logOut()
}
//读取我的消息
const router = useRouter()
const readMessage = () => {
    router.push({
        name: 'myMessage'
    })
}
const unreadNum = computed(() => {
    return store.state.unreadNum
})
import {useGetUnreadNumFactoryAlert} from "@/use/system/useFactoryAlert";

const {
    getUnreadNumFactoryAlertLoading,
    getUnreadNumFactoryAlert,
} = useGetUnreadNumFactoryAlert()
// 页面缓存
const pageTabs = ref([])
const curPageTab = ref('welcome')
// 点击tab跳转页面
let goToPage = debounce(() => {
    if (store.state.curPageName === curPageTab.value) {
        return
    }
    if (curPageTab.value) {
        setMenu(curPageTab.value)
        toPage(curPageTab.value)
    }
}, 150)
const changePageTab = (pane, ev) => {
    goToPage()
}

//根据缓存初始化页面
const initPageTab = () => {
    let pageList = store.state.pageList
    if (pageList && pageList.length) {
        pageTabs.value = pageList
    } else {
        pageTabs.value = [{
            pageName: '首页',
            parentPageName: '',
            routeName: 'welcome',
        }]
    }
    let pageName = store.state.curPageName
    if (pageName && pageName !== 'undefined' && pageName !== 'null') {
        curPageTab.value = pageName
    } else {
        if (pageTabs.value.length) {
            curPageTab.value = pageTabs.value[0].routeName
            store.commit('setCurPageName', curPageTab.value)
            setPage(curPageTab.value)
        } else {
            curPageTab.value = 'welcome'
            store.commit('setCurPageName', 'welcome')
            setPage('welcome')
        }
    }
}
/**
 * 清空缓存PageList
 */
const clearedStorePageList = () => {
    pageTabs.value = []
    store.commit('setPageList', pageTabs.value)
}
const handleToResetPwd = () => {
    toPage('resetPassword')
    addPageTab('重置密码', '设置', 'resetPassword')
}
// 新打开页面时设置tab栏目
const addPageTab = (pageName, parentPageName, routeName) => {
    curPageName.value = pageName
    curParentName.value = parentPageName
    sessionStorage.setItem('pageName', pageName)
    sessionStorage.setItem('parentPageName', parentPageName)
    hideAllMenus()
    // if (routeName === 'welcome') return
    curPageTab.value = routeName
    store.commit('setCurPageName', routeName)
    // store.commit('addVisitHistory', {
    //     factoryId: store.state.factoryId,
    //     moduleName: parentPageName,
    //     pageName: pageName,
    //     funcName: '',
    //     visitTime: new Date()
    // })
    if (pageTabs.value.some(x => x.routeName === routeName)) {
        return
    }
    if (pageTabs.value.some(x => x.pageName === pageName && x.parentPageName === parentPageName)) {
        return
    }
    if (pageTabs.value.length >= 7) {
        pageTabs.value.splice(1, 1)
    }
    pageTabs.value.push({
        pageName: pageName,
        parentPageName: parentPageName,
        routeName: routeName,
    })
    store.commit('setPageList', pageTabs.value)
}
import {useAddFactoryPageRecord} from "@/use/system/useFactoryPageRecord";

const {addFactoryPageRecord} = useAddFactoryPageRecord()
// setInterval(() => {
//     // 每10分钟上传页面访问记录
//     let param = store.state.visitHistory
//     if (!param || !param.length) return
//     addFactoryPageRecord({list: param})
//     store.commit('clearVisitHistory', [])
// }, 10 * 60 * 1000)
// 关闭页面
const removePageTab = routeName => {
    if (routeName === 'welcome') return
    let pageIndex = pageTabs.value.findIndex(x => x.routeName === routeName)
    if (pageIndex >= 0) pageTabs.value.splice(pageIndex, 1)
    if (curPageTab.value === routeName) {
        if (pageTabs.value.length) {
            curPageTab.value = pageTabs.value[pageIndex > 0 ? (pageIndex - 1) : pageIndex].routeName
            store.commit('setCurPageName', curPageTab.value)
            setPage(curPageTab.value)
        } else {
            store.commit('setCurPageName', 'welcome')
            setPage('welcome')
        }
    }
    store.commit('setPageList', pageTabs.value)
}
provide('removePageTab', removePageTab)

const curPageName = ref(sessionStorage.getItem('pageName')) //当前页中文
const curParentName = ref(sessionStorage.getItem('parentPageName'))//当前模块中文


onBeforeUnmount(async () => {
    await disconnect()
})
// 未读沟通消息
import {useGetUnreadListCaseComment, useReadAllCaseComment} from "@/use/case/useCaseComment";

const {
    caseCommentUnreadList,
    getUnreadListCaseComment,
    getUnreadListCaseCommentLoading
} = useGetUnreadListCaseComment()
const caseCommentVisible = ref(false)
const showUnreadCaseComment = () => {
    getUnreadListCaseComment()
    caseCommentVisible.value = true
}
const {
    readAllCaseCommentResult,
    readAllCaseCommentLoading,
    readAllCaseComment
} = useReadAllCaseComment()
watch(readAllCaseCommentResult, async (n, o) => {
    if (!!n) {
        await getUnreadListCaseComment()
    }
})
const handleCaseComment = caseId => {
    newCaseComment.caseId = caseId
    newCaseComment.commentContent = ''
    newCaseComment.fileId = ''
    caseCommentVisible.value = false
    toCaseComment(caseId)
}
//推送！
import * as signalR from "@microsoft/signalr"
import {ElNotification} from "element-plus";
import {factoryAlertMethod, moduleTypeEnum, userTypeEnum, factoryAlertTypeEnum, getEnumList} from "@/util/enum";

const factoryAlertTypeList = getEnumList(factoryAlertTypeEnum)
// 长链接对象
const connection = new signalR.HubConnectionBuilder()
    .withUrl("/messageHub", {accessTokenFactory: () => sessionStorage.getItem("token")})
    .configureLogging(signalR.LogLevel.Error)
    .build();
//正在连接
let connecting = false

// 开始连接
async function start() {
    console.log(connection.state)
    connecting = true
    try {
        await connection.start()
        connecting = false
        console.log("SignalR Connected.");
    } catch (err) {
        console.log(err);
        setTimeout(start, 20 * 1000);//错误重连
    }
}

// 关闭连接
const disconnect = async () => {
    console.log('disconnect')
    await connection.stop()
}
connection.onclose(async error => {
    console.log('error', error)
    if (error && !connecting) await start()
})
//获取服务端长链接的推送信息
connection.on(factoryAlertMethod.消息全部已读, message => {
    store.commit('setUnreadNum', 0)
})
connection.on(factoryAlertMethod.文件上传成功, message => {
    ElNotification.success(message)
})
// OSS存储提醒
let ossAlert = null
connection.on(factoryAlertMethod.OSS提醒, message => {
    ossAlert = netOrderNotification = ElNotification.warning({
        message: message,
        title: '云存储提醒',
        duration: 0,
        position: "bottom-right",
    })
})
// 新的网络订单
const newNetOrderLoading = ref(false)
provide('newNetOrderLoading', newNetOrderLoading)
let netOrderNotification = null
connection.on(factoryAlertMethod.新网络订单, message => {
    newNetOrderLoading.value = false
    if (netOrderNotification) netOrderNotification.close()
    netOrderNotification = ElNotification.success({
        message: '点击跳转到审核页面',
        title: '您有新的网络订单',
        duration: 0,
        position: "bottom-right",
        onClick: () => {
            newNetOrderLoading.value = true
            if (route.name !== 'netOrderAudit') {
                setPage('netOrderAudit')
            }
            netOrderNotification.close()
        },
        onClose: () => {
            newNetOrderLoading.value = true
        }
    })
})
// 通知
let alertList = []
connection.on(factoryAlertMethod.通知提醒, alertType => {
    let alert = alertList.find(x => x.alertType === alertType)
    if (alert) alert.alertNotification.close()
    let alertTypeText = ''
    let factoryAlertType = factoryAlertTypeList.find(x => x.id === parseInt(alertType))
    if (factoryAlertType) alertTypeText = factoryAlertType.name
    let newNotification = ElNotification.success({
        message: '点击跳转到通知页面',
        title: alertTypeText,
        duration: 0,
        position: "bottom-right",
        onClick: () => {
            if (route.name !== 'myMessage') {
                setMenu('myMessage')
                router.push({
                    name: 'myMessage',
                    query: {
                        factoryAlertType: parseInt(alertType)
                    }
                })
            }
            newNotification.close()
            let index = alertList.findIndex(x => x.alertType === alertType)
            if (index >= 0) alertList.splice(index, 1)
        },
        onClose: () => {
        }
    })
    alertList.push({
        alertType: alertType,
        alertNotification: newNotification
    })
})

//强制下线
connection.on(factoryAlertMethod.强制下线, async message => {
    ElNotification.warning({
        title: '请重新登录',
        message: '断开连接，请重新登录',
        duration: 10 * 1000,
    })
    await handleLogOut()
})
//获取库存提醒推送
connection.on(factoryAlertMethod.库存提醒, factoryAlert => {
    store.commit('setUnreadNum', 1)
    let _notify = ElNotification.warning({
        title: factoryAlert.Title,
        position: 'bottom-right',
        dangerouslyUseHTMLString: true,
        message: `<p>${factoryAlert.Message}</p><p>${factoryAlert.AddTime}</p>`,
        duration: 0,
        onClick: () => {
            toPage('myMessage')
            _notify.close()
        }
    })
})
const newCaseCommentNum = ref(0)//新沟通消息
provide('newCaseCommentNum', newCaseCommentNum)
const showCaseCommentNum = ref(0)//确认要查看新消息
provide('showCaseCommentNum', showCaseCommentNum)
const newCaseComment = reactive({
    id: '',
    caseId: '',
    sendSource: userTypeEnum.Factory,
    commentContent: '',
    fileId: '',
    fileToken: '',
    facDoctorName: '',
    facHospitalName: '',
    facWorkerName: '',
    factoryName: '',
    addTime: '',
    addTimeStr: '',
    isRead: 0
})
provide('newCaseComment', newCaseComment)
const toCaseComment = caseId => {
    if (route.name !== 'caseAdd') {
        router.push({
            name: 'caseAdd',
            query: {
                id: caseId,
                type: 'comment'
            }
        })
    } else {
        showCaseCommentNum.value++
    }
}
connection.on(factoryAlertMethod.订单沟通, comment => {
    newCaseCommentNum.value++
    if (!!comment) {
        for (let field in newCaseComment) {
            let dbField = field.substring(0, 1).toUpperCase() + field.substring(1)
            newCaseComment[field] = comment[dbField]
        }
    }
    let _notify = ElNotification.warning({
        title: '新的订单沟通信息',
        position: 'bottom-right',
        dangerouslyUseHTMLString: true,
        message: `<p>${comment.FacDoctorName + ":" + comment.FacHospitalName}</p><p>${comment.AddTimeStr}</p>`,
        duration: 10 * 1000,
        onClick: () => {
            toCaseComment(comment.CaseId)
            _notify.close()
        }
    })
})

import {factoryWebStoreEnum} from "@/util/enum";
import {useUpdateStoreProductType} from "@/use/product/useProductType";
import {useGetListFacWorker} from "@/use/workerMgt/useWorker";
import Question from "@/components/question";

const {updateStoreWorker, getListFacWorkerLoading} = useGetListFacWorker()
//子页面可以通过监听该字段来得知是否有数据刷新
const updateFactoryStoreLoading = ref(false)
const updateFactoryStoreType = ref(0)
provide('updateFactoryStoreLoading', updateFactoryStoreLoading)
provide('updateFactoryStoreType', updateFactoryStoreType)
let refreshNotify = null
connection.on(factoryAlertMethod.更新缓存, async storeName => {
    await refreshStoreItem(storeName)
    // 录单页面价格等数据敏感，医院、医生更改后强制刷新
    if (route.name === 'caseAdd') {
        if (storeName === factoryWebStoreEnum.hospitalList || storeName === factoryWebStoreEnum.doctorList) {
            // 提示可以刷新
            notifyRefreshPage('医院/医生')
        }
    }
    if (route.name === 'purchaseAdd') {
        if (storeName === factoryWebStoreEnum.materialSupplyList || storeName === factoryWebStoreEnum.materialList) {
            // 提示可以刷新
            notifyRefreshPage('材料')
        }
    }
})
const notifyRefreshPage = title => {
    if (refreshNotify) {
        // 已有弹窗
    } else {
        refreshNotify = ElNotification({
            title: title + '已修改，请刷新页面',
            type: 'warning',
            message: "点击刷新页面",
            position: 'bottom-right',
            duration: 0,
            onClick: () => {
                refreshPage()
                refreshNotify.close()
            }
        })
    }
}
// 手动刷新缓存
const refreshVisible = ref(false)
const showRefresh = () => {
    refreshVisible.value = true
    refreshList.value.forEach(x => x.result = '')
}
const hideRefresh = () => {
    refreshVisible.value = false
}
const refreshPage = () => {
    isRouterAlive.value = false
    ElMessage.success('页面刷新中')
    setTimeout(() => {
        isRouterAlive.value = true
    })
}
// 刷新缓存期间提示
let workerNotify = null
watch(getListFacWorkerLoading, (n, o) => {
    if (!!n) {
        workerNotify = ElNotification({
            message: '员工信息数据刷新中',
            type: 'warning',
            duration: 0,
        })
    } else if (workerNotify) {
        setTimeout(() => {
            workerNotify && workerNotify.close()
            workerNotify = null
        }, 2000)
    }
})
let productTypeNotify = null
watch(updateStoreProductTypeLoading, (n, o) => {
    if (!!n) {
        productTypeNotify = ElNotification({
            message: '产品种类数据刷新中',
            type: 'warning',
            duration: 0,
        })
    } else if (productTypeNotify) {
        setTimeout(() => {
            productTypeNotify && productTypeNotify.close()
            productTypeNotify = null
        }, 2000)
    }
})
let productNotify = null
watch(updateStoreProductDetailLoading, (n, o) => {
    if (!!n) {
        productNotify = ElNotification({
            message: '产品数据刷新中',
            type: 'warning',
            duration: 0,
        })
    } else if (productNotify) {
        setTimeout(() => {
            productNotify && productNotify.close()
            productNotify = null
        }, 2000)
    }
})
let hospitalNotify = null
watch(getListFacHospitalLoading, (n, o) => {
    if (modules.value.some(x => x.nameCn === '订单' || x.nameCn === '客户')) {
        if (!!n) {
            hospitalNotify = ElNotification({
                message: '客户数据刷新中',
                type: 'warning',
                duration: 0,
            })
        } else if (hospitalNotify) {
            setTimeout(() => {
                hospitalNotify && hospitalNotify.close()
                hospitalNotify = null
            }, 2000)
        }
    }
})
let doctorNotify = null
watch(updateStoreDoctorListLoading, (n, o) => {
    if (modules.value.some(x => x.nameCn === '订单' || x.nameCn === '客户')) {
        if (!!n) {
            doctorNotify = ElNotification({
                message: '医生数据刷新中',
                type: 'warning',
                duration: 0,
            })
        } else if (doctorNotify) {
            setTimeout(() => {
                doctorNotify && doctorNotify.close()
                doctorNotify = null
            }, 2000)
        }
    }
})
let materialNotify = null
watch(updateStoreMaterialLoading, (n, o) => {
    if (modules.value.some(x => x.nameCn === '采购' || x.nameCn === '仓库')) {
        if (!!n) {
            materialNotify = ElNotification({
                message: '材料数据刷新中',
                type: 'warning',
                duration: 0,
            })
        } else if (materialNotify) {
            setTimeout(() => {
                materialNotify && materialNotify.close()
                materialNotify = null
            }, 2000)
        }
    }
})
const refreshList = ref([
    {nameCn: '员工', id: factoryWebStoreEnum.workerList, loading: getListFacWorkerLoading, result: ''},
    {nameCn: '产品种类', id: factoryWebStoreEnum.productType, loading: updateStoreProductTypeLoading, result: ''},
    {nameCn: '产品', id: factoryWebStoreEnum.productList, loading: updateStoreProductDetailLoading, result: ''},
    {nameCn: '客户', id: factoryWebStoreEnum.hospitalList, loading: getListFacHospitalLoading, result: ''},
    {nameCn: '医生', id: factoryWebStoreEnum.doctorList, loading: updateStoreDoctorListLoading, result: ''},
    {nameCn: '材料种类', id: factoryWebStoreEnum.materialType, loading: updateStoreMaterialTypeLoading, result: ''},
    {nameCn: '材料', id: factoryWebStoreEnum.materialList, loading: updateStoreMaterialLoading, result: ''},
    {nameCn: '数据字典', id: factoryWebStoreEnum.dataValueList, loading: getAllDataValueLoading, result: ''},
    {nameCn: '工序', id: factoryWebStoreEnum.stepList, loading: getListStepLoading, result: ''},
    {nameCn: '其他', id: 0, loading: updateFactoryStoreLoading},
])
// 刷新所有缓存（根据模块权限，有些缓存不需要刷新）
const refreshAll = async () => {
    store.commit('setUpdateStoreTime', moment().format('yyyy-MM-DD HH:mm:ss'))
    for (let item of refreshList.value) {
        if (item.nameCn === '材料采购') {
            if (modules.value.some(x => x.nameCn === '采购' || x.nameCn === '仓库')) {
                await refreshItem(item)
            }
        } else if (item.nameCn === '客户' || item.nameCn === '客户价格' || item.nameCn === '医生' || item.nameCn === '私人价格') {
            if (modules.value.some(x => x.nameCn === '器械' || x.nameCn === '客户' || x.nameCn === '订单' || x.nameCn === '统计')) {
                await refreshItem(item)
            }
        } else {
            await refreshItem(item)
        }
    }
}
const refreshItem = async item => {
    await refreshStoreItem(item.id)
    item.result = '完成'
}
const refreshStoreItem = async storeName => {
    if (storeName === factoryWebStoreEnum.hospitalList) {
        await updateStoreHospitalDetail()
    } else if (storeName === factoryWebStoreEnum.productList) {
        await updateStoreProductDetail()
    } else if (storeName === factoryWebStoreEnum.stepList) {
        await updateStepListStore()
    } else if (storeName === factoryWebStoreEnum.doctorList) {
        await updateStoreDoctorList()
    } else if (storeName === factoryWebStoreEnum.productType) {
        await updateStoreProductType()
    } else if (storeName === factoryWebStoreEnum.materialType) {
        await updateStoreMaterialType()
    } else if (storeName === factoryWebStoreEnum.materialList) {
        await updateStoreMaterial()
    } else if (storeName === factoryWebStoreEnum.workerList) {
        await updateStoreWorker()
    } else if (storeName === factoryWebStoreEnum.dataValueList) {
        await getAllDataValueList()
    } else {
        // storeName === factoryWebStoreEnum.factorySetting
        // || storeName === factoryWebStoreEnum.toothColorList || storeName === factoryWebStoreEnum.productTestTemplate || storeName === factoryWebStoreEnum.agentList
        // || storeName === factoryWebStoreEnum.plantRetention || storeName === factoryWebStoreEnum.plantSystem
        updateFactoryStoreLoading.value = true
        updateFactoryStoreType.value = storeName
        await updateFactoryStore()
        updateFactoryStoreLoading.value = false
    }
}
//刷新缓存数据
const refreshStoreLoading = ref(false)
const refreshStore = async () => {
    refreshStoreLoading.value = true
    await refreshAll()
    ElMessage.success('完成刷新！')
    refreshStoreLoading.value = false
    isRouterAlive.value = false
    await nextTick(() => {
        isRouterAlive.value = true
    })
}

/**
 * 跳转到某个页面
 * @param nameEn
 */
function setPage(nameEn) {
    if (route.name !== nameEn) {
        toPage(nameEn)
        setMenu(nameEn)
    }
}

/**
 * 修改menu
 * @param nameEn
 */
function setMenu(nameEn) {
    if (!modules.value || !modules.value.length) return
    let page = null
    let menuOne = null
    if (nameEn === 'welcome') {
        // 首页
        defaultMenu.value = ''
        addPageTab('首页', '', nameEn)
        return
    }
    for (let module of modules.value) {
        page = hasPage(nameEn, module)
        if (page) {
            menuOne = module
            break
        }
    }
    if (!page || !menuOne) return
    if (page.moduleLevel === 4) {
        addPageTab(page.parentNameCn, menuOne.nameCn, page.nameEn)
    } else {
        addPageTab(page.nameCn, menuOne.nameCn, page.nameEn)
    }
    defaultMenu.value = menuOne.nameEn
}

provide('setPage', setPage)
provide('setMenu', setMenu)

function hasPage(nameEn, module) {
    if (!module.children || !module.children.length) return false
    if (module.children.some(x => x.nameEn === nameEn)) return module.children.find(x => x.nameEn === nameEn)
    for (let child of module.children) {
        let page = hasPage(nameEn, child)
        if (page) {
            if (child.moduleType === moduleTypeEnum.页面) {
                return child
            } else {
                return page
            }
        }
    }
    return false
}

import {useGetOssToken} from "@/use/system/useUpload";
import {useGetPublishFactoryNotice} from "@/use/system/useFactoryNotice";

const {
    getOssToken,
    ossToken
} = useGetOssToken()
</script>
<style lang="scss">
.home-menu {
    min-height: 100%;
    border-right: none !important;
}

.active-menu-text {
    font-size: 14px !important;
    color: #bbd3ff !important;
    background-color: #003ba7;

    &:hover {
        background-color: #003ba7 !important;
    }
}

.default-menu-text {
    font-size: 14px !important;

    &:hover {
        background-color: #003ba7 !important;
    }
}

.active-page-text {
    font-size: 13px !important;
}

.default-page-text {
    font-size: 13px !important;

    &:hover {
        color: white;
        background-color: #4862A3;
    }
}

.home-main {
    padding: 0 5px !important;
    //background-color: #f2f5fb;
    background-color: #f5f7fa;
}

.el-menu--horizontal {
    border-bottom: none !important;
}

.header-menu {

    .el-menu-item {
        line-height: 50px;
        font-size: 14px;
    }
}

.message-badge .el-badge__content {
    margin-top: 0px;
}

.popper-menu {
    padding: 0 !important;
}

.page-tabs {
    height: 39px;
    background-color: #f0f4fc;
    color: #606060;

    .el-tabs__nav {
        border: none !important;
    }

    .el-tabs__item {
        border: none !important;
        color: #606060;
        font-size: 12px;

        &.is-active {
            color: #003ba7;
        }
    }
}

.menu-text {
    color: #F3C15B !important;

    &:hover {
        color: #F3C15B !important;
        background-color: var(--el-fill-color-dark);
    }
}

.logo-menu-item {
    background: url("../assets/logo-name-white.png") no-repeat center;
    background-size: 90% 39%;
}

.page-select {
    .el-select__wrapper {
        background-color: #E9F1FF !important;
    }

    .el-input__wrapper {
        background-color: #E9F1FF !important;
    }

    .el-select__input {
        color: #999999 !important;
    }

    .el-select__placeholder {
        color: #999999 !important;
    }
}
</style>