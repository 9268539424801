import {reactive, ref, inject, toRaw} from 'vue'
import {
    facHospitalPriceSave, facHospitalPriceSavePrice, facHospitalPriceIncrease,
    facHospitalPriceGetList, facHospitalPriceCopy, facHospitalPriceGetStoreList,
    hospitalProductPriceGetList,
    facHospitalPriceGetPage, facHospitalPriceExcel
} from '@/api/customer'
import {ElMessage, ElNotification} from "element-plus";
import {useDownload} from "@/use/system/useFile";
import http from "@/util/axios";

/**
 * 获取所有客户价格
 * @returns {{updateStoreHospitalPriceList: ((function(): Promise<void>)|*), updateStoreHospitalPriceListLoading: *}}
 */
function useGetAllHospitalPriceList() {
    const allHospitalPriceList = ref([])
    const getAllHospitalPriceListLoading = ref(false)
    const getAllHospitalPriceList = async () => {
        if (getAllHospitalPriceListLoading.value) return
        getAllHospitalPriceListLoading.value = true
        let res = await facHospitalPriceGetStoreList()
        allHospitalPriceList.value = res ? res : []
        getAllHospitalPriceListLoading.value = false
    }
    return {
        allHospitalPriceList,
        getAllHospitalPriceList,
        getAllHospitalPriceListLoading
    }
}

/**
 * 获取某个医院的产品价格和打印信息
 * @returns {{getHospitalProductPriceList: ((function(*): Promise<void>)|*), hospitalProductList: *, getHospitalProductPriceListLoading: *}}
 */
function useGetHospitalProductPriceList() {
    const hospitalProductList = ref([])
    const getHospitalProductPriceListLoading = ref(false)
    const getHospitalProductPriceList = async hospitalId => {
        if (getHospitalProductPriceListLoading.value) return
        getHospitalProductPriceListLoading.value = true
        let res = await hospitalProductPriceGetList({id: hospitalId})
        hospitalProductList.value = res ? res : []
        getHospitalProductPriceListLoading.value = false
    }
    return {
        hospitalProductList,
        getHospitalProductPriceListLoading,
        getHospitalProductPriceList
    }
}

function useSaveHospitalPrice() {
    const saveHospitalPriceParam = reactive({
        id: 0,
        facHospitalId: null,
        productId: null,
        salesPrice: null
    })
    const saveHospitalPriceResult = ref(false)
    const saveHospitalPriceLoading = ref(false)
    const saveHospitalPrice = async () => {
        saveHospitalPriceResult.value = false
        if (saveHospitalPriceLoading.value) return
        saveHospitalPriceLoading.value = true
        saveHospitalPriceResult.value = await facHospitalPriceSavePrice(saveHospitalPriceParam)
        saveHospitalPriceLoading.value = false
    }
    return {
        saveHospitalPriceParam,
        saveHospitalPriceResult,
        saveHospitalPriceLoading,
        saveHospitalPrice,
    }
}

//保存医院价格 oycm 2022/7/31
function useSaveFacHospitalPrice() {
    const facHospitalPrice = reactive({
        setPrice: false,
        id: 0,
        facHospitalId: '',
        productId: '',
        salesPrice: '',
        printAlias: '',
        serialNo: '',
        productAlias: '',
        customerProductNo: '',
        remark: '',
        isPrintWarranty: 1,
        warrantYear: '',
        warrantType: '',
    })
    const facHospitalPriceEmpty = Object.assign({}, facHospitalPrice)
    const initFacHospitalPrice = () => {
        Object.assign(facHospitalPrice, facHospitalPriceEmpty)
    }
    const validateFacHospitalPrice = () => {
        if (!facHospitalPrice.facHospitalId) {
            ElMessage.error('请选择客户')
            return false
        }
        if (!facHospitalPrice.productId) {
            ElMessage.error('请选择产品')
            return false
        }
        return true
    }
    const saveFacHospitalPriceLoading = ref(false)
    const saveFacHospitalPriceResult = ref(false)
    const saveFacHospitalPrice = async () => {
        let validResult = validateFacHospitalPrice()
        if (!validResult) return
        saveFacHospitalPriceResult.value = false
        if (saveFacHospitalPriceLoading.value) return
        saveFacHospitalPriceLoading.value = true
        try {
            let res = await facHospitalPriceSave(facHospitalPrice)
            if (res.code === 0) {
                facHospitalPrice.id = res.data
                saveFacHospitalPriceResult.value = true
                ElNotification.success('保存成功')
            } else {
                ElMessage.error(res.message)
            }
        } catch (e) {
            ElMessage.error('请求错误，请检查网络或者联系客服')
        }
        saveFacHospitalPriceLoading.value = false
    }
    return {
        facHospitalPrice,
        initFacHospitalPrice,
        saveFacHospitalPriceLoading,
        saveFacHospitalPriceResult,
        saveFacHospitalPrice,
    }
}

//列表医院价格 oycm 2022/7/31
function useGetListFacHospitalPrice() {
    const getListFacHospitalPriceLoading = ref(false)
    const facHospitalPriceList = ref([])
    const getListFacHospitalPrice = async facHospitalId => {
        if (getListFacHospitalPriceLoading.value) return
        getListFacHospitalPriceLoading.value = true
        let data = await facHospitalPriceGetList({id: facHospitalId})
        if (data) {
            facHospitalPriceList.value = data
        }
        getListFacHospitalPriceLoading.value = false
    }
    return {
        getListFacHospitalPriceLoading,
        facHospitalPriceList,
        getListFacHospitalPrice,
    }
}

function useCopyFacHospitalPrice() {
    const copyFacHospitalPriceParam = reactive({
        sourceHospitalId: null,
        targetHospitalId: ''
    })
    const validParam = () => {
        return !!copyFacHospitalPriceParam.sourceHospitalId && !!copyFacHospitalPriceParam.targetHospitalId
            && copyFacHospitalPriceParam.targetHospitalId !== copyFacHospitalPriceParam.sourceHospitalId
    }
    const copyFacHospitalPriceLoading = ref(false)
    const copyFacHospitalPriceResult = ref(false)
    const copyFacHospitalPrice = async () => {
        if (copyFacHospitalPriceLoading.value) return
        if (!validParam()) {
            ElMessage.warning('请选择不同的复制源和复制目标')
            return
        }
        copyFacHospitalPriceResult.value = false
        copyFacHospitalPriceLoading.value = true
        copyFacHospitalPriceResult.value = await facHospitalPriceCopy(copyFacHospitalPriceParam)
        copyFacHospitalPriceLoading.value = false
    }
    return {
        copyFacHospitalPriceParam,
        copyFacHospitalPriceLoading,
        copyFacHospitalPriceResult,
        copyFacHospitalPrice,
    }
}

function useGetPageHospitalPrice() {
    const getPageHospitalPriceParam = reactive({
        facHospitalId: null,
        productId: null,
        priceOrder: null,
        pageIndex: 1,
        pageSize: 10,
    })
    const getPageHospitalPriceParamEmpty = Object.assign({}, getPageHospitalPriceParam)
    const initGetPageHospitalPriceParam = () => {
        Object.assign(getPageHospitalPriceParam, getPageHospitalPriceParamEmpty)
    }
    const getPageHospitalPriceLoading = ref(false)
    const hospitalPricePage = ref([])
    const getPageHospitalPriceResult = reactive({
        totalCount: 0,
        totalPages: 0,
    })
    const getPageHospitalPrice = async () => {
        if (getPageHospitalPriceLoading.value) return
        getPageHospitalPriceLoading.value = true
        let data = await facHospitalPriceGetPage(getPageHospitalPriceParam)
        if (data && data.list && data.list.length) {
            hospitalPricePage.value = data.list
            getPageHospitalPriceResult.totalPages = Math.ceil(data.totalCount / getPageHospitalPriceParam.pageSize)
            getPageHospitalPriceResult.totalCount = data.totalCount
        } else {
            hospitalPricePage.value = []
            getPageHospitalPriceResult.totalPages = 0
            getPageHospitalPriceResult.totalCount = 0
        }
        getPageHospitalPriceLoading.value = false
    }
    return {
        getPageHospitalPriceParam,
        initGetPageHospitalPriceParam,
        getPageHospitalPriceLoading,
        hospitalPricePage,
        getPageHospitalPriceResult,
        getPageHospitalPrice,
    }
}

//批量涨价
function useIncreaseFacHospitalPrice() {
    const increaseFacHospitalPriceParam = reactive({
        facHospitalId: null,
        discountPercent: null,
        fixNum: 2,
    })
    const validParam = () => {
        return !!increaseFacHospitalPriceParam.discountPercent
    }
    const increaseFacHospitalPriceLoading = ref(false)
    const increaseFacHospitalPriceResult = ref(false)
    const increaseFacHospitalPrice = async () => {
        if (increaseFacHospitalPriceLoading.value) return
        if (!validParam()) {
            ElMessage.warning('请输入正确的涨价比例')
            return
        }
        increaseFacHospitalPriceResult.value = false
        increaseFacHospitalPriceLoading.value = true
        increaseFacHospitalPriceResult.value = await facHospitalPriceIncrease(increaseFacHospitalPriceParam)
        increaseFacHospitalPriceLoading.value = false
    }
    return {
        increaseFacHospitalPriceParam,
        increaseFacHospitalPriceLoading,
        increaseFacHospitalPriceResult,
        increaseFacHospitalPrice,
    }
}

// 导出医院价格列表
const useExportHospitalPriceExcel = () => {
    const {
        downFileLoading,
        downFile
    } = useDownload()
    const exportHospitalPriceExcel = req => {
        if (!req.facHospitalId && !req.productId) {
            ElMessage.error('请至少选择一个搜索条件')
            return
        }
        req.token = sessionStorage.getItem('token')
        let url = facHospitalPriceExcel + '?' + http.getQueryFromJson(req)
        downFile(url, '客户价格列表')
    }
    return {
        exportHospitalPriceExcelLoading: downFileLoading,
        exportHospitalPriceExcel
    }
}

export {
    useGetAllHospitalPriceList,
    useSaveFacHospitalPrice,
    useGetListFacHospitalPrice,
    useCopyFacHospitalPrice,
    useIncreaseFacHospitalPrice,
    useGetPageHospitalPrice,
    useExportHospitalPriceExcel,
    useGetHospitalProductPriceList,
    useSaveHospitalPrice,
}