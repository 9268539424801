import {createStore} from 'vuex'

export default createStore({
    state: {
        userName: sessionStorage.getItem('userName'),
        factoryId: localStorage.getItem('factoryId'),
        phoneNo: sessionStorage.getItem('phoneNo'),
        token: sessionStorage.getItem('token'),
        pageList: sessionStorage.getItem('pageList') ? JSON.parse(sessionStorage.getItem('pageList')) : [],//当前的多个页面
        curPageName: sessionStorage.getItem('curPageName'),//当前正在浏览的页面
        auth: sessionStorage.getItem('auth'),//权限
        funcAuth: sessionStorage.getItem('funcAuth'),//功能权限
        factoryName: sessionStorage.getItem('factoryName'),//工厂名称
        updateStoreTime: localStorage.getItem('updateStoreTime'),//工厂名称
        dataValueList: sessionStorage.getItem('dataValueList') ? JSON.parse(sessionStorage.getItem('dataValueList')) : [],//数据字典
        workerList: localStorage.getItem('workerList') ? JSON.parse(localStorage.getItem('workerList')) : [],//员工列表
        currentWorker: localStorage.getItem('currentWorker') ? JSON.parse(localStorage.getItem('currentWorker')) : null,//当前员工
        hospitalList: localStorage.getItem('hospitalList') ? JSON.parse(localStorage.getItem('hospitalList')) : [],//医院列表
        hospitalDetails: localStorage.getItem('hospitalDetails') ? JSON.parse(localStorage.getItem('hospitalDetails')) : [],//医院详情列表
        materialList: localStorage.getItem('materialList') ? JSON.parse(localStorage.getItem('materialList')) : [],//材料列表
        productList: localStorage.getItem('productList') ? JSON.parse(localStorage.getItem('productList')) : [],//产品列表
        productRegisterList: localStorage.getItem('productRegisterList') ? JSON.parse(localStorage.getItem('productRegisterList')) : [],//首营产品列表
        productDetails: localStorage.getItem('productDetails') ? JSON.parse(localStorage.getItem('productDetails')) : [],//产品详情列表
        doctorList: localStorage.getItem('doctorList') ? JSON.parse(localStorage.getItem('doctorList')) : [],//医生列表
        materialSupplyList: sessionStorage.getItem('materialSupplyList') ? JSON.parse(sessionStorage.getItem('materialSupplyList')) : [],//材料与供应商关联信息
        productTestTemplate: sessionStorage.getItem('productTestTemplate') ? JSON.parse(sessionStorage.getItem('productTestTemplate')) : [],//产品质检模板
        unreadNum: sessionStorage.getItem('unreadNum'),//未读消息数量
        factorySetting: sessionStorage.getItem('factorySetting'),//工厂设置
        toothColorList: sessionStorage.getItem('toothColorList') ? JSON.parse(sessionStorage.getItem('toothColorList')) : [],//牙色
        agentList: sessionStorage.getItem('agentList') ? JSON.parse(sessionStorage.getItem('agentList')) : [],//代理
        stepList: sessionStorage.getItem('stepList') ? JSON.parse(sessionStorage.getItem('stepList')) : [],//工序
        caseOutScanSetting: localStorage.getItem('caseOutScanSetting') ? JSON.parse(localStorage.getItem('caseOutScanSetting')) : {},//扫描出货设置
        casePackOutSetting: localStorage.getItem('casePackOutSetting') ? JSON.parse(localStorage.getItem('casePackOutSetting')) : {},//装箱出货设置
        liangtianSetting: localStorage.getItem('liangtianSetting') ? JSON.parse(localStorage.getItem('liangtianSetting')) : {},//良田高拍仪设置
        casePrinterList: localStorage.getItem('casePrinterList') ? JSON.parse(localStorage.getItem('casePrinterList')) : [],//打印机设置
        plantRetentionList: sessionStorage.getItem('plantRetentionList') ? JSON.parse(sessionStorage.getItem('plantRetentionList')) : [],//打印机设置
        plantSystemList: sessionStorage.getItem('plantSystemList') ? JSON.parse(sessionStorage.getItem('plantSystemList')) : [],//打印机设置
        hospitalCount: localStorage.getItem('hospitalCount') ? JSON.parse(localStorage.getItem('hospitalCount')) : [],
        doctorCount: localStorage.getItem('doctorCount') ? JSON.parse(localStorage.getItem('doctorCount')) : [],
        productCount: localStorage.getItem('productCount') ? JSON.parse(localStorage.getItem('productCount')) : [],
        materialCount: localStorage.getItem('materialCount') ? JSON.parse(localStorage.getItem('materialCount')) : [],
        purchase: sessionStorage.getItem('purchase') ? JSON.parse(sessionStorage.getItem('purchase')) : null,
        purchaseApply: sessionStorage.getItem('purchaseApply') ? JSON.parse(sessionStorage.getItem('purchaseApply')) : null,
        productPurchaseApply: sessionStorage.getItem('productPurchaseApply') ? JSON.parse(sessionStorage.getItem('productPurchaseApply')) : null,
        stockApply: sessionStorage.getItem('stockApply') ? JSON.parse(sessionStorage.getItem('stockApply')) : null,
        productStockApply: sessionStorage.getItem('productStockApply') ? JSON.parse(sessionStorage.getItem('productStockApply')) : null,
        hospitalPriceList: [],
        privatePriceList: [],
        productType: localStorage.getItem('productType') ? JSON.parse(localStorage.getItem('productType')) : [],//产品类型
        materialType: localStorage.getItem('materialType') ? JSON.parse(localStorage.getItem('materialType')) : [],//材料类型
        lastProductIds: sessionStorage.getItem('lastProductIds') ? JSON.parse(sessionStorage.getItem('lastProductIds')) : [],//上次录单的产品
        lastMaterialIds: sessionStorage.getItem('lastMaterialIds') ? JSON.parse(sessionStorage.getItem('lastMaterialIds')) : [],//上次录单的材料
        caseSearchCondition: localStorage.getItem('caseSearchCondition') ? JSON.parse(localStorage.getItem('caseSearchCondition')) : [],//订单列表搜索条件
        caseProductSearchCondition: localStorage.getItem('caseProductSearchCondition') ? JSON.parse(localStorage.getItem('caseProductSearchCondition')) : [],//订单明细列表搜索条件
        visitHistory: localStorage.getItem('visitHistory') ? JSON.parse(localStorage.getItem('visitHistory')) : [],//待上传记录
        ossToken: localStorage.getItem('ossToken') ? JSON.parse(localStorage.getItem('ossToken')) : {},//待上传记录
        financeStat: {},//订单财务统计
        purchaseStat: {},//材料采购统计
        productPurchaseStat: {},//产品采购统计
        stockInStat: {},//入库统计
        stockOutStat: {},//出库统计
        productStockInStat: {},//器械入库统计
        productStockOutStat: {},//器械出库统计
        monthExcelName: sessionStorage.getItem('monthExcelName'),//对账单别名
        factoryNotice: sessionStorage.getItem('factoryNotice'),//最近发布工厂公告
        sfCache: localStorage.getItem('sfCache') ? JSON.parse(localStorage.getItem('sfCache')) : {},//顺丰下单
    },
    getters: {},
    mutations: {
        setSfCache(state, val) {
            state.sfCache = val
            sessionStorage.setItem('sfCache', val)
        },
        setFactoryNotice(state, val) {
            state.factoryNotice = val
            sessionStorage.setItem('factoryNotice', val)
        },
        setStockInStat(state, val) {
            state.stockInStat = val
        },
        setStockOutStat(state, val) {
            state.stockOutStat = val
        },
        setMonthExcelName(state, val) {
            state.monthExcelName = val
        },
        setFinanceStat(state, val) {
            state.financeStat = val
        },
        setPurchaseStat(state, val) {
            state.purchaseStat = val
        },
        setProductPurchaseStat(state, val) {
            state.productPurchaseStat = val
        },
        setProductStockInStat(state, val) {
            state.productStockInStat = val
        },
        setProductStockOutStat(state, val) {
            state.productStockOutStat = val
        },
        setOssToken(state, val) {
            state.ossToken = val
            localStorage.setItem('ossToken', JSON.stringify(val))
        },
        setUpdateStoreTime(state, val) {
            state.updateStoreTime = val
            localStorage.setItem('updateStoreTime', val)
        },
        setFactoryId(state, val) {
            state.factoryId = val
            localStorage.setItem('factoryId', val)
        },
        setLastMaterialIds(state, val) {
            if (!val || !val.length) return
            state.lastMaterialIds = val
            sessionStorage.setItem('lastMaterialIds', JSON.stringify(val))
            for (let materialId of val) {
                if (state.materialCount.length) {
                    let item = state.materialCount.find(x => x.materialId === materialId)
                    if (item) {
                        item.count = item.count + 1
                    } else {
                        state.materialCount.push({
                            materialId: materialId,
                            count: 1
                        })
                    }
                } else {
                    state.materialCount.push({
                        materialId: materialId,
                        count: 1
                    })
                }
                let material = state.materialList.find(x => x.id === materialId)
                material && (material.count = material.count + 1)
            }
            localStorage.setItem('materialCount', JSON.stringify(state.materialCount))
            localStorage.setItem('materialList', JSON.stringify(state.materialList))
        },
        setMaterialType(state, val) {
            state.materialType = val
            localStorage.setItem('materialType', JSON.stringify(val))
        },
        setLastProductIds(state, val) {
            if (!val || !val.length) return
            state.lastProductIds = val
            sessionStorage.setItem('lastProductIds', JSON.stringify(val))
            for (let productId of val) {
                if (state.productCount.length) {
                    let item = state.productCount.find(x => x.productId === productId)
                    if (item) {
                        item.count = item.count + 1
                    } else {
                        state.productCount.push({
                            productId: productId,
                            count: 1
                        })
                    }
                } else {
                    state.productCount.push({
                        productId: productId,
                        count: 1
                    })
                }
                let product = state.productList.find(x => x.id === productId)
                product && (product.count = product.count + 1)
            }
            localStorage.setItem('productCount', JSON.stringify(state.productCount))
            localStorage.setItem('productList', JSON.stringify(state.productList))
        },
        setProductType(state, val) {
            state.productType = val
            localStorage.setItem('productType', JSON.stringify(val))
        },
        setPrivatePriceList(state, val) {
            state.privatePriceList = val
        },
        setHospitalPriceList(state, val) {
            state.hospitalPriceList = val
        },
        setPurchase(state, val) {
            state.purchase = val
            sessionStorage.setItem('purchase', JSON.stringify(val))
        },
        setPurchaseApply(state, val) {
            state.purchaseApply = val
            sessionStorage.setItem('purchaseApply', JSON.stringify(val))
        },
        setProductPurchase(state, val) {
            state.productPurchase = val
            sessionStorage.setItem('productPurchase', JSON.stringify(val))
        },
        setProductPurchaseApply(state, val) {
            state.productPurchaseApply = val
            sessionStorage.setItem('productPurchaseApply', JSON.stringify(val))
        },
        setStockApply(state, val) {
            state.stockApply = val
            sessionStorage.setItem('stockApply', JSON.stringify(val))
        },
        setProductStockApply(state, val) {
            state.productStockApply = val
            sessionStorage.setItem('productStockApply', JSON.stringify(val))
        },
        addHospitalCount(state, hospitalId) {
            if (state.hospitalCount.length) {
                let item = state.hospitalCount.find(x => x.hospitalId === hospitalId)
                if (item) {
                    item.count = item.count + 1
                } else {
                    state.hospitalCount.push({
                        hospitalId: hospitalId,
                        count: 1
                    })
                }
            } else {
                state.hospitalCount.push({
                    hospitalId: hospitalId,
                    count: 1
                })
            }
            let hospital = state.hospitalList.find(x => x.id === hospitalId)
            hospital && (hospital.count = hospital.count + 1)
            localStorage.setItem('hospitalCount', JSON.stringify(state.hospitalCount))
            localStorage.setItem('hospitalList', JSON.stringify(state.hospitalList))
        },
        addDoctorCount(state, doctorId) {
            if (state.doctorCount.length) {
                let item = state.doctorCount.find(x => x.doctorId === doctorId)
                if (item) {
                    item.count = item.count + 1
                } else {
                    state.doctorCount.push({
                        doctorId: doctorId,
                        count: 1
                    })
                }
            } else {
                state.doctorCount.push({
                    doctorId: doctorId,
                    count: 1
                })
            }
            let doctor = state.doctorList.find(x => x.id === doctorId)
            doctor && (doctor.count = doctor.count + 1)
            localStorage.setItem('doctorCount', JSON.stringify(state.doctorCount))
            localStorage.setItem('doctorList', JSON.stringify(state.doctorList))
        },
        setCasePrinterList(state, val) {
            state.casePrinterList = val
            localStorage.setItem('casePrinterList', JSON.stringify(val))
        },
        setLiangtianSetting(state, val) {
            state.liangtianSetting = val
            localStorage.setItem('liangtianSetting', JSON.stringify(val))
        },
        setCasePackOutSetting(state, val) {
            state.casePackOutSetting = val
            localStorage.setItem('casePackOutSetting', JSON.stringify(val))
        },
        setCaseOutScanSetting(state, val) {
            state.caseOutScanSetting = val
            localStorage.setItem('caseOutScanSetting', JSON.stringify(val))
        },
        setUserName(state, val) {
            state.userName = val
            sessionStorage.setItem('userName', val)
        },
        setToken(state, val) {
            state.token = val
            sessionStorage.setItem('token', val)
        },
        setCurPageName(state, val) {
            state.curPageName = val
            sessionStorage.setItem('curPageName', val)
        },
        setPageList(state, val) {
            state.pageList = val
            sessionStorage.setItem('pageList', JSON.stringify(val))
        },
        setPhoneNo(state, val) {
            state.phoneNo = val
            sessionStorage.setItem('phoneNo', val)
        },
        setAuth(state, val) {
            let auth = JSON.stringify(val)
            state.auth = auth
            sessionStorage.setItem('auth', auth)
        },
        setFuncAuth(state, val) {
            let funcAuth = JSON.stringify(val)
            state.funcAuth = funcAuth
            sessionStorage.setItem('funcAuth', funcAuth)
        },
        setFactoryName(state, val) {
            state.factoryName = val
            sessionStorage.setItem('factoryName', val)
        },
        setDataValueList(state, val) {
            state.dataValueList = val
            sessionStorage.setItem('dataValueList', JSON.stringify(val))
        },
        setWorkerList(state, val) {
            state.workerList = val
            localStorage.setItem('workerList', JSON.stringify(val))
        },
        setCurrentWorker(state, val) {
            state.currentWorker = val
            localStorage.setItem('currentWorker', JSON.stringify(val))
        },
        setHospitalList(state, val) {
            state.hospitalList = val
            localStorage.setItem('hospitalList', JSON.stringify(val))
        },
        setHospitalDetails(state, val) {
            state.hospitalDetails = val
            localStorage.setItem('hospitalDetails', JSON.stringify(val))
        },
        setDoctorList(state, val) {
            state.doctorList = val
            localStorage.setItem('doctorList', JSON.stringify(val))
        },
        setMaterialList(state, val) {
            state.materialList = val
            localStorage.setItem('materialList', JSON.stringify(val))
        },
        setProductList(state, val) {
            state.productList = val
            localStorage.setItem('productList', JSON.stringify(val))
        },
        setProductRegisterList(state, val) {
            state.productRegisterList = val
            localStorage.setItem('productRegisterList', JSON.stringify(val))
        },
        setProductDetails(state, val) {
            state.productDetails = val
            localStorage.setItem('productDetails', JSON.stringify(val))
        },
        setMaterialSupplyList(state, val) {
            state.materialSupplyList = val
            sessionStorage.setItem('materialSupplyList', JSON.stringify(val))
        },
        setProductTestTemplate(state, val) {
            state.productTestTemplate = val
            sessionStorage.setItem('productTestTemplate', JSON.stringify(val))
        },
        setUnreadNum(state, val) {
            state.unreadNum = val
            sessionStorage.setItem('unreadNum', val)
        },
        setFactorySetting(state, val) {
            state.factorySetting = val
            sessionStorage.setItem('factorySetting', val)
        },
        setToothColorList(state, val) {
            state.toothColorList = val
            sessionStorage.setItem('toothColorList', JSON.stringify(val))
        },
        setAgentList(state, val) {
            state.agentList = val
            sessionStorage.setItem('agentList', JSON.stringify(val))
        },
        setStepList(state, val) {
            state.stepList = val
            sessionStorage.setItem('stepList', JSON.stringify(val))
        },
        setPlantRetentionList(state, val) {
            state.plantRetentionList = val
            sessionStorage.setItem('plantRetentionList', JSON.stringify(val))
        },
        setPlantSystemList(state, val) {
            state.plantSystemList = val
            sessionStorage.setItem('plantSystemList', JSON.stringify(val))
        },
        addCaseSearchCondition(state, val) {
            let caseSearchCondition = state.caseSearchCondition
            if (caseSearchCondition && caseSearchCondition.length) {
                let item = caseSearchCondition.find(x => x.name === val.name)
                if (item) {
                    item.condition = val.condition
                } else {
                    caseSearchCondition.push(val)
                }
            } else {
                caseSearchCondition = []
                caseSearchCondition.push(val)
            }
            state.caseSearchCondition = caseSearchCondition
            localStorage.setItem('caseSearchCondition', JSON.stringify(caseSearchCondition))
        },
        removeCaseSearchCondition(state, val) {
            let caseSearchCondition = state.caseSearchCondition
            let index = caseSearchCondition.findIndex(x => x.name === val.name)
            if (index >= 0) {
                caseSearchCondition.splice(index, 1)
                state.caseSearchCondition = caseSearchCondition
                localStorage.setItem('caseSearchCondition', JSON.stringify(caseSearchCondition))
            }
        },
        addCaseProductSearchCondition(state, val) {
            let caseProductSearchCondition = state.caseProductSearchCondition
            if (caseProductSearchCondition && caseProductSearchCondition.length) {
                let item = caseProductSearchCondition.find(x => x.name === val.name)
                if (item) {
                    item.condition = val.condition
                } else {
                    caseProductSearchCondition.push(val)
                }
            } else {
                caseProductSearchCondition = []
                caseProductSearchCondition.push(val)
            }
            state.caseProductSearchCondition = caseProductSearchCondition
            localStorage.setItem('caseProductSearchCondition', JSON.stringify(caseProductSearchCondition))
        },
        removeCaseProductSearchCondition(state, val) {
            let caseProductSearchCondition = state.caseProductSearchCondition
            let index = caseProductSearchCondition.findIndex(x => x.name === val.name)
            if (index >= 0) {
                caseProductSearchCondition.splice(index, 1)
                state.caseProductSearchCondition = caseProductSearchCondition
                localStorage.setItem('caseProductSearchCondition', JSON.stringify(caseProductSearchCondition))
            }
        },
        addVisitHistory(state, val) {
            if (!state.visitHistory || !state.visitHistory.length) state.visitHistory = []
            state.visitHistory.push(val)
            localStorage.setItem('visitHistory', JSON.stringify(state.visitHistory))
        },
        clearVisitHistory(state, val) {
            state.visitHistory = []
            localStorage.setItem('visitHistory', JSON.stringify(state.visitHistory))
        },
    },
    actions: {},
    modules: {}
})