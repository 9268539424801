import {usePrintTemplate} from "@/use/case/useCasePrintTemplate";
import {ElMessage} from "element-plus";
import moment from "moment";
import {getCaseMakeTypeStr} from "@/use/case/useCase";
import {
    printCaseItems,
    printCommonItems,
    printCaseProductItems,
    printFactorySettingItem,
    printCaseExtensionItems
} from "@/util/printUtil";
import {useStore} from "vuex";
import {hiprint} from "vue-plugin-hiprint";
import {moneyToChinese} from "@/util/common";

const sumFunc = (a, b) => a + b

function usePrintCaseBarcode() {
    const {
        getPrintPanel,
        setPrintText,
    } = usePrintTemplate()

    /**
     * 获取订单条码打印
     * @param barcodeTemplate
     * @param caseInfo
     * @param storeHospitalDetails
     * @param hiprint
     * @returns {{template: hiprint.PrintTemplate, data: {patientName: (string|[{trigger: string, message: string, required: boolean}]|*), facHospitalName: (*|string), caseNo}}}
     */
    function printCaseBarcode(barcodeTemplate, caseInfo, storeHospitalDetails, hiprint) {
        let hiprintTemplate = new hiprint.PrintTemplate();
        let printPanel = getPrintPanel(barcodeTemplate)
        printPanel.paperNumberDisabled = true
        let panel = hiprintTemplate.addPrintPanel(printPanel);
        let hospital = storeHospitalDetails.value.find(x => x.id === caseInfo.facHospitalId)
        let printData = {
            caseNo: caseInfo.caseNo,
            facHospitalName: hospital?.name ?? '',
            patientName: caseInfo.patientName
        }
        setPrintText(panel, barcodeTemplate)
        let res = {
            template: hiprintTemplate,
            data: printData
        }
        setTemplatePrinter(res, barcodeTemplate)
        return res
    }

    return {
        printCaseBarcode
    }
}

function usePrintWarranty() {
    const {
        getPrintPanel,
        setPrintText,
        setPrintTable
    } = usePrintTemplate()
    const getDefaultWarrantyId = (storeProductDetail, warrantyTemplateList) => {
        if (storeProductDetail) {
            //1.产品质保卡模板
            if (!!storeProductDetail.warrantyTemplateId) {
                return storeProductDetail.warrantyTemplateId
            }
            //2.默认质保卡模板
            let defaultTemplate = warrantyTemplateList.value.find(x => !!x.isDefault)
            if (!!defaultTemplate) {
                return defaultTemplate.id
            }
            //3.第一个质保卡模板
            if (!!warrantyTemplateList.value.length) {
                return warrantyTemplateList.value[0].id
            }
        }
        return 0
    }

    /**
     * 获取质保卡打印
     * @param caseInfo 订单详情
     * @param productId 要打印的产品id
     * @param warrantyTemplate 打印质保卡模板
     * @param hiprint hiprint
     * @param productDetails 缓存产品详情列表
     * @param factorySetting 缓存工厂设置
     */
    function printWarranty(caseInfo, productId, warrantyTemplate, hiprint, productDetails, factorySetting) {
        let hiprintTemplate = new hiprint.PrintTemplate();
        let printPanel = getPrintPanel(warrantyTemplate)
        printPanel.paperNumberDisabled = true
        let panel = hiprintTemplate.addPrintPanel(printPanel);

        let caseProduct = caseInfo.productList.find(x => x.productId === productId)
        if (!caseProduct) {
            ElMessage.error('请选择需要打印的产品')
            return
        }
        let productDetail = productDetails.find(x => x.id === productId)
        //质保期限文字
        let warrantText = (productDetail && productDetail.warrantYear) ? (productDetail.warrantYear + productDetail.warrantType) : ''
        let outDate = ''
        if (caseInfo.extension && caseInfo.extension.outDate) {
            outDate = moment(caseInfo.extension.outDate).format('yyyy-MM-DD')
        }
        // 产品名称
        let productName = productDetail?.name ?? ''
        if (caseProduct.printAlias) productName = caseProduct.printAlias
        if (caseProduct.warrantYear) warrantText = caseProduct.warrantYear + (caseProduct.warrantType ? caseProduct.warrantType : '年')
        if (caseProduct.warrantProductName) {
            // 订单录入页面自定义产品打印名称
            productName = caseProduct.warrantProductName
        }
        let barcodeUrlPre = warrantyTemplate.remark ? warrantyTemplate.remark : window.location.origin
        let printData = {
            companyName: factorySetting.fullName,
            caseNo: caseInfo.caseNo,
            productNo: productDetail?.productNo ?? '',
            productName: productName,
            productTypeName: productDetail?.productTypeName ?? '',
            facHospitalNo: caseInfo.facHospitalNo,
            facHospitalName: caseInfo.facHospitalName,
            facDoctorName: caseInfo.facDoctorName,
            patientName: caseInfo.patientName,
            outDate: outDate,
            toothColor: caseProduct.color,
            factoryRemark: caseInfo.factoryRemark,
            warrantYear: warrantText,
            warrantyBarcode: barcodeUrlPre + '/warranty/warrantyHome?caseProductId=' + caseProduct.id,
            pos1: caseProduct.pos1,
            pos2: caseProduct.pos2,
            pos3: caseProduct.pos3,
            pos4: caseProduct.pos4,
            upperJaw: caseProduct.upperJaw,
            lowerJaw: caseProduct.lowerJaw,
            udi: caseProduct.udi,
            udiQrcode: caseProduct.udi,
            regNo: productDetail?.regNo ?? '',
            regName: productDetail?.regName ?? '',
        }
        setPrintText(panel, warrantyTemplate)
        let res = {
            template: hiprintTemplate,
            data: printData
        }
        setTemplatePrinter(res, warrantyTemplate)
        return res
    }

    return {
        getDefaultWarrantyId,
        printWarranty
    }
}

function usePrintConfirm() {
    const {
        getPrintPanel,
        setPrintText,
        setPrintTable
    } = usePrintTemplate()

    function getDefaultConfirmId(confirmTemplateList) {
        let defaultConfirm = confirmTemplateList.value.find(x => !!x.isDefault)
        if (defaultConfirm) {
            return defaultConfirm.id
        } else if (confirmTemplateList.value && confirmTemplateList.value.length) {
            return confirmTemplateList.value[0].id
        }
        return 0
    }

    /**
     * 获取确认单打印
     * @param caseInfo 订单详情
     * @param hiprint
     * @param confirmTemplate 模板
     * @param confirmPrintColumns html表格列
     * @param printConfirmDetailTableRef html表格ref
     */
    function printConfirm(caseInfo, hiprint, confirmTemplate, confirmPrintColumns, printConfirmDetailTableRef, plantPrintColumns, plantPrintTableRef) {
        let hiprintTemplate = new hiprint.PrintTemplate();
        let printPanel = getPrintPanel(confirmTemplate)
        printPanel.paperNumberDisabled = true
        let panel = hiprintTemplate.addPrintPanel(printPanel);
        setPrintText(panel, confirmTemplate)

        let printData = {
            caseNo: caseInfo.caseNo,
            boxNo: caseInfo.boxNo,
            facHospitalName: caseInfo.facHospitalName,
            facDoctorName: caseInfo.facDoctorName,
            patientName: caseInfo.patientName,
            inDate: moment(caseInfo.inDate).format('yyyy-MM-DD'),
            scheduleDeliveryDate: moment(caseInfo.scheduleDeliveryDate).format('yyyy-MM-DD'),
            isUrgent: !!caseInfo.isUrgent ? '加急' : '',
            doctorHabit: caseInfo.doctorHabit,
            stepNote: caseInfo.stepNote,
            factoryRemark: caseInfo.factoryRemark,
            doctorRemark: caseInfo.doctorRemark,
            details: []
        }
        //产品表格中间有十字牙位 需要用html的形式来打印
        let detailTable = confirmTemplate.details.find(x => x.detailField === 'details')
        if (!!detailTable) {
            confirmPrintColumns.value = confirmTemplate.columns.filter(x => !!x.isPrint && x.detailField === 'details')
            if (confirmPrintColumns.value && confirmPrintColumns.value.length) {
                let colTotalWidth = confirmPrintColumns.value.map(x => x.colWidth).reduce((a, b) => a + b)
                for (let col of confirmPrintColumns.value) {
                    col.widthPer = col.colWidth / colTotalWidth * 100 + '%'
                }
            }
            panel.addPrintHtml({
                options: {
                    width: detailTable.posWidth,
                    height: detailTable.posHeight,
                    top: detailTable.posTop,
                    left: detailTable.posLeft,
                    content: printConfirmDetailTableRef.value
                }
            });
        }
        //种植表格中间有十字牙位 需要用html的形式来打印
        let plantTable = confirmTemplate.details.find(x => x.detailField === 'plantList')
        if (!!plantTable && caseInfo.plantList && caseInfo.plantList.length && caseInfo.plantList.some(x => !!x.num)) {
            plantPrintColumns.value = confirmTemplate.columns.filter(x => !!x.isPrint && x.detailField === 'plantList')
            if (plantPrintColumns.value && plantPrintColumns.value.length) {
                let colTotalWidth = plantPrintColumns.value.map(x => x.colWidth).reduce((a, b) => a + b)
                for (let col of plantPrintColumns.value) {
                    col.widthPer = col.colWidth / colTotalWidth * 100 + '%'
                }
            }
            panel.addPrintHtml({
                options: {
                    width: plantTable.posWidth,
                    height: plantTable.posHeight,
                    top: plantTable.posTop,
                    left: plantTable.posLeft,
                    content: plantPrintTableRef.value
                }
            });
        }
        let res = {
            template: hiprintTemplate,
            data: printData,
        }
        setTemplatePrinter(res, confirmTemplate)
        return res
    }

    return {
        getDefaultConfirmId,
        printConfirm
    }
}

function usePrintCaseOut() {
    const {
        getPrintPanel,
        setPrintText,
        setPrintTable
    } = usePrintTemplate()
    /**
     * 获取默认出货单模板
     * @param storeHospitalDetail
     * @param caseOutTemplateList
     * @returns {string|*}
     */
    const getDefaultCaseOutId = (storeHospitalDetail, caseOutTemplateList) => {
        // 1.客户默认出货单模板
        if (storeHospitalDetail && storeHospitalDetail.caseOutTemplateId) {
            return storeHospitalDetail.caseOutTemplateId
        }
        // 2.默认模板
        let defaultCaseOut = caseOutTemplateList.value.find(x => !!x.isDefault)
        if (!!defaultCaseOut) {
            return defaultCaseOut.id
        }
        // 3.第一个模板
        if (caseOutTemplateList.value && caseOutTemplateList.value.length) {
            return caseOutTemplateList.value[0].id
        }
        return 0
    }
    const store = useStore()

    // 如果有产品需要单独打印出货单  那么一个订单需要打印多份出货单！
    function getPrintCaseOutList(caseInfo, factorySetting, storeProductDetails, currentWorkerName, caseOutTemplate, hiprint) {
        let printResList = []
        if (!caseInfo.productList || !caseInfo.productList.length) return printResList
        for (let caseProduct of caseInfo.productList) {
            let product = storeProductDetails.value.find(x => x.id === caseProduct.productId)
            caseProduct.separateCaseOut = (product && product.separateCaseOut) ? 1 : 0
        }
        if (caseInfo.productList.some(x => !!x.separateCaseOut)) {
            for (let caseProduct of caseInfo.productList.filter(x => !!x.separateCaseOut)) {
                let printItem = JSON.parse(JSON.stringify(caseInfo))
                printItem.productList = [caseProduct]
                printItem.totalPrice = printItem.productList.map(x => x.sum).reduce(sumFunc)
                let res = printCaseOut(printItem, factorySetting, storeProductDetails, currentWorkerName, caseOutTemplate, hiprint)
                printResList.push(res)
            }
        }
        if (caseInfo.productList.some(x => !x.separateCaseOut)) {
            let printItem = JSON.parse(JSON.stringify(caseInfo))
            printItem.productList = caseInfo.productList.filter(x => !x.separateCaseOut)
            printItem.totalPrice = printItem.productList.map(x => x.sum).reduce(sumFunc)
            let res = printCaseOut(printItem, factorySetting, storeProductDetails, currentWorkerName, caseOutTemplate, hiprint)
            printResList.push(res)
        }
        return printResList
    }

    /**
     * 获取出货单打印
     * @param caseInfo 订单
     * @param factorySetting 缓存工厂设置
     * @param storeProductDetails 缓存产品列表
     * @param currentWorkerName 当前员工姓名
     * @param caseOutTemplate 出货单模板
     * @param hiprint hiprint
     */
    function printCaseOut(caseInfo, factorySetting, storeProductDetails, currentWorkerName, caseOutTemplate, hiprint) {
        let hiprintTemplate = new hiprint.PrintTemplate();
        let printPanel = getPrintPanel(caseOutTemplate)
        printPanel.paperNumberDisabled = true
        let panel = hiprintTemplate.addPrintPanel(printPanel);
        let salesmanPhoneNo = ''
        if (store.state.workerList) {
            let salesman = store.state.workerList.find(x => x.id === caseInfo.facWorkerId)
            salesman && (salesmanPhoneNo = salesman.phoneNo)
        }
        let facHospitalDeptName = ''
        if (store.state.doctorList) {
            let doctor = store.state.doctorList.find(x => x.id === caseInfo.facDoctorId)
            doctor && (facHospitalDeptName = doctor.facHospitalDeptName)
        }
        let totalPriceChinese = moneyToChinese(caseInfo.totalPrice)
        let text1Val = caseOutTemplate.details.find(x => x.detailField === 'text1')
        let text2Val = caseOutTemplate.details.find(x => x.detailField === 'text2')
        let lotNo = caseInfo.caseNo.startsWith('20') ? caseInfo.caseNo : ('20' + caseInfo.caseNo)
        let printData = {
            companyName: factorySetting.fullName,
            caseNo: caseInfo.caseNo,
            lotNo: lotNo,
            facHospitalName: caseInfo.facHospitalName,
            facDoctorName: caseInfo.facDoctorName,
            patientName: caseInfo.patientName,
            printWorkerName: currentWorkerName,
            salesmanName: caseInfo.facWorkerName,
            salesmanPhoneNo: salesmanPhoneNo,
            servicePhoneNo: factorySetting.servicePhoneNo,
            companyAddress: factorySetting.fullAddress,
            totalPrice: caseInfo.totalPrice,
            printTime: moment().format('yyyy-MM-DD HH:mm:ss'),
            totalPriceChinese: totalPriceChinese,
            facHospitalDeptName: facHospitalDeptName,
            text1: text1Val ? text1Val.fieldName : '',
            text2: text2Val ? text2Val.fieldName : ''
        }
        if (caseOutTemplate.details.some(x => x.detailField === 'productRegNo' && !!x.isPrint)) {
            setProductRegNo(printData, caseInfo, storeProductDetails)
        }
        if (caseOutTemplate.details.some(x => x.detailField === 'details' && !!x.isPrint)) {
            setCaseDetails(printData, caseInfo, storeProductDetails)
        }
        caseOutTemplate.height += (printData.details.length - 3) * 20;
        // 开始设置打印格式
        setPrintText(panel, caseOutTemplate, (printData.details.length - 3) * 20)
        setPrintTable(panel, caseOutTemplate)
        let res = {
            template: hiprintTemplate,
            data: printData,
        }
        console.log(printData)
        setTemplatePrinter(res, caseOutTemplate)
        return res
    }

    return {
        getDefaultCaseOutId,
        printCaseOut,
        getPrintCaseOutList,
    }
}

// 设置产品注册证号
function setProductRegNo(printData, caseInfo, storeProductList) {
    let hasProduct = caseInfo && caseInfo.productList && caseInfo.productList.length
    if (!hasProduct) return
    let regNoArr = []
    for (let caseProduct of caseInfo.productList) {
        let storeProduct = storeProductList.value.find(x => x.id === caseProduct.productId)
        if (storeProduct && storeProduct.extra2) {
            if (!regNoArr.some(x => x === storeProduct.extra2)) regNoArr.push(storeProduct.extra2)
        }
    }
    if (regNoArr.length <= 0) return;
    for (let i = 0; i < regNoArr.length; i++) {
        printData['productRegNo' + (i + 1)] = regNoArr[i]
        if (i === 2) return;//最多打印3个注册证号
    }
}

// 设置产品明细数据格式
function setCaseDetails(printData, caseInfo, productDetails) {
    printData.details = []
    for (let caseProduct of caseInfo.productList) {
        let printDetail = getPrintDetail(caseProduct, productDetails.value, caseInfo)
        printData.details.push(printDetail)
    }
    if (caseInfo.productList.length < 4) {
        for (let i = caseInfo.productList.length; i < 4; i++) {
            printData.details.push({
                caseType: '',
                productName: '',
                unit: '',
                warrantText: '',
                toothFullStr: '',
                num: '',
                color: '',
                price: '',
                sum: '',
                brand: '',
                regName: '',
                regNo: '',
                endDate: '',
                produceFactory: '',
            })
        }
    }
}

/**
 * 根据订单产品和产品明细 转化打印产品数据
 * @param caseProduct
 * @param productDetails
 * @param caseInfo
 * @returns {{regNo: (string|[{trigger: string, message: string, required: boolean}]|*), color, endDate: *, toothFullStr: *, num, sum: (string|*|null|number), version: (string|number|string|*), productName: (string|*), caseType: (string|*), regName: (string|*), productBelongName: *, unit: *, warrantText: (*|string), produceFactory: (string|*), price: number, productTypeName: *, udi: (string|*), brand: (string|*), productNo: (string|*)}}
 */
function getPrintDetail(caseProduct, productDetails, caseInfo) {
    let productDetail = productDetails.find(x => x.id === caseProduct.productId)
    let productName = caseProduct.productName
    //质保期限显示
    let warrantText = (productDetail && productDetail.warrantYear) ? (productDetail.warrantYear + productDetail.warrantType) : ''
    if (caseProduct.printAlias) productName = caseProduct.printAlias
    if (caseProduct.warrantYear) warrantText = caseProduct.warrantYear + (caseProduct.warrantType ? caseProduct.warrantType : '年')
    let price = Math.round(caseProduct.sum * 100 / caseProduct.num) / 100
    return {
        caseType: getCaseMakeTypeStr(caseInfo),
        productName: productName,
        unit: productDetail?.productUnit,
        warrantText: warrantText,
        toothFullStr: caseProduct.toothStr,
        num: caseProduct.num,
        color: caseProduct.color,
        price: price,
        sum: caseProduct.sum,
        version: productDetail?.version,
        udi: caseProduct.udi,
        endDate: productDetail?.endDate?.split(' ')[0],
        brand: productDetail?.brand,
        regNo: productDetail?.regNo,
        regName: productDetail?.regName,
        produceFactory: productDetail?.produceFactory,
        productTypeName: productDetail?.productTypeName,
        productBelongName: productDetail?.productBelongName,
        productNo: productDetail?.productNo,
    }
}

function setTemplatePrinter(res, printTemplate) {
    res.options = {}
    if (printTemplate.printerName) res.options.printer = printTemplate.printerName
    if (printTemplate.orient === 2) res.options.landscape = true
    res.template.printPanels[0].orient = (printTemplate.orient && printTemplate.orient === 2) ? 2 : 1
}

// 自定义订单打印模板
function usePrintCaseLabel() {
    const {
        getPrintPanel,
        setPrintText,
        setPrintTable
    } = usePrintTemplate()
    //获取订单主体自定义打印
    const printCaseLabel = (caseInfo, factorySetting, storeHospitalDetail, storeProductList, currentWorkerName, caseLabelTemplate, hiprint) => {
        let hiprintTemplate = new hiprint.PrintTemplate();
        let printPanel = getPrintPanel(caseLabelTemplate)
        printPanel.paperNumberDisabled = true
        let panel = hiprintTemplate.addPrintPanel(printPanel);
        let printData = {}
        setCasePrintData(printData, factorySetting, caseInfo, currentWorkerName, caseLabelTemplate)
        if (caseLabelTemplate.details.some(x => x.detailField === 'productRegNo' && !!x.isPrint)) {
            setProductRegNo(printData, caseInfo, storeProductList)
        }
        if (caseLabelTemplate.details.some(x => x.detailField === 'details' && !!x.isPrint)) {
            setCaseDetails(printData, caseInfo, storeProductList)
        }
        setPrintText(panel, caseLabelTemplate)
        setPrintTable(panel, caseLabelTemplate)
        let res = {
            template: hiprintTemplate,
            data: printData,
        }
        setTemplatePrinter(res, caseLabelTemplate)
        return res
    }
    //获取订单产品主体自定义打印
    const printCaseProductLabel = (caseInfo, productId, productDetails, storeHospitalDetail, factorySetting, currentWorkerName, caseLabelTemplate, hiprint) => {
        let hiprintTemplate = new hiprint.PrintTemplate();
        let printPanel = getPrintPanel(caseLabelTemplate)
        printPanel.paperNumberDisabled = true
        let panel = hiprintTemplate.addPrintPanel(printPanel);
        let printData = {}
        setCaseProductPrintData(printData, factorySetting, caseInfo, productId, productDetails, currentWorkerName, caseLabelTemplate)
        setPrintText(panel, caseLabelTemplate)
        setPrintTable(panel, caseLabelTemplate)
        let res = {
            template: hiprintTemplate,
            data: printData,
        }
        setTemplatePrinter(res, caseLabelTemplate)
        return res
    }
    //设置订单主体打印数据
    const setCasePrintData = (printData, factorySetting, caseInfo, currentWorkerName, caseLabelTemplate) => {
        for (let detail of caseLabelTemplate.details.filter(x => x.isPrint === 1)) {
            if (printFactorySettingItem.some(x => x.field === detail.detailField)) {
                printData[detail.detailField] = factorySetting[detail.detailField]
            } else if (printCaseItems.some(x => x.field === detail.detailField)) {
                if (detail.detailField === 'productNames') {
                    if (caseInfo.productList.length) {
                        printData[detail.detailField] = caseInfo.productList.map(x => x.productName).join('')
                    }
                } else if (detail.detailField !== 'details') {
                    printData[detail.detailField] = caseInfo[detail.detailField]
                }
            } else if (printCaseExtensionItems.some(x => x.field === detail.detailField)) {
                printData[detail.detailField] = caseInfo.extension[detail.detailField]
            } else if (detail.detailField === 'printWorkerName') {
                printData[detail.detailField] = currentWorkerName
            } else if (detail.detailField === 'printTime') {
                printData[detail.detailField] = moment().format('yyyy-MM-DD HH:mm:ss')
            }
        }
    }
    //设置订单产品主体打印数据
    const setCaseProductPrintData = (printData, factorySetting, caseInfo, productId, productDetails, currentWorkerName, caseLabelTemplate) => {
        let caseProduct = caseInfo.productList.find(x => x.productId === productId)
        if (!caseProduct) {
            ElMessage.error('请选择需要打印的产品')
            return
        }
        caseInfo.lotNo = caseInfo.caseNo.startsWith('20') ? caseInfo.caseNo : ('20' + caseInfo.caseNo)
        setCasePrintData(printData, factorySetting, caseInfo, currentWorkerName, caseLabelTemplate)
        // 防伪二维码网址前缀
        let barcodeUrlPre = caseLabelTemplate.remark ? caseLabelTemplate.remark : window.location.origin
        // 构造打印产品数据
        let printDetail = getPrintDetail(caseProduct, productDetails.value, caseInfo)
        for (let detail of caseLabelTemplate.details.filter(x => x.isPrint === 1)) {
            if (printCaseProductItems.some(x => x.field === detail.detailField)) {
                if (detail.detailField === 'warrantyBarcode') {
                    printData[detail.detailField] = barcodeUrlPre + '/warranty/warrantyHome?caseProductId=' + caseProduct.id
                } else if (detail.detailField === 'warrantYear') {
                    printData[detail.detailField] = printDetail.warrantText
                } else if (detail.detailField === 'udiQrcode' || detail.detailField === 'udi') {
                    printData[detail.detailField] = printDetail.udi
                } else if (detail.detailField === 'productTypeName' || detail.detailField === 'productBelongName' || detail.detailField === 'regNo' || detail.detailField === 'version'
                    || detail.detailField === 'regName' || detail.detailField === 'productName' || detail.detailField === 'productNo') {
                    printData[detail.detailField] = printDetail[detail.detailField]
                } else if (detail.detailField === 'toothNoTable') {
                    printData.pos1 = caseProduct.pos1
                    printData.pos2 = caseProduct.pos2
                    printData.pos3 = caseProduct.pos3
                    printData.pos4 = caseProduct.pos4
                    printData.upperJaw = caseProduct.upperJaw
                    printData.lowerJaw = caseProduct.lowerJaw
                } else {
                    printData[detail.detailField] = caseProduct[detail.detailField]
                }
            }
        }
    }
    return {
        printCaseLabel,
        printCaseProductLabel,
    }
}

export {
    setTemplatePrinter,
    usePrintCaseBarcode,
    usePrintWarranty,
    usePrintConfirm,
    usePrintCaseOut,
    usePrintCaseLabel
}