import {reactive, ref} from 'vue'
import {factoryPageRecordAdd, factoryPageRecordAddCloud} from "@/api/system";

function useAddFactoryPageRecord() {
    const addFactoryPageRecordLoading = ref(false)
    const addFactoryPageRecordResult = ref(false)
    const addFactoryPageRecord = async param => {
        if (addFactoryPageRecordLoading.value) return
        addFactoryPageRecordLoading.value = true
        await factoryPageRecordAddCloud(param)
        await factoryPageRecordAdd(param)
        addFactoryPageRecordLoading.value = false
    }
    return {
        addFactoryPageRecordLoading,
        addFactoryPageRecordResult,
        addFactoryPageRecord,
    }
}

export {
    useAddFactoryPageRecord
}