<template>
    <div>
        <div class="view-list flex-row">
            <div class="view-list-left">
                <el-form label-width="90px" :model="getPagePurchaseApplyParam">
                    <el-form-item label-width="10px">
                        <el-row :gutter="5">
                            <el-col :span="12">
                                <el-input placeholder="搜索申请单号" v-model.trim="getPagePurchaseApplyParam.applyNo"
                                          @keydown.enter="searchPage"
                                          maxlength="30"></el-input>
                            </el-col>
                            <el-col :span="12">
                                <worker-select v-model:worker-id="getPagePurchaseApplyParam.applyWorkerId"
                                               placeholder="搜索申请人"></worker-select>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item label="申请日期">
                        <date-range-picker v-model:begin-date="getPagePurchaseApplyParam.applyBeginDate"
                                           v-model:end-date="getPagePurchaseApplyParam.applyEndDate"></date-range-picker>
                    </el-form-item>
                    <el-form-item label-width="10px">
                        <el-checkbox border title="只看未完成或部分采购的申请单" v-model="getPagePurchaseApplyParam.onlyUndone"
                                     :true-label="1" :false-label="0">只看未完成采购
                        </el-checkbox>
                    </el-form-item>
                    <el-form-item label-width="15px">
                        <el-button type="primary" @click="searchPage" :icon="Search"
                                   :loading="getPagePurchaseApplyLoading">搜索
                        </el-button>
                        <el-button @click="initSearchPage" :icon="Refresh">清空</el-button>
                        <el-button type="success" @click="newPurchaseApply" :icon="Plus">新增</el-button>
                    </el-form-item>
                </el-form>
                <el-table :data="purchaseApplyPage" :show-header="false" v-loading="getPagePurchaseApplyLoading"
                          :highlight-current-row="true" @row-click="chooseRow" ref="purchaseApplyPageRef"
                          :row-class-name="tableRowClass">
                    <el-table-column>
                        <template #default="scope">
                            <div class="flex align-center justify-between">
                                <b>{{ scope.row.applyNo }}</b>
                                <div v-if="!!scope.row.isValid">
                                    <el-tag v-if="scope.row.applyPurchaseState===applyPurchaseStateEnum.未采购">未采购
                                    </el-tag>
                                    <el-tag v-if="scope.row.applyPurchaseState===applyPurchaseStateEnum.部分采购"
                                            type="warning">部分采购
                                    </el-tag>
                                    <el-tag v-if="scope.row.applyPurchaseState===applyPurchaseStateEnum.采购完成"
                                            type="success">采购完成
                                    </el-tag>
                                </div>
                                <el-tag v-else type="danger">已作废</el-tag>
                                <span>
                                制单{{ moment(scope.row.addTime).format('yyyy/MM/DD') }}
                            </span>
                            </div>
                            <div class="flex align-center justify-between">
                                <div>
                                <span class="text-success">{{
                                        scope.row.applyWorkerName
                                    }}</span>&nbsp;
                                    <span>{{ scope.row.applyDeptName }}</span>
                                </div>
                                <div>
                                    <span v-if="!!scope.row.applyDate">
                                    申请{{ moment(scope.row.applyDate).format('yyyy/MM/DD') }}
                                </span>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    :pager-count="5"
                    v-show="!!purchaseApplyPage.length"
                    v-model:currentPage="getPagePurchaseApplyParam.pageIndex"
                    v-model:page-size="getPagePurchaseApplyParam.pageSize"
                    :disabled="!getPagePurchaseApplyResult.totalCount"
                    layout="total, prev, pager, next"
                    :total="getPagePurchaseApplyResult.totalCount"
                    @size-change="getPagePurchaseApply"
                    @current-change="getPagePurchaseApply"
                />
            </div>
            <div class="view-list-right flex-grow">
                <el-tabs type="border-card" style="min-height: 100%;" v-model="curTab" @tab-change="changeTab">
                    <el-tab-pane :label="purchaseApply.id?'修改采购申请单':'新增采购申请单'" name="add">
                        <el-scrollbar style="width: 100%;padding-bottom: 10px;">
                            <el-form :model="purchaseApply" label-width="90px" ref='purchaseApplyFormRef'
                                     :rules='purchaseApplySaveRules'
                                     style="width:900px;"
                                     :disabled="purchaseApply.isValid===0"
                                     v-loading='savePurchaseApplyLoading || purchaseApplyGetLoading'>
                                <el-row :gutter="5">
                                    <el-col :span="8">
                                        <el-form-item label="申请单号" prop="applyNo">
                                            <el-input v-model.trim="purchaseApply.applyNo" maxlength="50"
                                                      class='input-medium'
                                                      :readonly="!!purchaseApply.id"
                                                      :placeholder="purchaseApply.id?'':'不填写则自动生成'"
                                                      ref="applyNoRef"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="申请日期" prop="applyDate">
                                            <el-date-picker type="date" v-model="purchaseApply.applyDate"
                                                            :shortcuts="dateShortcuts"
                                                            class='input-medium'></el-date-picker>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="5">
                                    <el-col :span="8">
                                        <el-form-item label="申请员工" prop="applyWorkerId">
                                            <worker-select v-model:worker-id="purchaseApply.applyWorkerId"
                                                           class='input-medium'
                                                           @change-worker="setWorkerDept"></worker-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="申请部门" prop="applyDeptId">
                                            <el-cascader v-model="purchaseApply.applyDeptId" :props="deptTreeProps"
                                                         class="input-medium"
                                                         :options="facDeptList" size="default"></el-cascader>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="批准人" prop="auditWorkerId">
                                            <worker-select v-model:worker-id="purchaseApply.auditWorkerId"
                                                           class='input-medium'></worker-select>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="5">
                                    <el-col :span="24">
                                        <el-form-item label="备注" prop="remark">
                                            <el-input maxlength="200" v-model.trim="purchaseApply.remark"
                                                      class="input-extra"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="5">
                                    <el-col :span="24">
                                        <el-table style="width: 1150px;" :data="purchaseApply.details" border
                                                  show-summary :summary-method="summaryMethod">>
                                            <el-table-column type="index" width="45"></el-table-column>
                                            <el-table-column label="操作" width="110">
                                                <template #default="scope">
                                                    <el-button-group>
                                                        <el-button plain :icon="Plus" type="primary" size="small"
                                                                   title="添加材料"
                                                                   @click="addMaterial"></el-button>
                                                        <el-popconfirm confirm-button-text="确认" cancel-button-text="取消"
                                                                       title="确认删除吗"
                                                                       @confirm="deleteMaterial(scope.row,scope.$index)">
                                                            <template #reference>
                                                                <el-button plain :icon="Delete" type="danger"
                                                                           size="small"></el-button>
                                                            </template>
                                                        </el-popconfirm>
                                                    </el-button-group>
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="材料" width="220" label-class-name="text-danger"
                                                             class-name="compact-cell">
                                                <template #default="scope">
                                                    <material-select :key="scope.$index"
                                                                     v-model:material-id="scope.row.materialId"
                                                                     @change-material="changeMaterial(scope.row)"></material-select>
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="规格" width="110" prop="standard"></el-table-column>
                                            <el-table-column label="数量" width="100" class-name="compact-cell"
                                                             label-class-name="text-danger">
                                                <template #default="scope">
                                                    <el-input v-only-number v-model="scope.row.num"
                                                              maxlength="8">
                                                        <template #suffix>
                                                            {{ scope.row.storeUnit }}
                                                        </template>
                                                    </el-input>
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="备注">
                                                <template #default="scope">
                                                    <el-input v-model="scope.row.remark"
                                                              maxlength="100"></el-input>
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </el-col>
                                </el-row>
                            </el-form>
                            <el-row :gutter="5">
                                <el-col :span="12" style="padding-left: 100px;padding-top:10px;">
                                    <el-button type="primary" :icon="Edit" @click="handleSavePurchaseApply"
                                               :disabled="purchaseApply.applyPurchaseState===applyPurchaseStateEnum.采购完成"
                                               v-show="purchaseApply.isValid===1"
                                               :loading="validPurchaseApplyLoading || savePurchaseApplyLoading || purchaseApplyGetLoading">
                                        保存信息
                                    </el-button>
                                    <el-popconfirm
                                        confirm-button-text="确认"
                                        cancel-button-text="取消" title="确认作废吗"
                                        v-if="purchaseApply.isValid===1"
                                        @confirm="handleValidPurchaseApply"
                                        :disabled="validPurchaseApplyLoading || savePurchaseApplyLoading || purchaseApplyGetLoading"
                                    >
                                        <template #reference>
                                            <el-button type="danger"
                                                       v-show="!!purchaseApply.id"
                                                       :disabled="purchaseApply.applyPurchaseState===applyPurchaseStateEnum.采购完成"
                                                       :loading="validPurchaseApplyLoading || savePurchaseApplyLoading || purchaseApplyGetLoading"
                                                       :icon="Delete">
                                                作废
                                            </el-button>
                                        </template>
                                    </el-popconfirm>

                                    <el-button type="primary" plain v-show="!!purchaseApply.id"
                                               v-if="purchaseApply.isValid===1 && purchaseApply.applyPurchaseState!==applyPurchaseStateEnum.采购完成"
                                               @click="newPurchase"
                                               :icon="Plus">
                                        生成采购单
                                    </el-button>
                                </el-col>
                            </el-row>
                        </el-scrollbar>
                    </el-tab-pane>
                    <el-tab-pane label="操作历史" name="log" :disabled="!purchaseApply.id">
                        <el-table :data="purchaseApplyLogList" v-loading="getPurchaseApplyLogListLoading" border
                                  style="width: 800px;">
                            <el-table-column label="操作员工" prop="workerName" width="100"></el-table-column>
                            <el-table-column label="操作时间" prop="operTime" width="200"
                                             :formatter="datetimeFormat"></el-table-column>
                            <el-table-column label="操作类型" prop="operTypeName" width="100"></el-table-column>
                            <el-table-column label="操作内容" prop="operContent" width="400">
                                <template #default="scope">
                                    <p v-html="scope.row.operContent"></p>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="批量上传" name="upload">
                        <el-steps :space="250" :active="2" finish-status="success">
                            <el-step title="下载采购申请单Excel模板">
                                <template v-slot:description>
                                    <el-button type="primary"
                                               @click="getExcelTemplate('purchase_apply')"
                                               :loading="uploadPurchaseApplyLoading">点击下载采购申请单Excel模板
                                    </el-button>
                                </template>
                            </el-step>
                            <el-step title="上传采购申请单Excel数据">
                                <template v-slot:description>
                                    <el-upload action="#"
                                               accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                               class="avatar-uploader"
                                               :before-upload="beforeExcelUpload"
                                               :auto-upload="true"
                                               :http-request="uploadPurchaseApply"
                                               :show-file-list="false">
                                        <el-button :loading="uploadPurchaseApplyLoading" type="primary">
                                            选择需要导入的采购申请单Excel
                                        </el-button>
                                    </el-upload>
                                </template>
                            </el-step>
                            <el-step title="导入结果">
                                <template v-slot:description>
                                    <el-tag v-if="!!uploadPurchaseApplyNum" type="success">导入成功</el-tag>
                                    <el-tag v-if="!!failedPurchaseApply.length" type="danger">导入失败</el-tag>
                                    <div v-if="!!uploadPurchaseApplyNum">成功导入了
                                        <span class="text-danger">{{ uploadPurchaseApplyNum }}</span>
                                        个采购申请单
                                    </div>
                                </template>
                            </el-step>
                        </el-steps>
                        <el-card shadow="never" header="注意事项" style="width:700px;margin-top: 10px;">
                            <ul class="ul-upload-attention">
                                <li>
                                    一次性不能导入超过500条数据
                                </li>
                                <li>
                                    必填项：<span class="text-danger">采购申请单号，日期，申请员工，单据状态，材料编号，数量</span>
                                </li>
                                <li>
                                    采购申请单号不能和系统已有的采购申请单号重复，且不能以A+6位年月日（YYMMDD）开头
                                </li>
                                <li>
                                    单据状态：未采购/部分采购/采购完成
                                </li>
                                <li>
                                    申请员工和批准员工只能是系统里面已经设置的员工姓名
                                </li>
                                <li>
                                    日期格式（年/月/日）：YYYY/MM/DD
                                </li>
                                <li>
                                    材料编号只能是系统里面已经设置的材料编号（采购管理-材料设置）
                                </li>
                                <li>
                                    导入的Excel格式需要严格按照模板格式（第一行为表头）
                                </li>
                                <li>
                                    任何数据错误都会导致导入失败，请按照提示修改错误后全部重新导入
                                </li>
                            </ul>
                        </el-card>
                        <el-card shadow="never" header="失败原因" style="width:600px;margin-top: 10px;"
                                 v-show="!!failedPurchaseApply.length">
                            <ul class="ul-upload-attention">
                                <li v-for="item in failedPurchaseApply" :key="item.rowIndex">
                                    第{{ item.rowIndex + 1 }}行：
                                    {{ item.message }}
                                </li>
                            </ul>
                        </el-card>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </div>
</template>
<script setup>
import {onActivated, ref, onMounted, watch, inject, computed} from 'vue';
import {Plus, Search, Refresh, Edit, Delete, Check, Hide, View, Setting} from '@element-plus/icons-vue';
import {useStore} from 'vuex'
import {
    dateRangeDefaultTime,
    dateFormat,
    datetimeFormat,
    tableRowClass, formatMoney,
} from "@/util/formatter";
import {deptTreeProps, rangeShortcuts, dateShortcuts} from "@/util/constant";
import {ElMessage} from "element-plus";
import moment from "moment";
import {useRoute, useRouter} from "vue-router";
import {
    useSavePurchaseApply,
    useGetPagePurchaseApply,
    useGetPurchaseApply,
    useValidPurchaseApply,
    useGetLogListPurchaseApply,
    useUploadPurchaseApply,
    useGetPurchaseStat
} from "@/use/purchase/usePurchaseApply";
import {getExcelTemplate, beforeExcelUpload} from "@/use/system/useFile";
import {applyPurchaseStateEnum, getEnumList, getEnumName} from "@/util/enum";

const route = useRoute()
const router = useRouter()
const store = useStore()
const applyPurchaseStateList = getEnumList(applyPurchaseStateEnum)
//部门
const {
    facDeptList,
    getListFacDept
} = useGetListFacDept()
const {
    purchaseApply,
    addMaterial,
    initPurchaseApply,
    purchaseApplyFormRef,
    purchaseApplySaveRules,
    savePurchaseApplyLoading,
    savePurchaseApplyResult,
    savePurchaseApply,
    totalNum,
} = useSavePurchaseApply()
const {
    validPurchaseApplyLoading,
    validPurchaseApplyResult,
    validPurchaseApply,
} = useValidPurchaseApply()
const {
    purchaseApplyLogList,
    getPurchaseApplyLogListLoading,
    getPurchaseApplyLogList
} = useGetLogListPurchaseApply()
const {
    getPagePurchaseApplyParam,
    initGetPagePurchaseApplyParam,
    getPagePurchaseApplyLoading,
    purchaseApplyPage,
    getPagePurchaseApplyResult,
    getPagePurchaseApply,
} = useGetPagePurchaseApply()
const {
    purchaseApplyGetLoading,
    getPurchaseApply,
} = useGetPurchaseApply(purchaseApply)
const {
    uploadPurchaseApplyResult,
    uploadPurchaseApplyLoading,
    uploadPurchaseApplyNum,
    failedPurchaseApply,
    uploadPurchaseApply
} = useUploadPurchaseApply()
const purchaseApplyPageRef = ref(null)
onMounted(async () => {
    initPage(true)
})
const initPage = throttle(async isFirst => {
    if (isFirst) {
        getListFacDept()
        newPurchaseApply()
    }
    if (route.query && route.query.isAudit) {
        newPurchaseApply()
        initSearchPage()
        getPagePurchaseApplyParam.isAudit = route.query.isAudit
        await searchPage()
    } else {
        await getPagePurchaseApply()
    }

    if (purchaseApply.id) {
        await getPurchaseApply(purchaseApply.id)
    }
})
onActivated(async () => {
    initPage(false)
})
const searchPage = async () => {
    getPagePurchaseApplyParam.pageIndex = 1
    await getPagePurchaseApply()
    if (!!getPagePurchaseApplyParam.applyNo && purchaseApplyPage.value.length === 1) {
        purchaseApplyPageRef.value.setCurrentRow(purchaseApplyPage.value[0])
    }
    if (purchaseApply.id) {
        await getPurchaseApply(purchaseApply.id)
    }
}
const initSearchPage = () => {
    initGetPagePurchaseApplyParam()
}
watch(uploadPurchaseApplyResult, (n, o) => {
    if (n) {
        searchPage()
    }
})
// 选择一行
const setFields = inject('setFields')
const curTab = ref('add')
const chooseRow = async row => {
    await getPurchaseApply(row.id)
    changeTab()
}
const changeTab = () => {
    if (curTab.value === 'add') {

    } else if (curTab.value === 'log' && purchaseApply.id) {
        getPurchaseApplyLogList(purchaseApply.id)
    }
}
const {
    getPurchaseStat
} = useGetPurchaseStat()
// 新建申请单
watch(savePurchaseApplyResult, async (newVal, oldVal) => {
    if (!!newVal) {
        if (!purchaseApply.id) {
            newPurchaseApply()
            await getPagePurchaseApply()
        } else {
            await getPagePurchaseApply()
            purchaseApplyPageRef.value.setCurrentRow(purchaseApplyPage.value.find(x => x.id === purchaseApply.id))
        }
        getPurchaseStat()
    }
})
const applyNoRef = ref(null)
const newPurchaseApply = () => {
    initPurchaseApply()
    curTab.value = 'add'
    for (let i = 0; i < 5; i++) {
        //默认添加5个空材料
        addMaterial()
    }
    purchaseApplyPageRef.value && purchaseApplyPageRef.value.setCurrentRow()
    setTimeout(_ => {
        applyNoRef.value && applyNoRef.value.focus()
        purchaseApplyFormRef.value && purchaseApplyFormRef.value.clearValidate()
    }, 0)
}
// 员工列表
const storeWorkerList = computed(() => store.state.workerList)
const setWorkerDept = val => {
    if (val) {
        let worker = storeWorkerList.value.find(x => x.id === val)
        if (worker) {
            purchaseApply.applyDeptId = parseInt(worker.deptId)
        }
    } else {
        purchaseApply.applyDeptId = null
    }
}

// 材料
const storeMaterialList = computed(() => {
    return store.state.materialList
})
const changeMaterial = row => {
    if (row.materialId) {
        let material = storeMaterialList.value.find(x => x.id === row.materialId)
        if (material) {
            row.storeUnit = material.extra1
            row.standard = material.standard
        }
    }
}
const deleteMaterial = (row, index) => {
    if (purchaseApply.details.length && purchaseApply.details.length <= 1) {
        ElMessage.error('至少要有1个材料，请检查')
        return
    }
    purchaseApply.details.splice(index, 1)
}
// 作废
const handleValidPurchaseApply = async () => {
    let param = {
        id: purchaseApply.id,
        isValid: purchaseApply.isValid === 0 ? 1 : 0
    }
    await validPurchaseApply(param)
}
watch(validPurchaseApplyResult, (newVal, oldVal) => {
    if (!!newVal) {
        purchaseApply.isValid = purchaseApply.isValid === 0 ? 1 : 0
        let pageItem = purchaseApplyPage.value.find(x => x.id === purchaseApply.id)
        if (pageItem) pageItem.isValid = purchaseApply.isValid
    }
})
//缓存数据字典
import {useGetDataValueListFromStore} from "@/use/system/useData";
import {useGetListFacDept} from "@/use/workerMgt/useDept";
import {throttle} from "@/util/common";

const {
    getDataValueListFromStore,
    getDataIdFromStore
} = useGetDataValueListFromStore()
const materialUnit = ref([])
const materialUnitId = getDataIdFromStore('materialUnit')
const setData = () => {
    materialUnit.value = getDataValueListFromStore('materialUnit')
}
setData()
// 保存
const handleSavePurchaseApply = async () => {
    let details = purchaseApply.details.filter(x => !!x.materialId)
    if (!details || !details.length) {
        ElMessage.error('请填写采购单材料')
        return
    }
    if (details.some(x => !x.num || !x.storeUnit)) {
        ElMessage.error('请填写所有材料的数量、单位')
        return
    }
    if (details.some(x => x.num < 0)) {
        ElMessage.error('材料数量不能小于0')
        return
    }
    purchaseApply.details = details
    await savePurchaseApply()
}
// 生成采购单
const setPurchaseMenu = inject('setPurchaseMenu')
const newPurchase = () => {
    store.commit('setPurchaseApply', purchaseApply)
    setPurchaseMenu('purchaseAdd')
    router.push({
        name: 'purchaseAdd',
        query: {
            isApply: 1
        }
    })
}
// 合计
const summaryMethod = param => {
    const {columns, data} = param
    const sums = []
    if (!data || !data.length) return sums
    let totalNum = data.map(x => parseFloat(x.num ? x.num : 0)).reduce((prev, cur) => prev + cur)
    columns.forEach((column, index) => {
        if (index === 4) {
            sums[index] = totalNum
        } else {
            sums[index] = ''
        }
    })
    return sums
}
</script>
<style scoped>
.ul-upload-attention li {
    margin-bottom: 5px;
}
</style>