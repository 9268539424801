import http from "@/util/axios";
// 资产种类
export const propertyTypeSave = data => http.factoryPost('propertyType/save', data)
export const propertyTypeDelete = data => http.factoryPost('propertyType/delete', data)
export const propertyTypeGetList = data => http.factoryGet('propertyType/get_list', data)
export const propertyTypeSort = data => http.factoryPost('propertyType/sort', data)
// 固定资产/低值易耗品
export const facPropertySave = data => http.factoryPost('facProperty/save', data)
export const facPropertyGetPage = data => http.factoryGet('facProperty/get_page', data)
export const facPropertyGet = data => http.factoryGet('facProperty/get', data)
export const facPropertyGetSimpleList = data => http.factoryGet('facProperty/get_simple_list', data)
export const facPropertyUpdateValid = data => http.factoryPost('facProperty/update_valid', data)
export const facPropertyDelete = data => http.factoryPost('facProperty/delete', data)
export const facPropertyGetDisinfectList = data => http.factoryGet('facProperty/get_disinfect_list', data)//获取消毒设备列表
export const facPropertyGetByMachineNo = data => http.factoryGet('facProperty/get_machine_no', data)
export const facPropertyGetStepList = data => http.factoryGet('facProperty/get_step_list', data)
export const facStepMachineGetPage = data => http.factoryGet('facProperty/get_step_machine',data)
export const facPropertyExportExcel = '/api/factory/facPropertyExport/excel'
export const machineStepExportExcel = '/api/factory/facPropertyExport/excel_machine_step'
// 资产维修
export const facPropertyFixSave = data => http.factoryPost('facPropertyFix/save', data)
export const facPropertyFixDelete = data => http.factoryPost('facPropertyFix/delete', data)
export const facPropertyFixGetList = data => http.factoryGet('facPropertyFix/get_list', data)
// 资产周转
export const facTurnoverSave = data => http.factoryPost('facTurnover/save', data)
export const facTurnoverGetList = data => http.factoryGet('facTurnover/get_list', data)
// 资产盘点
export const facPropertyCheckGetPage = data => http.factoryGet('facPropertyCheck/get_page', data)
export const facPropertyCheckDelete = data => http.factoryPost('facPropertyCheck/delete', data)
export const facPropertyCheckDetailGetPage = data => http.factoryGet('facPropertyCheck/get_detail_page', data)
export const uncheckedFacPropertyExportExcel = '/api/factory/facPropertyExport/excel_unchecked_property'
export const facPropertyCheckUploadExcel = (data, header) => http.postFile('facPropertyCheck/upload_excel', data, header)
// 资产图片
export const facPropertyPicAdd = data => http.factoryPost('facProperty/add_pic', data)
export const facPropertyPicDelete = data => http.factoryPost('facProperty/delete_pic', data)
export const facPropertyPicGetList = data => http.factoryGet('facProperty/get_pic_list', data)