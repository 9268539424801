import http from "@/util/axios";
// 出入厂质检
export const caseProductTestSave = data => http.factoryPost('caseProductTest/save', data)
export const caseProductTestGetPage = data => http.factoryGet('caseProductTest/get_page', data)
export const caseProductTestGet = data => http.factoryGet('caseProductTest/get', data)
export const caseProductTestGetUnauditNum = data => http.factoryGet('caseProductTest/get_unaudit_num', data)
export const caseProductTestGetByProduct = data => http.factoryGet('caseProductTest/get_by_product', data)
export const caseProductTestDelete = data => http.factoryPost('caseProductTest/delete', data)
export const caseProductTestAudit = data => http.factoryPost('caseProductTest/audit', data)
export const caseProductTestBatchAudit = data => http.factoryPost('caseProductTest/batch_audit', data)
export const caseProductTestUpdateReportNo = data => http.factoryPost('caseProductTest/update_report_no', data)
// 过程质检
export const stepTestSave = data => http.factoryPost('stepTest/save', data)
export const stepTestGet = data => http.factoryGet('stepTest/get', data)
export const stepTestGetPage = data => http.factoryGet('stepTest/get_page', data)
export const stepTestGetList = data => http.factoryGet('stepTest/get_list', data)
export const stepTestDelete = data => http.factoryPost('stepTest/delete', data)
// 消毒
export const caseDisinfectSave = data => http.factoryPost('caseDisinfect/save', data)
export const caseDisinfectGetPage = data => http.factoryGet('caseDisinfect/get_page', data)
export const caseDisinfectGet = data => http.factoryGet('caseDisinfect/get', data)
export const caseDisinfectDelete = data => http.factoryPost('caseDisinfect/delete', data)
export const caseDisinfectExportExcel = '/api/factory/produceExport/excel_case_disinfect'

// 生产
export const caseProductStepAdd = data => http.factoryPost('caseProductStep/add', data)
export const caseProductStepAddSingle = data => http.factoryPost('caseProductStep/add_single', data)
export const caseProductStepMod = data => http.factoryPost('caseProductStep/mod', data)
export const caseProductStepDelete = data => http.factoryPost('caseProductStep/delete', data)
export const caseProductStepGetPage = data => http.factoryGet('caseProductStep/get_page', data)
export const caseTraceGet = data => http.factoryGet('caseProductStep/get_case_trace', data)
export const caseStepGetList = data => http.factoryGet('caseProductStep/get_case_step_list', data)
export const caseProductStepGetListForWorker = data => http.factoryGet('caseProductStep/get_worker_step_list', data)
export const workerStepsGet = data => http.factoryGet('caseProductStep/get_worker_steps', data)
export const workerPointsGet = data => http.factoryGet('caseProductStep/get_worker_points', data)
export const workerPointDetailsGet = data => http.factoryGet('caseProductStep/get_worker_point_details', data)
export const caseProductStepGetList = data => http.factoryGet('caseProductStep/get_list', data)
export const caseProductStepCheckTime = data => http.factoryGet('caseProductStep/check_oper_time', data)
export const caseProductGetByCaseNo = data => http.factoryGet('caseProductStep/get_case_list', data)
export const stepCaseStatGet = data => http.factoryGet('caseProductStep/get_step_case_stat', data)
export const stepCaseStatDetailListGet = data => http.factoryGet('caseProductStep/get_step_case_stat_detail_list', data)
export const productMaterialLotGet = data => http.factoryGet('caseProductStep/get_product_material', data)
export const useMaterialLotGet = data => http.factoryPostBgData('caseProductStep/get_use_material', data)
export const workerStepStat = data => http.factoryPost('caseProductStep/worker_step_stat_by_time', data)

export const caseTraceExportExcel = '/api/factory/produceExport/excel_case_trace'
export const workerPointsExportExcel = '/api/factory/produceExport/excel_worker_points'
export const caseProductStepExportExcel = '/api/factory/produceExport/excel_case_product_step'
// 内返
export const innerRedoStepAdd = data => http.factoryPost('innerRedoStep/add', data)
export const innerRedoStepDelete = data => http.factoryPost('innerRedoStep/delete', data)
export const innerRedoStepGetPage = data => http.factoryGet('innerRedoStep/get_page', data)
export const innerRedoStepGetList = data => http.factoryGet('innerRedoStep/get_list', data)
export const innerRedoStepGetStat = data => http.factoryGet('innerRedoStep/get_stat', data)
export const innerRedoStepExportExcel = '/api/factory/produceExport/excel_inner_redo_step'
export const redoStepStatExportExcel = '/api/factory/produceExport/excel_redo_stat'

// 产品放行
export const caseReleaseAdd = data => http.factoryPost('caseRelease/add', data)
export const caseReleaseGetPage = data => http.factoryGet('caseRelease/get_page', data)
export const releaseCaseGetList = data => http.factoryGet('caseRelease/get_release_case_list', data)
export const caseReleaseDelete = data => http.factoryPost('caseRelease/delete', data)
export const caseReleaseExportExcel = '/api/factory/produceExport/excel_case_release'

// 成品质检
export const finishTestLogSave = data => http.factoryPost('finishTestLog/save', data)
export const finishTestLogDelete = data => http.factoryPost('finishTestLog/delete', data)
export const finishTestLogGetPage = data => http.factoryGet('finishTestLog/get_page', data)
export const finishTestLogGetByCase = data => http.factoryGet('finishTestLog/get_by_case', data)
