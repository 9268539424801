import moment from 'moment'
// 未指定产品编号
export const nullProductNo = 'P0'
// 通用分页
export const pageSizeList = [10, 20, 30, 40]
export const defaultPageSize = 10
//部门树
export const deptTreeProps = {
    checkStrictly: true,
    children: 'children',
    label: 'deptName',
    value: 'id',
    emitPath: false
}
//通用树
export const commonTreeProps = {
    children: 'children',
    label: 'name',
    value: 'id',
    emitPath: false,
    expandTrigger: 'hover'
}
//允许选择父节点的搜索树
export const commonSearchTree = {
    checkStrictly: true,
    children: 'children',
    label: 'name',
    value: 'id',
    expandTrigger: 'hover',
    emitPath: false
}
// 日期快捷选择
export const dateShortcuts = [
    {
        text: '今天',
        value: new Date(),
    },
    {
        text: '明天',
        value: new Date(moment().add(1, 'days').format('yyyy-MM-DD HH:mm:ss')),
    },
    {
        text: '后天',
        value: new Date(moment().add(2, 'days').format('yyyy-MM-DD HH:mm:ss')),
    },
    {
        text: '3天后',
        value: new Date(moment().add(3, 'days').format('yyyy-MM-DD HH:mm:ss')),
    },
    {
        text: '7天后',
        value: new Date(moment().add(7, 'days').format('yyyy-MM-DD HH:mm:ss')),
    },
]
/**
 * 日期区间快捷选择
 */
export const rangeShortcuts = [
    {
        text: '今天',
        value: () => {
            const start = new Date(moment().format('yyyy-MM-DD 00:00:00'))
            const end = new Date(moment().format('yyyy-MM-DD 23:59:59'))
            return [start, end]
        },
    },
    {
        text: '最近7天',
        value: () => {
            const end = new Date(moment().format('yyyy-MM-DD 23:59:59'))
            const start = new Date(moment().add(-7, 'days').format('yyyy-MM-DD 00:00:00'))
            return [start, end]
        },
    },
    {
        text: '最近30天',
        value: () => {
            const end = new Date(moment().format('yyyy-MM-DD 23:59:59'))
            const start = new Date(moment().add(-30, 'days').format('yyyy-MM-DD 00:00:00'))
            return [start, end]
        },
    },
]
// 通用分隔符
export const separator = '|'
//打印左右边距
export const printMinLeft = 5
//打印A4宽度px
export const a4Width = 595
//A4的panel设置
export const a4Panel = {width: 210, height: 297, paperFooter: 340, paperHeader: 10}
//订单列名
export const caseListColumns = [
    {nameCn: '订单号', nameEn: 'CaseNo', width: 30},
    {nameCn: '制作类型', nameEn: 'CaseTypeStr', width: 25},
    {nameCn: '是否开票', nameEn: 'IsInvoiceStr', width: 25},
    {nameCn: '是否加急', nameEn: 'IsUrgentStr', width: 25},
    {nameCn: '盒号', nameEn: 'BoxNo', width: 25},
    {nameCn: '客户单号', nameEn: 'HospitalCaseNo', width: 25},
    {nameCn: '病历号', nameEn: 'MedicalNo', width: 25},
    {nameCn: '客户编号', nameEn: 'FacHospitalNo', width: 25},
    {nameCn: '客户简称', nameEn: 'FacHospitalName', width: 40},
    {nameCn: '医院名称', nameEn: 'RealHospitalName', width: 40},
    {nameCn: '片区', nameEn: 'District', width: 25},
    {nameCn: '医生编号', nameEn: 'FacDoctorNo', width: 25},
    {nameCn: '医生姓名', nameEn: 'FacDoctorName', width: 25},
    {nameCn: '销售代表', nameEn: 'FacWorkerName', width: 25},
    {nameCn: '私人单位', nameEn: 'IsPrivateStr', width: 25},
    {nameCn: '代理', nameEn: 'AgentName', width: 25},
    {nameCn: '患者姓名', nameEn: 'PatientName', width: 25},
    {nameCn: '患者性别', nameEn: 'Gender', width: 25},
    {nameCn: '患者年龄', nameEn: 'PatientAgeStr', width: 25},
    {nameCn: '患者电话', nameEn: 'PatientPhoneNo', width: 25},
    {nameCn: '戴牙日期', nameEn: 'WearDateStr', width: 25},
    {nameCn: '入件日期', nameEn: 'InDateStr', width: 25},
    {nameCn: '预计制作天数', nameEn: 'MakeDaysStr', width: 35},
    {nameCn: '预计额外天数', nameEn: 'ExtraDaysStr', width: 35},
    {nameCn: '预计出件日期', nameEn: 'ScheduleDeliveryDateStr', width: 35},
    {nameCn: '关联订单号', nameEn: 'SourceCaseNo', width: 25},
    {nameCn: '订单状态', nameEn: 'CaseStateStr', width: 25},
    {nameCn: '工厂备注', nameEn: 'FactoryRemark', width: 40},
    {nameCn: '医生备注', nameEn: 'DoctorRemark', width: 40},
    {nameCn: '生产备注', nameEn: 'StepNote', width: 40},
    {nameCn: '材料费用', nameEn: 'MaterialCostStr', width: 25},
    {nameCn: '制作费用', nameEn: 'MakeCostStr', width: 25},
    {nameCn: '总金额', nameEn: 'TotalPriceStr', width: 25},
    {nameCn: '订单来源', nameEn: 'OrderSourceStr', width: 25},
    {nameCn: '审核状态', nameEn: 'AuditStateStr', width: 25},
    {nameCn: '拒绝原因', nameEn: 'RejectReason', width: 25},
    {nameCn: '作废时间', nameEn: 'ValidTimeStr', width: 25},
    {nameCn: '作废原因', nameEn: 'ValidReason', width: 25},
    {nameCn: '返工原因', nameEn: 'RedoReason', width: 25},
    {nameCn: '出件日期', nameEn: 'OutDateStr', width: 25},
    {nameCn: '发货员工', nameEn: 'OutWorkerName', width: 25},
    {nameCn: '延迟发货原因', nameEn: 'DelayReason', width: 35},
    {nameCn: '发票日期', nameEn: 'InvoiceDateStr', width: 25},
    {nameCn: '发票号码', nameEn: 'InvoiceNo', width: 25},
    {nameCn: '发票类型', nameEn: 'InvoiceTypeStr', width: 25},
    {nameCn: '发票抬头', nameEn: 'InvoiceHeader', width: 40},
    {nameCn: '发票地址', nameEn: 'InvoiceAddress', width: 40},
    {nameCn: '快递单号', nameEn: 'ExpressNo', width: 25},
    {nameCn: '快递公司', nameEn: 'ExpressCompany', width: 25},
    {nameCn: '快递发件日期', nameEn: 'PickUpDateStr', width: 25},
]
// 订单产品列名
export const caseProductColumns = [
    {nameCn: '产品编号', nameEn: 'ProductNo', width: 30},
    {nameCn: '产品名称', nameEn: 'ProductName', width: 30},
    {nameCn: '大类', nameEn: 'ProductBelongName', width: 30},
    {nameCn: '种类', nameEn: 'ProductTypeName', width: 30},
    {nameCn: '牙色', nameEn: 'Color', width: 30},
    {nameCn: '牙号', nameEn: 'ToothStr', width: 30},
    {nameCn: '数量', nameEn: 'NumStr', width: 30},
    {nameCn: '单价', nameEn: 'PriceStr', width: 30},
    {nameCn: '小计', nameEn: 'SumStr', width: 30},
    {nameCn: 'UDI', nameEn: 'Udi', width: 100},
    {nameCn: '优惠', nameEn: 'BenefitStr', width: 30},
    {nameCn: '注册证号', nameEn: 'ProductRegNo', width: 60},
]
// 权限枚举
export const funcAuth = {
    保存订单: 'saveCase',
    出货: 'outCase',
    退货: 'returnCase',
    取消出货: 'cancelOutCase',
    作废订单: 'invalidCase',
    打印订单: 'printCase',
    保存快递信息: 'saveCaseExpress',
    在线沟通: 'commentCase',
    更正订单: 'modCase',
    保存客户: 'saveHospital',
    保存医生: 'saveDoctor',
    作废客户: 'deleteHospital',
    作废医生: 'deleteDoctor',
    保存科室: 'saveHospitalDept',
    删除科室: 'deleteHospitalDept',
    设置客户凭证: 'saveHospitalLicense',
    设置客户价格: 'saveHospitalPrice',
    修改消毒记录: 'modDisinfect',
    删除消毒记录: 'deleteDisinfect',
    删除生产记录: 'deleteProductStep',
    删除内返记录: 'deleteInnerRedo',
    删除过程质检: 'deleteStepTest',
    删除出厂质检: 'deleteCaseProductTest',
    查看订单价格: 'viewCasePrice',
    修改订单价格: 'modCasePrice',
    财务弃审: 'financeUnaudit',
    更正出库单: 'modStockOut',
    更正入库单: 'modStockIn',
    修改医生: 'modDoctor'
}

// 订单审核表格项配置
export const financeAuditCaseColumns = [{
    key: 'id',
    title: '制作类型',
    dataKey: 'makeTypeStr',
    width: '85'
}, {
    key: 'id',
    title: '订单号',
    dataKey: 'case.caseNo',
    width: '115'
}, {
    key: 'id',
    title: '客户',
    dataKey: 'case.facHospitalName',
    width: '115'
}, {
    key: 'id',
    title: '入件日期',
    dataKey: 'case.inDate',
    width: '100'
}, {
    key: 'id',
    title: '出件日期',
    dataKey: 'case.extension.outDate',
    width: '100'
}, {
    key: 'id',
    title: '医生',
    dataKey: 'case.facDoctorName',
    width: '100'
}, {
    key: 'id',
    title: '产品名称',
    dataKey: 'productName',
    width: '140'
}, {
    key: 'id',
    title: '数量',
    dataKey: 'num',
    width: '60'
}, {
    key: 'id',
    title: '单价',
    dataKey: 'price',
    width: '90'
}, {
    key: 'id',
    title: '客户价',
    dataKey: 'salesPrice',
    width: '80'
}, {
    key: 'id',
    title: '折扣',
    dataKey: 'case.caseNo',
    width: '80'
}, {
    key: 'id',
    title: '小计',
    dataKey: 'sum',
    width: '70'
}, {
    key: 'id',
    title: '牙位',
    dataKey: 'tooth',
    width: '200'
}, {
    key: 'id',
    title: '患者姓名',
    dataKey: 'case.patientName',
    width: '100'
}]