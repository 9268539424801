<template>
    <el-space class="flex-row tooth-container" :size="0">
        <div class="flex-col">
            <el-tag v-if="isMissing" type="danger" size="small">缺</el-tag>
            <el-tag v-if="isPontic" type="success" size="small">桥</el-tag>
        </div>
        <div class="jaw">
            <div><span v-show="!!complete">全口</span></div>
            <div><span v-show="!!upperJaw">上颌</span></div>
            <div><span v-show="!!lowerJaw">下颌</span></div>
        </div>
        <div class="flex-column">
            <div class="flex-row border-bottom-dash upper">
                <div class="border-right-dash tooth-part tooth-left"
                     :class="{'is-pontic':isPontic}">
                    <span v-for="item in pos1Arr" :key="item.posPart+''+item.pos"
                          :class="{'pontic':item.isPontic,'begin':item.isBegin,'end':item.isEnd,'text-danger':item.isMissing}">
                        {{ item.isSingle || item.isMissing || item.isPontic ? item.pos : '' }}
                    </span>
                </div>
                <div class="tooth-part tooth-right" :class="{'is-pontic':isPontic}">
                    <span v-for="item in pos2Arr" :key="item.posPart+''+item.pos"
                          :class="{'pontic':item.isPontic,'begin':item.isBegin,'end':item.isEnd,'text-danger':item.isMissing}">
                        {{ item.isSingle || item.isMissing || item.isPontic ? item.pos : '' }}
                    </span>
                </div>
            </div>
            <div class="flex-row lower">
                <div class="border-right-dash tooth-part tooth-left"
                     :class="{'is-pontic':isPontic}">
                    <span v-for="item in pos4Arr" :key="item.posPart+''+item.pos"
                          :class="{'pontic':item.isPontic,'begin':item.isBegin,'end':item.isEnd,'text-danger':item.isMissing}">
                        {{ item.isSingle || item.isMissing || item.isPontic ? item.pos : '' }}
                    </span>
                </div>
                <div class="tooth-part tooth-right" :class="{'is-pontic':isPontic}">
                    <span v-for="item in pos3Arr" :key="item.posPart+''+item.pos"
                          :class="{'pontic':item.isPontic,'begin':item.isBegin,'end':item.isEnd,'text-danger':item.isMissing}">
                        {{ item.isSingle || item.isMissing || item.isPontic ? item.pos : '' }}
                    </span>
                </div>
            </div>
        </div>
    </el-space>
</template>
<!--牙位组件-->
<!--用法示例-->
<!--重要 table使用一定要加上key-->
<!--<table-tooth-->
<!--    :key="scope.row.id"-->
<!--    :pos1="scope.row.pos1"-->
<!--    :pos2="scope.row.pos2"-->
<!--    :pos3="scope.row.pos3"-->
<!--    :pos4="scope.row.pos4"-->
<!--    :complete="scope.row.complete"-->
<!--    :upper-jaw="scope.row.upperJaw"-->
<!--    :lower-jaw="scope.row.lowerJaw"-->
<!--    :tooth-type="scope.row.toothType"-->
<!--    :pontic="scope.row.pontic"-->
<!--    :missing1="scope.row.missing1"-->
<!--    :missing2="scope.row.missing2"-->
<!--    :missing3="scope.row.missing3"-->
<!--    :missing4="scope.row.missing4"-->
<!--/>-->
<script setup>
import {ref, reactive, onMounted, watch, toRef, defineProps, defineEmits, computed} from 'vue'
import {toothTypeEnum} from "@/util/enum";
import {getFdiStr, getPosObj} from "@/use/case/useTooth";

const props = defineProps({
    pos1: String,//左上
    pos2: String,//右上
    pos3: String,//右下
    pos4: String,//左下
    complete: Number,
    upperJaw: Number,
    lowerJaw: Number,
    toothType: Number,//牙位类型
    pontic: String,//桥体
    missing1: String,
    missing2: String,
    missing3: String,
    missing4: String,
})
// 牙位类型
const innerToothType = ref(toothTypeEnum.单冠)
watch(() => props.toothType, (newVal, oldVal) => {
    setInnerToothType(newVal)
})
const setInnerToothType = toothType => {
    if (toothType) {
        innerToothType.value = toothType
    } else {
        innerToothType.value = toothTypeEnum.单冠
    }
}
const isPontic = computed(() => !!(innerToothType.value & toothTypeEnum.桥))
const isMissing = computed(() => !!(innerToothType.value & toothTypeEnum.缺失))
// 全口
const innerComplete = ref(0)
watch(() => props.complete, (newVal, oldVal) => {
    innerComplete.value = newVal
})
// 上下颌
const innerUpperJaw = ref(0)
watch(() => props.upperJaw, (newVal, oldVal) => {
    innerUpperJaw.value = newVal
})
const innerLowerJaw = ref(0)
watch(() => props.lowerJaw, (newVal, oldVal) => {
    innerLowerJaw.value = newVal
})
//牙位对象
const pos1Arr = ref([])
const pos2Arr = ref([])
const pos3Arr = ref([])
const pos4Arr = ref([])
const newPosItem = (pos, posPart) => {
    return {
        pos: pos,//1-8
        posPart: posPart,//1 2 3 4
        fdiStr: getFdiStr(pos, posPart),//A1
        isSingle: false,//单冠
        isMissing: false,//缺失
        isPontic: false,//桥体
        isBegin: false,//桥体开始
        isEnd: false//桥体结束
    }
}
//初始化牙位对象
const initPosArr = () => {
    for (let i = 1; i <= 8; i++) {
        pos1Arr.value.push(newPosItem(i, 1))
        pos1Arr.value.sort((a, b) => b.pos - a.pos)
        pos2Arr.value.push(newPosItem(i, 2))
        pos3Arr.value.push(newPosItem(i, 3))
        pos4Arr.value.push(newPosItem(i, 4))
        pos4Arr.value.sort((a, b) => b.pos - a.pos)
    }
}
initPosArr()
// 根据传入pos修改牙位对象
const changeSingleFromOuter = (val, posPart) => {
    if (posPart === 1) {
        pos1Arr.value.forEach(x => {
            x.isSingle = val && val.includes(x.pos + '')
        })
    } else if (posPart === 2) {
        pos2Arr.value.forEach(x => {
            x.isSingle = val && val.includes(x.pos + '')
        })
    } else if (posPart === 3) {
        pos3Arr.value.forEach(x => {
            x.isSingle = val && val.includes(x.pos + '')
        })
    } else if (posPart === 4) {
        pos4Arr.value.forEach(x => {
            x.isSingle = val && val.includes(x.pos + '')
        })
    }
}
// 根据传入missing修改牙位对象
const changeMissingFromOuter = (val, posPart) => {
    if (posPart === 1) {
        pos1Arr.value.forEach(x => {
            x.isMissing = val && val.includes(x.pos + '')
        })
    } else if (posPart === 2) {
        pos2Arr.value.forEach(x => {
            x.isMissing = val && val.includes(x.pos + '')
        })
    } else if (posPart === 3) {
        pos3Arr.value.forEach(x => {
            x.isMissing = val && val.includes(x.pos + '')
        })
    } else if (posPart === 4) {
        pos4Arr.value.forEach(x => {
            x.isMissing = val && val.includes(x.pos + '')
        })
    }
}

watch(() => props.pos1, (newVal, oldVal) => {
    changeSingleFromOuter(newVal, 1)
})
watch(() => props.missing1, (newVal, oldVal) => {
    changeMissingFromOuter(newVal, 1)
})
watch(() => props.pos2, (newVal, oldVal) => {
    changeSingleFromOuter(newVal, 2)
})
watch(() => props.missing2, (newVal, oldVal) => {
    changeMissingFromOuter(newVal, 2)
})
watch(() => props.pos3, (newVal, oldVal) => {
    changeSingleFromOuter(newVal, 3)
})
watch(() => props.missing3, (newVal, oldVal) => {
    changeMissingFromOuter(newVal, 3)
})
watch(() => props.pos4, (newVal, oldVal) => {
    changeSingleFromOuter(newVal, 4)
})
watch(() => props.missing4, (newVal, oldVal) => {
    changeMissingFromOuter(newVal, 4)
})

const innerPontic = ref('')//桥字符串 多个桥|分割
const ponticArr = ref([])//桥数组 牙位,分割
watch(() => props.pontic, (newVal, oldVal) => {
    if (innerPontic.value === newVal) return
    innerPontic.value = newVal
    setPosFromPonticStr()
})
//根据桥体字符串修改牙位对象
const setPosFromPonticStr = () => {
    //桥体字符串拆分牙位数组
    ponticArr.value = innerPontic.value ? innerPontic.value.split('|') : []
    setPosFromPontic()
}
//根据桥体数组修改牙位对象
const setPosFromPonticArr = () => {
    innerPontic.value = ponticArr.value.length ? ponticArr.value.join('|') : ''
    setPosFromPontic()
}
const setPosFromPontic = () => {
    let beginArr = []//桥开始牙位
    let endArr = []//桥结束牙位
    let allPonticArr = []//所有桥牙位
    for (let item of ponticArr.value) {
        let posArr = item.split(',')
        allPonticArr = allPonticArr.concat(posArr)
        if (posArr.length) {
            beginArr.push(posArr[0])
            endArr.push(posArr[posArr.length - 1])
        }
    }
    pos1Arr.value.forEach(x => setPosPontic(x, beginArr, endArr, allPonticArr))
    pos2Arr.value.forEach(x => setPosPontic(x, beginArr, endArr, allPonticArr))
    pos3Arr.value.forEach(x => setPosPontic(x, beginArr, endArr, allPonticArr))
    pos4Arr.value.forEach(x => setPosPontic(x, beginArr, endArr, allPonticArr))
}
//设置某个牙位桥的情况
const setPosPontic = (tooth, beginArr, endArr, allPonticArr) => {
    tooth.isBegin = beginArr && beginArr.includes(tooth.fdiStr)
    tooth.isEnd = endArr && endArr.includes(tooth.fdiStr)
    tooth.isPontic = allPonticArr && allPonticArr.includes(tooth.fdiStr)
    if (tooth.isPontic) tooth.isSingle = false
}

// 根据牙位对象获取显示状态

onMounted(() => {
    innerUpperJaw.value = props.upperJaw
    innerLowerJaw.value = props.lowerJaw
    innerComplete.value = props.complete

    setInnerToothType(props.toothType)
    innerPontic.value = props.pontic

    setPosFromPonticStr()

    changeSingleFromOuter(props.pos1, 1)
    changeSingleFromOuter(props.pos2, 2)
    changeSingleFromOuter(props.pos3, 3)
    changeSingleFromOuter(props.pos4, 4)

    changeMissingFromOuter(props.missing1, 1)
    changeMissingFromOuter(props.missing2, 2)
    changeMissingFromOuter(props.missing3, 3)
    changeMissingFromOuter(props.missing4, 4)
})
</script>

<style scoped lang="scss">
.tooth-container {
    width: 185px;
    font-size: 11px;
}

.jaw {
    width: 30px;

    div {
        height: 20px;
    }
}

.tooth-part {
    width: 55px;
    height: 20px;
}

.border-bottom-dash {
    border-bottom: 1px dashed #777;

    div {
        padding-bottom: 2px;
    }
}

.border-right-dash {
    border-right: 1px dashed #777;
    text-align: right;
}

.tooth-left {
    padding-right: 3px;
    text-align: right;
}

.tooth-left.is-pontic {
    padding-right: 1px;
}

.tooth-right {
    padding-left: 3px;
    text-align: left;
}

.tooth-right.is-pontic {
    padding-left: 1px;
}


.upper .pontic {
    border-top: 1px solid var(--el-color-success);
}

.lower .pontic {
    border-top: 1px solid var(--el-color-success);
}

.pontic.begin {
    margin-left: 2px;
    padding-left: 2px;
    border-left: 1px solid var(--el-color-success);
}

.pontic.end {
    margin-right: 2px;
    padding-right: 2px;
    border-right: 1px solid var(--el-color-success)
}

</style>