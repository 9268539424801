import {reactive, ref, inject, toRaw} from 'vue'
import {sysUserLoginRecordGetPage, sysUserLoginRecordAddCloud} from "@/api/system";

// 用户操作记录翻页
function useGetPageSysUserLoginRecord() {
    const getPageSysUserLoginRecordParam = reactive({
        beginTime: '',
        endTime: '',
        workerName: '',
        pageIndex: 1,
        pageSize: 10,
    })
    const getPageSysUserLoginRecordParamEmpty = JSON.parse(JSON.stringify(toRaw(getPageSysUserLoginRecordParam)))
    const setFields = inject('setFields')
    const getPageSysUserLoginRecordResult = reactive({
        totalCount: 0,
        totalPages: 0,
    })
    const sysUserLoginRecordPage = ref([])
    const initGetPageSysUserLoginRecordParam = () => {
        Object.assign(getPageSysUserLoginRecordParam, getPageSysUserLoginRecordParamEmpty)
    }
    const getPageSysUserLoginRecordLoading = ref(false)
    const getPageSysUserLoginRecord = async () => {
        if (getPageSysUserLoginRecordLoading.value) return
        getPageSysUserLoginRecordLoading.value = true
        let data = await sysUserLoginRecordGetPage(getPageSysUserLoginRecordParam)
        if (data && data.list && data.list.length) {
            sysUserLoginRecordPage.value = data.list
            getPageSysUserLoginRecordResult.totalPages = Math.ceil(data.totalCount / getPageSysUserLoginRecordParam.pageSize)
            getPageSysUserLoginRecordResult.totalCount = data.totalCount
            getPageSysUserLoginRecordParam.minId = data.list[data.list.length - 1].id
        } else {
            sysUserLoginRecordPage.value = []
            getPageSysUserLoginRecordResult.totalPages = 0
            getPageSysUserLoginRecordResult.totalCount = 0
        }
        getPageSysUserLoginRecordLoading.value = false
    }
    return {
        getPageSysUserLoginRecordParam,
        getPageSysUserLoginRecordResult,
        sysUserLoginRecordPage,
        initGetPageSysUserLoginRecordParam,
        getPageSysUserLoginRecordLoading,
        getPageSysUserLoginRecord
    }
}

function useAddCloudLoginRecord() {
    const addCloudLoginRecordLoading = ref(false)
    const addCloudLoginRecordResult = ref(false)
    const addCloudLoginRecord = async () => {
        if (addCloudLoginRecordLoading.value) return
        addCloudLoginRecordResult.value = false
        addCloudLoginRecordLoading.value = true
        addCloudLoginRecordResult.value = await sysUserLoginRecordAddCloud()
        addCloudLoginRecordLoading.value = false
    }
    return {
        addCloudLoginRecord,
        addCloudLoginRecordLoading,
        addCloudLoginRecordResult
    }
}

export {
    useGetPageSysUserLoginRecord,
    useAddCloudLoginRecord,
}