//获取dpi
export const getDpi = () => {
    for (let i = 56; i < 2000; i++) {
        if (matchMedia("(max-resolution: " + i + "dpi)").matches === true) {
            return i;
        }
    }
}
//显示纸张缩放比例
export const printScaleType = {
    条形码: {name: 'caseBarcode', value: 2},
    质保卡: {name: 'warranty', value: 2},
    出货单: {name: 'caseOut', value: 1.2},
    确认单: {name: 'caseBarcode', value: 1.4},
    自定义标签: {name: 'caseLabel', value: 2},
}
//厘米和像素通用转换比例
export const commonRatio = 72 / 2.54
//根据毫米转换像素
export const getPxByMm = (num, ratio = commonRatio) => {
    return Math.floor(num / 10 * ratio)
}
//新增打印表格列
export const newPrintColumn = (title, field, width, order, align = 'center') => {
    return {
        title: title,
        field: field,
        width: width,
        colspan: 1,
        rowspan: 1,
        align: align,
        order: order,
        isPrint: false,
    }
}
export const newPrintColumnExtra = (title, field, width, order, colspan, rowspan, align) => {
    return {
        title: title,
        field: field,
        width: width,
        colspan: colspan,
        rowspan: rowspan,
        align: align,
        order: order,
        isPrint: false,
    }
}
//新增打印字段列 type:text barcode qrcode
export const newPrintDetail = (title, field, showTitle, showField, align, type = 'text') => {
    let height = 14
    let width = 120
    if (type === 'barcode') {
        height = 40
    } else if (type === 'qrcode') {
        width = 50
        height = 50
    }
    return {
        top: 0,
        left: 0,
        width: width,
        title: title,
        showTitle: showTitle,
        field: field,
        showField: showField,
        fieldName: '',
        type: type,
        height: height,
        fontSize: 9,
        isPrint: false,
        align: align,
        transform: 0
    }
}
/**
 * 新加打印元素 自动调整元素位置
 * @param detail
 * @param details
 */
export const setNewDetailPosition = (detail, details) => {
    //以下代码是获取当前最下面的元素位置，调整最新显示的元素位置（已显示的所有元素下方）
    let topArr = details.value.filter(x => !!x.isPrint && x.field !== detail.field).map(x => x.top)//已显示元素的top
    let maxTop = 0//已显示元素的最大top
    if (topArr && topArr.length) {
        maxTop = Math.max(...topArr)
        let heightArr = details.value.filter(x => x.top === maxTop).map(x => x.height)
        maxTop = maxTop + Math.max(...heightArr)
    }
    detail.top = maxTop ? maxTop + 3 : 20
    detail.left = 20
}


// 自定义打印标签
export const printItemTypeEnum = {
    通用: 1,
    工厂设置: 2,
    订单信息: 3,
    订单扩展: 4,
    订单产品: 5
}
export const printCommonItems = [
    {title: '打印员工', field: 'printWorkerName', type: 'text'},
    {title: '打印时间', field: 'printTime', type: 'text'},
]
export const printFactorySettingItem = [
    {title: '工厂名称', field: 'fullName', type: 'text'},
    {title: '公司地址', field: 'fullAddress', type: 'text'},
    {title: '客服电话', field: 'servicePhoneNo', type: 'text'},
]
export const printCaseItems = [
    {title: '订单编号', field: 'caseNo', type: 'text'},
    {title: '客户编号', field: 'facHospitalNo', type: 'text'},
    {title: '客户名称', field: 'facHospitalName', type: 'text'},
    {title: '医生编号', field: 'facDoctorNo', type: 'text'},
    {title: '医生姓名', field: 'facDoctorName', type: 'text'},
    {title: '患者姓名', field: 'patientName', type: 'text'},
    {title: '入件日期', field: 'inDateStr', type: 'text'},
    {title: '预计出件日期', field: 'scheduleDeliveryDateStr', type: 'text'},
    {title: '盒号', field: 'boxNo', type: 'text'},
    {title: '产品注册证号', field: 'productRegNo', type: 'text'},
    {title: '是否加急', field: 'isUrgentStr', type: 'text'},
    {title: '制作类型', field: 'caseTypeStr', type: 'text'},
    {title: '生产批号', field: 'lotNo', type: 'text'},
    {title: '销售员', field: 'salesmanName', type: 'text'},
    {title: '销售电话', field: 'salesmanPhoneNo', type: 'text'},
    {title: '片区', field: 'district', type: 'text'},
    {title: '代理', field: 'agentName', type: 'text'},
    {title: '总金额', field: 'totalPriceStr', type: 'text'},
    {title: '客户订单号', field: 'hospitalCaseNo', type: 'text'},
    {title: '病历号', field: 'medicalNo', type: 'text'},
    {title: '医生备注', field: 'doctorRemark', type: 'text'},
    {title: '工厂备注', field: 'factoryRemark', type: 'text'},
    {title: '产品名称总', field: 'productNames', type: 'text'},
]
export const printCaseExtensionItems = [
    {title: '出件日期', field: 'outDateStr', type: 'text'},
    {title: '发票号码', field: 'invoiceNo', type: 'text'},
    {title: '发票抬头', field: 'invoiceHeader', type: 'text'},
    {title: '发票地址', field: 'invoiceAddress', type: 'text'},
]
export const printCaseProductItems = [
    {title: '产品编号', field: 'productNo', type: 'text'},
    {title: '产品名称', field: 'productName', type: 'text'},
    {title: '产品种类', field: 'productTypeName', type: 'text'},
    {title: '产品大类', field: 'productBelongName', type: 'text'},
    {title: '数量', field: 'num', type: 'text'},
    {title: '十字牙位', field: 'toothNoTable', type: 'toothNoTable'},
    {title: '牙色', field: 'color', type: 'text'},
    {title: '质保期限', field: 'warrantYear', type: 'text'},
    {title: '患者二维码', field: 'warrantyBarcode', type: 'qrcode'},
    {title: 'UDI条形码', field: 'udi', type: 'barcode'},
    {title: 'UDI二维码', field: 'udiQrcode', type: 'qrcode'},
    {title: '注册证名称', field: 'regName', type: 'text'},
    {title: '注册证号', field: 'regNo', type: 'text'},
    {title: '规格型号', field: 'version', type: 'text'},
]