import {reactive, ref, inject, toRaw} from 'vue'
import {
    privatePriceAdd,
    privatePriceMod,
    privatePriceGetList,
    privatePriceGetStoreList,
    privatePriceSaveProduct,
    privatePriceDeleteProduct,
    privatePriceDelete,
    privatePriceDeleteDoctor,
    privatePriceGetProductList,
    privatePriceGetDoctorList,
    privatePriceCopyPrice,
    privatePriceUpdateStore,
} from '@/api/customer'
import {ElMessage} from "element-plus";

// 通知医院医生更新私人价格
function useUpdateHospitalPrivatePriceStore() {
    const updateHospitalPrivatePriceStore = async id => {
        await privatePriceUpdateStore({id: id})
    }
    return {
        updateHospitalPrivatePriceStore
    }
}

/**
 * 获取所有私人价格
 * @returns {{getAllPrivatePriceList: ((function(): Promise<void>)|*), getAllPrivatePriceListLoading: *, allPrivatePriceList: *}}
 */
function useGetAllPrivatePriceList() {
    const allPrivatePriceList = ref([])
    const getAllPrivatePriceListLoading = ref(false)
    const getAllPrivatePriceList = async () => {
        if (getAllPrivatePriceListLoading.value) return
        getAllPrivatePriceListLoading.value = true
        let res = await privatePriceGetStoreList()
        allPrivatePriceList.value = res ? res : []
        getAllPrivatePriceListLoading.value = false
    }
    return {
        allPrivatePriceList,
        getAllPrivatePriceList,
        getAllPrivatePriceListLoading
    }
}

// 新增私人价格
function useAddPrivatePrice() {
    const addPrivatePriceParam = reactive({
        id: 0,
        name: '',
        remark: '',
        list: []
    })
    const initAddPrivatePriceParam = () => {
        addPrivatePriceParam.id = 0
        addPrivatePriceParam.name = ''
        addPrivatePriceParam.remark = ''
        addPrivatePriceParam.list = []
    }
    const privatePriceFormRef = ref()
    const privatePriceFormRules = reactive({
        name: [
            {required: true, message: '请输入名称', trigger: 'blur'},
        ],
    })
    const addPrivatePriceLoading = ref(false)
    const addPrivatePriceResult = ref(false)
    const addPrivatePrice = async () => {
        if (addPrivatePriceLoading.value) return
        addPrivatePriceResult.value = false
        addPrivatePriceLoading.value = true
        privatePriceFormRef.value.validate(async (valid, fields) => {
            if (!valid) {
                addPrivatePriceLoading.value = false
                return
            }
            addPrivatePriceResult.value = await privatePriceAdd(addPrivatePriceParam)
            addPrivatePriceLoading.value = false
        })
    }
    return {
        addPrivatePriceParam,
        privatePriceFormRef,
        privatePriceFormRules,
        initAddPrivatePriceParam,
        addPrivatePriceLoading,
        addPrivatePriceResult,
        addPrivatePrice
    }
}

// 修改价格名称和备注
function useModPrivatePrice() {
    const modPrivatePriceParam = {
        id: null,
        name: '',
        remark: ''
    }
    const modPrivatePriceLoading = ref(false)
    const modPrivatePriceResult = ref(false)
    const modPrivatePrice = async () => {
        if (modPrivatePriceLoading.value) return
        modPrivatePriceLoading.value = true
        modPrivatePriceResult.value = false
        modPrivatePriceResult.value = await privatePriceMod(modPrivatePriceParam)
        modPrivatePriceLoading.value = false
    }
    return {
        modPrivatePriceParam,
        modPrivatePriceLoading,
        modPrivatePriceResult,
        modPrivatePrice
    }
}

// 获取私人价格列表
function useGetPrivatePriceList() {
    const getPrivatePriceListLoading = ref(false)
    const privatePriceList = ref([])
    const getPrivatePriceList = async () => {
        if (getPrivatePriceListLoading.value) return
        getPrivatePriceListLoading.value = true
        privatePriceList.value = []
        let res = await privatePriceGetList()
        if (res && res.length) privatePriceList.value = res
        getPrivatePriceListLoading.value = false
    }
    return {
        getPrivatePriceListLoading,
        privatePriceList,
        getPrivatePriceList
    }
}

// 保存某个产品价格
function useSavePrivateProductPrice() {
    const savePrivateProductPriceParam = {
        id: null,
        priceId: null,
        productId: null,
        salesPrice: null,
    }
    const savePrivateProductPriceLoading = ref(false)
    const savePrivateProductPriceResult = ref(false)
    const savePrivateProductPrice = async () => {
        if (!savePrivateProductPriceParam.priceId) {
            ElMessage.error('价格ID不存在')
            return
        }
        if (!savePrivateProductPriceParam.productId) {
            ElMessage.error('产品ID不存在')
            return
        }
        if (savePrivateProductPriceLoading.value) return
        savePrivateProductPriceResult.value = false
        savePrivateProductPriceLoading.value = true
        if (savePrivateProductPriceParam.salesPrice !== null && savePrivateProductPriceParam.salesPrice !== '') {
            savePrivateProductPriceResult.value = await privatePriceSaveProduct(savePrivateProductPriceParam)
            savePrivateProductPriceParam.id = savePrivateProductPriceResult.value ? savePrivateProductPriceResult.value : null
        } else {
            savePrivateProductPriceResult.value = await privatePriceDeleteProduct({id: savePrivateProductPriceParam.id})
            !!savePrivateProductPriceResult.value && (savePrivateProductPriceParam.id = null)
        }
        savePrivateProductPriceLoading.value = false
    }
    return {
        savePrivateProductPriceParam,
        savePrivateProductPriceLoading,
        savePrivateProductPriceResult,
        savePrivateProductPrice
    }
}

// 删除私人价格
function useDeletePrivatePrice() {
    const deletePrivatePriceLoading = ref(false)
    const deletePrivatePriceResult = ref(false)
    const deletePrivatePrice = async id => {
        if (deletePrivatePriceLoading.value) return
        deletePrivatePriceResult.value = false
        deletePrivatePriceLoading.value = true
        deletePrivatePriceResult.value = await privatePriceDelete({id: id})
        deletePrivatePriceLoading.value = false
    }
    return {
        deletePrivatePriceLoading,
        deletePrivatePriceResult,
        deletePrivatePrice
    }
}

// 获取私人价格产品
function useGetPrivateProductPriceList() {
    const getPrivateProductPriceListLoading = ref(false)
    const privateProductPriceList = ref([])
    const getPrivateProductPriceList = async id => {
        if (getPrivateProductPriceListLoading.value) return
        getPrivateProductPriceListLoading.value = true
        privateProductPriceList.value = []
        let res = await privatePriceGetProductList({id: id})
        if (res && res.length) privateProductPriceList.value = res
        getPrivateProductPriceListLoading.value = false
    }
    return {
        getPrivateProductPriceListLoading,
        getPrivateProductPriceList,
        privateProductPriceList
    }
}

// 删除私人价格关联医生
function useDeletePrivatePriceDoctor() {
    const deletePrivatePriceDoctorParam = {
        id: null,
        doctorId: null
    }
    const deletePrivatePriceDoctorLoading = ref(false)
    const deletePrivatePriceDoctorResult = ref(false)
    const deletePrivatePriceDoctor = async () => {
        if (!deletePrivatePriceDoctorParam.id || !deletePrivatePriceDoctorParam.doctorId) {
            ElMessage.error('请确认价格和医生ID')
            return
        }
        if (deletePrivatePriceDoctorLoading.value) return
        deletePrivatePriceDoctorResult.value = false
        deletePrivatePriceDoctorLoading.value = true
        deletePrivatePriceDoctorResult.value = await privatePriceDeleteDoctor(deletePrivatePriceDoctorParam)
        deletePrivatePriceDoctorLoading.value = false
    }
    return {
        deletePrivatePriceDoctorParam,
        deletePrivatePriceDoctorLoading,
        deletePrivatePriceDoctorResult,
        deletePrivatePriceDoctor
    }
}

// 私人价格医生列表
function useGetPrivatePriceDoctorList() {
    const getPrivatePriceDoctorListLoading = ref(false)
    const privatePriceDoctorList = ref([])
    const getPrivatePriceDoctorList = async id => {
        if (getPrivatePriceDoctorListLoading.value) return
        getPrivatePriceDoctorListLoading.value = true
        privatePriceDoctorList.value = []
        let res = await privatePriceGetDoctorList({id: id})
        if (res && res.length) privatePriceDoctorList.value = res
        getPrivatePriceDoctorListLoading.value = false
    }
    return {
        getPrivatePriceDoctorListLoading,
        privatePriceDoctorList,
        getPrivatePriceDoctorList
    }
}

// 复制其他医院或者私人价格
function useCopyPrivatePrice() {
    const copyPrivatePriceParam = reactive({
        sourceHospitalId: null,
        sourceHospitalName: '',
        sourcePriceId: null,
        sourcePriceName: '',
        targetPriceId: null,
        name: '',
        remark: ''
    })
    const copyPrivatePriceLoading = ref(false)
    const copyPrivatePriceResult = ref(false)
    const copyPrivatePrice = async () => {
        if (copyPrivatePriceLoading.value) return
        copyPrivatePriceLoading.value = true
        copyPrivatePriceResult.value = false
        copyPrivatePriceResult.value = await privatePriceCopyPrice(copyPrivatePriceParam)
        copyPrivatePriceLoading.value = false
    }
    return {
        copyPrivatePriceParam,
        copyPrivatePriceLoading,
        copyPrivatePriceResult,
        copyPrivatePrice
    }
}

export {
    useAddPrivatePrice,
    useModPrivatePrice,
    useGetPrivatePriceList,
    useDeletePrivatePrice,
    useSavePrivateProductPrice,
    useGetPrivateProductPriceList,
    useDeletePrivatePriceDoctor,
    useGetPrivatePriceDoctorList,
    useCopyPrivatePrice,
    useGetAllPrivatePriceList,
    useUpdateHospitalPrivatePriceStore,
}
