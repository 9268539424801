<template>
    <div>
        <el-autocomplete title="可以搜索名称、拼首和编号；双击可以弹出搜索弹窗" v-model.trim="inputMaterialInfo" value-key="name"
                         :disabled="disabled" clearable @blur="blurMaterial" style="width:100%;"
                         @dblclick="showMaterial" select-when-unmatched
                         placeholder="请选择材料" @select="chooseInputMaterial"
                         highlight-first-item
                         :type="isTextarea?'textarea':''"
                         :fetch-suggestions="fetchMaterial">
            <template #default="{ item }">
                <div class="flex align-center justify-between">
                    <b>{{ item.name }}</b>
                    <span class="text-secondary" style="font-size: 13px;">&nbsp;&nbsp;{{ item.standard }}</span>
                </div>
            </template>
            <!--            <template #prepend>-->
            <!--                <el-button :icon="Search" size="small" @click.stop="showMaterial"></el-button>-->
            <!--            </template>-->
        </el-autocomplete>
        <el-dialog v-model="materialVisible" title="材料选择框" width="800px" append-to-body>
            <div class="flex-row">
                <div style="width:190px;">
                    <el-tree :data="storeMaterialType" @node-click="chooseMaterialType"
                             :props="commonTreeProps"
                             node-key="id" ref="treeRef"
                             :highlight-current="true" :default-expand-all="false"
                             :expand-on-click-node="true">
                    </el-tree>
                </div>
                <div class="flex-grow">
                    <el-space :size="0">
                        <el-input placeholder="搜索名称、编号、拼音首字母" v-model.trim="searchMaterialInfo"
                                  class="input-large"
                                  :suffix-icon="Search" maxlength="50"></el-input>
                        <el-button :icon="Refresh" @click="resetSearchMaterial">重置搜索</el-button>
                        <span class="text-danger">单击列表材料即可选择</span>
                    </el-space>
                    <el-table border stripe :data="modalMaterialList" @row-click="chooseMaterial" style="width: 100%;"
                              max-height="800px">
                        <el-table-column prop="code" label="编号" min-width="100"></el-table-column>
                        <el-table-column prop="name" label="名称" min-width="200"></el-table-column>
                        <el-table-column prop="standard" label="规格" min-width="100"></el-table-column>
                        <el-table-column prop="extra1" label="库存单位" width="60"></el-table-column>
                        <el-table-column prop="extra3" label="种类" min-width="90"></el-table-column>
                    </el-table>
                </div>
            </div>
            <footer>
                <div style="width: 100%;display: flex;">
                    <div class="flex-grow"></div>
                    <el-button @click="hideMaterial">
                        关闭
                    </el-button>
                </div>
            </footer>
        </el-dialog>
    </div>
</template>
<!--示例-->
<!--
<material-select v-model:material-id='materialId' :material-list='storeMaterialList'></material-select>
-->
<script setup>
import {useStore} from "vuex";
import {ref, reactive, onMounted, watch, toRef, defineProps, defineEmits, computed} from 'vue'
import {Search, Refresh} from "@element-plus/icons-vue";
import {commonTreeProps} from "@/util/constant";
import {commonListFilter, commonListDefaultFilter} from "@/util/formatter";
import {throttle} from "@/util/common";

const props = defineProps({
    materialId: Number,
    disabled: Boolean || null,
    materialList: Array,
    isTextarea: Boolean
})
const emits = defineEmits(['update:materialId', 'changeMaterial'])
watch(() => props.materialId, (n, o) => {
    if (n) {
        innerMaterialId.value = parseInt(n)
        let material = store.state.materialList.find(x => x.id === n)
        material && (inputMaterialInfo.value = material.name)
    } else {
        innerMaterialId.value = null
        inputMaterialInfo.value = ''
        //外部主动清空数据时 进行一次排序
        sortMaterialList()
    }
})
const store = useStore()
const innerMaterialId = ref()
const inputMaterialInfo = ref('')
const storeMaterialType = store.state.materialType
let storeMaterialList = null
watch(() => store.state.lastMaterialIds, (n, o) => {
    sortMaterialList()
})
const sortMaterialList = () => {
    storeMaterialList.sort((a, b) => {
        if (!store.state.lastMaterialIds.includes(b.id) && !store.state.lastMaterialIds.includes(a.id)) {
            if (a.count === b.count) {
                return b.id - a.id
            }
            return b.count - a.count
        }
        return store.state.lastMaterialIds.includes(b.id) ? 1 : -1
    })
}
// 输入框
const fetchMaterial = (str, cb) => {
    let res = []
    if (str) {
        if (storeMaterialList.some(x => x.id === innerMaterialId.value && x.name === str)) {
            res = []
        } else {
            res = storeMaterialList.filter(commonListFilter(str))
        }
    } else {
        res = storeMaterialList.filter(commonListDefaultFilter)
    }
    cb(res)
}
const chooseInputMaterial = item => {
    innerMaterialId.value = (item && item.id) ? item.id : null
    changeMaterialId()
}
const blurMaterial = () => {
    let emitOuter = false//是否要通知外层
    if (!inputMaterialInfo.value) {
        if (innerMaterialId.value) emitOuter = true
        innerMaterialId.value = null
    } else {
        let material = store.state.materialList.find(x => x.name === inputMaterialInfo.value)
        if (material) {
            if (innerMaterialId.value !== material.id) {
                innerMaterialId.value = material.id
                emitOuter = true
            }
        } else {
            if (innerMaterialId.value) emitOuter = true
            innerMaterialId.value = null
        }
        //没有选择 清空输入框
        if (!innerMaterialId.value) {
            inputMaterialInfo.value = ''
        }
    }
    if (emitOuter) changeMaterialId()
}
onMounted(() => {
    if (props.materialId) {
        innerMaterialId.value = props.materialId
        let material = store.state.materialList.find(x => x.id === props.materialId)
        material && (inputMaterialInfo.value = material.name)
    }
    if (props.materialList && props.materialList.length) {
        storeMaterialList = props.materialList
    } else {
        storeMaterialList = store.state.materialList
    }
    sortMaterialList()
})

const changeMaterialId = () => {
    if (innerMaterialId.value) {
        emits('update:materialId', innerMaterialId.value)
    } else {
        emits('update:materialId', null)
    }
    emits('changeMaterial', innerMaterialId.value)
}
// 材料弹窗
const materialVisible = ref(false)
const showMaterial = () => {
    materialVisible.value = true
    searchMaterialTypeId.value = 0
    searchMaterialInfo.value = ''
    searchModalMaterial()
}
const hideMaterial = () => {
    materialVisible.value = false
}
const treeRef = ref(null)
const searchMaterialTypeId = ref(0)
const chooseMaterialType = val => {
    searchMaterialTypeId.value = val.id
    searchModalMaterial()
}
const searchMaterialInfo = ref('')
let debounce = null
watch(searchMaterialInfo, (n, o) => {
    if (debounce !== null) {
        clearTimeout(debounce)
    }
    debounce = setTimeout(() => {
        searchModalMaterial()
    }, 300)
})
const modalMaterialList = ref([])
const searchModalMaterial = () => {
    if (!searchMaterialTypeId.value && !searchMaterialInfo.value) {
        modalMaterialList.value = storeMaterialList.filter(commonListDefaultFilter)
    } else {
        modalMaterialList.value = storeMaterialList.filter(x => {
            return (!searchMaterialTypeId.value || x.typePath.indexOf(',' + searchMaterialTypeId.value + ',') >= 0)
                && (!searchMaterialInfo.value ||
                    (x.name && x.name.indexOf(searchMaterialInfo.value) >= 0) || (x.code && x.code.indexOf(searchMaterialInfo.value) >= 0)
                    || (x.pinyin && x.pinyin.indexOf(searchMaterialInfo.value.toUpperCase()) >= 0))
        })
    }
}
const chooseMaterial = throttle(row => {
    innerMaterialId.value = row.id
    inputMaterialInfo.value = row.name
    materialVisible.value = false
    changeMaterialId()
}, 300)
const resetSearchMaterial = () => {
    searchMaterialTypeId.value = 0
    searchMaterialInfo.value = ''
    searchModalMaterial()
}

</script>

<style scoped>

</style>