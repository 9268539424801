<template>
    <el-select size="default" v-model="innerDoctorId" filterable title="可以按照姓名、拼音首字母和编号搜索；请先选择客户"
               :filter-method="filterDoctor" @change="changeDoctorId" default-first-option
               @visible-change="doctorVisibleChange" placeholder="选择医生"
               clearable>
        <el-option v-for="item in searchDoctorList" :key="item.id" :value="item.id"
                   :label="item.name">
            <span style="float: left">{{ item.name }}</span>
            <span
                style="float: right;color: var(--el-text-color-secondary);font-size: 13px;">{{
                    item.code
                }}</span>
        </el-option>
    </el-select>
</template>
<!--示例-->
<!--
<doctor-select v-model:doctor-id="doctorId" :hospital-id="hospitalId"></doctor-select>
-->
<script setup>
import {useStore} from "vuex";
import {ref, reactive, onMounted, watch, toRef, defineProps, defineEmits, computed} from 'vue'
import {commonListFilter} from "@/util/formatter";

const props = defineProps({
    doctorId: Number,
    hospitalId: Number,
})
const emits = defineEmits(['update:doctorId', 'changeDoctor'])
watch(() => props.doctorId, (n, o) => {
    if (n) {
        innerDoctorId.value = parseInt(n)
        if (searchDoctorList.value.some(x => x.id === innerDoctorId.value)) {
            //切换医院导致清空医生  这时如果赋值正确的医生ID 不清空外部医生
            window.clearTimeout(clearDoctor)
        }
    } else {
        innerDoctorId.value = null
    }
})
let clearDoctor = null
watch(() => props.hospitalId, (n, o) => {
    if (n) {
        //切换医院
        innerHospitalId.value = parseInt(n)
        searchDoctorList.value = storeDoctorList.value
        if (searchDoctorList.value.some(x => x.id === innerDoctorId.value)) {
            // 先切换医生 后切换医院导致
            // 医院下找到了医生 什么都不做
        } else {
            innerDoctorId.value = null
            clearDoctor = setTimeout(() => {
                changeDoctorId()
            }, 300)
        }
    } else {
        //清空医院
        innerHospitalId.value = null
        innerDoctorId.value = null
        changeDoctorId()
        searchDoctorList.value = []
    }
})
const innerHospitalId = ref()

const store = useStore()
const innerDoctorId = ref()
const storeDoctorList = computed(() => store.state.doctorList.filter(x => x.parentId === innerHospitalId.value).sort((a, b) => {
    if (b.count === a.count) {
        return b.id - a.id
    }
    return b.count - a.count
}))
const searchDoctorList = ref([])

const filterDoctor = val => {
    if (!val) {
        searchDoctorList.value = storeDoctorList.value
    } else {
        searchDoctorList.value = storeDoctorList.value.filter(commonListFilter(val))
    }
}
const doctorVisibleChange = val => {
    if (val && !innerDoctorId.value) {
        searchDoctorList.value = storeDoctorList.value
    }
}

onMounted(() => {
    if (props.hospitalId) {
        innerHospitalId.value = props.hospitalId
    }
    if (props.doctorId) {
        innerDoctorId.value = props.doctorId
    }
})

const changeDoctorId = () => {
    if (innerDoctorId.value) {
        emits('update:doctorId', innerDoctorId.value)
    } else {
        emits('update:doctorId', null)
    }
    emits('changeDoctor', innerDoctorId.value)
}

</script>

<style scoped>

</style>