<template>
    <div class="login-container">
        <div></div>
        <div class="login-body">
            <el-row style="justify-content:center;">
                <p style="font-size: 25px">存知工厂管理平台</p>
            </el-row>
            <el-row>
                <ul>
                    <li class="borderLi">
                        <div>
                            <img src="../assets/loginUser.png" alt="账号"/>
                            <input id="userName" type="text" v-model.trim="user.userName" maxlength="20"
                                   ref="userNameRef" @change="changeUserName"
                                   @keyup.enter="enterUserName" autoComplete="off"/>
                        </div>
                    </li>
                    <li class="borderLi">
                        <img src="../assets/loginPwd.png" alt="密码"/>
                        <input id="password" type="password" v-model.trim="user.password" maxlength="20"
                               ref="passwordRef"
                               @keyup.enter="login" autoComplete="off"/>
                    </li>
                    <li class="borderLi" v-if="needVerify && user.userName && user.userName.length===11">
                        <input id="verifyCode" type="text" v-model.trim="user.verifyCode" maxlength="20"
                               ref="verifyCodeRef" class="verify-code" placeholder="验证码"
                               @keyup.enter="login" autoComplete="off"/>
                        <img :src="verifyPicPath+user.userName" alt="验证码" class="verify-code"/>
                    </li>
                    <li class="text-center">
                        <el-button class="loginBtn"
                                   :loading="loginLoading" @click="handleLogin">登&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;录
                        </el-button>
                    </li>
                    <li class="text-center">
                        <el-tooltip content="请联系系统管理员">
                            <p style="font-size: 12px;color:white;padding-top:10px;">忘记密码</p>
                        </el-tooltip>
                    </li>
                </ul>
            </el-row>
        </div>
        <div class="text-df">
            <a @click="showAgreement" class="login-otherInfo">用户协议</a>&nbsp<span class="login-line"> | </span>&nbsp<a
            @click="showPrivacy" class="login-otherInfo">隐私政策</a>
            <br>
            <span class="login-footer">
                Copyright © 2023 All Rights Reserved. 推荐分辨率1366*768及以上
                建议浏览器：Chrome
            </span>
        </div>
        <el-dialog v-model="factoryListVisible" title="选择工厂" :close-on-click-modal="false"
                   :close-on-press-escape="false" :show-close="false">
            <el-form label-width="100px">
                <el-form-item label="选择工厂">
                    <el-select v-model="factoryId" class="input-large" size="default">
                        <el-option v-for="item in factoryList" :key="item.id" :label="item.name"
                                   :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <footer>
                <div style="width: 100%;display: flex;">
                    <div class="flex-grow"></div>
                    <el-button type="primary" :loading="loginFactoryLoading" @click="loginFactory">
                        确认登录
                    </el-button>
                </div>
            </footer>
        </el-dialog>
    </div>

    <el-dialog v-model="agreementVisiable" title="存知科技用户协议" width="1100px">
        <user-agreement></user-agreement>
    </el-dialog>

    <el-dialog v-model="privacyVisiable" title="存知科技隐私政策" width="1100px">
        <privacy-policy></privacy-policy>
    </el-dialog>

</template>

<script setup>
import UserAgreement from "@/views/userAgreement";
import PrivacyPolicy from "@/views/privacyPolicy";
import {ref, onMounted} from 'vue'
import {useLogin, verifyPicPath} from "@/use/system/useUser";
import {ElMessage} from "element-plus";

const agreementVisiable = ref(false)
const privacyVisiable = ref(false)
const showAgreement = () => {
    agreementVisiable.value = true
}
const showPrivacy = () => {
    privacyVisiable.value = true
}
const {
    user,
    loginLoading,
    login,
    factoryId,
    factoryList,
    factoryListVisible,
    loginFactory,
    loginFactoryLoading,
    needVerify
} = useLogin()
const userNameRef = ref()
const passwordRef = ref()
const verifyCodeRef = ref()
onMounted(() => {
    let userName = localStorage.getItem('factoryUserName')
    if (!!userName) {
        user.userName = userName
        passwordRef.value && passwordRef.value.focus()
    } else {
        userNameRef.value && userNameRef.value.focus()
    }
})

const enterUserName = async () => {
    if (!user.password) {
        passwordRef.value && passwordRef.value.focus()
    } else {
        await login()
    }
}
const handleLogin = async () => {
    if (!user.userName) {
        userNameRef.value.focus()
        ElMessage.error('请输入手机号')
    } else if (!user.password) {
        passwordRef.value.focus()
        ElMessage.error('请输入密码')
    } else {
        await login()
    }
}

const changeUserName = () => {
    needVerify.value = false
}

</script>

<style scoped lang="scss">
.login-container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: url("../assets/login.png") no-repeat center center;
    background-size: 100% 100%;
    text-align: center;

    .login-body {
        width: 250px;
        //height: 300px;
        color: #FFFFFF;
        background-color: #000000;
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        padding: 30px;

        p {
            margin: 2px;
            font-size: 32px;
            letter-spacing: 2px;
            font-weight: 100;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                width: 100%;
                height: 35px;
                padding: 0;
                margin-top: 30px;

                input {
                    width: 205px;
                    height: 25px;
                    text-indent: 15px;
                    border: none;
                    background-color: rgba(0, 0, 0, 0);
                    outline: none;
                    color: black;
                    font-size: 16px;
                    vertical-align: middle;
                    margin-top: 4px;
                }

                input.verify-code {
                    width: 105px;
                    height: 25px;
                    text-indent: 15px;
                    border: none;
                    background-color: rgba(0, 0, 0, 0);
                    outline: none;
                    color: black;
                    font-size: 16px;
                    vertical-align: middle;
                    margin-top: 4px;
                }

                .loginBtn {
                    background-color: #4d75e1;
                    background-color: rgba(65, 105, 225, 0.8);
                    color: white;
                    font-size: 14px;
                    width: 250px;
                    height: 40px;
                    border: none;

                    &:hover {
                        background-color: #4d75e1;
                    }
                }
            }

            .borderLi {
                background-color: rgba(255, 255, 255, 0.9);
                border: 1px solid rgba(255, 255, 255, 0.49);
                border-radius: 1px;

                img {
                    height: 25px;
                    width: 25px;
                    vertical-align: middle;
                    margin-top: 4px;
                }

                img.verify-code {
                    height: 35px;
                    width: 130px;
                    margin-top: 0;
                    vertical-align: middle;
                }
            }
        }
    }

    .login-footer {
        color: white;
        margin-bottom: 10px;
    }

    .login-otherInfo {
        color: white;
        margin-bottom: 10px;
        cursor: pointer;
    }

    .login-otherInfo:hover {
        color: yellow;
    }

    .login-line {
        color: white;
        font-size: 16px;
    }
}

/* 视频预览 */
#view1, #view2 {
    width: 400px;
    height: 300px;
    border: 1px solid red;
    margin-right: 5px;
}
</style>