import {reactive, ref} from 'vue'
import {
    productTypeSave,
    productTypeDelete,
    productTypeGetList,
    productTypeSort
} from '@/api/product'
import {useStore} from "vuex";

function useSaveProductType() {
    const productType = reactive({
        id: 0,
        name: '',
        typeNo: '',
        levelId: 0,
        parentId: 0,
        typeBelong: 0,
    })
    const initProductType = () => {
        productType.id = 0
        productType.typeNo = ''
        productType.name = ''
        productType.parentId = 0
        productType.levelId = 0
        productType.typeBelong = 0
    }
    const productTypeFormRef = ref()
    const productTypeSaveRules = reactive({
        name: [
            {required: true, message: '请输入名称', trigger: 'blur'},
        ]
    })
    const saveProductTypeLoading = ref(false)
    const saveProductTypeResult = ref(false)
    const saveProductType = async () => {
        saveProductTypeResult.value = false
        if (saveProductTypeLoading.value) return
        await productTypeFormRef.value.validate(async (valid, fields) => {
            if (!valid) {
                return
            }
            saveProductTypeLoading.value = true
            saveProductTypeResult.value = await productTypeSave(productType)
            saveProductTypeLoading.value = false
        })
    }
    return {
        productType,
        initProductType,
        productTypeFormRef,
        productTypeSaveRules,
        saveProductTypeResult,
        saveProductTypeLoading,
        saveProductType
    }
}

function useSortProductType() {
    const sortProductTypeLoading = ref(false)
    const sortProductTypeResult = ref(false)
    const sortProductType = async param => {
        sortProductTypeResult.value = false
        if (sortProductTypeLoading.value) return
        sortProductTypeLoading.value = true
        sortProductTypeResult.value = await productTypeSort(param)
        sortProductTypeLoading.value = false
    }
    return {
        sortProductTypeLoading,
        sortProductTypeResult,
        sortProductType
    }
}

function useGetListProductType() {
    const productTypeList = ref([])
    const getListProductTypeLoading = ref(false)
    const getListProductType = async () => {
        if (getListProductTypeLoading.value) return
        getListProductTypeLoading.value = true
        let data = await productTypeGetList({})
        if (data) {
            productTypeList.value = data
        }
        getListProductTypeLoading.value = false
    }
    return {
        productTypeList,
        getListProductType,
        getListProductTypeLoading
    }
}

function useUpdateStoreProductType() {
    const updateStoreProductTypeLoading = ref(false)
    const store = useStore()
    const updateStoreProductType = async () => {
        if (updateStoreProductTypeLoading.value) return
        updateStoreProductTypeLoading.value = true
        let data = await productTypeGetList({})
        store.commit('setProductType', (data && data.length) ? data : [])
        updateStoreProductTypeLoading.value = false
    }
    return{
        updateStoreProductTypeLoading,
        updateStoreProductType
    }
}

function useDeleteProductType() {
    const deleteProductTypeLoading = ref(false)
    const deleteProductTypeResult = ref(false)
    const deleteProductType = async id => {
        deleteProductTypeResult.value = false
        if (deleteProductTypeLoading.value) return
        deleteProductTypeLoading.value = true
        let param = {
            id: id
        }
        deleteProductTypeResult.value = await productTypeDelete(param)
        deleteProductTypeLoading.value = false
    }
    return {
        deleteProductTypeLoading,
        deleteProductTypeResult,
        deleteProductType
    }
}

export {
    useSaveProductType,
    useSortProductType,
    useGetListProductType,
    useDeleteProductType,
    useUpdateStoreProductType
}