import {reactive, ref, inject, toRaw} from 'vue'
import moment from 'moment'
import {
    facPropertySave,
    facPropertyGet,
    facPropertyGetPage,
    facPropertyUpdateValid,
    facPropertyDelete,
    facPropertyExportExcel,
    facPropertyGetDisinfectList,
    facPropertyGetStepList,
    facPropertyGetSimpleList,
    facPropertyGetByMachineNo,
    facStepMachineGetPage,
    machineStepExportExcel
} from '@/api/property'
import http from "@/util/axios";
import {useDownload} from "@/use/system/useFile";
import {ElMessage} from "element-plus";
// 获取车间使用的设备
function useGetWorkshopMachineList() {
    const workshopMachineList = ref([])
    const getWorkshopMachineListLoading = ref(false)
    const getWorkshopMachineList = async () => {
        if (getWorkshopMachineListLoading.value) return
        getWorkshopMachineListLoading.value = true
        let data = await facPropertyGetStepList()
        workshopMachineList.value = data ? data : []
        getWorkshopMachineListLoading.value = false
    }
    return {
        workshopMachineList,
        getWorkshopMachineListLoading,
        getWorkshopMachineList
    }
}

//保存资产 oycm 2022/9/6
function useSaveFacProperty() {
    const facProperty = reactive({
        id: 0,
        propertyNo: '',
        machineNo: '',
        useState: 2,
        name: '',
        propertyBelong: '',
        propertyType: '',
        isDisinfect: 0,
        standard: '',
        version: '',
        unit: '',
        useWorkerId: null,
        useDeptId: '',
        useDate: '',
        useAddress: '',
        factory: '',
        factoryPerson: '',
        factoryPhoneNo: '',
        supply: '',
        supplyPerson: '',
        supplyPhoneNo: '',
        remark: '',
        financeDate: '',
        orgPrice: '',
        financeNo: '',
        financeRemark: '',
        invoiceNo: '',
        isValid: 1,
    })
    const facPropertyEmpty = JSON.parse(JSON.stringify(toRaw(facProperty)))
    const setFields = inject('setFields')
    const initFacProperty = () => {
        setFields(facProperty, facPropertyEmpty)
    }
    const facPropertyFormRef = ref()
    const facPropertySaveRules = reactive({
        name: [
            {required: true, message: '请输入资产名称', trigger: 'blur'},
        ],
        propertyType: [
            {required: true, message: '请选择资产类型', trigger: 'blur'},
        ],
        useState: [
            {required: true, message: '请选择使用状态', trigger: 'blur'},
        ],
    })
    const saveFacPropertyLoading = ref(false)
    const saveFacPropertyResult = ref(false)
    const saveFacProperty = async () => {
        saveFacPropertyResult.value = false
        if (saveFacPropertyLoading.value) return
        saveFacPropertyLoading.value = true
        await facPropertyFormRef.value.validate(async (valid, fields) => {
            if (!valid) {
                saveFacPropertyLoading.value = false
                return
            }
            saveFacPropertyResult.value = await facPropertySave(facProperty)
            saveFacPropertyLoading.value = false
        })
    }
    return {
        facProperty,
        initFacProperty,
        facPropertyFormRef,
        facPropertySaveRules,
        saveFacPropertyLoading,
        saveFacPropertyResult,
        saveFacProperty,
    }
}

//分页资产 oycm 2022/9/6
function useGetPageFacProperty() {
    const getPageFacPropertyParam = reactive({
        id: null,
        propertyNo: '',
        machineNo: '',
        useState: null,
        name: '',
        propertyBelong: null,
        propertyType: null,
        isDisinfect: '',
        useWorkerId: null,
        useDeptId: '',
        useAddress: '',
        factory: '',
        supply: '',
        remark: '',
        financeNo: '',
        isValid: '',
        pageIndex: 1,
        pageSize: 10,
    })
    const getPageFacPropertyParamEmpty = JSON.parse(JSON.stringify(toRaw(getPageFacPropertyParam)))
    const setFields = inject('setFields')
    const initGetPageFacPropertyParam = () => {
        setFields(getPageFacPropertyParam, getPageFacPropertyParamEmpty)
    }
    const getPageFacPropertyLoading = ref(false)
    const facPropertyPage = ref([])
    const getPageFacPropertyResult = reactive({
        totalCount: 0,
        totalPages: 0,
    })
    const getPageFacProperty = async () => {
        if (getPageFacPropertyLoading.value) return
        getPageFacPropertyLoading.value = true
        let data = await facPropertyGetPage(getPageFacPropertyParam)
        if (data && data.list && data.list.length) {
            facPropertyPage.value = data.list
            getPageFacPropertyResult.totalPages = Math.ceil(data.totalCount / getPageFacPropertyParam.pageSize)
            getPageFacPropertyResult.totalCount = data.totalCount
        } else {
            facPropertyPage.value = []
            getPageFacPropertyResult.totalPages = 0
            getPageFacPropertyResult.totalCount = 0
        }
        getPageFacPropertyLoading.value = false
    }
    return {
        getPageFacPropertyParam,
        initGetPageFacPropertyParam,
        getPageFacPropertyLoading,
        facPropertyPage,
        getPageFacPropertyResult,
        getPageFacProperty,
    }
}

//停用启用资产
function useUpdateValidFacProperty() {
    const updateValidFacPropertyLoading = ref(false)
    const updateValidFacPropertyResult = ref(false)
    const updateValidFacProperty = async (id, isValid) => {
        updateValidFacPropertyResult.value = false
        if (updateValidFacPropertyLoading.value) return
        updateValidFacPropertyLoading.value = true
        updateValidFacPropertyResult.value = await facPropertyUpdateValid({id: id, isValid: isValid})
        updateValidFacPropertyLoading.value = false
    }
    return {
        updateValidFacPropertyLoading,
        updateValidFacPropertyResult,
        updateValidFacProperty,
    }
}

function useDeleteFacProperty() {
    const deleteFacPropertyLoading = ref(false)
    const deleteFacPropertyResult = ref(false)
    const deleteFacProperty = async id => {
        deleteFacPropertyResult.value = false
        if (deleteFacPropertyLoading.value) return
        deleteFacPropertyLoading.value = true
        deleteFacPropertyResult.value = await facPropertyDelete({id: id})
        deleteFacPropertyLoading.value = false
    }
    return {
        deleteFacPropertyLoading,
        deleteFacPropertyResult,
        deleteFacProperty,
    }
}

const useExportFacPropertyExcel = () => {
    const {
        downFile,
        downFileLoading
    } = useDownload()
    const exportFacPropertyExcel = req => {
        req.token = sessionStorage.getItem('token')
        let url = facPropertyExportExcel + '?' + http.getQueryFromJson(req)
        downFile(url, '资产列表')
    }
    return {
        exportFacPropertyExcelLoading: downFileLoading,
        exportFacPropertyExcel
    }
}


function useGetDisinfectFacPropertyList() {
    const disinfectFacPropertyList = ref([])
    const getDisinfectFacPropertyListLoading = ref(false)
    const getDisinfectFacPropertyList = async () => {
        if (getDisinfectFacPropertyListLoading.value) return
        getDisinfectFacPropertyListLoading.value = true
        let data = await facPropertyGetDisinfectList()
        if (data && data.length) disinfectFacPropertyList.value = data
        getDisinfectFacPropertyListLoading.value = false
    }
    return {
        disinfectFacPropertyList,
        getDisinfectFacPropertyListLoading,
        getDisinfectFacPropertyList,
    }
}

function useGetSimpleListFacProperty() {
    const getFacPropertySimpleListParam = reactive({
        propertyBelong: null,
        propertyType: null,
    })
    const facPropertySimpleList = ref([])
    const getFacPropertySimpleListLoading = ref(false)
    const getFacPropertySimpleList = async () => {
        if (getFacPropertySimpleListLoading.value) return
        getFacPropertySimpleListLoading.value = true
        let res = await facPropertyGetSimpleList(getFacPropertySimpleListParam)
        if (res) {
            facPropertySimpleList.value = res
        } else {
            facPropertySimpleList.value = []
        }
        getFacPropertySimpleListLoading.value = false
    }
    return {
        getFacPropertySimpleListParam,
        facPropertySimpleList,
        getFacPropertySimpleListLoading,
        getFacPropertySimpleList
    }
}

// 通过机器编号获取id和名称
function useGetByMachineNoAsset() {
    const simpleAssetList = ref([])
    const getAssetByMachineNoLoading = ref(false)
    const getAssetByMachineNo = async machineNo => {
        if (!machineNo) return
        if (getAssetByMachineNoLoading.value) return
        getAssetByMachineNoLoading.value = true
        let res = await facPropertyGetByMachineNo({machineNo: machineNo})
        if (!!res && res.length) {
            simpleAssetList.value = res
        } else {
            simpleAssetList.value = []
        }
        getAssetByMachineNoLoading.value = false
    }
    return {
        simpleAssetList,
        getAssetByMachineNo,
    }
}

function useGetFacProperty() {
    const getFacPropertyLoading = ref(false)
    const getFacProperty = async id => {
        if (getFacPropertyLoading.value) return
        getFacPropertyLoading.value = true
        let res = await facPropertyGet({id: id})
        getFacPropertyLoading.value = false
        if (res) return res
    }
    return {
        getFacProperty,
        getFacPropertyLoading
    }
}

//  获取设备追溯分页
function useGetPageMachine()
{
    const getPageMachineParam = reactive({
        propertyId:'',
        machineNo:'',
        caseNo:'',
        beginOperTime:'',
        endOperTime:'',
        pageIndex: 1,
        pageSize: 10,
    })
    const getPageMachineParamEmpty = JSON.parse(JSON.stringify(toRaw(getPageMachineParam)))
    const setFields = inject('setFields')
    const initGetPageMachineParam = () => {
        setFields(getPageMachineParam, getPageMachineParamEmpty)
    }
    const getPageMachineLoading = ref(false)
    const machinePage = ref([])
    const getPageMachineResult = reactive({
        totalCount: 0,
        totalPages: 0,
    })
    const getPageMachine = async () => {
        if (getPageMachineLoading.value) return
        getPageMachineLoading.value = true
        let data = await facStepMachineGetPage(getPageMachineParam)
        if (data && data.list && data.list.length) {
            machinePage.value = data.list
            getPageMachineResult.totalPages = Math.ceil(data.totalCount / getPageMachineParam.pageSize)
            getPageMachineResult.totalCount = data.totalCount
        } else {
            machinePage.value = []
            getPageMachineResult.totalPages = 0
            getPageMachineResult.totalCount = 0
        }
        getPageMachineLoading.value = false
    }
    return {
            getPageMachineParam,
            getPageMachineResult,
            machinePage,
            initGetPageMachineParam,
            getPageMachineLoading,
            getPageMachine
    }
}

const exportMachineStepExcel = req => {
    if (!req.beginOperTime && !req.endOperTime ) {
        ElMessage.error('需要选择好操作日期后再导出')
        return
    }
    req.token = sessionStorage.getItem('token')
    let url = machineStepExportExcel + '?' + http.getQueryFromJson(req)
    window.open(url, '_blank')
}

export {
    useSaveFacProperty,
    useGetPageFacProperty,
    useUpdateValidFacProperty,
    useDeleteFacProperty,
    useExportFacPropertyExcel,
    useGetDisinfectFacPropertyList,
    useGetByMachineNoAsset,
    useGetSimpleListFacProperty,
    useGetFacProperty,
    useGetWorkshopMachineList,
    useGetPageMachine,
    exportMachineStepExcel
}