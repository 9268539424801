<template>
    <el-autocomplete title="可以按照名称、拼音首字母和编号搜索" v-model.trim="inputWorkerInfo" value-key="name" clearable
                     placeholder="请选择员工" @select="chooseInputWorker" select-when-unmatched
                     highlight-first-item @blur="blurWorker"
                     :fetch-suggestions="fetchWorker">
        <template #default="{ item }">
            <div style="display: flex;justify-content:space-between;">
                <b>{{ item.name }}</b>
                <span style="float: right;color: var(--el-text-color-secondary);font-size: 13px;">{{
                        item.code
                    }}</span>
            </div>
        </template>

    </el-autocomplete>
</template>
<!--示例-->
<!--
<worker-select v-model:worker-id="workerId"></worker-select>
-->
<script setup>
import {useStore} from "vuex";
import {ref, reactive, onMounted, watch, toRef, defineProps, defineEmits, computed} from 'vue'
import {commonListDefaultFilter, commonListFilter} from "@/util/formatter";

const props = defineProps({
    workerId: Number,
    workerIds: Array,
    workerList: Array
})
const emits = defineEmits(['update:workerId', 'changeWorker'])
watch(() => props.workerId, (n, o) => {
    if (n) {
        innerWorkerId.value = parseInt(n)
        let worker = store.state.workerList.find(x => x.id === innerWorkerId.value)
        worker && (inputWorkerInfo.value = worker.name)
    } else {
        innerWorkerId.value = null
        inputWorkerInfo.value = ''
    }
})
const store = useStore()
const innerWorkerId = ref()
const inputWorkerInfo = ref('')
let storeWorkerList = null
const sortWorker = () => {
    storeWorkerList.sort((a, b) => {
        if (b.count === a.count) {
            return b.id - a.id
        }
        return b.count - a.count
    })
}
watch(() => props.workerIds, (n, o) => {
    if (n && n.length) {
        storeWorkerList = store.state.workerList.filter(x => n.includes(x.id))
    } else {
        storeWorkerList = store.state.workerList
    }
})
// 输入框
const fetchWorker = (str, cb) => {
    let res = []
    if (str) {
        res = storeWorkerList.filter(commonListFilter(str))
    } else {
        res = storeWorkerList.filter(commonListDefaultFilter)
    }
    cb(res)
}
const chooseInputWorker = item => {
    innerWorkerId.value = (item && item.id) ? item.id : null
    changeWorkerId()
}
const blurWorker = () => {
    let emitOuter = false//是否要通知外层
    if (!inputWorkerInfo.value) {
        if (innerWorkerId.value) emitOuter = true
        innerWorkerId.value = null
    } else {
        let worker = store.state.workerList.find(x => x.name === inputWorkerInfo.value)
        if (worker) {
            if (innerWorkerId.value !== worker.id) {
                innerWorkerId.value = worker.id
                emitOuter = true
            }
        } else {
            if (innerWorkerId.value) emitOuter = true
            innerWorkerId.value = null
        }
        //没有选择 清空输入框
        if (!innerWorkerId.value) {
            inputWorkerInfo.value = ''
        }
    }
    if (emitOuter) changeWorkerId()
}

onMounted(() => {
    if (props.workerId) {
        innerWorkerId.value = props.workerId
        let worker = store.state.workerList.find(x => x.id === props.workerId)
        worker && (inputWorkerInfo.value = worker.name)
    }
    if (props.workerList && props.workerList.length) {
        storeWorkerList = props.workerList
    } else {
        storeWorkerList = store.state.workerList
    }
    sortWorker()
    if (props.workerIds && props.workerIds.length) {
        storeWorkerList = storeWorkerList.filter(x => props.workerIds.includes(x.id))
    }

})

const changeWorkerId = () => {
    if (innerWorkerId.value) {
        emits('update:workerId', innerWorkerId.value)
    } else {
        emits('update:workerId', null)
    }
    emits('changeWorker', innerWorkerId.value)
}

</script>

<style scoped>

</style>